import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
    transform(value: any) {
       var datePipe = new DatePipe('en-US');
        value = datePipe.transform(value , 'dd/MM/yyyy hh:mm:ss');
        return value;
    }
}