<!-- begin::Header -->

<div class="d-flex flex-wrap justify-content-end align-items-center mb-2">
    <div class="form-group inputtext-block">
        <input type="text" class="form-control form-control-lg" [formControl]="searchControl" name="searchText"
            placeholder="Search" (keydown)="preventSpace($event)" value="" />
        <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
            class="svg-icon svg-icon-1 me-0 span-input"></span>
    </div>
</div>


<div class="card mb-5 mb-xl-0">
    <div class="card-body p-0">
        <!-- begin::Table container -->
        <div class="table-responsive border">
            <!-- begin::Table -->
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                <!-- begin::Table head -->
                <thead class="table-light">
                    <tr class="fw-bolder text-dark fs-7">
                        <th (click)="onSort('ipAddress')" class="py-4">
                            IP Address
                            <span *ngIf="sortBy == 'ipAddress'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th (click)="onSort('operation')" class="py-4">
                            Operation
                            <span *ngIf="sortBy == 'operation'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th (click)="onSort('priority')" class="py-4">
                            Priority
                            <span *ngIf="sortBy == 'priority'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th (click)="onSort('type')" class="py-4">
                            Type
                            <span *ngIf="sortBy == 'type'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th (click)="onSort('createdAt')" class="py-4">
                            Created At
                            <span *ngIf="sortBy == 'createdAt'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-50px w-50px text-center">Actions</th>
                    </tr>
                </thead>
                <!-- end::Table head -->
                <!-- begin::Table body -->
                <tbody *ngIf="!preLoaderScreen && length > 0">
                    <tr *ngFor="let log of logs; let i = index">
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="d-flex justify-content-start flex-column">
                                    <a class="text-dark fs-6">
                                        {{log.ipAddress != 'null' ? log.ipAddress : '-'}}
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td>
                            <a class="text-dark word-wrap d-block fs-6">
                                {{log.operation}}
                            </a>
                        </td>
                        <td>
                            <a class="text-dark d-block">
                                {{log.priority}}
                            </a>
                        </td>
                        <td>
                            <a class="text-dark d-block">
                                {{log.type}}
                            </a>
                        </td>
                        <td>
                            <a class="text-dark d-block">
                                {{log.createdAt | dateFormatPipe}}
                            </a>
                        </td>
                        <td class="text-center">
                            <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                    class="svg svg-icon svg-transparent">
                                </span>
                            </a>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item class="menu-list-block" title="Delete"
                                    *ngIf="log.isDeleted == false" (click)="onDeleteLogs(log._id)">
                                    <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                            class="svg svg-icon svg-icon-danger">
                                        </span>
                                    </div>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
                <!-- end::Table body -->
            </table>
            <app-preloader-table-list *ngIf="preLoaderScreen"></app-preloader-table-list>
            <div *ngIf="!preLoaderScreen && logs?.length == 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
                <!-- <hr> -->
            </div>
            <!-- end::Table -->
        </div>
        <!-- end: Table -->
        <app-pagination *ngIf="length > 0" [childMessage]="length" [index]="page" [pagesize]="limit"
            (messageEvent)="receiveMessage($event)">
        </app-pagination>
        <!-- end::Table container -->
    </div>
</div>