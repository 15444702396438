<div class="card mb-5 mb-xl-10 border-1 border">
  <div class="card-body p-3">
    <div class="row" *ngIf="advancedConfigurationPermissionsObj?.view">

      <div class="col-md-6">
        <div class="col-md-12">
          <div class="card mb-5 mb-xl-5 border-1 border h-100">
            <div class="card-header cursor-pointer d-flex px-5 min-h-45px bg-light">
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0 fs-4">Tunnel</h3>
              </div>
              <div class="me-2 card-toolbar d-flex align-items-center justify-content-end" title="Edit"
                *ngIf="advancedConfigurationPermissionsObj?.update">
                <button type="button" class="btn btn-primary btn-style"
                  [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                  (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openTunnel()">
                  <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                      class="svg-icon svg-icon-block mb-2">
                    </span>
                  </div> Edit
                </button>
              </div>
            </div>

            <div class="card-body p-5">
              <div class="row mb-1" *ngIf="bondDetails?.clamp_tcp == false || bondDetails?.debug == true">
                <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end">
                  &nbsp; </label>
                <div class="col-lg-4 col-xxl-6 col-md-6">
                  <span class="label label-warning fs-8 text-light ng-star-inserted"
                    *ngIf="bondDetails?.clamp_tcp == false">
                    TCP clamping disabled </span> &nbsp;
                  <span class="label label-info fs-8 text-light ng-star-inserted" *ngIf="bondDetails?.debug == true">
                    debug </span>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                  Aggregator </label>
                <div class="col-lg-4 col-xxl-6 col-md-6">
                  <span class="fs-7 text-dark" *ngIf="bondDetails?.aggregator_name">
                    {{bondDetails?.aggregator_name }}
                  </span>
                  <span *ngIf="!bondDetails?.aggregator_name" title="false">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                      class="svg-icon svg-icon-3 ml-2 me-1">
                    </span>
                  </span>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                  Secondary aggregator</label>
                <div class="col-lg-4 col-xxl-6 col-md-6">
                  <span class="fs-7 text-dark" *ngIf="bondDetails?.secondary_aggregator_name">
                    {{bondDetails?.secondary_aggregator_name}}
                  </span>
                  <span *ngIf="!bondDetails?.secondary_aggregator_name" title="false">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                      class="svg-icon svg-icon-3 ml-2 me-1">
                    </span>
                  </span>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                  Aggregator failback</label>
                <div class="col-lg-4 col-xxl-6 col-md-6">
                  <span *ngIf="bondDetails?.aggregator_failback" title="true">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                      class="svg-icon svg-icon-3 ml-2 me-1">
                    </span>
                  </span>
                  <span *ngIf="!bondDetails?.aggregator_failback" title="false">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                      class="svg-icon svg-icon-3 ml-2 me-1">
                    </span>
                  </span>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                  Debug</label>
                <div class="col-lg-4 col-xxl-6 col-md-6">
                  <span class="fw-bolder fs-6 text-dark">
                    <span *ngIf="!bondDetails?.debug" title="false">
                      <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                        class="svg-icon svg-icon-3 ml-2 me-1">
                      </span>
                    </span>
                    <span *ngIf="bondDetails?.debug" title="true">
                      <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                        class="svg-icon svg-icon-3 ml-2 me-1">
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="card mb-5 mb-xl-5 border-1 border h-100">
            <div class="card-header cursor-pointer d-flex px-5 min-h-45px bg-light">
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0 fs-4">Status</h3>
              </div>
              <div class="me-2 card-toolbar d-flex align-items-center justify-content-end" title="Edit"
                *ngIf="advancedConfigurationPermissionsObj?.update">
                <button type="button" class="btn btn-primary btn-style"
                  [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                  (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': popupChangePasswordModal()">
                  <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                      class="svg-icon svg-icon-block mb-2">
                    </span>
                  </div> Edit
                </button>
              </div>
            </div>

            <div class="card-body p-5">
              <div class="row mb-1">
                <div class="col-lg-12">
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Username</label>
                  <div class="col-lg-6 col-xxl-6 col-md-6">
                    <span class="fs-7 text-dark">{{bonderDetails?.authorization?.username}}</span>
                  </div>
                </div>
                <!-- <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Password</label>
                  <div class="col-lg-6 col-xxl-6">
                    <span *ngIf="!show_eye">••••••••</span>
                    <span *ngIf="show_eye" class="fs-7 text-dark">{{bonderDetails?.authorization?.password}}</span>
                    <span *ngIf="!show_eye" (click)="show_eye = true" class="ms-2 curser-pointer"> Show password</span>
                  </div>
                </div> -->
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Management VPN IPv4 </label>
                  <div class="col-lg-8 col-md-6"><span class="fs-7 text-dark">{{nodeDetails?.openvpn_ip}} </span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Management VPN IPv6 </label>
                  <div class="col-lg-8 col-md-6"><span class="fs-7 text-dark">{{nodeDetails?.openvpn_ipv6}}</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Tunnel subnet </label>
                  <div class="col-lg-6 col-xxl-6 col-md-6">
                    <span class="fs-7 text-dark">{{bondDetails?.tunnel_subnet}}/31</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Last config download </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">{{nodeDetails?.last_config_download | date:'MMM dd, y, hh:MM'}}
                    </span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Config started </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">
                      {{calculateDifferenceOfDate(nodeDetails?.config_service_started)}}
                    </span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Subprocess started </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">
                      {{calculateDifferenceOfDate(nodeDetails?.subprocess_service_started)}}
                    </span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Node started </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">
                      {{calculateDifferenceOfDate(nodeDetails?.node_service_started)}}
                    </span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Last boot </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">
                      {{calculateDifferenceOfDate(nodeDetails?.last_boot)}}
                    </span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Last management VPN connect time </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">
                      {{nodeDetails?.openvpn_client_connected | date:'MMM dd, y, hh:MM'}}
                    </span>
                  </div>
                </div>


              </div>
              <div class="col-lg-12">
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Last management VPN client address </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">{{nodeDetails?.openvpn_client_addr}}</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Salt connected </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">
                      <span *ngIf="!nodeDetails?.salt_connected_time" title="false">
                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                          class="svg-icon svg-icon-3 ml-2 me-1">
                        </span>
                      </span>
                      <span *ngIf="nodeDetails?.salt_connected_time" title="true">
                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                          class="svg-icon svg-icon-3 ml-2 me-1">
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Salt last online
                  </label>
                  <div class="col-lg-8 col-md-6"><span class="fs-7 text-dark">-</span></div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Salt fingerprint </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">{{nodeDetails?.salt_fingerprint}}</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    RSA fingerprint </label>
                  <div class="col-lg-8 col-md-6"><span class="fs-7 text-dark">{{nodeDetails?.rsa_fingerprint}}</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    DSA fingerprint </label>
                  <div class="col-lg-8 col-md-6"><span class="fs-7 text-dark">{{nodeDetails?.dsa_fingerprint}}</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    ECDSA fingerprint </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">{{nodeDetails?.ecdsa_fingerprint}}</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                    Conntrack entries </label>
                  <div class="col-lg-8 col-md-6">
                    <span class="fs-7 text-dark">
                      {{nodeDetails?.current_conntrack_entries}} ( max: {{nodeDetails?.conntrack_table_size}} )
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="col-md-12">
          <div class="card mb-5 mb-xl-5 border-1 border h-100">
            <div class="card-header cursor-pointer border-top d-flex px-5 min-h-45px bg-light">
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0 fs-4">Node </h3>
              </div>
              <div class="me-2 card-toolbar d-flex align-items-center justify-content-end" title="Edit"
                *ngIf="advancedConfigurationPermissionsObj?.update">
                <button type="button" class="btn btn-primary btn-style"
                  [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                  (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openNode()">
                  <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                      class="svg-icon svg-icon-block mb-2">
                    </span>
                  </div> Edit
                </button>
              </div>
            </div>

            <div class="card-body p-5">
              <div class="row mb-1">
                <div class="col-lg-12">
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Automatic reorder max hold </label>
                    <div class="col-lg-4 col-xxl-6 col-md-6">
                      <span class="fw-bolder fs-6 text-dark">
                        <span *ngIf="!bondDetails?.automatic_reorder_max_hold" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                        <span *ngIf="bondDetails?.automatic_reorder_max_hold" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Batched leg send operations </label>
                    <div class="col-lg-4 col-xxl-6 col-md-6">
                      <span class="fw-bolder fs-6 text-dark">
                        <span *ngIf="!bondDetails?.batched_leg_tx" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                        <span *ngIf="bondDetails?.batched_leg_tx" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Send jitter buffer </label>
                    <div class="col-lg-4 col-xxl-6 col-md-6">
                      <span class="fw-bolder fs-6 text-dark">
                        <span *ngIf="!bondDetails?.send_jitter_buffer" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                        <span *ngIf="bondDetails?.send_jitter_buffer" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Note </label>
                    <div class="col-lg-4 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">{{bondDetails?.note}}</span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      DNS Servers </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6" *ngIf="bonderDetails?.dns_servers?.length > 0">
                      <span class="fs-7 text-dark d-block" *ngFor="let dns of bonderDetails?.dns_servers">
                        {{dns}}
                      </span>
                    </div>
                    <div class="col-lg-6 col-xxl-6 col-md-6" *ngIf="bonderDetails?.dns_servers?.length <= 0">
                      <span class="fs-7 text-dark d-block" *ngFor="let dnsl of bonderDetails?.dns_servers_list">
                        {{dnsl}}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Manage process affinity </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fw-bolder fs-6 text-dark">
                        <span *ngIf="!bonderDetails?.manage_process_affinity" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                        <span *ngIf="bonderDetails?.manage_process_affinity" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Tunnel affinity core </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">{{bonderDetails?.tunnel_affinity_core}}</span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Metric collection interval </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">{{bonderDetails?.metric_collection_interval}}</span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Metric reporting interval </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">{{bonderDetails?.metric_reporting_interval}}</span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      TCP congestion control </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">{{bonderDetails?.tcp_congestion_control_algorithm |
                        uppercase}}</span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Web server </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">
                        <a *ngIf="!nodeDetails?.web_server" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </a>
                        <a *ngIf="nodeDetails?.web_server" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Automatic source IP </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fw-bolder fs-6 text-dark">
                        <span *ngIf="!bonderDetails?.automatic_source_ip" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                        <span *ngIf="bonderDetails?.automatic_source_ip" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Automatic ping timing </label>
                    <div class="col-lg-4 col-xxl-6 col-md-6">
                      <span class="fw-bolder fs-6 text-dark">
                        <span *ngIf="!bondDetails?.automatic_ping_timing" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                        <span *ngIf="bondDetails?.automatic_ping_timing" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Regular leg ping time </label>
                    <div class="col-lg-4 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">
                        Ping every {{bondDetails?.regular_leg_ping_time || 0}} ms,
                        down after {{bondDetails?.regular_leg_fail_time || 0}} ms
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Failover leg ping time </label>
                    <div class="col-lg-4 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">
                        Ping every {{bondDetails?.failover_leg_ping_time || 0}} ms,
                        down after {{bondDetails?.failover_leg_fail_time || 0}} ms
                      </span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Node ID </label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">{{nodeDetails?.id}}</span>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                      Node Debug</label>
                    <div class="col-lg-6 col-xxl-6 col-md-6">
                      <span class="fs-7 text-dark">
                        <a *ngIf="!nodeDetails?.debug" title="false">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </a>
                        <a *ngIf="nodeDetails?.debug" title="true">
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                            class="svg-icon svg-icon-3 ml-2 me-1">
                          </span>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="!advancedConfigurationPermissionsObj?.view">
      You have no permission to view the data. Kindly contact the authority.
    </div>
  </div>
</div>