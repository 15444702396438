<div class="card mb-5 border-1 border border-top-0" *ngIf="permissionsObj?.view">
  <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50">
    <div> </div>
    <div class="card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
      data-bs-placement="top" data-bs-trigger="hover" title="Add Interface" *ngIf="permissionsObj?.update">
      <button type="button" class="btn btn-primary btn-style"
        [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? 'disable-tune':''"
        (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? '': addInterface()">
        <div>
          <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
          </span>
        </div> Add Interface
      </button>
    </div>
  </div>
  <div class="card-body p-2">
    <div class="row">
      <div class="col-md-12 col-lg-12 mb-8 mb-0">
        <div *ngIf="permissionsObj?.view" class="table-responsive border">
          <table
            class="table table-fixed table-row-bordered table-row-gray-300 align-middle gs-2 gy-0 mb-0 interface-table">
            <thead class="table-light">
              <tr class="fw-bolder text-dark fs-7">
                <th class="py-4"> </th>
                <th class="py-4"> ID </th>
                <th class="py-4">Type </th>
                <th class="py-4">Interface </th>
                <th class="py-4">Mode </th>
                <th class="py-4">MTU </th>
                <th class="py-4">MAC </th>
                <th class="py-4">Status </th>
                <th class="py-4">Bridge </th>
                <th class="py-4">Note </th>
                <th class="py-4 min-w-100px w-100px text-center">
                  <span *ngIf="(permissionsObj.update || permissionsObj.delete)">
                    Actions </span>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="allInterfaces?.length > 0">
              <tr *ngFor="let interface of allInterfaces; let i = index" class="border-bottom border-0">
                <td colspan="11" class="p-0">
                  <table
                    class="table table-fixed table-row-bordered table-row-gray-300 align-middle gs-0 gy-1 mb-0 interface-table-inner">
                    <tbody>
                      <tr>
                        <td>
                          <button type="button" class="btn px-5" (click)="collapse.toggle()"
                            [attr.aria-expanded]="!interface.isCollapsed" aria-controls="collapseExample">
                            <i class="fa fa-caret-right text-primary"></i>
                          </button>
                        </td>
                        <td> {{ interface.id }} </td>
                        <td> {{interface.type}} </td>
                        <td> {{ interface.ifname }} </td>
                        <td>
                          <span *ngIf="interface.mode == 'auto'"> Auto </span>
                          <span *ngIf="interface.mode == '10baseT/Half'"> 10 Mbps Half duplex </span>
                          <span *ngIf="interface.mode == '10baseT/Full'"> 10 Mbps Full duplex </span>
                          <span *ngIf="interface.mode == '100baseT/Half'"> 100 Mbps Half duplex </span>
                          <span *ngIf="interface.mode == '100baseT/Full'"> 100 Mbps Full duplex </span>
                          <span *ngIf="interface.mode == '1000baseT/Full'"> 1 Gbps Full duplex </span>
                          <span *ngIf="interface.mode == 'auto' && interface?.status?.speed == '1000'">
                            :1 Gbps {{interface?.status?.duplex}} duplex
                          </span>
                          <span *ngIf="interface.mode != 'auto' && interface.mode != '10baseT/Half' && interface.mode != '10baseT/Full'
                            && interface.mode != '100baseT/Half' && interface.mode != '100baseT/Full' &&
                            interface.mode != '1000baseT/Full'"> n/a </span>
                        </td>
                        <td> {{interface?.mtu ? interface?.mtu : interface?.status?.mtu || '-'}}
                          <span class="badge-light-warning fw-bold w-100 d-flex fs-8 px-2 py-1 text-center"
                            *ngIf="interface?.mtu ? interface?.mtu  <= 1280 : interface?.status?.mtu ? interface?.status?.mtu < 1280 : false">
                            MTU must be at least 1280 for IPv6 addresses
                          </span>
                        </td>
                        <td> {{interface?.mac ? interface?.mac : interface?.status?.mac || '-'}} </td>
                        <td *ngIf="interface.status?.carrier!=null && interface.status?.carrier==true"><span
                            class="badge badge-light-success">Connected</span></td>
                        <td *ngIf="interface.status?.carrier!=null && interface.status?.carrier==false"><span
                            class="badge badge-light-danger">Disconnected</span></td>
                        <td *ngIf="interface.status?.carrier==null || interface.status==null">
                          <span class="text-dark">Never Reported</span>
                        </td>
                        <td> {{interface?.bridge_ifname ? interface?.bridge_ifname : '-'}} </td>
                        <td> {{interface?.note ? interface?.note : '-'}} </td>
                        <td class="text-center" *ngIf="(permissionsObj.update || permissionsObj.delete)">
                          <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                            *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                            class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                              class="svg svg-icon svg-transparent">
                            </span>
                          </a>
                          <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                            class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                              class="svg svg-icon svg-transparent">
                            </span>
                          </a>
                          <mat-menu #actionMenu="matMenu">
                            <button mat-menu-item class="menu-list-block" title="Edit"
                              [disabled]="permissionsObj.update ? false: true" title="Edit"
                              [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !(permissionsObj.update)? 'disable-tune':''"
                              (click)="(bondPermission && bondPermission.isUpdateDisable) || !(permissionsObj.update) ? '': editInterface(interface)">
                              <div class="clone-icon">
                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                  class="svg svg-icon svg-icon-warning">
                                </span>
                              </div>
                              <span>Edit</span>
                            </button>
                            <button mat-menu-item class="menu-list-block" title="Delete"
                              [disabled]="permissionsObj.delete ? false : true" title="Delete"
                              [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !(permissionsObj.delete) ? 'disable-tune':''"
                              (click)="(bondPermission && bondPermission.isUpdateDisable) || !(permissionsObj.delete) ? '': deleteInterface(interface.type,interface.id,interface?.ifname)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg svg-icon svg-icon-danger">
                                </span>
                              </div>
                              <span>Delete</span>
                            </button>
                          </mat-menu>
                        </td>
                      </tr>

                      <tr class="datatable-row-detail align-top bg-light" #collapse="ngbCollapse"
                        [(ngbCollapse)]="interface.isCollapsed">
                        <td class="px-0" colspan="9" *ngIf="!interface?.status">
                          <p class="m-0 p-3"> The node has not reported status for this interface. </p>
                        </td>

                        <td class="p-5" colspan="11" *ngIf="interface?.status"
                          style="border-bottom: 1px solid #dcdcdc !important;">
                          <div class="w-50">
                            <div class="row">
                              <div class="col-lg-12">
                                <h3 class="text-dark"> Interface status </h3>
                              </div>
                            </div>

                            <div class="row mb-1">
                              <label
                                class="col-lg-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">Carrier
                                detected </label>
                              <div class="col-lg-6"><span class="fs-7 text-dark">{{interface?.status?.carrier==false ?
                                  'No' : 'Yes'}}</span></div>
                            </div>

                            <div class="row mb-1">
                              <label class="col-lg-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                Speed </label>
                              <div class="col-lg-6">
                                <span class="fs-7 text-dark" *ngIf="interface.status.speed"> {{interface.status.speed}}
                                  Mbps </span>
                                <span class="fs-7 text-dark" *ngIf="!interface.status.speed"> Unknown Speed </span>
                              </div>
                            </div>

                            <div class="row mb-1">
                              <label class="col-lg-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                Duplex </label>
                              <div class="col-lg-6"><span class="fs-7 text-dark"> {{interface?.status?.duplex ||
                                  'Unknown'}} </span></div>
                            </div>

                            <div class="row mb-1">
                              <label class="col-lg-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                MAC </label>
                              <div class="col-lg-6"><span class="fs-7 text-dark"> {{interface?.status?.mac}} </span>
                              </div>
                            </div>

                            <div class="row mb-1">
                              <label class="col-lg-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                MTU </label>
                              <div class="col-lg-6"><span class="fs-7 text-dark"> {{interface?.status?.mtu}} </span>
                              </div>
                            </div>

                            <div class="row mb-1">
                              <label class="col-lg-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                Supported modes </label>
                              <div class="col-lg-6">
                                <p *ngFor="let item of interface?.status?.supported_modes" class="m-0 p-0">
                                  <span *ngIf="item == '10baseT/Half'"> 10 Mbps Half duplex </span>
                                  <span *ngIf="item == '10baseT/Full'"> 10 Mbps Full duplex </span>
                                  <span *ngIf="item == '100baseT/Half'"> 100 Mbps Half duplex </span>
                                  <span *ngIf="item == '100baseT/Full'"> 100 Mbps Full duplex </span>
                                  <span *ngIf="item == '1000baseT/Full'"> 1 Gbps Full duplex </span>
                                  <span *ngIf="item == 'auto'"> Auto negotiation </span>
                                </p>
                              </div>
                            </div>

                            <div class="row mb-1">
                              <label
                                class="col-lg-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">Updated
                              </label>
                              <div class="col-lg-6"> {{calculateDiff(interface?.status?.updated)}} </div>
                            </div>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <app-preloader-table-list *ngIf="sharedService.Loader.isVLANInterfaceLoader || sharedService.Loader.isVXLANInterfaceLoader 
          || sharedService.Loader.isEthernetInterfaceLoader ||sharedService.Loader.isBridgeInterfaceLoader"
            [length]="3"></app-preloader-table-list>
          <div *ngIf="(!sharedService.Loader.isVLANInterfaceLoader && !sharedService.Loader.isVXLANInterfaceLoader && !sharedService.Loader.isEthernetInterfaceLoader && !sharedService.Loader.isBridgeInterfaceLoader) 
            && allInterfaces?.length == 0" class="text-center font16 mt15 mb15 pt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
            </h3>
          </div>
        </div>
        <div *ngIf="!permissionsObj?.view">
          You have no permission to view the data. Kindly contact the authority.
        </div>
      </div>
    </div>
  </div>
</div>