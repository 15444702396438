<div class="card mb-5 mb-xl-8 border-1 border border-top-0">
    <div class="card-body p-2">
        <div class="d-flex flex-wrap mb-2 justify-content-end sd-wan-bonds-list">
            <!-- <div class="fw-bolder mb-0 div-position col-md-2 me-4">
                <input type="text" class="form-control" [formControl]="searchControl" name="searchText"
                    placeholder="Search" (keydown)="preventSpace($event)" value="" />
                <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
                    class="svg-icon svg-icon-1 me-0 span-input"></span>
            </div> -->
            <div class="d-flex flex-wrap">
                <!-- <div class="me-4">
                    <select style="max-width: 278px;" [(ngModel)]="spaceOption" name="status" data-hide-search="true"
                        class="form-select form-select-sm form-select-white col-md-2" (change)="onFilterChange($event)"
                        defaultValue="Active">
                        <option *ngFor="let item of filterOptions" [value]="item.value+''">{{item.title}}</option>
                    </select>
                </div> -->
                <!-- <div class="me-4">
                    <select (change)="onSecondFilterChange($event)" [disabled]="!selectedItemList?.length" name="status"
                        data-hide-search="true" class="form-select form-select-sm" defaultValue="Active"
                        [ngClass]="{'disable-option': !selectedItemList?.length, 'enable-option' : selectedItemList?.length  }">
                        <option [selected]="item.value==1" *ngFor="let item of secondFilterOptions"
                            [value]="item.value">
                            {{item.title}}</option>
                    </select>
                </div> -->
                <div class="me-2 me-md-3 card-toolbar d-flex align-items-center justify-content-end" *ngIf="!isSDWAN">
                    <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                        <input type="checkbox" name="show_again" class="form-check-input filter-space"
                            [(ngModel)]="isDescendantSpace" (change)="onFilterChange($event)">
                        <span class="form-check-label m-2 fw-bolder">Show descendant spaces</span>
                    </label>
                </div>
                <div class="me-2 me-md-3 card-toolbar d-flex align-items-center justify-content-end"
                    data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-trigger="hover">
                    <button type="button" class="btn btn-primary btn-style" placement="bottom"
                        [ngbPopover]="selectedItemList.length <= 0? '': addAction"
                        [disabled]="selectedItemList.length <= 0 ? true : false"
                        [ngClass]="selectedItemList.length <= 0 ? 'disable-option':''">
                        <div>
                            <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'"
                                class="svg-icon svg-icon-block mb-2">
                            </span>
                        </div> Action
                    </button>
                    <ng-template #addAction>
                        <ul class="p-0 mb-0">
                            <li class="menu-item list-style-none border-bottom"
                                *ngFor="let item of secondFilterOptions">
                                <span class="menu-link px-5"
                                    [ngClass]="(selectedItemList.length > 1 && item.value != 5) || ((!isAdminMail || !permissions?.clone) && item.value == 6)  ? 'disable-action':''"
                                    (click)="(selectedItemList.length > 1 && item.value != 5) || ((!isAdminMail || !permissions?.clone) && item.value == 6) ? '':onSecondFilterChange(item.value)">
                                    {{item.title}}
                                </span>
                            </li>
                        </ul>
                    </ng-template>
                </div>
                <!-- <div class="me-4 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
                    data-bs-placement="top" data-bs-trigger="hover" title="Select Column">
                    <a >
                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/columns.svg'"
                            class="svg-icon svg-icon-1 me-0"></span>
                    </a>
                </div> -->
                <!-- <div class="me-4 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
                    data-bs-placement="top" data-bs-trigger="hover" title="Support">
                    <a class="btn btn-sm btn-primary pe-4 ps-4">
                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/envelope.svg'"
                            class="svg-icon svg-icon-1 me-0"></span>
                    </a>
                </div> -->
                <div *ngIf="permissions?.update && (isPartner || isHomeSpace) && false"
                    class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
                    data-bs-placement="top" data-bs-trigger="hover" title="Click to add a Bond">
                    <a class="btn btn-sm btn-primary" (click)="goToAddBond()"> <span
                            [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                            class="svg-icon svg-icon-5 me-0"></span> Add Bond
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="permissions?.view" class="table-responsive border">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0 bonds-list">
                <thead class="align-middle table-light" *ngIf="!isColumnListAPI">
                    <tr class="fw-bolder fs-7">
                        <th class="ps-4 py-4">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" [(ngModel)]="isMasterSel"
                                    (change)="checkUncheckAll($event)" type="checkbox">
                            </div>
                        </th>
                        <th *ngFor="let item of selectedItems" (click)="onSort(item.sortKey)" [class]="item.width">
                            {{item.title}}
                            <span *ngIf="sortBy == item.sortKey && item.title != 'Options' && item.title != 'Legs'"
                                [inlineSVG]="arrow" class="svg-icon svg-icon-7" style="height: 10px;"></span>
                        </th>
                        <th *ngIf="(permissions.update || permissions.view)" class="py-3 min-w-50px w-50px text-center">
                            Action</th>
                    </tr>
                </thead>
                <div class="shine w-100" style="height: 40px;" *ngIf="isColumnListAPI"></div>
                <tbody *ngIf="length > 0 && !isBondListAPI">
                    <tr *ngFor="let bond of bondList; let i = index">
                        <td class="ps-4">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="bond.isSelected"
                                    name="list_name" value="{{bond.id}}" (change)="isAllSelected()" />
                            </div>
                        </td>

                        <td *ngFor="let item of selectedItems">
                            <ng-container [ngSwitch]="item.title">
                                <div *ngSwitchCase="'Legs'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start" *ngIf="!isLegListAPI">
                                        <a *ngIf="!bond.legsInfo?.length"
                                            class="text-white px-1 py-0 fw-normal bg-warning fs-7">
                                            No legs configured.
                                        </a>
                                        <a *ngIf="bond.legsInfo?.length" class="d-flex flex-wrap align-items-center">
                                            <div *ngFor="let item of bond.legsInfo" class="d-flex align-items-center">
                                                <ng-container
                                                    *ngIf="item.bonder_status != null && item.link_mode != 'idle' && item.link_mode != 'offline'">
                                                    <span *ngIf="item.overall_state == 'up'" [ngbPopover]="ngbPop1"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                    <span *ngIf="item.overall_state != 'up'"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        class="svg-icon svg-icon-8 ml-2 me-1"
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                        [ngbPopover]="ngbPop1" triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"></span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="item.bonder_status == null && item.link_mode != 'idle' && item.link_mode != 'offline'">
                                                    <span
                                                        *ngIf="item.overall_state == 'down' && item.type == 'Mobile broadband' && item.aggregator_status.security_mode == 'HMAC'"
                                                        [ngbPopover]="ngbPop1" triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1 "></span>
                                                    <span
                                                        *ngIf="item.overall_state == 'down' && item.type == 'Mobile broadband' && item.aggregator_status.security_mode == 'None'"
                                                        [ngbPopover]="ngbPop1" triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                    <span
                                                        *ngIf="(item.overall_state == 'down' || item.overall_state == 'not monitored') && item.type == 'Mobile broadband' && item.aggregator_status?.state == 'unknown'"
                                                        [ngbPopover]="ngbPop1" triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                    <span
                                                        *ngIf="(item.overall_state == 'not monitored'|| item.overall_state == 'flapping') && item.type == 'Interface'"
                                                        [ngbPopover]="ngbPop1" triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                    <span
                                                        *ngIf="(item.overall_state == 'down') && item.type == 'Interface'"
                                                        [ngbPopover]="ngbPop1" triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="item.link_mode == 'idle' || item.link_mode == 'offline'">
                                                    <span
                                                        *ngIf="item.link_mode == 'offline' && (item?.overall_state == 'flapping' || item?.overall_state == 'not monitored')"
                                                        [ngbPopover]="ngbPop1"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/white-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                    <span
                                                        *ngIf="item.link_mode == 'idle' && (item?.overall_state == 'flapping' || item?.overall_state == 'not monitored')"
                                                        [ngbPopover]="ngbPop1"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                    <span
                                                        *ngIf="item?.overall_state != 'flapping' && item?.overall_state != 'not monitored'"
                                                        [ngbPopover]="ngbPop1"
                                                        [ngClass]="item.failover ? 'leg-box-mbb': '' "
                                                        triggers="mouseenter:mouseleave"
                                                        [popoverTitle]="item.type+' leg '+item.id"
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                        class="svg-icon svg-icon-8 ml-2 me-1"></span>
                                                </ng-container>

                                                <ng-template class="p-5" #ngbPop1>
                                                    <table>
                                                        <tr>
                                                            <th>State:</th>
                                                            <td>{{item?.overall_state != 'not monitored' &&
                                                                item?.overall_state != 'flapping' ?
                                                                item?.overall_state : 'unknown'}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Local IP:</th>
                                                            <td>{{item?.bonder_status?.bound_tunnel_ip}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>NAT IP:</th>
                                                            <td>{{item?.bonder_status?.connected_tunnel_ip}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Failover:</th>
                                                            <td>
                                                                <span *ngIf="item.failover==false"
                                                                    [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                                    class="svg-icon svg-icon-3">
                                                                </span>
                                                                <span *ngIf="item.failover==true"
                                                                    [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                                    class="svg-icon svg-icon-3">
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Link mode:</th>
                                                            <td>{{item?.link_mode}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Interface:</th>
                                                            <td>{{item?.interface}}</td>
                                                        </tr>
                                                    </table>
                                                </ng-template>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="shine" style="width: 200px;height: 20px;" *ngIf="isLegListAPI"></div>
                                </div>

                                <div *ngSwitchCase="'Name'" class="d-flex align-items-center ">
                                    <div class="d-flex justify-content-start flex-column ">
                                        <a class="text-primary word-wrap d-block bond-name cursor-pointer fw-bold text-unerline"
                                            (click)="goToBondView(bond?.id,bond.bonder?.id)">
                                            {{bond.bonder?.name}}
                                        </a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Version'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a *ngIf="bond.version" class="text-dark  d-block fs-7">
                                            {{bond.version}}
                                        </a>
                                        <a *ngIf="!bond.version" class="text-dark  d-block fs-7">
                                            Never Online
                                        </a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Options'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a>
                                            <!-- <span [inlineSVG]="'./assets/media/icons/duotune/general/gen051.svg'"
                                                class="svg-icon svg-icon-3"
                                                [title]="bond.aggregator_failback?'Aggregator failover is enabled':'Aggregator failover is disabled'">
                                            </span> -->

                                            <span *ngIf="bond.qos_profile"
                                                [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                                title="QoS profile: {{bond.qos_profile.name}}"
                                                class="svg-icon svg-icon-3 svg-icon-warning">
                                            </span>

                                            <span *ngIf="bond.secondary_aggregator"
                                                [inlineSVG]="'./assets/media/icons/duotune/icon/code-merge.svg'"
                                                class="svg-icon svg-icon-5 me-1 svg-icon-primary"
                                                title="Aggregator failover is enabled">
                                            </span>

                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/filter.svg'"
                                                class="svg-icon svg-icon-7 me-1 svg-icon-success"
                                                [title]="bond.compression?'Compression is enabled':'Compression is disabled'">
                                            </span>

                                            <span *ngIf="bond.tunnel_security=='hmac'"
                                                [inlineSVG]="'./assets/media/icons/duotune/icon/HMAC-authentication.svg'"
                                                class="svg-icon svg-icon-7 me-1 svg-icon-danger"
                                                title="HMAC authentication is enabled">
                                            </span>
                                            <span *ngIf="bond.tunnel_security=='encryption'"
                                                [inlineSVG]="'./assets/media/icons/duotune/icon/Encryption.svg'"
                                                class="svg-icon svg-icon-7 me-1 svg-icon-danger"
                                                title="Encryption is enabled">
                                            </span>

                                            <span *ngIf="bond.bridge_enabled"
                                                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr033.svg'"
                                                class="svg-icon svg-icon-2 me-1 svg-icon-info"
                                                title="TCP proxy is enabled">
                                            </span>

                                        </a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Status'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <span *ngIf="bond.bonder?.status == 'up'" [ngbPopover]="ngbStatus1"
                                            triggers="mouseenter:mouseleave" popoverTitle="Management VPN:"
                                            [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1">
                                            <ng-template class="p-0 px-2" #ngbStatus1>
                                                <span>{{bond.bonder?.openvpn_ipv6}}</span><br>
                                                <span>{{bond.bonder?.openvpn_ip}}</span>
                                            </ng-template>
                                        </span>
                                        <span *ngIf="bond.bonder?.status == 'down'" [ngbPopover]="ngbStatus2"
                                            triggers="mouseenter:mouseleave" popoverTitle="Management VPN:"
                                            [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1">
                                            <ng-template class="p-0 px-2" #ngbStatus2>
                                                <span>{{bond.bonder?.openvpn_ipv6}}</span><br>
                                                <span>{{bond.bonder?.openvpn_ip}}</span>
                                            </ng-template>
                                        </span>
                                        <span *ngIf="bond.bonder?.status == 'unknown'" [ngbPopover]="ngbStatus3"
                                            triggers="mouseenter:mouseleave" popoverTitle="Management VPN:"
                                            [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1">
                                            <ng-template class="p-0 px-2" #ngbStatus3>
                                                <span>{{bond.bonder?.openvpn_ipv6}}</span><br>
                                                <span>{{bond.bonder?.openvpn_ip}}</span>
                                            </ng-template>
                                        </span>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Connected IP'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start" *ngIf="!isIPListAPI">
                                        <a class="text-dark  d-block fs-7">
                                            <span *ngIf="bond.connectedIPInfo && bond.connectedIPInfo.length > 0">
                                                {{bond.connectedIPInfo[0].ip ? bond.connectedIPInfo[0].ip :
                                                bond.connectedIPInfo[0].use_ipv6_link_local_address ?
                                                'Link local IPv6': ''}}
                                                <span *ngIf="bond.connectedIPInfo.length > 1">,
                                                    (+{{bond.connectedIPInfo.length - 1}} more)
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="shine" style="width: 200px;height: 20px;" *ngIf="isIPListAPI"></div>
                                </div>

                                <div *ngSwitchCase="'Aggregator failback'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-star">
                                        <span *ngIf="bond.aggregator_failback==false">
                                            -
                                        </span>
                                        <span *ngIf="bond.aggregator_failback==true"
                                            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr085.svg'"
                                            class="svg-icon svg-icon-1 svg-icon-success">
                                        </span>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Download'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                                            class="svg-icon svg-icon-5 svg-icon-danger" style="height: 10px;"></span>
                                        <span>{{calculateBytes(bond?.download_rate)}}</span>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Upload'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                                            class="svg-icon svg-icon-5 svg-icon-success" style="height: 10px;"></span>
                                        <span>{{calculateBytes(bond?.upload_rate)}}</span>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Compression'"
                                    class="d-flex align-items-center justify-content-center">
                                    <span *ngIf="bond.compression==false"
                                        [inlineSVG]="'./assets/media/icons/duotune/general/gen043.svg'"
                                        class="svg-icon svg-icon-2 svg-icon-success">
                                    </span>
                                    <span *ngIf="bond.compression==true"
                                        [inlineSVG]="'./assets/media/icons/duotune/general/gen044.svg'"
                                        class="svg-icon svg-icon-2 svg-icon-primary">
                                    </span>
                                </div>

                                <div *ngSwitchCase="'TCP proxy'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen043.svg'"
                                            class="svg-icon svg-icon-2 svg-icon-success"></span>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Space'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-star">
                                        <a class="text-dark  d-block fs-7">
                                            {{bond.space?.name}}
                                        </a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'QoS profile'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">
                                            {{bond.qos_profile?.name}}
                                        </a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Classification profile'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start" *ngIf="!isProfileListAPI">
                                        <a class="text-dark  d-block fs-7">
                                            {{bond.classification_display_name}}
                                        </a>
                                    </div>
                                    <div class="shine" style="width: 200px;height: 20px;" *ngIf="isProfileListAPI">
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Packet distribution'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7"
                                            *ngIf="bond.packet_distribution=='wrr'">Weighted round
                                            robin</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Bonder ID'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.id}}</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Distro'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.distro}}</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Kernel'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.kernel}}</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'CPU'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.cpu}}</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Memory'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.memory}}</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'System'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.system}}</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Serial Number'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.serial_number}}</a>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Asset tag'" class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  d-block fs-7">{{bond.bonder?.asset_tag}}</a>
                                    </div>
                                </div>
                                <div *ngSwitchDefault class="d-flex align-items-center">
                                    <div class="d-flex justify-content-start">
                                        <a class="text-dark  fs-7">
                                            {{bond[item.key]}}
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </td>
                        <td class="text-center">
                            <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                    class="svg svg-icon svg-transparent">
                                </span>
                            </a>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item class="menu-list-block" *ngIf="permissions.view" title="View"
                                    [ngClass]="(bond?.permissions && bond?.permissions?.view && bond?.permissions?.view == 'false') ? 'disable-tune':''"
                                    (click)=" (bond?.permissions && bond?.permissions?.view && bond?.permissions?.view == 'false') ? '': goToBondView(bond?.id,bond.bonder?.id)">
                                    <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/eye.svg'"
                                            class="svg svg-icon svg-icon-primary">
                                        </span>
                                    </div>
                                    <span>View</span>
                                </button>
                                <!-- <button mat-menu-item class="menu-list-block"
                                    *ngIf="permissions.update" title="Edit"
                                    [ngClass]="(bond.tuning && bond.tuning?.status == 'running') || (bond?.permissions && bond?.permissions?.update && bond?.permissions?.update == 'false') ? 'disable-tune':''"
                                    (click)="(bond.tuning && bond.tuning?.status == 'running') || (bond?.permissions && bond?.permissions?.update && bond?.permissions?.update == 'false') ? '': onEdit(bond.id,bond.bonder.id)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                            class="svg svg-icon svg-icon-warning">
                                        </span>
                                    </div>
                                    <span>Edit</span>
                                </button> -->
                                <button mat-menu-item class="menu-list-block" title="Share"
                                    (click)="onShared([{ id: '' + bond.id, homeSpace: (isSDWAN && sharedSdwanData) ? sharedSdwanData.homeSpace : null, shareId: (isSDWAN && sharedSdwanData) ? sharedSdwanData._id : null }], false)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/normal/share-alt.svg'"
                                            class="svg svg-icon svg-icon-success">
                                        </span>
                                    </div>
                                    <span>Share</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Delete"
                                    *ngIf="permissions.delete && !(isSDWAN && sharedSdwanData)"
                                    [ngClass]="(bond.tuning && bond.tuning?.status == 'running') ? 'disable-tune':''"
                                    (click)="(bond.tuning && bond.tuning?.status == 'running')  ? 'disable-tune': onDelete(bond)">
                                    <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                            class="svg svg-icon svg-icon-danger">
                                        </span>
                                    </div>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-preloader-table-list *ngIf="isBondListAPI"></app-preloader-table-list>
            <div *ngIf="!isBondListAPI && length == 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
            </div>
        </div>
        <app-pagination *ngIf="!isBondListAPI && length > 0" [childMessage]="length" [index]="page" [pagesize]="limit"
            (messageEvent)="receiveMessage($event)">
        </app-pagination>
        <div *ngIf="!permissions?.view">
            You have no permission to view the data. Kindly contact the authority.
        </div>
    </div>
</div>