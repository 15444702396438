<div class="d-flex flex-wrap flex-stack mb-3 justify-content-end">
    <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
        title="Click to go back">
        <button (click)="onBack()" class="btn btn-sm btn-primary d-inline-flex align-items-center p-3">
            <i class="fa fa-arrow-left me-1" aria-hidden="true"></i>
            Back
        </button>
    </div>
</div>

<div class="card mb-5 mb-xl-10">

    <div class="card-header px-5">
        <div class="card-title">
            <h3 class="card-label fs-3 fw-bolder">Configuration </h3>
        </div>
    </div>

    <div class="card-body p-5 pb-0">
        <div class="g-5 g-xxl-8">
            <h3 class="card-label fs-4 fw-bold">Tunnel</h3>
            <div class="form-group">
                <form action="#" [formGroup]="configurationForm" class="form form-label-right">
                    <div class="row">
                        <!-- Aggregator start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Aggregator</label>
                            <ng-select formControlName="aggregator" placeholder="---------">
                                <ng-option *ngFor="let item of allAggregators" [value]="item.url">
                                    {{item.space_name+'-'+item.name}} </ng-option>
                                <!-- <ng-option>  SD-WAN-Agg-sydney-gs-002</ng-option>
                        <ng-option>  SD-WAN-Agg-sydney-gs-003 </ng-option> -->
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.aggregator?.join(' ')}}
                            </small>
                            <div class="invalid-feedback"
                                *ngIf="aggregator?.invalid && (aggregator?.dirty || aggregator?.touched)">
                                Please select aggregator.
                            </div>
                        </div>
                        <!-- Secondary Aggregator start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Secondary aggregator</label>
                            <ng-select formControlName="secondary_aggregator">
                                <ng-option value=""> --------- </ng-option>
                                <ng-option *ngFor="let item of allAggregators" [value]="item.url">
                                    {{item.space_name+'-'+item.name}} </ng-option>
                            </ng-select>
                            <!-- Secondary Aggregator End-->
                            <small class="text-danger">
                                {{errors?.secondary_aggregator?.join(' ')}}
                            </small>

                            <div class="d-flex align-items-center mt-3">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" formControlName="aggregator_failback"
                                        type="checkbox" name="basic" id="basic">
                                    <label class="mb-0 form-label ms-2"> Aggregator failback <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip=" Automatically move this bond back to its primary aggregator when it recovers"></span> </label>
                                </div>
                            </div>
                            <small>
                               
                            </small>
                            <small class="text-danger">
                                {{errors?.aggregator_failback?.join(' ')}}
                            </small>
                        </div>
                        <!-- Aggregator end-->


                        <!-- QoS profile start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">QoS profile</label>
                            <ng-select formControlName="qos_profile">
                                <ng-option value=""> --------- </ng-option>
                                <ng-option *ngFor="let item of allQoSProfile" [value]="item.id">
                                    {{item.space_name+'-'+item.name}} </ng-option>
                            </ng-select>
                            <small class="text-danger"> {{errors?.qos_profile?.join(' ')}} </small>
                        </div>
                        <!-- QoS profile End-->

                        <!-- Classification profile start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Classification profile</label>
                            <ng-select formControlName="classification_profile" placeholder="---------">
                                <ng-option *ngFor="let item of allClassificationProfile" [value]="item.url">
                                    {{item.space_name+'-'+item.name}} </ng-option>
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.classification_profile?.join(' ')}}
                            </small>

                        </div>
                        <!-- Classification profile End-->

                        <!-- <div class="col-md-6 col-lg-6 mb-5">
                          <div class="d-flex align-items-center mt-3">
                            <input formControlName="compression" type="checkbox" name="Compression" id="">
                            <label class="mb-0 form-label ms-2"> Compression </label>
                        </div>

                        <small> Compress traffic in tunnel </small>
                        <small class="text-danger" >
                            {{errors?.compression']?.join(' ')}}
                        </small>
                        </div> -->
                        <!-- Flow collectors start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Flow collectors</label>
                            <ng-select formControlName="flow_collectors" placeholder="---------">
                                <ng-option *ngFor="let item of flowCollectors" [value]="item"> {{item?.name}}
                                </ng-option>
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.flow_collectors?.join(' ')}}
                            </small>
                            <div class="d-flex align-items-center mt-3">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" formControlName="compression" type="checkbox"
                                        name="Compression" id="">
                                    <label class="mb-0 form-label ms-2"> Compression <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Compress traffic in tunnel"></span> </label>
                                </div>
                            </div>

                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.compression?.join(' ')}}
                            </small>
                        </div>
                        <!-- <input type="text" class="form-control form-control-lg form-control-solid" name="Flow collectors"
                        placeholder="Enter Flow collectors" autocomplete="off"/> -->
                        <!-- Flow collectors End-->

                        <!-- Tunnel security start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Tunnel security</label>
                            <ng-select (change)="onEncryptionChange()" formControlName="tunnel_security"
                                placeholder="Tunnel security">
                                <ng-option *ngFor="let item of tunnelSecurity" [value]="item.key"> {{item.title}}
                                </ng-option>

                            </ng-select>
                            <small class="text-danger">
                                {{errors?.tunnel_security?.join(' ')}}
                            </small>
                        </div>
                        <!-- Tunnel security End-->

                        <!-- Encryption cipher start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Encryption cipher</label>
                            <ng-select [readonly]="!encryptionEnabled" formControlName="encryption_cipher"
                                placeholder="Tunnel security">
                                <ng-option *ngFor="let item of encyptionCipher" [value]="item.key"> {{item.title}}
                                </ng-option>
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.encryption_cipher?.join(' ')}}
                            </small>
                        </div>
                        <!-- Encryption cipher End-->
                        <!-- Encryption handshake interval start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Encryption handshake interval</label>
                            <input [readOnly]="!encryptionEnabled" formControlName="encryption_handshake_interval"
                                type="number" class="form-control form-control-lg form-control-solid"
                                name="Encryption handshake interval" placeholder="Enter Encryption handshake interval"
                                autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.encryption_handshake_interval?.join(' ')}}
                            </small>
                        </div>
                        <!-- Encryption handshake interval End-->
                        <!-- Packet distribution start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Packet distribution</label>
                            <ng-select (change)="onPacketDistributionChange()" formControlName="packet_distribution"
                                placeholder="Weighted round robin">
                                <ng-option *ngFor="let item of packetDistribution" [value]="item.key"> {{item.title}}
                                </ng-option>
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.packet_distribution?.join(' ')}}
                            </small>
                        </div>
                        <!-- Packet distribution End-->

                        <!-- Flowlet delta start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Flowlet delta <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="After pause of this duration, flow can be re-balanced to new leg (milliseconds)"></span> </label>
                            <input [readOnly]="!isFlowletSelected" formControlName="flowlet_delta" type="number"
                                class="form-control form-control-lg form-control-solid" name="flowlet_delta"
                                placeholder="Flowlet delta" autocomplete="off" />
                            <small> 
                            </small>
                            <small class="text-danger">
                                {{errors?.flowlet_delta?.join(' ')}}
                            </small>
                        </div>
                        <!-- Flowlet delta End-->

                        <!-- Enable Replify WAN optimization start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input disable-cursor" type="checkbox"
                                    formControlName="replify_enabled" name="replify_enabled"
                                    id="Enable-Replify-WAN-optimization" [readonly]="true" onclick="return false;">
                                <label class="m-2 form-label"> Enable Replify WAN optimization <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Additional infrastracture is required to run Replify, refer to the"></span> </label>
                            </div>
                            <small>  <a href="https://sd-wan.nepeannetworks.com/docs/bonds/replify.html">documentation</a> </small>
                            <small class="text-danger">
                                {{errors?.replify_enabled?.join(' ')}}
                            </small>
                        </div>
                        <!-- Enable Replify WAN optimization End-->

                        <!-- Batched leg send operations start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="batched_leg_tx" type="checkbox"
                                    name="Batched leg send operations" id="Batched-leg-send-operations">
                                <label class="m-2 form-label"> Batched leg send operations <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Improve CPU utilization at the cost of up to 1ms extra latency on any sent packet"></span> </label>
                            </div>
                            <small> 
                            </small>
                            <small class="text-danger" *ngIf="errors && errors['batched_leg_tx']">
                                {{errors['batched_leg_tx']?.join(' ')}}
                            </small>
                        </div>
                        <!-- Batched leg send operations End-->

                        <!-- Clamp TCP start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="clamp_tcp" type="checkbox"
                                    name="Clamp TCP" id="clamp-TCP">
                                <label class="m-2 form-label"> Clamp TCP <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Conform TCP packet size to tunnel MTU"></span> </label>
                            </div>
                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.clamp_tcp?.join(' ')}}
                            </small>
                        </div>
                        <!-- Clamp TCP start End-->
                        <!-- Source address verification start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="source_address_verification"
                                    type="checkbox" name="source_address_verification" id="Source address verification">
                                <label class="m-2 form-label"> Source address verification <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip=" Drop upload traffic coming from unrecognized source IPs"></span> </label>
                            </div>
                            <small> </small>
                            <small class="text-danger">
                                {{errors?.source_address_verification?.join(' ')}}
                            </small>
                        </div>
                        <!--Source address verification End-->
                        <!-- Encryption replay protection start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox"
                                    formControlName="encryption_replay_protection" name="Encryption replay protection"
                                    id="Encryption-replay-protection" [readonly]="encryptionEnabled">
                                <label class="m-2 form-label"> Encryption replay protection <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Enables DTLS replay protection. This prevents potential attackers from performing replay attacks. However, if a leg is experiencing significant packet reordering, this may
                                    result in tunnel packet loss."></span> </label>
                            </div>
                            <small>
                            </small>
                            <small class="text-danger">
                                {{errors?.encryption_replay_protection?.join(' ')}}
                            </small>
                        </div>
                        <!--Encryption replay protection End-->

                        <!-- Automatic ping timing start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="automatic_ping_timing" type="checkbox"
                                    name="Automatic ping timing" id="Automatic ping timing">
                                <label class="m-2 form-label"> Automatic ping timing <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Automatically set ping and failure intervals"></span> </label>
                            </div>
                            <small></small>
                            <small class="text-danger">
                                {{errors?.automatic_ping_timing?.join(' ')}}
                            </small>
                        </div>
                        <!--Automatic ping timing End-->

                        <!-- Regular leg ping time start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Regular leg ping time <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Send pings this often on regular legs (milliseconds)"></span> </label>
                            <input formControlName="regular_leg_ping_time" type="number"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Regular leg ping time" autocomplete="off" />
                            <small> </small>
                            <small class="text-danger">
                                {{errors?.regular_leg_ping_time?.join(' ')}}
                            </small>
                        </div>
                        <!-- Regular leg ping time End-->

                        <!-- Regular leg fail time start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Regular leg fail time <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Consider regular legs down after this receive idle time (milliseconds)"></span> </label>
                            <input type="number" formControlName="regular_leg_fail_time"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Regular leg fail time" autocomplete="off" />
                            <small></small>
                            <small class="text-danger">
                                {{errors?.regular_leg_fail_time?.join(' ')}}
                            </small>
                        </div>
                        <!--Regular leg fail time End-->

                        <!-- Failover leg ping time start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Failover leg ping time <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Send pings this often on failover leg (milliseconds)"></span> </label>
                            <input type="number" formControlName="failover_leg_ping_time"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Regular leg fail time" autocomplete="off" />
                            <small> </small>
                            <small class="text-danger">
                                {{errors?.failover_leg_ping_time?.join(' ')}}
                            </small>
                        </div>
                        <!--Failover leg fail time End-->

                        <!-- Failover leg fail time start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Failover leg fail time <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Consider failover leg down after this receive idle time (milliseconds)"></span> </label>
                            <input formControlName="failover_leg_fail_time" type="number"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Failover leg fail time" autocomplete="off" />
                            <small></small>
                            <small class="text-danger">
                                {{errors?.failover_leg_fail_time?.join(' ')}}
                            </small>
                        </div>
                        <!--Failover leg fail time End-->

                        <!-- Automatic reorder max hold start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" (change)="onReorderMaxChnage()"
                                    formControlName="automatic_reorder_max_hold" type="checkbox"
                                    name="Automatic reorder max hold" id="Automatic reorder max hold">
                                <label class="m-2 form-label"> Automatic reorder max hold <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Automatically set reorder max hold value"></span> </label>
                            </div>
                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.automatic_reorder_max_hold?.join(' ')}}
                            </small>
                        </div>
                        <!--Automatic reorder max hold End-->


                        <!-- Reorder max hold start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Reorder max hold <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Max duration to hold early packets (milliseconds)"></span> </label>
                            <input [readOnly]="isReorderMaxSelected" formControlName="reorder_max_hold" type="number"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Reorder max hold" autocomplete="off" />
                            <small></small>
                            <small class="text-danger">
                                {{errors?.reorder_max_hold?.join(' ')}}
                            </small>
                        </div>
                        <!--Reorder max hold End-->


                        <!-- Track packet loss on bonded legs start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="packet_loss_detection" type="checkbox"
                                    name="Packet loss detection" id="packet-loss-detection">
                                <label class="m-2 form-label"> Packet loss detection <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Track packet loss on bonded legs"></span> </label>
                            </div>
                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.packet_loss_detection?.join(' ')}}
                            </small>
                        </div>
                        <!--Track packet loss on bonded legs End-->

                        <!-- Flap detection start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="flap_detection" type="checkbox"
                                    name="Flap detection" id="Flap-detection">
                                <label class="m-2 form-label"> Flap detection <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Detect and remove legs that are rapidly switching between up and down"></span> </label>
                            </div>
                            <small> </small>
                            <small class="text-danger">
                                {{errors?.flap_detection?.join(' ')}}
                            </small>
                        </div>
                        <!--Flap detection End-->

                        <!-- Leg MTU detection start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" (change)="onLegMTUDetectionChnage()" type="checkbox"
                                    formControlName="leg_mtu_detection" name="Leg MTU detection" id="leg-mtu-detection">
                                <label class="m-2 form-label"> Leg MTU detection <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Automatically detect path MTU of links"></span> </label>
                            </div>
                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.leg_mtu_detection?.join(' ')}}
                            </small>
                        </div>
                        <!--Leg MTU detection End-->

                        <!-- Leg MTU detection time start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Leg MTU detection time <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Repeat leg MTU detection at this interval (hours)"></span> </label>
                            <input [readOnly]="!isLegMTUSelected" formControlName="leg_mtu_detection_time" type="number"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Leg MTU detection time" autocomplete="off" />
                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.leg_mtu_detection_time?.join(' ')}}
                            </small>
                        </div>
                        <!--Leg MTU detection time End-->





                        <!--Send jitter buffer start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="send_jitter_buffer" type="checkbox"
                                    name="Send jitter buffe" id="send-jitter-buffe">
                                <label class="m-2 form-label"> Send jitter buffer <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Delay low-latency links to match high-latency links"></span> </label>
                            </div>
                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.send_jitter_buffer?.join(' ')}}
                            </small>
                        </div>
                        <!--Send jitter buffer End-->
                        <!--Debug start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="debug" type="checkbox" name="Debug"
                                    id="Debug">
                                <label class="m-2 form-label"> Debug <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Enable debug logging for tunnel and TCP proxy applications on bonder and aggregator."></span> </label>
                            </div>
                            <small> 
                            </small>
                            <small class="text-danger">
                                {{errors?.debug?.join(' ')}}
                            </small>
                        </div>
                        <!--Debug End-->

                        <div class="separator separator-dashed my-5"> </div>
                        <div class="card-title d-flex align-items-center mb-0">
                            <h3 class="card-label m-0 fs-4 fw-bolder">TCP proxy</h3>
                            <div class="ms-5">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" (change)="onTCPProxyChange()"
                                        formControlName="bridge_enabled" type="checkbox" name="Enable TCP proxy"
                                        id="enable-tcp-proxy">
                                    <label class="m-2 form-label"> Enable TCP proxy </label>
                                </div>
                                <small class="text-danger">
                                    {{errors?.bridge_enabled?.join(' ')}}
                                </small>
                            </div>
                        </div>
                        <div class="separator separator-dashed my-5"></div>



                        <!-- TCP ports to proxy start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">TCP ports to proxy</label>
                            <input [readOnly]="!isTCPProxySelected" formControlName="bridge_ports" type="text"
                                class="form-control form-control-lg form-control-solid" name="TCP ports to proxy"
                                placeholder="Enter TCP ports to proxy" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.bridge_ports?.join(' ')}}
                            </small>
                        </div>
                        <!--TCP ports to proxy End-->

                        <!-- Concurrency start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Concurrency</label>
                            <input [readOnly]="!isTCPProxySelected" formControlName="bridge_concurrency" type="number"
                                class="form-control form-control-lg form-control-solid" name="Concurrency"
                                placeholder="Enter Concurrency" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.bridge_concurrency?.join(' ')}}
                            </small>
                        </div>
                        <!--Concurrency End-->

                        <!-- Bridge congestion control algorithm start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Bridge congestion control algorithm</label>
                            <ng-select [readonly]="!isTCPProxySelected"
                                formControlName="bridge_congestion_control_algorithm"
                                placeholder="Select Bridge congestion control algorithm">
                                <ng-option *ngFor="let item of bridgeCongetionAlgorithms" [value]="item.key">
                                    {{item.title}}
                                </ng-option>
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.bridge_congestion_control_algorithm?.join(' ')}}
                            </small>
                        </div>
                        <!-- Bridge congestion control algorithm End-->

                        <!-- File descriptor limit start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">File descriptor limit</label>
                            <input [readOnly]="!isTCPProxySelected" formControlName="bridge_file_descriptor_limit"
                                type="number" class="form-control form-control-lg form-control-solid"
                                name="File descriptor limit" placeholder="File descriptor limit" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.bridge_file_descriptor_limit?.join(' ')}}
                            </small>
                        </div>
                    </div>
                </form>
                <!-- File descriptor limit End-->
                <div class="separator separator-dashed my-5"> </div>
                <div class="card-title d-flex align-items-center justify-content-between mb-0">
                    <h3 class="card-label m-0 fs-4 fw-bolder">Details </h3>
                </div>
                <div class="separator separator-dashed my-5"> </div>

                <form [formGroup]="detailsForm">
                    <div class="row">
                        <!-- Administrative profile start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Administrative profile</label>
                            <ng-select formControlName="administrative_profile"
                                placeholder="Select Administrative profile">
                                <ng-option value="default" selected>Default</ng-option>
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.administrative_profile?.join(' ')}}
                            </small>
                        </div>
                        <!-- Administrative profile End-->

                        <!-- Name start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Name</label>
                            <input formControlName="name" type="text"
                                class="form-control form-control-lg form-control-solid" name="Name"
                                placeholder="Enter name" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.name?.join(' ')}}
                            </small>
                            <small class="text-danger" *ngIf="detailsForm?.controls?.name?.errors?.required
                                    && detailsForm?.controls?.name?.touched">
                                This field is required.
                            </small>
                            <div class="invalid-feedback d-block" *ngIf="controlHasError('pattern', 'name')">
                                Name is invalid
                            </div>
                        </div>
                        <!--  name End-->

                        <!-- Note start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Note</label>
                            <input formControlName="note" type="text"
                                class="form-control form-control-lg form-control-solid" name="Note"
                                placeholder="Enter Note" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.note?.join(' ')}}
                            </small>
                        </div>
                        <!--  Note End-->

                        <!-- Circuit ID start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Circuit ID</label>
                            <input formControlName="circuit_id" type="text"
                                class="form-control form-control-lg form-control-solid" name="Circuit ID"
                                placeholder="Enter Circuit ID" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.circuit_id?.join(' ')}}
                            </small>
                            <div class="invalid-feedback d-block" *ngIf="controlHasError('pattern', 'circuit_id')">
                                Circuit ID is invalid
                            </div>
                        </div>
                        <!--  Circuit ID End-->

                        <!-- Product start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Product</label>
                            <input formControlName="product" type="text"
                                class="form-control form-control-lg form-control-solid" name="Product"
                                placeholder="Enter Product" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.product?.join(' ')}}
                            </small>
                            <div class="invalid-feedback d-block" *ngIf="controlHasError('pattern', 'product')">
                                Product is invalid
                            </div>
                        </div>
                        <!--  Product End-->

                        <!-- Serial number start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Serial number</label>
                            <input formControlName="serial_number" type="text"
                                class="form-control form-control-lg form-control-solid" name="Serial number"
                                placeholder="Enter Serial number" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.serial_number?.join(' ')}}
                            </small>
                            <div class="invalid-feedback d-block" *ngIf="controlHasError('pattern', 'serial_number')">
                                Serial number is invalid
                            </div>
                        </div>
                        <!--  Serial number End-->

                        <!-- Asset tag start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Asset tag</label>
                            <input formControlName="asset_tag" type="text"
                                class="form-control form-control-lg form-control-solid" name="Asset tag"
                                placeholder="Enter Asset tag" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.asset_tag?.join(' ')}}
                            </small>
                            <div class="invalid-feedback d-block" *ngIf="controlHasError('pattern', 'asset_tag')">
                                Asset tag is invalid
                            </div>
                        </div>
                        <!--  Asset tag-->

                        <!-- Space start-->
                        <div class="col-md-6 col-lg-6 mb-5" *ngIf="isHomeSpace">
                            <label class="form-label">Space</label>
                            <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="space"
                                placeholder="Select Space">
                                <ng-option *ngFor="let item of spaces;" [value]="item.key">{{item.name}}</ng-option>
                            </ng-select>
                            <small class="text-danger">
                                {{errors?.space?.join(' ')}}
                            </small>
                        </div>
                        <!-- Space End-->
                        <div class="col-md-6 col-lg-6 mb-5" *ngIf="isEdit">
                            <button class="btn btn-primary" (click)="popupChangePasswordModal()">
                                Show / change password</button>
                        </div>
                        <!--Proof of concept start-->
                        <div class="col-md-12 col-lg-12 mb-5" *ngIf="!isEdit">
                            <div>
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" formControlName="proof_of_concept" type="checkbox"
                                        name="Proof of concept" id="Proof of concept">
                                    <label class="m-2 form-label"> Proof of concept </label>
                                </div>
                                <small class="text-danger">
                                    {{errors?.proof_of_concept?.join(' ')}}
                                </small>
                            </div>
                        </div>
                        <!-- Proof of concept End-->

                        <!-- Metric collection interval start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Metric collection interval</label>
                            <input type="number" formControlName="metric_collection_interval"
                                class="form-control form-control-lg form-control-solid"
                                name="Metric collection interval" placeholder="Enter Metric collection interval"
                                autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.metric_collection_interval?.join(' ')}}
                            </small>
                        </div>
                        <!--Metric collection interval End-->

                        <!-- Metric reporting interval start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Metric reporting interval</label>
                            <input formControlName="metric_reporting_interval" type="number"
                                class="form-control form-control-lg form-control-solid" name="Metric reporting interval"
                                placeholder="Enter Metric reporting interval" autocomplete="off" />
                            <small class="text-danger">
                                {{errors?.metric_reporting_interval?.join(' ')}}
                            </small>
                        </div>
                        <!--Metric reporting interval End-->

                        <!-- CPU governor start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">CPU governor <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip=" Method to use for scaling CPU frequencies. If unset, the last used method for the CPU type will be used or the system default will be used after the system is rebooted."></span> </label>
                            <ng-select formControlName="cpu_governor">
                                <ng-option value=""> ----------- </ng-option>
                                <ng-option *ngFor="let item of cpuGoverners" [value]="item.key">{{item.title}}
                                </ng-option>
                            </ng-select>
                            <small>
                            </small>
                            <small class="text-danger">
                                {{errors?.cpu_governor?.join(' ')}}
                            </small>
                        </div>
                        <!-- CPU governor End-->

                        <!-- TCP Congestion control algorithm start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">TCP Congestion control algorithm <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Algorithm to use for TCP congestion control."></span></label>
                            <ng-select formControlName="tcp_congestion_control_algorithm" placeholder="Conservative">
                                <ng-option *ngFor="let item of bridgeCongetionAlgorithms" [value]="item.key">
                                    {{item.title}}
                                </ng-option>
                            </ng-select>
                            <small></small>
                            <small class="text-danger">
                                {{errors?.tcp_congestion_control_algorithm?.join(' ')}}
                            </small>
                        </div>
                        <!-- TCP Congestion control algorithm End-->

                        <!-- Conntrack table size start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Conntrack table size <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="The maximum number of connections the host can track in its internal tables. If the
                                number of tracked connections reaches this number, new connections will be dropped and an entry made in the system log file."></span> </label>
                            <ng-select formControlName="conntrack_table_size" placeholder="Conntrack table size"
                                [searchable]="false">
                                <ng-option *ngFor="let item of conntrackTableSize" [value]="item.key">{{item.title}}
                                </ng-option>

                            </ng-select>
                            <small>
                            </small>
                            <small class="text-danger">
                                {{errors?.conntrack_table_size?.join(' ')}}
                            </small>
                        </div>
                        <!-- Conntrack table size End-->

                        <!-- Automatic ping timing start-->
                        <!-- <div class="col-md-6 col-lg-6 mb-5">
                    <div>
                        <input type="checkbox" formControlName="automatic_ping_timing" name="Automatic ping timing" id="automatic-ping-timing">
                        <label class="m-2 form-label"> Automatic ping timing </label>
                    </div>
                    <small> Automatically set ping and failure intervals </small>
                </div> -->
                        <!--Automatic ping timing End-->

                        <!-- Web server start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="web_server" type="checkbox"
                                    name="Web server" id="Webserver">
                                <label class="m-2 form-label"> Web server <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Enable node web server."></span> </label>
                            </div>
                            <small></small>
                            <small class="text-danger">
                                {{errors?.web_server?.join(' ')}}
                            </small>
                        </div>
                        <!--Web server End-->
                        <!-- Node debug  start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="debug" type="checkbox"
                                    name="Node debug" id="Node-debug">
                                <label class="m-2 form-label">Node debug <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Enable debug logging for core node services."></span> </label>
                            </div>
                            <small>  </small>
                            <small class="text-danger">
                                {{errors?.debug?.join(' ')}}
                            </small>
                        </div>
                        <!-- Node debug End-->
                        <!-- Manage process affinity  start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="manage_process_affinity"
                                    type="checkbox" name="Manage process affinity" id="Manage-process-affinity">
                                <label class="m-2 form-label"> Manage process affinity </label>
                                <small class="text-danger">
                                    {{errors?.manage_process_affinity?.join(' ')}}
                                </small>
                            </div>

                        </div>
                        <!-- Manage process affinity End-->



                        <!--Tunnel affinity core start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Tunnel affinity core <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="Which CPU core to bind the tunnel process to, where 1 is the first CPU core "></span> </label>
                            <input class="form-control form-control-lg form-control-solid" type="number"
                                formControlName="tunnel_affinity_core">
                            <small></small>
                            <small class="text-danger">
                                {{errors?.tunnel_affinity_core?.join(' ')}}
                            </small>
                        </div>
                        <!-- Tunnel affinity core End-->

                        <!-- Dns servers start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <label class="form-label">Dns servers <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="List of DNS servers that will be used for name resolution of the management and update servers. Bond defaults DNS servers will be used if no DNS server is set."></span> </label>
                            <input formControlName="dns_servers" type="text"
                                class="form-control form-control-lg form-control-solid" name="Dns servers"
                                placeholder="Enter Dns servers" autocomplete="off" />
                            <small></small>
                            <small class="text-danger">
                                {{errors?.dns_servers?.join(' ')}}
                            </small>
                        </div>
                        <!--  Dns servers End-->

                        <!-- Manage process affinity start-->
                        <div class="col-md-6 col-lg-6 mb-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="automatic_source_ip" type="checkbox"
                                    name="Manage process affinity" id="Manage process affinity">
                                <label class="m-2 form-label">Automatic Source IP</label>
                                <small class="text-danger">
                                    {{errors?.automatic_source_ip?.join(' ')}}
                                </small>
                            </div>
                        </div>
                        <!-- Manage process affinity End-->
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="separator separator-dashed my-5"></div>
    <div class="card-title d-flex align-items-center justify-content-between mb-0 px-5">
        <h3 class="card-label m-0 fs-4 fw-bolder">Network details</h3>
    </div>
    <div class="separator separator-dashed my-5"></div>

    <div class="card-body p-5">
        <p> Network details are now managed on the main bond page. </p>
    </div>

    <div class="card-footer p-5">
        <!-- Save -->
        <button type="submit" (click)="onSave()" class="btn btn-primary"><span [inlineSVG]="'./assets/media/icons/normal/check.svg'" class="svg-icon svg-icon-4 me-1"></span>Save</button>
        <!-- Update -->
        <!-- <button  type="submit" class="btn btn-info">Update</button> -->
        <button type="cancel" (click)="onCancel()" class="btn btn-primary"><span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-4 me-1"></span>Cancel</button>
        <!-- <button  type="button" class="btn btn-info">Reset
            Password</button> -->
    </div>
</div>
