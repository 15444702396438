import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-space-ip-delegate-modal',
  templateUrl: './shared-space-ip-delegate-modal.component.html',
  styleUrls: ['./shared-space-ip-delegate-modal.component.scss']
})
export class SharedSpaceIpDelegateModalComponent implements OnInit {
  GroupAllocation: FormGroup;
  spaceList: any = [];
  parentId: string;
  errors: any;
  spaceData: any;
  delegateData: any;
  isEdit: boolean = false;
  isDelegate: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private spaceService: PartnerSpaceService) { }

  ngOnInit(): void {
    if (this.isEdit) this.GroupAllocation = this.createForm(this.delegateData);
    else this.GroupAllocation = this.createForm();
  }

  createForm(data: any = {}) {
    return new FormGroup({
      routing_group: new FormControl(data?.space?.key ? data?.space?.key : this.spaceList && this.spaceList.length > 0 ? this.spaceList[0].key : ''),
      subnet: new FormControl(data?.network || null)
    })
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onSave() {
    let body: any;
    let space: any;
    this.spaceList.forEach((item: any) => {
      if (item && item.key == this.GroupAllocation.value.routing_group) space = item;
    })
    if (this.isDelegate) {
      body = {
        network: this.GroupAllocation.value.subnet || null,
        parent_delegated_ip_allocation: this.spaceData.url,
        space: space
      }
    } else {
      body = {
        network: this.GroupAllocation.value.subnet || null,
        parent_ip_allocation: this.spaceData.url,
        space: space
      }
    }
    let reqObj = {
      "url": `spaces/${this.GroupAllocation.value.routing_group}/delegated_ip_allocations/`,
      "method": "POST",
      "data": body
    }
    if (this.isEdit) {
      body = {
        network: this.GroupAllocation.value.subnet || null,
        space: space,
        id: this.delegateData.id
      }
      reqObj = {
        "url": `spaces/${this.GroupAllocation.value.routing_group}/delegated_ip_allocations/${this.delegateData.id}/`,
        "method": "PATCH",
        "data": body,
      }
    }
    this.sharedService.showLoader()
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess('delegated allocation saved successfully.');
      else this.sharedService.loggerError(addRes.message);
      this.activeModal.close({ event: 'save' });
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }
}
