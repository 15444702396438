import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { PartnerBondNetworkDetailsService } from 'src/app/services/partner-bond-network-details.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-bonds-view-speed-test',
  templateUrl: './shared-bonds-view-speed-test.component.html',
  styleUrls: ['./shared-bonds-view-speed-test.component.scss']
})
export class SharedBondsViewSpeedTestComponent implements OnInit {
  TestAdd: FormGroup;
  allSpeedTest: any[] = [];
  mergeArray: any[] = [];
  errors: any;
  speedTestId: any;
  page: any = 1;
  limit: any = AppConst.pageSize;
  testLength: number = 0;
  isView: boolean = false;
  preInterfaceLoaderScreen: boolean = false;
  isBondSpeedTestListLoader: boolean = false;

  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() bondData: any;

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    public sharedService: SharedService,
    private partnerBondNetworkService: PartnerBondNetworkDetailsService) { }

  ngOnInit(): void {
    this.createForm();
    this.getAllInterfaces();
    this.getSpeedTest();
  }

  createForm() {
    this.TestAdd = this.fb.group({
      target: [''],
      protocol: ['tcp'],
      direction: ['down'],
      length: [10],
      rate_limit: [null],
      concurrency: [1],
      payload_size: [1000],
    });
    this.TestAdd.get('payload_size')?.disable();
    this.TestAdd.controls['protocol']?.valueChanges.subscribe((data) => {
      if (data && data == 'tcp') {
        this.TestAdd.get('payload_size')?.disable();
        this.TestAdd.get('concurrency')?.enable();
      } else {
        this.TestAdd.get('payload_size')?.enable();
        this.TestAdd.get('concurrency')?.disable();
      }
    });
    setTimeout(() => {
      let targetValue = '';
      this.mergeArray?.forEach((item: any) => {
        if (item.isBond) targetValue = item.url;
      });
      this.TestAdd.controls['target'].setValue(targetValue)
    }, 2000);
  }

  getSpeedTest() {
    let url = `bonds/${this.bondId}/speedtests/?exclude_fields=progress_results&exclude_fields=url&exclude_fields=agg_version&exclude_fields=bonder_version&exclude_fields=leg&exclude_fields=status&ordering=-id&page=${this.page}&page_size=${this.limit}&search=`;
    this.allSpeedTest = [];
    this.testLength = 0;
    this.isBondSpeedTestListLoader = true;
    this.partnerBondNetworkService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res?.data) {
        this.allSpeedTest = res.data.results;
        this.testLength = res.data.count;
      }
      this.isBondSpeedTestListLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isBondSpeedTestListLoader = false;
      this.cd.detectChanges();
    });
  }

  getAllInterfaces() {
    let url = `bonds/${this.bondId}/interface_legs/`;
    let url2 = `bonds/${this.bondId}/mobile_broadband_legs/`;
    this.preInterfaceLoaderScreen = true;
    combineLatest([
      this.partnerBondNetworkService.getResponse(url, 'GET'),
      this.partnerBondNetworkService.getResponse(url2, 'GET'),
    ]).subscribe(([res, res2]: any[]) => {
      if (res.data) {
        let allLegs = res?.data;
        let mobileLegs = res2?.data;
        allLegs.map((leg: any) => {
          if (leg.aggregator_status?.state == "up" || leg.aggregator_status?.state == "ssl up") {
            let n = { name: "", url: "", isBond: false };
            n.name = "Interface leg " + leg.id + ", " + leg.ifname;
            n.url = leg.url;
            this.mergeArray?.push(n);
            this.cd.detectChanges();
          }
        });
        mobileLegs.map((mobileLeg: any) => {
          if (mobileLeg.aggregator_status?.state == "up" || mobileLeg.aggregator_status?.state == "ssl up") {
            let n = { name: "", url: "", isBond: false };
            if (mobileLeg?.failover == true) n.name = "Mobile Broadband leg " + mobileLeg.id + "," + "failover";
            else n.name = "Mobile Broadband leg " + mobileLeg.id;
            n.url = mobileLeg.url;
            this.mergeArray?.push(n);
            this.cd.detectChanges();
          }
        });
        this.mergeArray?.push({ name: this.bondData.name, url: this.bondData.url, isBond: true });
        this.TestAdd.controls['target'].setValue(this.bondData.url);
      }
      this.preInterfaceLoaderScreen = false;
      this.cd.detectChanges();
    }, (err) => {
      this.preInterfaceLoaderScreen = false;
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  async onSave(type: any) {
    let data: any = {};
    let bond: any = {};
    this.mergeArray.forEach((item: any) => {
      if (item.isBond) bond = item;
    });

    if (type == 'btd') {
      data = {
        target: bond?.url,
        protocol: 'tcp',
        direction: 'down',
        length: 10,
        concurrency: 1,
      }
      this.createSpeedTest(data, true);
    } else if (type == 'btd8') {
      data = {
        target: bond?.url,
        protocol: 'tcp',
        direction: 'down',
        length: 10,
        concurrency: 8,
      }
      this.createSpeedTest(data, true);
    } else if (type == 'btu') {
      data = {
        target: bond?.url,
        protocol: 'tcp',
        direction: 'up',
        length: 10,
        concurrency: 1,
      }
      this.createSpeedTest(data, true);
    } else if (type == 'bpl') {
      data = {
        target: bond?.url,
        protocol: 'udp',
        direction: 'down',
        length: 10,
        payload_size: 1000,
      }
      this.createSpeedTest(data, true);
    } else {
      data = {
        target: this.TestAdd.value.target,
        protocol: this.TestAdd.value.protocol,
        direction: this.TestAdd.value.direction,
        length: this.TestAdd.value['length'],
      };
      if (this.TestAdd.value.rate_limit) data['rate_limit'] = this.TestAdd.value.rate_limit;
      if (this.TestAdd.value.protocol = 'tcp') data['concurrency'] = this.TestAdd.value.concurrency;
      else data['payload_size'] = this.TestAdd.value.payload_size;
      if (this.TestAdd.value.rate_limit) {
        let temp = this.TestAdd.value.rate_limit.split(',');
        let isRedirect = true;
        let i = 0;
        for (const item of temp) {
          await this.createSpeedTestPromise({ ...data, rate_limit: item }, (i == temp.length - 1) ? true : false)
          isRedirect = false;
          i++;
        }
      } else this.createSpeedTest(data, true);
    }
  }

  createSpeedTestPromise(data: any, isRedirect: boolean) {
    return new Promise((resolve, reject) => {
      let reqObject = {
        url: `bonds/${this.bondId}/speedtests/`,
        method: "POST",
        data: data
      }
      this.sharedService.showLoader();
      this.partnerBondNetworkService.addSpeedTest(reqObject).subscribe((res: any) => {
        if (res.code == 201 || res.code == 200) {
          this.sharedService.loggerSuccess(res.message);
          this.sharedService.hideLoader();
          if (isRedirect) this.navigateToView(res.data.id);
        } else {
          this.sharedService.loggerError(res.message);
          this.sharedService.hideLoader();
        }
        return resolve(true);
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors?.non_field_errors);
          else this.sharedService.loggerError(err);
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          return reject(true);
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    });
  }

  createSpeedTest(data: any, isRedirect: boolean) {
    let reqObject = {
      url: `bonds/${this.bondId}/speedtests/`,
      method: "POST",
      data: data
    }
    this.sharedService.showLoader();
    this.partnerBondNetworkService.addSpeedTest(reqObject).subscribe((res: any) => {
      if (res.code == 201 || res.code == 200) {
        this.sharedService.loggerSuccess(res.message);
        this.sharedService.hideLoader();
        if (isRedirect) this.navigateToView(res.data.id);
      } else {
        this.sharedService.loggerError(res.message);
        this.sharedService.hideLoader();
      }
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors?.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  onRepeat(data: any) {
    if (!data) return;
    this.speedTestId = null;
    let payload: any = {
      protocol: data?.protocol,
      direction: data?.direction,
      length: data['length'],
    }
    if (data?.rate_limit) payload['rate_limit'] = data?.rate_limit;
    this.mergeArray.forEach((item: any) => {
      if (item.name == data.target) payload['target'] = item.url;
    });
    if (!payload['target']) {
      if (data?.bond_configuration) {
        payload['target'] = this.bondData.url;
      } else if (data?.leg) {
        payload['target'] = data?.leg;
      } else {
        if (data?.target?.includes('Interface leg')) {
          let temp = data?.target?.split(',');
          let tem1 = temp[0].split(' ');
          if (tem1[2]) payload['target'] = `${this.bondData.url}interface_legs/${tem1[2]}/`;
        } else payload['target'] = this.bondData.url;
      }
    }
    if (data.protocol = 'tcp') payload['concurrency'] = data?.concurrency;
    else payload['payload_size'] = data.payload_size;
    this.createSpeedTest(payload, true);
  }

  receiveMessage(event: any) {
    this.limit = event.pageSize;
    this.page = event.pageIndex;
    this.getSpeedTest();
    this.cd.detectChanges();
  }

  targetChange() {
    this.TestAdd.get('rate_limit')?.enable();
    this.mergeArray.forEach((item: any) => {
      if (this.TestAdd.value.target == item.url && item.isBond) {
        this.TestAdd.controls['rate_limit']?.setValue(null);
        this.TestAdd.get('rate_limit')?.disable();
      }
    });
    this.cd.detectChanges();
  }

  navigateToView(id: number) {
    if (!id) return;
    this.speedTestId = id;
    this.isView = true;
    this.cd.detectChanges();
  }

  navigateToSpeedTest() {
    this.speedTestId = null;
    this.isView = false;
    this.cd.detectChanges();
    this.getSpeedTest();
  }
}


