<div class="modal-header px-5 py-3">
    <h5 class="modal-title">{{ isEdit ? 'Edit CPE NAT IP' : 'Add CPE NAT IP'}} </h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
    <form [formGroup]="configurationForm">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors?.non_field_errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">IP <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The public IPv4 address to assign."></span></label>
                            <input formControlName="ip" type="text" name="ip" [readOnly]="!isSpace && isEdit"
                                class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="errors"> {{errors['ip']?.join(' ')}} </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Destination IP <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The private IPv4 address to associate with the public IP. This IP must be in the
                            same range as a connected IP."></span></label>
                            <input formControlName="dest_nat_ip" type="text" autocomplete="off"
                                class="form-control form-control-lg form-control-solid" name="Destination IP" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="errors"> {{errors['dest_nat_ip']?.join(' ')}} </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label"> Enabled <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Enable the route."></span> </label>
                            <div class="d-flex flex-column mt-2">
                                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                    formControlName="enabled">
                                </ui-switch>
                                <small class="text-danger" *ngIf="errors"> {{errors['enabled']?.join(' ')}} </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> {{ isEdit ? 'Save':'Add' }}
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>