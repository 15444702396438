<div class="card mb-5 border-1 border border-top-0" *ngIf="permissionsObj?.view">
  <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50">
    <div></div>
    <div class="me-2 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
      data-bs-placement="top" data-bs-trigger="hover" title="Add" *ngIf="permissionsObj?.update">
      <button type="button" class="btn btn-primary btn-style" data-bs-toggle="modal" data-bs-target="#addinterface"
        [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? 'disable-tune':''"
        (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? '': onEdit('')">
        <div>
          <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
          </span>
        </div> Add Leg
      </button>
    </div>
  </div>

  <div class="card-body p-2">
    <div class="row">
      <div class="col-md-12 col-lg-12 mb-5 mt-0">
        <div *ngIf="permissionsObj?.view" class="table-responsive border">
          <table class="table table-row-bordered table-row-gray-300 align-middle gs-0 gy-1 mb-0 table-fixed legs-table">
            <thead class="table-light">
              <tr class="fw-bolder text-dark fs-7">
                <th class="py-4"> </th>
                <th class="py-4"> ID </th>
                <th class="py-4">Status </th>
                <th class="py-4"> Last 24 hours </th>
                <th class="py-4">Summary </th>
                <th class="py-4">Interface </th>
                <th class="py-4">Rates </th>
                <th class="py-4">Limits </th>
                <th class="py-4">Address </th>
                <th class="py-4">Note</th>
                <th class="py-4 min-w-50px w-50px text-center"> Actions </th>
              </tr>
            </thead>
            <tbody
              *ngIf="(allLegs.length + allMobileBroadband.length > 0) && !(sharedService.Loader.isLegLoader || sharedService.Loader.isMobileLegLoader)">
              <tr *ngFor="let legs of allLegs;">
                <td class="p-0" colspan="11">
                  <table
                    class="table table-row-bordered table-row-gray-300 align-middle gs-0 gy-2 mb-0 table-fixed legs-table-inner">
                    <tbody>
                      <tr>
                        <td>
                          <button type="button" class="btn px-5" (click)="collapse.toggle()"
                            [attr.aria-expanded]="!legs.isCollapsed" aria-controls="collapseExample">
                            <i class="fa fa-caret-right text-primary"></i>
                          </button>
                        </td>

                        <td> {{ legs.id }} </td>

                        <td class="text-center">
                          <span *ngIf="legs?.aggregator_status && legs.link_mode!='idle'">
                            <a *ngIf="legs?.aggregator_status?.state == 'down'" title="down">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="legs?.aggregator_status?.state == 'pending'" title="pending">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="legs?.aggregator_status?.state == 'ssl init waiting for client'"
                              title="ssl init waiting for client">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/info-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="legs?.aggregator_status?.state == 'unknown'" title="unknown">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="legs?.aggregator_status?.state == 'up' || legs?.aggregator_status?.state == 'ssl up'"
                              title="up">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                          </span>
                          <span *ngIf="!legs?.aggregator_status && legs.link_mode!='idle'">
                            <a title="unknown">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                          </span>
                          <span *ngIf="legs.link_mode=='idle'">
                            <a *ngIf="legs.link_mode == 'idle'" title="idle">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                          </span>

                          <span *ngIf="alertPermission">
                            <a title="enable alert" *ngIf="!legs.isAlertAPI && !legs.isAlert"
                              class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px svg-icon svg-icon-4 br-6"
                              [ngClass]="legs.link_mode != 'active' || !monitorIcon? 'disable-tune': '' "
                              (click)="legs.link_mode != 'active' || !monitorIcon ? '': addMonitor(legs.id, legs?.aggregator_status?.state)">
                              <span [inlineSVG]="'./assets/media/icons/normal/bell-slash.svg'" class=""> </span>
                            </a>
                            <a *ngIf="legs.isAlertAPI"
                              class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px svg-icon svg-icon-4 br-6">
                              <mat-spinner style="height: 16px;width: 16px;"></mat-spinner>
                            </a>
                            <a title="disable alert" *ngIf="!legs.isAlertAPI && legs.isAlert"
                              class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px svg-icon svg-icon-4 br-6"
                              (click)="removeMonitor(legs.id)">
                              <span [inlineSVG]="'./assets/media/icons/normal/bell.svg'" class=""> </span>
                            </a>
                          </span>
                        </td>

                        <td>
                          <span *ngIf="legs?.stateHistory && legs?.stateHistory?.states.length > 0">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" [style.width.%]="state[3]" [ngClass]="state[2] == 'down' ? 'bg-danger':
                                state[2] == 'ssl init waiting for client' ? 'status-ssl-init':
                                (state[2] == 'up' || state[2] == 'ssl init') ? 'bg-success':
                                'bg-secondary'" *ngFor="let state of legs?.stateHistory?.states;"
                                [title]="state[2] + ' for ' +getTextForProgressBar(state[0],state[1])">
                              </div>
                            </div>
                          </span>
                          <span *ngIf="legs?.stateHistory?.states.length <= 0">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                aria-valuemax="100" title="Unknown for 24 hours"></div>
                            </div>
                          </span>
                        </td>
                        <!-- <td *ngIf="legs?.stateHistory?.states[0][2] == 'down'" class="w-150px">
                  <a [title]="getTextForProgressBar(legs?.stateHistory?.states[0][0],legs?.stateHistory?.states[0][1])">
                    <p><ngb-progressbar  [max]="24" type="danger" [value]="getValueForProgressBar(legs?.stateHistory?.states[0][0],legs?.stateHistory?.states[0][1])"></ngb-progressbar></p>
                    </a>
                  </td> -->
                        <!-- <a title="Down"><span
                        [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                        class="svg-icon svg-icon-8 ml-2 me-1">
                      </span></a>  -->
                        <!-- <td class="w-150px">
                    <div class="progress h-8px w-100 p-0 bg-light">
                      <div role="progressbar" class="progress-bar bg-danger" style="width: 70%;">
                      </div></div>
                    </td> -->
                        <td class="fs-7 text-dark">
                          <table class="w-100">
                            <tbody>
                              <tr class="border-0" *ngIf="legs?.link_mode == 'offline'">
                                <td class="border-none"> Offline </td>
                              </tr>
                              <tr class="border-0" *ngIf="legs?.link_mode == 'idle'">
                                <td class="border-none"> Idle </td>
                              </tr>
                              <tr class="border-0" *ngIf="legs.failover">
                                <td class="border-none"> Failover </td>
                              </tr>
                              <tr class="border-0" *ngIf="legs.tunnel_bypass_enabled">
                                <td class="border-none p-0">
                                  <span *ngIf="legs.tunnel_bypass_priority == 1">
                                    <span class="badge badge-light bg-light-primary text-primary-1 mr-2 ml-0 pl-0 ">
                                      Tunnel bypass
                                      <span *ngIf="legs.tunnel_bypass_percentage">
                                        {{ legs.tunnel_bypass_percentage }}%
                                      </span>
                                    </span>
                                  </span>
                                  <span *ngIf="legs.tunnel_bypass_priority != 1">
                                    Tunnel bypass
                                  </span>
                                </td>
                              </tr>
                              <tr class="border-0" *ngIf="legs.bandwidth_adaptation">
                                <td class="border-none p-0"> Bandwidth adaptation
                                </td>
                              </tr>
                              <tr class="border-0" *ngIf="legs.failbackMessage">
                                <td class="border-none p-0">
                                  <span _ngcontent-c30="" class="alert-text d-flex align-items-center"> <span
                                      [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-triangle.svg'"
                                      class="svg-icon svg-icon-5 me-2"></span> {{legs?.failbackMessage}}
                                  </span>
                                </td>
                              </tr>
                              <tr class="border-0" *ngIf="legs.bonder_status?.flapping == 'flapping down'">
                                <td class="border-none">
                                  <span class="badge badge-light bg-light-warning text-warning">Flapping</span>
                                </td>
                              </tr>
                              <tr class="border-0"
                                *ngIf="latest_tuning?.status == 'running' && latest_tuning?.legId == legs?.id">
                                <td class="border-none">
                                  <span class="moving-border-primary">Tuning running
                                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen034.svg'"
                                      class="svg-icon svg-icon-danger svg-icon-5 cursor-pointer" title="Cancel Tuning"
                                      (click)="cancelTuning(legs?.id)"></span>
                                  </span>
                                </td>
                              </tr>
                              <tr class="border-0"
                                *ngIf="latest_tuning?.status == 'tuned' && latest_tuning?.legId == legs?.id">
                                <td class="border-none" (click)="viewTuning(legs?.id)">
                                  <span class="cursor-pointer moving-border-success">Tuning completed
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td> {{ legs.ifname }} </td>

                        <td class="fs-7">
                          <span class="d-flex">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-dark">
                            </span>
                            <span>
                              {{calculateBytes(legs?.aggregator_status?.tx_rate)}}
                            </span>
                          </span>
                          <span class="d-flex">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-dark">
                            </span>
                            <span>
                              {{calculateBytes(legs?.aggregator_status?.rx_rate)}}
                            </span>
                          </span>
                        </td>
                        <!--<td class="min-w-100px"> <span class="btn btn-sm btn-light btn-color-muted d-block">  {{ legs.x_rate }} </span> <span class="btn btn-sm btn-light btn-color-muted d-block"> {{ legs.rx_rate }} </span> </td>-->

                        <td class="fs-7">
                          <span
                            [ngClass]="(legs?.aggregator_status?.state == 'up' || legs?.aggregator_status?.state == 'ssl up') ? 'badge badge-light-success':'badge badge-light-success status-down'"
                            class="mb-1">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-success">
                            </span> {{ legs.down_speed.toFixed(1) }} Mbps
                          </span>
                          <span
                            [ngClass]="(legs?.aggregator_status?.state == 'up' || legs?.aggregator_status?.state == 'ssl up') ? 'badge badge-light-success':'badge badge-light-success status-down'">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-success">
                            </span> {{ legs.up_speed.toFixed(1) }} Mbps
                          </span>
                        </td>

                        <td>
                          <table class="w-100 fs-7 text-dark">
                            <tbody>
                              <tr class="border-0" *ngIf="!sharedService.Loader.isDHCPLegLoader">
                                <td colspan="2" *ngIf="legs.dhcpData && legs.dhcpData?.enabled" class="p-0">
                                  <span> DHCP </span>
                                  <span *ngIf="legs?.dhcpData?.bonder_status?.address" class="text-muted">
                                    {{legs?.dhcpData?.bonder_status.address }}
                                  </span>
                                  <span *ngIf="!legs?.dhcpData?.bonder_status?.address" class="text-muted">
                                    No DHCP lease.
                                  </span>
                                  <!-- <span class="d-block" *ngIf="legs.dhcp_addressings?.length != 0 && legs.dhcpData?.length != 0 && legs?.bonder_status == null">  No DHCP lease </span>
                            <span *ngIf="legs.dhcp_addressings?.length != 0 && legs.dhcpData?.length != 0 && legs?.bonder_status !== null">  {{legs?.bonder_status?.address}} </span> -->
                                </td>
                              </tr>
                              <tr class="shine border-0" style="width: 120px;height: 12px;"
                                *ngIf="sharedService.Loader.isDHCPLegLoader">
                              </tr>

                              <tr class="border-0" *ngIf="!sharedService.Loader.isAutoipv6LegLoader">
                                <td colspan="2" *ngIf="legs?.autov6Data && legs?.autov6Data?.enabled" class="p-0">
                                  <span> Auto IPv6 </span>
                                  <span *ngIf="legs?.autov6Data?.bonder_status?.address" class="text-muted">
                                    {{legs.autov6Data?.bonder_status?.address }}
                                  </span>
                                  <span *ngIf="!legs?.autov6Data?.bonder_status?.address" class="text-muted">
                                    Status unknown.
                                  </span>
                                  <!-- <span class="d-block" *ngIf="legs.auto_ipv6_addressings?.length != 0 && legs.autov6Data?.length != 0 && legs?.bonder_status == null">Status unknown. </span>
                          <span *ngIf="legs.auto_ipv6_addressings?.length != 0 && legs.autov6Data?.length != 0 && legs?.bonder_status !== null">  {{legs?.bonder_status?.address}} </span> -->
                                </td>
                              </tr>
                              <tr class="shine border-0" style="width: 120px;height: 12px;"
                                *ngIf="sharedService.Loader.isAutoipv6LegLoader">
                              </tr>

                              <tr class="border-0" *ngIf="!sharedService.Loader.isPPPOELegLoader">
                                <td colspan="2" *ngIf="legs?.pppoeData && legs?.pppoeData?.enabled" class="p-0"> <span>
                                    PPPoE </span>
                                  <span class="text-muted" *ngIf="!legs.pppoeData?.bonder_status?.address4 &&
                                    !legs?.pppoeData?.bonder_status?.address6">
                                    Never authenticated to PPP. </span>
                                  <span class="text-muted" *ngIf="legs?.pppoeData?.ip_version_preference == '4' &&
                                    legs.pppoeData?.bonder_status?.address4">
                                    {{legs?.pppoeData?.bonder_status?.address4 }}
                                  </span>
                                  <span class="text-muted" *ngIf="legs?.pppoeData?.ip_version_preference == '6' &&
                                    legs.pppoeData?.bonder_status?.address6">
                                    {{legs?.pppoeData?.bonder_status?.address6 }}
                                  </span>
                                  <span class="text-muted" *ngIf="legs?.pppoeData?.ip_version_preference == '46' &&
                                    legs.pppoeData?.bonder_status?.address6 &&
                                    legs?.pppoeData?.bonder_status?.address4">
                                    {{legs?.pppoeData?.bonder_status?.address4 || '-'}},
                                    {{legs?.pppoeData?.bonder_status?.address6 || '-'}}
                                  </span>
                                </td>
                              </tr>
                              <tr class="shine border-0" style="width: 120px;height: 12px;"
                                *ngIf="sharedService.Loader.isPPPOELegLoader">
                              </tr>

                              <tr class="border-0" *ngFor="let static of legs?.staticData;">
                                <td colspan="2" class="p-0" *ngIf="static && static.enabled">
                                  <span> Static </span>
                                  <span>
                                    <span *ngIf="static.bonder_status?.address" class="text-muted">
                                      {{static.bonder_status?.address }}
                                    </span>
                                    <span *ngIf="!static.bonder_status" class="text-muted">
                                      No status.
                                    </span>
                                  </span>
                                  <!-- <span class="d-block" *ngIf="legs.static_addressings?.length != 0 && legs.staticData?.length != 0 && legs?.bonder_status == null"> No status. </span>
                          <span *ngIf="legs.static_addressings?.length != 0 && legs.staticData?.length != 0 && legs?.bonder_status !== null">  {{legs?.bonder_status?.address}} </span> -->
                                </td>
                              </tr>
                              <tr class="shine border-0" style="width: 120px;height: 12px;"
                                *ngIf="sharedService.Loader.isStaticLegLoader">
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{{ legs.note }} </td>

                        <td class="text-center min-w-50px w-50px">
                          <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                            *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                            class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px position-relative">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                              class="svg svg-icon svg-transparent">
                            </span>
                            <span class="icon-badge" style="margin-left: 26px;margin-top: -26px;"
                              *ngIf="latest_tuning?.status == 'tuned' && latest_tuning?.legId == legs?.id">
                              <div class="badge-circle-icon pulsate"></div>
                            </span>
                          </a>
                          <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                            class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                              class="svg svg-icon svg-transparent">
                            </span>
                          </a>
                          <mat-menu #actionMenu="matMenu">
                            <button mat-menu-item class="menu-list-block" title="Edit" *ngIf="permissionsObj.update"
                              [ngClass]="(bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                              (click)="(bondPermission && bondPermission.isUpdateDisable) ? '': onEdit(legs)">
                              <div class="clone-icon">
                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                  class="svg svg-icon svg-icon-warning">
                                </span>
                              </div>
                              <span>Edit</span>
                            </button>
                            <button mat-menu-item class="menu-list-block" title="Delete" *ngIf="permissionsObj.delete"
                              [ngClass]="(bondPermission && bondPermission.isUpdateDisable)  ? 'disable-tune':''"
                              (click)="(bondPermission && bondPermission.isUpdateDisable) ? '': onDelete(legs?.id,legs?.ifname, legs, false)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg svg-icon svg-icon-danger">
                                </span>
                              </div>
                              <span>Delete</span>
                            </button>
                            <button mat-menu-item class="menu-list-block" (click)="getDetectMTUs(legs?.id, legs.type)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen004.svg'"
                                  class="svg svg-icon svg-icon-info">
                                </span>
                              </div>
                              <span> Detect MTUs</span>
                            </button>
                            <button mat-menu-item class="menu-list-block" (click)="getDetectSpeed(legs?.id, legs.type)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen002.svg'"
                                  class="svg svg-icon svg-icon-info">
                                </span>
                              </div>
                              <span> Detect Speed</span>
                            </button>
                            <button mat-menu-item class="menu-list-block" (click)="viewTuning(legs?.id)"
                              *ngIf="latest_tuning?.status == 'tuned' && latest_tuning?.legId == legs?.id">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen063.svg'"
                                  class="svg svg-icon svg-icon-3 svg-icon-warning">
                                </span>
                                <span class="icon-badge" style="margin-top: -6px; margin-left: -6px;">
                                  <div class="badge-circle-icon pulsate"></div>
                                </span>
                              </div>
                              <span> View Recommendations</span>
                            </button>
                          </mat-menu>
                          <!-- <li class="menu-item list-style-none">
                            <span class="menu-link px-5" (click)="getDHCPLease(legs?.dhcpData)"
                              *ngIf="legs?.dhcpData">
                              Renew DHCP lease </span>
                          </li> -->
                        </td>
                      </tr>

                      <tr class="align-top" #collapse="ngbCollapse" [(ngbCollapse)]="legs.isCollapsed">
                        <td class="px-3 py-0" colspan="6" *ngIf="legs.bonder_status">
                          <table
                            class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-2 w-100 fs-7 text-dark">
                            <thead>
                              <th class="card-label fw-bolder fs-4 mb-1 ps-0" colspan="3"> Leg status </th>
                            </thead>
                            <tbody class="border-none">
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Latency </td>
                                <td> {{(legs?.bonder_status?.detected_latency * 1000).toFixed(1)}}ms </td>
                                <td>
                                  {{calculateDifferenceOfDate(legs?.bonder_status?.detected_latency_updated)}} </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Jitter </td>
                                <td> {{(legs?.bonder_status?.detected_latency_stdev * 1000).toFixed(1)}}ms
                                </td>
                                <td>
                                  {{calculateDifferenceOfDate(legs?.bonder_status?.detected_latency_updated)}} </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Path MTU </td>
                                <td>{{legs?.bonder_status?.path_mtu}} </td>
                                <td> {{calculateDifferenceOfDate(legs?.bonder_status?.path_mtu_updated)}} </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Packet loss </td>
                                <td>
                                  <span class="fs-7 text-dark fw-bold">
                                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                                      class="svg-icon svg-icon-5 svg-icon-danger"></span>
                                    {{(legs?.aggregator_status?.detected_packet_loss*100).toFixed(1)}} %
                                  </span>
                                  <span class="fs-7 text-dark fw-bold">
                                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                                      class="svg-icon svg-icon-5 svg-icon-success" style="height: 10px;"></span>
                                    {{(legs?.bonder_status?.detected_packet_loss*100).toFixed(1)}} %
                                  </span>
                                </td>
                                <td>
                                  {{calculateDifferenceOfDate(legs?.bonder_status?.detected_packet_loss_updated)}} </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Local IP </td>
                                <td> {{legs?.bonder_status?.bound_tunnel_ip}} </td>
                                <td>
                                  {{calculateDifferenceOfDate(legs?.bonder_status?.bound_tunnel_ip_updated)}}</td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> NAT IP </td>
                                <td> {{legs?.aggregator_status?.connected_tunnel_ip}} </td>
                                <td>
                                  {{calculateDifferenceOfDate(legs?.aggregator_status?.connected_tunnel_ip_updated)}}
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Tunnel port </td>
                                <td> {{legs?.tunnel_port}} </td>
                                <td> </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td class="px-3 py-0" colspan="6" *ngIf="!legs.bonder_status">
                          The bonder has not reported status for this leg.
                        </td>
                        <!--<td class="datatable-detail" colspan="6">
                        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <thead> <th> Leg status </th></thead>
                                  <tbody> <tr> <td> Latency </td> <td> {{ legs.bonder_status.detected_latency }}  </td>  <td> Not found </td> </tr>
                                  <tr> <td> Jitter </td> <td> Jitter </td>  <td> Not found </td> </tr>
                                  <tr> <td> Path MTU </td> <td> {{ legs.bonder_status.path_mtu }} </td>  <td>Not found </td> </tr>
                                  <tr> <td> Packet loss </td> <td> {{ legs.bonder_status.detected_latency }} </td>  <td> Not found </td> </tr>
                                  <tr> <td> Local IP </td> <td> 1{{ legs.bonder_status.bound_tunnel_ip }} </td>  <td> Not found </td> </tr>
                                  <tr> <td> NAT IP </td> <td> {{ legs.bonder_status.connected_tunnel_ip }} </td>  <td> Not found </td> </tr>
                                  <tr> <td> Tunnel port </td> <td> {{ legs.bonder_status.tunnel_port }} </td>  <td>  </td> </tr>
                            </tbody>
                          </table>
                  </td>-->
                        <td class="px-3 py-0" colspan="5" *ngIf="legs?.dhcpData || legs?.autov6Data ||
                          legs?.pppoeData || legs?.staticData">
                          <table class="table align-middle gs-3 gy-2 w-100">
                            <thead>
                              <th class="card-label fw-bolder fs-4 mb-1 ps-0"> Addresses </th>
                            </thead>
                            <tbody>
                              <tr *ngIf="legs?.dhcpData" class="border-0">
                                <td colspan="5" class="align-top px-0 pt-0">
                                  <table class="border border-1 table-row-gray-300 align-top w-100">
                                    <thead>
                                      <tr class="fw-bolder text-muted"
                                        [ngClass]="legs?.dhcpData?.bonder_status && legs?.dhcpData?.enabled ? 'bg-light-success': 'bg-light'">
                                        <td colspan="2" class="ps-3" *ngIf="legs?.dhcpData?.enabled"
                                          [ngClass]="legs?.dhcpData?.bonder_status && legs?.dhcpData?.enabled ? 'text-success': ''">
                                          DHCP </td>
                                        <td colspan="2" class="ps-3" *ngIf="!legs?.dhcpData?.enabled"
                                          [ngClass]="legs?.dhcpData?.bonder_status && legs?.dhcpData?.enabled ? 'text-success': ''">
                                          DHCP (Disabled)</td>
                                        <td colspan="2" class="text-end"
                                          [ngClass]="legs?.dhcpData?.bonder_status && legs?.dhcpData?.enabled ? 'text-success': ''">
                                          ID {{legs?.dhcpData?.id}} </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="ps-5">
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder text-dark fs-7">Address </span>
                                            <span *ngIf="!legs?.dhcpData?.bonder_status?.address">
                                              - </span>
                                            <span *ngIf="legs?.dhcpData?.bonder_status?.address">
                                              {{legs?.dhcpData?.bonder_status?.address}}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder text-dark fs-7">
                                              Router </span>
                                            <span *ngIf="!legs?.dhcpData?.bonder_status?.router">
                                              - </span>
                                            <span *ngIf="legs.dhcpData?.bonder_status?.router">
                                              {{legs.dhcpData?.bonder_status.router}}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder text-dark fs-7"> MTU </span>
                                            <span *ngIf="!legs.dhcpData?.bonder_status?.mtu">—</span>
                                            <span *ngIf="legs.dhcpData?.bonder_status?.mtu">
                                              {{legs?.dhcpData?.bonder_status?.mtu}}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder text-dark fs-7">
                                              Updated </span>
                                            <span *ngIf="!legs?.dhcpData?.bonder_status?.updated">
                                              - </span>
                                            <span *ngIf="legs?.dhcpData?.bonder_status?.updated">
                                              {{calculateDifferenceOfDate(legs?.dhcpData?.bonder_status?.updated)}}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="ps-5">
                                          <div class="d-flex flex-column"><span class="fw-bolder text-dark fs-7"> Domain
                                            </span>
                                            <span *ngIf="!legs.dhcpData?.bonder_status?.domain">—</span>
                                            <span *ngIf="legs?.dhcpData?.bonder_status?.domain">
                                              {{legs?.dhcpData?.bonder_status?.domain}}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder text-dark fs-7">
                                              Lease time </span>
                                            <span *ngIf="!legs?.dhcpData?.bonder_status?.lease">—</span>
                                            <span *ngIf="legs?.dhcpData?.bonder_status?.lease">
                                              {{legs?.dhcpData?.bonder_status?.lease}}
                                              seconds
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                              <tr *ngIf="legs?.autov6Data" class="border-0">
                                <td colspan="5" class="align-top px-0">
                                  <table class="border border-1 table-row-gray-300 align-top w-100">
                                    <thead>
                                      <tr class="fw-bolder text-muted"
                                        [ngClass]="legs?.autov6Data?.bonder_status && legs?.autov6Data?.enabled ? 'bg-light-success': 'bg-light'">
                                        <td colspan="2" class="ps-3" *ngIf="legs?.autov6Data?.enabled"
                                          [ngClass]="legs?.autov6Data?.bonder_status && legs?.autov6Data?.enabled ? 'text-success': ''">
                                          Auto IPv6 </td>
                                        <td colspan="2" class="ps-3" *ngIf="!legs?.autov6Data?.enabled"
                                          [ngClass]="legs?.autov6Data?.bonder_status && legs?.autov6Data?.enabled ? 'text-success': ''">
                                          Auto IPv6 (Disabled) </td>
                                        <td colspan="2" class="text-end"
                                          [ngClass]="legs?.autov6Data?.bonder_status && legs?.autov6Data?.enabled ? 'text-success': ''">
                                          ID {{legs?.autov6Data?.id}}
                                        </td>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr>
                                        <td class="ps-5">
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark">Address</span>
                                            <span *ngIf="!legs?.autov6Data?.bonder_status?.address">
                                              - </span>
                                            <span *ngIf="legs?.autov6Data?.bonder_status?.address">
                                              {{legs?.autov6Data?.bonder_status?.address }}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder fs-7 text-dark">
                                              Router </span>
                                            <span *ngIf="!legs.autov6Data?.bonder_status?.router">
                                              - </span>
                                            <span *ngIf="legs.autov6Data?.bonder_status?.router">
                                              {{legs.autov6Data?.bonder_status.router }}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder fs-7 text-dark">
                                              Updated </span>
                                            <span *ngIf="!legs?.autov6Data?.bonder_status?.updated">
                                              - </span>
                                            <span *ngIf="legs?.autov6Data?.bonder_status?.updated">
                                              {{calculateDifferenceOfDate(legs?.autov6Data?.bonder_status?.updated)}}
                                            </span>
                                          </div>
                                        </td>
                                    </tbody>
                                  </table>
                                </td>

                              </tr>

                              <tr *ngIf="legs?.pppoeData" class="border-0">
                                <td colspan="5" class="align-top px-0">
                                  <table class="border border-1 table-row-gray-300 align-top w-100">
                                    <thead>
                                      <tr class="fw-bolder text-muted"
                                        [ngClass]=" legs?.pppoeData?.bonder_status?.error ? 'bg-light-danger' :
                                        legs?.pppoeData?.bonder_status && legs?.pppoeData?.enabled ? 'bg-light-success': 'bg-light'">
                                        <td colspan="2" class="ps-3" *ngIf="legs?.pppoeData?.enabled"
                                          [ngClass]=" legs?.pppoeData?.bonder_status?.error ? 'text-danger' :
                                        legs?.pppoeData?.bonder_status && legs?.pppoeData?.enabled ? 'text-success': ''">
                                          PPPoE </td>
                                        <td colspan="2" class="ps-3 text-danger" *ngIf="!legs?.pppoeData?.enabled"
                                          [ngClass]=" legs?.pppoeData?.bonder_status?.error ? 'text-danger' :
                                        legs?.pppoeData?.bonder_status && legs?.pppoeData?.enabled ? 'text-success': ''">
                                          PPPoE (Disabled) </td>
                                        <td colspan="3" class="text-end text-danger"
                                          [ngClass]=" legs?.pppoeData?.bonder_status?.error ? 'text-danger' :
                                        legs?.pppoeData?.bonder_status && legs?.pppoeData?.enabled ? 'text-success': ''">
                                          ID {{legs?.pppoeData?.id}}</td>
                                      </tr>
                                    </thead>
                                    <tbody *ngIf="legs?.pppoeData?.bonder_status?.error">
                                      <tr>
                                        <td class="ps-5 w-64">
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark">Error </span>
                                            <span class="pe-4 w-50">
                                              {{legs?.pppoeData?.bonder_status?.error}}
                                            </span>
                                          </div>
                                        </td>
                                        <td class="w-20 align-top">
                                          <div class="d-flex flex-column"> <span class="fw-bolder fs-7 text-dark">
                                              Updated </span>
                                            <span *ngIf="!legs?.pppoeData?.bonder_status?.updated">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.bonder_status?.updated">
                                              {{calculateDifferenceOfDate(legs?.pppoeData?.bonder_status?.updated)}}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody *ngIf="!legs?.pppoeData?.bonder_status?.error">
                                      <tr>
                                        <td class="ps-5">
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark">Address </span>
                                            <span *ngIf="!legs.pppoeData?.bonder_status?.address4 &&
                                              !legs?.pppoeData?.bonder_status?.address6">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference == '4' &&
                                              legs.pppoeData?.bonder_status?.address4">
                                              {{legs?.pppoeData?.bonder_status?.address4 }}
                                            </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference == '6' &&
                                              legs.pppoeData?.bonder_status?.address6">
                                              {{legs?.pppoeData?.bonder_status?.address6 }}
                                            </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference == '46' &&
                                              legs.pppoeData?.bonder_status?.address6 &&
                                              legs?.pppoeData?.bonder_status?.address4">
                                              {{legs?.pppoeData?.bonder_status?.address4 || '-'}},
                                              {{legs?.pppoeData?.bonder_status?.address6 || '-'}}
                                            </span>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder fs-7 text-dark">
                                              Remote IP </span>
                                            <span *ngIf="!legs.pppoeData?.bonder_status?.remote_ip &&
                                              !legs?.pppoeData?.bonder_status?.remote_ip6">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference == '4' &&
                                              legs.pppoeData?.bonder_status?.remote_ip">
                                              {{legs?.pppoeData?.bonder_status?.remote_ip }}
                                            </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference == '6' &&
                                              legs.pppoeData?.bonder_status?.remote_ip6">
                                              {{legs?.pppoeData?.bonder_status?.remote_ip6}}
                                            </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference == '46' &&
                                              legs.pppoeData?.bonder_status?.remote_ip6 &&
                                              legs?.pppoeData?.bonder_status?.remote_ip">
                                              {{legs?.pppoeData?.bonder_status?.remote_ip || '-' }},
                                              {{legs?.pppoeData?.bonder_status?.remote_ip6 || '-' }}
                                            </span>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark"> MAC </span>
                                            <span *ngIf="!legs?.pppoeData?.bonder_status?.remote_mac">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.bonder_status?.remote_mac">
                                              {{legs?.pppoeData?.bonder_status?.remote_mac }}
                                            </span>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder fs-7 text-dark">
                                              Updated </span>
                                            <span *ngIf="!legs?.pppoeData?.bonder_status?.updated">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.bonder_status?.updated">
                                              {{calculateDifferenceOfDate(legs?.pppoeData?.bonder_status?.updated)}}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder fs-7 text-dark">
                                              Username </span>
                                            <span *ngIf="!legs?.pppoeData?.username">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.username">
                                              {{legs?.pppoeData?.username}}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column"> <span class="fw-bolder fs-7 text-dark">
                                              Password </span>
                                            <span *ngIf="!legs?.pppoeData?.password">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.password">
                                              {{legs?.pppoeData?.password}}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark"> IP version </span>
                                            <span *ngIf="!legs?.pppoeData?.ip_version_preference">
                                              - </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference &&
                                              legs?.pppoeData?.ip_version_preference == '46'">
                                              IPv4 or IPv6
                                            </span>
                                            <span *ngIf="legs?.pppoeData?.ip_version_preference &&
                                              legs?.pppoeData?.ip_version_preference != '46' ">
                                              IPv{{legs?.pppoeData?.ip_version_preference}}
                                              only
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                              <tr class="border-0" *ngFor="let static of legs?.staticData;">
                                <td colspan="5" class="align-top px-0" *ngIf="static">
                                  <table class="border border-1 table-row-gray-300 align-top w-100">
                                    <thead>
                                      <tr class="fw-bolder text-muted "
                                        [ngClass]="static.bonder_status && static.enabled ? 'bg-light-success': 'bg-light'">
                                        <td colspan="2" class="ps-3" *ngIf="static.enabled"
                                          [ngClass]="static.bonder_status && static.enabled ? 'text-success': ''">
                                          Static </td>
                                        <td colspan="2" class="ps-3" *ngIf="!static.enabled"
                                          [ngClass]="static.bonder_status && static.enabled? 'text-success': ''">
                                          Static (Disabled) </td>
                                        <td colspan="2" class="text-end"
                                          [ngClass]="static.bonder_status && static.enabled ? 'text-success': ''">
                                          ID {{static.id}}</td>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr>
                                        <td class="ps-5">
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark">
                                              {{static.bonder_status && static.enabled
                                              ? 'Address' : 'Configured address'}} </span>
                                            <span *ngIf="!static?.ip"> - </span>
                                            <span *ngIf="static?.ip"> {{static?.ip }} </span>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark">
                                              {{static.bonder_status && static.enabled
                                              ? 'Router' : 'Configured gateway'}} </span>
                                            <span *ngIf="!static?.gateway"> - </span>
                                            <span *ngIf="static?.gateway"> {{static?.gateway }} </span>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="d-flex flex-column">
                                            <span class="fw-bolder fs-7 text-dark"> Updated </span>
                                            <span *ngIf="!static.bonder_status?.updated"> - </span>
                                            <span *ngIf="static.bonder_status?.updated">
                                              {{calculateDifferenceOfDate(static.bonder_status?.updated)}}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <!-- mobile broadband start-->
              <tr *ngFor="let mobile of allMobileBroadband;">
                <td colspan="11" class="p-0">
                  <table
                    class="table table-row-bordered table-row-gray-300 align-middle gs-0 gy-2 mb-0 table-fixed legs-table-inner">
                    <tbody>
                      <tr>

                        <td>
                          <button type="button" class="btn px-5" (click)="collapse.toggle()"
                            [attr.aria-expanded]="!mobile.isCollapsed" aria-controls="collapseExample">
                            <i class="fa fa-caret-right text-primary"></i>
                          </button>
                        </td>

                        <td> {{ mobile.id }} </td>

                        <td class="text-center">
                          <span *ngIf="mobile?.aggregator_status && mobile.link_mode!='idle'">
                            <a *ngIf="mobile?.aggregator_status?.state == 'down'" title="down">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="mobile?.aggregator_status?.state == 'pending'" title="pending">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="mobile?.aggregator_status?.state == 'ssl init waiting for client'"
                              title="ssl init waiting for client">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/info-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="mobile?.aggregator_status?.state == 'up'" title="up">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="mobile?.aggregator_status?.state == 'ssl up'" title="ssl up">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                            <a *ngIf="mobile?.aggregator_status?.state == 'unknown'" title="unknown">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                          </span>
                          <span *ngIf="!mobile?.aggregator_status && mobile.link_mode!='idle'">
                            <a title="unknown">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                          </span>
                          <span *ngIf="mobile?.link_mode=='idle'">
                            <a *ngIf="mobile?.link_mode == 'idle'" title="idle">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                              </span>
                            </a>
                          </span>
                          <span *ngIf="alertPermission">
                            <a title="enable alert" *ngIf="!mobile.isAlertAPI && !mobile.isAlert"
                              class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px svg-icon svg-icon-4"
                              [ngClass]="mobile.link_mode != 'active'  || !monitorIcon? 'disable-tune': ''"
                              (click)="mobile.link_mode != 'active'  || !monitorIcon ? '':addMonitor(mobile.id, mobile?.aggregator_status?.state)">
                              <span [inlineSVG]="'./assets/media/icons/normal/bell-slash.svg'">
                              </span>
                            </a>
                            <a *ngIf="mobile.isAlertAPI"
                              class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px svg-icon svg-icon-4 br-6">
                              <mat-spinner style="height: 16px;width: 16px;"></mat-spinner>
                            </a>
                            <a title="disable alert" *ngIf="!mobile.isAlertAPI && mobile.isAlert"
                              class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px svg-icon svg-icon-4"
                              (click)="removeMonitor(mobile.id)">
                              <span [inlineSVG]="'./assets/media/icons/normal/bell.svg'">
                              </span>
                            </a>
                          </span>
                        </td>

                        <td>
                          <span *ngIf="mobile?.stateHistory && mobile?.stateHistory?.states.length > 0">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" [style.width.%]="state[3]" [ngClass]="state[2] == 'down' ? 'bg-danger':
                                state[2] == 'ssl init waiting for client' ? 'status-ssl-init':
                                (state[2] == 'up' || state[2] == 'ssl init') ? 'bg-success':
                                'bg-secondary'" *ngFor="let state of mobile?.stateHistory?.states;"
                                [title]="state[2] + ' for ' +getTextForProgressBar(state[0],state[1])">
                              </div>
                            </div>
                          </span>
                          <span *ngIf="mobile?.stateHistory?.states.length <= 0">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                aria-valuemax="100" title="Unknown for 24 hours">
                              </div>
                            </div>
                          </span>
                        </td>

                        <td>
                          <table>
                            <tbody>
                              <tr class="border-0">
                                <td *ngIf="mobile?.aggregator_status?.state == 'ssl init'" class="border-none">
                                  SSL initializing. </td>
                              </tr>
                              <tr class="border-0">
                                <td *ngIf="mobile?.aggregator_status?.state == 'unknown'" class="border-none">
                                  Offline
                                </td>
                              </tr>
                              <tr class="border-0">
                                <td *ngIf="mobile?.failover" class="border-none">
                                  Failover </td>
                              </tr>
                              <tr class="border-0">
                                <td *ngIf="mobile?.bandwidth_adaptation" class="border-none p-0">
                                  Bandwidth adaptation
                                </td>
                              </tr>
                              <tr class="border-0">
                                <td *ngIf="mobile?.tunnel_bypass_enabled" class="border-none p-0">
                                  Tunnel bypass </td>
                              </tr>
                              <tr class="border-0">
                                <td *ngIf="!mobile.bonder_status" class="border-none p-0"> Bonder has
                                  not reported status for this leg.
                                </td>
                              </tr>
                              <tr class="border-0">
                                <td *ngIf="mobile.provider_name" class="border-none p-0">
                                  profile: {{mobile.provider_name}}
                                </td>
                              </tr>
                              <tr class="border-0"
                                *ngIf="latest_tuning?.status == 'running' && latest_tuning?.legId == mobile?.id">
                                <td class="border-none">
                                  <span class="badge badge-light-primary">Tuning running
                                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen034.svg'"
                                      class="svg-icon svg-icon-danger svg-icon-5 cursor-pointer" title="Cancel Tuning"
                                      (click)="cancelTuning(mobile?.id)"></span>
                                  </span>
                                </td>
                              </tr>
                              <tr class="border-0"
                                *ngIf="latest_tuning?.status == 'tuned' && latest_tuning?.legId == mobile?.id">
                                <td class="border-none" (click)="viewTuning(mobile?.id)">
                                  <span class="cursor-pointer moving-border">Tuning completed
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td> {{ mobile?.type }} </td>
                        <td>
                          <span class="d-flex">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-dark">
                            </span>
                            <span>
                              {{calculateBytes(mobile?.aggregator_status?.tx_rate)}}
                            </span>
                          </span>
                          <span class="d-flex">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-dark">
                            </span>
                            <span>
                              {{calculateBytes(mobile?.aggregator_status?.rx_rate)}}
                            </span>
                          </span>
                        </td>

                        <td>
                          <span
                            [ngClass]="(mobile?.aggregator_status?.state == 'up' || mobile?.aggregator_status?.state == 'ssl up') ? 'badge badge-light-success':'badge badge-light-success status-down'"
                            class="mb-1">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-success">
                            </span> {{ mobile?.down_speed?.toFixed(1) }} Mbps
                          </span>
                          <span
                            [ngClass]="(mobile?.aggregator_status?.state == 'up' || mobile?.aggregator_status?.state == 'ssl up') ? 'badge badge-light-success':'badge badge-light-success status-down'">
                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                              class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-success">
                            </span> {{ mobile?.up_speed?.toFixed(1) }} Mbps
                          </span>
                        </td>
                        <td> </td>
                        <td>{{ mobile?.note }} </td>
                        <td class="text-center min-w-50px w-50px">
                          <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                            *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                            class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                              class="svg svg-icon svg-transparent">
                            </span>
                            <span class="icon-badge" style="margin-left: 26px;margin-top: -26px;"
                              *ngIf="latest_tuning?.status == 'tuned' && latest_tuning?.legId == mobile?.id">
                              <div class="badge-circle-icon pulsate"></div>
                            </span>
                          </a>
                          <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                            class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                              class="svg svg-icon svg-transparent">
                            </span>
                          </a>
                          <mat-menu #actionMenu="matMenu">
                            <button mat-menu-item class="menu-list-block" title="Edit" *ngIf="permissionsObj.update"
                              [ngClass]="(bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                              (click)="(bondPermission && bondPermission.isUpdateDisable) ? '': onEdit(mobile)">
                              <div class="clone-icon">
                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                  class="svg svg-icon svg-icon-warning">
                                </span>
                              </div>
                              <span>Edit</span>
                            </button>
                            <button mat-menu-item class="menu-list-block" title="Delete" *ngIf="permissionsObj.delete"
                              [ngClass]="(bondPermission && bondPermission.isUpdateDisable)  ? 'disable-tune':''"
                              (click)="(bondPermission && bondPermission.isUpdateDisable) ? '': onDelete(mobile?.id,mobile?.type, mobile, true)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg svg-icon svg-icon-danger">
                                </span>
                              </div>
                              <span>Delete</span>
                            </button>
                            <button mat-menu-item class="menu-list-block"
                              (click)="getDetectMTUs(mobile?.id, mobile.type)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen004.svg'"
                                  class="svg svg-icon svg-icon-info">
                                </span>
                              </div>
                              <span> Detect MTUs</span>
                            </button>
                            <button mat-menu-item class="menu-list-block"
                              (click)="getDetectSpeed(mobile?.id, mobile.type)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen002.svg'"
                                  class="svg svg-icon svg-icon-info">
                                </span>
                              </div>
                              <span> Detect Speed</span>
                            </button>
                            <button mat-menu-item class="menu-list-block" (click)="viewTuning(mobile?.id)"
                              *ngIf="latest_tuning?.status == 'tuned' && latest_tuning?.legId == mobile?.id">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen063.svg'"
                                  class="svg svg-icon svg-icon-3 svg-icon-warning">
                                </span>
                                <span class="icon-badge" style="margin-top: -6px; margin-left: -6px;">
                                  <div class="badge-circle-icon pulsate"></div>
                                </span>
                              </div>
                              <span> View Recommendations</span>
                            </button>
                          </mat-menu>
                          <!-- <li class="menu-item list-style-none">
                            <span class="menu-link px-5" (click)="getDetectSpeed(mobile?.id, mobile.type)"> Detect
                              speeds </span>
                          </li> -->
                        </td>
                      </tr>
                      <tr class="datatable-row-detail align-top" #collapse="ngbCollapse"
                        [(ngbCollapse)]="mobile.isCollapsed">
                        <td class="datatable-detail border" colspan="6" *ngIf="mobile.bonder_status">
                          <table
                            class="table table-row-dashed table-row-gray-300 align-middle gs-5 gy-2 w-100 fs-7 text-dark">
                            <thead>
                              <th class="card-label fw-bolder fs-4 mb-1 ps-0" colspan="3"> Leg status </th>
                            </thead>
                            <tbody class="border-none">
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Latency </td>
                                <td> {{(mobile?.bonder_status?.detected_latency * 1000).toFixed(1)}}ms </td>
                                <td>
                                  {{calculateDifferenceOfDate(mobile?.bonder_status?.detected_latency_updated)}} </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Jitter </td>
                                <td> {{(mobile?.bonder_status?.detected_latency_stdev * 1000).toFixed(1)}}ms
                                </td>
                                <td>
                                  {{calculateDifferenceOfDate(mobile?.bonder_status?.detected_latency_updated)}} </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Path MTU </td>
                                <td>{{mobile?.bonder_status?.path_mtu}} </td>
                                <td> {{calculateDifferenceOfDate(mobile?.bonder_status?.path_mtu_updated)}} </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Packet loss </td>
                                <td>
                                  <span class="fs-7 text-dark fw-bold">
                                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                                      class="svg-icon svg-icon-5 svg-icon-danger"></span>
                                    {{(mobile?.aggregator_status?.detected_packet_loss*100).toFixed(1)}} %
                                  </span>
                                  <span class="fs-7 text-dark fw-bold">
                                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                                      class="svg-icon svg-icon-5 svg-icon-success" style="height: 10px;"></span>
                                    {{(mobile?.bonder_status?.detected_packet_loss*100).toFixed(1)}} %
                                  </span>
                                </td>
                                <td>
                                  {{calculateDifferenceOfDate(mobile?.bonder_status?.detected_packet_loss_updated)}}
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Local IP </td>
                                <td> {{mobile?.bonder_status?.bound_tunnel_ip}} </td>
                                <td>
                                  {{calculateDifferenceOfDate(mobile?.bonder_status?.bound_tunnel_ip_updated)}}</td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> NAT IP </td>
                                <td> {{mobile?.aggregator_status?.connected_tunnel_ip}} </td>
                                <td>
                                  {{calculateDifferenceOfDate(mobile?.aggregator_status?.connected_tunnel_ip_updated)}}
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder fs-7 bg-light"> Tunnel port </td>
                                <td> {{mobile?.tunnel_port}} </td>
                                <td> </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td class="px-3 py-0" colspan="6" *ngIf="!mobile.bonder_status">
                          The bonder has not reported status for this leg.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <!-- mobile broadband End-->
            </tbody>
          </table>
          <app-preloader-table-list *ngIf="sharedService.Loader.isLegLoader || sharedService.Loader.isMobileLegLoader"
            [length]="3"></app-preloader-table-list>
          <div
            *ngIf="!(sharedService.Loader.isLegLoader || sharedService.Loader.isMobileLegLoader) && allLegs.length + allMobileBroadband.length <= 0"
            class="text-center font16 mt15 mb15 pt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-4 mb-1 ps-0">No records found.</span>
            </h3>
          </div>
        </div>
        <div *ngIf="!permissionsObj?.view">
          You have no permission to view the data. Kindly contact the authority.
        </div>
      </div>
    </div>
  </div>
</div>