import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedNetworkRoutesEditComponent } from './shared-network-routes-edit/shared-network-routes-edit.component';
@Component({
  selector: 'app-shared-network-routes',
  templateUrl: './shared-network-routes.component.html',
  styleUrls: ['./shared-network-routes.component.scss']
})
export class SharedNetworkRoutesComponent implements OnInit {
  authService = AuthService;
  latest_tuning: any;
  bondPermission: any;
  @Input() allRoutes: any;
  @Input() InterfaceDetailArray: any[] = [];
  @Input() bondsInfo: any;
  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() permissionsObj: any;

  @Output() getRoutesById = new EventEmitter<any>();

  constructor(private PartnerBondingService: PartnerBondingService,
    public sharedService: SharedService, private storageService: StorageService,
    private cd: ChangeDetectorRef, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.PartnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
  }

  onDelete(routesId: any, routes: any) {
    let ifname;
    if (routes.interface == '' || routes.interface == null) {
      ifname = "Auto";
    } else {
      let arr = this.InterfaceDetailArray.find((detailArr: any) => detailArr.interface_url == routes.interface);
      ifname = arr.ifname;
    }
    let questionTitle = 'Are you sure you want to delete this route?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    let deleteObject = {
      method: "DELETE",
      url: `bonds/${this.bondId}/routes/${routesId}/`,
      data: {},
      type: "Routes",
      bondName: this.storageService.getCipherObj('bondName'),
      bondId: this.bondId,
      network: routes?.network,
      ifname: ifname,
      id: routesId
    }
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.PartnerBondingService.deleteData(deleteObject).subscribe((data: any) => {
          if (data.code == 204 || data.code == 200) this.sharedService.loggerSuccess(data.message);
          else this.sharedService.loggerError(JSON.stringify(data?.data));
          this.sharedService.hideLoader();
          this.getRoutesById.emit();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getRoutesById.emit();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  onEdit(routeDetail: any) {
    let modal = this.modalService.open(SharedNetworkRoutesEditComponent, { size: 'lg' });
    if (routeDetail) {
      modal.componentInstance.routeDetail = routeDetail;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.InterfaceDetailArray = this.InterfaceDetailArray;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getRoutesById.emit();
      this.cd.detectChanges();
    }, () => { });
  }
}


