import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import * as xss from 'xss';
import { environment } from '../../../environments/environment';
import { AppConst } from '../constants/app.constant';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class SharedService {
    routes: any;
    groupPermissionArray: any[] = [];
    public selectedSpaceSubject = new Subject<any>();
    noPermission: any = false;
    organizationManagement: any = {
        view: false,
        update: false,
        delete: false
    }
    userManagement: any = {
        view: false,
        update: false,
        delete: false
    }
    groupManagement: any = {
        view: false,
        update: false,
        delete: false
    }
    generalConfiguration: any = {
        view: false,
        update: false,
        delete: false
    }
    branding: any = {
        view: false,
        update: false,
        delete: false
    }
    notificationTemplate: any = {
        view: false,
        update: false,
        delete: false
    }
    emailManagement: any = {
        view: false,
        update: false,
        delete: false
    }
    activityLogs: any = {
        view: false,
        update: false,
        delete: false
    }
    errorLogs: any = {
        view: false,
        update: false,
        delete: false
    }
    requestLogs: any = {
        view: false,
        update: false,
        delete: false
    }
    automationLogs: any = {
        view: false,
        update: false,
        delete: false
    }
    notificationLogs: any = {
        view: false,
        update: false,
        delete: false
    }
    bond: any = {
        view: false,
        update: false,
        delete: false
    }
    networkConfiguration: any = {
        view: false,
        update: false,
        delete: false
    }
    advancedConfiguration: any = {
        view: false,
        update: false,
        delete: false
    }
    bondTuning: any = {
        view: false,
        update: false,
        delete: false
    }
    alertConfig: any = {
        view: false,
        update: false,
        delete: false
    }
    qosProfile: any = {
        view: false,
        update: false,
        delete: false
    }
    classificationProfile: any = {
        view: false,
        update: false,
        delete: false
    }
    flowCollector: any = {
        view: false,
        update: false,
        delete: false
    }
    mobileProviders: any = {
        view: false,
        update: false,
        delete: false
    }
    space: any = {
        view: false,
        update: false,
        delete: false
    }
    nodeSetup: any = {
        view: false,
        update: false,
        delete: false
    }
    delegatedIpAllocation: any = {
        view: false,
        update: false,
        delete: false
    }
    privateWan: any = {
        view: false,
        update: false,
        delete: false
    }
    manageMeshRouting: any = {
        view: false,
        update: false,
        delete: false
    }
    privateWanRouting: any = {
        view: false,
        update: false,
        delete: false
    }
    privateWanCloud: any = {
        view: false,
        update: false,
        delete: false
    }
    privateWanCloneBond: any = {
        view: false,
        update: false,
        delete: false
    }
    routingGroup: any = {
        view: false,
        update: false,
        delete: false
    }
    groupIpAllocation: any = {
        view: false,
        update: false,
        delete: false
    }
    aggregator: any = {
        view: false,
        update: false,
        delete: false
    }
    aggregatorAuthorization: any = {
        view: false,
        update: false,
        delete: false
    }
    aggregatorProtocols: any = {
        view: false,
        update: false,
        delete: false
    }
    aggregatorFilters: any = {
        view: false,
        update: false,
        delete: false
    }
    privateWanRouter: any = {
        view: false,
        update: false,
        delete: false
    }
    privateWanRouterAuthorization: any = {
        view: false,
        update: false,
        delete: false
    }
    Loader: any = {
        isLegLoader: false,
        isMobileLegLoader: false,
        isStaticLegLoader: false,
        isPPPOELegLoader: false,
        isAutoipv6LegLoader: false,
        isDHCPLegLoader: false,
        isVLANInterfaceLoader: false,
        isVXLANInterfaceLoader: false,
        isEthernetInterfaceLoader: false,
        isBridgeInterfaceLoader: false,
        isIPLoader: false,
        isCPELoader: false,
        isRouteLoader: false,
        isProtocolLoader: false,
        isFilterLoader: false,
        isDNSServiceLoader: false,
        isCacheServiceLoader: false,
        isDhcpv6PDServiceLoader: false,
        isDhcpv6NAServiceLoader: false,
        isRAServiceLoader: false,
        isBondStatusLoader: false,
        isBondUpdateLogLoader: false,
        isBondPerformanceLoader: false,
        //add on
        isCheckIlluminateAgentLoader: false,
        isIlluminatePlanListLoader: false,
        isIlluminateInstallationLoader: false,
        isIlluminateProvisionLoader: false,
        isFirewallListLoader: false,
        isInstallFirewallLoader: false,
        isUpdateFirewallLoader: false,
        isUninstallFirewallLoader: false,
        isInstallToolLoader: false,
        isUpdateToolLoader: false,
        isUninstallToolLoader: false,
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService, private spinner: NgxSpinnerService,
        private storageService: StorageService,
    ) {
        this.getRoutesForMenu();
        // this.getPermission();
    }

    public static getTextValuePairFromEnum(myEnum: any) {
        let arr = [];
        // tslint:disable-next-line:forin
        for (var enumMember in myEnum) {
            var isValueProperty = parseInt(enumMember, 10) >= 0;
            if (isValueProperty) {
                arr.push({
                    text: this.prettifyCamelCase(myEnum[enumMember]),
                    value: typeof parseInt(enumMember) == 'number' ? parseInt(enumMember) : enumMember,
                });
            }
        }
        return arr;
    }

    public static prettifyCamelCase(str: any) {
        if (!str) {
            return '';
        }
        var output = '';
        var len = str.length;
        var char;

        for (var i = 0; i < len; i++) {
            char = str.charAt(i);

            if (i == 0) {
                output += char.toUpperCase();
            } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
                output += ' ' + char;
            } else if (char == '-' || char == '_') {
                output += ' ';
            } else {
                output += char;
            }
        }

        return output;
    }

    // for success message of toster
    loggerSuccess(msg: string, timeOut: number | undefined = 1500) {
        // console.log(msg);

        this.toastr.success(msg, 'Success', { timeOut: timeOut, progressBar: true });
    }


    // for info message of toster
    loggerInfo(msg: string, timeOut = 2500) {
        this.toastr.info(msg, 'Info', { timeOut: timeOut, progressBar: true });
    }

    // for error message of toster
    loggerError(msg: string, timeOut = 2500) {
        if (navigator.userAgent.includes('Firefox')) console.error('firefox error');
        if (!msg) console.error('error');
        this.toastr.error(msg, 'Error', { timeOut: timeOut, progressBar: true });
    }

    // for warning message of toster
    loggerWarning(msg: string, timeOut = 2500) {
        this.toastr.warning(msg, 'Warning', { timeOut: timeOut, progressBar: true });
    }

    // for show loader
    showLoader() {
        this.spinner.show();
    }

    // for hide loader
    hideLoader() {
        this.spinner.hide();
    }

    // swal question
    swalQuestion(title: string, text: string, confirmButtonText: string) {
        return Swal.fire({
            title: title,
            html: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText
        });
    }

    swalInfo(title: string, text: string) {
        return Swal.fire({
            title: title,
            html: text,
            icon: 'info',
            showCloseButton: true
        });
    }

    // swal Delete
    swalDelete(titleText: string, text: string) {
        Swal.fire(
            titleText,
            text,
            'success'
        );
    }

    // for remove white space of input text
    trimming_function(x: any) {
        return x ? x.replace(AppConst.trimPattern, '') : '';
    }

    getRole() {
        return Number(this.storageService.getCipherObj('role'));
    }

    swalSuccess(successMessage: string) {
        Swal.fire('', successMessage, 'success')
    }

    swalError(errorMessage: string) {
        Swal.fire('', errorMessage, 'error')
    }

    getRoutesForMenu() {
        let role = this.getRole();
        if (role === AppConst.admin) {
            this.routes = SharedService.SuperAdminRoutes;
        } else if (role === AppConst.basicUser) {
            this.routes = SharedService.BasicUserRoutes;
        }
        return this.routes;
    }

    public static urlExtemptWhenError: any = [
        'generalConfig/system/view',
        'generalConfig/security/view',
        'generalConfig/mailConfig/view',
        'admin/branding/view',
        'brand/emailBranding/view',
        '/addBond',
        '/addDynamicDataForThirdParty'
    ];

    public static SuperAdminRoutes: any = {
        items: [
            {
                title: 'Dashboard',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/tachometer.svg',
                page: '/admin/dashboard',
                translate: 'MENU.DASHBOARD',
                bullet: 'dot',
            },
            {
                title: 'Partners Management',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/user-friends.svg',
                page: '/admin/partner-management',
                translate: 'MENU.PARTNERS.NAME',
                bullet: 'dot',
            },
            {
                title: 'Staff Management',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/user-graduate.svg',
                page: '/admin/staff-management',
                translate: 'MENU.STAFF.NAME',
                bullet: 'dot',
            },
            {
                title: 'Modules',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/shield-check.svg',
                page: '/admin/module-access',
                translate: 'Modules',
                bullet: 'dot',
            },
            {
                title: 'Configuration',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/cog.svg',
                page: '/admin/configuration',
                translate: 'Configuration',
                bullet: 'dot',
                submenu: [
                    {
                        title: 'General Configuration',
                        svg: './assets/media/icons/duotune/icon/user-cog.svg',
                        page: '/admin/configuration/general-configuration'
                    },
                    {
                        title: 'Branding',
                        svg: './assets/media/icons/duotune/icon/palette.svg',
                        page: '/admin/configuration/branding'
                    },
                    {
                        title: 'Email Template',
                        svg: './assets/media/icons/duotune/icon/envelope-duo.svg',
                        page: '/admin/configuration/email-management'
                    },
                ]
            },
            {
                title: 'Logs',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/hourglass-half.svg',
                page: '/admin/logs',
                translate: 'Logs',
                bullet: 'dot',
                submenu: [
                    {
                        title: 'Activity Logs',
                        svg: './assets/media/icons/duotune/icon/file-alt.svg',
                        page: '/admin/logs/activity-logs'
                    },
                    // {
                    //     title: 'Error Logs',
                    //     page: '/admin/crafted/widgets/charts'
                    // },
                    // {
                    //     title: 'Request Log',
                    //     page: '/admin/crafted/widgets/feeds'
                    // },
                    // {
                    //     title: 'Automation Log',
                    //     page: '/admin/crafted/widgets/feeds'
                    // },
                    // {
                    //     title: 'Notification Log',
                    //     page: '/admin/crafted/widgets/feeds'
                    // },
                ]
            }
        ]
    };

    public static BasicUserRoutes: any = {
        items: [
            {
                title: 'Dashboard',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/tachometer.svg',
                page: '/admin/dashboard',
                translate: 'MENU.DASHBOARD',
                bullet: 'dot',
            },
            {
                title: 'Partners Management',
                root: true,
                icon: 'flaticon2-architecture-and-city',
                svg: './assets/media/icons/duotune/design/user-friends.svg',
                page: '/admin/partner-management',
                translate: 'MENU.PARTNERS.NAME',
                bullet: 'dot',
            }
        ]
    };

    getPartnerLPDetails(data: any) {
        return this.http.post(BACKEND_URL + '/partnerPanel/getPartnerLPDetail', data)
    }

    getPartnerLPDetailsForAdmin(data: any) {
        return this.http.post(BACKEND_URL + '/admin/getPartnerLPDetail', data)
    }

    public getPermission() {
        this.groupPermissionArray = this.storageService.getCipherObj('group_permission');
        if (this.groupPermissionArray.length) {
            this.noPermission = false;
            this.groupPermissionArray.forEach((element: any) => {
                if (element.title == 'User & Organization') {
                    element.sub.forEach((item: any) => {
                        if (item.name.includes('Organization')) this.organizationManagement = item.action;
                        if (item.name.includes('Groups')) this.groupManagement = item.action;
                        if (item.name.includes('User')) this.userManagement = item.action;
                    });
                }
                if (element.title == 'Configuration') {
                    element.sub.forEach((item: any) => {
                        if (item.name.includes('Email Management')) this.emailManagement = item.action;
                        if (item.name.includes('Branding')) this.branding = item.action;
                        if (item.name.includes('Notification Templates')) this.notificationTemplate = item.action;
                        if (item.name.includes('General Configuration')) this.generalConfiguration = item.action;
                    });
                }
                if (element.title == 'Logs') {
                    element.sub.forEach((item: any) => {
                        if (item.name.includes('Activity Logs')) this.activityLogs = item.action;
                        if (item.name.includes('Error Logs')) this.errorLogs = item.action;
                        if (item.name.includes('Request Logs')) this.requestLogs = item.action;
                        if (item.name.includes('Automation Logs')) this.automationLogs = item.action;
                        if (item.name.includes('Notification Logs')) this.notificationLogs = item.action;
                    });
                }
                if (element.title == 'Node & SD-WAN') {
                    element.sub.forEach((item: any) => {
                        if (item.name.includes('Node Configuration')) this.bond = item.action;
                        if (item.name.includes('Network Configuration')) this.networkConfiguration = item.action;
                        if (item.name.includes('Advanced Configuration')) this.advancedConfiguration = item.action;
                        if (item.name.includes('Node Tuning')) this.bondTuning = item.action;
                        if (item.name.includes('Advance Alert Configuration')) this.alertConfig = item.action;
                    });
                }
                if (element.title == 'Policies') {
                    element.sub.forEach((item: any) => {
                        if (item.name.includes('QoS profile')) this.qosProfile = item.action;
                        if (item.name.includes('Classification profile')) this.classificationProfile = item.action;
                        if (item.name.includes('Flow collector')) this.flowCollector = item.action;
                        if (item.name.includes('Mobile providers')) this.mobileProviders = item.action;
                    });
                }
                if (element.title == 'Spaces') {
                    element.sub.forEach((item: any) => {
                        if (item.name.includes('Space')) this.space = item.action;
                        // if (item.name.includes('Branding')) this.classificationProfile = item.action;
                        if (item.name.includes('Node setup')) this.nodeSetup = item.action;
                        if (item.name.includes('Delegated IP allocation')) this.delegatedIpAllocation = item.action;
                    });
                }
                if (element.title == 'Private WAN') {
                    element.sub.forEach((item: any) => {
                        if (item.name == 'Private WAN') this.privateWan = item.action;
                        if (item.name.includes('Managed Mesh Routing')) this.manageMeshRouting = item.action;
                        if (item.name == 'Private WAN routing (Legacy)') this.privateWanRouting = item.action;
                        if (item.name == 'Private WAN Cloud Firewall') this.privateWanCloud = item.action;
                        if (item.name == 'Add node to SD-WAN') this.privateWanCloneBond = item.action;
                    });
                }
                if (element.title == 'Routing groups') {
                    element.sub.forEach((item: any) => {
                        if (item.name.includes('Routing group')) this.routingGroup = item.action;
                        if (item.name.includes('Group IP allocation')) this.groupIpAllocation = item.action;
                    });
                }
                if (element.title == 'Hosts') {
                    element.sub.forEach((item: any) => {
                        if (item.name == 'Aggregator') this.aggregator = item.action;
                        if (item.name.includes('Aggregator authorization')) this.aggregatorAuthorization = item.action;
                        if (item.name.includes('Aggregator protocols')) this.aggregatorProtocols = item.action;
                        if (item.name.includes('Aggregator filters')) this.aggregatorFilters = item.action;
                        if (item.name.includes('Private WAN router')) this.privateWanRouter = item.action;
                        if (item.name.includes('Private WAN router authorization')) this.privateWanRouterAuthorization = item.action;
                    });
                }
            });
        } else this.noPermission = true;

    }

    public selectedSpace(item: any) {
        this.selectedSpaceSubject.next({ data: item })
    }

    public removeDuplicateFromArrayOfObjects(array: any[], keyName: any) {
        array = array.filter((test, index, array) =>
            index === array.findIndex((findTest) =>
                findTest[keyName] === test[keyName]
            )
        );
        return array;
    }

    xssValidator(control: any) {
        let sanitizedValue;
        if (typeof control?.value === "string") {
            sanitizedValue = (xss as any)(control.value);
            if (sanitizedValue.indexOf("onerror") !== -1 || sanitizedValue.indexOf("script") !== -1 || sanitizedValue.indexOf("img src") !== -1 || sanitizedValue.indexOf("javascript:alert") !== -1 || sanitizedValue.indexOf("iframe") !== -1 || sanitizedValue.indexOf("onclick") !== -1 || sanitizedValue.indexOf("&lt;") !== -1 || sanitizedValue.indexOf("&gt;") !== -1) {
                sanitizedValue = sanitizedValue.replace(/onerror|script|img src|javascript:alert|iframe|onclick|&lt;|&gt;/gi, "");
            }
            if (sanitizedValue !== control.value) return control.setValue(sanitizedValue);
        }
        return null;
    }

    calculateBytes(rate: number) {
        if (!rate) return '0 Bps';
        if (rate < 1000) return `${rate.toFixed()} Bps`;
        else {
            rate /= 1000;
            if (rate < 1000) return `${rate.toFixed(1)} Kbps`;
            else {
                rate /= 1000;
                if (rate < 1000) return `${rate.toFixed(1)} Mbps`;
                else {
                    rate /= 1000;
                    return `${rate.toFixed(2)} Gbps`;
                }
            }
        }
    }
}
