<div class="modal-header px-5 py-3">
    <h5 class="modal-title" id="exampleModalLabel"> Node Sharing </h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5 min-h-275px">
    <div class="card mb-5">
        <div class="card-body p-0">
            <div class="row" *ngIf="errors && errors['non_field_errors']">
                <div class="col-md-12">
                    <div class="alert alert-danger">{{errors['non_field_errors']}}</div>
                </div>
            </div>
            <div class="row" *ngIf="errors && !errors['non_field_errors']">
                <div class="col-md-12">
                    <div class="alert alert-danger">Please correct the errors below.</div>
                </div>
            </div>
            <div class="row" *ngIf="orgList.length <= 0 && isOrganization">
                <div class="col-md-12">
                    <div class="alert alert-danger">Organize is required to share bond.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-12 mb-5 border-bottom-light pb-1">
                    <div class="form-group inputtext-block mb-5">
                        <label class="form-label"> Organization <span class="required-asterisk m-0">*</span> </label>
                        <ng-select class="dropdown-block" [(ngModel)]="selectedOrg" placeholder="Select Organization"
                            *ngIf="!isPreloader">
                            <ng-option value="" disabled selected>Select Organization</ng-option>
                            <ng-option *ngFor="let org of orgList;" [value]="org._id"> {{org.fname}} </ng-option>
                        </ng-select>
                        <div class="shine w-100" style="height: 60%;" *ngIf="isPreloader">
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 mb-5">
                    <label class="form-label">Node Permission</label>
                    <div class="d-flex">
                        <!-- <div class="d-flex gap-4">
                            <span>View</span>
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input [disabled]="(permissions && permissions?.isViewDisable == true) ? true : false"
                                    class="form-check-input" [(ngModel)]="permissions.view" type="checkbox"
                                    value="{{(permissions?.view) ? 'true' : 'false'}}" data-kt-check="true"
                                    data-kt-check-target=".widget-9-check" />
                            </div>
                        </div> -->
                        <div class="d-flex gap-4 me-3">
                            <!-- <span>Update</span> -->
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input [disabled]="(permissions && permissions?.isUpdateDisable == true) ? true : false"
                                    class="form-check-input" [(ngModel)]="permissions.update" type="checkbox"
                                    value="{{(permissions?.update) ? 'true' : 'false'}}" data-kt-check="true"
                                    data-kt-check-target=".widget-9-check" />
                                <span class="form-check-label m-2 form-label">Update<span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="end"
                                        ngbTooltip="After allowing update permission child organization can add,update or delete connectedIp,Legs,interface and etc services."></span></span>
                            </div>
                        </div>
                        <!-- <div class="d-flex gap-4">
                            <span>Delete</span>
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input [disabled]="(permissions && permissions?.isDeleteDisable == true) ? true : false"
                                    class="form-check-input" [(ngModel)]="permissions.delete" type="checkbox"
                                    value="{{(permissions?.delete) ? 'true' : 'false'}}" data-kt-check="true"
                                    data-kt-check-target=".widget-9-check" />
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()"
        [disabled]="!selectedOrg">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/share-alt.svg'" class="svg-icon svg-icon-block mb-2"></span>
        </div> Share
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>