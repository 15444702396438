<div class="card mb-5 border-1 border border-top-0" *ngIf="permissionsObj?.view">
    <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50">
        <div class="card-title m-0">
            <h4 class="fw-bolder m-0 fs-4">Filters</h4>
        </div>
        <div *ngIf="permissionsObj?.update" class="me-2 card-toolbar d-flex align-items-center justify-content-end"
            data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Add">
            <button type="button" class="btn btn-primary btn-style"
                [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? '': editFilter('')">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Add Filters
            </button>
        </div>
    </div>

    <div class="card-body p-2">
        <div class="row g-5 g-xxl-8">
            <div class="col-md-12 col-lg-12 mb-5">
                <div *ngIf="permissionsObj?.view" class="table-responsive border">
                    <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                        <thead class="table-light">
                            <tr class="fw-bolder text-dark fs-7">
                                <th class="py-4 min-w-75px w-75px"> ID </th>
                                <th class="py-4"> Name </th>
                                <th class="py-4 min-w-50px w-50px text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!(sharedService?.Loader?.isFilterLoader) && allfilters && allfilters.length > 0"
                            class="datatable-body">
                            <tr *ngFor="let filter of allfilters;" class="main-row">
                                <td> {{ filter.id }} </td>
                                <td> {{ filter.name }} </td>
                                <td class="text-center">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': editFilter(filter)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': deleteFilter(filter,filter?.name)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-preloader-table-list *ngIf="sharedService?.Loader?.isFilterLoader"
                        [length]="3"></app-preloader-table-list>
                    <div *ngIf="!(sharedService?.Loader?.isFilterLoader) && allfilters && allfilters.length <= 0"
                        class="text-center font16 mt15 mb15 pt-4">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                        </h3>
                    </div>
                </div>
                <div *ngIf="!permissionsObj?.view">
                    You have no permission to view the data. Kindly contact the authority.
                </div>
            </div>
        </div>
    </div>
</div>