import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/auth';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  menuConfig: any;
  navBarImageUrl: any;
  navBarText: any;
  isNavImageSelected: any;
  isNavTextSelected: any;
  routerLink: any;
  role: any;
  userRole: any;
  constructor(private router: Router, private sharedService: SharedService,
    private authService: AuthService, private storageService: StorageService,
    private cd: ChangeDetectorRef) {
    this.menuConfig = this.sharedService.getRoutesForMenu();
  }

  isSubMenuVisible = false;

  showSubMenu() {
    this.isSubMenuVisible = true;
  }

  hideSubMenu() {
    this.isSubMenuVisible = false;
  }

  ngOnInit(): void {
    this.fetchNavOptions();
    this.fetchRouterLink();
    this.getRole();
  }

  fetchRouterLink() {
    let role = this.storageService.getCipherObj('role');
    if (Number(role) == AppConst.admin || Number(role) == AppConst.basicUser) {
      this.routerLink = '/dashboard'
    } else if (Number(role) == AppConst.partner) {
      this.routerLink = '/partner/dashboard'
    } else if (Number(role) == AppConst.user) {
      this.routerLink = '/partner/dashboard'
    } else if (Number(role) == AppConst.organization) {
      this.routerLink = '/partner/dashboard'
    }
  }

  fetchNavOptions() {
    this.authService.navBar$.subscribe((data: any) => {
      if (data?.image) {
        this.navBarImageUrl = data.image
      }
      if (data?.text) {
        this.navBarText = data.text
      }
      this.cd.detectChanges();
      // if(data.type==1){
      //      this.isNavImageSelected=true;
      //      this.isNavTextSelected=false;
      //      this.navBarImageUrl=data.content
      // }
      // if(data.type==2){
      //   this.isNavTextSelected=true;
      //   this.isNavImageSelected=false;
      //   this.navBarText=data.content
      // }
    })
    // this.authService.navBar$.subscribe((data:any)=>{
    //   console.log('data: ', data);
    //   if(data.type==1){
    //        this.isNavImageSelected=true;
    //        this.isNavTextSelected=false;
    //        this.navBarImageUrl=data.content;
    //        this.navBarText='';
    //   }
    //  else if(data.type==2){
    //     this.navBarImageUrl='';
    //     this.isNavTextSelected=true;
    //     this.isNavImageSelected=false;
    //     this.navBarText=data.content
    //   }
    //   this.cd.detectChanges();
    // })
  }

  getRole() {
    this.userRole = Number(this.storageService.getCipherObj('role'));
    if (this.userRole == AppConst.admin) {
      this.menuConfig = SharedService.SuperAdminRoutes;
      return;
    } else if (this.userRole == AppConst.basicUser) {
      this.menuConfig = SharedService.BasicUserRoutes;
      return;
    } else if (this.userRole == AppConst.partner) {
      this.role = '/partner';
      this.setAsidePartnerMenu();
    } else if (this.userRole == AppConst.user) {
      this.role = '/partner';
      this.setAsideMenu();
    } else if (this.userRole == AppConst.organization) {
      this.role = '/partner';
      this.setAsideMenu();
    }
  }

  setAsideMenu() {
    let items: any[] = [];
    let menu: any[] = [];
    let userSubItems: any[] = [];
    let configSubItems: any[] = []
    let spaceSubItems: any[] = [];
    let policiesSubItems: any[] = [];
    let sdWanSubItems: any[] = [];
    let logs: any[] = [];
    this.sharedService.getPermission();
    menu = this.storageService.getCipherObj('Module_Access');
    // sdWanSubItems.push(
    //   {
    //     title: 'Hosts',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/design/cog.svg',
    //     page: `${this.role}/hosts/aggregators`,
    //     translate: 'Aggregators',
    //     bullet: 'dot',
    //   })
    items.push({
      title: 'Dashboard',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/icons/duotune/design/tachometer.svg',
      page: `${this.role}/dashboard`,
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
    },
      //{
      // title: 'Space',
      // root: true,
      // icon: 'flaticon2-architecture-and-city',
      // svg: './assets/media/icons/duotune/design/tachometer.svg',
      // page: `${this.role}/space`,
      // translate: 'Space',
      // bullet: 'dot',
      //},
      //  {
      //    title: 'Hosts',
      //   root: true,
      //    icon: 'flaticon2-architecture-and-city',
      //    svg: './assets/media/icons/duotune/icon/router.svg',
      //   // page: `${this.role}/hosts`,
      //    translate: 'Hosts',
      //    bullet: 'dot',
      //  },
    )

    // if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
    //   items.push({
    //     title: 'Tree View',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/icon/network-wired.svg',
    //     page: `${this.role}/tree-view`,
    //     translate: 'Tree View',
    //     bullet: 'dot',
    //   })
    // }

    let flagMng = 'true';
    // let spaceMng = menu.find((x: any) => x.name == 'Space');
    let bondMng = menu.find((x: any) => x.name == AppConst.moduleList.FBModule);

    // policiesSubItems.push(
    //   {
    //     title: 'Aggregators',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/design/cog.svg',
    //     page: `${this.role}/hosts/aggregators`,
    //     translate: 'Aggregators',
    //     bullet: 'dot',
    //     // submenu: userSubItems
    //   },
    //   {
    //     title: 'Private WAN Routers',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/design/cog.svg',
    //     page: `${this.role}/hosts/routers`,
    //     translate: 'Private WAN Routers',
    //     bullet: 'dot',
    //     // submenu: userSubItems
    //   },
    //   {
    //     title: 'Bonders',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/design/cog.svg',
    //     page: `${this.role}/hosts/bonders`,
    //     translate: 'Bonders',
    //     bullet: 'dot',
    //     // submenu: userSubItems
    //   }
    // )
    if (this.sharedService.classificationProfile.view) {
      policiesSubItems.push(
        {
          title: 'Classification',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          svg: './assets/media/icons/duotune/icon/address-card.svg',
          page: `${this.role}/policies/classification`,
          translate: 'Classification',
          bullet: 'dot',
        }
      )
    }

    if (this.sharedService.qosProfile.view) {
      policiesSubItems.push(
        {
          title: 'Quality of Service',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          svg: './assets/media/icons/duotune/icon/shield-check.svg',
          page: `${this.role}/policies/qos`,
          translate: 'Quality of Service',
          bullet: 'dot',
        }
      )
    }

    if (flagMng == 'true' && bondMng && this.sharedService.bond.view) {
      spaceSubItems.push(
        {
          title: 'SD-WAN Networks',
          root: true,
          svg: './assets/media/icons/duotune/icon/chart-network.svg',
          page: `${this.role}/sd-wan`,
          translate: 'SD-WAN Networks',
          bullet: 'dot',
        },
      )
      let homeSpace = this.storageService.getCipherObj('homeSpace');
      if (homeSpace != '0' && (this.userRole == AppConst.organization || this.userRole == AppConst.user)) {
        spaceSubItems.push({
          title: 'Spaces',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          svg: './assets/media/icons/duotune/text/txt004.svg',
          page: `${this.role}/space`,
          translate: 'Spaces',
          bullet: 'dot',
        });
      }
      spaceSubItems.push({
        title: 'Nodes',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/electronics/elc008.svg',
        page: `${this.role}/bonds`,
        translate: 'Nodes',
        bullet: 'dot',
      });
      spaceSubItems.push({
        title: 'Shared Assets',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/icon/share-alt.svg',
        page: `${this.role}/shared-bonds`,
        translate: 'Shared Assets',
        bullet: 'dot',
      });
      if (policiesSubItems.length > 0) {
        spaceSubItems.push(
          {
            title: 'Policies',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            svg: './assets/media/icons/duotune/icon/file-alt.svg',
            page: `${this.role}/policies`,
            translate: 'Policies',
            bullet: 'dot',
            submenu: policiesSubItems
          }
        )
      }
      spaceSubItems.push({
        title: 'Audit',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/finance/fin008.svg',
        page: `${this.role}/audit`,
        translate: 'Audit',
        bullet: 'dot'
      })
    }

    if (spaceSubItems.length > 0) {
      items.push(
        {
          title: 'Node & SD-WAN',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          svg: './assets/media/icons/duotune/icon/planet-moon.svg',
          page: `${this.role}/sd-wan`,
          translate: 'Node & SD-WAN',
          bullet: 'dot',
          submenu: spaceSubItems
        }
      )
    }

    // let spaceFind=menu.find((x:any)=>x.name=='Space')
    // if(spaceFind && flag == 'true'){
    //   items.push(
    //   {
    //     title: 'Space',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/icon/planet-moon.svg',
    //     page: `${this.role}/space`,
    //     translate: 'Space',
    //     bullet: 'dot'
    //   },
    //   {
    //     title: 'SD-WAN',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/icon/planet-moon.svg',
    //     page: `${this.role}/space/sd-wan`,
    //     translate: 'SD-WAN',
    //     bullet: 'dot',
    //   })
    // }

    // let bondFind=menu.find((x:any)=>x.name=='BOND')
    // if(bondFind && flag == 'true'){
    //   items.push({
    //     title: 'Nodes',
    //     root: true,
    //     icon: 'flaticon2-architecture-and-city',
    //     svg: './assets/media/icons/duotune/electronics/elc008.svg',
    //     page: `${this.role}/bonds`,
    //     translate: 'Nodes',
    //     bullet: 'dot',
    // })
    // }
    // sdWanSubItems.push(
    //   {
    //     title: 'Organization Mgmt',
    //     page: `${this.role}/user-org-management/organization-management`
    //   },
    // )

    let orgFind = menu.find((x: any) => x.name == AppConst.moduleList.orgModule);
    if (orgFind && this.sharedService.organizationManagement.view) {
      userSubItems.push(
        {
          title: 'Organizations',
          svg: './assets/media/icons/duotune/icon/building.svg',
          page: `${this.role}/user-org-management/organization-management`
        },
      )
    }

    let groupFind = menu.find((x: any) => x.name == AppConst.moduleList.groupModule);
    if (groupFind && this.sharedService.groupManagement.view) {
      userSubItems.push(
        {
          title: 'Group Permission',
          svg: './assets/media/icons/duotune/icon/layer-group.svg',
          page: `${this.role}/user-org-management/group-permission`
        },
      )
    }

    let userFind = menu.find((x: any) => x.name == AppConst.moduleList.userModule);
    if (userFind && this.sharedService.userManagement.view) {
      userSubItems.push(
        {
          title: 'Users',
          svg: './assets/media/icons/duotune/icon/users.svg',
          page: `${this.role}/user-org-management/user-management`
        },
      )
    }

    if (userSubItems.length > 0) {
      items.push(
        {
          title: 'Users & Organizations',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          svg: './assets/media/icons/duotune/icon/user-cog.svg',
          page: `${this.role}/user-org-management/organization-management`,
          translate: 'Users & Organizations',
          bullet: 'dot',
          submenu: userSubItems
        }
      )
    }

    let generalConfigFind = menu.find((x: any) => x.name == AppConst.moduleList.configModule);
    if (generalConfigFind && this.sharedService.generalConfiguration.view && this.sharedService.getRole() != AppConst.organization) {
      configSubItems.push({
        title: 'General Configuration',
        svg: './assets/media/icons/duotune/icon/user-cog.svg',
        page: `${this.role}/configuration/general-configuration`
      },
      )
    }

    let moduleAccessFind = menu.find((x: any) => x.name == AppConst.moduleList.accessModule)
    if (moduleAccessFind) {
      configSubItems.push({
        title: 'Modules',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/icon/shield-check.svg',
        page: `${this.role}/configuration/module-access`,
        translate: 'Modules',
        bullet: 'dot',
      })
    }

    let emailFind = menu.find((x: any) => x.name == AppConst.moduleList.emailModule);
    if (emailFind && this.sharedService.emailManagement.view && this.sharedService.getRole() != AppConst.organization) {
      configSubItems.push({
        title: 'Email Template',
        svg: './assets/media/icons/duotune/icon/envelope-duo.svg',
        page: `${this.role}/configuration/email-management`
      })
    }

    // if (this.sharedService.getRole() != AppConst.user) { }
    let brandingFind = menu.find((x: any) => x.name == AppConst.moduleList.brandModule);
    if (brandingFind && this.sharedService.branding.view) {
      configSubItems.push({
        title: 'Branding',
        svg: './assets/media/icons/duotune/icon/palette.svg',
        page: `${this.role}/configuration/branding`
      })
    }

    let alertFind = menu.find((x: any) => x.name == AppConst.moduleList.alertModule);
    if (alertFind && this.sharedService.alertConfig.view) {
      configSubItems.push({
        title: 'Alerts',
        svg: './assets/media/icons/duotune/icon/bell.svg',
        page: `${this.role}/configuration/alert`,
      })
    }
    let notificationFind = menu.find((x: any) => x.name == AppConst.moduleList.notificationModule);
    if (notificationFind && this.sharedService.notificationTemplate.view) {
      // configSubItems.push(
      //   {
      //     title: 'Notification Template',
      //     page: `${this.role}/configuration/notification-template`
      //   },
      // )
    }

    if (configSubItems.length) {
      items.push({
        title: 'Configuration',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/design/cog.svg',
        page: '/admin/configuration',
        translate: 'Configuration',
        bullet: 'dot',
        submenu: configSubItems
      })
    }

    let activityLogsFind = menu.find((x: any) => x.name == 'Activity Logs');
    if (activityLogsFind && this.sharedService.activityLogs.view) {
      logs.push(
        {
          title: 'Activity Logs',
          page: `${this.role}/logs/activity`
        },
      )
    }

    let errorLogsFind = menu.find((x: any) => x.name == 'Error Logs');
    if (errorLogsFind && this.sharedService.errorLogs.view) {
      logs.push(
        {
          title: 'Error Logs',
          page: `${this.role}/logs/error`
        },
      )
    }

    let requestLogsFind = menu.find((x: any) => x.name == 'Request Logs');
    if (requestLogsFind && this.sharedService.requestLogs.view) {
      logs.push(
        {
          title: 'Request Logs',
          page: `${this.role}/logs/request`
        },
      )
    }

    let automationLogsFind = menu.find((x: any) => x.name == 'Automation Logs');
    if (automationLogsFind && this.sharedService.automationLogs.view) {
      logs.push(
        {
          title: 'Automation Logs',
          page: `${this.role}/logs/automation`
        },
      )
    }

    let notificationLogsFind = menu.find((x: any) => x.name == 'Notification Logs');
    if (notificationLogsFind && this.sharedService.notificationLogs.view) {
      logs.push(
        {
          title: 'Notification Logs',
          page: `${this.role}/logs/notification`
        },
      )
    }

    if (logs.length) {
      items.push({
        title: 'Logs',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/design/cog.svg',
        //page: '/admin/configuration',
        translate: 'Logs',
        bullet: 'dot',
        submenu: logs
      })
    }

    if (items.length) this.menuConfig = { items: items }
  }

  setAsidePartnerMenu() {
    let items: any[] = [];
    let menu: any[] = [];
    let userSubItems: any[] = [];
    let configSubItems: any[] = []
    let spaceSubItems: any[] = [];
    let policiesSubItems: any[] = [];
    let logs: any[] = [];
    this.sharedService.getPermission();
    menu = this.storageService.getCipherObj('Module_Access');

    items.push({
      title: 'Dashboard',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/icons/duotune/design/tachometer.svg',
      page: `${this.role}/dashboard`,
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
    })

    let bondMng = menu.find((x: any) => x.name == AppConst.moduleList.FBModule);

    if (bondMng) {
      spaceSubItems.push({
        title: 'SD-WAN Networks',
        root: true,
        svg: './assets/media/icons/duotune/icon/chart-network.svg',
        page: `${this.role}/sd-wan`,
        translate: 'SD-WAN Networks',
        bullet: 'dot',
      })
      spaceSubItems.push({
        title: 'Spaces',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/text/txt004.svg',
        page: `${this.role}/space`,
        translate: 'Spaces',
        bullet: 'dot',
      });
      spaceSubItems.push({
        title: 'Nodes',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/electronics/elc008.svg',
        page: `${this.role}/bonds`,
        translate: 'Nodes',
        bullet: 'dot',
      });
      spaceSubItems.push({
        title: 'Shared Assets',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/icon/share-alt.svg',
        page: `${this.role}/shared-bonds`,
        translate: 'Shared Assets',
        bullet: 'dot',
      });
      policiesSubItems.push({
        title: 'Classification',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/icon/address-card.svg',
        page: `${this.role}/policies/classification`,
        translate: 'Classification',
        bullet: 'dot',
      })
      policiesSubItems.push({
        title: 'Quality of Service',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/icon/shield-check.svg',
        page: `${this.role}/policies/qos`,
        translate: 'Quality of Service',
        bullet: 'dot',
      })
      if (policiesSubItems.length > 0) {
        spaceSubItems.push(
          {
            title: 'Policies',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            svg: './assets/media/icons/duotune/icon/file-alt.svg',
            page: `${this.role}/policies`,
            translate: 'Policies',
            bullet: 'dot',
            submenu: policiesSubItems
          }
        )
      }
      spaceSubItems.push({
        title: 'Audit',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/finance/fin008.svg',
        page: `${this.role}/audit`,
        translate: 'Audit',
        bullet: 'dot'
      })
    }

    if (spaceSubItems.length > 0) {
      items.push(
        {
          title: 'Node & SD-WAN',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          svg: './assets/media/icons/duotune/icon/planet-moon.svg',
          page: `${this.role}/sd-wan`,
          translate: 'Node & SD-WAN',
          bullet: 'dot',
          submenu: spaceSubItems
        }
      )
    }

    let orgFind = menu.find((x: any) => x.name == AppConst.moduleList.orgModule);
    if (orgFind) {
      userSubItems.push({
        title: 'Organizations',
        svg: './assets/media/icons/duotune/icon/building.svg',
        page: `${this.role}/user-org-management/organization-management`
      })
    }

    let groupFind = menu.find((x: any) => x.name == AppConst.moduleList.groupModule);
    if (groupFind) {
      userSubItems.push({
        title: 'Group Permission',
        svg: './assets/media/icons/duotune/icon/layer-group.svg',
        page: `${this.role}/user-org-management/group-permission`
      })
    }

    let userFind = menu.find((x: any) => x.name == AppConst.moduleList.userModule);
    if (userFind) {
      userSubItems.push({
        title: 'Users',
        svg: './assets/media/icons/duotune/icon/users.svg',
        page: `${this.role}/user-org-management/user-management`
      })
    }

    if (userSubItems.length > 0) {
      items.push({
        title: 'Users & Organizations',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/icon/user-cog.svg',
        page: `${this.role}/user-org-management/organization-management`,
        translate: 'Users & Organizations',
        bullet: 'dot',
        submenu: userSubItems
      })
    }

    let generalConfigFind = menu.find((x: any) => x.name == AppConst.moduleList.configModule);
    if (generalConfigFind) {
      configSubItems.push({
        title: 'General Configuration',
        svg: './assets/media/icons/duotune/icon/user-cog.svg',
        page: `${this.role}/configuration/general-configuration`
      })
    }

    let moduleAccessFind = menu.find((x: any) => x.name == AppConst.moduleList.accessModule)
    if (moduleAccessFind) {
      configSubItems.push({
        title: 'Modules',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/icon/shield-check.svg',
        page: `${this.role}/configuration/module-access`,
        translate: 'Modules',
        bullet: 'dot',
      })
    }

    let emailFind = menu.find((x: any) => x.name == AppConst.moduleList.emailModule);
    if (emailFind) {
      configSubItems.push({
        title: 'Email Template',
        svg: './assets/media/icons/duotune/icon/envelope-duo.svg',
        page: `${this.role}/configuration/email-management`
      })
    }

    let brandingFind = menu.find((x: any) => x.name == AppConst.moduleList.brandModule);
    if (brandingFind) {
      configSubItems.push({
        title: 'Branding',
        svg: './assets/media/icons/duotune/icon/palette.svg',
        page: `${this.role}/configuration/branding`
      })
    }

    let alertFind = menu.find((x: any) => x.name == AppConst.moduleList.alertModule);
    if (alertFind) {
      configSubItems.push({
        title: 'Alerts',
        svg: './assets/media/icons/duotune/icon/bell.svg',
        page: `${this.role}/configuration/alert`,
      })
    }

    let notificationFind = menu.find((x: any) => x.name == AppConst.moduleList.notificationModule);
    if (notificationFind) {
      // configSubItems.push({
      //     title: 'Notification Template',
      //     page: `${this.role}/configuration/notification-template`
      // })
    }

    if (configSubItems.length) {
      items.push({
        title: 'Configuration',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/design/cog.svg',
        page: '/admin/configuration',
        translate: 'Configuration',
        bullet: 'dot',
        submenu: configSubItems
      })
    }

    let activityLogsFind = menu.find((x: any) => x.name == 'Activity Logs');
    if (activityLogsFind) {
      logs.push({
        title: 'Activity Logs',
        page: `${this.role}/logs/activity`
      })
    }

    let errorLogsFind = menu.find((x: any) => x.name == 'Error Logs');
    if (errorLogsFind) {
      logs.push({
        title: 'Error Logs',
        page: `${this.role}/logs/error`
      })
    }

    let requestLogsFind = menu.find((x: any) => x.name == 'Request Logs');
    if (requestLogsFind) {
      logs.push({
        title: 'Request Logs',
        page: `${this.role}/logs/request`
      })
    }

    let automationLogsFind = menu.find((x: any) => x.name == 'Automation Logs');
    if (automationLogsFind) {
      logs.push({
        title: 'Automation Logs',
        page: `${this.role}/logs/automation`
      })
    }

    let notificationLogsFind = menu.find((x: any) => x.name == 'Notification Logs');
    if (notificationLogsFind) {
      logs.push({
        title: 'Notification Logs',
        page: `${this.role}/logs/notification`
      })
    }

    if (logs.length) {
      items.push({
        title: 'Logs',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        svg: './assets/media/icons/duotune/design/cog.svg',
        //page: '/admin/configuration',
        translate: 'Logs',
        bullet: 'dot',
        submenu: logs
      })
    }

    if (items.length) this.menuConfig = { items: items }
  }

  calculateMenuItemCssClass(url: string): string {
    return checkIsActive(this.router.url, url) ? 'active' : '';
  }
  onSideMenuClick(event: any, route: any) {
    if (!event.ctrlKey && !this.router.url.includes(route)) {
      this.sharedService.showLoader()
    }
  }

  isActiveSubmenuHeader(page: string) {
    return this.router.url.includes(page)
  }

  toggleMenu() {
    let list = document.getElementsByClassName('menu-item menu-accordion');
    for (let i = 0; i < list.length; i++) {
      const element = list.item(i);
      element?.classList.remove('show');
      element?.classList.remove('hover');
      let children: any = element?.getElementsByClassName('menu-sub menu-sub-accordion');
      for (let i = 0; i < children?.length; i++) {
        const child: HTMLElement = children.item(i);
        child?.classList?.remove('show');
      }
    }

  }
  openDoc() {
    window.open(`https://${window.location.host}/docs`, '_blank')
  }
}

const getCurrentUrl = (pathname: string): string => {
  return pathname.split(/[?#]/)[0];
};

const checkIsActive = (pathname: string, url: string) => {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
};

