<div class="d-flex align-items-center justify-content-between mt-2 px-5 bg-light border">
  <div class="">
    <span class="d-flex justify-content-start fs-6 fw-semibold text-gray-700"></span>
    Showing {{startValue}} to {{endValue}} of {{childMessage}} enteries
  </div>

  <div class="d-flex align-items-center justify-content-end fs-6 text-gray-700">
    <div class="display-number d-flex align-items-center me-2">
      <span class="me-0 fw-bold">
        Display
      </span>
      <ng-select class="" (change)="onChangePageOptions($event)" [searchable]="false" [clearable]="false"
        [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}">
        <ng-option *ngFor="let page of pageSizeOptions" [value]="page">
          {{page}}
        </ng-option>
      </ng-select>
    </div>

    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="childMessage" (pageChange)="getNext($event)"
      [(page)]="page" [maxSize]="5" [pageSize]="pageSize" [boundaryLinks]="true">
    </ngb-pagination>
  </div>
</div>
