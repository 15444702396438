import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AppConst } from '../../constants/app.constant';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  // MatPaginator Inputs
  length: any;
  pageSize = AppConst.pageSize;
  pageSizeOptions: number[] = AppConst.pageSizeOptions;
  pageEvent: PageEvent;
  offSet: any = 1;
  showFirstLastButtons: boolean = true;
  page = 1;
  pages = {
    pageSize: 10,
    pageIndex: 1
  }
  startValue: any = 1;
  endValue: any = 10;
  letPageLimitValue: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() childMessage: number;
  @Input() pagesize: number;
  @Input() index: number;
  @Output() messageEvent = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.pagesize) {
      this.pageSize = this.pagesize;
      this.pages.pageSize = this.pageSize;
    }
    if(this.index){
      this.page = this.index;
      this.pages.pageIndex = this.page;
    }
    this.startValue = (this.page - 1) * this.pageSize + 1;
    this.endValue = this.childMessage - ((this.page - 1) * this.pageSize) > this.pageSize ? (this.page - 1) * this.pageSize + this.pageSize : this.childMessage;
  }

  ngOnChanges() {
    if (this.childMessage < this.endValue) this.endValue = this.childMessage;
    else this.endValue = this.childMessage - ((this.page - 1) * this.pageSize) > this.pageSize ? (this.page - 1) * this.pageSize + this.pageSize : this.childMessage;
  }

  getNext(event: any) {
    this.page = event;
    this.pages.pageIndex = event;
    this.endValue = this.childMessage - ((this.page - 1) * this.pageSize) > this.pageSize ? (this.page - 1) * this.pageSize + this.pageSize : this.childMessage;
    this.startValue = (this.page - 1) * this.pageSize + 1;
    this.messageEvent.emit(this.pages);
  }

  onChangePageOptions(event: any) {
    this.pages.pageSize = event;
    this.pageSize = event;
    this.letPageLimitValue = event;
    this.endValue = event;
    this.getNext(1);
  }

}
