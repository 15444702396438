import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-automation-logs',
  templateUrl: './shared-automation-logs.component.html',
  styleUrls: ['./shared-automation-logs.component.scss']
})
export class SharedAutomationLogsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
