import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], args: string): unknown {
    return value.filter(x=>{
      JSON.stringify(x).toLowerCase().includes(args)
    })
  }

}
