import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedBondsViewConfigModalComponent } from '../shared-bonds-view-config-modal/shared-bonds-view-config-modal.component';

@Component({
  selector: 'app-shared-bond-view-dev-details',
  templateUrl: './shared-bond-view-dev-details.component.html',
  styleUrls: ['./shared-bond-view-dev-details.component.scss']
})
export class SharedBondViewDevDetailsComponent implements OnInit {
  show_eye: boolean = false;
  latest_tuning: any;
  bondPermission: any;

  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() bondDetails: any;
  @Input() bonderDetails: any;
  @Input() nodeDetails: any;

  @Output() getBondStatus = new EventEmitter<any>();
  @Output() getClassificationName = new EventEmitter<any>();

  constructor(private partnerBondingService: PartnerBondingService,
    private sharedService: SharedService, private storageService: StorageService,
    private cd: ChangeDetectorRef, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.sharedService.hideLoader();
    this.partnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
  }

  openWanServices() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.option = 4;
    modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.getBondStatus.emit();
        setTimeout(() => this.getClassificationName.emit(), 1500);
        this.cd.detectChanges();
      }
    }, () => { });
  }

}
