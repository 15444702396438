import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloader-table-list',
  templateUrl: './preloader-table-list.component.html',
  styleUrls: ['./preloader-table-list.component.scss']
})
export class PreloaderTableListComponent implements OnInit {
  rowList: any = [];

  @Input() length: number = 10;

  constructor() { }

  ngOnInit(): void {
    this.rowList = Array.from(Array(this.length).keys())
  }

}
