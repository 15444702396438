import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-alert-configuration-form',
  templateUrl: './alert-configuration-form.component.html',
  styleUrls: ['./alert-configuration-form.component.scss']
})
export class AlertConfigurationFormComponent implements OnInit {
  configurationForm: FormGroup;
  alertGroupData: any;
  length: any;
  bondId: number;
  loading: any = [];
  isMultiple: boolean = false;
  preLoaderScreen: boolean = true;
  activeAlertLegList: any = [];

  constructor(private sharedService: SharedService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getAllAlertList();
    this.activeLegList();
  }

  activeLegList() {
    if (!this.bondId) return;
    this.alertService.activeAlertLegList$?.subscribe((res: any) => {
      if (res && res?.length > 0) this.activeAlertLegList = res;
    });
  }

  getAllAlertList() {
    this.preLoaderScreen = true;
    this.alertService.getAllAlertListing()?.subscribe((res: any) => {
      if (res && res?.data) {
        res?.data?.forEach((alert: any) => {
          if (alert && alert.bondList && alert.bondList.length > 0) {
            alert.bondList = alert.bondList.map((bond: any) => bond.id);
            if (alert.bondList?.includes(this.bondId) || alert.bondList?.includes('' + this.bondId)) alert["isAlert"] = true;
            else alert["isAlert"] = false;
          } else alert["isAlert"] = false;
        })
        this.alertService.alertGroupData.next(res.data);
        this.alertGroupData = res.data;
        if (this.alertGroupData?.length > 0) this.length = this.alertGroupData?.length;
      }
      this.sharedService.hideLoader();
      this.preLoaderScreen = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.hideLoader();
      this.preLoaderScreen = false;
      this.cd.detectChanges();
    });
  }

  onChange(event: any, notificationId: any, bondId: any, index: any) {
    if (this.isMultiple) {
      this.alertGroupData?.forEach((alert: any) => {
        if (alert && alert._id == notificationId) alert["isAlert"] = event;
      })
    } else {
      let isAnyEnable = this.alertGroupData?.some((alert: any) => alert && alert._id != notificationId && alert.isAlert);
      if (!event && this.activeAlertLegList?.length > 0 && !isAnyEnable) {
        this.loading[index] = true;
        let text = "At least one alert group must remain active when there are active leg alerts."
        this.sharedService.swalError(text).then((result: any) => {
          this.alertGroupData?.forEach((alert: any) => {
            if (alert && alert._id == notificationId) alert["isAlert"] = !event;
          })
          this.loading[index] = false;
          this.cd.detectChanges();
        });
      } else {
        let body = { bondId: bondId, isAlert: event }
        this.loading[index] = true;
        this.alertService.updateAlert(body, notificationId)?.subscribe((res: any) => {
          if (res.code != 200) {
            this.sharedService.loggerSuccess('Configure alert not updated');
          } else {
            this.loading[index] = false;
            this.getAllAlertList();
          }
        }, (err: any) => {
          this.loading[index] = false;
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        });
      }
    }
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onSave() {
    let activeGroupList: any = [];
    this.alertGroupData.forEach((alert: any) => {
      if (alert && alert._id && alert.isAlert) activeGroupList.push(alert._id);
    });
    if (activeGroupList.length > 0) {
      this.activeModal.close({ event: 'save', list: activeGroupList });
    } else this.sharedService.loggerError('Please select at least one group');
  }
}
