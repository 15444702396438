import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

const secretKey = environment.secretKey;

@Injectable({ providedIn: 'root' })
export class StorageService {

  constructor() { }

  setCipherText(key: string, value: any) {
    const encrypted = CryptoJS.AES.encrypt(value + '', secretKey);
    localStorage.setItem(key, encrypted.toString());
  }

  getCipherText(key: string) {
    let encryptedText = localStorage.getItem(key);
    if (!encryptedText) return;
    let decrypted: any = CryptoJS.AES.decrypt(encryptedText, secretKey);
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    if (decrypted && (!isNaN(Number(decrypted)) || key == 'space')) return +decrypted;
    else return null
  }

  setCipherObj(key: string, value: any) {
    let objStr = JSON.stringify(value);
    const encrypted = CryptoJS.AES.encrypt(objStr, secretKey);
    localStorage.setItem(key, encrypted.toString());
  }

  getCipherObj(key: string) {
    let encryptedText = localStorage.getItem(key);
    if (!encryptedText) return null;
    let decrypted: any = CryptoJS.AES.decrypt(encryptedText, secretKey);
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    if (decrypted) return JSON.parse(decrypted);
    else return null
  }

  getCipherBoolean(key: string) {
    let encryptedText = localStorage.getItem(key);
    if (!encryptedText) return;
    let decrypted: any = CryptoJS.AES.decrypt(encryptedText, secretKey);
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted == 'true' ? true : false;
  }

  removeCipherText(key: string) {
    localStorage.removeItem(key);
  }

  setQOLParams(key: string, value: object) {
    let qolObj = this.getCipherObj('qol_params');
    if (!qolObj || Object.keys(qolObj).length <= 0) qolObj = {};
    qolObj[key] = value;
    this.setCipherObj('qol_params', qolObj);
  }

  getQOLParams(key: string) {
    let qolObj = this.getCipherObj('qol_params');
    if (qolObj && qolObj[key]) return qolObj[key];
    else return null
  }

  removeQOLParams(key: string) {
    let qolObj = this.getCipherObj('qol_params');
    if (qolObj && qolObj[key]) delete qolObj[key];
    if (Object.keys(qolObj).length > 0) this.setCipherObj('qol_params', qolObj);
    else localStorage.removeItem('qol_params');
  }

}
