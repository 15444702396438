import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { AuthService } from './auth.service';

enum User {
  admin,
  partner,
  business,
  endUser,
}
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private storageService: StorageService,
    private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = null;
    let role = this.storageService.getCipherObj('role');
    if (role || role == 0) {
      if (Number(role) === AppConst.basicUser || Number(role) === AppConst.admin) {
        currentUser = 'admin'
      } else if (Number(role) === AppConst.user) {
        currentUser = 'partner'
      } else if (Number(role) === AppConst.organization) {
        currentUser = 'partner'
      } else {
        currentUser = User[Number(role)];
      }
    }
    if (!this.storageService.getCipherObj('authToken')) {
      this.authService.logout();
      return false;
    }
    if (currentUser) {
      let currentURL: string = state.url;
      if (!currentURL.includes(currentUser)) {
        this.router.navigate([`${currentUser}/dashboard`]);
      }
      return true;
    }

    let logoutPath = this.storageService.getCipherObj('logoutPath');
    if (logoutPath) {
      this.storageService.removeCipherText('logoutPath');
      this.router.navigate([logoutPath]);
      return false;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
