import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/modules/shared/auth';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-suspended-org-list',
  templateUrl: './shared-suspended-org-list.component.html',
  styleUrls: ['./shared-suspended-org-list.component.scss']
})
export class SharedSuspendedOrgListComponent implements OnInit {
  authService = AuthService;

  @Input() organizationList: any[] = [];
  @Input() length: any;
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() page: number;
  @Input() limit: number;
  @Input() permissionsObj: any;
  @Input() preLoaderScreen: boolean = false;

  @Output() onSortFields = new EventEmitter<any>();
  @Output() receiveMessageEmitter = new EventEmitter<any>();
  @Output() unsuspend = new EventEmitter<any>();
  @Output() deleteUser = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef, private sharedService: SharedService) { }

  ngOnInit(): void { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  ngOnChanges(): void {
    this.cd.detectChanges();
  }

  onSort(event: any) {
    this.onSortFields.emit(event);
  }

  receiveMessage(data: any) {
    this.receiveMessageEmitter.emit(data);
  }

  onUnSuspend(id: any) {
    if (!id) return;
    let questionTitle = 'Are you sure?';
    let text = "<b>You want to unsuspend this organization.<br>This user will revert back! </b>"
    let confirmButtonText = "Yes, UnSuspend it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.unsuspend.emit(id);
      }
      this.cd.detectChanges();
    });
  }

  onDelete(userId: any) {
    if (!userId) return;
    let questionTitle = 'Are you sure?';
    let text = "<b>You want to Delete this organization.<br>It can't be revert back! </b> "
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.deleteUser.emit(userId);
      }
      this.cd.detectChanges();
    });
  }

}

