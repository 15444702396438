<div class="modal-header px-5 py-3">
    <h5 class="modal-title">Alert History </h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="card mb-5 mb-xl-0 alert-history">
    <div class="card-body p-xxl-2 p-md-2">
        <div class="table-responsive border">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-2 mb-0">
                <thead class="align-middle table-light">
                    <tr class="fw-bolder text-dark fs-6">
                        <th class="min-w-20px  py-4">No </th>
                        <th class="min-w-175px  py-4">Sent at </th>
                        <th class="min-w-200px py-4">Recipients </th>
                        <th class="min-w-250px py-4">Triggered by </th>
                    </tr>
                </thead>
                <tbody *ngIf="alertHistoryArr && alertHistoryArr?.length > 0 && !preLoaderScreen">
                    <tr *ngFor="let alertHistory of alertHistoryArr; let i=index " class="fs-6">
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="d-flex justify-content-start flex-column">
                                    <a class="text-dark">
                                        {{i+1}}
                                    </a>
                                </div>
                            </div>
                        </td>

                        <td>
                            <a class="text-dark d-block">
                                {{alertHistory.createdAt | date:'dd/MM/yyyy, h:mm a'}}
                            </a>
                        </td>
                        <td>
                            <span class="text-dark">
                                {{alertHistory.groupName}}
                                {{'('}}
                                <a *ngFor="let recipients of alertHistory.toEmail; let j=index " class="text-dark">
                                    <span *ngIf="j == 0">
                                        {{recipients}}
                                    </span>
                                    <span *ngIf="j != 0">
                                        {{', '+recipients}}
                                    </span>

                                </a>
                                {{')'}}
                            </span>
                        </td>

                        <td>
                            <a class="text-dark d-block">
                                <strong>{{alertHistory?.interfaceName == '' ?
                                    'Mobile Broadband' : alertHistory?.interfaceName}}</strong>
                                ({{alertHistory?.legId}}) {{alertHistory.triggeredBy}}
                            </a>
                        </td>

                    </tr>
                </tbody>
            </table>
            <app-preloader-table-list *ngIf="preLoaderScreen" [length]="3"></app-preloader-table-list>
            <div *ngIf="alertHistoryArr?.length == 0 && !preLoaderScreen" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder  fs-3 mb-1">No records found.</span>
                </h3>
            </div>
        </div>
        <!-- <app-pagination *ngIf="length > 0" [childMessage]="length" (messageEvent)="receiveMessage($event)"
                    class="bg-light">
                </app-pagination> -->
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>