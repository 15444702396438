import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-network-interdface-add',
  templateUrl: './shared-network-interdface-add.component.html',
  styleUrls: ['./shared-network-interdface-add.component.scss']
})
export class SharedNetworkInterdfaceAddComponent implements OnInit {
  typeInterface: any = "Ethernet";
  method: any;
  url: any;
  allInterfaces: any;
  bonderId: any;
  submitted = false;
  errors: any;
  isEthernetAdded: boolean = false;
  // isInterfaceFormInvalid:boolean=false;
  InterfaceAdd: FormGroup;
  arrBridge: any;
  bondName: any;
  bondId: any;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef, private storageService: StorageService,
    private partnerBondingService: PartnerBondingService) { }

  ngOnInit(): void {
    this.InterfaceAdd = this.createForm();
    if (this.allInterfaces && this.allInterfaces.length > 0) {
      this.allInterfaces.forEach((item: any) => {
        if (item && item.type == 'Ethernet') this.isEthernetAdded = true;
      });
    }
  }

  ngOnChanges() { this.cd.detectChanges(); }

  createForm(): FormGroup {
    return new FormGroup({
      type: new FormControl('',),
      ethernet_interface: new FormControl(null,),//if vlan
      vlan_tag: new FormControl('',),//vlan
      ifname: new FormControl('',),//ethernet vxlan
      Interface_mode: new FormControl('auto',),
      VNI: new FormControl('0',),
      Port: new FormControl('4789',),
      ethernet_name: new FormControl('',),
      multicast_group: new FormControl(null,),
      source_address: new FormControl(null,),
      ttl: new FormControl(null,),
      tos: new FormControl(null,),
      MAC_address: new FormControl('',),
      Interface_MTU: new FormControl(null,),
      endpoints: new FormArray([]),
      ageing_time: new FormControl(null,),
      stp: new FormControl(false,),
      hello_time: new FormControl(null,),
      forward_delay: new FormControl(null,),
      max_age: new FormControl(null,),
      priority: new FormControl(null,),
      bridge_ifname: new FormControl(null,),
      bridge: new FormControl(null,),
      note: new FormControl('', [this.sharedService.xssValidator]),
    });
  }

  get endpoints(): FormArray {
    return this.InterfaceAdd.get("endpoints") as FormArray
  }

  newEndpoints(): FormGroup {
    return new FormGroup({
      address: new FormControl(''),
      port: new FormControl('4789'),
      vni: new FormControl('0'),
    })
  }

  onRemove(index: number) {
    this.endpoints.removeAt(index);
  }

  onAdd() {
    this.endpoints.push(this.newEndpoints());
  }

  get f() {
    return this.InterfaceAdd.controls;
  }

  changeType(e: any) {
    this.typeInterface = e.target.value;
    this.cd.detectChanges()
  }

  getSuccess() {
    this.partnerBondingService.successForInterface$.subscribe((res: any) => {
      if (res && res.success) this.activeModal.close({});
    });
  }

  onSave() {
    this.submitted = true;
    let apiURL = this.storageService.getCipherObj('api_url');
    if (!apiURL) return;
    if (this.InterfaceAdd.invalid) return;
    let InterfaceData: any = {};
    let body: any = {};
    let node = `${apiURL}nodes/${this.bonderId}/`;
    if (this.typeInterface == 'Ethernet') {
      body = {
        type: this.typeInterface,
        ifname: this.InterfaceAdd.value.ifname,
        mode: this.InterfaceAdd.value.Interface_mode,
        mac: this.InterfaceAdd.value.MAC_address,
        node: node,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        status: null,
        bridge: this.InterfaceAdd.value.bridge,
        note: this.InterfaceAdd.value.note,
      }
    } else if (this.typeInterface == 'VLAN') {
      body = {
        ifname: this.InterfaceAdd.value.ifname,
        type: this.typeInterface,
        mode: this.InterfaceAdd.value.Interface_mode,
        mac: this.InterfaceAdd.value.MAC_address,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        ethernet_interface: this.InterfaceAdd.value.ethernet_interface,
        vlan_tag: this.InterfaceAdd.value.vlan_tag,
        bridge: this.InterfaceAdd.value.bridge,
        note: this.InterfaceAdd.value.note,
        node: node//"node_url" in bonder response,
      }
    } else if (this.typeInterface == 'VXLAN') {
      body = {
        type: this.typeInterface,
        mode: this.InterfaceAdd.value.Interface_mode,
        mac: this.InterfaceAdd.value.MAC_address,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        ip_addresses: null,
        space: null,
        ifname: this.InterfaceAdd.value.ifname,
        vni: this.InterfaceAdd.value.VNI,
        port: this.InterfaceAdd.value.Port,
        ethernet_interface: this.InterfaceAdd.value.ethernet_interface,
        multicast_group: this.InterfaceAdd.value.multicast_group ?? null,
        source_address: this.InterfaceAdd.value.source_address ?? null,
        ttl: this.InterfaceAdd.value.ttl ?? null,
        tos: this.InterfaceAdd.value.tos ?? null,
        endpoints: this.InterfaceAdd.value.endpoints,
        note: this.InterfaceAdd.value.note,
        bridge: this.InterfaceAdd.value.bridge,
        node: node//"node_url" in bonder response
      }
    } else {
      body = {
        type: this.typeInterface,
        ifname: this.InterfaceAdd.value.ifname,
        ageing_time: this.InterfaceAdd.value.ageing_time,
        stp: this.InterfaceAdd.value.stp,
        hello_time: this.InterfaceAdd.value.hello_time,
        forward_delay: this.InterfaceAdd.value.forward_delay,
        max_age: this.InterfaceAdd.value.max_age,
        priority: this.InterfaceAdd.value.priority,
        mac: this.InterfaceAdd.value.MAC_address,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        note: this.InterfaceAdd.value.note,
        node: node//"node_url" in bonder response,
      }
    }
    body.bondName = this.bondName;
    body.bondId = this.bondId;
    InterfaceData['method'] = "POST";
    InterfaceData['data'] = body;
    if (body.type == "Ethernet") InterfaceData['url'] = `bonders/${this.bonderId}/ethernet_interfaces/`;
    else if (body.type == "VLAN") InterfaceData['url'] = `bonders/${this.bonderId}/vlan_interfaces/`;
    else if (body.type == "VXLAN") InterfaceData['url'] = `bonders/${this.bonderId}/vxlan_interfaces/`;
    else InterfaceData['url'] = `bonders/${this.bonderId}/bridge_interfaces/`;
    this.sharedService.showLoader()
    this.partnerBondingService.addData(InterfaceData).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        this.partnerBondingService.successForInterface.next({ success: true })
        this.sharedService.loggerSuccess(addRes.message);
        this.allInterfaces = this.allInterfaces.sort((a: any, b: any) => +a.id - +b.id);
      } else this.sharedService.loggerError(addRes.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
      this.activeModal.close({ event: 'save' })
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.partnerBondingService.successForInterface.next({ success: false })
        Object.keys(this.errors).forEach(x => {
          let interfaceAddControl = this.InterfaceAdd.get(x);
          if (interfaceAddControl) {
            this.InterfaceAdd.setErrors({ ...this.InterfaceAdd.errors, [x]: this.errors[x] })
          }
        })
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
