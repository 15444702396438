
      <div *ngFor="let item of spaceList" class="col-md-12">
        <div class="d-flex bg-light justify-content-between align-items-center py-2 px-2 w-tree">
          <h5 class="m-0 px-2 text-hover fs-7 fw-bolder" (click)="onEdit(item)"> {{item.name}} </h5>
          <div class="d-flex">
            <a class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px border" (click)="onEdit(item)">
              <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'" class="svg-icon svg-icon-3 m-0 svg-icon-primary"> </span> </a>
            <a class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-30px w-30px border"  (click)="onAdd(item)"> <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-3 m-0 svg-icon-primary"></span> </a>
          </div>
        </div>
        <div *ngIf="item?.child_spaces" class="row mt-2 ps-10">
            <app-shared-space-listing [spaces]="item?.child_spaces"></app-shared-space-listing>
        </div>
      </div>
