import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedBondAuthorizationModalComponent } from '../../shared-bonds-create/shared-bond-authorization-modal/shared-bond-authorization-modal.component';
import { SharedBondsViewConfigModalComponent } from '../shared-bonds-view-config-modal/shared-bonds-view-config-modal.component';

@Component({
  selector: 'app-shared-bonds-view-node-details',
  templateUrl: './shared-bonds-view-node-details.component.html',
  styleUrls: ['./shared-bonds-view-node-details.component.scss']
})
export class SharedBondsViewNodeDetailsComponent {
  show_eye: boolean = false;
  latest_tuning: any;
  bondPermission: any;
  advancedConfigurationPermissionsObj: any = { view: true, update: true, delete: true };

  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() bondDetails: any;
  @Input() bonderDetails: any;
  @Input() nodeDetails: any;

  @Output() getBondStatus = new EventEmitter<any>();

  constructor(private modalService: NgbModal, private storageService: StorageService,
    private cd: ChangeDetectorRef, private sharedService: SharedService,
    private partnerBondingService: PartnerBondingService) { }

  ngOnInit(): void {
    this.partnerBondingService.latestTuningData$.subscribe((data: any) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
    this.getPermission();
  }

  getPermission() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.advancedConfigurationPermissionsObj = { view: true, update: true, delete: true }
    } else {
      this.sharedService.getPermission();
      if (this.sharedService.noPermission) this.advancedConfigurationPermissionsObj = { view: true, update: true, delete: true }
      else this.advancedConfigurationPermissionsObj = this.sharedService.advancedConfiguration;
    }
  }

  openTunnel() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.option = 5;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondStatus.emit();
      this.cd.detectChanges();
    }, () => { });
  }

  openNode() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.option = 6;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondStatus.emit();
      this.cd.detectChanges();
    }, () => { });
  }

  popupChangePasswordModal() {
    this.sharedService.showLoader();
    this.partnerBondingService.getBondAuth(this.bonderId)?.subscribe((res: any) => {
      this.sharedService.hideLoader();
      if (res.code == 200) {
        let modal = this.modalService.open(SharedBondAuthorizationModalComponent, { size: 'lg' });
        modal.componentInstance.bonderId = this.bonderId;
        modal.componentInstance.authData = res.data;
        modal.result.then((data) => {
          if (data && data?.event === 'save') this.getBondStatus.emit();
          this.cd.detectChanges();
        }, () => { });
      } else this.sharedService.loggerError(res.message);
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  calculateDifferenceOfDate(date: any) {
    if (!date) return '-'
    let startDate = new Date(date);
    let endDate = new Date();
    if (startDate > endDate) {
      let swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    let startYear = startDate.getFullYear();
    let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = -1;
    let minDiff = -1;
    let secDiff = -1;
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    } else hourDiff = endDate.getHours() - startDate.getHours();
    minDiff = endDate.getMinutes() - startDate.getMinutes();
    secDiff = endDate.getSeconds() - startDate.getSeconds();
    if (yearDiff == 1) return `About year ago`;
    else if (yearDiff > 1) return `${parseInt(yearDiff + '')} year ago`;
    else if (monthDiff == 1) return `About month ago`;
    else if (monthDiff > 1 && monthDiff <= 31) return `${parseInt(monthDiff + '')} month ago`;
    else if (dayDiff == 1) return `About day ago`;
    else if (dayDiff >= 1 && dayDiff <= 31) return `${parseInt(dayDiff + '')} day ago`;
    else if (hourDiff >= 1 && hourDiff <= 31) return `${parseInt(hourDiff + '')} hour ago`;
    else if (minDiff >= 1 && minDiff <= 59) return `${parseInt(minDiff + '')} minutes ago`;
    else if (secDiff >= 1 && secDiff <= 59) return `${parseInt(secDiff + '')} seconds ago`;
    else return '-';
  }

  Tunnel() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.option = 5;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondStatus.emit();
      this.cd.detectChanges();
    }, () => { });
  }

  keyFormate(key: string) {
    if (!key) return;
    return key.match(/.{1,5}/g)?.join('-');
  }

  timeconverter(sec: number) {
    if (sec < 60) return `${sec.toFixed()} seconds`;
    else {
      sec = sec / 60;
      if (sec < 60) return `${sec.toFixed()} minutes`;
      else {
        sec = sec / 60;
        if (sec < 60) return `${sec.toFixed()} hours`;
        else {
          sec = sec / 24;
          if (sec < 24) return `${sec.toFixed()} days`;
          else {
            sec = sec / 30;
            return `${sec.toFixed()} months`;
          }
        }
      }
    }
  }

}
