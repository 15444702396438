<div class="modal-header px-5 py-3">
    <h5 class="modal-title">{{isEdit ? 'Edit': 'Add'}} Connected IP</h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-0">
    <div class="card">
        <form [formGroup]="IpEdit">
            <div class="card-body pb-0">
                <div class="row" *ngIf="errors && errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors['non_field_errors']}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Interface <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The interface the IP will be assigned to. "></span></label>
                            <ng-select formControlName="interface" name="interface" placeholder="Select Interface"
                                class="dropdown-block">
                                <ng-option *ngFor="let item of InterfaceDetailArray;" [value]="item.id">
                                    {{item.ifname}} </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger"> {{errors?.interface?.join(' ')}} </span>
                                <span *ngIf="submitted==true && f.interface.invalid && f.interface.errors?.required"
                                    class="text-danger">
                                    This field is required.
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">IP <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The IPv4 or IPv6 address to assign, in CIDR format."></span></label>
                            <input formControlName="ip" name="ip" type="text" name="IP"
                                class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger"> {{errors?.ip?.join(' ')}} </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label"> Aggregator routing <span
                                    *ngIf="f.aggregator_routing.value == 'auto'"
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The aggregator will publish a route for the address only if it is a non-private address"></span>
                                <span *ngIf="f.aggregator_routing.value == 'always'"
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The aggregator will publish a route for the address unconditionally"></span>
                                <span *ngIf="f.aggregator_routing.value == 'never'"
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The aggregator will never publish a route for the address"></span>
                            </label>
                            <ng-select formControlName="aggregator_routing" name="aggregator_routing"
                                placeholder="Select Agg" class="dropdown-block" [clearable]="false"
                                [searchable]="false">
                                <ng-option value="auto"> Automatic </ng-option>
                                <ng-option value="always"> Always </ng-option>
                                <ng-option value="never"> Never </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger"> {{errors?.aggregator_routing?.join(' ')}} </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Note</label>
                            <input formControlName="note" name="note" type="text" name="note"
                                class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger"> {{errors?.note?.join(' ')}} </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-2 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Enabled <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Enable the connected IP. "></span></label>
                            <div class="d-flex flex-column mt-1">
                                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="enabled"
                                    name="enabled"> </ui-switch>
                                <small class="text-danger"> {{errors?.enabled?.join(' ')}} </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-2 mb-5">
                        <div class="form-group inputtext-block mb-1">
                            <label class="form-label">Use IPv6 link-local <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="If set, this IP will represent the automatically configured IPv6 link-local address."></span></label>
                            <div class="d-flex flex-column mt-1">
                                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                    formControlName="use_ipv6_link_local_address" name="ipv6"> </ui-switch>
                                <small class="text-danger"> {{errors?.use_ipv6_link_local_address?.join(' ')}} </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-2 mb-5">
                        <div class="form-group inputtext-block mb-1">
                            <label class="form-label">Include in private WAN <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip=" Publish a route for the address in private WAN."></span> </label>
                            <div class="d-flex flex-column mt-1">
                                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                    formControlName="include_private_wan" name="pwan"> </ui-switch>
                                <small class="text-danger"> {{errors?.include_private_wan?.join(' ')}} </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> {{ isEdit ? 'Save':'Add' }}
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>