import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import * as customEditor from 'src/app/ckeditor-build/build/ckeditor';
import { AuthService } from 'src/app/modules/shared/auth';
import { BrandingService } from 'src/app/services/branding.service';
import { PartnerEmailManagementService } from 'src/app/services/partner-email-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst, coreMailType, emailType } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-custom-email-list',
  templateUrl: './shared-custom-email-list.component.html',
  styleUrls: ['./shared-custom-email-list.component.scss']
})
export class SharedCustomEmailListComponent implements OnInit, OnDestroy {
  shared = SharedService;
  authService = AuthService;
  searchControl: FormControl = new FormControl();
  coreMailType = coreMailType;
  emailType = emailType;
  currentEditor = 'header';
  sortBy: any = 'createdAt';
  sortOrder: number = -1;
  customEmailTemplates: any[] = [];
  page: any = 1;
  limit: any = AppConst.pageSize;
  searchKey = '';
  customEmailTemplateLength: number = 0;
  templateTypeArr = AppConst.partnerMailList;
  dataOnReset: string = '';
  basicEditor: any;
  emailPreview: string = '';
  editor = customEditor;
  coreMailTypeArray: any[];
  closeResult: string;
  sendEmailTemplateForm: FormGroup;
  role: any;
  preLoaderScreen: boolean = false;

  @Output() deleteCustom = new EventEmitter<any>();
  @Output() editCustom = new EventEmitter<any>();

  @Input() permissionsObj: any;
  @Input() isAdmin: boolean = false;

  editorConfig = {
    toolbar: {
      items: [
        'heading', '|', 'alignment:left', "alignment:right", "alignment:center",
        "alignment:justify", 'fontfamily', 'fontcolor', 'fontbackgroundcolor',
        '|', "blockQuote", "bold", 'italic', "selectAll", "undo", "redo", "|",
        "htmlEmbed", "link", "mediaEmbed", "|", "insertImage",
        "imageStyle:inline"
        , "imageStyle:alignLeft"
        , "imageStyle:alignRight"
        , "imageStyle:alignCenter"
        , "imageStyle:alignBlockLeft"
        , "imageStyle:alignBlockRight"
        , "imageStyle:block"
        , "imageStyle:side"
        , "imageStyle:wrapText"
        , "imageStyle:breakText", "|",
        "indent", "outdent", "|",
        "numberedList", "bulletedList", "|",
        "sourceEditing", "|",
        "insertTable", "tableColumn", "tableRow", "mergeTableCells"
      ],
      shouldNotGroupWhenFull: true
    },
    language: 'en',
  }

  constructor(private sharedService: SharedService,
    private emailBrandingService: BrandingService,
    private storageService: StorageService,
    private partnerEmailManagementService: PartnerEmailManagementService,
    private router: Router, private cd: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this.storageService.setQOLParams(AppConst.qolKeyList.customeMailList, {
      page: this.page,
      pageSize: this.limit,
      searchKey: this.searchKey,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    });
  }

  ngOnInit(): void {
    this.role = this.storageService.getCipherObj('role');
    if (this.isAdmin) this.templateTypeArr = AppConst.adminMailList;
    else this.templateTypeArr = AppConst.partnerMailList;
    this.sharedService.hideLoader();
    this.setQolParams();
    this.onSearch();
    this.getAllCustomEmailTemplates();
    this.cd.detectChanges();
  }

  setQolParams() {
    let qolParams = this.storageService.getQOLParams(AppConst.qolKeyList.customeMailList);
    if (qolParams) {
      if (qolParams.page) this.page = qolParams.page;
      if (qolParams.pageSize) this.limit = qolParams.pageSize;
      if (qolParams.searchKey) {
        this.searchKey = qolParams.searchKey;
        this.searchControl.setValue(qolParams.searchKey);
      }
      if (qolParams.sortBy) this.sortBy = qolParams.sortBy;
      if (qolParams.sortOrder) this.sortOrder = qolParams.sortOrder;
    }
  }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  getAllCustomEmailTemplates() {
    if (this.isAdmin) {
      this.preLoaderScreen = true;
      this.emailBrandingService.getAllEmailTemplateListing(this.searchKey, this.page, this.limit, this.sortBy, this.sortOrder).subscribe((res: any) => {
        if (res?.data[0] && res.data[0]?.data) this.customEmailTemplates = res.data[0].data;
        if (res?.data[0] && res.data[0]?.data && res.data[0].data.length) this.customEmailTemplateLength = res.total_count;
        else this.customEmailTemplateLength = 0;
        this.preLoaderScreen = false;
        this.cd.detectChanges();
      }, (err) => {
        console.log(err);
        this.sharedService.loggerError(err);
        this.preLoaderScreen = false;
      });
    } else {
      let partnerId = this.storageService.getCipherObj('mainId')
      if (!partnerId) return;
      this.preLoaderScreen = true;
      this.partnerEmailManagementService.getCustomEmailTemplateListingPartner(this.searchKey, this.page, this.limit, this.sortBy, this.sortOrder, partnerId).subscribe((res: any) => {
        if (res?.data[0] && res.data[0]?.data) this.customEmailTemplates = res.data[0].data;
        if (res?.data[0] && res.data[0]?.data && res.data[0].data.length) this.customEmailTemplateLength = res.total_count;
        else this.customEmailTemplateLength = 0;
        this.preLoaderScreen = false;
        this.cd.detectChanges();
      }, (err) => {
        console.log(err);
        this.sharedService.loggerError(err);
        this.preLoaderScreen = false;
      });
    }
  }

  onAddCustomEmailTemplate() {
    let role = Number(this.storageService.getCipherObj('role'));
    if (role == AppConst.admin) {
      this.router.navigate(['/admin/configuration/email-management/create-custom'])
    } else if (role == AppConst.partner || role == AppConst.user) {
      this.router.navigate(['/partner/configuration/email-management/create-custom'])
    }
  }

  onEditCustomTemplate(templateId: any) {
    if (this.isAdmin) this.router.navigate([`/admin/configuration/email-management/edit-custom/${templateId}`]);
    else this.router.navigate([`/partner/configuration/email-management/edit-custom/${templateId}`])
  }

  onDeleteCustomTemplate(templateId: any) {
    if (!templateId) return;
    let partnerId = this.storageService.getCipherObj('mainId');
    let questionTitle = 'Are you sure?';
    let text = "<b>You are deleting this custom template. <br>It can't be revert back! </b>"
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        if (this.isAdmin) {
          this.sharedService.showLoader();
          this.emailBrandingService.deleteCustomEmailTemplate(templateId).subscribe((res: any) => {
            if (res) {
              let deletedMessage = 'Custom Email Template has been deleted.';
              let titleText = 'Deleted!'
              this.searchKey = '';
              this.sharedService.swalDelete(titleText, deletedMessage);
              this.customEmailTemplateLength = this.customEmailTemplateLength - 1;
              this.getAllCustomEmailTemplates();
            }
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          }, (err) => {
            console.log(err);
            this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
          })
        } else {
          if (!partnerId) return;
          this.sharedService.showLoader();
          this.partnerEmailManagementService.deleteCustomEmailTemplate(templateId, partnerId).subscribe((res: any) => {
            if (res) {
              let deletedMessage = 'Custom Email Template has been deleted.';
              let titleText = 'Deleted!'
              this.searchKey = '';
              this.sharedService.swalDelete(titleText, deletedMessage);
              this.customEmailTemplateLength = this.customEmailTemplateLength - 1;
              this.getAllCustomEmailTemplates();
            }
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          }, (err) => {
            console.log(err);
            this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
          })
        }
      }
    }, (err) => {
      console.log(err);
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    })
  }

  onSearch() {
    this.searchControl.valueChanges.pipe(debounceTime(800)).subscribe(data => {
      if (data != null || data != undefined) {
        this.searchKey = data;
        this.customEmailTemplates = [];
        this.customEmailTemplateLength = 0;
        this.cd.detectChanges();
        this.getAllCustomEmailTemplates();
      }
    })
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) event.preventDefault();
  }

  onResetFilter() {
    this.searchControl.patchValue('');
    this.searchKey = '';
    this.getAllCustomEmailTemplates();
  }

  receiveMessage(data: any) {
    this.limit = data.pageSize;
    this.page = data.pageIndex;
    this.getAllCustomEmailTemplates();
  }

  onSort(event: any) {
    if (this.customEmailTemplateLength <= 0) return
    if (this.sortBy == event) {
      this.sortOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      this.sortBy = event;
      this.sortOrder = 1;
    }
    this.getAllCustomEmailTemplates();
  }

  getTemplateTypeValue(templateTypeKey: any) {
    let templateTypeObj = this.templateTypeArr.find((element: any) => element.key === templateTypeKey);
    return templateTypeObj?.value;
  }
}
