<div class="card mb-5 mb-xl-10 border-1 border">
    <div class="card-body p-3">
        <div class="row" *ngIf="bondConfigPermission?.view">
            <div class="col-lg-6 col-xxl-6">
                <div class="card mb-5 mb-xl-0 border-1 border">
                    <div class="card-header cursor-pointer d-flex px-5 min-h-50px bg-light">
                        <div class="card-title m-0">
                            <h3 class="fw-bolder m-0">Node Information</h3>
                        </div>
                        <div class="me-2 card-toolbar d-flex align-items-center justify-content-end" title="Edit"
                            *ngIf="bondConfigPermission?.update">
                            <button type="button" class="btn btn-primary btn-style"
                                [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                                (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openDeviceEdit()">
                                <div>
                                    <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                                        class="svg-icon svg-icon-block mb-2">
                                    </span>
                                </div> Edit
                            </button>
                        </div>
                    </div>

                    <div class="card-body p-5">
                        <div class="row mb-1">
                            <div class="col-lg-12 col-md-12">
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                                        Device</label>
                                    <div class="col-lg-8 col-md-6">
                                        <span class="fs-7 text-dark">
                                            {{nodeDetails?.distro}} {{nodeDetails?.kernel}} {{nodeDetails?.cpu}}
                                            {{nodeDetails?.memory}}
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                                        Software version </label>
                                    <div class="col-lg-8 col-md-6">
                                        <span class="fs-7 text-dark">{{nodeDetails?.version}}</span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                                        Node key </label>
                                    <div class="col-lg-6 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark"> {{keyFormate(bondDetails?.bonder?.key)}} </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                                        Created</label>
                                    <div class="col-lg-6 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark">
                                            {{bondDetails?.created | date: 'MMM dd, y'}}</span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Circuit ID </label>
                                    <div class="col-lg-6 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark">{{bondDetails?.circuit_id}}</span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Product </label>
                                    <div class="col-lg-6 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark">{{bondDetails?.product}}</span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Serial number </label>
                                    <div class="col-lg-6 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark">{{bonderDetails?.serial_number}}</span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Asset tag </label>
                                    <div class="col-lg-6 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark">{{bonderDetails?.asset_tag}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xxl-6">
                <div class="card mb-5 mb-xl-0 border-1 border">
                    <div class="card-header cursor-pointer d-flex px-5 min-h-50px bg-light">
                        <div class="card-title m-0">
                            <h3 class="fw-bolder m-0">Site Address</h3>
                        </div>
                        <div class="me-2 card-toolbar d-flex align-items-center justify-content-end" title="Edit"
                            *ngIf="privateWANPermissionsObj?.update">
                            <button type="button" class="btn btn-primary btn-style"
                                [ngClass]="(bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                                (click)="(bondPermission && bondPermission.isUpdateDisable)  ? '': openCoordinateEdit()">
                                <div>
                                    <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                                        class="svg-icon svg-icon-block mb-2">
                                    </span>
                                </div> Edit
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-5">
                        <div class="row mb-1">
                            <div class="col-lg-12 col-md-12">
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                                        Latitude</label>
                                    <div class="col-lg-8 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondGeoData?.lat">
                                            {{bondGeoData?.lat}}
                                        </span>
                                        <span *ngIf="!bondGeoData?.lat" title="Unavailable">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                                        Longitude </label>
                                    <div class="col-lg-8 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondGeoData?.lang">
                                            {{bondGeoData?.lang}}
                                        </span>
                                        <span *ngIf="!bondGeoData?.lang" title="Unavailable">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!bondConfigPermission?.view">
            You have no permission to view the data. Kindly contact the authority.
        </div>
    </div>
</div>