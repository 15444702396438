<div class="modal-header px-5 py-3">
    <h4 class="modal-title" id="exampleModalLabel"> SD-WAN Sharing </h4>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5 min-h-275px">
    <div class="card mb-5">
        <div class="card-body p-0">
            <div class="row" *ngIf="errors && errors['non_field_errors']">
                <div class="col-md-12">
                    <div class="alert alert-danger">{{errors['non_field_errors']}}</div>
                </div>
            </div>
            <div class="row" *ngIf="errors && !errors['non_field_errors']">
                <div class="col-md-12">
                    <div class="alert alert-danger">Please correct the errors below.</div>
                </div>
            </div>
            <div class="row" *ngIf="orgList.length <= 0 && isOrganization && !isEdit">
                <div class="col-md-12">
                    <div class="alert alert-danger">Organize is required to share bond.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-12 mb-5" *ngIf="!isEdit">
                    <label class="form-label"> Organization <span class="required-asterisk m-0">*</span> </label>
                    <ng-select [(ngModel)]="selectedOrg" placeholder="Select Organization" *ngIf="!isOrgPreloader"
                        class="dropdown-block">
                        <ng-option value="" disabled selected>Select Organization</ng-option>
                        <ng-option *ngFor="let org of orgList;" [value]="org._id"> {{org.fname}} </ng-option>
                    </ng-select>
                    <div class="shine w-100" style="height: 60%;" *ngIf="isOrgPreloader">
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 mb-5 border-bottom-light pb-5">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="d-flex align-items-center">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                        [disabled]="(sdwanPermissions && sdwanPermissions?.isUpdateDisable == true) ? true : false"
                                        class="form-check-input" [(ngModel)]="sdwanPermissions.update" type="checkbox"
                                        value="{{(sdwanPermissions?.update == 'true') ? 'true' : 'false'}}"
                                        data-kt-check="true" data-kt-check-target=".widget-9-check">
                                    <label class="mb-0 form-label ms-2"> SD-WAN Edit Permission </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="d-flex align-items-center">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" name="use_cache" id=""
                                        [disabled]="isManageMeshRoutingDisable" [(ngModel)]="isManageMeshRouting">
                                    <label class="mb-0 form-label ms-2"> Manage Mesh Routing</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 mb-5 border-bottom-light pb-5">
                    <label class="form-label">Node Permission</label>
                    <div class="d-flex ">
                        <div class="d-flex gap-4 me-3">
                            <span>Update</span>
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                    [disabled]="(bondPermissions && bondPermissions?.isUpdateDisable == true) ? true : false"
                                    class="form-check-input" [(ngModel)]="bondPermissions.update" type="checkbox"
                                    data-kt-check="true" data-kt-check-target=".widget-9-check" />
                            </div>
                        </div>
                        <div class="d-flex gap-4">
                            <span>Delete</span>
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                    [disabled]="(bondPermissions && bondPermissions?.isDeleteDisable == true) ? true : false"
                                    class="form-check-input" [(ngModel)]="bondPermissions.delete" type="checkbox"
                                    data-kt-check="true" data-kt-check-target=".widget-9-check" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12">
                    <label class="form-label"> Node List </label>
                </div>
                <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0 shared-bond-list">
                    <thead class="align-middle table-light">
                        <tr class="fw-bolder text-dark text-capitalize">
                            <th class="ps-4 fs-7 py-4">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="isMasterSel"
                                        (change)="checkUncheckAll()">
                                </div>
                            </th>
                            <th class="py-4"> Node Id </th>
                            <th class="py-4"> Name </th>
                        </tr>
                    </thead>
                    <tbody class="fs-7" *ngIf="allBonds && allBonds.length > 0 && !isBondPreloader">
                        <tr *ngFor="let bond of allBonds; let i = index">
                            <td class="ps-4">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="bond.isChecked"
                                        name="list_name" />
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-star">
                                        <a class="text-dark  d-block fs-7">
                                            {{bond?.id}}
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center ">
                                    <div class="d-flex justify-content-start flex-column ">
                                        <a class="text-primary word-wrap d-block bond-name cursor-pointer fw-bolder">
                                            {{bond?.bonder?.name}}
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <app-preloader-table-list *ngIf="isBondPreloader" [length]="3"></app-preloader-table-list>
                <div *ngIf="!isBondPreloader && allBonds.length == 0" class="text-center font16 mt15 mb15 pt-4">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button [disabled]="!selectedOrg && !isEdit" type="button" class="btn btn-primary btn-style" (click)="onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/share-alt.svg'" class="svg-icon svg-icon-block mb-2"></span>
        </div> Share
    </button>
    <button type="button" class="btn btn-secondary btn-style" (click)="onClose()" data-bs-dismiss="modal">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>