import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-api',
  templateUrl: './shared-api.component.html',
  styleUrls: ['./shared-api.component.scss']
})
export class SharedApiComponent implements OnInit {
  formApi: FormGroup;
  @Input() allAPI: any;
  @Input() apiId: any;

  @Output() submit = new EventEmitter<any>();
  @Output() reset = new EventEmitter<any>();
  permissionsObj: { view: boolean; update: boolean; delete: boolean; };

  constructor(private storageService: StorageService,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getPermission();
    this.formApi = this.initForm(this.allAPI);
  }

  getPermission() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.admin || Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.permissionsObj = { view: true, update: true, delete: true }
    } else {
      this.sharedService.getPermission();
      if (this.sharedService.noPermission) {
        this.permissionsObj = { view: true, update: true, delete: true }
      } else {
        this.permissionsObj = this.sharedService.generalConfiguration;
        if (!this.permissionsObj) {
          this.permissionsObj = { view: true, update: true, delete: true }
        }
      }
    }
    this.cd.detectChanges();
  }



  initForm(data: any = {}) {
    console.log('data: ', data);
    return new FormGroup({
      basicAuth: new FormControl(data?.basicAuth?.flag || false),
      basicAuthAdminArea: new FormControl(data?.basicAuth?.adminArea || false),
      basicAuthUserArea: new FormControl(data?.basicAuth?.userArea || false),
      token: new FormControl(data?.token?.flag || false),
      tokenAdminArea: new FormControl(data?.token?.adminArea || false),
      tokenUserArea: new FormControl(data?.token?.userArea || false),
      publicPrivateKey: new FormControl(data?.publicPrivateKey?.flag || false),
      keyAdminArea: new FormControl(data?.publicPrivateKey?.adminArea || false),
      keyUserArea: new FormControl(data?.publicPrivateKey?.userArea || false)
    });
  }

  onCheckBoxChange(event: any, formControlName: string) {
    let flag = false;
    if (event.target.checked) flag = true;
    else flag = false;
    switch (formControlName) {
      case 'basicAuth':
        this.formApi.patchValue({
          basicAuthAdminArea: flag,
          basicAuthUserArea: flag
        })
        break;
      case 'token':
        this.formApi.patchValue({
          tokenAdminArea: flag,
          tokenUserArea: flag
        })
        break;
      case 'publicPrivateKey':
        this.formApi.patchValue({
          keyAdminArea: flag,
          keyUserArea: flag
        })
        break;
      case 'basicAuthAdminArea':
        this.formApi.patchValue({
          basicAuth: flag,
        })
        break;
      case 'basicAuthUserArea':
        this.formApi.patchValue({
          basicAuth: flag,
        })
        break;
      case 'tokenAdminArea':
        this.formApi.patchValue({
          token: flag,
        })
        break;
      case 'tokenUserArea':
        this.formApi.patchValue({
          token: flag,
        })
        break;
      case 'keyAdminArea':
        this.formApi.patchValue({
          publicPrivateKey: flag,
        })
        break;
      case 'keyUserArea':
        this.formApi.patchValue({
          publicPrivateKey: flag,
        })
        break;
      default:
        break;
    }

  }

  onSubmit() {
    let value = this.formApi.value;
    let obj = {
      basicAuth: {
        flag: value.basicAuth,
        adminArea: value.basicAuthAdminArea,
        userArea: value.basicAuthUserArea,
      },
      token: {
        flag: value.token,
        adminArea: value.tokenAdminArea,
        userArea: value.tokenUserArea,
      },
      publicPrivateKey: {
        flag: value.publicPrivateKey,
        adminArea: value.keyAdminArea,
        userArea: value.keyUserArea,
      }
    }
    this.submit.emit({ apiData: obj })
  }


  resetForm() {
    this.formApi.reset();
    // this.router.navigateByUrl('/admin/configuration/general-configuration/system');
    this.reset.emit();
  }

}
