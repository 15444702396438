<div class="card border-1 border border-top-0 bonds-status">
    <div class="card-body p-5" *ngIf="bondstatus?.bonder?.status != 'unknown'">
        <div class="row mb-1 align-items-center align-items-stretch m-0">

            <div class="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                <div class="row d-flex justify-content-center align-items-stretch">
                    <div class="col-md-12 d-flex">
                        <div class="statusArrow">
                            <span *ngIf="showSVG" [inlineSVG]="'./assets/media/icons/normal/arrow-alt-circle-up.svg'"
                                class="svg-icon svg-icon-1 svg-icon-success mb-2"> </span>
                            <circle-progress [percent]="getMaxLegSpeed(bondstatus?.upload_rate,true)"
                                [outerStrokeColor]="'#6fbadd'" [innerStrokeColor]="'#e7e8ea'" [animation]="true"
                                [showTitle]="true" [title]="calculateBytes(bondstatus?.upload_rate)"
                                [showSubtitle]="true" [titleColor]="'#6fbadd'" [animationDuration]="300"
                                [subtitle]="subTitle">
                            </circle-progress>
                        </div>
                        <div class="statusArrow">
                            <span *ngIf="showSVG" [inlineSVG]="'./assets/media/icons/normal/arrow-alt-circle-down.svg'"
                                class="svg-icon svg-icon-1 svg-icon-danger mb-2"> </span>
                            <circle-progress [percent]="getMaxLegSpeed(bondstatus?.download_rate,false)"
                                [outerStrokeColor]="'#769edf'" [innerStrokeColor]="'#e7e8ea'" [animation]="true"
                                [showTitle]="true" [title]="calculateBytes(bondstatus?.download_rate)"
                                [showSubtitle]="true" [titleColor]="'#769edf'" [animationDuration]="300"
                                [subtitle]="subTitle"></circle-progress>
                        </div>


                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="row mb-1">
                    <label class="col-lg-4 col-md-6 fw-bolder text-black fs-7 text-end separator-vertical">
                        Overall status </label>

                    <div class="col-lg-8 col-md-6 ">
                        <span *ngIf="bondstatus?.bonder?.status == 'down'" title="down">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                            </span> Problem detected:
                            <ul *ngIf="!bondstatus?.openvpn_connected">
                                <li> Management VPN not connected.</li>
                            </ul>
                        </span>
                        <span *ngIf="bondstatus?.bonder?.status == 'up'" title="up">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                class="svg-icon svg-icon-8 ml-2 me-1">
                            </span>
                        </span>
                    </div>
                </div>

                <div class="row mb-1">
                    <label class="col-lg-4 col-md-6  fw-bolder text-black fs-7 text-end separator-vertical">
                        Software version</label>
                    <div class="col-lg-8 col-md-6 ">
                        <span class="fs-7 text-dark fw-bold">{{bondstatus?.bonder?.version}}</span>
                    </div>
                </div>

                <div class="row mb-1">
                    <label class="col-lg-4 col-md-6  fw-bolder text-black fs-7 text-end separator-vertical">
                        Tunnel MTU</label>
                    <div class="col-lg-8 col-md-6 ">
                        <span class="fs-7 text-dark fw-bold" *ngIf="bondstatus?.tunnel_mtu?.aggregator">
                            <span *ngIf="bondstatus?.tunnel_mtu?.bonder >= 1280">
                                {{bondstatus?.tunnel_mtu?.aggregator}}
                            </span>
                            <span *ngIf="bondstatus?.tunnel_mtu?.bonder < 1280">
                                {{bondstatus?.tunnel_mtu?.bonder}} on bonder,
                                {{bondstatus?.tunnel_mtu?.aggregator}} on aggregator
                                <span class="badge badge-light-warning fw-bold w-100 ">
                                    {{bondstatus?.tunnel_mtu?.bonder}} below minimum MTU of 1280 for
                                    IPv6
                                    connectivity
                                </span>
                            </span>
                        </span>
                    </div>
                </div>

                <div class="row mb-1">
                    <label class="col-lg-4 col-md-6  fw-bolder text-black fs-7 text-end separator-vertical">
                        Downtime prevented</label>
                    <div class="col-lg-8 col-md-6 ">
                        <div> <span class="fs-7 text-dark fw-bolder">Last week</span> <span class="fs-7 text-dark"> :
                                {{calculateHours(downTimeData?.week?.duration)}}
                                hours, {{calculateMinutes(downTimeData?.week?.duration)}} minutes
                                {{downTimeData?.week?.count}} outages </span> </div>
                        <div> <span class="fs-7 text-dark fw-bolder">Last month</span><span class="fs-7 text-dark"> :
                                {{calculateHours(downTimeData?.month?.duration)}}
                                hours, {{calculateMinutes(downTimeData?.month?.duration)}} minutes
                                {{downTimeData?.month?.count}} outages</span> </div>
                    </div>
                </div>

                <div class="row mb-1" *ngIf="bondstatus?.tunnel_security == 'encryption'">
                    <label class="col-lg-4 col-md-6  fw-bolder text-black fs-7 text-end separator-vertical">
                        Encryption </label>
                    <div class="col-lg-8 col-md-6 ">
                        <div>
                            <span class="fs-7 text-dark fw-bold"> Protocol : </span>
                            <span class="text-dark fs-7">
                                {{calculateDifferenceOfDate(bondstatus?.tunnel_service_started_bonder)}}
                            </span>
                        </div>
                        <div>
                            <span class="fs-7 text-dark fw-bold"> Cipher : </span>
                            <span class="text-dark fs-7">
                                {{calculateDifferenceOfDate(bondstatus?.tunnel_service_started_bonder)}}
                            </span>
                        </div>
                        <div>
                            <span class="fs-7 text-dark fw-bold"> MAC : </span>
                            <span class="text-dark fs-7">
                                {{calculateDifferenceOfDate(bondstatus?.tunnel_service_started_bonder)}}
                            </span>
                        </div>
                        <div>
                            <span class="fs-7 text-dark fw-bold"> Key exchange : </span>
                            <span class="text-dark fs-7">
                                {{calculateDifferenceOfDate(bondstatus?.tunnel_service_started_bonder)}}
                            </span>
                        </div>
                        <div>
                            <span class="fs-7 text-dark fw-bold"> Replay protection : </span>
                            <span class="text-dark fs-7">
                                {{calculateDifferenceOfDate(bondstatus?.tunnel_service_started_bonder)}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row mb-1">
                    <label class="col-lg-4 col-md-6  fw-bolder text-black fs-7 text-end separator-vertical">
                        Node ID</label>
                    <div class="col-lg-8 col-md-6 ">
                        <span class="fs-7 text-dark fw-bold">{{bondstatus?.id}}</span>
                    </div>
                </div>

                <div class="row mb-1">
                    <label class="col-lg-4 col-md-6  fw-bolder text-black fs-7 text-end separator-vertical">
                        Tunnel started</label>
                    <div class="col-lg-8 col-md-6 ">
                        <div>
                            <span class="fs-7 text-dark fw-bold"> Bonder : </span>
                            <span class="text-dark fs-7">
                                {{calculateDifferenceOfDate(bondstatus?.tunnel_service_started_bonder)}}
                            </span>
                        </div>
                        <div>
                            <span class="fs-7 text-dark fw-bold"> Aggregator : </span> <span class="text-dark">
                                {{calculateDifferenceOfDate(bondstatus?.tunnel_service_started_agg)}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row mb-1" *ngIf="bondstatus?.bridge_enabled">
                    <label class="col-lg-4 col-md-6  fw-bolder text-black fs-7 text-end separator-vertical">
                        TCP Proxy Started</label>
                    <div class="col-lg-8 col-md-6 ">
                        <div> <span class="fs-7 text-dark fw-bold "> Bonder : </span>
                            <span class="text-dark fs-7">
                                {{calculateDifferenceOfDate(bondstatus?.bridge_service_started_bonder)}}
                            </span>
                        </div>
                        <div> <span class="fs-7 text-dark fw-bold"> Aggregator : </span>
                            <span class="text-dark">
                                {{calculateDifferenceOfDate(bondstatus?.bridge_service_started_agg)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="row">
            <div class="col-lg-12 col-xxl-12">
                <div class="card mb-5 mb-xl-0 border-1 border">
                    <div class="card-header cursor-pointer d-flex justify-content-start px-5 min-h-50px bg-light">
                        <div class="card-title m-0">
                            <h3 class="fw-bolder m-0">Status</h3>
                        </div>
                    </div>

                    <div class="card-body p-5 pb-0">
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row mb-1 pt-1" *ngIf="!isQuickView">
            <div class="col-lg-12 col-md-12 py-3 border-bottom border-bottom-1" *ngIf="allLegs && allLegs?.length > 0">
                <div class="table-responsive border">
                    <!-- begin::Table -->
                    <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-2 mb-0">
                        <!-- <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-2 mb-0 legs-table-inner-status table-fixed"> -->
                        <thead class="table-light">
                            <tr class="fw-bolder text-dark fs-7">
                                <!-- <th class="py-4"> ID </th> -->
                                <th class="py-4" style="width:170px">Interface </th>
                                <th class="py-4" style="width:120px">Status </th>
                                <th class="py-4" style="width:200px"> Last 24 hours </th>
                                <th class="py-4" style="width:200px">Summary </th>
                                <th class="py-4" style="width:250px">Rates </th>
                                <th class="py-4" style="width:250px">Note </th>
                                <!-- <th class="py-4">Limits </th> -->
                            </tr>
                        </thead>

                        <tbody *ngIf="allLegs &&  allLegs?.length > 0">
                            <tr *ngFor="let legs of allLegs;">
                                <!-- <td> {{ legs.id }} </td> -->
                                <td> {{ legs?.type == 'Interface' ? legs.interface ? legs.interface: legs.ifname :
                                    'Mobile broadband' }} </td>
                                <td class="text-start">
                                    <span *ngIf="legs?.aggregator_status && legs.link_mode!='idle'">
                                        <a *ngIf="legs?.aggregator_status?.state == 'down'" title="down">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                        <a *ngIf="legs?.aggregator_status?.state == 'pending'" title="pending">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                        <a *ngIf="legs?.aggregator_status?.state == 'ssl init waiting for client'"
                                            title="ssl init waiting for client">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/info-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                        <a *ngIf="legs?.aggregator_status?.state == 'unknown'" title="unknown">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                        <a *ngIf="legs?.aggregator_status?.state == 'up' || legs?.aggregator_status?.state == 'ssl up'"
                                            title="up">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                    </span>
                                    <span *ngIf="!legs?.aggregator_status && legs.link_mode!='idle'">
                                        <a title="unknown">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                    </span>
                                    <span *ngIf="legs.link_mode=='idle'">
                                        <a *ngIf="legs.link_mode == 'idle'" title="idle">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                    </span>

                                    <span *ngIf="alertPermission">
                                        <a title="enable alert" *ngIf="!legs.isAlertAPI && !legs.isAlert"
                                            class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-25px w-25px svg-icon svg-icon-4 br-6"
                                            [ngClass]="legs.link_mode != 'active' || !monitorIcon? 'disable-tune': '' "
                                            (click)="legs.link_mode != 'active' || !monitorIcon ? '': addMonitor(legs.id, legs?.aggregator_status?.state)">
                                            <span [inlineSVG]="'./assets/media/icons/normal/bell-slash.svg'"> </span>
                                        </a>
                                        <a *ngIf="legs.isAlertAPI"
                                            class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-25px w-25px svg-icon svg-icon-4 br-6">
                                            <mat-spinner style="height: 16px;width: 16px;"></mat-spinner>
                                        </a>
                                        <a title="disable alert" *ngIf="!legs.isAlertAPI && legs.isAlert"
                                            class="btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-2 h-25px w-25px svg-icon svg-icon-4 br-6"
                                            (click)="removeMonitor(legs.id)">
                                            <span [inlineSVG]="'./assets/media/icons/normal/bell.svg'"> </span>
                                        </a>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="legs?.stateHistory && legs?.stateHistory?.states.length > 0">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" [style.width.%]="state[3]"
                                                [ngClass]="state[2] == 'down' ? 'bg-danger':
                                                state[2] == 'ssl init waiting for client' ? 'status-ssl-init':
                                                (state[2] == 'up' || state[2] == 'ssl init') ? 'bg-success':
                                                'bg-secondary'" *ngFor="let state of legs?.stateHistory?.states;"
                                                [title]="state[2] + ' for ' +getTextForProgressBar(state[0],state[1])">
                                            </div>
                                        </div>
                                    </span>
                                    <span *ngIf="legs?.stateHistory?.states.length <= 0">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                aria-valuemin="0" aria-valuemax="100" title="Unknown for 24 hours">
                                            </div>
                                        </div>
                                    </span>
                                </td>
                                <td class="fs-7 text-dark">
                                    <table class="w-100">
                                        <tbody>
                                            <tr class="border-0">
                                                <!-- <td *ngIf="legs?.aggregator_status?.state == 'ssl init'"
                                                    class="border-none"> SSL initializing. </td> -->
                                                <td *ngIf="legs?.link_mode == 'offline'" class="border-none">
                                                    Offline </td>
                                            </tr>
                                            <tr class="border-0">
                                                <!-- <td *ngIf="legs.aggregator_status?.state == 'unknown'"
                                                    class="border-none p-0">
                                                    Offline
                                                </td> -->
                                                <td *ngIf="legs?.link_mode == 'idle'" class="border-none">
                                                    Idle
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="legs.failover" class="border-none p-0"> Failover
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td class="border-none p-0" *ngIf="legs.tunnel_bypass_enabled">
                                                    <span *ngIf="legs.tunnel_bypass_priority == 1">
                                                        <span
                                                            class="badge badge-light bg-light-primary text-primary-1 mx-2">
                                                            Tunnel bypass
                                                            <span *ngIf="legs.tunnel_bypass_percentage">
                                                                {{ legs.tunnel_bypass_percentage }}%
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span *ngIf="legs.tunnel_bypass_priority != 1">
                                                        Tunnel bypass
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="legs.bandwidth_adaptation" class="border-none p-0"> Bandwidth
                                                    adaptation
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <!-- <td *ngIf="!legs.bonder_status" class="border-none p-0"> Bonder has
                                                    not reported status for this leg.
                                                </td> -->
                                                <td *ngIf="legs.failbackMessage" class="border-none p-0">
                                                    <span class="alert-text d-flex align-items-center">
                                                        <span
                                                            [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-triangle.svg'"
                                                            class="svg-icon svg-icon-5 me-2"></span>
                                                        {{legs?.failbackMessage}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr class="border-0"
                                                *ngIf="legs.bonder_status?.flapping == 'flapping down'">
                                                <td class="border-none">
                                                    <span
                                                        class="badge badge-light bg-light-warning text-warning">Flapping</span>
                                                </td>
                                            </tr>
                                            <tr class="border-0"
                                                *ngIf="latest_tuning?.status == 'running' && latest_tuning?.legId == legs?.id">
                                                <td class="border-none">
                                                    <span class="moving-border-primary">Tuning running
                                                        <span
                                                            [inlineSVG]="'./assets/media/icons/duotune/general/gen034.svg'"
                                                            class="svg-icon svg-icon-danger svg-icon-5 cursor-pointer"
                                                            title="Cancel Tuning"
                                                            (click)="cancelTuning(legs?.id)"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr class="border-0"
                                                *ngIf="latest_tuning?.status == 'tuned' && latest_tuning?.legId == legs?.id">
                                                <td class="border-none" (click)="viewTuning(legs?.id)">
                                                    <span class="cursor-pointer moving-border-success">Tuning completed
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="legs.provider_name" class="border-none p-0">
                                                    profile: {{legs.provider_name}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <span class="d-flex">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                                            class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-dark">
                                        </span>
                                        <span *ngIf="legs?.aggregator_status?.tx_rate >= 1000">
                                            {{(legs?.aggregator_status?.tx_rate / 1000).toFixed(1)}}
                                            Kbps
                                        </span>
                                        <span *ngIf="legs?.aggregator_status?.tx_rate < 1000">
                                            {{legs?.aggregator_status?.tx_rate.toFixed(1)}} Bps
                                        </span>
                                    </span>
                                    <span class="d-flex">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                                            class="svg-icon svg-icon-6 ml-2 me-1 svg-icon-dark">
                                        </span>
                                        <span *ngIf="legs?.aggregator_status?.rx_rate >= 1000">
                                            {{(legs?.aggregator_status?.rx_rate / 1000).toFixed(1)}}
                                            Kbps
                                        </span>
                                        <span *ngIf="legs?.aggregator_status?.rx_rate < 1000">
                                            {{legs?.aggregator_status?.rx_rate.toFixed(1)}} Bps
                                        </span>
                                    </span>
                                </td>
                                <td>
                                    <div class="status_note"> {{ legs?.note != '' ? legs?.note : '-' }} </div>
                                </td>
                                <!-- <td>
                                    <span class="badge badge-light bg-light-danger text-danger p-1 mx-2">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1 svg-icon-danger">
                                        </span> {{ legs.down_speed.toFixed(1) }} Mbps
                                    </span>
                                    <span class=" badge badge-light mt-2 bg-light-success text-success p-1 mx-2">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1 svg-icon-success">
                                        </span> {{ legs.up_speed.toFixed(1) }} Mbps
                                    </span>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <!-- mobile broadband start-->
                    <!-- <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-2 mb-0 legs-table-inner-status table-fixed">
                        <tbody *ngIf="allMobileBroadband && allMobileBroadband.length > 0">
                            <tr *ngFor="let mobile of allMobileBroadband;">
                                <td> {{ mobile.id }} </td>
                                <td class="text-center">
                                    <span *ngIf="mobile?.aggregator_status">
                                        <a *ngIf="mobile?.aggregator_status?.state == 'down' " title="down">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                        <a *ngIf="mobile?.aggregator_status?.state == 'ssl init waiting for client'"
                                            title="ssl init waiting for client">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/info-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                        <a *ngIf="mobile?.aggregator_status?.state == 'up'" title="up">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                        <a *ngIf="mobile?.aggregator_status?.state == 'unknown'" title="unknown">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                    </span>
                                    <span *ngIf="!mobile?.aggregator_status">
                                        <a title="unknown">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>
                                        </a>
                                    </span>
                                </td>

                                <td>
                                    <span *ngIf="mobile?.stateHistory && mobile?.stateHistory?.states.length > 0">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" [style.width.%]="state[3]"
                                                [ngClass]="state[2] == 'down' ? 'bg-danger':
                                            state[2] == 'ssl init waiting for client' ? 'status-ssl-init':
                                            (state[2] == 'up' || state[2] == 'ssl init') ? 'bg-success':
                                          'bg-secondary'" *ngFor="let state of mobile?.stateHistory?.states;"
                                                [title]="state[2] + ' for ' +getTextForProgressBar(state[0],state[1])">
                                            </div>
                                        </div>
                                    </span>
                                    <span *ngIf="mobile?.stateHistory?.states.length <= 0">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                aria-valuemin="0" aria-valuemax="100" title="Unknown for 24 hours">
                                            </div>
                                        </div>
                                    </span>
                                </td>

                                <td class="fs-7 text-dark">
                                    <table class="w-100">
                                        <tbody>
                                            <tr class="border-0">
                                                <td *ngIf="mobile?.aggregator_status?.state == 'ssl init'"
                                                    class="border-none p-0">
                                                    SSL initializing. </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="mobile?.aggregator_status?.state == 'unknown'"
                                                    class="border-none p-0">
                                                    Offline
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="mobile?.failover" class="border-none p-0">
                                                    Failover </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="mobile?.bandwidth_adaptation" class="border-none p-0">
                                                    Bandwidth adaptation
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="mobile?.tunnel_bypass_enabled" class="border-none p-0">
                                                    Tunnel bypass </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="!mobile.bonder_status" class="border-none p-0"> Bonder has
                                                    not reported status for this leg.
                                                </td>
                                            </tr>
                                            <tr class="border-0">
                                                <td *ngIf="mobile.provider_name" class="border-none p-0">
                                                    profile: {{mobile.provider_name}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>

                                <td> {{ mobile?.ifname }} </td>
                                <td>
                                    <span class="badge badge-light bg-light-danger text-danger p-1 mx-2">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1 svg-icon-danger">
                                        </span>
                                        <span *ngIf="mobile?.aggregator_status?.tx_rate >= 1000">
                                            {{(mobile?.aggregator_status?.tx_rate / 1000).toFixed(1)}}
                                            Kbps
                                        </span>
                                        <span *ngIf="mobile?.aggregator_status?.tx_rate < 1000">
                                            {{mobile?.aggregator_status?.tx_rate.toFixed(1)}} Bps
                                        </span>
                                    </span>
                                    <span class="badge badge-light mt-2 bg-light-success text-success p-1 mx-2">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1 svg-icon-success">
                                        </span>
                                        <span *ngIf="mobile?.aggregator_status?.rx_rate >= 1000">
                                            {{(mobile?.aggregator_status?.rx_rate / 1000).toFixed(1)}}
                                            Kbps
                                        </span>
                                        <span *ngIf="mobile?.aggregator_status?.rx_rate < 1000">
                                            {{mobile?.aggregator_status?.rx_rate.toFixed(1)}} Bps
                                        </span>
                                    </span>
                                </td>

                                <td>
                                    <span class="badge badge-light bg-light-danger text-danger p-1 mx-2">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr004.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1 svg-icon-danger">
                                        </span> {{ mobile?.down_speed?.toFixed(1) }} Mbps
                                    </span>
                                    <span class=" badge badge-light mt-2 bg-light-success text-success p-1 mx-2">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr003.svg'"
                                            class="svg-icon svg-icon-8 ml-2 me-1 svg-icon-success">
                                        </span> {{ mobile?.up_speed?.toFixed(1) }} Mbps
                                    </span>
                                </td>

                            </tr>
                        </tbody>
                    </table> -->
                    <!-- mobile broadband End-->
                </div>
            </div>

            <div class="col-lg-12 col-md-12 pt-2">
                <h4 class="mt-5">Tunnel Data Traffic</h4>
                <div id="tunnelChart" style="height: 230px; width: 100%; margin:auto;" *ngIf="!tunnelTrafficLoader">
                </div>
                <div class="d-flex align-items-center justify-content-center" *ngIf="tunnelTrafficLoader"
                    style="height: 230px; width: 100%; margin:auto;">
                    <img src="./assets/media/icons/normal/chart-loader.gif">
                </div>
            </div>

        </div>
    </div>

    <div class="card-body p-5" *ngIf="bondstatus?.bonder?.status == 'unknown'">
        <div class="row mb-1">
            <div class="col-lg-6 col-md-7">

                <div class="row mb-1">
                    {{ bondstatus?.name}} has never been online.
                </div>
            </div>
        </div>
    </div>
</div>