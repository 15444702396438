import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import * as customEditor from 'src/app/ckeditor-build/build/ckeditor';
import { AuthService } from 'src/app/modules/shared/auth';
import { BrandingService } from 'src/app/services/branding.service';
import { PartnerEmailManagementService } from 'src/app/services/partner-email-management.service';
import { PartnerManagmentService } from 'src/app/services/partner-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst, coreMailType, emailType } from '../../constants/app.constant';
import { SharedService } from '../../services/shared.service';
import { SharedEmailSendFormComponent } from './shared-email-send-form/shared-email-send-form.component';

@Component({
  selector: 'app-shared-email-management',
  templateUrl: './shared-email-management.component.html',
  styleUrls: ['./shared-email-management.component.scss']
})
export class SharedEmailManagementComponent implements OnInit {
  coreMailType = coreMailType;
  shared = SharedService;
  emailType = emailType;
  currentEditor = 'header';
  searchControl: FormControl = new FormControl();
  authService = AuthService;


  sortBy: any = 'createdAt';
  sortOrder: number = -1;
  emailTemplates: any[] = [];
  page: any = 1;
  limit: any = AppConst.pageSize;
  searchKey = '';
  length: number = 0;
  emailTemplate: any;
  partnerList: any[] = [];
  templateId: any;
  // selectedTab: any;

  @Output() delete = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() sendPopUp = new EventEmitter<any>();

  @Input() permissionsObj: any;
  @Input() isAdmin: boolean = false;


  editorConfig = {
    toolbar: {
      items: [
        'heading', '|', 'alignment:left', "alignment:right", "alignment:center",
        "alignment:justify", 'fontfamily', 'fontcolor', 'fontbackgroundcolor',
        '|', "blockQuote", "bold", 'italic', "selectAll", "undo", "redo", "|",
        "htmlEmbed", "link", "mediaEmbed", "|", "insertImage",
        "imageStyle:inline"
        , "imageStyle:alignLeft"
        , "imageStyle:alignRight"
        , "imageStyle:alignCenter"
        , "imageStyle:alignBlockLeft"
        , "imageStyle:alignBlockRight"
        , "imageStyle:block"
        , "imageStyle:side"
        , "imageStyle:wrapText"
        , "imageStyle:breakText", "|",
        "indent", "outdent", "|",
        "numberedList", "bulletedList", "|",
        "sourceEditing", "|",
        "insertTable", "tableColumn", "tableRow", "mergeTableCells"
      ],
      shouldNotGroupWhenFull: true
    },
    language: 'en',
  }



  // Set Editor to ClassicEditor with given functionality

  dataOnReset: string = '';
  basicEditor: any;
  emailPreview: string = '';
  editor = customEditor;
  coreMailTypeArray: any[];
  closeResult: string;
  sendEmailTemplateForm: FormGroup;
  role: any;

  constructor(private sharedService: SharedService,
    private partnerEmailManagementService: PartnerEmailManagementService,
    private partnerManagementService: PartnerManagmentService,
    private router: Router, private cd: ChangeDetectorRef,
    private emailBrandingService: BrandingService,
    private modalService: NgbModal, private storageService: StorageService) { }

  ngOnInit(): void {
    this.role = this.storageService.getCipherObj('role');
    this.onSearch();
    this.getAllEmailTemplates();
    this.cd.detectChanges();
  }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  getAllEmailTemplates() {
    if (this.isAdmin) {
      this.sharedService.showLoader();
      this.emailBrandingService.getEmailTemplateListing(this.searchKey, this.page, this.limit, this.sortBy, this.sortOrder).subscribe((res: any) => {
        if (res && res.data && res.data[0] && res.data[0].data) this.emailTemplates = res.data[0].data;
        if (res?.data[0] && res?.data[0].data) this.length = res.total_count;
        else this.length = 0;
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        console.log(err);
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      });
    } else {
      let partnerId = this.storageService.getCipherObj('mainId')
      if (!partnerId) return;
      this.sharedService.showLoader();
      this.partnerEmailManagementService.getEmailTemplateListingPartner(this.searchKey, this.page, this.limit, this.sortBy, this.sortOrder, partnerId).subscribe((res: any) => {
        if (res && res.data && res.data[0] && res.data[0].data) this.emailTemplates = res.data[0].data;
        if (res?.data[0] && res?.data[0].data) this.length = res.total_count;
        else this.length = 0;
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        console.log(err);
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      });
    }
  }

  onEditTemplate(templateId: any) {
    if (this.isAdmin) this.router.navigate([`/admin/configuration/email-management/edit/${templateId}`])
    else this.router.navigate([`/partner/configuration/email-management/edit/${templateId}`]);
  }

  onAddEmailTemplate() {
    let role = Number(this.storageService.getCipherObj('role'));
    if (role == AppConst.admin) {
      this.router.navigate(['/admin/configuration/email-management/create'])
    }
    else if (role == AppConst.partner || role == AppConst.organization || role == AppConst.user) {
      this.router.navigate(['/partner/configuration/email-management/create'])
    }
  }

  onDeleteTemplate(templateId: any) {
    if (!templateId) return;
    let questionTitle = 'Are you sure?';
    let text = "<b>You are deleting this template. <br>It can't be revert back! </b>"
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        if (this.isAdmin) {
          this.emailBrandingService.deleteEmailTemplate(templateId).subscribe((res: any) => {
            if (res) {
              let deletedMessage = 'Email Template has been deleted.';
              let titleText = 'Deleted!'
              this.searchKey = '';
              this.sharedService.swalDelete(titleText, deletedMessage);
              this.length = this.length - 1;
              this.getAllEmailTemplates();
            }
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          }, (err) => {
            console.log(err);
            this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
          });
        } else {
          let partnerId = this.storageService.getCipherObj('mainId')
          if (!partnerId) return;
          this.sharedService.showLoader();
          this.partnerEmailManagementService.deleteEmailTemplatePartner(templateId, partnerId).subscribe((res: any) => {
            if (res) {
              let deletedMessage = 'Email Template has been deleted.';
              let titleText = 'Deleted!'
              this.searchKey = '';
              this.sharedService.swalDelete(titleText, deletedMessage);
              this.length = this.length - 1;
              this.getAllEmailTemplates();
            }
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          }, (err) => {
            console.log(err);
            this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
          });
        }
      }
    });
  }

  open(id: any) {
    let partnerId = this.storageService.getCipherObj('mainId')
    this.templateId = id;
    this.sharedService.showLoader();
    if (this.isAdmin) {
      this.partnerEmailManagementService.getEmailTemplateById(id).subscribe((res: any) => {
        if (res && res.data) {
          this.emailTemplate = res.data;
          this.getAllPartners();
        } else this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        console.log(err);
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      });
    } else {
      this.partnerEmailManagementService.getEmailTemplateByIdPartner(id, partnerId).subscribe((res: any) => {
        if (res && res.data) {
          this.emailTemplate = res.data;
          this.getAllPartners();
        } else this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        console.log(err);
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      });
    }
  }

  getAllPartners() {
    this.partnerManagementService.getAllPartners('', 'true', '', '', 'createdAt', '-1').subscribe((res: any) => {
      this.sharedService.hideLoader();
      if (res && res.data) {
        this.partnerList = res.data;
        this.cd.detectChanges();
        let modal = this.modalService.open(SharedEmailSendFormComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
        modal.componentInstance.partnerList = this.partnerList;
        modal.componentInstance.emailTemplate = this.emailTemplate;
        let partnerId = this.storageService.getCipherObj('mainId')
        modal.closed.subscribe((result: any) => {
          if (this.isAdmin) {
            if (!result || !this.templateId) return;
            this.sharedService.showLoader();
            this.partnerEmailManagementService.sendEmail(result, this.templateId).subscribe((res: any) => {
              if (res && res.code == 200) {
                this.sharedService.loggerSuccess(res.message);
                this.sharedService.hideLoader();
              } else {
                this.sharedService.loggerError(res.message);
                this.sharedService.hideLoader();
              }
            }, (err) => {
              console.log(err);
              this.sharedService.loggerError(err);
              this.sharedService.hideLoader();
            });
          } else {
            if (!result || !partnerId || !this.templateId) return;
            this.sharedService.showLoader();
            this.partnerEmailManagementService.sendEmailTemplatePartner(result, partnerId, this.templateId).subscribe((res: any) => {
              if (res && res.code == 200) {
                this.sharedService.loggerSuccess(res.message);
                this.sharedService.hideLoader();
              } else {
                this.sharedService.loggerError(res.message);
                this.sharedService.hideLoader();
              }
            }, (err) => {
              console.log(err);
              this.sharedService.loggerError(err);
              this.sharedService.hideLoader();
            });
          }
        });
        this.cd.detectChanges();
      }
    }, (err) => {
      console.log(err);
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  onSearch() {
    this.searchControl.valueChanges.pipe(debounceTime(800)).subscribe(data => {
      if (data) {
        this.searchKey = data;
        this.getAllEmailTemplates();
      }
    })
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) event.preventDefault();
  }

  onResetFilter() {
    this.searchControl.patchValue('');
    this.searchKey = '';
    this.getAllEmailTemplates();
  }

  receiveMessage(data: any) {
    this.limit = data.pageSize;
    this.page = data.pageIndex;
    this.getAllEmailTemplates();
  }

  onSort(event: any) {
    if (this.sortBy == event) {
      this.sortOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      this.sortBy = event;
      this.sortOrder = 1;
    }
    this.getAllEmailTemplates();
  }

}
