<div>
  <div class="modal-header d-flex justify-content-between align-items-center px-5 py-3">
    <h5 class="mb-0"> Add Gateway Aggregator </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body p-5">
    <p> A gateway aggregator will run the protocols in the routing group even if there are no bonds, interfaces, or
      protocols configured directly on it.
    </p>
    <form [formGroup]="gatewayForm">
      <div class="row" *ngIf="errors?.non_field_errors">
        <div class="col-md-12">
          <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
        </div>
      </div>
      <div class="row" *ngIf="errors && !errors['non_field_errors']">
        <div class="col-md-12">
          <div class="alert alert-danger">Please correct the errors below.</div>
        </div>
      </div>
      <p> Specify the IP subnet to delegate to a child space. </p>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="form-group mb-5">
            <label class="form-label"> Aggregator <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" The chosen aggregator must have a routing group VLAN assignment assigned to this space, and be
              configured with a managed trunk interface."></span> </label>
            <ng-select class="dropdown-block" formControlName="aggregator" name="aggregator">
              <ng-option *ngFor="let agg of aggregatorList" [value]="agg.url"> {{agg?.space_name}} - {{agg?.name}}
              </ng-option>
            </ng-select>
            <small class="form-invalid-message">
              <span class="text-danger" *ngIf="errors?.aggregator">
                {{errors?.aggregator?.join(' ')}}
              </span>
            </small>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Add
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</div>