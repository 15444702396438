<!-- <div class="d-flex flex-wrap justify-content-end mb-3">
  <div class="my-2">
      <div class="col-2 card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"title="Click to go back">
          <a class="btn btn-sm btn-primary d-inline-flex align-items-center p-3">
              <i class="fa fa-arrow-left me-1" aria-hidden="true"></i>
              Back
          </a>
      </div>
  </div>
</div> -->
<div class="modal-header bg-light">
  <h5 class="modal-title" id="exampleModalLabel">Add Aggregator</h5>
  <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>

<div class="modal-body px-0">
  <div class="card mb-5 mb-xl-0">
      <div class="card-body pt-0 pb-0">
          <div class="row g-5 g-xxl-8">

              <form class="form ">
                  <div class="form-group row mb-5">

                      <!-- Name -->
                      <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Name</label>
                          <input type="text" class="form-control form-control-lg form-control-solid" placeholder="" autocomplete="off" />
                      </div>

                      <!-- IP -->
                      <div class="col-md-6 col-lg-6 mb-6">
                        <label class="form-label"> IP </label>
                        <div class="d-flex flex-column">
                          <input type="text" class="form-control form-control-lg form-control-solid mb-2"  placeholder="" autocomplete="off" />
                            <small> The IP address that bonders, private WAN routers, and the management server connect to. Requires manual configuration on the aggregator. Changes require a reboot. </small>
                        </div>
                      </div>

                        <!-- IPv6 -->
                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> IPv6 </label>
                          <div class="d-flex flex-column">
                            <input type="text" class="form-control form-control-lg form-control-solid mb-2"  placeholder="" autocomplete="off" />
                              <small> The IPv6 address that bonders, private WAN routers, and the management server connect to. Requires manual configuration on the aggregator. Changes require a reboot. </small>
                          </div>
                        </div>

                          <!-- IPv6 -->
                          <div class="col-md-6 col-lg-6 mb-6">
                            <label class="form-label"> Routing group </label>
                            <div class="d-flex flex-column">
                              <ng-select class="" placeholder="Select Agg">
                                <ng-option value="auto"> SD-WAN-Default group </ng-option>
                                <ng-option value="always"> Antaresv2 dev space-Default group </ng-option>
                                <ng-option value="never"> child10-Default group </ng-option>
                              </ng-select>
                                <small> The IPv6 address that bonders, private WAN routers, and the management server connect to. Requires manual configuration on the aggregator. Changes require a reboot. </small>
                            </div>
                          </div>

                          <!-- Serial number  -->
                      <div class="col-md-6 col-lg-6 mb-3">
                        <label class="form-label"> Serial number </label>
                        <input type="text" class="form-control form-control-lg form-control-solid"  placeholder="" autocomplete="off" />
                      </div>

                      <div class="col-md-6 col-lg-6 mb-6">
                        <label class="form-label"> Asset tag </label>
                        <input type="text" class="form-control form-control-lg form-control-solid"  placeholder="" autocomplete="off" />
                      </div>

                        <!-- IPv6 -->
                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Space </label>
                          <div class="d-flex flex-column">
                            <ng-select  class="mb-2" placeholder="Select Agg">
                              <ng-option value="auto"> SD-WAN-Default group </ng-option>
                              <ng-option value="always"> Antaresv2 dev space-Default group </ng-option>
                              <ng-option value="never"> child10-Default group </ng-option>
                            </ng-select>
                              <small> Enable monitoring for aggregator failures.</small>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <div class="d-flex align-items-center mt-3">
                            <div class="form-check form-check-sm form-check-custom form-check-solid mb-2">
                            <input class="form-check-input" type="checkbox"  id="" checked>  <label class="mb-0 form-label ms-2">  Failover Monitoring </label></div>
                          </div>
                          <small> Enable monitoring for aggregator failures.</small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Failover interval </label>
                          <input type="number" class="form-control form-control-lg form-control-solid mb-2"  placeholder="" autocomplete="off" />
                          <small> How often to send checks to each aggregator, in seconds.</small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Failover receive timeout </label>
                          <input type="number" class="form-control form-control-lg form-control-solid mb-2"  placeholder="" autocomplete="off" />
                          <small>How long to wait for a response to a check, in seconds. </small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Failover failure threshold </label>
                          <input type="text" class="form-control form-control-lg form-control-solid mb-2"  placeholder="" autocomplete="off" />
                          <small> Number of sequential checks to fail for an aggregator to be considered failed. </small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Failover recovery checks </label>
                          <input type="number" class="form-control form-control-lg form-control-solid mb-2"  placeholder="" autocomplete="off" />
                          <small> Initial number of successful sequential checks before an aggregator is considered up again. </small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Failover max flap checks </label>
                          <input type="number" class="form-control form-control-lg form-control-solid mb-2"  placeholder="" autocomplete="off" />
                          <small> Upper bound on number of successful sequential checks before an aggregator is considered up again. </small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <div class="d-flex align-items-center mt-3">
                            <div class="form-check form-check-sm form-check-custom form-check-solid mb-2">
                            <input class="form-check-input" type="checkbox"  id="">  <label class="mb-0 form-label ms-2">  Proxy ARP </label></div>
                          </div>
                          <small> Enable proxy ARP on the default interface. </small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label">Metric collection interval</label>
                          <input type="number" class="form-control form-control-lg form-control-solid"  placeholder="" autocomplete="off" />
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Metric reporting interval </label>
                          <input type="number" class="form-control form-control-lg form-control-solid"  placeholder="" autocomplete="off" />
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> CPU governor </label>
                          <div class="d-flex flex-column">
                            <ng-select class="mb-2" placeholder="----">
                              <ng-option value="Conservative"> Conservative </ng-option>
                              <ng-option value="Powersave"> Powersave </ng-option>
                              <ng-option value="On demand"> On demand </ng-option>
                              <ng-option value="Performance"> Performance </ng-option>
                            </ng-select>
                              <small> Method to use for scaling CPU frequencies. If unset, the last used method for the CPU type will be used or the system default will be used after the system is rebooted.
                              </small>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> TCP Congestion control algorithm </label>
                          <div class="d-flex flex-column">
                            <ng-select class="mb-2" placeholder="----">
                              <ng-option value="CUBIC"> CUBIC </ng-option>
                              <ng-option value="BIC"> BIC </ng-option>
                              <ng-option value="Datacenter TCP"> Datacenter TCP </ng-option>
                              <ng-option value="Hamilton TCP"> Hamilton TCP </ng-option>
                            </ng-select>
                              <small>Algorithm to use for TCP congestion control. </small>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <label class="form-label"> Conntrack table size </label>
                          <div class="d-flex flex-column">
                            <ng-select class="mb-2" placeholder="----">
                              <ng-option value="4096"> 4096 </ng-option>
                              <ng-option value="8192"> 8192 </ng-option>
                              <ng-option value="16384"> 16384 </ng-option>
                              <ng-option value="32768"> 32768 </ng-option>
                            </ng-select>
                              <small>The maximum number of connections the host can track in its internal tables. If the number of tracked connections reaches this number, new connections will be dropped and an entry made in the system log file.
                              </small>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <div class="d-flex align-items-center mt-3">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox" id="">  <label class="mb-0 form-label ms-2">  Web server </label></div>
                          </div>
                          <small> Enable node web server. </small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <div class="d-flex align-items-center mt-3">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox"  id="">  <label class="mb-0 form-label ms-2">  Debug </label></div>
                          </div>
                          <small> Enable debug logging for core node services. </small>
                        </div>

                        <div class="col-md-6 col-lg-6 mb-6">
                          <div class="d-flex align-items-center mt-3">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox"  id="" checked>  <label class="mb-0 form-label ms-2">  TCP Segmentation Offloading </label></div>
                          </div>
                          <small> TCP Segmentation Offloading (TSO) is enabled for all private WAN communication between nodes. If disabled, TSO will not be used for mesh interfaces using encryption. </small>
                        </div>
                  </div>
              </form>
          </div>
      </div>
      <!-- <div class="card-footer">
          <button type="submit" class="btn btn-info" >Save</button>
          <button type="cancel" class="btn btn-danger">Cancel</button>
      </div> -->
  </div>
</div>

<div class="modal-footer p-3">
  <button type="submit" class="btn btn-primary" data-bs-dismiss="modal"><span [inlineSVG]="'./assets/media/icons/normal/check.svg'" class="svg-icon svg-icon-4 me-1"></span>Save</button>
  <button type="cancel" (click)="onClose()" class="btn btn-primary" data-bs-dismiss="modal"><span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-4 me-1"></span> Cancel</button>
</div>
