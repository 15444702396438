import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { MenuComponent } from '../../../kt/components';
import { LayoutService } from '../../core/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private unsubscribe: Subscription[] = [];
  headerContainerCssClasses: string = '';
  asideDisplay: boolean = true;
  headerLeft: string = 'menu';
  pageTitleCssClasses: string = '';
  pageTitleAttributes: { [attrName: string]: string | boolean };
  headerDisplay: string;
  navBarImageUrl: any;
  navBarText: any;
  cachingTimeInterval: any = 30;
  isCachedData: boolean = false;

  @ViewChild('ktPageTitle', { static: true }) ktPageTitle: ElementRef;

  constructor(private layout: LayoutService, private router: Router,
    private partnerBondingService: PartnerBondingService, private auth: AuthService,
    private cd: ChangeDetectorRef, private storageService: StorageService) {
    this.routingChanges();
  }

  ngOnInit(): void {
    this.headerContainerCssClasses =
      this.layout.getStringCSSClasses('headerContainer');
    this.asideDisplay = this.layout.getProp('aside.display') as boolean;
    this.headerLeft = this.layout.getProp('header.left') as string;
    this.headerDisplay = this.layout.getProp('header.display') as string;
    this.pageTitleCssClasses = this.layout.getStringCSSClasses('pageTitle');
    this.pageTitleAttributes = this.layout.getHTMLAttributes('pageTitle');
    this.fetchNavOptions()
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd || val instanceof NavigationCancel) {
        if (!(val && val.url && (val.url.includes('partner/bonds/view/') || val.url.includes('partner/dashboard')))) {
          this.isCachedData = false;
          this.cd.detectChanges();
        }
      }
    });
    this.partnerBondingService.bondData$.subscribe((res: any) => {
      if (!res) return;
      //timeBar
      this.cachingTimeInterval = this.storageService.getCipherObj('cacheInterval') ?? 30;
      this.isCachedData = false;
      this.cd.detectChanges();
      this.isCachedData = true;
      this.cd.detectChanges();
      setTimeout(() => {
        const timeBar: any = document.querySelector('.timeBar');
        timeBar.style.animationDuration = `${this.cachingTimeInterval}s`;
      }, 100);
    });
    this.partnerBondingService.activeBondList$.subscribe((res: any) => {
      if (!res) return;
      //timeBar
      this.cachingTimeInterval = this.storageService.getCipherObj('cacheInterval') ?? 30;
      this.isCachedData = false;
      this.cd.detectChanges();
      this.isCachedData = true;
      this.cd.detectChanges();
      setTimeout(() => {
        const timeBar: any = document.querySelector('.timeBar');
        timeBar.style.animationDuration = `${this.cachingTimeInterval}s`;
      }, 100);
    });
  }

  fetchNavOptions() {
    this.auth.navBar$.subscribe((data: any) => {
      if (data?.image) {
        this.navBarImageUrl = data.image
      }
      if (data?.text) {
        this.navBarText = data.text
      }
      this.cd.detectChanges();
    })
  }

  ngAfterViewInit() {
    if (this.ktPageTitle) {
      for (const key in this.pageTitleAttributes) {
        if (this.pageTitleAttributes.hasOwnProperty(key)) {
          this.ktPageTitle.nativeElement.attributes[key] =
            this.pageTitleAttributes[key];
        }
      }
    }
  }

  routingChanges() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        MenuComponent.reinitialization();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }
}
