import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shared-select-column-form',
  templateUrl: './shared-select-column-form.component.html',
  styleUrls: ['./shared-select-column-form.component.scss']
})
export class SharedSelectColumnFormComponent implements OnInit {
  availableColumnsArray:any[]=[];
  selectedItems:any[]=[];
  selectedToAdd: any;
  selectedToRemove: any;
  constructor(public activeModal: NgbActiveModal) { }
  
  ngOnInit(): void {
  }

  assign() {
    if(this.selectedToAdd && this.selectedToAdd.length){
      this.selectedItems = this.selectedItems.concat(this.selectedToAdd);
      this.availableColumnsArray = this.availableColumnsArray.filter((selectedData:any) => {
      return this.selectedItems.indexOf(selectedData) < 0;
      });
      this.selectedToAdd = [];
      //this.length=this.allBonds.length;
    }
   
     }
   
     unassign() {
       if(this.selectedToRemove && this.selectedToRemove.length){
        this.availableColumnsArray = this.availableColumnsArray.concat(this.selectedToRemove);
        this.selectedItems = this.selectedItems.filter((selectedData:any) => {
          return this.availableColumnsArray.indexOf(selectedData) < 0;
        });
        this.selectedToRemove = [];
       }
      //  if(!this.selectedItems.length){
      //    this.length=0;
      //  }
    
     }

     up(){
      if(this.selectedToRemove && this.selectedToRemove.length){
        let index=this.selectedItems.findIndex(x=>x==this.selectedToRemove[0]);
        if(index>0){
          let temp=this.selectedItems[index-1];
          this.selectedItems[index]=temp;
          this.selectedItems[index-1]=this.selectedToRemove[0]
        }
      }
     }

     down(){
      if(this.selectedToRemove && this.selectedToRemove.length){
        let index=this.selectedItems.findIndex(x=>x==this.selectedToRemove[0]);
        if(index<this.selectedItems.length-1){
          let temp=this.selectedItems[index+1];
          this.selectedItems[index]=temp;
          this.selectedItems[index+1]=this.selectedToRemove[0]
        }
      }
    }
    onSaveColumns(){
       this.activeModal.close({event:'save',selectedColumns:this.selectedItems, availableColumns:this.availableColumnsArray});
       
     }

     onClose(){
       this.activeModal.close({event:'close',selectedColumns:this.selectedItems, availableColumns:this.availableColumnsArray});
     }

}
