import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-shared-space-listing',
  templateUrl: './shared-space-listing.component.html',
  styleUrls: ['./shared-space-listing.component.scss']
})
export class SharedSpaceListingComponent implements OnInit {
  @Input() spaces: any;
  spaceList: any[] = [];

  constructor(private router: Router,
    private storageService: StorageService,
    private spaceService: PartnerSpaceService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.spaceList = this.spaces;
    this.cd.detectChanges();
  }

  onAdd(item: any) {
    this.spaceService.setSpaceParentId(item.key);
    this.spaceService.setSpaceData(item);
    this.storageService.setCipherText('space', item.key)
    this.cd.detectChanges();
    this.router.navigate([`/partner/space/create`])
  }

  onEdit(item: any) {
    this.spaceService.setSpaceParentId(item.key);
    this.spaceService.setSpaceData(item);
    this.storageService.setCipherText('space', item.key)
    this.cd.detectChanges();
    this.router.navigate([`/partner/space/edit`]);
  }
}
