<div *ngIf="permissionsObj.view" class="card mb-5 mb-xl-10">
  <div class="card-body p-5">
    <div class="row g-5 g-xxl-8">
      <form class="form form-label-right" [formGroup]="formApi">
        <div class="form-group row mb-5">
          <h4> Authentication Drivers </h4>
          <div class="col-md-12 col-lg-12">
            <div class="d-flex align-items-center">
              <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                <input type="checkbox" name="basic" id="basic" (change)="onCheckBoxChange($event,'basicAuth')"
                  formControlName="basicAuth" class="form-check-input">
                <span class="form-check-label m-2 my-0 fw-bolder text-nowrap">Basic Auth</span>
              </label>
              <p class="m-0">-Basic auth uses standard username and passoword which are the same in login page.<span
                  class="badge-light-warning p-1 text-nowrap fw-bolder py-0"> NOT RECOMMENDED </span> </p>
            </div>
            <div class="mx-10 my-5">
              <div>
                <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                  <input type="checkbox" (change)="onCheckBoxChange($event,'basicAuthAdminArea')" name="basic"
                    id="basic" formControlName="basicAuthAdminArea" class="form-check-input">
                  <span class="form-check-label m-2 fw-bolder">Admin Area</span>
                </label>
              </div>
              <div>
                <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                  <input type="checkbox" (change)="onCheckBoxChange($event,'basicAuthUserArea')" name="basic" id="basic"
                    formControlName="basicAuthUserArea" class="form-check-input">
                  <span class="form-check-label m-2 fw-bolder">User Area</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="d-flex align-items-start">
              <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                <input type="checkbox" name="token" id="token" (change)="onCheckBoxChange($event,'token')"
                  formControlName="token" class="form-check-input">
                <span class="form-check-label m-2 fw-bolder my-0">Token</span>
              </label>
              <p class="m-0">-JSON Web Token (JWT) is an open standard (RFC 7519) that defines a compact and
                self-contained way for securely transmitting information between parties as a JSON
                object. This information can be verified and trusted because it is digitally
                signed.<span class="badge-light-success p-1 fw-bolder py-0"> RECOMMENDED</span></p>
            </div>
            <div class="mx-10 my-5">
              <div>
                <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                  <input type="checkbox" name="basic" id="basic" (change)="onCheckBoxChange($event,'tokenAdminArea')"
                    formControlName="tokenAdminArea" class="form-check-input">
                  <span class="form-check-label m-2 fw-bolder">Admin Area</span>
                </label>
              </div>
              <div>
                <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                  <input type="checkbox" name="basic" id="basic" (change)="onCheckBoxChange($event,'tokenUserArea')"
                    formControlName="tokenUserArea" class="form-check-input">
                  <span class="form-check-label m-2 fw-bolder">User Area</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="d-flex align-items-start">
              <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                <input type="checkbox" name="public_private" id="public_private"
                  (change)="onCheckBoxChange($event,'publicPrivateKey')" formControlName="publicPrivateKey"
                  class="form-check-input">
                <span class="form-check-label m-2 my-0 fw-bolder text-nowrap">Public/Private Keys</span>
              </label>
              <p class="m-0">-A keyed-hash message authentication code (HMAC) is a specific type of message
                authentication code (MAC) involving a cryptographic hash function and a secret
                cryptographic key. It may be used to simultaneously verify both the data integrity and
                the authentication of a message, as with any MAC. <span
                  class="badge-light-success p-1 py-0 text-nowrap fw-bolder">STRONG SECURITY </span> </p>
            </div>
            <div class="mx-10 my-5">
              <div>
                <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                  <input type="checkbox" (change)="onCheckBoxChange($event,'keyAdminArea')" name="basic" id="basic"
                    formControlName="keyAdminArea" class="form-check-input">
                  <span class="form-check-label m-2 fw-bolder">Admin Area</span>
                </label>
              </div>
              <div>
                <label class="form-check form-check-sm form-check-custom form-check-solid me-0">
                  <input type="checkbox" (change)="onCheckBoxChange($event,'keyUserArea')" name="basic" id="basic"
                    formControlName="keyUserArea" class="form-check-input">
                  <span class="form-check-label m-2 fw-bolder">User Area</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card-footer px-5 py-3" *ngIf="!permissionsObj.update">
    <button [disabled]="formApi && formApi.invalid" type="button" class="btn btn-primary btn-style"
      (click)="onSubmit()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
  </div>
</div>
<div *ngIf="!permissionsObj?.view">
  You have no permission to view the data. Kindly contact the authority.
</div>