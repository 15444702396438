import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
// import { SharedSelectColumnFormComponent } from './shared-select-column-form/shared-select-column-form.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { PaginationComponent } from '../../pagination/pagination.component';
import { SharedBondSharingModalComponent } from '../shared-bonds-listing/shared-bond-sharing-modal/shared-bond-sharing-modal.component';

@Component({
  selector: 'app-sdwan-bonds-listing',
  templateUrl: './sdwan-bonds-listing.component.html',
  styleUrls: ['./sdwan-bonds-listing.component.scss']
})
export class SdwanBondsListingComponent implements OnInit {
  @ViewChild(PaginationComponent) paginationComponent: PaginationComponent;
  selectedToAdd: any;
  selectedToRemove: any;
  page: any = 1;
  limit: number = AppConst.pageSize;
  searchKey: any = null;
  status: any;
  bondList: any[] = [];
  //length: any;
  isHomeSpace: boolean = false;
  isPartner: boolean = false;
  searchInput: any = '';
  isMasterSel: boolean = false;
  filterOptions = [
    {
      title: 'Show records from my current space only',
      value: '0'
    },
    {
      title: 'Show records from my current space and its descendant spaces',
      value: '1'
    }
  ]

  secondFilterOptions = [
    // {
    //   title: 'Edit Selected',
    //   value: 1
    // },
    {
      title: 'Restart selected',
      value: 2
    },
    {
      title: 'Restore selected to primary aggregator',
      value: 3
    },
    // {
    //   title: 'Move selected bonds to space',
    //   value: 4
    // },
    // {
    //   title: 'Share Bond',
    //   value: 5
    // }
  ]

  searchControl: FormControl = new FormControl();
  isItemSelected: boolean = false;
  isDescendantSpace: boolean = false;
  classificationArray: any[];
  selectedItemList: any[] = [];

  authService = AuthService;

  @Output() onSearch = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Output() onSortFields = new EventEmitter<any>();
  @Output() onReceiveMessage = new EventEmitter<any>();
  @Output() onSaveColumn = new EventEmitter<any>();
  @Output() onSelectColumn = new EventEmitter<any>();
  @Output() onFilterOptionChange = new EventEmitter<any>();
  @Output() onsecondFilterOptionChange = new EventEmitter<any>();
  @Output() onAdd = new EventEmitter<any>();
  @Output() onEditBond = new EventEmitter<any>();
  @Output() getSpaceBondList = new EventEmitter<any>();

  @Input() allBonds: any[] = [];
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() length: any;
  @Input() permissions: any = {};
  @Input() spaceOption: any;
  @Input() selectedItems: any[] = [];
  @Input() rearrangedItems: any[] = [];
  @Input() availableColumnsArray: any[];
  @Input() legsInfo: any[] = [];
  @Input() sharedSdwanData: any;
  @Input() isSDWAN: boolean = false;
  @Input() isColumnListAPI: boolean = false;
  @Input() isBondListAPI: boolean = false;
  @Input() isLegListAPI: boolean = false;
  @Input() isAggListAPI: boolean = false;
  @Input() isProfileListAPI: boolean = false;
  @Input() isIPListAPI: boolean = false;
  @Input() isAdminMail: boolean = false;

  constructor(private sharedService: SharedService,
    private partnerBondingService: PartnerBondingService,
    private modalService: NgbModal, private router: Router,
    private storageService: StorageService,
    private cd: ChangeDetectorRef) { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  ngOnInit(): void {
    // if (this.storageService.getCipherObj('homeSpace') == '0' && Number(this.storageService.getCipherObj('role')) != AppConst.partner) {
    //   this.secondFilterOptions = [{ title: 'Share selected bonds', value: 5 }]
    // }
    if (this.permissions?.clone) {
      this.secondFilterOptions.push({ title: 'Clone selected bonds', value: 6 });
    }
    if (this.storageService.getCipherObj('homeSpace') && this.storageService.getCipherObj('homeSpace') !== '0') this.isHomeSpace = true;
    if (Number(this.storageService.getCipherObj('role')) === AppConst.partner
      || (Number(this.storageService.getCipherObj('role')) === AppConst.user
        && Number(this.storageService.getCipherObj('parentRole')) === AppConst.partner)) this.isPartner = true;
    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data: any) => {
        if (data != null || data != undefined) {
          this.searchKey = data;
          this.onSearch.emit(data);
        }
      });
    this.allBonds = this.allBonds.map(x => {
      x.isSelected = false;
      return x;
    })
    if (this.allBonds?.length > this.limit) this.bondList = this.allBonds.slice(0, this.limit);
    else this.bondList = this.allBonds;
  }

  ngOnChanges() {
    if (this.allBonds?.length > this.limit) this.bondList = this.allBonds.slice(this.limit * (this.page - 1), this.limit * this.page);
    else this.bondList = this.allBonds;
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  // onResetFilter() {
  //   this.searchControl.patchValue('');
  //   this.onReset.emit();
  // }

  onSort(event: any) {
    if (!event || event == '-') return;
    this.onSortFields.emit(event);
    if (!this.paginationComponent) new PaginationComponent(this.cd);
    if (this.paginationComponent) this.paginationComponent.getNext(1);
  }

  receiveMessage(event: any) {
    // this.onReceiveMessage.emit(event);
    this.page = event.pageIndex;
    this.limit = event.pageSize;
    this.bondList = this.allBonds.slice(event.pageSize * (event.pageIndex - 1), event.pageSize * event.pageIndex);
  }

  goToBondView(bondId: any, bonderId: any) {
    if (!bondId || !bonderId) return;
    let permissionsObject: any = {};
    permissionsObject['view'] = this.permissions?.view || false;
    permissionsObject['update'] = this.permissions?.update || false;
    permissionsObject['delete'] = this.permissions?.delete || false;
    if (this.isSDWAN && this.sharedSdwanData) {
      if (this.sharedSdwanData.bondPermissions && this.sharedSdwanData.bondPermissions.update == 'false') permissionsObject.update = false;
    }
    if (permissionsObject?.view !== true) permissionsObject['isViewDisable'] = true;
    if (permissionsObject?.update !== true) permissionsObject['isUpdateDisable'] = true;
    if (permissionsObject?.delete !== true) permissionsObject['isDeleteDisable'] = true;
    this.storageService.setCipherObj('bondPermission', { id: bondId, permission: permissionsObject });
    this.storageService.setCipherText('bondId', bondId);
    this.storageService.setCipherText('bonderId', bonderId);
    this.storageService.setCipherText('isSharedBond', false);
    this.storageService.setCipherText('isSdwanSharedBond', this.isSDWAN && this.sharedSdwanData ? true : false);
    this.router.navigate([`/partner/bonds/view`], { queryParams: { isSpace: true } });
  }

  goToAddBond() {
    this.onAdd.emit();
    //this.router.navigate(['/partner/bonds/create'])
  }

  isAllSelected() {
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.selectedItemList = this.allBonds.filter(x => x.isSelected)
  }

  checkUncheckAll(event: any) {
    this.allBonds = this.allBonds.map(x => {
      x.isSelected = this.isMasterSel;
      return x;
    });
    this.getCheckedItemList();
  }

  assign() {
    if (this.selectedToAdd && this.selectedToAdd.length) {
      this.selectedItems = this.selectedItems.concat(this.selectedToAdd);
      this.availableColumnsArray = this.availableColumnsArray.filter((selectedData: any) => {
        return this.selectedItems.indexOf(selectedData) < 0;
      });
      this.selectedToAdd = [];
      // this.length=this.allBonds.length;
    }
  }

  unassign() {
    if (this.selectedToRemove && this.selectedToRemove.length) {
      this.availableColumnsArray = this.availableColumnsArray.concat(this.selectedToRemove);
      this.selectedItems = this.selectedItems.filter((selectedData: any) => {
        return this.availableColumnsArray.indexOf(selectedData) < 0;
      });
      this.selectedToRemove = [];
    }
  }

  up() {
    if (this.selectedToRemove && this.selectedToRemove.length) {
      let index = this.selectedItems.findIndex(x => x == this.selectedToRemove[0]);
      if (index > 0) {
        let temp = this.selectedItems[index - 1];
        this.selectedItems[index] = temp;
        this.selectedItems[index - 1] = this.selectedToRemove[0]
      }
    }
  }

  down() {
    if (this.selectedToRemove && this.selectedToRemove.length) {
      let index = this.selectedItems.findIndex(x => x == this.selectedToRemove[0]);
      if (index < this.selectedItems.length - 1) {
        let temp = this.selectedItems[index + 1];
        this.selectedItems[index] = temp;
        this.selectedItems[index + 1] = this.selectedToRemove[0]
      }
    }
  }

  onFilterChange(event: any) {
    if (this.isDescendantSpace) {
      this.onFilterOptionChange.emit({ key: 'spaceunder', spaceOption: '1' })
    } else {
      this.onFilterOptionChange.emit({ key: 'space', spaceOption: '0' })
    }
  }

  onSecondFilterChange(event: any) {
    if (!event) return;
    if (event == 1) this.onsecondFilterOptionChange.emit({ key: 'edit', bondList: this.selectedItemList })
    else if (event == 2) this.onsecondFilterOptionChange.emit({ key: 'restart', bondId: this.selectedItemList[0].id })
    else if (event == 3) this.onsecondFilterOptionChange.emit({ key: 'restore', bondId: this.selectedItemList[0].id })
    else if (event == 4) this.onsecondFilterOptionChange.emit({ key: 'move', bondId: this.selectedItemList[0].id, count: this.selectedItemList.length, bonderId: this.selectedItemList[0].bonder.id })
    else if (event == 6) this.cloneBond(this.selectedItemList[0]);
  }

  onEdit(bondId: any, bonderId: any) {
    this.onEditBond.emit({ bondId: bondId, bonderId: bonderId })
  }

  onSaveColumns() {
    this.onSaveColumn.emit({ data: this.selectedItems });
  }

  // open() {
  //   this.onSelectColumn.emit();
  //   // const modalRef = this.modalService.open(SharedSelectColumnFormComponent);
  //   // modalRef.componentInstance.name = 'World';
  // }
  cloneBond(data: any) {
    let payload = {
      "aggregator": data.aggregator,
      "secondary_aggregator": data.secondary_aggregator,
      "aggregator_failback": data.aggregator_failback || false,
      "qos_profile": data.qos_profile ?? null,
      "flow_collectors": null,
      "classification_profile": null,
      "compression": data.compression == false ? false : true,
      "tunnel_security": data.tunnel_security || 'hmac',
      "encryption_cipher": data.encryption_cipher || 'AES128',
      "encryption_handshake_interval": data.encryption_handshake_interval || 3600,
      "encryption_replay_protection": data.encryption_replay_protection == false ? false : true,
      "packet_distribution": data.packet_distribution || 'wrr',
      "flowlet_delta": data.flowlet_delta || 0,
      "batched_leg_tx": data.batched_leg_tx || false,
      "clamp_tcp": data.clamp_tcp == false ? false : true,
      "automatic_ping_timing": data.automatic_ping_timing == false ? false : true,
      "regular_leg_ping_time": data.regular_leg_ping_time || 100,
      "regular_leg_fail_time": data.regular_leg_fail_time || 300,
      "failover_leg_ping_time": data.failover_leg_ping_time || 1000,
      "failover_leg_fail_time": data.failover_leg_fail_time || 3000,
      "automatic_reorder_max_hold": data.automatic_reorder_max_hold == false ? false : true,
      "reorder_max_hold": data.reorder_max_hold || 30,
      "packet_loss_detection": data.packet_loss_detection == false ? false : true,
      "flap_detection": data.flap_detection == false ? false : true,
      "leg_mtu_detection": data.leg_mtu_detection == false ? false : true,
      "leg_mtu_detection_time": data.leg_mtu_detection_time || 1,
      "send_jitter_buffer": data.send_jitter_buffer || false,
      "source_address_verification": data.source_address_verification || false,
      "replify_enabled": data?.replify_enabled || false,
      "debug": data.debug || false,
      "proof_of_concept": data.proof_of_concept || false,
      "bridge_enabled": data.bridge_enabled || false,
      "bridge_ports": data.bridge_portss || '80,443,8080',
      "bridge_concurrency": data.bridge_concurrency || 4,
      "bridge_congestion_control_algorithm": data.bridge_congestion_control_algorithm || 'bbr',
      "bridge_file_descriptor_limit": data.bridge_file_descriptor_limit || 16384,
      "note": data.note || '',
      "circuit_id": data.circuit_id || '',
      "product": data.product || '',
      "asset_tag": data?.bonder?.asset_tag || '',
      "space": data.space ?? null,
      "bonder": {
        "metric_collection_interval": data?.bonder?.metric_collection_interval || 10,
        "administrative_profile": data?.administrative_profile || 'default',
        "note": data.note || '',
        "circuit_id": data.circuit_id || '',
        "product": data.product || '',
        "proof_of_concept": data.proof_of_concept || false,
        "metric_reporting_interval": data?.bonder?.metric_reporting_interval || 60,
        "cpu_governor": data?.bonder?.cpu_governor || '',
        "tcp_congestion_control_algorithm": data.tcp_congestion_control_algorithm || "bbr",
        "conntrack_table_size": data.conntrack_table_size || 131072,
        "manage_process_affinity": data.conntrack_table_size == false ? false : true,
        "tunnel_affinity_core": data.conntrack_table_size || 2,
        "dns_servers": data.dns_servers ? data.dns_servers.split(',') : [],
        "automatic_source_ip": data.automatic_source_ip || false,
        "web_server": data.web_server == false ? false : true,
        "name": data?.bonder?.name + '(copy)',
        "asset_tag": data?.bonder?.asset_tag || '',
        "serial_number": data?.bonder?.serial_number || '',
        "debug": data?.bonder?.debug || false,
      }
    }
    const modalRef = this.modalService.open(NgbModalBondClone)
    modalRef.componentInstance.bondList = this.allBonds;
    modalRef.componentInstance.cloneBondName = data?.bonder?.name;
    modalRef.closed.subscribe((result: any) => {
      if (result && result.name) {
        payload.bonder.name = result.name;
        this.sharedService.showLoader()
        this.partnerBondingService.cloneBond(payload, data.id).subscribe((res) => {
          if (res.code != 400 && res.data && (res.data.bondCode == 200 || res.data.bondCode == 201)) {
            res = res.data;
            if (!(res.bonderCode == 200 || res.bonderCode == 201)) {
              this.sharedService.loggerSuccess('Bond Data cloned, but bonder Data encounter error.');
            } else {
              let isError = false;
              if (res.interfaceResponses && res.interfaceResponses.length > 0) {
                let interfaceError = ''
                if (!(res.interfaceResponses[0] && res.interfaceResponses[0].ifname == 'eth0')) {
                  interfaceError += 'eth0';
                } else if (!(res.interfaceResponses[1] && res.interfaceResponses[1].ifname == 'eth1')) {
                  if (interfaceError) interfaceError += ', eth1';
                  else interfaceError += 'eth1';
                } else if (!(res.interfaceResponses[2] && res.interfaceResponses[2].ifname == 'eth2')) {
                  if (interfaceError) interfaceError += ', eth2';
                  else interfaceError += 'eth2';
                }
                if (interfaceError) {
                  this.sharedService.loggerSuccess(`Bond Data cloned, but encounter error while creating interface ${interfaceError}.`);
                  isError = true;
                }
              }
              if (res.wanResponses && res.wanResponses.length > 0) {
                let wanError = false
                if (!(res.wanResponses[0] && res.wanResponses[0].id)) {
                  wanError = true;
                } else if (!(res.wanResponses[1] && res.wanResponses[1].id)) {
                  wanError = true;
                }
                if (wanError) {
                  this.sharedService.loggerSuccess(`Bond Data cloned, but encounter error while creating WAN.`);
                  isError = true;
                }
              }
              if (res.dhcpAddressResponses && res.dhcpAddressResponses.length > 0) {
                if (!(res.dhcpAddressResponses[2] && res.dhcpAddressResponses[2].id)) {
                  this.sharedService.loggerSuccess(`Bond Data cloned, but encounter error while creating connected IP.`);
                  isError = true;
                }
              }
              if (!isError) this.sharedService.loggerSuccess('Bond Cloned successfully');
            }
            this.getSpaceBondList.emit();
          } else this.sharedService.loggerError('Internal server error');
          this.sharedService.hideLoader();
          this.cd.detectChanges();
        }, (err) => {
          console.error(err);
          this.sharedService.loggerError('Internal server error');
          this.cd.detectChanges();
        });
      }
    });
  }

  onDelete(data: any) {
    if (!(data && data.id)) return;
    let reqObj = {
      "method": "DELETE",
      "url": `bonds/${data.id}/`,
      'type': 'Bond',
      'bondId': data.id,
      'bondName': data?.bonder?.name
    }
    let questionTitle = 'Delete Bond';
    let text = `Are you sure you want to delete ${data?.bonder?.name} ? This cannot be undone.`;
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.partnerBondingService.deleteData(reqObj).subscribe((res) => {
          if (res.code == 204 || res.code == 200) this.sharedService.loggerSuccess(res.message);
          else this.sharedService.loggerError(res.message);
          this.sharedService.hideLoader();
          this.getSpaceBondList.emit();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error?.non_field_errors) this.sharedService.loggerError(error?.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  onShared(bondData: any, isMultiple: boolean) {
    let modal = this.modalService.open(SharedBondSharingModalComponent, { size: 'md' });
    modal.componentInstance.bondData = bondData;
    modal.componentInstance.isMultiple = isMultiple;
    let permissionsObject: any = {};
    permissionsObject['view'] = this.permissions?.view || false;
    permissionsObject['update'] = this.permissions?.update || false;
    permissionsObject['delete'] = this.permissions?.delete || false;
    if (permissionsObject?.view !== true) permissionsObject['isViewDisable'] = true;
    if (permissionsObject?.update !== true) permissionsObject['isUpdateDisable'] = true;
    if (permissionsObject?.delete !== true) permissionsObject['isDeleteDisable'] = true;
    modal.componentInstance.permissions = permissionsObject;
    if (isMultiple) modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.allBonds.map(x => {
          x.isSelected = false;
          return x;
        });
        this.isMasterSel = false;
        this.selectedItemList = [];
        this.cd.detectChanges();
      }
    }, () => { });
  }

  calculateBytes(rate: number) {
    return this.sharedService.calculateBytes(rate);
  }
}

// clone Popup
@Component({
  selector: 'ngb-modal-clone',
  template: `
    <div class="modal-header px-5 py-3">
      <h5 class="modal-title">Create Bond from {{cloneBondName}}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-5">
      <p> 
        <small class="badge py-5 px-4 fs-7 badge-light-warning w-100 text-start fw-normal text-dark border-warning border-1 border text-wrap"> 
          Kindly Confirm your intent to copy this Bond, It is a chargeable item.Cloning this Bond will trigger a monthly invoice using your standard rate and terms. 
          The Bond will be invoiced from today. Please confirm you'd like to continue.
        </small>
      </p>
      <form class="col-md-8 col-lg-8 col-xl-8" [formGroup]="bondForm">
        <div class="form-group inputtext-block mb-5">
          <label class="form-label">Provide New Bond Name</label>&nbsp;<span class="required-asterisk m-0">*</span>
          <input type="text" class="form-control form-control-lg form-control-solid"
            name="bondName" placeholder="Enter Bond Name" autocomplete="off"
            formControlName="bondName" />
          <small class="form-invalid-message">
            <span class="text-danger" *ngIf="controlHasError('required', 'bondName')">
              Bond name is required
            </span>
            <span class="text-danger" *ngIf="!controlHasError('required', 'bondName') && isExist">
              Bond name already exist.
            </span>
          </small>
        </div>
      </form>
    </div>
    <div class="modal-footer px-5 py-3">
      <button  type="button" class="btn btn-primary btn-style" 
        [disabled]="bondForm.invalid || isExist"  
        (click)="bondForm.invalid || isExist ? '' :onSave()">
            <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen028.svg'"
                    class="svg-icon svg-icon-block mb-2"></span>
            </div> Clone it!
      </button>
    </div>
  `
})
export class NgbModalBondClone implements OnInit {
  bondForm: FormGroup;
  cloneBondName: string;
  bondList: any[];
  isExist: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private shared: SharedService,
    private formErrorHandler: FormErrorService,
    private _fb: FormBuilder,) { }

  ngOnInit(): void {
    this.bondForm = this._fb.group({ bondName: ['', Validators.required] })
    this.bondForm.controls.bondName.valueChanges.subscribe((val) => {
      this.isExist = false;
      if (val) {
        if (this.bondList && this.bondList.length > 0) {
          this.bondList.forEach((bond) => {
            if (val === bond?.bonder?.name) this.isExist = true;
          });
        }
      }
    });
    if (this.cloneBondName) this.bondForm.controls.bondName.setValue(this.cloneBondName + '(copy)')
  }

  onSave() {
    if (!this.bondForm.valid) {
      this.bondForm.markAsTouched();
      return;
    }
    this.shared.showLoader();
    this.activeModal.close({ name: this.bondForm.value.bondName })
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.bondForm);
  }

}