import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AppConst } from '../../constants/app.constant';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-shared-activity-logs',
  templateUrl: './shared-activity-logs.component.html',
  styleUrls: ['./shared-activity-logs.component.scss']
})
export class SharedActivityLogsComponent implements OnInit {
  searchControl: FormControl = new FormControl();
  status: any;
  searchInput: any = '';

  @Output() onSearch = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Output() onSortFields = new EventEmitter<any>();
  @Output() onReceiveMessage = new EventEmitter<any>();

  @Input() logs: any[] = [];
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() page: number = 1;
  @Input() limit: number = AppConst.pageSize;;
  @Input() length: any;
  @Input() permissions: any = {};
  @Input() preLoaderScreen: boolean = false;

  constructor(private sharedService: SharedService) { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  ngOnInit(): void {
    this.sharedService.hideLoader();
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((data: any) => {
      if (data != null || data != undefined) {
        this.onSearch.emit(data);
      }
    })
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  onResetFilter() {
    this.searchControl.patchValue('');
    this.onReset.emit();
  }

  onSort(event: any) {
    this.onSortFields.emit(event);
  }

  onDeleteLogs(logId: any) {
    if (logId) {
      let questionTitle = 'Are you sure?';
      let text = "<b>You are deleting this log. <br>It can't be revert back! </b>"
      let confirmButtonText = "Yes, Delete it!"
      this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
        if (result.isConfirmed) {
          this.sharedService.showLoader();
          this.onDelete.emit(logId)
        }
      })
    }
  }

  receiveMessage(event: any) {
    this.onReceiveMessage.emit(event);
  }

}
