import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-tune-bond-setting',
  templateUrl: './tune-bond-setting.component.html',
  styleUrls: ['./tune-bond-setting.component.scss']
})
export class TuneBondSettingComponent implements OnInit {
  tuneForm: FormGroup;
  testType: any = '0.77';
  scheduleType: any = 'immediate';
  scheduleList: any[] = []
  errors: any;
  bondId: any;
  bondData: any;

  constructor(private partnerBondingService: PartnerBondingService,
    private fb: FormBuilder, private cd: ChangeDetectorRef,
    public activeModal: NgbActiveModal, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.tuneForm = this.createTuneForm();
    this.createScheduleList();
  }


  createTuneForm() {
    return this.fb.group({
      bonded_throughput_requirement: [85, Validators.required],
      speedtest_length: [10, Validators.required],
      accept_recommendations_automatically: [false, Validators.required],
      schedule_at: ['', Validators.required],
    });
  }

  changeType(e: any) {
    this.testType = e.target.value;
    this.cd.detectChanges()
  }

  changeSchedule(e: any) {
    this.scheduleType = e.target.value;
    this.cd.detectChanges()
  }

  onSave() {
    if (this.tuneForm.invalid) {
      this.tuneForm.markAllAsTouched();
      return;
    }
    if (!this.bondId) return;
    this.errors = null;
    if (+this.tuneForm.value.bonded_throughput_requirement < 0 || +this.tuneForm.value.bonded_throughput_requirement > 95) {
      this.errors = { 'bonded_throughput_requirement': ['Bonded throughput must be between 0 and 95'] }
      return;
    }
    if (+this.tuneForm.value.speedtest_length < 10 || +this.tuneForm.value.speedtest_length > 60) {
      this.errors = { 'speedtest_length': ['Test length must be between 10 and 60'] }
      return;
    }
    let payload: any = {
      "accept_recommendations_automatically": this.tuneForm.value.accept_recommendations_automatically,
      "bonded_throughput_requirement": +this.tuneForm.value.bonded_throughput_requirement / 100,
      "speedtest_length": this.tuneForm.value.speedtest_length,
      "throughput_stability_requirement": this.testType,
      "schedule": this.scheduleType,
      bondName: this.bondData?.bonder?.name,
      type: 'BondTuning',
      bondId: this.bondId,
    }
    if (this.scheduleType == 'timer') payload['schedule_at'] = this.tuneForm.value.schedule_at;
    this.sharedService.showLoader();
    this.partnerBondingService.saveTuneSettings(this.bondId, payload)?.subscribe((res: any) => {
      if (res) this.sharedService.loggerSuccess('Bond Tuning start successfully');
      else this.sharedService.loggerError(res?.message);
      this.sharedService.hideLoader();
      this.activeModal.close({ event: res })
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        if (this.errors?.non_field_errors) this.sharedService.loggerError(this.errors?.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  createScheduleList() {
    let today = new Date();
    for (let i = 1; i <= 23; i++) {
      this.scheduleList.push(new Date(today.setTime(today.getTime() + (60 * 60 * 1000))))
    }
    setTimeout(() => {
      this.tuneForm.controls.schedule_at.setValue(this.scheduleList[0])
    }, 500);
  }
}
