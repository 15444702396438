import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { PartnerOrganizationManagementService } from 'src/app/services/partner-organization-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedBondsViewConfigModalComponent } from '../shared-bonds-view-config-modal/shared-bonds-view-config-modal.component';

@Component({
  selector: 'app-shared-bond-view-device-details',
  templateUrl: './shared-bond-view-device-details.component.html',
  styleUrls: ['./shared-bond-view-device-details.component.scss']
})
export class SharedBondViewDeviceDetailsComponent implements OnInit {
  latest_tuning: any;
  bondPermission: any;
  bondGeoData: any;
  bondConfigPermission: any = { view: true, update: true, delete: true };
  privateWANPermissionsObj: any = { view: false, update: false, delete: false };

  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() bondDetails: any;
  @Input() bonderDetails: any;
  @Input() nodeDetails: any;

  @Output() getBondStatus = new EventEmitter<any>();

  constructor(private partnerBondingService: PartnerBondingService,
    private partnerOrganizationManagementService: PartnerOrganizationManagementService,
    private sharedService: SharedService, private storageService: StorageService,
    private cd: ChangeDetectorRef, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.sharedService.hideLoader();
    this.partnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
    this.getBondCoordinate();
    this.bondConfigPermission = this.getPermission();
  }

  getPermission() {
    let permissionsObj: any;
    if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      permissionsObj = { view: true, update: true, delete: true }
      this.privateWANPermissionsObj = { view: true, update: true, delete: true }
    } else {
      this.sharedService.getPermission();
      if (this.sharedService.noPermission) permissionsObj = { view: true, update: true, delete: true };
      else {
        permissionsObj = this.sharedService.bond;
        this.privateWANPermissionsObj = this.sharedService.privateWan;
      }
    }
    return permissionsObj;
  }

  getBondCoordinate() {
    if (!this.bondDetails?.id) return;
    this.partnerOrganizationManagementService.getBondGeoMap({ nodeId: this.bondDetails?.id })?.subscribe((res: any) => {
      if (res && res.code == 200) {
        if (res.data) this.bondGeoData = res.data;
      } else this.sharedService.loggerError(res.message);
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  keyFormate(key: string) {
    if (!key) return;
    return key.match(/.{1,5}/g)?.join('-');
  }

  openDeviceEdit() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.option = 1;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondStatus.emit();
      this.cd.detectChanges();
    }, () => { });
  }

  openCoordinateEdit() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.bondGeoData = this.bondGeoData;
    modal.componentInstance.option = 7;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondCoordinate();
      this.cd.detectChanges();
    }, () => { });
  }
}
