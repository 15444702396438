<div class="modal-header px-6 py-3">
    <h5 class="modal-title">Add Interface </h5>
    <button type="button" class="btn-close" (click)="onClose()" aria-label="Close"></button>
</div>
<div class="modal-body p-0">
    <div class="card mb-5 mb-xl-0">
        <div class="card-body p-5 pb-0">
            <div class="row" *ngIf="errors && errors['non_field_errors']">
                <div class="col-md-12">
                    <div class="alert alert-danger">{{errors['non_field_errors']}}</div>
                </div>
            </div>
            <div class="row" *ngIf="errors && !errors['non_field_errors']">
                <div class="col-md-12">
                    <div class="alert alert-danger">Please correct the errors below.</div>
                </div>
            </div>
            <div class="form-group mb-5">
                <form action="#" [formGroup]="InterfaceAdd" class="form form-label-right">
                    <div class="row">
                        <!-- <div class="row" *ngIf="InterfaceAdd.invalid">
                            <div class="col-md-12">
                                <div class="alert alert-danger">Please correct the errors below.</div>
                            </div>
                        </div> -->
                        <div class="col-md-12 col-lg-12 mb-5">
                            <label class="form-label">Type</label>
                            <div class="d-flex align-items-start">
                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                    <input type="radio" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="typeInterface" name="type" value="Ethernet" id="Ethernet"
                                        (change)="changeType($event)" class="form-check-input">
                                    <span class="form-check-label">Ethernet</span>
                                </label>
                                <!-- <div class="d-flex align-items-center justify-content-center">
                                    <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="typeInterface" name="type" value="Ethernet"
                                        id="Ethernet" (change)="changeType($event)">
                                    <label class="m-2 form-label"> Ethernet </label>
                                </div> -->
                                <div class=" d-flex flex-column">
                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input type="radio" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="typeInterface" name="type" value="VLAN" id="VLAN"
                                            (change)="changeType($event)" [disabled]="!isEthernetAdded"
                                            class="form-check-input">
                                        <span class="form-check-label">VLAN</span>
                                    </label>
                                    <small class="ms-5 text-danger" *ngIf="!isEthernetAdded">You must add at least one
                                        Ethernet interface before
                                        adding a VLAN interface.</small>
                                </div>
                                <!-- <div class="d-flex align-items-center justify-content-center">
                                    <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="typeInterface" name="type" value="VLAN" id="VLAN"
                                        (change)="changeType($event)">
                                    <label class="m-2 form-label"> VLAN </label>
                                    <small> You must add at least one Ethernet interface before adding a VLAN interface. </small>
                                </div> -->
                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                    <input type="radio" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="typeInterface" value="VXLAN" name="type" id="VXLAN"
                                        (change)="changeType($event)" class="form-check-input">
                                    <span class="form-check-label">VXLAN</span>
                                </label>
                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                    <input type="radio" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="typeInterface" value="Bridge" name="type" id="Bridge"
                                        (change)="changeType($event)" class="form-check-input">
                                    <span class="form-check-label">Bridge</span>
                                </label>
                            </div>
                            <small class="text-danger">
                                <small class="text-danger">
                                    {{errors?.type?.join(' ')}}
                                </small>
                            </small>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1"
                            *ngIf="typeInterface == 'Ethernet' || typeInterface == 'VXLAN' || typeInterface == 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Interface name</label>
                                <input formControlName="ifname" type="text"
                                    class="form-control form-control-lg form-control-solid" name="ifname"
                                    placeholder="interface name" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.ifname?.join(' ')}} </span>
                                    <span *ngIf="submitted==true && f.ifname.invalid && f.ifname.errors?.required"
                                        class="text-danger"> This field is required
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'Ethernet'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Interface mode</label>
                                <ng-select formControlName="Interface_mode" name="Interface_mode" class="dropdown-block"
                                    placeholder="Auto negotiation" [clearable]="false" [searchable]="false">
                                    <!-- <ng-option value="10Gbps/Full">  10 Gbps Full </ng-option>
                                    <ng-option value="1Gbps/Full">  1 Gbps Full </ng-option>
                                        <ng-option value="100Mbps/Full">  100 Mbps Full </ng-option> -->
                                    <ng-option value="100baseT/Full">100 Mbps Full</ng-option>
                                    <ng-option value="100baseT/Half">100 Mbps Half</ng-option>
                                    <ng-option value="10baseT/Full">10 Mbps Full</ng-option>
                                    <ng-option value="auto">Auto negotiation</ng-option>
                                    <ng-option value="10000baseT/Full">10 Gbps Full</ng-option>
                                    <ng-option value="10baseT/Half">10 Mbps Half</ng-option>
                                    <ng-option value="1000baseT/Full">1 Gbps Full</ng-option>
                                </ng-select>
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.mode?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <!-- VNI -->
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VXLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">VNI</label>
                                <input formControlName="VNI" type="text"
                                    class="form-control form-control-lg form-control-solid" name="VNI" placeholder=""
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <small class="text-danger"> {{errors?.vni?.join(' ')}} </small>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VXLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Port</label>
                                <input formControlName="Port" type="text"
                                    class="form-control form-control-lg form-control-solid" name="Port" placeholder=""
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <small class="text-danger"> {{errors?.port?.join(' ')}} </small>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface != 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Bridge</label>
                                <ng-select formControlName="bridge" class="dropdown-block" placeholder="">
                                    <ng-option *ngFor="let bridges of arrBridge"
                                        [value]="bridges.url">{{bridges.ifname}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <!-- Ethernet interface -->
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Ethernet interface
                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="An optional base interface to bind to."></span>
                                </label>
                                <ng-select formControlName="ethernet_interface" class="dropdown-block" placeholder="">
                                    <ng-option *ngFor="let item of allInterfaces" [value]="item.url">{{item.ifname}}
                                    </ng-option>
                                </ng-select>
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.ethernet_interface?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <!-- VLAN tag -->
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">VLAN tag</label>
                                <input formControlName="vlan_tag" name="vlan_tag" type="number"
                                    class="form-control form-control-lg form-control-solid" placeholder="200"
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.vlan_tag?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VXLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Ethernet interface
                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="An optional base interface to bind to."></span>
                                </label>
                                <ng-select formControlName="ethernet_interface" class="dropdown-block" placeholder="">
                                    <ng-option *ngFor="let item of allInterfaces" [value]="item.url">{{item.ifname}}
                                    </ng-option>
                                </ng-select>
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.ethernet_interface?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VXLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Multicast group</label>
                                <input formControlName="multicast_group" type="text"
                                    class="form-control form-control-lg form-control-solid" name="multicast_group"
                                    placeholder="224.0.0.0" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.multicast_group.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VXLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Source address</label>
                                <input formControlName="source_address" type="text"
                                    class="form-control form-control-lg form-control-solid" name="source_address"
                                    placeholder="1.2.3.4" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.source_address?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VXLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">TTL</label>
                                <input formControlName="ttl" type="text"
                                    class="form-control form-control-lg form-control-solid" name="ttl" placeholder="255"
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.ttl?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'VXLAN'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">TOS</label>
                                <input formControlName="tos" type="text"
                                    class="form-control form-control-lg form-control-solid" name="tos" placeholder="18"
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.tos?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-5 mb-1" *ngIf="typeInterface == 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Ageing Time</label>
                                <input formControlName="ageing_time" type="text"
                                    class="form-control form-control-lg form-control-solid" name="ageing_time"
                                    placeholder="30000" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.ageing_time?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-1 col-lg-1 mb-1" *ngIf="typeInterface == 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label w-100">STP</label>
                                <div class="d-flex flex-column mt-2">
                                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                        formControlName="stp"></ui-switch>
                                    <small class="text-danger">
                                        {{errors?.stp?.join(' ')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Hello Time</label>
                                <input formControlName="hello_time" type="text"
                                    class="form-control form-control-lg form-control-solid" name="hello_time"
                                    placeholder="200" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.hello_time?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Forward Delay</label>
                                <input formControlName="forward_delay" type="text"
                                    class="form-control form-control-lg form-control-solid" name="forward_delay"
                                    placeholder="1500" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.forward_delay?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Max Age</label>
                                <input formControlName="max_age" type="text"
                                    class="form-control form-control-lg form-control-solid" name="max_age"
                                    placeholder="2000" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.max_age?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1" *ngIf="typeInterface == 'Bridge'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Priority</label>
                                <input formControlName="priority" type="text"
                                    class="form-control form-control-lg form-control-solid" name="priority"
                                    placeholder="32768" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.priority?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1">
                            <!-- //*ngIf="typeInterface==Ethernet || typeInterface==VLAN"> -->
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">MAC address</label>
                                <input formControlName="MAC_address" type="text"
                                    class="form-control form-control-lg form-control-solid" name="MAC_address"
                                    placeholder="11:22:33:44:55:66" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.mac?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1">
                            <!-- *ngIf="typeInterface==Ethernet || typeInterface==VLAN"> -->
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Interface MTU</label>
                                <input formControlName="Interface_MTU" type="text"
                                    class="form-control form-control-lg form-control-solid" name="Interface_MTU"
                                    placeholder="1500" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.mtu?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Note</label>
                                <input formControlName="note" type="text"
                                    class="form-control form-control-lg form-control-solid" name="note" placeholder=""
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"> {{errors?.note?.join(' ')}} </span>
                                </small>
                            </div>
                        </div>
                        <div *ngIf="typeInterface == 'VXLAN'" formArrayName="endpoints">
                            <div [formGroupName]="i"
                                class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around points-block"
                                *ngFor="let data of endpoints?.controls;let i=index">
                                <div class="btn-remove-point">
                                    <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemove(i)"
                                        title="Remove Endpoint">
                                        <div>
                                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                class="svg-icon svg-icon-block">
                                            </span>
                                        </div>
                                    </button>
                                </div>
                                <div class="row pt-3">
                                    <div class="col-md-6 col-lg-4 mb-1">
                                        <div class="form-group inputtext-block mb-5">
                                            <label class="form-label">Address</label>
                                            <input formControlName="address" type="text"
                                                class="form-control form-control-lg form-control-solid" name="address"
                                                placeholder="1.2.3.4" autocomplete="off" />
                                            <small class="form-invalid-message">
                                                <span class="text-danger"> {{errors?.address?.join(' ')}} </span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4 mb-1">
                                        <div class="form-group inputtext-block mb-5">
                                            <label class="form-label">VNI</label>
                                            <input formControlName="vni" type="text"
                                                class="form-control form-control-lg form-control-solid" name="vni"
                                                placeholder="VNI" autocomplete="off" />
                                            <small class="form-invalid-message">
                                                <span class="text-danger"> {{errors?.vni?.join(' ')}} </span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4 mb-1">
                                        <div class="form-group inputtext-block mb-5">
                                            <label class="form-label">Port</label>
                                            <input formControlName="port" type="text"
                                                class="form-control form-control-lg form-control-solid" name="port"
                                                placeholder="Port" autocomplete="off" />
                                            <small class="form-invalid-message">
                                                <span class="text-danger"> {{errors?.port?.join(' ')}} </span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="typeInterface == 'VXLAN'" class="col-12 delete-button">
                            <button type="button" class="btn btn-primary btn-style" (click)="onAdd()">
                                <div>
                                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                        class="svg-icon svg-icon-block mb-2"></span>
                                </div> Add Endpoint
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Add
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>