import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { AlertConfigurationFormComponent } from 'src/app/modules/partner/bonds/partner-bond-view/alert-configuration/alert-configuration-form.component';
import { AuthService } from 'src/app/modules/shared/auth';
import { AlertService } from 'src/app/services/alert.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PaginationComponent } from '../../pagination/pagination.component';
import { SharedBondSharingModalComponent } from './shared-bond-sharing-modal/shared-bond-sharing-modal.component';

@Component({
  selector: 'app-shared-bonds-listing',
  templateUrl: './shared-bonds-listing.component.html',
  styleUrls: ['./shared-bonds-listing.component.scss']
})
export class SharedBondsListingComponent implements OnInit {
  @ViewChild(PaginationComponent) paginationComponent: PaginationComponent;
  authService = AuthService;
  searchControl: FormControl = new FormControl();
  selectedToAdd: any;
  selectedToRemove: any;
  eventObj: any;
  isSharedFilter: boolean = false;
  isMasterSel: boolean = false;
  isEmptyHomeSpace: boolean = false;
  selectedItemList: any[] = [];
  filterOptions = [
    {
      title: 'Show records from my current space only',
      value: '0'
    },
    {
      title: 'Show records from my current space and its descendant spaces',
      value: '1'
    },
  ]
  secondFilterOptions = [
    // {
    //   title: 'Select options',
    //   value: 0
    // },
    // {
    //   title: 'Edit Selected',
    //   value: 1
    // },
    {
      title: 'Restart selected',
      value: 2
    },
    {
      title: 'Restore selected to primary aggregator',
      value: 3
    },
    {
      title: 'Move selected bonds to space',
      value: 4
    },
    { title: 'Share selected bonds', value: 5 }
  ]

  @Output() onSearch = new EventEmitter<any>();
  @Output() onDeleteBond = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Output() onSortFields = new EventEmitter<any>();
  @Output() onReceiveMessage = new EventEmitter<any>();
  @Output() onSaveColumn = new EventEmitter<any>();
  @Output() onSelectColumn = new EventEmitter<any>();
  @Output() onFilterOptionChange = new EventEmitter<any>();
  @Output() onsecondFilterOptionChange = new EventEmitter<any>();
  @Output() onAdd = new EventEmitter<any>();

  @Input() selectedItems: any[] = [];
  @Input() rearrangedItems: any[] = [];
  @Input() availableColumnsArray: any[];
  @Input() allBonds: any[] = [];
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() searchKey: any = null;
  @Input() length: any;
  @Input() limit: any;
  @Input() page: any;
  @Input() permissions: any = {};
  @Input() spaceOption: any;
  @Input() isBondListAPI: boolean = false;
  @Input() isLegListAPI: boolean = false;
  @Input() isAggListAPI: boolean = false;
  @Input() isProfileListAPI: boolean = false;
  @Input() isIPListAPI: boolean = false;

  constructor(private sharedService: SharedService,
    private cd: ChangeDetectorRef, private alertService: AlertService,
    private modalService: NgbModal, private storageService: StorageService,
    private router: Router) { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  ngOnInit(): void {
    if (this.storageService.getCipherObj('homeSpace') == '0' && Number(this.storageService.getCipherObj('role')) != AppConst.partner) {
      this.isEmptyHomeSpace = true;
      this.secondFilterOptions = [{ title: 'Share selected bonds', value: 5 }]
    }
    this.getAlertPermission();
    if (Number(this.storageService.getCipherObj('role')) != AppConst.partner && !(Number(this.storageService.getCipherObj('role')) == AppConst.user && Number(this.storageService.getCipherObj('parentRole')) == AppConst.partner)) this.filterOptions.push({ title: 'Show records from shared bonds', value: '2' });
    if (this.searchKey) this.searchControl.setValue(this.searchKey);
    this.searchControl.valueChanges.pipe(debounceTime(900)).subscribe((data: any) => {
      if (!this.paginationComponent) new PaginationComponent(this.cd);
      if (data != null || data != undefined) {
        this.searchKey = data;
        this.onSearch.emit(data);
        this.page = 1;
        this.receiveMessage({ pageIndex: this.page, pageSize: this.limit });
        this.cd.detectChanges();
      }
    });
  }

  getAlertPermission() {
    let moduleList = this.storageService.getCipherObj('Module_Access');
    if (moduleList && moduleList.length > 0) {
      moduleList.forEach((item: any) => {
        if (item && item.name == AppConst.moduleList.alertModule) {
          this.secondFilterOptions.push({ title: 'Enable Leg Alerts', value: 6 })
        }
      });
    }
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  onResetFilter() {
    if (!this.searchControl.value) return;
    this.searchControl.patchValue('');
    this.onReset.emit();
  }

  onSort(event: any) {
    if (!event || event == '-' || this.length <= 0) return;
    this.onSortFields.emit(event);
  }

  receiveMessage(event: any) {
    this.eventObj = event;
    this.onReceiveMessage.emit(event);
  }

  goToBondView(bondId: any, bonderId: any, permission: any, isSdwanSharedBond: boolean = false, isSharedBond: boolean = false) {
    if (!bondId || !bonderId) return;
    let permissionsObject: any = {};
    permissionsObject['view'] = this.permissions?.view || false;
    permissionsObject['update'] = this.permissions?.update || false;
    permissionsObject['delete'] = this.permissions?.delete || false;
    if (permission?.view == 'false') permissionsObject.view = false;
    if (permission?.update == 'false') permissionsObject.update = false;
    if (permission?.delete == 'false') permissionsObject.delete = false;
    if (permissionsObject?.view !== true) permissionsObject['isViewDisable'] = true;
    if (permissionsObject?.update !== true) permissionsObject['isUpdateDisable'] = true;
    if (permissionsObject?.delete !== true) permissionsObject['isDeleteDisable'] = true;
    this.storageService.setCipherObj('bondPermission', { id: bondId, permission: permissionsObject });
    this.storageService.setCipherText('bondId', bondId);
    this.storageService.setCipherText('bonderId', bonderId);
    this.storageService.setCipherText('isSharedBond', isSharedBond);
    this.storageService.setCipherText('isSdwanSharedBond', isSdwanSharedBond);
    this.router.navigate([`/partner/bonds/view`], bonderId);
  }

  onEdit(bondId: any, bonderId: any) {
    if (!bondId || !bonderId) return;
    this.router.navigate([`/partner/bonds/edit/${bondId}/${bonderId}`])
  }

  goToAddBond() {
    this.onAdd.emit();
  }

  isAllSelected() {
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.selectedItemList = this.allBonds.filter(x => x.isSelected)
  }

  checkUncheckAll(event: any) {
    this.allBonds = this.allBonds.map(x => {
      x.isSelected = this.isMasterSel;
      return x;
    });
    this.getCheckedItemList();
  }

  assign() {
    if (this.selectedToAdd && this.selectedToAdd.length) {
      this.selectedItems = this.selectedItems.concat(this.selectedToAdd);
      this.availableColumnsArray = this.availableColumnsArray.filter((selectedData: any) => {
        return this.selectedItems.indexOf(selectedData) < 0;
      });
      this.selectedToAdd = [];
      // this.length=this.allBonds.length;
    }
  }

  unassign() {
    if (this.selectedToRemove && this.selectedToRemove.length) {
      this.availableColumnsArray = this.availableColumnsArray.concat(this.selectedToRemove);
      this.selectedItems = this.selectedItems.filter((selectedData: any) => {
        return this.availableColumnsArray.indexOf(selectedData) < 0;
      });
      this.selectedToRemove = [];
    }
  }

  up() {
    if (this.selectedToRemove && this.selectedToRemove.length) {
      let index = this.selectedItems.findIndex(x => x == this.selectedToRemove[0]);
      if (index > 0) {
        let temp = this.selectedItems[index - 1];
        this.selectedItems[index] = temp;
        this.selectedItems[index - 1] = this.selectedToRemove[0]
      }
    }
  }

  down() {
    if (this.selectedToRemove && this.selectedToRemove.length) {
      let index = this.selectedItems.findIndex(x => x == this.selectedToRemove[0]);
      if (index < this.selectedItems.length - 1) {
        let temp = this.selectedItems[index + 1];
        this.selectedItems[index] = temp;
        this.selectedItems[index + 1] = this.selectedToRemove[0]
      }
    }
  }

  onFilterChange(event: any) {
    this.isMasterSel = false;
    let val = event.target.value;
    if (val == '2') this.isSharedFilter = true;
    else this.isSharedFilter = false;
    if (this.isEmptyHomeSpace && !this.isSharedFilter) return;
    if (val == '0') this.onFilterOptionChange.emit({ key: 'space', spaceOption: '0' });
    else if (val == '1') this.onFilterOptionChange.emit({ key: 'spaceunder', spaceOption: '1' });
    else if (val == '2') this.onFilterOptionChange.emit({ key: 'shared', spaceOption: '2' });
    this.selectedItemList = [];
    this.searchControl.setValue(null);
  }

  onSecondFilterChange(event: any) {
    // if (!event || !event.target.value) return;
    // let val = event.target.value;
    if (!event) return;
    if (event == 1) this.onsecondFilterOptionChange.emit({ key: 'edit', bondList: this.selectedItemList })
    else if (event == 2) this.onsecondFilterOptionChange.emit({ key: 'restart', bondId: this.selectedItemList[0].id })
    else if (event == 3) this.onsecondFilterOptionChange.emit({ key: 'restore', bondId: this.selectedItemList[0].id })
    else if (event == 4) this.onsecondFilterOptionChange.emit({
      key: 'move',
      bondId: this.selectedItemList[0].id,
      count: this.selectedItemList.length,
      bonderId: this.selectedItemList[0].bonder.id,
      space: this.selectedItemList[0].space.key
    })
    else if (event == 5) {
      let bonIdList: any = [];
      if (this.selectedItemList && this.selectedItemList.length > 0) {
        this.selectedItemList.forEach((item: any) => {
          if (item && item.id) bonIdList.push({ id: '' + item.id, homeSpace: item.isSharedSdwan ? item.space.key : null, shareId: item.shareId });
        });
        this.onShared(bonIdList, { view: true, update: true, delete: true }, true);
      }
    } else if (event == 6) {
      let bonIdList: any = [];
      let isLeg = false;
      if (this.selectedItemList && this.selectedItemList.length > 0) {
        this.selectedItemList.forEach((item: any) => {
          let legList: any[] = [];
          if (item && item.id && item.legsInfo && item.legsInfo.length > 0) {
            item.legsInfo.forEach((leg: any) => {
              if (leg && leg.id && leg.link_mode == 'active') {
                isLeg = true;
                legList.push({ legId: leg.id, state: leg.aggregator_status?.state });
              }
            });
          }
          if (legList.length > 0) bonIdList.push({ bondId: '' + item.id, legList: legList });
        });
        if (isLeg) this.onMultipleALert(bonIdList);
        else this.sharedService.loggerError('No active leg found.');
      }
    }
  }

  onDelete(bondData: any) {
    this.onDeleteBond.emit(bondData);
  }

  onShared(bondData: any, permission: any, isMultiple: boolean) {
    let modal = this.modalService.open(SharedBondSharingModalComponent, { size: 'md' });
    modal.componentInstance.bondData = bondData;
    modal.componentInstance.isMultiple = isMultiple;
    let permissionsObject: any = {};
    permissionsObject['view'] = this.permissions?.view || false;
    permissionsObject['update'] = this.permissions?.update || false;
    permissionsObject['delete'] = this.permissions?.delete || false;
    if (permission?.view == 'false') permissionsObject.view = false;
    if (permission?.update == 'false') permissionsObject.update = false;
    if (permission?.delete == 'false') permissionsObject.delete = false;
    if (permissionsObject?.view !== true) permissionsObject['isViewDisable'] = true;
    if (permissionsObject?.update !== true) permissionsObject['isUpdateDisable'] = true;
    if (permissionsObject?.delete !== true) permissionsObject['isDeleteDisable'] = true;
    modal.componentInstance.permissions = permissionsObject;
    if (isMultiple) modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.allBonds.map(x => {
          x.isSelected = false;
          return x;
        });
        this.isMasterSel = false;
        this.selectedItemList = [];
        this.cd.detectChanges();
      }
    }, () => { });
  }

  onMultipleALert(bondList: any) {
    if (bondList && bondList.length > 0) {
      let modal = this.modalService.open(AlertConfigurationFormComponent, { size: 'md' });
      modal.componentInstance.isMultiple = true;
      modal.result.then((data) => {
        if (data && data?.event === 'save' && data?.list && data?.list?.length > 0) {
          this.sharedService.showLoader();
          this.alertService.addMultiMonitoring(bondList, data?.list)?.subscribe((res: any) => {
            if (res && (res.code == 200 || res.code == 201)) {
              this.sharedService.loggerSuccess('Alert Monitor saved successfully.');
            } else this.sharedService.loggerError(res.message);
            this.allBonds.map(x => {
              x.isSelected = false;
              return x;
            });
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          }, (err) => {
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          })
        }
      }, () => { });
    } else this.sharedService.loggerError('No active leg found.');
  }

  onSaveColumns() {
    this.onSaveColumn.emit({ data: this.selectedItems })
  }

  open() {
    this.onSelectColumn.emit();
  }

  calculateBytes(rate: number) {
    return this.sharedService.calculateBytes(rate);
  }
}
