<div class="modal-header px-5 py-3">
    <h5 class="modal-title">{{isEdit ? 'Edit': 'Add'}} Delegated Allocation</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body p-5">
    <div class="row" *ngIf="errors?.non_field_errors">
        <div class="col-md-12">
            <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
        </div>
    </div>
    <div class="row" *ngIf="errors && !errors['non_field_errors']">
        <div class="col-md-12">
            <div class="alert alert-danger">Failed to update delegated subnet. There were problems validating the data.
                Please review and correct the issues below.
            </div>
        </div>
    </div>
    <form [formGroup]="GroupAllocation">
        <p> Specify the IP subnet to delegate to a child space </p>
        <div class="form-group mb-3">
            <label class="form-label"> Parent </label><br />
            <label>{{spaceData?.network}}</label>
        </div>
        <div class="form-group mb-3">
            <div class="form-group inputtext-block mb-5">
                <label class="form-label"> Subnet </label>
                <input type="text" formControlName="subnet" name="subnet"
                    class="form-control form-control-lg form-control-solid" />
                <small class="form-invalid-message">
                    <span class="text-danger">
                        {{errors?.network?.join(' ')}}
                    </span>
                </small>
            </div>
        </div>
        <div class="form-group mb-3">
            <label class="form-label"> Space </label>
            <ng-select class="dropdown-block" formControlName="routing_group" name="routing_group" [readonly]="isEdit">
                <ng-option *ngFor="let space of spaceList" [value]="space.key">{{space.name}}</ng-option>
            </ng-select>
            <small class="form-invalid-message">
                <span class="text-danger">
                    {{errors?.space?.join(' ')}}
                </span>
            </small>
        </div>
    </form>
</div>
<div class="modal-footer px-5 py-3">
    <button (click)="onSave()" type="button" class="btn btn-primary btn-style">
        <div>
            <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> {{isEdit ? 'Save': 'Delegate'}}
    </button>
    <button (click)="onClose()" type="button" class="btn btn-secondary btn-style">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2">
            </span>
        </div>Cancel
    </button>
</div>