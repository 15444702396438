<!-- begin::Brand -->
<div class="aside-logo flex-column-auto" id="kt_aside_logo"
  [ngClass]="navBarText?.trim() ? '': 'justify-content-center'">
  <!-- begin::Logo -->
  <ng-container>
    <img *ngIf="asideMinimize && navBarImageUrl && !isAsideMinimize" alt="Logo" class="slogo h-35px me-1"
      [src]="navBarImageUrl" />
    <img *ngIf="asideMinimize && faviconImageUrl && isAsideMinimize" alt="Logo" class="flogo h-35px me-1 w-100"
      [src]="faviconImageUrl" />
    <!-- <span *ngIf="isNavTextSelected" class="logo-text text-primary">{{navBarText}}</span> -->
    <a [routerLink]="routerLink">
      <!-- <img alt="Logo" class="h-25px logo" src="./assets/media/logos/logo_sidebar_white.png" /> -->
      <span *ngIf="navBarText" class="logo logo-text ps-3">{{navBarText}}</span>
    </a>
  </ng-container>
  <!-- end::Logo -->

  <!-- begin::Aside toggle -->
  <ng-container *ngIf="asideMinimize">
    <div (click)="animationAdd()" id="kt_aside_toggle"
      class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true"
      data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
      <!-- <img *ngIf="asideMinimize" alt="Logo" class="slogo" src="./assets/media/logos/sidebar-close.png" /> -->
      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr079.svg'"
        class="svg-icon svg-icon-1 rotate-180"></span>
    </div>
  </ng-container>
  <!-- end::Aside toggler -->
</div>
<!-- end::Brand  -->

<!-- begin::Aside menu -->
<div class="aside-menu flex-column-fluid">
  <div #ktAsideScroll id="kt_aside_menu_wrapper" class="hover-scroll-overlay-y my-5 my-lg-5" data-kt-scroll="true"
    data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto"
    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu"
    data-kt-scroll-offset="0">
    <div id="#kt_aside_menu" data-kt-menu="true" class="
        menu
        menu-column
        menu-title-gray-800
        menu-state-title-primary
        menu-state-icon-primary
        menu-state-bullet-primary
        menu-arrow-gray-500
      ">
      <app-aside-menu id="asideMenu"></app-aside-menu>
    </div>
  </div>
</div>
<!-- end::Aside menu -->

<!-- begin::Footer -->
<!-- <div class="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
  <a target="_blank" class="btn btn-custom btn-primary w-100" [href]="appPreviewDocsUrl" data-bs-toggle="tooltip"
    data-bs-trigger="hover" data-bs-dismiss-="click"
    ngbDropdown="Check out the complete documentation with over 100 components">
    <span class="btn-label">Docs & Components</span>
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'" class="svg-icon btn-icon svg-icon-2"></span>
  </a>
</div> -->
<!-- end::Footer -->