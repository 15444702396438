<div class="modal-header px-5 py-3">
    <h5 class="modal-title">{{isEdit ? 'Edit Legs': 'Add Legs'}}</h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body  p-0">
    <div class="card">
        <div class="mb-0 mb-xl-0">
            <div class="d-flex overflow-auto mt-0 card bg-light">
                <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
                    <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6">
                        <li class="nav-item">
                            <a (click)="onTabChange(1)" class="nav-link text-active-primary mx-0 px-5"
                                [class.active]="selectedTab==1">
                                <span [inlineSVG]="'./assets/media/icons/normal/chart-network.svg'"
                                    class="svg-icon svg-icon-5 me-2 "></span> Leg Options
                                <span *ngIf="legOptionForm?.invalid"
                                    [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-circle.svg'"
                                    class="svg-icon svg-icon-3 text-alert p-5">
                                </span>
                            </a>
                        </li>
                        <li class="nav-item align-items-center"><span
                                class="h-40px border-start border-muted mx-0 border-1"></span></li>
                        <li *ngIf="isInterfaceChecked" class="nav-item">
                            <a (click)="onTabChange(2)" class="nav-link text-active-primary mx-0 px-5"
                                [class.active]="selectedTab==2">
                                <span [inlineSVG]="'./assets/media/icons/normal/home-lg.svg'"
                                    class="svg-icon svg-icon-5 me-2"></span> Address
                                <span *ngIf="addressForm.invalid || this.pppoeError || this.staticError.length > 0"
                                    [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-circle.svg'"
                                    class="svg-icon svg-icon-3 p-5 me-2">
                                </span>
                                <span *ngIf="noLegsConfigured"
                                    [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-triangle.svg'"
                                    class="svg-icon svg-icon-3 ms-2">
                                </span>
                            </a>
                        </li>
                        <li *ngIf="!isInterfaceChecked" class="nav-item">
                            <a (click)="onTabChange(2)" class="nav-link text-active-primary mx-0 px-5"
                                [class.active]="selectedTab==2">
                                <span [inlineSVG]="'./assets/media/icons/normal/mobile-android.svg'"
                                    class="svg-icon svg-icon-5 me-2 "></span> Mobile Broadband
                                <span [hidden]="!mobileBroadBandForm.invalid"
                                    [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-circle.svg'"
                                    class="svg-icon svg-icon-3 p-5">
                                </span>
                            </a>
                        </li>
                        <li class="nav-item align-items-center"><span
                                class="h-40px border-start border-muted mx-0 border-1"></span></li>
                        <li class="nav-item">
                            <a (click)="onTabChange(3)" class="nav-link text-active-primary mx-0 px-5"
                                [class.active]="selectedTab==3">
                                <span [inlineSVG]="'./assets/media/icons/normal/tachometer.svg'"
                                    class="svg-icon svg-icon-5 me-2 "></span> Optimization
                                <span [hidden]="!optimizationForm.invalid"
                                    [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-circle.svg'"
                                    class="svg-icon svg-icon-3 p-5">
                                </span>
                            </a>
                        </li>
                        <li class="nav-item align-items-center"><span
                                class="h-40px border-start border-muted mx-0 border-1"></span></li>
                        <li class="nav-item">
                            <a (click)="onTabChange(4)" class="nav-link text-active-primary mx-0 px-5"
                                [class.active]="selectedTab==4">
                                <span [inlineSVG]="'./assets/media/icons/normal/dungeon.svg'"
                                    class="svg-icon svg-icon-5 me-2 "></span> Tunnel Bypass
                                <span [hidden]="!tunnelBypassForm.invalid"
                                    [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-circle.svg'"
                                    class="svg-icon svg-icon-3 p-5">
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <form [hidden]="selectedTab != 1" [formGroup]="legOptionForm">
            <div class="card">
                <div class="card-body p-5">
                    <div class="row" *ngIf="legOptionForm.invalid">
                        <div class="col-md-12">
                            <div class="alert alert-danger br-6">Please correct the errors below.</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-lg-12 mb-8">
                            <span
                                class="badge br-6 py-5 px-4 fs-7 badge-light-primary w-100 text-start fw-normal text-dark border-primary border-1 border text-wrap">These
                                options relate to the operations of the leg in the bond. For example, the
                                speed limits, whether the leg is used, etc.</span>
                        </div>
                        <div class="col-md-12 col-lg-4 mb-1">
                            <label class="form-label">Type</label>
                            <div class="d-flex flex-column">
                                <div>
                                    <div class=" d-flex align-items-center">
                                        <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                            <input (change)="onTypeOptionChange()" formControlName="type" id="radio1"
                                                name="type" checked value="Interface" type="radio"
                                                class="form-check-input me-2" [attr.disabled]="isEdit ? '': null">
                                            Interface <span class="form-check-label"> <span
                                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                    placement="top"
                                                    ngbTooltip="This leg will use an Ethernet or VLAN interface"></span></span>
                                        </label>
                                        <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                            <input (change)="onTypeOptionChange()" formControlName="type" id="radio2"
                                                name="type" value="Mobile broadband" type="radio"
                                                class="form-check-input me-2" [attr.disabled]="isEdit ? '': null">
                                            Mobile Broadband <span class="form-check-label"> <span
                                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                    placement="top"
                                                    ngbTooltip=" The leg will use a USB or PCIe mobile broadband modem"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <!-- <div *ngIf="isEdit">
                                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="legsData.type" readOnly
                                        type="text" class="form-control form-control-lg form-control-solid"
                                        placeholder="10" autocomplete="off" />
                                </div> -->
                                <!-- <small> The threshold of detected bandwidth, as a percentage of the configured leg
                                    speed, that triggers a warning </small> -->
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Mode <small
                                        *ngIf="legOptionForm.controls.link_mode.value == 'active'"> <span
                                            [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                            ngbTooltip="The leg will be used for bonded traffic."></span> </small>
                                    <small *ngIf="legOptionForm.controls.link_mode.value == 'idle'"> <span
                                            [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                            ngbTooltip="The leg will be configured but not used for bonded traffic."></span>
                                    </small> <small *ngIf="legOptionForm.controls.link_mode.value == 'offline'"> <span
                                            [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                            ngbTooltip="The leg will not be configured or used for bonded traffic."></span>
                                    </small></label>
                                <ng-select [searchable]="false" [clearable]="false" formControlName="link_mode"
                                    placeholder="Mode Options" class="dropdown-block">
                                    <ng-option *ngFor="let item of modeOptions" [value]="item.key"> {{item.title}}
                                    </ng-option>
                                </ng-select>
                                <!-- <small *ngIf="legOptionForm.controls.link_mode.value == 'active'">
                                    The leg will be used for bonded traffic.</small>
                                    <small *ngIf="legOptionForm.controls.link_mode.value == 'idle'">
                                        The leg will be configured but not used for bonded traffic.</small>
                                        <small *ngIf="legOptionForm.controls.link_mode.value == 'offline'">
                                            The leg will not be configured or used for bonded traffic.</small> -->
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Failover <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="If set, this leg will only be used if all other active legs have lost connectivity."></span>
                                </label>
                                <div class="d-flex flex-column mt-2">
                                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                        formControlName="failover" class="mt-0 ms-0"></ui-switch>
                                    <small class="text-danger" *ngIf="legOptionForm.hasError('failover')">
                                        {{legOptionForm.errors?.failover.join(' ')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4 mb-1" *ngIf="legOptionForm.controls.type.value == 'Interface'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Interface <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="The interface the leg will operate on."></span> </label>
                                <ng-select [searchable]="false" formControlName="interface" placeholder="Interface"
                                    class="dropdown-block">
                                    <ng-option *ngFor="let item of allInterfaces" [value]="item?.interface_url">
                                        {{item?.ifname}}
                                    </ng-option>
                                </ng-select>
                                <small class="form-invalid-message">
                                    <span class="text-danger" *ngIf="legOptionForm.hasError('interface')">
                                        {{legOptionForm.errors?.interface?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4 mb-1"
                            *ngIf="legOptionForm.controls.type.value == 'Mobile broadband'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Provider profile <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="The provider profile controls which APN the modem uses."></span>
                                </label>
                                <ng-select [searchable]="false" formControlName="provider" placeholder="provider"
                                    class="dropdown-block">
                                    <ng-option *ngFor="let item of providerProfiles" [value]="item?.url">
                                        {{item?.profile_name}}
                                    </ng-option>
                                </ng-select>
                                <small class="form-invalid-message">
                                    <span class="text-danger" *ngIf="legOptionForm.hasError('provider')">
                                        {{legOptionForm.errors?.provider.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-2 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Download speed <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The tested download speed that the leg is able to achieve, in Mbps. This field can be set to 0 if this leg is not to be used for download traffic."></span></label>
                                <input formControlName="down_speed" type="text" name="download speed"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger" *ngIf="legOptionForm.hasError('down_speed')">
                                        {{legOptionForm.errors?.down_speed?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-2 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Upload speed <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The tested upload speed that the leg is able to achieve, in Mbps. This field can be set to 0 if this leg is not to be used for upload traffic."></span></label>
                                <input formControlName="up_speed" type="text" name="download speed"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger" *ngIf="legOptionForm.hasError('up_speed')">
                                        {{legOptionForm.errors?.up_speed?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-2 mb-1"
                            *ngIf="legOptionForm.controls.type.value == 'Mobile broadband'">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Interface MTU <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The MTU to apply to the interface after it is created"></span>
                                </label>
                                <input formControlName="interface_mtu" type="text" name="interface_mtu"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger" *ngIf="legOptionForm.hasError('interface_mtu')">
                                        {{legOptionForm.errors?.interface_mtu?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-2 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Minimum path MTU <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The minimum value that MTU detection will reduce to."></span></label>
                                <input formControlName="minimum_mtu" type="text" name="download speed"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger" *ngIf="legOptionForm.hasError('minimum_mtu')">
                                        {{legOptionForm.errors?.minimum_mtu?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Note <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="A free-form field for any relevant information. Maximum 200 characters."></span></label>
                                <textarea class="form-control form-control-lg form-control-solid min-h-80px" rows="5"
                                    formControlName="note"> </textarea>
                                <small class="form-invalid-message">
                                    <span class="text-danger" *ngIf="legOptionForm.hasError('note')">
                                        {{legOptionForm.errors?.note?.join(' ')}}
                                    </span>
                                    <span class="invalid-feedback d-block" *ngIf="controlHasError('maxlength', 'note')">
                                        Note is invalid
                                    </span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div [hidden]="selectedTab != 2">
            <div *ngIf="isInterfaceChecked" class="card">
                <div class="card-body p-5">
                    <div class="row" *ngIf="addressForm.invalid || this.pppoeError || this.staticError.length > 0">
                        <div class="col-md-12">
                            <div class="alert alert-danger br-6">Please correct the errors below.</div>
                        </div>
                    </div>
                    <p>
                        <small
                            class="badge br-6 py-5 px-4 fs-7 badge-light-primary w-100 text-start fw-normal text-dark border-primary border-1 border text-wrap">Choose
                            the address type for this leg. Multiple addresses are supported; for
                            example, if a leg is configured with both a DHCP and Static address, the leg will be
                            configured with both types of address. In this event, the bonder will monitor both addresses
                            and choose whichever one is available.
                        </small>
                    </p>
                    <p *ngIf="noLegsConfigured">
                        <small
                            class="badge br-6 py-5 px-4 fs-7 badge-light-warning w-100 text-start fw-normal text-dark border-warning border-1 border text-wrap">
                            No address configured. This leg will not be active in the bond.</small>
                    </p>
                    <diV class="col-12 d-flex justify-content-end mb-3">
                        <button type="button" class="btn btn-primary btn-style mt-3" (click)="onAddAddress()">
                            <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                    class="svg-icon svg-icon-block mb-2"></span>
                            </div> Add Address
                        </button>
                    </diV>
                    <form [formGroup]="addressForm">
                        <div formArrayName="data" *ngIf="!noLegsConfigured" class="border br-6 px-5">
                            <div [ngClass]="x.value.isDeleted ? 'delete':  'enable'" [formGroupName]="i"
                                class="row align-items-center br-6" *ngFor="let x of data?.controls;let i=index">
                                <div class="row"
                                    *ngIf="staticError && staticError[i] && staticError[i].non_field_errors">
                                    <div class="col-md-12">
                                        <div class="alert alert-danger">{{staticError[i].non_field_errors}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-3">
                                    <div class="form-group inputtext-block mb-5">
                                        <label class="form-label"
                                            *ngIf="!x.value.isNew">{{getDropdownOptionName(i)}}</label>
                                        <label class="form-label" *ngIf="x.value.isNew"></label>
                                        <ng-select [clearable]="false" [searchable]="false" *ngIf="x.value.isNew"
                                            formControlName="dropDownOption" class="select-width w-100 dropdown-block">
                                            <ng-option *ngFor="let item of dropDownArray[i]; let idx = index"
                                                [selected]="idx == 0" [value]="item.value"> {{item.text}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-1">
                                    <div class="form-group inputtext-block">
                                        <label class="form-label"></label>
                                        <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                            formControlName="enabled" class="ms-5"> </ui-switch>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-3" *ngIf="x?.value.dropDownOption =='3'">
                                    <div class="form-group inputtext-block mb-5">
                                        <label class="form-label">IP</label>
                                        <input formControlName="ip" type="text" placeholder="192.168.0.2/24"
                                            class="form-control form-control-lg form-control-solid">
                                        <small class="form-invalid-message">
                                            <span class="text-danger"
                                                *ngIf="staticError && staticError[i] && staticError[i].ip">
                                                {{staticError[i].ip?.join(' ')}}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-2" *ngIf="x?.value.dropDownOption =='2'">
                                    <div class="form-group inputtext-block mb-5">
                                        <label class="form-label">UserName</label>
                                        <input formControlName="username" type="text"
                                            class="form-control form-control-lg form-control-solid">
                                        <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="pppoeError && pppoeError['username']">
                                                {{pppoeError['username']?.join(' ')}}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-3" *ngIf="x?.value.dropDownOption =='3'">
                                    <div class="form-group inputtext-block mb-5">
                                        <label class="form-label">Gateway</label>
                                        <input formControlName="gateway" type="text" placeholder="192.168.0.2"
                                            class="form-control form-control-lg form-control-solid">
                                        <small class="form-invalid-message">
                                            <span class="text-danger"
                                                *ngIf="staticError && staticError[i] && staticError[i].gateway">
                                                {{staticError[i].gateway?.join(' ')}}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-2" *ngIf="x?.value.dropDownOption =='2'">
                                    <div class="form-group inputtext-block mb-5">
                                        <label class="form-label">Password</label>
                                        <input formControlName="password" type="text"
                                            class="form-control form-control-lg form-control-solid">
                                        <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="pppoeError && pppoeError['password']">
                                                {{pppoeError['password']?.join(' ')}}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-2" *ngIf="x?.value.dropDownOption =='2'">
                                    <div class="form-group inputtext-block mb-5">
                                        <label class="form-label">IP Version</label>
                                        <ng-select formControlName="ip_version_preference"
                                            class="select-width dropdown-block" [clearable]="false"
                                            [searchable]="false">
                                            <ng-option selected value="4"> IPv4 only </ng-option>
                                            <ng-option value="6"> IPv6 Only </ng-option>
                                            <ng-option value="46"> IPv4 or IPv6 </ng-option>
                                        </ng-select>
                                        <small class="form-invalid-message">
                                            <span class="text-danger"
                                                *ngIf="pppoeError && pppoeError['ip_version_preference']">
                                                {{pppoeError['ip_version_preference']?.join(' ')}}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <div [ngClass]="x?.value.dropDownOption =='2' || x?.value.dropDownOption =='3' ? 'mt-3' : ''"
                                    class="col-md-12 col-lg-1">
                                    <div class="form-group inputtext-block">
                                        <button type="button" class="btn btn-icon-1" (click)="onToggle(i,x)"
                                            [ngClass]="data.controls[i].get('isDeleted')?.value ? 'btn-primary' : 'btn-danger'">
                                            <div>
                                                <span *ngIf="!data.controls[i].get('isDeleted')?.value"
                                                    [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg-icon svg-icon-block">
                                                </span>
                                                <span *ngIf="data.controls[i].get('isDeleted')?.value"
                                                    [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'"
                                                    class="svg-icon svg-icon-block">
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="!isInterfaceChecked" class="card">
                <div class="card-body p-5">
                    <div class="row" *ngIf="mobileBroadBandForm.invalid">
                        <div class="col-md-12">
                            <div class="alert alert-danger br-6">Please correct the errors below.</div>
                        </div>
                    </div>
                    <form [formGroup]="mobileBroadBandForm">
                        <div class="row">
                            <div class="col-md-12 col-lg-4 mb-1">
                                <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">IMEI <span
                                            [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                            ngbTooltip="The IMEI is a 15-digit unique identifier for a mobile network device."></span></label>
                                    <input formControlName="imei" type="text"
                                        class="form-control form-control-lg form-control-solid" name="Warning threshold"
                                        placeholder="90" autocomplete="off" />
                                    <small class="form-invalid-message">
                                        <span class="text-danger" *ngIf="mobileBroadBandForm.hasError('imei')">
                                            {{mobileBroadBandForm.errors?.imei?.join(' ')}}
                                        </span>
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-4 mb-1">
                                <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">SIM PIN <span
                                            [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                            ngbTooltip="The PIN required to access the SIM card, if necessary."></span></label>
                                    <input formControlName="sim_pin" type="text"
                                        class="form-control form-control-lg form-control-solid" name="Warning threshold"
                                        placeholder="90" autocomplete="off" />
                                    <small class="form-invalid-message">
                                        <span class="text-danger" *ngIf="mobileBroadBandForm.hasError('sim_pin')">
                                            {{mobileBroadBandForm.errors?.sim_pin?.join(' ')}}
                                        </span>
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-4 mb-1">
                                <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Roaming <span
                                            [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                            ngbTooltip="Configures whether the leg will be constrained to its home network or not."></span></label>
                                    <div class="d-flex flex-column">
                                        <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                            formControlName="roaming" class="mt-2 ms-0"> </ui-switch>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 mb-1">
                                <label class="form-label">Access modes <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The list of access modes that the modem can use to connect to the mobile network. "></span></label>
                                <div class="d-flex flex-column">
                                    <select class="group-select-box textarea-field br-6" multiple name="myselecttsms2"
                                        formControlName="access_modes">
                                        <option *ngFor="let item of accessModes" class="br-6"
                                            style="text-align: left; margin-bottom: 2px; padding: 5px 15px;"
                                            [value]="item.key">{{item.title}}</option>
                                    </select>
                                    <small class="text-danger" *ngIf="mobileBroadBandForm.hasError('access_modes')">
                                        {{mobileBroadBandForm.errors?.access_modes?.join(' ')}}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 mb-1">
                                <label class="form-label">Radio modes <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The list of radio bands that the modem can use to connect to the mobile network."></span></label>
                                <div class="d-flex flex-column">
                                    <select formControlName="radio_bands" class="group-select-box textarea-field br-6"
                                        multiple name="myselecttsms2">
                                        <option *ngFor="let item of radioBandModes" class="br-6"
                                            style="text-align: left; margin-bottom: 2px; padding: 5px 15px;"
                                            [value]="item">{{item}}</option>
                                    </select>
                                    <small class="text-danger" *ngIf="mobileBroadBandForm.hasError('radio_bands')">
                                        {{mobileBroadBandForm.errors?.radio_bands?.join(' ')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div [hidden]="selectedTab != 3" class="card">
            <div class="card-body p-5">
                <form [formGroup]="optimizationForm">
                    <div class="row">
                        <div class="row" *ngIf="optimizationForm.invalid">
                            <div class="col-md-12">
                                <div class="alert alert-danger br-6">Please correct the errors below.</div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 mb-1">
                            <h5 class="mb-3"> Bandwidth adaptation </h5>
                            <p> <small
                                    class="badge br-6 py-5 px-4 fs-7 badge-light-primary w-100 text-start fw-normal text-dark border-primary border-1 border text-wrap">
                                    Bandwidth adaptation adjusts leg download and upload speeds when it detects
                                    increases in leg latency or packet loss. </small></p>
                        </div>
                        <div class="col-md-12 col-lg-2 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Enabled <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="If set, bandwidth adaptation will be enabled for this leg."></span>
                                </label>
                                <div class="d-flex flex-column mt-2">
                                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                        formControlName="bandwidth_adaptation" class="mt-0 ms-0"> </ui-switch>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Warning threshold <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The threshold of detected bandwidth, as a percentage of the configured leg speed, that triggers a warning"></span></label>
                                <input formControlName="bandwidth_adaptation_warn" type="text"
                                    class="form-control form-control-lg form-control-solid" name="Warning threshold"
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"
                                        *ngIf="optimizationForm.hasError('bandwidth_adaptation_warn')">
                                        {{optimizationForm.errors?.bandwidth_adaptation_warn?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Limit threshold <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The minimum speed, as a percentage of the configured leg speed, that adaptation will adjust to."></span></label>
                                <input formControlName="bandwidth_adaptation_limit" type="text"
                                    class="form-control form-control-lg form-control-solid" name="Limit threshold"
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"
                                        *ngIf="optimizationForm.hasError('bandwidth_adaptation_limit')">
                                        {{optimizationForm.errors?.bandwidth_adaptation_limit?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label"> Packet loss threshold <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The threshold of packet loss that is detected to trigger adaptation."></span></label>
                                <input formControlName="bandwidth_adaptation_packet_loss_threshold" type="text"
                                    class="form-control form-control-lg form-control-solid" name="Packet loss threshold"
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"
                                        *ngIf="optimizationForm.hasError('bandwidth_adaptation_packet_loss_threshold')">
                                        {{optimizationForm.errors?.bandwidth_adaptation_packet_loss_threshold?.join('
                                        ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 mb-1">
                            <h5 class="mb-3"> Packet loss detection </h5>
                            <p
                                class="badge br-6 py-5 px-4 fs-7 badge-light-primary w-100 text-start fw-normal text-dark border-primary border-1 border text-wrap">
                                Packet loss detection will conditionally remove legs from the bond if packet loss is
                                detected on the link, and there are other legs operating normally. This feature is
                                disabled. Edit the bond configuration to change it. </p>
                        </div>
                        <div class="col-md-12 col-lg-5 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Removal threshold <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="If packet loss detection is enabled, this will indicate how much packet loss, asa percentage, will be tolerated before the leg is removed from the bond, as longas there are other active legs that do not exceed their own removal threshold."></span></label>
                                <input formControlName="packet_loss_removal_threshold" type="text"
                                    class="form-control form-control-lg form-control-solid" name="Packet loss threshold"
                                    autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"
                                        *ngIf="optimizationForm.hasError('packet_loss_removal_threshold')">
                                        {{optimizationForm.errors?.packet_loss_removal_threshold?.join(' ')}}
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="packetLossDetection?.invalid && (packetLossDetection?.dirty || packetLossDetection?.touched)">
                                        Please provide value for packet loss remove threshold.
                                    </span>
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div [hidden]="selectedTab != 4" class="card">
            <form [formGroup]="tunnelBypassForm">
                <div class="card-body p-5">
                    <div class="row" *ngIf="tunnelBypassForm.invalid">
                        <div class="col-md-12">
                            <div class="alert alert-danger br-6">Please correct the errors below.</div>
                        </div>
                    </div>
                    <p
                        class="badge br-6 py-5 px-4 fs-7 badge-light-primary w-100 text-start fw-normal text-dark border-primary border-1 border text-wrap">
                        Tunnel bypass is a feature which allows customer traffic to be sent and received on a bonder
                        directly via a leg, bypassing the tunnel and the aggregator. </p>
                    <div class="alert alert-info br-6" role="alert"
                        *ngIf="bondDetails && !bondDetails.classification_profile">
                        <i class="fa-solid fa-circle-info"></i>
                        This bond does not have an enabled classification profile. Changes here will have no effect.
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-lg-4 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Soft reserve <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="The soft reserve for tunnel-bypass traffic as a percentage of the configured leg speed."></span>
                                </label>
                                <input formControlName="tunnel_bypass_percentage" type="text"
                                    class="form-control form-control-lg form-control-solid" name="soft reserve"
                                    placeholder="100" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"
                                        *ngIf="tunnelBypassForm.hasError('tunnel_bypass_percentage')">
                                        {{tunnelBypassForm.errors?.tunnel_bypass_percentage?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4 mb-1">
                            <div class="form-group inputtext-block mb-5">
                                <label class="form-label">Priority <span
                                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                        ngbTooltip="Priority indicates which leg will be used for tunnel-bypass traffic. Lower numbers take precedence."></span></label>
                                <input formControlName="tunnel_bypass_priority" type="text"
                                    class="form-control form-control-lg form-control-solid" name="priority"
                                    placeholder="0" autocomplete="off" />
                                <small class="form-invalid-message">
                                    <span class="text-danger"
                                        *ngIf="tunnelBypassForm.hasError('tunnel_bypass_priority')">
                                        {{tunnelBypassForm.errors?.tunnel_bypass_priority?.join(' ')}}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-2 mb-1">
                            <label class="form-label">Enabled <span
                                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1" placement="top"
                                    ngbTooltip="If set, tunnel bypass will be enabled for this leg."></span> </label>
                            <div class="d-flex flex-column mt-2">
                                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                    formControlName="tunnel_bypass_enabled" class="mt-0 ms-0"> </ui-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> {{isEdit ? 'Save': 'Add'}}
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>