<!-- <div class="">
    <div class="div-bg d-flex">
        <div class="mt-5">
            <input [(ngModel)]="selectedOption" (ngModelChange)="change()" [value]="bondDetails.key" selected type="radio">
            <span class="mr-2">{{bondDetails.name}}</span>
        </div>
    </div>
</div>
<div class="mt-2 ml-5" *ngFor="let item of bondDetails?.child_spaces">

    <app-recursive-space [bondDetails]="item"></app-recursive-space>
</div> -->

<div *ngFor="let item of bondDetails" class="col-md-12">
    <div class="d-flex bg-light px-5 py-3">
        <input [(ngModel)]="selectedOption" (ngModelChange)="change($event)" [value]="item.key" checked="item.key == selectedOption" selected type="radio">
        <span class="ms-2 fs-7 fw-bold">{{item.name}}</span>
    </div>
    <div *ngIf="item?.child_spaces" class="row mt-2 ps-10">
          <app-recursive-space [bondDetails]="item?.child_spaces" [selectedOption]="selectedOption"></app-recursive-space>
    </div>
  </div>

    <!-- <div class="col-md-1">

    </div>
    <div class="col-md-11 div-bg d-flex">
        <div  class="mt-5">
            <input [(ngModel)]="selectedOption" [value]="'child_'+item.key" type="radio">
            <span class="mr-2">{{item?.name}}</span>
        </div>
    </div>   -->
    <!-- <div class="col-md-1">

    </div>
    <div class="col-md-11 div-bg d-flex">
        <div  class="mt-5">
            <input [(ngModel)]="selectedOption" [value]="'child_'+item.key" type="radio">
            <span class="mr-2">{{item?.name}}</span>
        </div>
    </div> -->
