<div class="d-flex flex-column flex-root">
  <div class="page d-flex flex-row flex-column-fluid">
    <app-aside id="kt_aside" data-kt-drawer="true" data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle" class="aside " [ngClass]="asideCSSClasses"></app-aside>
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <app-header id="kt_header" [ngClass]="headerCSSClasses" [class.top-branding-header]="showHeader"
        class="header align-items-stretch" #ktHeader></app-header>
      <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
        <div class="container-fluid position-relative">
          <app-toolbar class="toolbar d-none d-lg-flex my-3 position-absolute" id="kt_toolbar"></app-toolbar>
        </div>
        <div class="post d-flex flex-column-fluid position-relative" id="kt_post">
          <app-content id="kt_content_container" class="container-fluid"></app-content>
        </div>
      </div>
      <app-footer [ngClass]="footerCSSClasses" class="footer py-4 d-flex flex-lg-column" id="kt_footer"></app-footer>
    </div>
  </div>
</div>

<app-scripts-init></app-scripts-init>

<!-- begin:: Drawers -->
<!-- <app-activity-drawer></app-activity-drawer> -->
<!-- <app-explore-main-drawer></app-explore-main-drawer> -->
<!-- <app-messenger-drawer></app-messenger-drawer> -->
<!-- end:: Drawers -->

<!-- begin:: Modals -->
<!-- <app-main-modal></app-main-modal> -->
<!-- <app-invite-users-modal></app-invite-users-modal>
<app-upgrade-plan-modal></app-upgrade-plan-modal> -->
<!-- end:: Modals -->