import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as versionData from 'version.json';
import { LayoutService } from '../../core/layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerContainerCssClasses: string = '';
  currentDateStr: string = new Date().getFullYear().toString();
  appVersion: string = '0.0.0';
  versionData = versionData;

  constructor(private layout: LayoutService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    // this.footerContainerCssClasses = this.layout.getStringCSSClasses('footerContainer');
    if (this.versionData && this.versionData.number) this.appVersion = this.versionData.number;
    this.cd.detectChanges();
  }
}
