import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-space-create',
  templateUrl: './shared-space-create.component.html',
  styleUrls: ['./shared-space-create.component.scss']
})
export class SharedSpaceCreateComponent implements OnInit {
  SpaceAdd!: FormGroup
  errors: any = {};
  NameFieldValidation: any = AppConst.NameFieldPattern;

  @Input() spaceData: any;
  @Input() isEdit: boolean = false;
  @Input() parentName: string;
  @Input() parentId: string;
  @Input() isSpaceLoader: boolean = false;

  constructor(private sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private spaceService: PartnerSpaceService,
    private router: Router, private storageService: StorageService,
    private fb: FormBuilder) { }


  ngOnInit(): void {
    this.SpaceAdd = this.createSpaceForm();
  }

  createSpaceForm(data: any = {}) {
    return this.fb.group({
      parent: [data?.parent || ''],
      name: [data?.name || '', [this.sharedService.xssValidator, Validators.pattern(this.NameFieldValidation)]],
      key: [data?.key || '', Validators.required],
      use_parent_qos_profiles: [data?.use_parent_qos_profiles || false],
      use_parent_classification_profiles: [data?.use_parent_classification_profiles || false],
      use_parent_flow_collectors: [data?.use_parent_flow_collectors || false],
      use_parent_aggregators: [data?.use_parent_aggregators || false],
      use_parent_mobile_providers: [data?.use_parent_mobile_providers || false],
      use_parent_routing_groups: [data?.use_parent_routing_groups || false],
      create_iso: [data?.create_iso || false],
      crm_id: [data?.crm_id || '', [this.sharedService.xssValidator]],
      note: [data?.note || '', [this.sharedService.xssValidator]],
      replify_enterprise_manager: [data?.replify_enterprise_manager || '', [this.sharedService.xssValidator]],
    })
  }

  onSave() {
    if (this.isSpaceLoader) return;
    let apiURL = this.storageService.getCipherObj('api_url');
    if (!apiURL) return;
    if (this.SpaceAdd?.invalid) {
      this.SpaceAdd.markAllAsTouched();
      return;
    }
    let body = {
      parent: this.isEdit ? this.SpaceAdd.value.parent : `${apiURL}spaces/${this.parentId}/`,
      name: this.SpaceAdd.value.name,
      key: this.SpaceAdd.value.key,
      use_parent_qos_profiles: this.SpaceAdd.value.use_parent_qos_profiles,
      use_parent_classification_profiles: this.SpaceAdd.value.use_parent_classification_profiles,
      use_parent_flow_collectors: this.SpaceAdd.value.use_parent_flow_collectors,
      use_parent_aggregators: this.SpaceAdd.value.use_parent_aggregators,
      use_parent_mobile_providers: this.SpaceAdd.value.use_parent_mobile_providers,
      use_parent_routing_groups: this.SpaceAdd.value.use_parent_routing_groups,
      create_iso: this.SpaceAdd.value.create_iso,
      crm_id: this.SpaceAdd.value.crm_id,
      note: this.SpaceAdd.value.note,
      replify_enterprise_manager: this.SpaceAdd.value.replify_enterprise_manager
    }
    // this.spaceService.addSpace(reqObj)
    if (this.isEdit) {
      let reqObj = { method: "PATCH", url: `spaces/${this.parentId}/`, data: body }
      this.sharedService.showLoader();
      this.spaceService.editSpace(reqObj).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerSuccess(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        try {
          console.log("🚀 ~ file: shared-space-create.component.ts ~ line 83 ~ SharedSpaceCreateComponent ~ onSave ~ err", err);
          this.errors = JSON.parse(err);
          Object.keys(this.errors).forEach(x => {
            let SpaceAddControl = this.SpaceAdd.get(x);
            if (SpaceAddControl) this.SpaceAdd.setErrors({ ...this.SpaceAdd.errors, [x]: this.errors[x] });
          })
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors?.non_field_errors);
          else this.sharedService.loggerError(err);
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    } else {
      let reqObj = { method: "POST", url: `spaces/`, data: body }
      this.sharedService.showLoader();
      this.spaceService.addSpace(reqObj).subscribe((addRes) => {
        if (addRes.code == 201 || addRes.code == 201) {
          this.sharedService.loggerSuccess(addRes.message);
          this.spaceService.setSpaceParentId(addRes.data.key);
          this.spaceService.setSpaceData(addRes.data);
          this.storageService.setCipherText('space', addRes.data.key);
          this.router.navigate([`/partner/space/edit`]);
        }
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        try {
          console.log("🚀 ~ file: shared-space-create.component.ts ~ line 83 ~ SharedSpaceCreateComponent ~ onSave ~ err", err);
          this.errors = JSON.parse(err);
          Object.keys(this.errors).forEach(x => {
            let SpaceAddControl = this.SpaceAdd.get(x);
            if (SpaceAddControl) this.SpaceAdd.setErrors({ ...this.SpaceAdd.errors, [x]: this.errors[x] });
          })
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors?.non_field_errors);
          else this.sharedService.loggerError(err);
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  navigateToSpace() {
    this.router.navigate([`/partner/space`]);
  }

  controlHasError(validation: any, controlName: any): boolean {
    const control = this.SpaceAdd.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }
}
