<div class="modal-header px-5 py-3">
  <h5 class="modal-title">{{isEdit ? 'Edit' : 'Add'}} Protocol</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body p-5">
  <div class="card mb-xl-0">
    <form [formGroup]="addProtocol">
      <div class="card mb-xl-0">
        <div class="card-body p-0">
          <div class="row" *ngIf="errors?.non_field_errors">
            <div class="col-md-12">
              <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
            </div>
          </div>
          <div class="row" *ngIf="errors && !errors['non_field_errors']">
            <div class="col-md-12">
              <div class="alert alert-danger">Please correct the errors below.</div>
            </div>
          </div>
          <div class="row">
            <!-- basic -->
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Name
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="A short description of this protocol's purpose."></span>
                </label>
                <div class="d-flex flex-column">
                  <input formControlName="name" name="name" type="text"
                    class="form-control form-control-lg form-control-solid" name="Name" placeholder="Name"
                    autocomplete="off" />
                  <small class="text-danger"> {{errors?.name?.join(' ')}} </small>
                  <small class="text-danger" *ngIf="controlHasError('pattern', 'name')">
                    Name is invalid
                  </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Include in private WAN
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="Apply this protocol to routes in private WAN."></span>
                </label>
                <div class="d-flex flex-column mt-2">
                  <ui-switch formControlName="private_wan" name="private_wan" checkedLabel="ON" size="small"
                    uncheckedLabel="OFF"></ui-switch>
                  <small class="text-danger"> {{errors?.private_wan?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Protocol
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="The type of protocol."></span>
                </label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="Protocol" (change)="changeType($event)" formControlName="protocol"
                    name="protocol" class="dropdown-block" [clearable]="false" [searchable]="false">
                    <ng-option value="ospf"> OSPF</ng-option>
                    <ng-option value="bgp"> BGP</ng-option>
                    <ng-option value="static"> Static </ng-option>
                    <ng-option value="babel">Babel</ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.protocol?.join(' ')}} </small>
                  <small class="text-danger" *ngIf="controlHasError('required', 'name')">
                    Protocol is required
                  </small>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-lg-2 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Enabled
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="Enable protocol."></span>
                </label>
                <div class="d-flex flex-column mt-2">
                  <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="enabled"
                    name="enabled"></ui-switch>
                  <small class="text-danger"> {{errors?.enabled?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-lg-2 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Debug
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="Enable protocol debug logging."></span>
                </label>
                <div class="d-flex flex-column mt-2">
                  <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="debug"
                    name="debug"></ui-switch>
                  <small class="text-danger"> {{errors?.debug?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">IPv4 import
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="What IPv4 routes should be kept when importing from the protocol to the routing table."></span>
                </label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv4_import" name="ipv4_import" class="dropdown-block"
                    [clearable]="false" [searchable]="false">
                    <ng-option value="all"> All</ng-option>
                    <ng-option value="none"> None</ng-option>
                    <ng-option value="filter"> Filter </ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv4_import?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv4_import !='filter'"></div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv4_import =='filter'">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Filter for IPv4 import</label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv4_import_filter" name="ipv4_import_filter"
                    class="dropdown-block">
                    <ng-option *ngFor="let filter of allfilters;" [value]=filter.url>{{filter.name}}</ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv4_import_filter?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">IPv4 export
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="What IPv4 routes should be kept when exporting from the routing table to the protocol."></span>
                </label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv4_export" name="ipv4_export" class="dropdown-block"
                    [clearable]="false" [searchable]="false">
                    <ng-option value="all"> All</ng-option>
                    <ng-option value="none"> None</ng-option>
                    <ng-option value="filter"> Filter </ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv4_export?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv4_export != 'filter'"></div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv4_export == 'filter'">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Filter for IPv4 export</label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv4_export_filter" name="ipv4_export_filter"
                    class="dropdown-block">
                    <ng-option *ngFor="let filter of allfilters;" [value]=filter.url>{{filter.name}}</ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv4_export_filter?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">IPv6 import
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="What IPv6 routes should be kept when importing from the protocol to the routing table."></span>
                </label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv6_import" name="ipv6_import" class="dropdown-block"
                    [clearable]="false" [searchable]="false">
                    <ng-option value="all"> All</ng-option>
                    <ng-option value="none"> None</ng-option>
                    <ng-option value="filter"> Filter </ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv6_import?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv6_import != 'filter'"></div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv6_import == 'filter'">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Filter for IPv6 import</label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv6_import_filter" name="ipv6_import_filter"
                    class="dropdown-block">
                    <ng-option *ngFor="let filter of allfilters;" [value]=filter.url>{{filter.name}}</ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv6_import_filter?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">IPv6 export
                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="What IPv6 routes should be kept when exporting from the routing table to the protocol."></span>
                </label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv6_export" name="ipv6_export" class="dropdown-block"
                    [clearable]="false" [searchable]="false">
                    <ng-option value="all"> All</ng-option>
                    <ng-option value="none"> None</ng-option>
                    <ng-option value="filter"> Filter </ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv6_export?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv6_export != 'filter'"></div>
            <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol.value.ipv6_export == 'filter'">
              <div class="form-group inputtext-block mb-3">
                <label class="form-label">Filter for IPv6 export</label>
                <div class="d-flex flex-column">
                  <ng-select placeholder="All" formControlName="ipv6_export_filter" name="ipv6_export_filter"
                    class="dropdown-block">
                    <ng-option *ngFor="let filter of allfilters;" [value]=filter.url>{{filter.name}}</ng-option>
                  </ng-select>
                  <small class="text-danger"> {{errors?.ipv6_export_filter?.join(' ')}} </small>
                </div>
              </div>
            </div>
            <!-- OSPF form -->
            <div *ngIf="typeProtocol=='ospf'" class="col-12">
              <div class="row">

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Version
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="OSPF version (2 or 3)"></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ospfversion" name="ospfversion" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value="2"> 2</ng-option>
                        <ng-option value="3"> 3</ng-option>
                      </ng-select>
                      <small class="text-danger">{{errors?.ospf?.version}}</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Channel
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="IP version (OSPFv2 supports IPv4 only)."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ospfchannel" name="ospfchannel" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value="IPv4"> IPv4 </ng-option>
                        <ng-option value="IPv6"> IPv6 </ng-option>
                      </ng-select>
                      <small class="text-danger">{{errors?.ospf?.channel}}</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">RFC 1583 compatibility
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="This option controls compatibility of routing table calculation with RFC 1583. Default
                        value is no."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ospfrfc1583compat" name="ospfrfc1583compat" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="1">Yes</ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.ospf?.rfc1583compat}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">RFC 5838
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Basic OSPFv3 is limited to IPv6 unicast routing. The RFC 5838 extension defines support for
                        more address families (IPv4, IPv6, both unicast and multicast). The extension is enabled by default,
                        but can be disabled if necessary, as it restricts the range of available instance IDs. Default value is yes."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ospfrfc5838" name="ospfrfc5838" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="1">Yes</ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.ospf?.rfc5838}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Instance ID
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="When multiple OSPF protocol instances are active on the same links, they should use
                        different instance IDs to distinguish their packets. Although it could be done on per-interface
                        basis, it is often preferred to set one instance ID to whole OSPF domain/topology (e.g., when
                        multiple instances are used to represent separate logical topologies on the same physical
                        network). This option specifies the instance ID for all virtual_links of the OSPF instance, but
                        can be overridden by interface option. Default value is 0 unless OSPFv3-AF extended address
                        families are used, see RFC 5838 for that case."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="ospfinstance_id" type="number"
                        class="form-control form-control-lg form-control-solid" name="ospfinstance_id"
                        autocomplete="off" />
                      <small class="text-danger">{{errors?.ospf?.instance_id}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Stub router
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="This option configures the router to be a stub router, i.e., a router that participates in
                        the OSPF topology but does not allow transit traffic. In OSPFv2, this is implemented by
                        advertising maximum metric for outgoing links. In OSPFv3, the stub router behavior is announced
                        by clearing the R-bit in the router LSA. See RFC 6987 for details. Default value is no."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ospfstub_router" name="ospfstub_router" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="1">Yes</ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.ospf?.stub_router}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Tick
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="The routing table calculation and clean-up of areas' databases is not performed when a single link state change arrives. To lower the CPU utilization, it's processed later at periodical intervals of num seconds. The default value is 1."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="ospftick" type="number"
                        class="form-control form-control-lg form-control-solid" name="ospftick" autocomplete="off" />
                      <small class="text-danger"> {{errors?.ospf?.tick}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> ECMP
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="This option specifies whether OSPF is allowed to generate ECMP (equal-cost multipath)
                        routes.By default, ECMP is enabled if supported by Kernel."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ospfecmp" name="ospfecmp" class="dropdown-block" [clearable]="false"
                        [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger">{{errors?.ospf?.ecmp?.enable}}</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1" *ngIf="addProtocol?.controls?.ospfecmp?.value != ''">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> ECMP limit
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Specify a limit on maximum number of nexthops in one route. Default value of the limit is
                        16."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="ospfecpmlimit" type="number" name="ospfecpmlimit"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.ospf?.ecmp?.limit}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 mb-1">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Merge external
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="This option specifies whether OSPF should merge external routes from different
                        routers/LSAs for the same destination. When enabled together with ecmp, equal-cost external routes will be
                        combined to multipath routes in the same way as regular routes. When disabled, external routes
                        from different LSAs are treated as separate even if they represents the same destination.
                        Default value is no."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ospfmerge_external" name="ospfmerge_external" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.ospf?.merge_external}} </small>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="mb-0"> Areas </h4>
                  <p> Definition of OSPF areas. </p>
                </div>
                <div>
                  <button type="button" class="btn btn-primary btn-style" (click)="onAddospfAreas()">
                    <div>
                      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-block mb-2">
                      </span>
                    </div> Add Area
                  </button>
                </div>
              </div>
              <div class="col-12">
                <small class="text-danger"
                  *ngIf="ospfAreas?.controls && ospfAreas.controls.length <= 0 && errors?.ospf?.areas.length > 0">{{errors?.ospf?.areas}}</small>
              </div>
              <div formArrayName="ospfareas" class="col-12">
                <div [formGroupName]="i" *ngFor="let areaControl of ospfAreas?.controls;let i=index">
                  <div class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 pt-8 p-3 areas-block">
                    <div class="btn-remove-area">
                      <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemove(i)" title="Remove Area">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Area ID
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                            ngbTooltip="Area ID (a 32-bit number in dot-decimal form)."></span>
                        </label>
                        <div class="d-flex flex-column">
                          <input formControlName="id" type="text"
                            class="form-control form-control-lg form-control-solid" name="id" autocomplete="off" />
                          <small class="text-danger" *ngIf="areaControl?.get('id')?.errors">
                            {{areaControl?.get('id')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Area type </label>
                        <div class="d-flex flex-column">
                          <ng-select placeholder="Standard" formControlName="type" name="type" class="dropdown-block"
                            [clearable]="false" [searchable]="false">
                            <ng-option value="standard">Standard</ng-option>
                            <ng-option value="stub"> Stub </ng-option>
                            <ng-option value="nssa"> NSSA </ng-option>
                          </ng-select>
                          <small class="text-danger" *ngIf="areaControl?.get('type')?.errors">
                            {{areaControl?.get('type')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1" *ngIf="areaControl?.get('type')?.value == 'stub'
                      || areaControl?.get('type')?.value == 'nssa'">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Summary
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="This option controls propagation of summary LSAs into stub or NSSA areas. If enabled,
                            summary LSAs are propagated as usual, otherwise just the default summary route (0.0.0.0/0)
                            is propagated (this is sometimes called totally stubby area). If a stub area has more area
                            boundary routers, propagating summary LSAs could lead to more efficient routing at the cost
                            of larger link state database. Default value is no."></span>
                        </label>
                        <div class="d-flex flex-column">
                          <ng-select placeholder="Standard" formControlName="summary" name="summary"
                            class="dropdown-block" [clearable]="false" [searchable]="false">
                            <ng-option value=""> </ng-option>
                            <ng-option value="1"> Yes </ng-option>
                            <ng-option value="0"> No </ng-option>
                          </ng-select>
                          <small class="text-danger" *ngIf="areaControl?.get('summary')?.errors">
                            {{areaControl?.get('summary')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1" *ngIf="areaControl?.get('type')?.value == 'nssa'">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Default NSSA
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="When summary option is enabled, default summary route is no longer propagated to the
                            NSSA. In that case, this option allows to originate default route as NSSA-LSA to the NSSA.
                            Default value is no."></span>
                        </label>
                        <div class="d-flex flex-column">
                          <ng-select placeholder="Standard" formControlName="default_nssa" name="default_nssa"
                            class="dropdown-block" [clearable]="false" [searchable]="false">
                            <ng-option value=""> </ng-option>
                            <ng-option value="1"> Yes </ng-option>
                            <ng-option value="0"> No </ng-option>
                          </ng-select>
                          <small class="text-danger" *ngIf="areaControl?.get('default_nssa')?.errors">
                            {{areaControl?.get('default_nssa')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Default cost
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="This option controls the cost of a default route propagated to stub and NSSA areas.
                            Default value is 1000."></span>
                        </label>
                        <div class="d-flex flex-column">
                          <input formControlName="default_cost" type="text"
                            class="form-control form-control-lg form-control-solid" name="default_cost"
                            autocomplete="off" />
                          <small class="text-danger" *ngIf="areaControl?.get('default_cost')?.errors">
                            {{areaControl?.get('default_cost')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1" *ngIf="areaControl?.get('type')?.value == 'nssa'">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Default cost 2
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="When a default route is originated as NSSA-LSA, its cost can use either type 1 or type
                            2 metric. This option allows to specify the cost of a default route in type 2 metric. By
                            default, type 1 metric (option default cost) is used."></span>
                        </label>
                        <div class="d-flex flex-column">
                          <input formControlName="default_cost2" type="text" name="default_cost2"
                            class="form-control form-control-lg form-control-solid" autocomplete="off" />
                          <small class="text-danger" *ngIf="areaControl?.get('default_cost2')?.errors">
                            {{areaControl?.get('default_cost2')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Translator
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="This option controls translation of NSSA-LSAs into external LSAs. By default, one
                            translator per NSSA is automatically elected from area boundary routers. If enabled, this
                            area boundary router would unconditionally translate all NSSA-LSAs regardless of translator
                            election. Default value is no."></span>
                        </label>
                        <div class="d-flex flex-column">
                          <ng-select placeholder="Standard" formControlName="translator" name="translator"
                            class="dropdown-block" [clearable]="false" [searchable]="false">
                            <ng-option value=""> </ng-option>
                            <ng-option value="1"> Yes </ng-option>
                            <ng-option value="0"> No </ng-option>
                          </ng-select>
                          <small class="text-danger" *ngIf="areaControl?.get('translator')?.errors">
                            {{areaControl?.get('translator')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1">
                      <div class="form-group inputtext-block mb-3">
                        <label class="form-label"> Translator stability
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="This option controls the translator stability interval (in seconds). When the new
                            translator is elected, the old one keeps translating until the interval is over. Default
                            value is 40."></span>
                        </label>
                        <div class="d-flex flex-column">
                          <input formControlName="translator_stability" type="text"
                            class="form-control form-control-lg form-control-solid" name="translator_stability"
                            autocomplete="off" />
                          <small class="text-danger" *ngIf="areaControl?.get('translator_stability')?.errors">
                            {{areaControl?.get('translator_stability')?.errors}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-12 my-2">
                      <div class="col-md-12 d-flex justify-content-between align-items-center">
                        <div>
                          <h5 class="mb-0"> Interfaces </h5>
                        </div>
                        <div>
                          <button type="button" class="btn btn-primary btn-style" (click)="onAddospfinterfaces(i)">
                            <div>
                              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                class="svg-icon svg-icon-block mb-2">
                              </span>
                            </div> Add Interfaces
                          </button>
                        </div>
                      </div>
                      <div class="col-12">
                        <p> Defines that the specified interfaces belong to the area being defined. </p>
                      </div>
                      <div class="col-12">
                        <small class="text-danger" *ngIf="areaControl?.get('interfaces')?.errors">
                          {{areaControl?.get('interfaces')?.errors}}
                        </small>
                      </div>
                      <div formArrayName="interfaces" class="col-12">
                        <div [formGroupName]="interfacei"
                          class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around"
                          *ngFor="let interfaceControl of getospfinterface(i)?.controls;let interfacei=index">
                          <div class="row p-0">
                            <div class="col-md-12 d-flex justify-content-between align-items-center">
                              <div>
                                <h5 class="mb-0"> Interface List </h5>
                              </div>
                              <div>
                                <button type="button" class="btn btn-danger btn-icon-1"
                                  (click)="onRemoveinterfaces(i,interfacei)" title="Remove Interfaces">
                                  <div>
                                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                      class="svg-icon svg-icon-block">
                                    </span>
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div class="col-12">
                              <p> Specify the interfaces that use this protocol. You may only select the interfaces
                                already added to this node. If you have not added any interfaces yet it won't be
                                possible to use interfaces with your custom protocol. If a space is set on your custom
                                protocol then the interfaces must also have a space set; otherwise they are
                                incompatible.
                              </p>
                              <small class="text-danger" *ngIf="interfaceControl?.get('interfaces')?.errors">
                                {{interfaceControl?.get('interfaces')?.errors}}
                              </small>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                              <div>
                                <button type="button" class="btn btn-primary btn-style"
                                  (click)="onAddinterfacelinks(i,interfacei)">
                                  <div>
                                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                      class="svg-icon svg-icon-block mb-2">
                                    </span>
                                  </div> Add Interfaces Link
                                </button>
                              </div>
                            </div>
                            <div formArrayName="interfaces" class="row mb-3">
                              <div [formGroupName]="interfacelinki" class="col-lg-4 col-md-4 col-12 mb-1 
                              d-flex align-items-center gap-3"
                                *ngFor="let interfaceLinkController of getospfinterfacelinks(i,interfacei)?.controls;let interfacelinki=index">
                                <div class="form-group inputtext-block w-100">
                                  <label class="form-label">Link {{interfacelinki+1}} </label>
                                  <div class="d-flex w-100">
                                    <div class="d-flex flex-column mb-5 w-100">
                                      <ng-select formControlName="link" name="link" class="dropdown-block">
                                        <ng-option *ngFor="let interface of InterfaceDetailArray;"
                                          [value]=interface.ifname>{{interface.ifname}}</ng-option>
                                      </ng-select>
                                      <small class="text-danger" *ngIf="interfaceLinkController?.get('link')?.errors">
                                        {{interfaceLinkController?.get('link')?.errors}}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-3">
                                  <button type="button" class="btn btn-danger btn-icon-1"
                                    (click)="onRemoveinterfacelinks(i,interfacei,interfacelinki)">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                        class="svg-icon svg-icon-block">
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Instance
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="You can specify alternative instance ID for the interface definition,
                                  therefore it is possible to have several instances of that interface with different
                                  options or even in different areas. For OSPFv2, instance ID support is an extension
                                  (RFC 6549) and is supposed to be set per-protocol. For OSPFv3, it is an integral
                                  feature."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="instance" type="text" name="instance"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('instance')?.errors">
                                    {{interfaceControl?.get('instance')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Cost
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies output cost (metric) of an interface. Default value is 10."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="cost" type="text" name="cost"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('cost')?.errors">
                                    {{interfaceControl?.get('cost')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Hello
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies interval in seconds between sending of Hello messages. Beware, all
                                    routers on the same network need to have the same hello interval. Default value is
                                    10."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="hello" type="text" name="hello"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('hello')?.errors">
                                    {{interfaceControl?.get('hello')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Poll
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies interval in seconds between sending of Hello messages for some
                                  neighbors on NBMA network. Default value is 20."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="poll" type="text" name="poll"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('poll')?.errors">
                                    {{interfaceControl?.get('poll')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Dead
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="When the router does not receive any messages from a neighbor in dead seconds,
                                  it will consider the neighbor down. If both directives dead count and dead are used,
                                  dead has precedence."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="dead" type="text" name="dead"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('dead')?.errors">
                                    {{interfaceControl?.get('dead')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Dead count
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="When the router does not receive any messages from a neighbor in dead
                                  count*hello seconds, it will consider the neighbor down."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="dead_count" type="text" name="dead_count"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('dead_count')?.errors">
                                    {{interfaceControl?.get('dead_count')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Retransmit
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies interval in seconds between retransmissions of unacknowledged
                                    updates. Default value is 5."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="retransmit" type="text" name="retransmit"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('retransmit')?.errors">
                                    {{interfaceControl?.get('retransmit')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Transmit delay
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies estimated transmission delay of link state updates send over the
                                      interface. The value is added to LSA age of LSAs propagated through it. Default
                                      value is 1."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="transmit_delay" type="text" name="transmit_delay"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('transmit_delay')?.errors">
                                    {{interfaceControl?.get('transmit_delay')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Wait
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="After start, router waits for the specified number of seconds between starting
                                      election and building adjacency. Default value is 4*hello."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="wait" type="text" name="wait"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('wait')?.errors">
                                    {{interfaceControl?.get('wait')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Type
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Type of connected network. BIRD detects this automatically, but sometimes it's
                                  convenient to force use of a different type manually."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="type" name="type" class="dropdown-block"
                                    [clearable]="false" [searchable]="false">
                                    <ng-option value=""> </ng-option>
                                    <ng-option value="broadcast"> Brodcast </ng-option>
                                    <ng-option value="pointopoint"> Point to Point </ng-option>
                                    <ng-option value="pointomultipoint"> Point to multipoint </ng-option>
                                    <ng-option value="nonbroadcast"> Non-broadcast </ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="interfaceControl?.get('cost')?.errors">
                                    {{interfaceControl?.get('cost')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Real broadcast
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="In type broadcast or type ptp network configuration, OSPF packets are sent as
                                  IP multicast packets. This option changes the behavior to using old-fashioned IP
                                  broadcast packets. This may be useful as a workaround if IP multicast for some
                                  reason does not work or does not work reliably. This is a non-standard option and
                                  probably is not interoperable with other OSPF implementations. Default value is no."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="real_broadcast" name="real_broadcast"
                                    class="dropdown-block" [clearable]="false" [searchable]="false">
                                    <ng-option value=""></ng-option>
                                    <ng-option value="1"> Yes </ng-option>
                                    <ng-option value="0"> No</ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="interfaceControl?.get('real_broadcast')?.errors">
                                    {{interfaceControl?.get('real_broadcast')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">PtP netmask
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="In type ptp network configurations, OSPFv2 implementations should ignore
                                  received netmask field in hello packets and should send hello packets with zero
                                  netmask field on unnumbered PtP links. But some OSPFv2 implementations perform
                                  netmask checking even for PtP links. This option specifies whether real netmask will
                                  be used in hello packets on type ptp interfaces. You should ignore this option
                                  unless you meet some compatibility problems related to this issue. Default value is
                                  no for unnumbered PtP links, yes otherwise."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="ptp_netmask" name="ptp_netmask" class="dropdown-block"
                                    [clearable]="false" [searchable]="false">
                                    <ng-option value=""> </ng-option>
                                    <ng-option value="1"> Yes </ng-option>
                                    <ng-option value="0"> No</ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="interfaceControl?.get('ptp_netmask')?.errors">
                                    {{interfaceControl?.get('ptp_netmask')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Priority
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="On every multiple access network (e.g., the Ethernet) Designated Router and
                                  Backup Designated router are elected. These routers have some special functions in
                                  the flooding process. Higher priority increases preferences in this election.
                                  Routers with priority 0 are not eligible. Default value is 1."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="priority" type="text" name="priority"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('priority')?.errors">
                                    {{interfaceControl?.get('priority')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Strict non-broadcast
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="If set, don't send hello to any undefined neighbor. This switch is ignored on
                                  other than NBMA or PtMP interfaces. Default value is no."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="strict_nonbroadcast" name="strict_nonbroadcast"
                                    class="dropdown-block" [clearable]="false" [searchable]="false">
                                    <ng-option value=""> </ng-option>
                                    <ng-option value="1"> Yes </ng-option>
                                    <ng-option value="0"> No</ng-option>
                                  </ng-select>
                                  <small class="text-danger"
                                    *ngIf="interfaceControl?.get('strict_nonbroadcast')?.errors">
                                    {{interfaceControl?.get('strict_nonbroadcast')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Stub
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="If set to interface it does not listen to any packet and does not send any
                                  hello. Default value is no."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="stub" name="stub" class="dropdown-block"
                                    [clearable]="false" [searchable]="false">
                                    <ng-option value=""> </ng-option>
                                    <ng-option value="1"> Yes </ng-option>
                                    <ng-option value="0"> No</ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="interfaceControl?.get('stub')?.errors">
                                    {{interfaceControl?.get('stub')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Check link
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="If set, a hardware link state (reported by OS) is taken into consideration.
                                  When a link disappears (e.g. an ethernet cable is unplugged), neighbors are
                                  immediately considered unreachable and only the address of the iface (instead of
                                  whole network prefix) is propagated. It is possible that some hardware drivers or
                                  platforms do not implement this feature. Default value is yes."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="check_link" name="check_link" class="dropdown-block"
                                    [clearable]="false" [searchable]="false">
                                    <ng-option value=""> </ng-option>
                                    <ng-option value="1"> Yes </ng-option>
                                    <ng-option value="0"> No</ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="interfaceControl?.get('check_link')?.errors">
                                    {{interfaceControl?.get('check_link')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">ECMP weight
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="When ECMP (multipath) routes are allowed, this value specifies a relative
                                  weight used for nexthops going through the iface. Allowed values are 1-256. Default
                                  value is 1."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="ecmp_weight" type="text" name="ecmp_weight"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('ecmp_weight')?.errors">
                                    {{interfaceControl?.get('ecmp_weight')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Link LSA suppression
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="In OSPFv3, link LSAs are generated for each link, announcing link-local IPv6
                                  address of the router to its local neighbors. These are useless on PtP or PtMP
                                  networks and this option allows to suppress the link LSA origination for such
                                  interfaces. The option is ignored on other than PtP or PtMP interfaces. Default
                                  value is no."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="link_lsa_suppression" name="link_lsa_suppression"
                                    class="dropdown-block" [clearable]="false" [searchable]="false">
                                    <ng-option value=""> </ng-option>
                                    <ng-option value="1"> Yes </ng-option>
                                    <ng-option value="0"> No</ng-option>
                                  </ng-select>
                                  <small class="text-danger"
                                    *ngIf="interfaceControl?.get('link_lsa_suppression')?.errors">
                                    {{interfaceControl?.get('link_lsa_suppression')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-12 col-lg-12 mb-5">
                              <div class="col-md-12 d-flex justify-content-between align-items-center">
                                <div>
                                  <h6 class="mb-0"> Neighbors </h6>
                                </div>
                                <div>
                                  <button type="button" class="btn btn-primary btn-style"
                                    (click)="onAddneighbors(i,interfacei)">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                        class="svg-icon svg-icon-block mb-2">
                                      </span>
                                    </div> Add Neighbors
                                  </button>
                                </div>
                              </div>
                              <div class="col-12">
                                <p> A set of neighbors to which Hello messages on NBMA or PtMP networks are to be
                                  sent. </p>
                                <small class="text-danger" *ngIf="interfaceControl?.get('neighbors')?.errors">
                                  {{interfaceControl?.get('neighbors')?.errors}}
                                </small>
                              </div>
                              <div formArrayName="neighbors" class="col-12">
                                <div [formGroupName]="neighborsi"
                                  class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around neighbors-block"
                                  *ngFor="let neighborsController of getospfneighbors(i,interfacei)?.controls;let neighborsi=index">
                                  <div class="btn-remove-neighbor">
                                    <button type="button" class="btn btn-danger btn-icon-1"
                                      (click)="onRemoveneighbors(i,interfacei,neighborsi)" title="Remove Neighbor">
                                      <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                          class="svg-icon svg-icon-block">
                                        </span>
                                      </div>
                                    </button>
                                  </div>
                                  <div class="row pt-3">
                                    <div class="col-md-6 col-lg-6">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">Address</label>
                                        <div class="d-flex flex-column">
                                          <input formControlName="address" type="text" name="Address"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="text-danger"
                                            *ngIf="neighborsController?.get('address')?.errors">
                                            {{neighborsController?.get('address')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">Eligible
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top"
                                            ngbTooltip="Eligible for NBMA networks. Default value is false."></span>
                                        </label>
                                        <div class="d-flex flex-column mt-2">
                                          <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                            formControlName="eligible" name="eligible"></ui-switch>
                                          <small class="text-danger"
                                            *ngIf="neighborsController?.get('eligible')?.errors">
                                            {{neighborsController?.get('eligible')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Authentication
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="OSPF authentication. Default is none."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="authentication" name="authentication"
                                    class="dropdown-block" [clearable]="false" [searchable]="false">
                                    <ng-option value="null"> </ng-option>
                                    <ng-option value="none"> none </ng-option>
                                    <ng-option value="simple"> Simple</ng-option>
                                    <ng-option value="cryptographic"> Cryptographic</ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="interfaceControl?.get('authentication')?.errors">
                                    {{interfaceControl?.get('authentication')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-12 col-lg-12 mb-5" *ngIf="interfaceControl?.get('authentication')?.value == 'simple'
                              || interfaceControl?.get('authentication')?.value == 'cryptographic'">
                              <div class="col-md-12 d-flex justify-content-between align-items-center">
                                <div>
                                  <h6 class="mb-0"> Passwords </h6>
                                </div>
                                <div>
                                  <button type="button" class="btn btn-primary btn-style"
                                    (click)="onAddInterfacePassword(i,interfacei)">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                        class="svg-icon svg-icon-block mb-2">
                                      </span>
                                    </div> Add Password
                                  </button>
                                </div>
                              </div>
                              <div class="col-12">
                                <p> Specifies passwords used if authentication is enabled.</p>
                                <small class="text-danger" *ngIf="interfaceControl?.get('passwords')?.errors">
                                  {{interfaceControl?.get('passwords')?.errors}}
                                </small>
                              </div>
                              <div formArrayName="passwords" class="col-12">
                                <div [formGroupName]="passi"
                                  class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around auth-block"
                                  *ngFor="let passiController of getospfInterfacePassword(i,interfacei)?.controls;let passi=index">
                                  <div class="btn-remove-auth">
                                    <button type="button" class="btn btn-danger btn-icon-1"
                                      (click)="onRemoveInterfacePassword(i,interfacei,passi)" title="Remove Password">
                                      <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                          class="svg-icon svg-icon-block">
                                        </span>
                                      </div>
                                    </button>
                                  </div>
                                  <div class="row pt-3">
                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">Password
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="Specifies a password that can be used by the protocol as a shared
                                          secret key."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <input formControlName="password" type="text" name="password"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="text-danger" *ngIf="passiController?.get('password')?.errors">
                                            {{passiController?.get('password')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">ID
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="ID of the password, (1-255). If it is not used, BIRD will choose ID
                                          based on an order of the password item in the interface. For example, second
                                          password item in one interface will have default ID 2. ID is used by some
                                          routing protocols to identify which password was used to authenticate
                                          protocol packets."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <input formControlName="id" type="text" name="id"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="text-danger" *ngIf="passiController?.get('id')?.errors">
                                            {{passiController?.get('id')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Generate from
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top"
                                            ngbTooltip="The start time of the usage of the password for packet signing."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="generate_from"
                                              class="form-control form-control-lg form-control-solid"
                                              name="generate_from">
                                          </mat-form-field>
                                          <small class="text-danger"
                                            *ngIf="passiController?.get('generate_from')?.errors">
                                            {{passiController?.get('generate_from')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Generate to
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top"
                                            ngbTooltip="The last time of the usage of the password for packet signing."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="generate_to"
                                              class="form-control form-control-lg form-control-solid"
                                              name="generate_to">
                                          </mat-form-field>
                                          <small class="text-danger"
                                            *ngIf="passiController?.get('generate_to')?.errors">
                                            {{passiController?.get('generate_to')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Accept from
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="The start time of the usage of the password for packet
                                          verification."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="accept_from"
                                              class="form-control form-control-lg form-control-solid"
                                              name="accept_from">
                                          </mat-form-field>
                                          <small class="text-danger"
                                            *ngIf="passiController?.get('accept_from')?.errors">
                                            {{passiController?.get('accept_from')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Accept to
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="The last time of the usage of the password for packet
                                          verification."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="accept_to"
                                              class="form-control form-control-lg form-control-solid" name="accept_to">
                                          </mat-form-field>
                                          <small class="text-danger" *ngIf="passiController?.get('accept_to')?.errors">
                                            {{passiController?.get('accept_to')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">Algorithm
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="The message authentication algorithm for the password when
                                          cryptographic authentication is enabled. The default value for OSPFv2 is
                                          Keyed-MD5 (for compatibility), for OSPFv3 it is HMAC-SHA-256."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <ng-select formControlName="algorithm" name="algorithm" class="dropdown-block"
                                            [clearable]="false" [searchable]="false">
                                            <ng-option value=""> </ng-option>
                                            <ng-option value="keyed md5"> Keyed-MD5 </ng-option>
                                            <ng-option value="keyed sha1"> Keyed-SHA-1 </ng-option>
                                            <ng-option value="hmac sha1"> HMAC-SHA-1 </ng-option>
                                            <ng-option value="hmac sha256"> HMAC-SHA-256 </ng-option>
                                            <ng-option value="hmac sha384"> HMAC-SHA-384 </ng-option>
                                            <ng-option value="hmac sha512"> HMAC-SHA-512 </ng-option>
                                          </ng-select>
                                          <small class="text-danger" *ngIf="passiController?.get('algorithm')?.errors">
                                            {{passiController?.get('algorithm')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">TX class
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="This option specifies the value of ToS/DS/Class field in IP headers of the
                                  outgoing protocol packets. This may affect how the protocol packets are processed by
                                  the network relative to the other network traffic. With class keyword, the value
                                  (0-255) is used for the whole ToS/Class octet (but two bits reserved for ECN are
                                  ignored)."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="tx_class" type="text" name="tx_class"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('tx_class')?.errors">
                                    {{interfaceControl?.get('tx_class')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">TX DSCP
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="This option specifies the value of ToS/DS/Class field in IP headers of the
                                  outgoing protocol packets. This may affect how the protocol packets are processed by
                                  the network relative to the other network traffic. With dscp keyword, the value
                                  (0-63) is used just for the DS field in the octet. Default value is 0xc0 (DSCP 0x30
                                  - CS6)."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="tx_dscp" type="text" name="tx_dscp"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('tx_dscp')?.errors">
                                    {{interfaceControl?.get('tx_dscp')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">TX priority
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="This option specifies the local packet priority. This may affect how the
                                  protocol packets are processed in the local TX queues. This option is Linux
                                  specific. Default value is 7 (highest priority, privileged traffic)."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="tx_priority" type="text" name="tx_priority"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('tx_priority')?.errors">
                                    {{interfaceControl?.get('tx_priority')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">RX buffer
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="This option allows to specify the size of buffers used for packet processing.
                                  The buffer size should be bigger than maximal size of any packets. By default,
                                  buffers are dynamically resized as needed, but a fixed value could be specified.
                                  Value large means maximal allowed packet size - 65535."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="rx_buffer" type="text" name="rx_buffer"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('rx_buffer')?.errors">
                                    {{interfaceControl?.get('rx_buffer')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">TX length
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Transmitted OSPF messages that contain large amount of information are
                                  segmented to separate OSPF packets to avoid IP fragmentation. This option specifies
                                  the soft ceiling for the length of generated OSPF packets. Default value is the MTU
                                  of the network interface. Note that larger OSPF packets may still be generated if
                                  underlying OSPF messages cannot be splitted (e.g. when one large LSA is propagated)."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="tx_length" type="text" name="tx_length"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="interfaceControl?.get('tx_length')?.errors">
                                    {{interfaceControl?.get('tx_length')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">TTL security
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="TTL security is a feature that protects routing protocols from remote spoofed
                                  packets by using TTL 255 instead of TTL 1 for protocol packets destined to
                                  neighbors. Because TTL is decremented when packets are forwarded, it is non-trivial
                                  to spoof packets with TTL 255 from remote locations. Note that this option would
                                  interfere with OSPF virtual links. If this option is enabled, the router will send
                                  OSPF packets with TTL 255 and drop received packets with TTL less than 255. If this
                                  option si set to tx only, TTL 255 is used for sent packets, but is not checked for
                                  received packets. Default value is no."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="ttl_security" name="ttl_security" class="dropdown-block"
                                    [clearable]="false" [searchable]="false">
                                    <ng-option value="yes"> Yes </ng-option>
                                    <ng-option value="no"> No </ng-option>
                                    <ng-option value="tx"> Tx only </ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="interfaceControl?.get('ttl_security')?.errors">
                                    {{interfaceControl?.get('ttl_security')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-12 my-2">
                      <div class="col-md-12 d-flex justify-content-between align-items-center">
                        <div>
                          <h5 class="mb-0"> Networks </h5>
                        </div>
                        <div>
                          <button type="button" class="btn btn-primary btn-style" (click)="onAddospfnetwork(i)">
                            <div>
                              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                class="svg-icon svg-icon-block mb-2">
                              </span>
                            </div> Add Networks
                          </button>
                        </div>
                      </div>
                      <div class="col-12">
                        <p>Definition of area IP ranges. This is used in summary LSA origination. </p>
                      </div>
                      <div class="col-12">
                        <small class="text-danger" *ngIf="areaControl?.get('networks')?.errors">
                          {{areaControl?.get('networks')?.errors}}
                        </small>
                      </div>
                      <div formArrayName="networks" class="col-12">
                        <div [formGroupName]="networki"
                          class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around networks-block"
                          *ngFor="let networkControl of getospfnetwork(i)?.controls;let networki=index">
                          <div class="btn-remove-network">
                            <button type="button" class="btn btn-danger btn-icon-1"
                              (click)="onRemoveospfnetwork(i,networki)" title="Remove Network">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg-icon svg-icon-block">
                                </span>
                              </div>
                            </button>
                          </div>
                          <div class="row pt-3">
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Network
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Network prefix."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="network" type="text" name="instanetworknce"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="networkControl?.get('network')?.errors">
                                    {{networkControl?.get('network')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Hidden
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Hidden networks are not propagated into other areas. Default value is
                                  false."></span>
                                </label>
                                <div class="d-flex flex-column mt-2">
                                  <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                    formControlName="hidden" name="hidden"></ui-switch>
                                  <small class="text-danger" *ngIf="networkControl?.get('hidden')?.errors">
                                    {{networkControl?.get('hidden')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-12 my-2">
                      <div class="col-md-12 d-flex justify-content-between align-items-center">
                        <div>
                          <h5 class="mb-0"> External Networks </h5>
                        </div>
                        <div>
                          <button type="button" class="btn btn-primary btn-style" (click)="onAddexternalnetworks(i)">
                            <div>
                              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                class="svg-icon svg-icon-block mb-2">
                              </span>
                            </div> Add External Networks
                          </button>
                        </div>
                      </div>
                      <div class="col-12">
                        <p> Definition of external area IP ranges for NSSAs. This is used for NSSA-LSA translation.
                        </p>
                      </div>
                      <div class="col-12">
                        <small class="text-danger" *ngIf="areaControl?.get('external_networks')?.errors">
                          {{areaControl?.get('external_networks')?.errors}}
                        </small>
                      </div>
                      <div formArrayName="external_networks" class="col-12">
                        <div [formGroupName]="externalnetworksi"
                          class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around exnetworks-block"
                          *ngFor="let externalNetworkControl of getospfexternalnetworks(i)?.controls;let externalnetworksi=index">
                          <div class="btn-remove-exnetwork">
                            <button type="button" class="btn btn-danger btn-icon-1"
                              (click)="onRemoveexternalnetworks(i,externalnetworksi)" title="Remove External Network">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg-icon svg-icon-block">
                                </span>
                              </div>
                            </button>
                          </div>
                          <div class="row pt-3">
                            <div class="col-md-5 col-lg-5 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Network
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Network prefix."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="network" type="text"
                                    class="form-control form-control-lg form-control-solid" name="instance"
                                    autocomplete="off" />
                                  <small class="text-danger" *ngIf="externalNetworkControl?.get('network')?.errors">
                                    {{externalNetworkControl?.get('network')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5 col-lg-5 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Tag
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Route tag."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="tag" type="text"
                                    class="form-control form-control-lg form-control-solid" name="tag"
                                    autocomplete="off" />
                                  <small class="text-danger" *ngIf="externalNetworkControl?.get('tag')?.errors">
                                    {{externalNetworkControl?.get('tag')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 col-lg-2 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Hidden
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Hidden networks are not propagated into other areas. Default value is
                                  false."></span>
                                </label>
                                <div class="d-flex flex-column mt-2">
                                  <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                    formControlName="hidden" name="hidden"></ui-switch>
                                  <small class="text-danger" *ngIf="externalNetworkControl?.get('hidden')?.errors">
                                    {{externalNetworkControl?.get('hidden')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-12 my-2">
                      <div class="col-md-12 d-flex justify-content-between align-items-center">
                        <div>
                          <h5 class="mb-0"> Stubnets </h5>
                        </div>
                        <div>
                          <button type="button" class="btn btn-primary btn-style" (click)="onAddstubnets(i)">
                            <div>
                              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                class="svg-icon svg-icon-block mb-2">
                              </span>
                            </div> Add Stubnets
                          </button>
                        </div>
                      </div>
                      <div class="col-12">
                        <p> Networks that are not transit networks between OSPF routers. They are also propagated
                          through an OSPF area as a part of a link state database. By default, BIRD generates a stub
                          network record for each primary network address on each OSPF interface that does not have any
                          OSPF neighbors, and also for each non-primary network address on each OSPF interface. This
                          option allows to alter a set of stub networks propagated by this router. </p>
                      </div>
                      <div class="col-12">
                        <small class="text-danger" *ngIf="areaControl?.get('stubnets')?.errors">
                          {{areaControl?.get('stubnets')?.errors}}
                        </small>
                      </div>
                      <div formArrayName="stubnets" class="col-12">
                        <div [formGroupName]=" stubnetsi"
                          class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around stubnets-block"
                          *ngFor="let stubnetsControl of getospfstubnets(i)?.controls;let stubnetsi=index">
                          <div class="btn-remove-stubnet">
                            <button type="button" class="btn btn-danger btn-icon-1"
                              (click)="onRemovestubnets(i,stubnetsi)" title="Remove Stubnet">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg-icon svg-icon-block">
                                </span>
                              </div>
                            </button>
                          </div>
                          <div class="row pt-3">
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Network
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Network prefix."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="network" type="text" name="network"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="stubnetsControl?.get('network')?.errors">
                                    {{stubnetsControl?.get('network')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Cost
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Route cost."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="cost" type="text" name="cost"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <!-- <small class="text-danger" *ngIf="stubnetsControl?.get('cost')?.errors">
                                  {{stubnetsControl?.get('cost')?.errors}}
                                </small> -->
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Hidden
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Stub network with given network prefix will not be added to the set of
                                  propagated stub networks."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="hidden" name="hidden" class="dropdown-block"
                                    [clearable]="false" [searchable]="false">
                                    <ng-option value=""></ng-option>
                                    <ng-option value="1"> Yes</ng-option>
                                    <ng-option value="0"> No </ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="stubnetsControl?.get('hidden')?.errors">
                                    {{stubnetsControl?.get('hidden')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Summary
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Route tag.Default stub networks that are subnetworks of given stub network are
                                  suppressed. This might be used, for example, to aggregate generated stub networks."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="summary" name="summary" class="dropdown-block"
                                    [clearable]="false" [searchable]="false">
                                    <ng-option value=""></ng-option>
                                    <ng-option value="1"> Yes</ng-option>
                                    <ng-option value="0"> No </ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="stubnetsControl?.get('summary')?.errors">
                                    {{stubnetsControl?.get('summary')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-12 my-2">
                      <div class="col-md-12 d-flex justify-content-between align-items-center">
                        <div>
                          <h5 class="mb-0"> Virtual Links </h5>
                        </div>
                        <div>
                          <button type="button" class="btn btn-primary btn-style" (click)="onAddvirtuallinks(i)">
                            <div>
                              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                class="svg-icon svg-icon-block mb-2">
                              </span>
                            </div> Add Virtual Links
                          </button>
                        </div>
                      </div>
                      <div class="col-12">
                        <p>Virtual link acts as a point-to-point interface belonging to backbone. The actual area is
                          used as a transport area. This item cannot be in the backbone </p>
                      </div>
                      <div class="col-12">
                        <small class="text-danger" *ngIf="areaControl?.get('virtual_links')?.errors">
                          {{areaControl?.get('virtual_links')?.errors}}
                        </small>
                      </div>
                      <div formArrayName="virtual_links" class="col-12">
                        <div [formGroupName]="virtuallinksi"
                          class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around links-block"
                          *ngFor="let linkController of getospfvirtuallinks(i)?.controls;let virtuallinksi=index">
                          <div class="btn-remove-link">
                            <button type="button" class="btn btn-danger btn-icon-1"
                              (click)="onRemovevirtuallinks(i,virtuallinksi)" title="Remove Virtual Links">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg-icon svg-icon-block">
                                </span>
                              </div>
                            </button>
                          </div>
                          <div class="row pt-3">
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">ID
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Virtual link ID."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="id" type="text" name="id"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('id')?.errors">
                                    {{linkController?.get('id')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Instance
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="You can specify alternative instance ID for the virtual link definition,
                                  therefore it is possible to have several instances of that virtual link with
                                  different options or even in different areas."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="instance" type="text" name="instance"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('instance')?.errors">
                                    {{linkController?.get('instance')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Hello
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies interval in seconds between sending of Hello messages. Beware, all
                                  routers on the same network need to have the same hello interval. Default value is
                                  10."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="hello" type="text" name="hello"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('hello')?.errors">
                                    {{linkController?.get('hello')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Dead
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="When the router does not receive any messages from a neighbor in dead seconds,
                                  it will consider the neighbor down. If both directives dead count and dead are used,
                                  dead has precedence."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="dead" type="text" name="dead"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('dead')?.errors">
                                    {{linkController?.get('dead')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Dead count
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="When the router does not receive any messages from a neighbor in dead
                                  count*hello seconds, it will consider the neighbor down."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="dead_count" type="text" name="dead_count"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('dead_count')?.errors">
                                    {{linkController?.get('dead_count')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Retransmit
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies interval in seconds between retransmissions of unacknowledged
                                  updates. Default value is 5."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="retransmit" type="text" name="retransmit"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('retransmit')?.errors">
                                    {{linkController?.get('retransmit')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Transmit delay
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Specifies estimated transmission delay of link state updates send over the
                                  interface. The value is added to LSA age of LSAs propagated through it. Default
                                  value is 1."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="transmit_delay" type="text" name="transmit_delay"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('transmit_delay')?.errors">
                                    {{linkController?.get('transmit_delay')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Wait
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="After start, router waits for the specified number of seconds between starting
                                  election and building adjacency. Default value is 4*hello."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <input formControlName="wait" type="text" name="wait"
                                    class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                  <small class="text-danger" *ngIf="linkController?.get('wait')?.errors">
                                    {{linkController?.get('wait')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-1">
                              <div class="form-group inputtext-block mb-3">
                                <label class="form-label">Authentication
                                  <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="WOSPF authentication. Default is none."></span>
                                </label>
                                <div class="d-flex flex-column">
                                  <ng-select formControlName="authentication" name="authentication"
                                    class="dropdown-block" [clearable]="false" [searchable]="false">
                                    <ng-option value="none"> None</ng-option>
                                    <ng-option value="simple"> Simple </ng-option>
                                    <ng-option value="cryptographic"> Cryptographic </ng-option>
                                  </ng-select>
                                  <small class="text-danger" *ngIf="linkController?.get('authentication')?.errors">
                                    {{linkController?.get('authentication')?.errors}}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-lg-12 mb-5" *ngIf="linkController?.get('authentication')?.value == 'simple'
                              || linkController?.get('authentication')?.value == 'cryptographic'">
                              <div class="col-md-12 d-flex justify-content-between align-items-center">
                                <div>
                                  <h6 class="mb-0"> Passwords </h6>
                                </div>
                                <div>
                                  <button type="button" class="btn btn-primary btn-style"
                                    (click)="onAddLinkPassword(i,virtuallinksi)">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                        class="svg-icon svg-icon-block mb-2">
                                      </span>
                                    </div> Add Password
                                  </button>
                                </div>
                              </div>
                              <div class="col-12">
                                <p> Specifies passwords used if authentication is enabled.</p>
                                <small class="text-danger" *ngIf="linkController?.get('passwords')?.errors">
                                  {{linkController?.get('passwords')?.errors}}
                                </small>
                              </div>
                              <div formArrayName="passwords" class="col-12">
                                <div [formGroupName]="passl"
                                  class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around auth-block"
                                  *ngFor="let passlController of getospfVirtualLinkPassword(i,virtuallinksi)?.controls;let passl=index">
                                  <div class="btn-remove-auth">
                                    <button type="button" class="btn btn-danger btn-icon-1"
                                      (click)="onRemoveLinkPassword(i,virtuallinksi,passl)" title="Remove Password">
                                      <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                          class="svg-icon svg-icon-block">
                                        </span>
                                      </div>
                                    </button>
                                  </div>
                                  <div class="row pt-3">
                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">Password
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="Specifies a password that can be used by the protocol as a shared
                                          secret key."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <input formControlName="password" type="text" name="password"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="text-danger" *ngIf="passlController?.get('password')?.errors">
                                            {{passlController?.get('password')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">ID
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="ID of the password, (1-255). If it is not used, BIRD will choose ID
                                          based on an order of the password item in the interface. For example, second
                                          password item in one interface will have default ID 2. ID is used by some
                                          routing protocols to identify which password was used to authenticate
                                          protocol packets."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <input formControlName="id" type="text" name="id"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="text-danger" *ngIf="passlController?.get('id')?.errors">
                                            {{passlController?.get('id')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Generate from
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top"
                                            ngbTooltip="The start time of the usage of the password for packet signing."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="generate_from"
                                              class="form-control form-control-lg form-control-solid"
                                              name="generate_from">
                                          </mat-form-field>
                                          <small class="text-danger"
                                            *ngIf="passlController?.get('generate_from')?.errors">
                                            {{passlController?.get('generate_from')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Generate to
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top"
                                            ngbTooltip="The last time of the usage of the password for packet signing."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="generate_to"
                                              class="form-control form-control-lg form-control-solid"
                                              name="generate_to">
                                          </mat-form-field>
                                          <small class="text-danger"
                                            *ngIf="passlController?.get('generate_to')?.errors">
                                            {{passlController?.get('generate_to')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Accept from
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="The start time of the usage of the password for packet
                                          verification."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="accept_from"
                                              class="form-control form-control-lg form-control-solid"
                                              name="accept_from">
                                          </mat-form-field>
                                          <small class="text-danger"
                                            *ngIf="passlController?.get('accept_from')?.errors">
                                            {{passlController?.get('accept_from')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label"> Accept to
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="The last time of the usage of the password for packet
                                          verification."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <mat-form-field>
                                            <input matInput type="datetime-local" formControlName="accept_to"
                                              class="form-control form-control-lg form-control-solid" name="accept_to">
                                          </mat-form-field>
                                          <small class="text-danger" *ngIf="passlController?.get('accept_to')?.errors">
                                            {{passlController?.get('accept_to')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-1">
                                      <div class="form-group inputtext-block mb-3">
                                        <label class="form-label">Algorithm
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top" ngbTooltip="The message authentication algorithm for the password when
                                          cryptographic authentication is enabled. The default value for OSPFv2 is
                                          Keyed-MD5 (for compatibility), for OSPFv3 it is HMAC-SHA-256."></span>
                                        </label>
                                        <div class="d-flex flex-column">
                                          <ng-select formControlName="algorithm" name="algorithm" class="dropdown-block"
                                            [clearable]="false" [searchable]="false">
                                            <ng-option value=""> </ng-option>
                                            <ng-option value="keyed md5"> Keyed-MD5 </ng-option>
                                            <ng-option value="keyed sha1"> Keyed-SHA-1 </ng-option>
                                            <ng-option value="hmac sha1"> HMAC-SHA-1 </ng-option>
                                            <ng-option value="hmac sha256"> HMAC-SHA-256 </ng-option>
                                            <ng-option value="hmac sha384"> HMAC-SHA-384 </ng-option>
                                            <ng-option value="hmac sha512"> HMAC-SHA-512 </ng-option>
                                          </ng-select>
                                          <small class="text-danger" *ngIf="passlController?.get('algorithm')?.errors">
                                            {{passlController?.get('algorithm')?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--ospf section End -->
            <!--bgp section start -->
            <div *ngIf="typeProtocol=='bgp'" class="col-12">
              <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-0"> Local </h5>
                </div>
              </div>
              <div class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3">
                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">IP</label>
                    <div class="d-flex flex-column">
                      <input formControlName="localip" name="localip" autocomplete="off"
                        class="form-control form-control-lg form-control-solid" />
                      <small class="text-danger"> {{errors?.bgp?.local?.ip}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Port</label>
                    <div class="d-flex flex-column">
                      <input formControlName="localport" name="localport" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.local?.port}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">AS Number </label>
                    <div class="d-flex flex-column">
                      <input formControlName="localasn" name="localasn" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.local?.asn}} </small>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-0"> Neighbor </h5>
                </div>
              </div>
              <div class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3">
                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">IP</label>
                    <div class="d-flex flex-column">
                      <input formControlName="neighborip" name="neighborip" type="text"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.neighbor?.ip}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">IP range
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Neighbor range given as a CIDR network. Dynamic peers can be established from hosts within
                        this network."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="neighborrange" name="neighborrange" type="text"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.neighbor?.range}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Port</label>
                    <div class="d-flex flex-column">
                      <input formControlName="neighborport" name="neighborport" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.neighbor?.port}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">AS Number </label>
                    <div class="d-flex flex-column">
                      <input formControlName="neighborasn" name="neighborasn" type="Number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.neighbor?.asn}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Automatic AS Number
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Automatically determine the neighbor AS number. If internal, the local AS number is used.
                        If external, any external AS number is accepted."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Standard" formControlName="neighborautomatic_asn"
                        name="neighborautomatic_asn" class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="internal"> Internal </ng-option>
                        <ng-option value="external"> External </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.neighbor?.automatic_asn}} </small>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-0"> IPv4 </h5>
                </div>
              </div>
              <div class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3">
                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Keep Next Hop
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Advertise unchanged next hop attribute."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Standard" formControlName="ipv4next_hop_keep" class="dropdown-block"
                        [clearable]="false" [searchable]="false" name="ipv4next_hop_keep">
                        <ng-option value=""> </ng-option>
                        <ng-option value="no"> No </ng-option>
                        <ng-option value="yes"> Yes </ng-option>
                        <ng-option value="ibgp"> Only IBGP </ng-option>
                        <ng-option value="ebgp"> Only EBGP </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv4?.next_hop_keep}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Self Next Hop
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Advertise local address as next hop."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="self_next" formControlName="ipv4next_hop_self" class="dropdown-block"
                        [clearable]="false" [searchable]="false" name="ipv4next_hop_self">
                        <ng-option value=""> </ng-option>
                        <ng-option value="no"> No </ng-option>
                        <ng-option value="yes"> Yes </ng-option>
                        <ng-option value="rx"> Only RX </ng-option>
                        <ng-option value="tx"> Only TX </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv4?.next_hop_self}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Next Hop Address
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Address advertised when local address is the next hop."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="ipv4next_hop_address" name="ipv4next_hop_address" type="text"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.ipv4?.next_hop_address}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Gateway
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip='How should the gateway be computed. Warning: if " Direct" is used, ensure that the
                        neighbor IP is present in a locally connected network. If it is not, this session will not
                        operate and the behavior of the node is undefined.'></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Gateway" formControlName="ipv4gateway" name="ipv4gateway"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="direct"> Direct </ng-option>
                        <ng-option value="recursive"> Recursive </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv4?.gateway}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Cost <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Set the route cost when gateway is direct."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="ipv4cost" name="ipv4cost" type="Number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.ipv4?.cost}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Add Paths <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Advertise all paths."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Add Paths" formControlName="ipv4add_paths" name="ipv4add_paths"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="no"> No </ng-option>
                        <ng-option value="yes"> Yes </ng-option>
                        <ng-option value="rx"> Only RX </ng-option>
                        <ng-option value="tx"> Only TX </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv4?.add_paths}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Mandatory <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Channel is mandatory for successful connection."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Mandatory" formControlName="ipv4mandatory" name="ipv4mandatory"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="1"> Yes</ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger">{{errors?.bgp?.ipv4?.mandatory}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">AIGP
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Control AIGP (RFC7311) behavior where route metrics are accumulated as they propagate. If set
                        to originate, AIGP will be set on exported routes from other non-BGP protocols."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="ipv4aigp" name="ipv4aigp" class="dropdown-block" [clearable]="false"
                        [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="yes"> Yes</ng-option>
                        <ng-option value="no"> No </ng-option>
                        <ng-option value="originate"> Originate </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv4?.aigp}} </small>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-0"> IPv6 </h5>
                </div>
              </div>
              <div class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3">
                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Keep Next Hop <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip=" Advertise unchanged next hop attribute."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Standard" formControlName="ipv6next_hop_keep" name="ipv6next_hop_keep"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="no"> No </ng-option>
                        <ng-option value="yes"> Yes </ng-option>
                        <ng-option value="ibgp"> Only IBGP </ng-option>
                        <ng-option value="ebgp"> Only EBGP </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.next_hop_keep}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Self Next Hop <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Advertise local address as next hop."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Standard" formControlName="ipv6next_hop_self" name="ipv6next_hop_self"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="no"> No </ng-option>
                        <ng-option value="yes"> Yes </ng-option>
                        <ng-option value="ibgp"> Only IBGP </ng-option>
                        <ng-option value="ebgp"> Only EBGP </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.next_hop_self}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Next Hop Address <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Address advertised when local address is the next hop. "></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="ipv6next_hop_address" name="ipv6next_hop_address" type="text"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.next_hop_address}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Missing Link-Local <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="How to handle a missing link-local IPv6 address."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Standard" formControlName="ipv6missing_lladdr" name="ipv6missing_lladdr"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="self"> Advertise local address </ng-option>
                        <ng-option value="drop"> Skip prefix and log error </ng-option>
                        <ng-option value="ignore"> Ignore </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.missing_lladdr}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Gateway
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="How should the gateway be computed."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Standard" formControlName="ipv6gateway" name="ipv6gateway"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="direct"> Direct </ng-option>
                        <ng-option value="recursive"> Recursive </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.gateway}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Cost
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Set the route cost when gateway is direct."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="ipv6cost" name="ipv6cost" type="Number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.cost}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Add Paths
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Advertise all paths."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Add_Paths" formControlName="ipv6add_paths" name="ipv6add_paths"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="no">No</ng-option>
                        <ng-option value="yes">Yes</ng-option>
                        <ng-option value="rx">Only RX</ng-option>
                        <ng-option value="tx">Only TX</ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.add_paths}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Mandatory
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Channel is mandatory for successful connection."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Mandatory" formControlName="ipv6mandatory" name="ipv6mandatory"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="0"> No </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.mandatory}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> AIGP
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Control AIGP (RFC7311) behavior where route metrics are accumulated as they propagate. If
                        set to originate, AIGP will be set on exported routes from other non-BGP protocols."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Mandatory" formControlName="ipv6aigp" name="ipv6aigp"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value="null"></ng-option>
                        <ng-option value="no"> No </ng-option>
                        <ng-option value="yes"> Yes </ng-option>
                        <ng-option value="originate"> Originate </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ipv6?.aigp}} </small>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-0"> General </h5>
                </div>
              </div>
              <div class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3">
                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Interface
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Interface used for link-local IPv6 sessions."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Interface" formControlName="interface" name="interface"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option *ngFor="let interface of InterfaceDetailArray;" [value]=interface.ifname>
                          {{interface.ifname}}</ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.interface}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Direct
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Neighbor is directly connected."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Direct" formControlName="direct" name="direct" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.direct}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Multihop
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Define the amount of hops for multihop."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="multihop" type="text"
                        class="form-control form-control-lg form-control-solid" name="multihop" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.multihop}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Source Address
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Link-local address for the BGP session."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="source_address" name="source_address" type="text"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.source_address}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Strict Bind
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Bind BGP listening socket to only the source address instead of all addresses."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Strict" formControlName="strict_bind" name="strict_bind"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.strict_bind}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Check Link
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Shutdown BGP session if link disappears."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="check_link" formControlName="check_link" name="check_link"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.check_link}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> TTL Security
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Use generalized TTL security mechanism to protect against spoofed packets."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select placeholder="Security" formControlName="ttl_security" name="ttl_security"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.ttl_security}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Password
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Password used for MD5 authentication."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="password" name="password" type="text"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.password}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Passive
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Never initiate outgoing connections."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="passive" name="passive" class="dropdown-block" [clearable]="false"
                        [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.passive}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Confederation <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="ID of shared confederation (0: Disable)."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="confederation" name="confederation" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.confederation}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Confederation member <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" Whether to consider the neighbor to be part of the same confederation as the local. This
                        option is ignored for iBGP sessions."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="confederation_member" name="confederation_member"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.confederation_member}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Route Reflector <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip=" Be a route reflector and treat the neighbor as a route reflection client."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="rr_client" name="rr_client" class="dropdown-block" [clearable]="false"
                        [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.rr_client}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> RR Cluster ID <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="IPv4 address of Route reflectors cluster."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="rr_cluster_id" name="rr_cluster_id" type="text"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.rr_cluster_id}}</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Route Server <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Be a route server and treat the neighbor as a route server."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="rs_client" name="rs_client" class="dropdown-block" [clearable]="false"
                        [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.rs_client}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Share Local Preference <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Share and accept the local preference attribute with eBGP neighbors."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="allow_bgp_local_pref" name="allow_bgp_local_pref"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.allow_bgp_local_pref}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Allow Local AS <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Receive routes with local AS (0: Deny, Blank: All, AS: Only that AS)."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="allow_local_as" name="allow_local_as" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.allow_local_as}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Route Refresh <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Allow BGP speaker to request re-advertisement of all routes from its neighbor."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="enable_route_refresh" name="enable_route_refresh"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.enable_route_refresh}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Interpret Communities <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Process well-known communities (ex. no-export or no-advertise)."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="interpret_communities" name="interpret_communities"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.interpret_communities}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> AS4 <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Support 4B AS numbers."></span></label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="enable_as4" name="enable_as4" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.enable_as4}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Extended Messages <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Allow messages up to 65535 bytes instead of only 4096 bytes."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="enable_extended_messages" name="enable_extended_messages"
                        class="dropdown-block" [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.enable_extended_messages}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Capabilities
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Advertise optional capabilities. Older BGP implementations don't support this
                        behavior."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="capabilities" name="capabilities" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.capabilities}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Hold Time <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Seconds until message is stale."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="hold_time" name="hold_time" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.hold_time}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Startup Hold Time <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Seconds before routers excahnge messages."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="startup_hold_time" name="startup_hold_time" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.startup_hold_time}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Keepalive Time <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Seconds between sending keepalive messages."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="keepalive_time" name="keepalive_time" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.keepalive_time}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">Connect Delay <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Seconds between protocol startup and connection attempt."></span></label>
                    <div class="d-flex flex-column">
                      <input formControlName="connect_delay_time" name="connect_delay_time" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.connect_delay_time}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Connect Retry <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Seconds between retrying a failed connection."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="connect_retry_time" name="connect_retry_time" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.connect_retry_time}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Error Wait Time Min. <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Minimum seconds between a protocol failure and an automatic restart (Default 60)."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="error_wait_time_min" name="error_wait_time_min" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger" *ngIf="errors?.bgp?.error_wait_time">
                        {{errors?.bgp?.error_wait_time[0]}}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Error Wait Time Length <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip=" Consecutive errors increase the delay between a protocol failure and an automatic restart.
                      This variable defines the amount of seconds after the minimum to cap the delay (Default 240)."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="error_wait_time_len" name="error_wait_time_len" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger" *ngIf="errors?.bgp?.error_wait_time">
                        {{errors?.bgp?.error_wait_time[1]}}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Error Forget <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Maximum seconds between protocol failures to consider them in sequence."></span></label>
                    <div class="d-flex flex-column">
                      <input formControlName="error_forget_time" name="error_forget_time" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.error_forget_time}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Path Metric <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Compare path lengths when deciding which BGP route is the best."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="path_metric" name="path_metric" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.path_metric}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> MED Metric <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Compare MED attributes between routes received from different ASes."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="med_metric" name="med_metric" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.med_metric}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Deterministic MED
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Use the proper, but slower, deterministic routing algorithm instead of comparing individual routes."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="deterministic_med" name="deterministic_med" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.deterministic_med}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> IGP Metric <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Compare internal distances to boundary routers during best route selection."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="igp_metric" name="igp_metric" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.igp_metric}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label"> Prefer Older <span
                        [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="When selecting routes favor older routes."></span> </label>
                    <div class="d-flex flex-column">
                      <ng-select formControlName="prefer_older" name="prefer_older" class="dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""> </ng-option>
                        <ng-option value="1"> Yes </ng-option>
                        <ng-option value="0"> No </ng-option>
                      </ng-select>
                      <small class="text-danger"> {{errors?.bgp?.prefer_older}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">BGP MED <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Value used when MED is missing."></span> </label>
                    <div class="d-flex flex-column">
                      <input formControlName="default_bgp_med" name="default_bgp_med" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.default_bgp_med}} </small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4 mb-3">
                  <div class="form-group inputtext-block mb-3">
                    <label class="form-label">BGP Local Preference
                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                        ngbTooltip="Value used when a Local Preference is attached to a route."></span>
                    </label>
                    <div class="d-flex flex-column">
                      <input formControlName="default_bgp_local_pref" name="default_bgp_local_pref" type="number"
                        class="form-control form-control-lg form-control-solid" autocomplete="off" />
                      <small class="text-danger"> {{errors?.bgp?.default_bgp_local_pref}} </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--bgp section End -->
            <!--static section start -->
            <div *ngIf="typeProtocol=='static'" class="col-12">
              <div class="col-md-4 col-lg-4 mb-3">
                <div class="form-group inputtext-block mb-3">
                  <label class="form-label">Check link <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Only enable this route if the link is up."></span></label>
                  <div class="d-flex flex-column mt-2">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="check_link"
                      name="check_link"></ui-switch>
                    <small class="text-danger"> {{errors?.check_link?.join(' ')}} </small>
                  </div>
                </div>
              </div>
              <div>
                <div class="staticroutes row">
                  <div class="col-md-12 d-flex justify-content-between align-items-center">
                    <div>
                      <h5 class="mb-0"> Routes </h5>
                    </div>
                    <div>
                      <button type="button" class="btn btn-primary btn-style" (click)="onAddstaticroutes()">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                            class="svg-icon svg-icon-block mb-2">
                          </span>
                        </div> Add Route
                      </button>
                    </div>
                  </div>
                  <p> Definition of routes.</p>
                  <div formArrayName="staticroutes">
                    <div [formGroupName]="i" class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3"
                      *ngFor="let staticControl of staticroute?.controls;let i=index">
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Network
                            <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="The network to add a static route for."></span>
                          </label>
                          <div class="d-flex flex-column">
                            <input formControlName="network" type="text" name="network"
                              class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="text-danger" *ngIf="staticControl?.get('network')?.errors">
                              {{staticControl?.get('network')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label"> Destination
                            <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="The type of route."></span>
                          </label>
                          <div class="d-flex flex-column">
                            <ng-select formControlName="destination" name="destination" class="dropdown-block"
                              [clearable]="false" [searchable]="false">
                              <ng-option value="gateway">Gateway</ng-option>
                              <ng-option value="interface">Interface</ng-option>
                              <ng-option value="recursive">Recursive</ng-option>
                              <ng-option value="blackhole">Blackhole</ng-option>
                              <ng-option value="unreachable">Unreachable</ng-option>
                              <ng-option value="prohibit">Prohibit</ng-option>
                            </ng-select>
                            <small class="text-danger" *ngIf="staticControl?.get('destination')?.errors">
                              {{staticControl?.get('destination')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5 col-lg-3" *ngIf="staticControl.value.destination == 'interface'">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Interface</label>
                          <div class="d-flex flex-column">
                            <ng-select formControlName="interface" name="interface" class="dropdown-block">
                              <ng-option *ngFor="let interface of InterfaceDetailArray;" [value]=interface.ifname>
                                {{interface.ifname}}</ng-option>
                            </ng-select>
                            <small class="text-danger" *ngIf="staticControl?.get('interface')?.errors">
                              {{staticControl?.get('interface')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5 col-lg-3 mb-1"
                        *ngIf="staticControl.value.destination == 'gateway' || staticControl.value.destination == 'recursive'">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Address <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="The address to add this route for"></span> </label>
                          <div class="d-flex flex-column">
                            <input formControlName="address" type="text" name="address"
                              class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="text-danger" *ngIf="staticControl?.get('address')?.errors">
                              {{staticControl?.get('address')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1 col-lg-1 mt-8">
                        <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemoveStaticRoute(i)"
                          title="Remove Route">
                          <div>
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                              class="svg-icon svg-icon-block">
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <!--static section End -->
            <!--babel section start -->
            <div *ngIf="typeProtocol=='babel'" class="col-12">
              <div class="col-md-4 col-lg-4 mb-3">
                <div class="form-group inputtext-block mb-3">
                  <label class="form-label"> Randomize Router ID <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Randomize the top 32 bits of the router ID whenever the protocol instance starts up."></span>
                  </label>
                  <div class="d-flex flex-column">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                      formControlName="babelrandomize_router_id" name="babelrandomize_router_id"></ui-switch>
                    <small class="text-danger">
                      {{errors?.babelrandomize_router_id?.join(' ')}}
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-lg-12">
                <small class="text-danger"
                  *ngIf="babelinterface?.controls && babelinterface.controls.length <= 0 && addProtocol?.controls?.interfaces?.errors">
                  {{addProtocol.controls.interfaces.errors}}
                </small>
              </div>
              <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="mb-0"> Babel Interfaces </h4>
                </div>
                <div>
                  <button type="button" class="btn btn-primary btn-style" (click)="onAddbabelinterfaces()">
                    <div>
                      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-block mb-2">
                      </span>
                    </div> Add Interfaces
                  </button>
                </div>
              </div>
              <div class="col-12">
                <div formArrayName="interfaces">
                  <div [formGroupName]="interfaceIndex"
                    class="row border border-gray-300 border-shadow-grey br-6 mx-0 my-3 p-3 justify-content-around"
                    *ngFor="let babelControl of babelinterface?.controls;let interfaceIndex=index">
                    <small class="text-danger" *ngIf="babelinterfacelinks(interfaceIndex)?.controls
                      && babelinterfacelinks(interfaceIndex).controls.length <= 0">
                      {{babelControl?.get('interfaces')?.errors}}
                    </small>
                    <div class="row p-0">
                      <div class="col-md-12 d-flex justify-content-between align-items-center">
                        <div>
                          <h5 class="mb-0"> Interface List </h5>
                        </div>
                        <div>
                          <button type="button" class="btn btn-danger btn-icon-1"
                            (click)="onRemovebabelinterfaces(interfaceIndex)" title="Remove Interfaces">
                            <div>
                              <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                class="svg-icon svg-icon-block">
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="col-12">
                        <p> Specify the interfaces that use this protocol. You may only select the interfaces already
                          added to this node. If you have not added any interfaces yet it won't be possible to use
                          interfaces with your custom protocol. If a space is set on your custom protocol then the
                          interfaces must also have a space set; otherwise they are incompatible.
                        </p>
                      </div>
                      <div class="col-12 d-flex justify-content-end">
                        <div>
                          <button type="button" class="btn btn-primary btn-style"
                            (click)="onAddbabelinterfacelink(interfaceIndex)">
                            <div>
                              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                class="svg-icon svg-icon-block mb-2">
                              </span>
                            </div> Add Interfaces Link
                          </button>
                        </div>
                      </div>
                      <div formArrayName="interfaces" class="row mb-3">
                        <div [formGroupName]="i" class="col-lg-4 col-md-4 col-12 mb-1 
                        d-flex align-items-center gap-3"
                          *ngFor="let linkControl of babelinterfacelinks(interfaceIndex)?.controls;let i=index">
                          <div class="form-group inputtext-block w-100">
                            <label class="form-label"> Link {{i+1}} </label>
                            <div class="d-flex w-100">
                              <div class="d-flex flex-column mb-5 w-100">
                                <ng-select formControlName="link" name="link" class="dropdown-block">
                                  <ng-option *ngFor="let interface of InterfaceDetailArray;" [value]=interface.ifname>
                                    {{interface.ifname}}</ng-option>
                                </ng-select>
                                <small class="text-danger" *ngIf="linkControl?.get('link')?.errors
                                  && linkControl?.get('link')?.touched">
                                  Must not be empty
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <button type="button" class="btn btn-danger btn-icon-1"
                              (click)="onRemovebabelInterfaceLink(interfaceIndex,i)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                  class="svg-icon svg-icon-block">
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label"> Type <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="Wired interface neighbors are considered unreachable after a small number of 'Hello'
                              packets are lost. Wireless interface link quality isn't binary like wired, as packets are lost routes become less preferable."></span>
                          </label>
                          <div class="d-flex flex-column">
                            <ng-select formControlName="type" name="type" class="dropdown-block" [clearable]="false"
                              [searchable]="false">
                              <ng-option value="wired"> Wired </ng-option>
                              <ng-option value="wireless"> Wireless </ng-option>
                            </ng-select>
                            <small class="text-danger" *ngIf="babelControl?.get('type')?.errors">
                              {{babelControl?.get('type')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label"> RX Cost
                            <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip=" How many of the last 16 'Hellos' have to be correctly received in order to assume
                              the neighbor is up."></span>
                          </label>
                          <div class="d-flex flex-column">
                            <input formControlName="rxcost" type="text"
                              class="form-control form-control-lg form-control-solid" name="rxcost"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('rxcost')?.errors">
                              {{babelControl?.get('rxcost')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Limit
                            <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip=" How many of the last 16 'Hellos' have to be correctly received in order to assume
                              the neighbor is up."></span>
                          </label>
                          <div class="d-flex flex-column">
                            <input formControlName="limit" type="text"
                              class="form-control form-control-lg form-control-solid" name="limit" autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('limit')?.errors">
                              {{babelControl?.get('limit')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Hello Interval <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="How often are 'Hello' messages sent."></span> </label>
                          <div class="d-flex flex-column">
                            <input formControlName="hello_interval" type="text"
                              class="form-control form-control-lg form-control-solid" name="hello_interval"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('hello_interval')?.errors">
                              {{babelControl?.get('hello_interval')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Update Interval <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="How often are (full) updates sent."></span></label>
                          <div class="d-flex flex-column">
                            <input formControlName="update_interval" type="text"
                              class="form-control form-control-lg form-control-solid" name="update_interval"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('update_interval')?.errors">
                              {{babelControl?.get('update_interval')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Port <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="Which UDP port to operate on."></span> </label>
                          <div class="d-flex flex-column">
                            <input formControlName="port" type="text"
                              class="form-control form-control-lg form-control-solid" name="port" autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('port')?.errors">
                              {{babelControl?.get('port')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">TX Class <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="TX Class value."></span></label>
                          <div class="d-flex flex-column">
                            <input formControlName="tx_class" type="text"
                              class="form-control form-control-lg form-control-solid" name="tx_class"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('tx_class')?.errors">
                              {{babelControl?.get('tx_class')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">TX DSCP <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="TX DSCP value."></span></label>
                          <div class="d-flex flex-column">
                            <input formControlName="tx_dscp" type="text"
                              class="form-control form-control-lg form-control-solid" name="tx_dscp"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('tx_dscp')?.errors">
                              {{babelControl?.get('tx_dscp')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">TX Priority <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="TX Priority value."></span> </label>
                          <div class="d-flex flex-column">
                            <input formControlName="tx_priority" type="text"
                              class="form-control form-control-lg form-control-solid" name="tx_priority"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('tx_priority')?.errors">
                              {{babelControl?.get('tx_priority')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">TX Length <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="Maximum length of generated Babel packets."></span> </label>
                          <div class="d-flex flex-column">
                            <input formControlName="tx_length" type="text"
                              class="form-control form-control-lg form-control-solid" name="tx_length"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('tx_length')?.errors">
                              {{babelControl?.get('tx_length')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">RX Buffer <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="Size of buffers used for packet processing."></span> </label>
                          <div class="d-flex flex-column">
                            <input formControlName="rx_buffer" type="text"
                              class="form-control form-control-lg form-control-solid" name="rx_buffer"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('rx_buffer')?.errors">
                              {{babelControl?.get('rx_buffer')?.errors}} </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Next Hop IPv4 <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="The hop address advertised for IPv4 routes advertised on this interface."></span>
                          </label>
                          <div class="d-flex flex-column">
                            <input formControlName="next_hop_ipv4" type="text"
                              class="form-control form-control-lg form-control-solid" name="next_hop_ipv4"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('next_hop_ipv4')?.errors">
                              {{babelControl?.get('next_hop_ipv4')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label">Next Hop IPv6 <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="The hop address advertised for IPv6 routes advertised on this interface."></span>
                          </label>
                          <div class="d-flex flex-column">
                            <input formControlName="next_hop_ipv6" type="text"
                              class="form-control form-control-lg form-control-solid" name="next_hop_ipv6"
                              autocomplete="off" />
                            <small class="text-danger" *ngIf="babelControl?.get('next_hop_ipv6')?.errors">
                              {{babelControl?.get('next_hop_ipv6')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-3">
                          <label class="form-label"> Check Link <span
                              [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                              class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                              ngbTooltip="If the link disappears, neighbors are immediately considered unreachable and all
                            routes received from them are withdrawn."></span> </label>
                          <div class="d-flex flex-column mt-2">
                            <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="check_link"
                              name="check_link"></ui-switch>
                            <small class="text-danger" *ngIf="babelControl?.get('check_link')?.errors">
                              {{babelControl?.get('check_link')?.errors}}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--babel section End -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer px-5 py-3">
  <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
    <div>
      <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
      <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
    </div> {{isEdit ? 'Save': 'Add'}}
  </button>
  <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
    <div>
      <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
    </div> Cancel
  </button>
</div>