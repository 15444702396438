<div class="card mb-5 mb-xl-8">
    <div class="card-body p-md-2 p-xl-5">
        <div class="table-responsive border">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                <thead class="align-middle table-light">
                    <tr class="fw-bolder text-dark fs-7">
                        <th class="py-4 ps-4" (click)="onSort('fname')">Organization Name
                            <span *ngIf="sortBy == 'fname' && $any(organizationList?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;">
                            </span>
                        </th>
                        <th class="py-4" (click)="onSort('registrationNo')">Registration Number
                            <span *ngIf="sortBy == 'registrationNo' && $any(organizationList?.length) > 0"
                                [inlineSVG]="arrow" class="svg-icon svg-icon-5" style="height: 10px;">
                            </span>
                        </th>
                        <th class="py-4" (click)="onSort('email')">E-mail Address
                            <span *ngIf="sortBy == 'email' && $any(organizationList?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;">
                            </span>
                        </th>
                        <th class="py-4 min-w-150px w-250px "> Groups </th>
                        <th class="py-4 min-w-150px w-250px"> Space </th>
                        <th class="py-4 min-w-150px w-150px " (click)="onSort('createdAt')">
                            Created At
                            <span *ngIf="sortBy == 'createdAt' && $any(organizationList?.length) > 0"
                                [inlineSVG]="arrow" class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="min-w-50px w-50px text-center pe-4">Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="!preLoaderScreen && length > 0">
                    <tr *ngFor="let item of organizationList">
                        <td class="charlimit ps-4" [title]="item.fname"> {{item.fname}} </td>
                        <td class="charlimit" [title]="item.registrationNo"> {{item.registrationNo || '-'}} </td>
                        <td>{{item.email || '-'}} </td>
                        <td *ngIf="item.groupIds.length">
                            <span class="d-block" *ngFor="let group of item.groupIds">{{group?.name}}</span>
                        </td>
                        <td *ngIf="!item.groupIds.length">
                            <span>-</span>
                        </td>
                        <td>
                            <div *ngIf="!isSpacePreloader">
                                {{item.homeSpaceName || '-'}}
                            </div>
                            <div class="shine" style="width: 150px;height: 20px;" *ngIf="isSpacePreloader"></div>
                        </td>
                        <td>
                            <a> {{item.createdAt | date: 'dd MMM, YYYY'}}</a>
                        </td>
                        <td class="text-center pe-4">
                            <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                    class="svg svg-icon svg-transparent">
                                </span>
                            </a>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item class="menu-list-block" *ngIf="permissionsObj?.update"
                                    title="Edit" (click)="onEdit(item)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                            class="svg svg-icon svg-icon-warning">
                                        </span>
                                    </div>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" *ngIf="permissionsObj?.delete"
                                    (click)="onSuspend(item._id)" title="Suspend">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/design/ban.svg'"
                                            class="svg svg-icon svg-icon-danger">
                                        </span>
                                    </div>
                                    <span>Suspend</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Reset Password"
                                    (click)="onResetPassword(item.email,item._id)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/Lock-overturning.svg'"
                                            class="svg svg-icon svg-icon-primary">
                                        </span>
                                    </div>
                                    <span>Reset Password</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Login as Organization"
                                    *ngIf="item.isLogin" (click)="onLoginAsUser(item)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen057.svg'"
                                            class="svg svg-icon svg-icon-info">
                                        </span>
                                    </div>
                                    <span>Organization Login</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-preloader-table-list *ngIf="preLoaderScreen"></app-preloader-table-list>
        </div>
        <div *ngIf="!preLoaderScreen && length == 0" class="text-center font16 mt15 mb15 pt-4">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
            </h3>
        </div>
        <app-pagination *ngIf="length > 0" [childMessage]="length" [index]="page" [pagesize]="limit"
            (messageEvent)="receiveMessage($event)">
        </app-pagination>
    </div>
</div>