<div *ngIf="delegate==true">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title">Add Delegated Allocation</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body p-5">
    <form [formGroup]="GroupAllocation">
      <p> Specify the IP subnet to delegate to a child space </p>
      <div class="form-group mb-3">
        <label class="form-label"> Parent </label><br />
        <label>{{details.network}}</label>
      </div>
      <div class="form-group mb-3">
        <div class="form-group inputtext-block mb-5">
          <label class="form-label"> Subnet </label>
          <input type="text" formControlName="subnet" name="subnet"
            class="form-control form-control-lg form-control-solid" />
          <small class="form-invalid-message">
            <span class="text-danger">
              {{errors['network']?.join(' ')}}
            </span>
          </small>
        </div>
      </div>
      <div class="form-group mb-3">
        <label class="form-label"> Space </label>
        <ng-select class="dropdown-block" formControlName="routing_group" name="routing_group">
          <ng-option *ngFor="let space of spaceList" [value]="space.key">{{space.name}}</ng-option>
        </ng-select>
        <small class="form-invalid-message">
          <span class="text-danger">
            {{errors['space']?.join(' ')}}
          </span>
        </small>
      </div>
    </form>
  </div>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" (click)="delegateRecord()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Delegate
    </button>
    <button type="button" class="btn btn-primary btn-style" (click)="onClose()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</div>
<div *ngIf="edit==true">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title" id="edit_allocationLabel">Edit delegated allocation</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body p-5">
    <form [formGroup]="GroupAllocation">
      <p> Specify the IP subnet to delegate to a child space </p>
      <div class="form-group mb-3">
        <label class="form-label"> Parent </label><br />
        <label>{{ipDetail.network}}</label>
        <!-- <input type="text" class="form-control" formControlName="network" name="network" [value]="ipDetail.ip.network"  [disabled]='true'> -->
      </div>
      <div class="form-group mb-3 col-md-6">
        <div class="form-group inputtext-block mb-5">
          <label class="form-label"> Subnet </label>
          <input type="text" formControlName="subnet" name="subnet" class="form-control" id="" />
          <small class="form-invalid-message">
            <span class="text-danger">
              {{errors['network']?.join(' ')}}
            </span>
          </small>
        </div>
      </div>
      <div class="form-group mb-3 col-md-6">
        <label class="form-label"> Space </label>
        <ng-select class="dropdown-block" formControlName="routing_group" name="routing_group" [readonly]="true">
          <ng-option *ngFor="let space of spaceList" [value]="space.url">{{space.name}}</ng-option>
        </ng-select>
        <small class="form-invalid-message">
          <span class="text-danger">
            {{errors['space']?.join(' ')}}
          </span>
        </small>
      </div>
    </form>
  </div>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" (click)="editRecord()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" (click)="onClose()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</div>
<div *ngIf="merge==true">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title"> Merge Group Allocations </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body p-5">
    <p *ngIf="mergeList.length == undefined || mergeList.length == 0">No merges available.</p>
  </div>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style"
      [disabled]="mergeList && mergeList.length > 0 ? false : true"
      (click)="mergeList && mergeList.length > 0 ? mergeRecord():''" data-bs-dismiss="modal"> Merge
    </button>
    <button type="button" class="btn btn-secondary btn-style" (click)="onClose()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</div>
<div *ngIf="delete==true">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title"> Delete Allocation </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body p-5">
    <p> Are you sure you wish to delete allocation <b>{{details.network}}</b> </p>
  </div>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" (click)="deleteRecord()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Delete
    </button>
    <button type="button" class="btn btn-secondary btn-style" (click)="onClose()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</div>
<div *ngIf="detailsgroup==true">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title"> {{ipDetail.network}} Availability </h5>
  </div>
  <div class="modal-body p-5">
    <div class="row">
      <div class="col-sm-12 availability_icons">
        <span><i class="availability_icon available"></i> Available</span>
        <span><i class="availability_icon used"></i> Used</span>
        <span><i class="availability_icon delegated"></i> Delegated</span>
      </div>
    </div>
    <div *ngIf="availabilityList.length > 0" class="row">
      <div *ngFor="let avl of availabilityList;let a = index;" class="availability_list">
        <div>{{avl.first}}</div>
        <div>
          <ng-container *ngFor="let ico of avl.hosts;let i = index;">
            <span *ngIf="ico.availability == 'used'"
              [class]="(a == 0 && i == 0) ? 'availability_icon used hoverIcon tl-corner' : (a == 0 && i == avl.hosts.length -1) ? 'availability_icon used hoverIcon tr-corner' : (a == (availabilityList.length - 1) && i == 0) ? 'availability_icon used hoverIcon bl-corner' : (a == (availabilityList.length - 1) && i == (avl.hosts.length - 1)) ? 'availability_icon used hoverIcon br-corner' : 'availability_icon used hoverIcon'"
              [ngbPopover]="ngbStatus1" triggers="mouseenter:mouseleave" popoverTitle="{{ico.address}}"
              (click)="onPopupSpaceEdit(ico.space_key)"></span>
            <ng-template class="p-5" #ngbStatus1>
              <table>
                <tr>
                  <th>Used in:</th>
                  <td>{{ico.space_name}}</td>
                </tr>
                <tr>
                  <td>{{ico.object_name}}</td>
                </tr>
              </table>
            </ng-template>
            <span *ngIf="ico.availability == 'delegated'"
              [class]="(a == 0 && i == 0) ? 'availability_icon delegated hoverIcon tl-corner' : (a == 0 && i == avl.hosts.length -1) ? 'availability_icon delegated hoverIcon tr-corner' : (a == (availabilityList.length - 1) && i == 0) ? 'availability_icon delegated hoverIcon bl-corner' : (a == (availabilityList.length - 1) && i == (avl.hosts.length - 1)) ? 'availability_icon delegated hoverIcon br-corner' : 'availability_icon delegated hoverIcon'"
              [ngbPopover]="ngbStatus2" triggers="mouseenter:mouseleave" popoverTitle="{{ico.address}}"
              (click)="onPopupSpaceEdit(ico.space_key)"></span>
            <ng-template class="p-5" #ngbStatus2>
              <table>
                <tr>
                  <th>Delegated to:</th>
                  <td>{{ico.space_name}}</td>
                </tr>
              </table>
            </ng-template>
            <span *ngIf="ico.availability == 'available'"
              [class]="(a == 0 && i == 0) ? 'availability_icon available tl-corner' : (a == 0 && i == avl.hosts.length -1) ? 'availability_icon available tr-corner' : (a == (availabilityList.length - 1) && i == 0) ? 'availability_icon available bl-corner' : (a == (availabilityList.length - 1) && i == (avl.hosts.length - 1)) ? 'availability_icon available br-corner' : 'availability_icon available'"
              [ngbPopover]="ngbStatus3" triggers="mouseenter:mouseleave" popoverTitle="{{ico.address}}"></span>
            <ng-template class="p-5" #ngbStatus3>
              <table>
                <tr>
                  <th>Available</th>
                </tr>
              </table>
            </ng-template>
          </ng-container>
        </div>
        <div>{{avl.last}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-secondary btn-style" (click)="onClose()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Dismiss
    </button>
  </div>
</div>
<div *ngIf="details==true">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title"> {{ipDetail.network}} Availability </h5>
  </div>
  <div class="modal-body p-5">
    <div class="row">
      <div class="col-sm-12 availability_icons">
        <span><i class="availability_icon available"></i> Available</span>
        <span><i class="availability_icon used"></i> Used</span>
        <span><i class="availability_icon delegated"></i> Delegated</span>
      </div>
    </div>
    <div *ngIf="availabilityList.length > 0" class="row">
      <div *ngFor="let avl of availabilityList;let a = index;" class="availability_list">
        <div>{{avl.first}}</div>
        <div>
          <ng-container *ngFor="let ico of avl.hosts;let i = index;">
            <span *ngIf="ico.availability == 'used'"
              [class]="(a == 0 && i == 0) ? 'availability_icon used hoverIcon tl-corner' : (a == 0 && i == avl.hosts.length -1) ? 'availability_icon used hoverIcon tr-corner' : (a == (availabilityList.length - 1) && i == 0) ? 'availability_icon used hoverIcon bl-corner' : (a == (availabilityList.length - 1) && i == (avl.hosts.length - 1)) ? 'availability_icon used hoverIcon br-corner' : 'availability_icon used hoverIcon'"
              [ngbPopover]="ngbAStatus1" triggers="mouseenter:mouseleave" popoverTitle="{{ico.address}}"
              (click)="onPopupSpaceEdit(ico.space_key)"></span>
            <ng-template class="p-5" #ngbAStatus1>
              <table>
                <tr>
                  <th>Used in:</th>
                  <td>{{ico.space_name}}</td>
                </tr>
                <tr>
                  <td>{{ico.object_name}}</td>
                </tr>
              </table>
            </ng-template>
            <span *ngIf="ico.availability == 'delegated'"
              [class]="(a == 0 && i == 0) ? 'availability_icon delegated hoverIcon tl-corner' : (a == 0 && i == avl.hosts.length -1) ? 'availability_icon delegated hoverIcon tr-corner' : (a == (availabilityList.length - 1) && i == 0) ? 'availability_icon delegated hoverIcon bl-corner' : (a == (availabilityList.length - 1) && i == (avl.hosts.length - 1)) ? 'availability_icon delegated hoverIcon br-corner' : 'availability_icon delegated hoverIcon'"
              [ngbPopover]="ngbAStatus2" triggers="mouseenter:mouseleave" popoverTitle="{{ico.address}}"
              (click)="onPopupSpaceEdit(ico.space_key)"></span>
            <ng-template class="p-5" #ngbAStatus2>
              <table>
                <tr>
                  <th>Delegated to:</th>
                  <td>{{ico.space_name}}</td>
                </tr>
              </table>
            </ng-template>
            <span *ngIf="ico.availability == 'used'" class="availability_icon used"
              title="{{ico.address}} : Used"></span>
            <span *ngIf="ico.availability == 'available'"
              [class]="(a == 0 && i == 0) ? 'availability_icon available tl-corner' : (a == 0 && i == avl.hosts.length -1) ? 'availability_icon available tr-corner' : (a == (availabilityList.length - 1) && i == 0) ? 'availability_icon available bl-corner' : (a == (availabilityList.length - 1) && i == (avl.hosts.length - 1)) ? 'availability_icon available br-corner' : 'availability_icon available'"
              [ngbPopover]="ngbAStatus3" triggers="mouseenter:mouseleave" popoverTitle="{{ico.address}}"></span>
            <ng-template class="p-5" #ngbAStatus3>
              <table>
                <tr>
                  <th>Available</th>
                </tr>
              </table>
            </ng-template>
          </ng-container>
        </div>
        <div>{{avl.last}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-secondary btn-style" (click)="onClose()" data-bs-dismiss="modal">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Dismiss
    </button>
  </div>
</div>