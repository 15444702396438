<!-- start tabbing details section Configuration & Details -->
<div class="card border-1 border p-0">
    <div class="card-body p-3">
        <div class="row" *ngIf="networkConfigurationPermissionsObj?.view">
            <div class="col-lg-6 col-xxl-6">
                <div class="row">
                    <div class="col-lg-12 col-xxl-12">
                        <div class="card mb-5 mb-xl-10 border-1 border">
                            <div class="card-header cursor-pointer border-top d-flex px-5 min-h-50px bg-light">
                                <div class="card-title m-0">
                                    <h3 class="fw-bolder m-0">General Configuration </h3>
                                </div>
                                <div class="me-2 card-toolbar d-flex align-items-center justify-content-end"
                                    title="Edit" *ngIf="networkConfigurationPermissionsObj?.update">
                                    <button type="button" class="btn btn-primary btn-style"
                                        [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                                        (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openGeneralConfig()">
                                        <div>
                                            <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                                                class="svg-icon svg-icon-block mb-2">
                                            </span>
                                        </div> Edit
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-5">
                                <div class="row mb-5">
                                    <div class="col-lg-12 col-xxl-6 col-md-6">
                                        <div class="row mb-1">
                                            <label
                                                class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                                Name</label>
                                            <div class="col-lg-6 col-xxl-6 col-md-6">
                                                <span class="fs-7 text-dark">{{bonderDetails?.name}}</span>
                                            </div>
                                        </div>
                                        <div class="row mb-1"
                                            *ngIf="(isHomeSpace || isPartner) && !(isSharedBond || isSdwanSharedBond)">
                                            <label
                                                class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                                Space</label>
                                            <div class="col-lg-6 col-xxl-7 col-md-7 d-flex">
                                                <a (click)="navigateToSpace(bonderDetails?.space)" class="ac">
                                                    <span class="fs-7 text-dark">{{bonderDetails?.space?.name}}</span>
                                                </a>
                                                <a (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openSpacepopup()"
                                                    class="ms-2 cursor-pointer">
                                                    <i class="fa fa-fw fa-sitemap" style=" color: currentcolor; "></i>
                                                </a>
                                                <span
                                                    class="custom-box ms-2 d-flex fs-9 fw-bolder bg-primary text-white"
                                                    *ngIf="bonderDetails?.space?.private_wan_enabled">
                                                    private WAN</span>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                                Antares DB Notes </label>
                                            <div class="col-lg-4 col-xxl-6 col-md-6">
                                                <span class="fs-7 text-dark"
                                                    *ngIf="bonderDetails?.note; else emptyDBNotes">
                                                    {{bonderDetails?.note}}
                                                    <!-- <span *ngIf="bondDetails?.replify_enabled" title="true">
                                                        <span
                                                            [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                            class="svg-icon svg-icon-3 ml-2 me-1">
                                                        </span>
                                                    </span>  -->
                                                </span>
                                                <ng-template #emptyDBNotes>
                                                    <span
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                        class="svg-icon svg-icon-3 ml-2 me-1">
                                                    </span>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12 col-xxl-12">
                        <div class="card mb-5 border-1 border">
                            <div class="card-header cursor-pointer border-top d-flex px-5 min-h-50px bg-light">
                                <div class="card-title m-0">
                                    <h3 class="fw-bolder m-0">WAN Services </h3>
                                </div>
                                <div class="me-2 card-toolbar d-flex align-items-center justify-content-end"
                                    title="Edit">
                                    <a class="btn btn-sm p-3 btn-bg-light text-primary"
                                        [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                                        (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openWanServices()">
                                        Edit <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                            class="svg-icon svg-icon-2 me-0 svg-icon-primary"></span>
                                    </a>
                                </div>
                            </div>
                            <div class="card-body p-5">
                                <div class="row mb-5">
                                    <div class="col-lg-12 col-xxl-6 col-md-6">
                                        <div class="row mb-1">
                                            <label
                                                class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                                QoS profile </label>
                                            <div class="col-lg-4 col-xxl-6 col-md-6">
                                                <span class="fs-7 text-dark" *ngIf="bondDetails?.qos_profile?.name">
                                                    {{bondDetails?.qos_profile?.name}}
                                                </span>
                                                <span *ngIf="!bondDetails?.qos_profile?.name" title="false">
                                                    <span
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                        class="svg-icon svg-icon-3 ml-2 me-1">
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                                Classification profile </label>
                                            <div class="col-lg-4 col-xxl-6 col-md-6">
                                                <span class="fs-7 text-dark"
                                                    *ngIf="bondDetails?.classification_profile && bondDetails?.classification_display_name">
                                                    {{bondDetails?.classification_display_name}}
                                                </span>
                                                <span *ngIf="!bondDetails?.classification_profile" title="false">
                                                    <span
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                        class="svg-icon svg-icon-3 ml-2 me-1">
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                                Flow collectors</label>
                                            <div class="col-lg-4 col-xxl-6 col-md-6">
                                                <span class="fs-7 text-dark" *ngIf="bondDetails?.flow_collectors[0]">
                                                    {{bondDetails?.flow_collectors[0]}}
                                                </span>
                                                <span *ngIf="!bondDetails?.flow_collectors[0]" title="false">
                                                    <span
                                                        [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                        class="svg-icon svg-icon-3 ml-2 me-1">
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-xxl-6">
                <div class="row">
                    <div class="col-lg-12 col-xxl-12">
                        <div class="card mb-5 mb-xl-0 border-1 border">
                            <div class="card-header cursor-pointer d-flex px-5 min-h-50px bg-light">
                                <div class="card-title m-0">
                                    <h3 class="fw-bolder m-0">WAN Optimization</h3>
                                </div>
                                <div class="me-2 card-toolbar d-flex align-items-center justify-content-end"
                                    title="Edit" *ngIf="networkConfigurationPermissionsObj?.update">
                                    <button type="button" class="btn btn-primary btn-style"
                                        [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                                        (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openSecurityAndOptimization()">
                                        <div>
                                            <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                                                class="svg-icon svg-icon-block mb-2">
                                            </span>
                                        </div> Edit
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-5">
                                <!-- <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Tunnel security </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.tunnel_security === 'hmac'">
                                            HMAC </span>
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.tunnel_security === 'encryption'">Encryption
                                        </span>
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.tunnel_security === 'none'"> No
                                            security</span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.tunnel_security === 'encryption'">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Encryption cipher </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.encryption_cipher === 'AES128'"> AES 128
                                        </span>
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.encryption_cipher === 'AES256'"> AES 256
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.tunnel_security === 'encryption'">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Encryption handshake interval </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6"
                                        *ngIf="bondDetails?.encryption_handshake_interval">
                                        {{timeconverter(bondDetails?.encryption_handshake_interval)}}
                                    </div>
                                </div> -->
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        QoS profile </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.qos_profile?.name">
                                            {{bondDetails?.qos_profile?.name}}
                                        </span>
                                        <span *ngIf="!bondDetails?.qos_profile?.name" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Classification profile </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.classification_profile && getClassificationPolicyName(bondDetails?.classification_profile)">
                                            {{getClassificationPolicyName(bondDetails?.classification_profile)}}
                                        </span>
                                        <span
                                            *ngIf="!(bondDetails?.classification_profile && getClassificationPolicyName(bondDetails?.classification_profile))"
                                            title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Compression </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fw-bolder fs-6 text-dark">
                                            <span *ngIf="!bondDetails?.compression" title="false">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                            <span *ngIf="bondDetails?.compression" title="true">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Flap detection </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fw-bolder fs-6 text-dark">
                                            <span *ngIf="!bondDetails?.flap_detection" title="false">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                            <span *ngIf="bondDetails?.flap_detection == true" title="true">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Leg MTU detection</label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fw-bolder fs-6 text-dark">
                                            <span *ngIf="!bondDetails?.leg_mtu_detection" title="false">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                            <span *ngIf="bondDetails?.leg_mtu_detection" title="true">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Packet loss detection</label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fw-bolder fs-6 text-dark">
                                            <span *ngIf="!bondDetails?.packet_loss_detection" title="false">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                            <span *ngIf="bondDetails?.packet_loss_detection" title="true">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Clamp TCP </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fw-bolder fs-6 text-dark">
                                            <span *ngIf="!bondDetails?.clamp_tcp" title="false">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                            <span *ngIf="bondDetails?.clamp_tcp" title="true">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Source address verification </label>
                                    <div class="col-lg-6 col-xxl-6 col-md-6">
                                        <span class="fw-bolder fs-6 text-dark">
                                            <span *ngIf="!bondDetails?.source_address_verification" title="false">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                            <span *ngIf="bondDetails?.source_address_verification" title="true">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Packet distribution </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.packet_distribution">
                                            <span *ngIf="bondDetails?.packet_distribution == 'wrr'">Weighted round
                                                robin</span>
                                            <span *ngIf="bondDetails?.packet_distribution == 'flowlet'">Flowlet</span>
                                            <span *ngIf="bondDetails?.packet_distribution == 'idmpq'">IDMPQ</span>
                                        </span>
                                        <span *ngIf="!bondDetails?.packet_distribution" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Leg MTU detection time </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark">{{bondDetails?.leg_mtu_detection_time || 0}} hour
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Tunnel security </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.tunnel_security === 'hmac'">
                                            HMAC </span>
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.tunnel_security === 'encryption'">Encryption
                                        </span>
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.tunnel_security === 'none'"> No
                                            security</span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.tunnel_security === 'encryption'">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Encryption cipher </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.encryption_cipher === 'AES128'"> AES 128
                                        </span>
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.encryption_cipher === 'AES256'"> AES 256
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.tunnel_security === 'encryption'">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Encryption handshake interval </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6"
                                        *ngIf="bondDetails?.encryption_handshake_interval">
                                        {{timeconverter(bondDetails?.encryption_handshake_interval)}}
                                    </div>
                                </div>
                                <!-- <div class="row mb-1">
                                    <div class="col-lg-12">
                                        <h3 class="fw-bolder m-0">TCP Proxy</h3>
                                    </div>
                                </div> -->

                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Enable TCP proxy </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fw-bolder fs-6 text-dark">
                                            <span *ngIf="!bondDetails?.bridge_enabled" title="false">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                            <span *ngIf="bondDetails?.bridge_enabled" title="true">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.bridge_enabled">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Ports </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.bridge_ports">
                                            {{bondDetails?.bridge_ports }}
                                        </span>
                                        <span *ngIf="!bondDetails?.bridge_ports" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.bridge_enabled">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Concurrency </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark"> {{bondDetails?.bridge_concurrency || 0}} </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.bridge_enabled">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Congestion control algorithm </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.bridge_congestion_control_algorithm">
                                            {{bondDetails?.bridge_congestion_control_algorithm | uppercase}}
                                            <span *ngIf="bondDetails?.bridge_congestion_control_algorithm ==='bbr'">
                                                (Recommended)</span>
                                        </span>
                                        <span *ngIf="!bondDetails?.bridge_congestion_control_algorithm" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="bondDetails?.bridge_enabled">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        File descriptor limit </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.bridge_file_descriptor_limit">
                                            {{bondDetails?.bridge_file_descriptor_limit }}
                                        </span>
                                        <span *ngIf="!bondDetails?.bridge_file_descriptor_limit" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!networkConfigurationPermissionsObj?.view">
            You have no permission to view the data. Kindly contact the authority.
        </div>
    </div>
</div>