<div class="modal-header p-5">
    <h5 class="modal-title" id="exampleModalLabel">Edit multiple</h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
    <div class="card mb-5">
        <div class="card-body p-0">
            <div class="row" *ngIf="errors">
                <div class="col-md-12">
                    <div class="alert alert-danger">Bond cannot be moved to {{selectedSpace?.name}}:</div>
                </div>
                <div class="col-md-12" *ngFor="let err of errors | keyvalue">
                    <div class="alert alert-danger">{{err.value}}</div>
                </div>
            </div>
            <div class="form-group row mb-5">
                <span>These values will be applied to each of the 2 selected bonds. To avoid changing a value for the
                    selected bonds, leave the field blank or choose the value "unchanged."</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer p-5">
    <button type="button" (click)="onSave()" class="btn btn-primary">Save</button>
    <button type="button" (click)="onClose()" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
</div>
