<!-- Ips HTML start -->
<div class="card mb-5 mb-xl-8 border-top-0 border-2 border">
  <div class="card-body p-5 pb-0">
    <div class="row" *ngIf="ipList?.length + delIpList.length <= 0">
      <div class="col-md-12">
        <div class="alert alert-info">Since this space has no available allocated or delegated IP addresses, no new
          connected IPs, CPE NAT IPs, routes, or private WAN rules can be added. To add IP space, request a delegated
          subnet from the parent space or add an allocation to a routing group in this space.
        </div>
      </div>
    </div>
    <div class="card-header d-flex justify-content-between align-items-center pt-0 px-0 border-bottom-0 pb-3">
      <h2 class="mb-0 fs-3">Group Allocations</h2>
      <button type="button" class="btn btn-primary btn-style"
        (click)="spaceData?.routing_groups.length > 0 ? addGroupAll(''): ''"
        [ngClass]="spaceData?.routing_groups.length > 0 ? '': 'c-disable'"
        [disabled]="spaceData?.routing_groups.length > 0 ? false: true"
        [title]="spaceData?.routing_groups.length > 0 ? '' : 'Unavailable because this space has no routing groups'">
        <div>
          <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
          </span>
        </div>Add Allocations
      </button>
    </div>
    <p> A group allocation specifies a network whose subnets can be assigned to bonds in certain routing groups. Subnets
      can be assigned to bonds in this space or delegated to child spaces to give child space admins limited control of
      routing assignments.
    </p>
    <div class="table-responsive border">
      <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-1 min-w-900">
        <thead class="table-light">
          <tr class="fw-bolder text-dark">
            <th class="fw-bolder py-4">Network</th>
            <th class="fw-bolder py-4 min-w-125px">Routing groups</th>
            <th class="fw-bolder py-4">Delegations</th>
            <th class="fw-bolder py-4">Availability </th>
            <th class="min-w-50px w-50px text-center fw-bolder py-4">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="!isIPLoader">
          <tr *ngFor="let ip of ipList; let i=index">
            <td class="ps-5">{{ip.network}} </td>
            <td>
              <span class="badge badge-light-warning" *ngIf="ip?.assigned_routing_groups_list?.length <= 0">
                No assigned routing groups
              </span>
              <div *ngFor="let name of ip.assigned_routing_groups_list"> {{name.name}} </div>
            </td>
            <td *ngIf="ip.delegations">
              <div *ngFor="let name of ip.delegations" class="delegateList">{{name.network}} for
                <a class="ml-1 me-2 ms-2 text-underline text-hover"
                  (click)="onOpenSpaceEdit(name.space)">{{name.space.name}}</a>
                <span class="ms-2 mb-2 min-w-125px">
                  <a title="Edit"
                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px"
                    (click)="editDelegate(ip, name)">
                    <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                      class="svg-icon svg-icon-3 m-0 svg-icon-warning">
                    </span>
                  </a>
                  <a title="Merge"
                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px"
                    (click)="onMergeIp(ip, name)">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/code-merge.svg'"
                      class="svg-icon svg-icon-3 m-0 svg-icon-primary">
                    </span>
                  </a>
                  <a title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px"
                    (click)="onDeleteDelegate(name)">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                      class="svg-icon svg-icon-3 m-0 svg-icon-danger">
                    </span>
                  </a>
                </span>
              </div>
            </td>
            <td>{{ip.availability | percent:'1.2-2'}} free <a title="Details"
                class="ml-1 me-2 ms-2 text-underline text-hover" (click)="onDetailsGroup(ip)">Details</a> </td>
            <td class="text-center">
              <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                  class="svg svg-icon svg-transparent">
                </span>
              </a>
              <mat-menu #actionMenu="matMenu">
                <button mat-menu-item class="menu-list-block"
                  [title]="(ip?.availability == 0  || !spaceData?.has_descendants) ? 'There are no available subnets to delegate.' : 'Delegate'"
                  [ngClass]="(ip?.availability == 0  || !spaceData?.has_descendants) ? 'a-disable' : ''"
                  [attr.disabled]="(ip?.availability == 0  || !spaceData?.has_descendants) ? '' : null"
                  (click)="(ip?.availability == 0  || !spaceData?.has_descendants) ? '' : addDelegate(ip,false)">
                  <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                      class="svg svg-icon svg-icon-primary">
                    </span>
                  </div>
                  <span>Delegate</span>
                </button>
                <button mat-menu-item class="menu-list-block" title="Edit" (click)="addGroupAll(ip)">
                  <div class="clone-icon">
                    <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                      class="svg svg-icon svg-icon-warning">
                    </span>
                  </div>
                  <span>Edit</span>
                </button>
                <button mat-menu-item class="menu-list-block" title="Merge" (click)="onMerge(ip)">
                  <div class="clone-icon">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/code-merge.svg'"
                      class="svg svg-icon svg-icon-info">
                    </span>
                  </div>
                  <span>Merge</span>
                </button>
                <button mat-menu-item class="menu-list-block" title="Delete" (click)="onDeleteIP(ip)">
                  <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                      class="svg svg-icon svg-icon-danger">
                    </span>
                  </div>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <app-preloader-table-list [length]="3" *ngIf="isIPLoader"></app-preloader-table-list>
      <div *ngIf="ipList.length <= 0 && !isIPLoader" class="text-center font16 mt15 mb15 pt-4">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Sorry! No group allocations.</span>
        </h3>
      </div>
    </div>
  </div>

  <div class="separator separator-solid my-5 mb-0"></div>

  <div class="card-body p-5" *ngIf="spaceData?.level !== 0">
    <div class="card-header d-flex justify-content-between align-items-center pt-0 px-0 border-bottom-0 pb-3">
      <h2 class="mb-0 fs-3"> Delegated From Parent </h2>
    </div>
    <p> The following networks have been assigned to this space by its parent. Subnets can be assigned to bonds or
      further delegated to child spaces of this space.
    </p>
    <div class="table-responsive border">
      <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-1 gx-1 min-w-900">
        <thead class="table-light">
          <tr class="fw-bolder text-dark">
            <th class="min-w-40px fw-bolder py-4">Network</th>
            <th class="min-w-40px fw-bolder py-4">Routing groups</th>
            <th class="min-w-40px fw-bolder py-4">Delegations</th>
            <th class="min-w-40px fw-bolder py-4">Availability </th>
            <th class="min-w-50px w-50px text-center fw-bolder py-4">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="!isDelIPLoader">
          <tr *ngFor="let ip of delIpList; let i=index">
            <td class="ps-5">{{ip.network}} </td>
            <td>
              <span class="badge badge-light-warning" *ngIf="!ip.parent_ip_allocation_routing_groups">
                No assigned routing groups </span>
              <div *ngFor="let name of ip.parent_ip_allocation_routing_groups"> {{name.name}} </div>
            </td>
            <td *ngIf="!ip.delegations"> No assigned routing groups </td>
            <td *ngIf="ip.delegations">
              <div *ngFor="let name of ip.delegations" class="delegateList">{{name.network}} for <a
                  class="ml-1 me-2 ms-2 text-underline text-hover"
                  (click)="onOpenSpaceEdit(name.space)">{{name.space.name}}</a>
                <span class="ms-2 mb-2">
                  <a title="Edit"
                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px"
                    (click)="editDelegate(ip, name)">
                    <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                      class="svg-icon svg-icon-3 m-0 svg-icon-warning">
                    </span>
                  </a>
                  <a title="Merge"
                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px"
                    (click)="onMergeIp(ip, name)">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/code-merge.svg'"
                      class="svg-icon svg-icon-3 m-0 svg-icon-primary">
                    </span>
                  </a>
                  <a title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px"
                    (click)="onDeleteDelegate(name)">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                      class="svg-icon svg-icon-3 m-0 svg-icon-danger">
                    </span>
                  </a>
                </span>
              </div>
            </td>
            <td>{{ip.availability | percent:'1.2-2'}} free <a title="Details"
                class="ml-1 me-2 ms-2 text-underline text-hover" (click)="onDetailsIp(ip)">Details</a>
            </td>
            <td class="text-center">
              <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                  class="svg svg-icon svg-transparent">
                </span>
              </a>
              <mat-menu #actionMenu="matMenu">
                <button mat-menu-item class="menu-list-block"
                  [title]="(ip?.availability == 0  || !spaceData?.has_descendants) ? 'There are no available subnets to delegate.' : 'Delegate'"
                  [ngClass]="(ip?.availability == 0  || !spaceData?.has_descendants) ? 'a-disable' : ''"
                  [attr.disabled]="(ip?.availability == 0  || !spaceData?.has_descendants) ? '' : null"
                  (click)="(ip?.availability == 0  || !spaceData?.has_descendants) ? '' : addDelegate(ip,true)">
                  <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                      class="svg svg-icon svg-icon-primary">
                    </span>
                  </div>
                  <span>Delegate</span>
                </button>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <app-preloader-table-list [length]="3" *ngIf="isDelIPLoader"></app-preloader-table-list>
      <div *ngIf="delIpList.length <= 0 && !isDelIPLoader" class="text-center font16 mt15 mb15 pt-4">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Sorry! No delegated allocations.</span>
        </h3>
      </div>
    </div>
  </div>
</div>