<div class="card mb-5 border-1 border" *ngIf="permissionsObj?.view">
    <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50">
        <div class="card-title m-0">
            <h4 class="fw-bolder m-0 fs-4">Services</h4>
        </div>
        <div class="card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Add" *ngIf="permissionsObj?.update">
            <button type="button" class="btn btn-primary btn-style"
                [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                [ngbPopover]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)? '': addservices">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Add Services
            </button>
            <ng-template #addservices>
                <ul class="p-0 mb-0">
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="onServiceOptionChange(1, '')"> DHCP </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" [ngClass]="dnsservices.length  > 0? 'bg-color':''"
                            (click)="dnsservices.length <= 0? onServiceOptionChange(2, ''): ''"> DNS caching service
                        </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="onServiceOptionChange(3, '')"> DHCPv6-PD </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="onServiceOptionChange(4, '')"> DHCPv6-NA </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="onServiceOptionChange(5, '')"> Router advertisement
                        </span>
                    </li>
                </ul>
            </ng-template>
        </div>
    </div>

    <div class="card-body p-2">
        <div class="row">
            <div class="col-md-12 col-lg-12 mb-8">
                <div *ngIf="permissionsObj?.view" class="table-responsive border">
                    <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-2 mb-0">
                        <thead class="table-light">
                            <tr class="fw-bolder text-dark fs-7">
                                <th class="py-4 min-w-75px w-75px">ID </th>
                                <th class="py-4 min-w-100px w-100px">Enabled </th>
                                <th class="py-4 min-w-300px w-300px"> Connected IP</th>
                                <th class="py-4 min-w-300px w-300px"> Type </th>
                                <th class="py-4">Details </th>
                                <th class="py-4 min-w-50px w-50px text-center">Action </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="allservices.length + dnsservices.length + dHCPV6PDservices.length
                            + dHCPV6NAservices.length + routerAdsservices.length  > 0" class="datatable-body">
                            <tr *ngFor="let services of allservices; " class="main-row">
                                <td>{{ services.id }}</td>
                                <td *ngIf="services.enabled && services?.dhcp != 'disabled'" class="ps-8">
                                    <a title="true">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                            class="svg-icon svg-icon-3">
                                        </span>
                                    </a>
                                </td>
                                <td *ngIf="!services.enabled || services?.dhcp == 'disabled'" class="ps-8">
                                    <a title="false">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                                            class="svg-icon svg-icon-3 svg-icon-danger">
                                        </span>
                                    </a>
                                </td>
                                <td> {{ services.connectedName }} </td>
                                <td> {{services.type}} </td>
                                <td *ngIf="services.dhcp == 'local'">
                                    DHCP {{ services.dhcp_range_start }} - {{ services.dhcp_range_end }}
                                    <span class="d-flex" *ngIf="services.dns_server_1">DNS server 1:
                                        {{services.dns_server_1}}</span>
                                    <span class="d-flex" *ngIf="services.dns_server_2">DNS server 2:
                                        {{services.dns_server_2}}</span>
                                    <span class="d-flex" *ngIf="services.dns_server_3">DNS server 3:
                                        {{services.dns_server_3}}</span>
                                    <span class="d-flex" *ngIf="services.dns == true"> DNS caching </span>
                                    <span class="d-flex" *ngIf="services.dns != true"> </span>
                                </td>
                                <td *ngIf="services.dhcp == 'relay'"> DHCP Relay {{ services.relay_host_1 }}
                                    <span class="d-flex" *ngIf="services.relay_host_2 !== null">
                                        {{ services.relay_host_2 }} </span>
                                    <span class="d-flex" *ngIf="services.relay_host_3 !== null">
                                        {{ services.relay_host_3 }} </span>
                                    <span class="d-flex" *ngIf="services.dns_server_1">DNS server 1:
                                        {{services.dns_server_1}}</span>
                                    <span class="d-flex" *ngIf="services.dns_server_2">DNS server 2:
                                        {{services.dns_server_2}}</span>
                                    <span class="d-flex" *ngIf="services.dns_server_3">DNS server 3:
                                        {{services.dns_server_3}}</span>
                                    <span class="d-flex" *ngIf="services.dns == true"> DNS caching </span>
                                    <span class="d-flex" *ngIf="services.dns != true"> </span>
                                </td>
                                <td *ngIf="services.dhcp == 'disabled'">
                                    <span class="d-flex" *ngIf="services.dns_server_1">DNS server 1:
                                        {{services.dns_server_1}}</span>
                                    <span class="d-flex" *ngIf="services.dns_server_2">DNS server 2:
                                        {{services.dns_server_2}}</span>
                                    <span class="d-flex" *ngIf="services.dns_server_3">DNS server 3:
                                        {{services.dns_server_3}}</span>
                                    <span *ngIf="services.dns == true"> DNS caching </span>
                                </td>
                                <td class="text-center">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false : true" title="Edit"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': onEditService(services)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': onDeleteService(services)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>

                            <tr *ngFor="let dnsservice of dnsservices;" class="main-row">
                                <td>{{ dnsservice.id }}</td>
                                <td *ngIf="dnsservice.enabled" class="ps-8">
                                    <a title="true">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                            class="svg-icon svg-icon-3">
                                        </span>
                                    </a>
                                </td>
                                <td *ngIf="!dnsservice.enabled" class="ps-8">
                                    <a title="false">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                                            class="svg-icon svg-icon-3 svg-icon-danger">
                                        </span>
                                    </a>
                                </td>
                                <td> {{dnsservice.type}} </td>
                                <td> {{ dnsservice.connectedName }} </td>
                                <!-- <td *ngIf="dnsservices.dhcp == 'local'"> DHCP {{ dnsservices.dhcp_range_start }} - {{
                                    dnsservices.dhcp_range_end }}
                                    <span *ngIf="dnsservices.dns == true"> DNS caching </span>
                                    <span *ngIf="dnsservices.dns != true"> </span>
                                </td> -->
                                <td>
                                    {{ dnsservice.dnsServerName ? dnsservice.dnsServerName : 'Use bonder nameservers' }}
                                </td>
                                <td class="text-center">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false : true" title="Edit"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': onEditService(dnsservice)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': onDeleteService(dnsservice)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>

                            <tr *ngFor="let service of dHCPV6PDservices;" class="main-row">
                                <td>{{ service.id }}</td>
                                <td *ngIf="service.enabled" class="ps-8">
                                    <a title="true">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                            class="svg-icon svg-icon-3">
                                        </span>
                                    </a>
                                </td>
                                <td *ngIf="!service.enabled" class="ps-8">
                                    <a title="false">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                                            class="svg-icon svg-icon-3 svg-icon-danger">
                                        </span>
                                    </a>
                                </td>
                                <td> {{ service.connectedName }} </td>
                                <td> {{service.type}} </td>
                                <td> Prefix {{ service.prefix }} <br> Delegation length {{service.delegated_len}} </td>
                                <td class="text-center">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false : true" title="Edit"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': onEditService(service)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': onDeleteService(service)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>

                            <tr *ngFor="let service of dHCPV6NAservices;" class="main-row">
                                <td>{{ service.id }}</td>
                                <td *ngIf="service.enabled" class="ps-8">
                                    <a title="true">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                            class="svg-icon svg-icon-3">
                                        </span>
                                    </a>
                                </td>
                                <td *ngIf="!service.enabled" class="ps-8">
                                    <a title="false">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                                            class="svg-icon svg-icon-3 svg-icon-danger">
                                        </span>
                                    </a>
                                </td>
                                <td> {{ service.connectedName }} </td>
                                <td> {{service.type}} </td>
                                <td> {{ service.dnsServerName }} </td>
                                <td class="text-center">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false : true" title="Edit"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': onEditService(service)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': onDeleteService(service)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>

                            <tr *ngFor="let service of routerAdsservices;" class="main-row">
                                <td>{{ service.id }}</td>
                                <td *ngIf="service.enabled" class="ps-8">
                                    <a title="true">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                            class="svg-icon svg-icon-3">
                                        </span>
                                    </a>
                                </td>
                                <td *ngIf="!service.enabled" class="ps-8">
                                    <a title="false">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                                            class="svg-icon svg-icon-3 svg-icon-danger">
                                        </span>
                                    </a>
                                </td>
                                <td> {{ service.connectedName }} </td>
                                <td> {{service.type}} </td>
                                <td> {{ service.prefix ? service.prefix : 'No prefix' }} </td>
                                <td class="text-center">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false : true" title="Edit"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': onEditService(service)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': onDeleteService(service)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-preloader-table-list *ngIf="sharedService?.Loader?.isDNSServiceLoader 
                        || sharedService?.Loader?.isCacheServiceLoader 
                        || sharedService?.Loader?.isDhcpv6PDServiceLoader 
                        || sharedService?.Loader?.isDhcpv6NAServiceLoader 
                        || sharedService?.Loader?.isRAServiceLoader" [length]="2"></app-preloader-table-list>
                    <div *ngIf="!(sharedService?.Loader?.isDNSServiceLoader 
                        || sharedService?.Loader?.isCacheServiceLoader 
                        || sharedService?.Loader?.isDhcpv6PDServiceLoader 
                        || sharedService?.Loader?.isDhcpv6NAServiceLoader 
                        || sharedService?.Loader?.isRAServiceLoader)
                        && (allservices.length + dnsservices.length + dHCPV6PDservices.length
                        + dHCPV6NAservices.length + routerAdsservices.length  <= 0)"
                        class="text-center font16 mt15 mb15 pt-4">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                        </h3>
                    </div>
                </div>
                <div *ngIf="!permissionsObj?.view">
                    You have no permission to view the data. Kindly contact the authority.
                </div>
            </div>
        </div>
    </div>
</div>