import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-inherited-aggregators',
  templateUrl: './shared-inherited-aggregators.component.html',
  styleUrls: ['./shared-inherited-aggregators.component.scss']
})
export class SharedInheritedAggregatorsComponent implements OnInit {
@Input() inheritedAggregatorList:any;
  constructor() { }

  ngOnInit(): void {
    console.log("=================inherited",this.inheritedAggregatorList)
  }

}
