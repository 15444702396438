<div class="modal-header px-5 py-3">
    <h5 class="modal-title" id="exampleModalLabel">Set bond space</h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
    <div class="card mb-5">
        <div class="card-body p-0">
            <div class="row" *ngIf="errors">
                <div class="col-md-12">
                    <div class="alert alert-danger">Bond cannot be moved to {{selectedSpace?.name}}:</div>
                </div>
                <div class="col-md-12" *ngFor="let err of errors | keyvalue">
                    <div class="alert alert-danger">{{err.value}}</div>
                </div>
            </div>
            <div class="form-group row mb-5" *ngIf="!isConfig">
                <span>The {{count}} selected bonds will be moved to space choose below.</span>
            </div>
            <div class="form-group row mb-5" *ngIf="isConfig">
                <span>Select the space below to move this bond to. If you move it to a child of its current space,
                    subnets for its connected IPs, CPE NAT IPs, and routes will be delegated from the current space to
                    the child space.
                </span>
            </div>
            <app-recursive-space [bondDetails]="bondDetails" [selectedOption]="selectedOption"></app-recursive-space>
        </div>
        <!-- <div class="card-footer">
            <button type="submit" (click)="onSubmit()" class="btn btn-info">Save & Next</button>
            <button type="cancel" (click)="onBackCalled()" class="btn btn-secondary">Cancel</button>
        </div> -->
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>