<div class="modal-header px-5 py-3">
  <h5 class="modal-title">{{isEdit? 'Edit': 'Add'}} Route </h5>
  <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
  <div class="card mb-5 mb-xl-0">
    <form [formGroup]="routeEdit">
      <div class="card-body p-0">
        <div class="row" *ngIf="errors && errors['non_field_errors']">
          <div class="col-md-12">
            <div class="alert alert-danger">{{errors['non_field_errors']}}</div>
          </div>
        </div>
        <div class="row" *ngIf="errors && !errors['non_field_errors']">
          <div class="col-md-12">
            <div class="alert alert-danger">Please correct the errors below.</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-lg-4 mb-1">
            <div class="form-group inputtext-block mb-5">
              <label class="form-label">Network <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="The network to route, in CIDR format."></span> </label>
              <div class="d-flex flex-column">
                <input formControlName="network" name="network" type="text"
                  class="form-control form-control-lg form-control-solid" name="Network" placeholder=""
                  autocomplete="off" />
                <small class="form-invalid-message">
                  <span class="text-danger">
                    {{errors?.network?.join(' ')}}
                  </span>
                  <span *ngIf="submitted==true && f.network.invalid && f.network.errors?.required" class="text-danger">
                    This field is required.
                  </span>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 mb-1">
            <div class="form-group inputtext-block mb-5">
              <label class="form-label">Gateway <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Route network through this address."></span></label>
              <div class="d-flex flex-column">
                <input formControlName="gateway" name="gateway" type="text"
                  class="form-control form-control-lg form-control-solid" name="Gateway" placeholder=""
                  autocomplete="off" />
                <small class="form-invalid-message">
                  <span class="text-danger">
                    {{errors?.gateway?.join(' ')}}
                  </span>
                  <span *ngIf="submitted==true && f.gateway.invalid && f.gateway.errors?.required" class="text-danger">
                    This field is required.
                  </span>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 mb-1">
            <div class="form-group inputtext-block mb-5">
              <label class="form-label">Interface <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Route network to this interface. "></span></label>
              <div class="d-flex flex-column">
                <ng-select formControlName="interface" name="interface" class="dropdown-block"
                  placeholder="Select Interface">
                  <ng-option value=""> Auto </ng-option>
                  <ng-option *ngFor="let item of InterfaceDetailArray" [value]="item.interface_url">
                    {{item.ifname}} </ng-option>
                </ng-select>
                <small class="form-invalid-message">
                  <span class="text-danger">
                    {{errors?.interface?.join(' ')}}
                  </span>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 mb-1">
            <div class="form-group inputtext-block">
              <label class="form-label"> Aggregator routing <span
                  [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="The aggregator will publish the route only if it is non-private"></span></label>
              <div class="d-flex flex-column">
                <ng-select formControlName="aggroute" name="aggroute" class="dropdown-block" [clearable]="false"
                  [searchable]="false">
                  <ng-option value="auto"> Automatic </ng-option>
                  <ng-option value="never"> Never </ng-option>
                  <ng-option value="always"> Always </ng-option>
                </ng-select>
                <small class="form-invalid-message">
                  <span class="text-danger">
                    {{errors?.aggregator_routing?.join(' ')}}
                  </span>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-lg-2 mb-1">
            <div class="form-group inputtext-block">
              <label class="form-label"> Enabled <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Enable the route."></span></label>
              <div class="d-flex flex-column mt-2">
                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="enabled" name="enabled">
                </ui-switch>
                <small class="text-danger">
                  {{errors?.enabled?.join(' ')}}
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-lg-2 mb-xl-10">
            <div class="form-group inputtext-block">
              <label class="form-label">Include in private WAN <span
                  [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Publish the route in private WAN."></span></label>
              <div class="d-flex flex-column mt-2">
                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="pvtwan" name="pvtwan">
                </ui-switch>
                <small class="text-danger">
                  {{errors?.pvtwan?.join(' ')}}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer px-5 py-3">
  <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
    <div>
      <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
      <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
    </div> {{isEdit ? 'Save': 'Add'}}
  </button>
  <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
    <div>
      <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
    </div> Cancel
  </button>
</div>