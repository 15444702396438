import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.scss']
})
export class AlertHistoryComponent implements OnInit {
  alertHistoryArr: any = [];
  bondId: any
  preLoaderScreen: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.preLoaderScreen = true;
    this.alertService.getAlertHistory(this.bondId)?.subscribe(res => {
      if (res?.data) this.alertHistoryArr = res.data;
      this.sharedService.hideLoader();
      this.preLoaderScreen = false;
    }, (err) => {
      this.sharedService.hideLoader();
      this.preLoaderScreen = false;
    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

}
