import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {SharedCreateAggregatorComponent} from './shared-create-aggregator/shared-create-aggregator.component'
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-shared-aggregators',
  templateUrl: './shared-aggregators.component.html',
  styleUrls: ['./shared-aggregators.component.scss']
})
export class SharedAggregatorsComponent implements OnInit {
  @Input() aggregatorList:any[];
  constructor(private modalService: NgbModal, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log("============================>",this.aggregatorList)
    this.searchInput.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data: any) => {
        if (data != null || data != undefined) {
          this.searchKey = data;
          this.onSearch.emit(data);
        }
      });

      this.aggregatorList = this.aggregatorList.map(x => {
        x.isSelected = false;
        return x;
      })
  }
  // SharedCreateAggregatorComponent
  addAggregator(){
    console.log("add aggregator")
    let modal = this.modalService.open(SharedCreateAggregatorComponent,
      { size: 'lg' });

    
  }

  searchInput: FormControl = new FormControl();
  searchKey: any = null;

  @Output() onReset = new EventEmitter<any>();
  @Output() onSearch = new EventEmitter<any>();

  onResetSearch() {
    this.searchInput.patchValue('');
    this.onReset.emit();
  }

  ngOnChanges(){
  }

  isCheckAll: boolean = false;
  selectedItemList: any[] = [];
  checkUncheckAll(evt: any){
    this.aggregatorList = this.aggregatorList.map(x => {
      x.isSelected = this.isCheckAll;
      return x;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.selectedItemList = this.aggregatorList.filter(x => x.isSelected)
    if(this.selectedItemList.length == this.aggregatorList.length)
      this.isCheckAll = true;
    else
      this.isCheckAll = false;
  }

  isAllSelected() {
    this.getCheckedItemList();
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchInput.value) {
      event.preventDefault();
    }
  }
}
