<div class="card mb-5 mb-xl-8">
    <div class="card-body p-2">
        <div class="table-responsive border">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-1 mb-0">
                <thead class="align-middle table-light">
                    <tr class="fw-bolder text-dark fs-7">
                        <th class="py-4" (click)="onSort('createdAt')"> ID
                            <span *ngIf="sortBy == 'createdAt' && $any(usersList?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="py-4" (click)="onSort('fname')"> First Name
                            <span *ngIf="sortBy == 'fname' && $any(usersList?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="py-4" (click)="onSort('lname')"> Last Name
                            <span *ngIf="sortBy == 'lname' && $any(usersList?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="py-4" (click)="onSort('email')"> Email Address
                            <span *ngIf="sortBy == 'email' && $any(usersList?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="py-4"> Organizations </th>
                        <th class="py-4"> Groups </th>
                        <th class="py-4 min-w-50px w-50px text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                <tbody *ngIf="!preLoaderScreen && length > 0">
                    <tr *ngFor="let user of usersList">
                        <td> <a> {{user._id}}</a> </td>
                        <td> <a> {{user.fname}}</a> </td>
                        <td> <a> {{user.lname}}</a> </td>
                        <td> <a> {{user.email}}</a> </td>
                        <td> <span>{{defaultOrgName}}</span> </td>
                        <td *ngIf="user.groupIds.length">
                            <span class="d-block" *ngFor="let group of user.groupIds">{{group?.name}}</span>
                        </td>
                        <td *ngIf="!user.groupIds.length">
                            <span>-</span>
                        </td>
                        <td class="text-center">
                            <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                    class="svg svg-icon svg-transparent">
                                </span>
                            </a>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item class="menu-list-block" title="Edit" *ngIf="permissionsObj.update"
                                    (click)="onEdit(user._id)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                            class="svg svg-icon svg-icon-warning">
                                        </span>
                                    </div>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Suspend"
                                    *ngIf="permissionsObj.delete" (click)="onSuspend(user._id)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/design/ban.svg'"
                                            class="svg svg-icon svg-icon-danger">
                                        </span>
                                    </div>
                                    <span>Suspend</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Reset Password"
                                    (click)="onResetPassword(user.email,user._id)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/Lock-overturning.svg'"
                                            class="svg svg-icon svg-icon-primary">
                                        </span>
                                    </div>
                                    <span>Reset Password</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Login as a Partner"
                                    title="Login as User" *ngIf="(user.groupIds.length > 0)  && role!= 2"
                                    (click)="onLoginAsUser(user)">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen057.svg'"
                                            class="svg svg-icon svg-icon-info">
                                        </span>
                                    </div>
                                    <span>User Login</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-preloader-table-list *ngIf="preLoaderScreen"></app-preloader-table-list>
            <div *ngIf="!preLoaderScreen && length == 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
            </div>
        </div>
        <app-pagination *ngIf="length > 0" [childMessage]="length" [index]="page" [pagesize]="limit"
            (messageEvent)="receiveMessage($event)">
        </app-pagination>
    </div>
</div>