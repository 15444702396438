<div class="card border-2 border border-top-0">
    <div class="card-body p-5 pb-0">
        <!-- <div class="row mb-7">
        <div class="col-lg-12">
            <div class="row mb-7"><label class="col-lg-4 fw-bold text-muted">Overall status </label><div class="col-lg-8">
                <a href="#" class="fw-bolder fs-6 text-dark">
                    Problems detected: Management VPN not connected.</a></div></div>

            <div class="row mb-7"><label class="col-lg-4 fw-bold text-muted">Traffic</label><div class="col-lg-8"><span class="fw-bolder fs-6 text-dark">921 bps</span><span class="fw-bolder fs-6 text-dark">0 bps</span></div></div>

            <div class="row mb-7"><label class="col-lg-4 fw-bold text-muted">Software version</label><div class="col-lg-8"><span class="fw-bolder fs-6 text-dark">6.6.39 </span></div></div>

            <div class="row mb-7"><label class="col-lg-4 fw-bold text-muted">Tunnel MTU</label><div class="col-lg-8"><span class="fw-bolder fs-6 text-dark">1396</span></div></div>

            <div class="row mb-7"><label class="col-lg-4 fw-bold text-muted">Downtime prevented</label><div class="col-lg-8">
                <div> <span class="fw-bolder fs-6 text-dark">Last week</span><span class="fw-bolder fs-6 text-dark">: 168 hours, 7 minutes <br> 7 outages </span> </div>
                <div> <span class="fw-bolder fs-6 text-dark">Last month</span><span class="fw-bolder fs-6 text-dark">: 744 hours, 33 minutes <br> 32 outages</span> </div>
            </div></div>

            <div class="row mb-7"><label class="col-lg-4 fw-bold text-muted">Tunnel started</label><div class="col-lg-8"><span class="fw-bolder fs-6 text-dark d-block">Bonder: 5 months ago</span><span class="fw-bolder fs-6 text-dark">Aggregator: 5 months ago            </span></div></div>

        </div>

        </div> -->

        <div class="row mb-7">
        <div class="col-lg-12 mb-5">
            <h3 class="fs-4">Pending updates </h3>
            <p class="text-dark fs-7"> No updates are currently pending on this node. </p>
        </div>
        <div class="col-lg-12">
            <h3 class="fs-4"> Completed updates </h3>
            <p class="text-dark fs-7"> Start bond test on aggregator 1 (4 months ago):id: 413, speed: None, length: 10.0, protocol: tcp, concurrency: 1, payload_size: 1000 </p>
            <p class="text-dark fs-7"> Start bond test on aggregator 1 (4 months ago):id: 412, speed: 0.1, length: 10.0, protocol: udp, concurrency: 1, payload_size: 10 </p>
        </div>
        </div>
    </div>
</div>
