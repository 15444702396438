import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PartnerBondViewComponent } from 'src/app/modules/partner/bonds/partner-bond-view/partner-bond-view.component';
import { AuthService } from 'src/app/modules/shared/auth';
import { CtsService } from 'src/app/services/cts.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { SharedNetworkCpeAddComponent } from './shared-network-cpe-add/shared-network-cpe-add.component';

const RHS_HOST = environment.rhsHost;

@Component({
  selector: 'app-shared-network-cpe',
  templateUrl: './shared-network-cpe.component.html',
  styleUrls: ['./shared-network-cpe.component.scss'],
})
export class SharedNetworkCpeComponent implements OnInit {
  bondPermission: any;
  latest_tuning: any;
  // cts functionality
  CPETunnelForm: FormGroup;
  cpeTunnelData: any = null;
  tunnelList: any = [];
  targetDateTime: any;
  intervalId: any;
  bondMac: any;
  tunnelExpiry: any = { hour: 0, minutes: 0, seconds: 0 };
  expiryOptionList: any[] = [
    { name: "5 Minutes", value: 5 },
    { name: "30 Minutes", value: 30 },
    { name: "60 Minutes", value: 60 },
    { name: "8 Hours", value: 480 },
  ];
  ctsPermission: boolean = false;
  cpeTunnelEditLoader: boolean = false;
  cpeTunnelDeleteLoader: boolean = false;
  isTunnelError: boolean = false;

  @Input() allCpe: any[] = [];
  @Input() bondId: any;
  @Input() bondData: any;
  @Input() permissionsObj: any;

  @Output() getCpe = new EventEmitter<any>();

  @ViewChild('cpeTunnelPopupButton') cpeTunnelPopupButton: any;

  constructor(private cd: ChangeDetectorRef, private storageService: StorageService,
    private PartnerBondingService: PartnerBondingService,
    private partnerBondViewComponent: PartnerBondViewComponent,
    private ctsService: CtsService, private fb: FormBuilder,
    public sharedService: SharedService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.PartnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    })
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
    // this.PartnerBondingService.bond_eth0_mac$.subscribe((data) => {
    //   this.bondMac = data;
    //   this.cd.detectChanges();
    // })
    // this.getCtsPermission();
    // this.ctsService.tunnelListDataByBond$.subscribe((data) => {
    //   this.tunnelList = data;
    //   this.tunnelList = this.tunnelList.filter((tunnel: any) => tunnel.type == AppConst.ctsType.cpeTunnel);
    //   this.tunnelList.forEach((tunnel: any) => {
    //     if (tunnel.expireSession) {
    //       const startTime = moment(tunnel.expireSession);
    //       const currentTime = moment();
    //       const diff = moment.duration(startTime.diff(currentTime));
    //       if (diff.asMinutes() <= 0) {
    //         this.deleteTunnel(tunnel.cpeId);
    //       }
    //     }
    //   })
    //   this.cd.detectChanges();
    // })
    // this.CPETunnelForm = this.createCPETunnelForm();
  }

  deleteCpe(data: any) {
    let questionTitle = 'Are you sure you want to delete this ip?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    let reqObject = {
      method: "DELETE",
      url: `bonds/${this.bondId}/cpe_nat_ips/${data.id}/`,
      "data": {},
      type: "CPE NAT IP",
      bondName: this.storageService.getCipherObj('bondName'),
      bondId: this.bondId,
      ip: data?.ip,
      id: data?.id,
      enabled: data?.enabled,
    };
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.PartnerBondingService.deleteData(reqObject).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getCpe.emit();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getCpe.emit();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  editCpe(data: any) {
    let modal = this.modalService.open(SharedNetworkCpeAddComponent, { size: 'lg' });
    if (data) {
      modal.componentInstance.bondsInfo = data;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.componentInstance.bondId = this.bondId;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getCpe.emit();
      this.cd.detectChanges();
    }, () => { });
  }

  getCtsPermission() {
    let moduleList = this.storageService.getCipherObj('Module_Access');
    if (moduleList && moduleList.length > 0) {
      moduleList.forEach((item: any) => {
        if (item && item.name == AppConst.moduleList.ctsModule) this.ctsPermission = true;
      });
    }
  }

  createCPETunnelForm(data: any = {}) {
    return this.fb.group({
      ip: [data.ip || "", Validators.required],
      port: [data.port || "", Validators.required],
      expiry: [5, Validators.required],
    });
  }

  getTunnelForCPE(cpeId: any) {
    let isFound = false;
    this.tunnelList.forEach((tunnel: any) => {
      if (tunnel.cpeId == cpeId && tunnel.type == AppConst.ctsType.cpeTunnel && tunnel.body.serverPort) isFound = true;
    });
    return isFound;
  }

  openTunnelPopup(cpe: any) {
    this.cpeTunnelDeleteLoader = false;
    this.cpeTunnelEditLoader = false;
    this.CPETunnelForm.controls.ip.setValue("");
    this.CPETunnelForm.controls.port.setValue("");
    this.CPETunnelForm.controls.expiry.setValue(5);
    this.cpeTunnelData = null;
    this.tunnelList.forEach((tunnel: any) => {
      if (tunnel.cpeId == cpe.id) {
        this.cpeTunnelData = tunnel;
        this.initializeTimer(tunnel.expireSession, cpe);
        this.CPETunnelForm.controls.ip.setValue(tunnel.body.ip);
        this.CPETunnelForm.controls.port.setValue(tunnel.body.port);
      }
    });
    if (!this.cpeTunnelData) this.CPETunnelForm.controls.ip.setValue(cpe.dest_nat_ip);
  }

  initializeTimer(startDateTime: string, cpe: any): void {
    const startTime = moment(startDateTime);
    const currentTime = moment();
    const diff = moment.duration(startTime.diff(currentTime));
    if (diff.asMinutes() <= 0) {
      this.deleteTunnel(cpe.id);
      this.cpeTunnelPopupButton.close();
      this.tunnelExpiry = { hour: 0, minutes: 0, seconds: 0 };
      return;
    }
    this.targetDateTime = currentTime.add(diff);
    this.updateTimer(cpe);
    this.intervalId = setInterval(() => this.updateTimer(cpe), 1000);
  }

  updateTimer(cpe: any): void {
    const currentTime = moment();
    const duration = moment.duration(this.targetDateTime.diff(currentTime));
    if (duration.asMilliseconds() <= 0) {
      clearInterval(this.intervalId);
      this.deleteTunnel(cpe.id);
      this.cpeTunnelPopupButton.close();
      this.tunnelExpiry = { hour: 0, minutes: 0, seconds: 0 };
    } else {
      this.tunnelExpiry = {
        hour: Math.floor(duration.asHours()) < 10 ? `0${Math.floor(duration.asHours())}` : Math.floor(duration.asHours()),
        minutes: Math.floor(duration.minutes()) < 10 ? `0${Math.floor(duration.minutes())}` : Math.floor(duration.minutes()),
        seconds: Math.floor(duration.seconds()) < 10 ? `0${Math.floor(duration.seconds())}` : Math.floor(duration.seconds()),
      };
    }
    this.cd.detectChanges();
  }

  openConnection(cpeId: any) {
    if (!cpeId) return;
    this.tunnelList.forEach((tunnel: any) => {
      if (tunnel.cpeId == cpeId && tunnel.type == AppConst.ctsType.cpeTunnel && tunnel.body.serverPort) {
        window.open(`https://${RHS_HOST}:${tunnel.body.serverPort}`, '_blank');
      }
    });
  }

  createUpdateTunnel(data: any) {
    if (!this.bondMac) {
      this.sharedService.loggerError('Unable to detect Mac Address');
      return;
    }
    if (this.CPETunnelForm.invalid) {
      this.isTunnelError = true;
      return;
    } this.isTunnelError = false;
    let expiryDate = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + this.CPETunnelForm.controls.expiry.value);
    let reqObject = {
      ip: this.CPETunnelForm.controls.ip.value,
      port: this.CPETunnelForm.controls.port.value,
      expire: expiryDate,
      cpeId: data?.id,
      bondName: this.storageService.getCipherObj('bondName'),
      bondId: this.bondId,
      nodeKey: this.bondData?.bonder?.key,
      type: AppConst.ctsType.cpeTunnel,
      mac: this.bondMac,
    };
    this.cpeTunnelEditLoader = true;
    this.ctsService.createUpdateTunnel(reqObject).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.sharedService.loggerSuccess(addRes.message);
        this.partnerBondViewComponent.getRHSTunnelList();
        this.cpeTunnelPopupButton.close();
      } else this.sharedService.loggerError(addRes.message);
      this.cpeTunnelEditLoader = true;
      this.cd.detectChanges();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.cpeTunnelEditLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.cpeTunnelEditLoader = false;
        this.cd.detectChanges();
      }
    });
  }

  deleteTunnel(cpeId: any) {
    if (!cpeId) return;
    let reqObject = {
      cpeId: cpeId,
      bondId: this.bondId,
      type: AppConst.ctsType.cpeTunnel,
    };
    this.cpeTunnelDeleteLoader = true;
    this.ctsService.deleteTunnel(reqObject).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.partnerBondViewComponent.getRHSTunnelList();
        this.sharedService.loggerSuccess(addRes.message);
        this.cpeTunnelPopupButton.close();
      } else this.sharedService.loggerError(addRes.message);
      this.cpeTunnelDeleteLoader = true;
      this.cd.detectChanges();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.cpeTunnelDeleteLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.cpeTunnelDeleteLoader = false;
        this.cd.detectChanges();
      }
    });
  }
}
