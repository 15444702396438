import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'app-shared-space-ip-add',
  templateUrl: './shared-space-ip-add.component.html',
  styleUrls: ['./shared-space-ip-add.component.scss']
})
export class SharedSpaceIpAddComponent implements OnInit {
  errors: any;
  spaceData: any;
  isEdit: boolean = false;
  groupList: any = [];
  groupAllocationData: any;
  GroupAllocationAdd: FormGroup;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private spaceService: PartnerSpaceService) { }

  ngOnInit(): void {
    this.groupList = this.spaceData?.routing_groups;
    if (this.isEdit) this.GroupAllocationAdd = this.createForm(this.groupAllocationData);
    else this.GroupAllocationAdd = this.createForm();
  }

  createForm(data: any = {}) {
    let urlList: any[] = []
    if (data && data.assigned_routing_groups_list && data.assigned_routing_groups_list.length > 0) {
      data.assigned_routing_groups_list.forEach((item: any) => {
        if (item && item.routing_group) urlList.push(item.routing_group);
      });
    }
    return new FormGroup({
      network: new FormControl(data.network || ''),
      routing_group: new FormControl(urlList),
    })
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onSave() {
    let body = { network: this.GroupAllocationAdd.value.network || null }
    let reqObj = {
      "url": `spaces/${this.spaceData.key}/ip_allocations/`,
      "method": "POST",
      "data": body
    }
    if (this.isEdit) {
      reqObj = {
        "url": `spaces/${this.spaceData.key}/ip_allocations/${this.groupAllocationData.id}/`,
        "method": "PATCH",
        "data": body
      }
    }
    this.sharedService.showLoader()
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) {
        this.sharedService.loggerSuccess('group allocation saved successfully.');
        if (this.GroupAllocationAdd.value.routing_group && this.GroupAllocationAdd.value.routing_group.length > 0) {
          this.GroupAllocationAdd.value.routing_group.forEach((url: any) => {
            let reqObj = {
              "url": `spaces/${this.spaceData.key}/ip_allocations/${addRes.data.id}/assigned_routing_groups/`,
              "method": "POST",
              "data": { routing_group: url }
            }
            this.addRoutingGroup(reqObj);
          });
        }
      }
      else this.sharedService.loggerError(addRes.message);
      if (this.GroupAllocationAdd.value.routing_group.length <= 0) this.activeModal.close({ event: 'save' });
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addRoutingGroup(body: any) {
    this.sharedService.showLoader()
    this.spaceService.generalSpaceApi(body).subscribe((addRes) => {
      this.activeModal.close({ event: 'save' });
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }
}
