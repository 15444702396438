import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { PartnerModel } from '../models/partner.model';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl;
@Injectable({ providedIn: 'root' })
export class PartnerManagmentService {
    onSearchClicked: BehaviorSubject<any> = new BehaviorSubject(null);
    mainId = this.storageService.getCipherObj('mainId');

    constructor(private http: HttpClient, private storageService: StorageService) { }

    get onSearchClicked$() {
        return this.onSearchClicked.asObservable();
    }

    getAllPartners(search: string,
        status: string,
        page: string,
        limit: any,
        name: string,
        sort: any,
        partnerPermission?: any
    ) {
        let params;
        if (status == 'false') {
            params = new HttpParams()
                .set('fname', search ? search : '')
                .set('page', page)
                .set('pageSize', limit)
                .set('status', status ? status : '')
                .set('name', name ? name : 'createdAt')
                .set('sort', sort ? sort : -1)
        } else {
            params = new HttpParams()
                .set('fname', search ? search : '')
                .set('page', page)
                .set('pageSize', limit)
                .set('status', status ? status : '')
                .set('name', name ? name : 'createdAt')
                .set('sort', sort ? sort : -1)
            if (partnerPermission != undefined || partnerPermission != null) {
                params.set('isLP', partnerPermission)
            }
        }
        return this.http.get(BACKEND_URL + '/partner/list', { params })
    }

    createPartner(data: any) {
        return this.http.post<any>(BACKEND_URL + '/partner/add', data);
    }

    updatePartner(data: any, partnerId: string) {
        return this.http.put<PartnerModel>(BACKEND_URL + `/partner/update/${partnerId}`, data);
    }

    updatePageSize(pageSize: number) {
        return this.http.put<PartnerModel>(BACKEND_URL + `/admin/updatePartnerPageSize/${pageSize ?? 10}`, {});
    }

    resetPartnerPasswordByAdmin(partnerId: any) {
        return this.http.post(`${BACKEND_URL}/partner/updatePasswordLink`, { partnerId });
    }

    resetPasswordForUser(data: any) {
        this.mainId = this.storageService.getCipherObj('mainId');
        if (!this.mainId) return;
        return this.http.post(`${BACKEND_URL}/partner/updatePasswordLinkForUser/${this.mainId}`, data);
    }

    getPartnerDataWithId(partnerId: string) {
        return this.http.get<any>(BACKEND_URL + `/partner/view/${partnerId}`);
    }

    deletePartner(id: string) {
        return this.http.put(BACKEND_URL + `/partner/delete/${id}`, id);
    }

    disablePartner(id: string) {
        return this.http.put(BACKEND_URL + `/partner/disable/${id}`, id);
    }

    getPartnerReport(id: string) {
        return this.http.get(BACKEND_URL + `/partner/getReport/${id}`);
    }

    getAllPartnerReport() {
        return this.http.get(BACKEND_URL + `/partner/getReport`);
    }

    loginAsAPartner(mainId: any) {
        return this.http.post(BACKEND_URL + `/partnerPanel/getPartnerLP`, mainId);
    }

    revokePermission() {
        this.mainId = this.storageService.getCipherObj('mainId');
        if (!this.mainId) return;
        return this.http.put(BACKEND_URL + `/partnerPanel/revokePartnerLP/${this.mainId}`, {});
    }

    loginAsUser(obj: any) {
        this.mainId = this.storageService.getCipherObj('mainId') || '';
        return this.http.post(BACKEND_URL + `/partnerPanel/usersPanel/getUserLP/${this.mainId}`, obj);
    }

}
