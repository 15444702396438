import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondNetworkDetailsService } from 'src/app/services/partner-bond-network-details.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'app-shared-network-connected-ip-edit',
  templateUrl: './shared-network-connected-ip-edit.component.html',
  styleUrls: ['./shared-network-connected-ip-edit.component.scss']
})
export class SharedNetworkConnectedIpEditComponent implements OnInit {
  ipDetail: any;
  IpEdit: FormGroup;
  InterfaceDetailArray: any;
  bondId: any;
  errors: any;
  isEdit: any;
  submitted: any = false;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService, private storageService: StorageService,
    private cd: ChangeDetectorRef,
    private partnerBondingService: PartnerBondingService,
    private partnerBondNetworkService: PartnerBondNetworkDetailsService,) { }

  ngOnInit(): void {
    // this.getSuccess()
    if (this.isEdit) this.IpEdit = this.createForm(this.ipDetail);
    else this.IpEdit = this.createForm();
  }

  createForm(data: any = {}) {
    let interfaceID = '';
    this.InterfaceDetailArray.forEach((item: any) => {
      if (item && item.interface_url == data.interface) interfaceID = item.id
    });
    return new FormGroup({
      interface: new FormControl(interfaceID || '', [Validators.required]),
      ip: new FormControl(data.ip || ''),
      enabled: new FormControl(data.enabled == false ? false : true),
      use_ipv6_link_local_address: new FormControl(data.use_ipv6_link_local_address || false),
      include_private_wan: new FormControl(data.include_private_wan || false),
      aggregator_routing: new FormControl(data.aggregator_routing || 'auto'),
      note: new FormControl(data.note || '', [this.sharedService.xssValidator, Validators.maxLength(200)])
    });
  }

  get f() {
    return this.IpEdit.controls;
  }

  getSuccess() {
    this.partnerBondingService.successForConnectedIp$.subscribe((res: any) => {
      if (res && res.success) this.activeModal.close();
    });
  }

  onSave() {
    let apiURL = this.storageService.getCipherObj('api_url');
    if (!apiURL) return;
    this.submitted = true;
    if (this.IpEdit.invalid) return;
    let interfaceURL = '';
    this.InterfaceDetailArray.forEach((item: any) => {
      if (item && item.id == this.IpEdit.value.interface) interfaceURL = item.interface_url
    });
    let body = {
      interface: interfaceURL,
      ip: this.IpEdit.value.ip,
      enabled: this.IpEdit.value.enabled,
      use_ipv6_link_local_address: this.IpEdit.value.use_ipv6_link_local_address,
      include_private_wan: this.IpEdit.value.include_private_wan,
      aggregator_routing: this.IpEdit.value.aggregator_routing,
      note: this.IpEdit.value.note,
      bond: `${apiURL}bonds/${this.bondId}/`,
      bondName: this.storageService.getCipherObj('bondName'),
      bondId: this.bondId,
      type: 'Connected IP'
    }
    if (this.isEdit) {
      let IpObject = {
        method: "PATCH",
        url: `bonds/${this.bondId}/connected_ips/${this.ipDetail.id}/`,
        data: body
      }
      this.sharedService.showLoader();
      this.partnerBondingService.addData(IpObject).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) {
          this.partnerBondingService.successForConnectedIp.next({ success: true })
          this.sharedService.loggerSuccess(addRes.message);
        } else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.partnerBondingService.successForConnectedIp.next({ success: false })
          Object.keys(this.errors).forEach(x => {
            let IPEditControl = this.IpEdit.get(x);
            if (IPEditControl) this.IpEdit.setErrors({ ...this.IpEdit.errors, [x]: this.errors[x] });
          })
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.sharedService.hideLoader();
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    } else {
      let url = `bonds/${this.bondId}/connected_ips/`;
      this.sharedService.showLoader()
      this.partnerBondNetworkService.addReq(url, 'POST', body).subscribe((res: any) => {
        if (res.code == 201 || res.code == 200) {
          this.partnerBondingService.successForConnectedIp.next({ success: true })
          this.sharedService.loggerSuccess(res.message);
        } else this.sharedService.loggerError(res.message)
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.partnerBondingService.successForConnectedIp.next({ success: false })
          Object.keys(this.errors).forEach(x => {
            let IPEditControl = this.IpEdit.get(x);
            if (IPEditControl) this.IpEdit.setErrors({ ...this.IpEdit.errors, [x]: this.errors[x] });
          })
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
