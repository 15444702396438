import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-version-modal',
  templateUrl: './version-modal.component.html',
  styleUrls: ['./version-modal.component.scss']
})
export class VersionModalComponent implements OnInit {
  versionData: any;
  selectedData: any;
  pageSize = 1;
  page = 1;
  childMessage: number = 0;
  isChecked: any = false;
  isPreview: any = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.versionData && this.versionData?.featureUpdates?.length > 0) {
      this.selectedData = this.versionData?.featureUpdates[0]
      this.childMessage = this.versionData?.featureUpdates.length;
    }
    this.cd.detectChanges();
  }

  onClose() {
    this.activeModal.close({ event: 'close', isChecked: this.isChecked });
  }

  getNext(event: any) {
    this.page = event;
    this.selectedData = this.versionData.featureUpdates[this.page - 1];
  }
}
