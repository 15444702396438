import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shared-create-aggregator',
  templateUrl: './shared-create-aggregator.component.html',
  styleUrls: ['./shared-create-aggregator.component.scss']
})
export class SharedCreateAggregatorComponent implements OnInit {

  constructor(public mModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onClose(){
    // this.mModal.close({event:'close',selectedColumns:this.selectedItems, availableColumns:this.availableColumnsArray});
    this.mModal.close({event: 'close'});
  }

}
