<div class="modal-header px-5 py-3">
    <h5 class="modal-title">Node Provisioning</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body p-5">
    <form class="col-md-8 col-lg-8 col-xl-8" [formGroup]="nodeForm">
        <div class="form-group inputtext-block mb-5">
            <label class="form-label">MAC Address</label>&nbsp;<span class="required-asterisk m-0">*</span>
            <input type="text" class="form-control form-control-lg form-control-solid" name="mac"
                placeholder="00:00:00:00:00:00" autocomplete="off" formControlName="mac"
                (input)="onMacAddressInput($event)" />
            <small class="form-invalid-message">
                <span class="text-danger" *ngIf="controlHasError('required', 'mac')">
                    MAC Address is required
                </span>
                <span class="text-danger" *ngIf="!controlHasError('required', 'mac') 
                    && controlHasError('pattern', 'mac')">
                    invalid MAC Address
                </span>
            </small>
        </div>
    </form>
</div>
<div class="card-footer px-5 py-3 d-flex justify-content-between align-items-center">
    <div style="width: 370px;">
        <span class="fw-bold fs-7">MAC must be in lowercase (a-f, 0-9).
        </span>
    </div>
    <div>
        <button type="button" class="btn btn-primary btn-style" [disabled]="nodeForm.invalid"
            (click)="nodeForm.invalid ? '' :onSave()">
            <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                    class="svg-icon svg-icon-block mb-2"></span>
            </div> Save
        </button>
    </div>
</div>