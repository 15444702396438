<div class="card mb-5 border-1 border border-top-0" *ngIf=" permissionsObj?.view">
  <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50">
    <div class="card-title m-0">
      <h4 class="fw-bolder m-0 fs-4">Protocols</h4>
    </div>
    <div class="card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
      data-bs-placement="top" data-bs-trigger="hover" title="Add" *ngIf="permissionsObj?.update">
      <button type="button" class="btn btn-primary btn-style"
        [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
        (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? '': editProtocol('')">
        <div>
          <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
          </span>
        </div> Add Protocols
      </button>
    </div>
  </div>

  <div class="card-body p-2">
    <div class="row">
      <div class="col-md-12 col-lg-12 mt-0">
        <div *ngIf="permissionsObj?.view" class="table-responsive border">
          <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
            <thead class="table-light">
              <tr class="fw-bolder text-dark fs-7">
                <th class="py-4 min-w-75px w-75px"> ID </th>
                <th class="py-4 min-w-150px "> Name </th>
                <th class="py-4 min-w-100px">Enabled</th>
                <th class="py-4 min-w-150px">Protocol</th>
                <th class="py-4 min-w-50px w-50px text-center"> Actions </th>
              </tr>
            </thead>
            <tbody *ngIf="!(sharedService?.Loader?.isProtocolLoader) && allprotocol.length > 0" class="datatable-body">
              <tr *ngFor="let protocol of allprotocol;" class="main-row">
                <td class="ps-5"> {{ protocol.id }} </td>
                <td class="charlimit"> {{ protocol.name }} </td>
                <td *ngIf="!protocol.private_wan && protocol.enabled">
                  <a title="true">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'" class="svg-icon svg-icon-3">
                    </span>
                  </a>
                </td>
                <td *ngIf="!protocol.enabled">
                  <a title="false">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                      class="svg-icon svg-icon-3 svg-icon-danger">
                    </span>
                  </a>
                </td>
                <td *ngIf="protocol.private_wan && protocol.enabled">
                  <a title="false" class="d-block">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                      class="svg-icon svg-icon-3 svg-icon-danger">
                    </span>
                  </a>
                  <small *ngIf="protocol.administratively_disabled_reason">
                    {{protocol.administratively_disabled_reason}}
                  </small>
                </td>
                <td> {{ protocol.protocol }} </td>
                <td class="text-center">
                  <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                    *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                      class="svg svg-icon svg-transparent">
                    </span>
                  </a>
                  <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                      class="svg svg-icon svg-transparent">
                    </span>
                  </a>
                  <mat-menu #actionMenu="matMenu">
                    <button mat-menu-item class="menu-list-block" title="Edit"
                      [disabled]="permissionsObj.update ? false : true" title="Edit"
                      [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                      (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': editProtocol(protocol)">
                      <div class="clone-icon">
                        <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                          class="svg svg-icon svg-icon-warning">
                        </span>
                      </div>
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item class="menu-list-block" title="Delete"
                      [disabled]="permissionsObj.delete ? false : true" title="Delete"
                      [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                      (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': deleteProtocol(protocol,protocol?.name,protocol.protocol)">
                      <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                          class="svg svg-icon svg-icon-danger">
                        </span>
                      </div>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
          <app-preloader-table-list *ngIf="sharedService?.Loader?.isProtocolLoader"
            [length]="3"></app-preloader-table-list>
          <div *ngIf="!(sharedService?.Loader?.isProtocolLoader) && allprotocol.length == 0"
            class="text-center font16 mt15 mb15 pt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
            </h3>
          </div>
        </div>
        <div *ngIf="!permissionsObj?.view">
          You have no permission to view the data. Kindly contact the authority.
        </div>
      </div>
    </div>
  </div>
</div>