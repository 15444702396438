import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-cts-access-modal',
    templateUrl: './cts-access-modal.component.html',
    styleUrls: ['./cts-access-modal.component.scss']
})
export class CtsAccessModalComponent implements OnInit {
    totalList: any[] = [];
    firewallList: any[] = []
    selectedList: any;

    errors: any;

    constructor(private cd: ChangeDetectorRef,
        public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
        for (let item of this.firewallList) {
            let find = this.selectedList?.find((x: any) => x == item);
            if (find) this.totalList.push({ name: item, access: true });
            else this.totalList.push({ name: item, access: false });
        }
    }

    onSave() {
        let finalList: any[] = [];
        this.totalList.forEach(x => x.access ? finalList.push(x.name) : null);
        this.activeModal.close({ event: 'save', firewallList: finalList });
    }

    onClose() {
        this.activeModal.close({ event: 'close' });
    }
}