import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/auth';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-org-management-list',
  templateUrl: './shared-org-management-list.component.html',
  styleUrls: ['./shared-org-management-list.component.scss']
})
export class SharedOrgManagementListComponent implements OnInit {
  authService = AuthService;

  @Input() organizationList: any[] = [];
  @Input() length: any;
  @Input() page: number;
  @Input() limit: number;
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() permissionsObj: any;
  @Input() preLoaderScreen: boolean = false;

  @Output() receiveMessageEmitter = new EventEmitter<any>();
  @Output() onSortFields = new EventEmitter<any>();
  @Output() view = new EventEmitter<any>();
  @Output() suspend = new EventEmitter<any>();
  @Output() loginAsUser = new EventEmitter<any>();
  @Output() resetPassword = new EventEmitter<any>();

  constructor(private router: Router, private sharedService: SharedService) { }

  ngOnInit(): void { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  onAddOrganization() {
    this.router.navigate(['/partner/user-org-management/organization-management/create'])
  }

  onEdit(item: any) {
    if (!item._id) return;
    this.router.navigate([`/partner/user-org-management/organization-management/edit/${item._id}`]);
  }

  onSort(event: any) {
    this.onSortFields.emit(event);
  }

  onSuspend(id: any) {
    if (!id) return;
    let questionTitle = 'Are you sure?';
    let text = "<b>You are suspending this organization."
    let confirmButtonText = "Yes, Suspend it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.suspend.emit(id);
      }
    });
  }

  receiveMessage(data: any) {
    this.receiveMessageEmitter.emit(data);
  }

  onLoginAsUser(user: any) {
    if (user) this.loginAsUser.emit(user);
  }

  onResetPassword(email: any, _id: any) {
    if (!email) return;
    this.resetPassword.emit({ email: email, _id: _id });
  }

}
