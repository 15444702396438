import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondNetworkDetailsService } from 'src/app/services/partner-bond-network-details.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'app-shared-network-routes-edit',
  templateUrl: './shared-network-routes-edit.component.html',
  styleUrls: ['./shared-network-routes-edit.component.scss']
})
export class SharedNetworkRoutesEditComponent implements OnInit {
  routeEdit: FormGroup;
  InterfaceDetailArray: any;
  routeDetail: any;
  bondId: any;
  bonderId: any;
  isEdit: any;
  errors: any;
  submitted: any = false;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService, private storageService: StorageService,
    private cd: ChangeDetectorRef,
    private partnerBondNetworkService: PartnerBondNetworkDetailsService,
    private partnerBondingService: PartnerBondingService) { }

  ngOnInit(): void {
    // this.getSuccess();
    if (this.isEdit) this.routeEdit = this.createForm(this.routeDetail);
    else this.routeEdit = this.createForm();
    this.cd.detectChanges()
  }

  get f() {
    return this.routeEdit.controls;
  }

  createForm(data: any = {}) {
    let apiURL = this.storageService.getCipherObj('api_url');
    return new FormGroup({
      interface: new FormControl(data.interface || ''), // != 'null' ? new FormControl(`${apiURL}bonders/${this.bonderId}/interfaces/${this.routeDetail.interface}/`) : new FormControl(null),
      network: new FormControl(data.network || '', [Validators.required]),
      gateway: new FormControl(data.gateway || '', [Validators.required]),
      enabled: new FormControl(data.enabled == false ? false : true),
      // "aggregator_routing":this.configurationForm.value.enabledroute !== true ? false : true,
      aggroute: new FormControl(data.aggregator_routing || 'auto'),
      pvtwan: new FormControl(data.include_private_wan || false),
      bond: new FormControl(`${apiURL}bonds/${this.bondId}/`),
      ifname: new FormControl(data.ifname || ''),
    })
  }

  onSave() {
    this.submitted = true;
    if (this.routeEdit.invalid) return;
    let apiURL = this.storageService.getCipherObj('api_url');
    if (!apiURL) return;

    let ifname;
    if (this.routeEdit.value.interface == '' || this.routeEdit.value.interface == null) {
      ifname = "Auto";
    } else {
      let arr = this.InterfaceDetailArray.find((detailArr: any) => detailArr.interface_url == this.routeEdit.value.interface);
      ifname = arr.ifname;
    }
    let body = {
      interface: this.routeEdit.value.interface,
      network: this.routeEdit.value.network,
      gateway: this.routeEdit.value.gateway,
      enabled: this.routeEdit.value.enabled !== true ? false : true,
      // "aggregator_routing":this.configurationForm.value.enabledroute !== true ? false : true,
      aggregator_routing: this.routeEdit.value.aggroute,
      include_private_wan: this.routeEdit.value.pvtwan !== true ? false : true,
      bond: `${apiURL}bonds/${this.bondId}/`,
      bondName: this.storageService.getCipherObj('bondName'),
      bondId: this.bondId,
      type: 'Routes',
      ifname: ifname
    }
    if (this.isEdit) {
      let IpObject = {
        method: "PATCH",
        url: `bonds/${this.bondId}/routes/${this.routeDetail.id}/`,
        data: body
      }
      this.sharedService.showLoader();
      this.partnerBondingService.addData(IpObject).subscribe((addRes) => {
        if (addRes.code == 200) {
          this.partnerBondingService.successForRoutes.next({ success: true })
          this.sharedService.loggerSuccess(addRes.message);
        } else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' })
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.partnerBondingService.successForRoutes.next({ success: false });
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          Object.keys(this.errors).forEach(x => {
            let RoutesEditControl = this.routeEdit.get(x);
            if (RoutesEditControl) this.routeEdit.setErrors({ ...this.routeEdit.errors, [x]: this.errors[x] });
          })
          this.sharedService.hideLoader();
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    } else {
      let url = `${apiURL}bonds/${this.bondId}/routes/`
      this.sharedService.showLoader()
      this.partnerBondNetworkService.addReq(url, 'POST', body).subscribe(
        (res: any) => {
          if (res.code == 201) {
            this.partnerBondingService.successForRoutes.next({ success: true })
            this.sharedService.loggerSuccess(res.message);
          } else this.sharedService.loggerError(res.message);
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          this.activeModal.close({ event: 'save' });
        }, (err) => {
          try {
            this.errors = JSON.parse(err);
            this.partnerBondingService.successForRoutes.next({ success: false });
            if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
            else this.sharedService.loggerError('Please correct the errors.');
            Object.keys(this.errors).forEach(x => {
              let RoutesEditControl = this.routeEdit.get(x);
              if (RoutesEditControl) this.routeEdit.setErrors({ ...this.routeEdit.errors, [x]: this.errors[x] });
            })
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
    }
  }

  getSuccess() {
    this.partnerBondingService.successForInterface$.subscribe((res: any) => {
      if (res && res.success) this.activeModal.close();
    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
