<div *ngIf="permissionsObj.view" class="card mb-5 mb-xl-8">
    <div
        class="card-header d-flex flex-wrap flex-stack px-3 py-1 min-h-50 justify-content-end align-items-center gap-5">
        <div></div>
        <div class="fw-bolder div-position col-md-2">
            <div class="form-group inputtext-block">
                <input type="text" class="form-control form-control-lg" [formControl]="searchControl" name="searchText"
                    placeholder="Search" (keydown)="preventSpace($event)" value="" />
                <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
                    class="svg-icon svg-icon-1 me-0 span-input"></span>
            </div>
        </div>
        <div *ngIf="permissionsObj.update" class="d-flex flex-wrap">
            <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
                data-bs-placement="top" data-bs-trigger="hover" title="Click to Create Template">
                <button (click)="onAddCustomEmailTemplate()" type="button" class="btn btn-primary btn-style">
                    <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                            class="svg-icon svg-icon-block mb-2">
                        </span>
                    </div> Create Custom Email Template
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-2">
        <div class="table-responsive border">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                <thead class="align-middle table-light">
                    <tr class="fw-bolder text-dark fs-7">
                        <th class="py-4 cursor-pointer" (click)="onSort('templateName')">
                            Template Name / Subject
                            <span *ngIf="sortBy == 'templateName'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-150px cursor-pointer" (click)="onSort('templateType')">
                            Template Type
                            <span *ngIf="sortBy == 'templateType'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-150px cursor-pointer" (click)="onSort('isActivated')">
                            Status
                            <span *ngIf="sortBy == 'isActivated'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-150px cursor-pointer" (click)="onSort('createdAt')">
                            Created At
                            <span *ngIf="sortBy == 'createdAt'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-150px cursor-pointer" (click)="onSort('updatedAt')">
                            Last Updated At
                            <span *ngIf="sortBy == 'updatedAt'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="min-w-50px w-50px text-center">Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="!preLoaderScreen && customEmailTemplateLength > 0">
                    <tr *ngFor="let customTemplate of customEmailTemplates">
                        <td class="text-dark fs-6">
                            {{customTemplate.titleName}}
                        </td>
                        <td class="text-dark fs-6">
                            {{getTemplateTypeValue(customTemplate.templateType)}}
                        </td>
                        <td class="text-dark fs-6" *ngIf="customTemplate.isActive == true">
                            <span [inlineSVG]="'./assets/media/icons/duotune/art/tick-mark-icon.svg'"
                                class="svg-icon svg-icon-3 svg-icon-warning">
                            </span>
                        </td>
                        <td class="text-dark fs-6" *ngIf="!customTemplate.isActive == true">
                            <span [inlineSVG]="'./assets/media/icons/duotune/art/close-icon.svg'"
                                class="svg-icon svg-icon-3 svg-icon-danger">
                            </span>
                        </td>
                        <td class="text-dark fs-6">
                            {{customTemplate.createdAt | date}}
                        </td>
                        <td>
                            {{customTemplate.updatedAt | date}}
                        </td>
                        <td class="text-center">
                            <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                    class="svg svg-icon svg-transparent">
                                </span>
                            </a>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item class="menu-list-block" title="Edit"
                                    [disabled]="permissionsObj.update ? false : true"
                                    (click)="permissionsObj.update ? onEditCustomTemplate(customTemplate._id) : ''">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                            class="svg svg-icon svg-icon-warning">
                                        </span>
                                    </div>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Delete"
                                    [disabled]="permissionsObj.delete ? false : true"
                                    (click)="permissionsObj.delete ? onDeleteCustomTemplate(customTemplate._id) : ''">
                                    <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                            class="svg svg-icon svg-icon-danger">
                                        </span>
                                    </div>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-preloader-table-list *ngIf="preLoaderScreen"></app-preloader-table-list>
            <div *ngIf="!preLoaderScreen && customEmailTemplateLength == 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
            </div>
        </div>
        <app-pagination *ngIf="customEmailTemplateLength > 0" [childMessage]="customEmailTemplateLength" [index]="page"
            [pagesize]="limit" (messageEvent)="receiveMessage($event)">
        </app-pagination>
    </div>
</div>

<div *ngIf="!permissionsObj.view">
    <span>You have no permission to view the data. Kindly contact the authority.</span>
</div>