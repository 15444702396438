<div class="modal-header px-5 py-3">
    <h5 class="modal-title" id="exampleModalLabel">Show / change password</h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
    <div class="card">
        <form [formGroup]="authForm">
            <div class="card-body pb-0">
                <div class="row" *ngIf="errors && errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors['non_field_errors']}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <span>This form shows the username and password saved for this node. Changing this field updates
                        only the management server database; it does not update the password on the node.</span><br>
                    <span>To update the password on the node, start a console session and run the passwd command.</span>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Username</label>
                            <input formControlName="username" name="username" type="text"
                                class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger"> {{errors?.username?.join(' ')}} </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Password</label>
                            <input formControlName="password" name="password" type="text"
                                class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger"> {{errors?.password?.join(' ')}} </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>