<div *ngIf="permissionsObj.view" class="card mb-5 mb-xl-10">
    <div class="card-body p-5">
        <div class="row g-5 g-xxl-8">
            <!-- <h3> Two Factor Auth </h3> -->
            <form action="#" class="form form-label-right" [formGroup]="BondApi" *ngIf="BondApi">
                <div class="row">
                    <!-- Username -->
                    <div class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <label class="form-label">Username</label>
                        <input type="text" class="form-control form-control-lg form-control-solid"
                            formControlName="email" name="email" placeholder="polcode@sd-wan.net.au"
                            autocomplete="off" />
                    </div>
                    <!-- Password -->
                    <div class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <label class="form-label">Password</label>
                        <div class="password-input-block">
                            <input class="form-control form-control-lg form-control-solid" autocomplete="off"
                                type="password" placeholder="Password" formControlName="pass" name="pass" />
                            <!--<i [class]="show_eye ? 'fa fa-eye password_hide_show' : 'fa fa-eye-slash password_hide_show'"
                                (click)="showPassword()"></i>-->
                        </div>
                    </div>

                    <!-- Username -->
                    <div class="col-md-12 col-lg-6 col-xl-4 mb-5">
                        <label class="form-label">URL</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" formControlName="url"
                            name="url" autocomplete="off" />
                    </div>

                    <div class="col-md-4 col-lg-6 col-xl-4 mb-5">
                        <div class="d-flex align-items-center">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" name="use_cache" id=""> <label
                                    class="mb-0 form-label ms-2"> Use Cache </label>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
    <div class="card-footer p-5">
        <!-- Save -->
        <button *ngIf="permissionsObj.update" type="submit" class="btn btn-primary" (click)="onSave()">Save
            changes</button>
        <button *ngIf="permissionsObj.update" type="cancel" class="btn btn-primary" (click)="onTest()">Test
            Connection</button>
        <button *ngIf="permissionsObj.update" type="button" class="btn btn-primary">Refresh
            Cache</button>
    </div>
</div>

<div *ngIf="!permissionsObj?.view">
    You have no permission to view the data. Kindly contact the authority.
</div>
