import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import Swal from 'sweetalert2';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private sharedService: SharedService,
        private authenticationService: AuthService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError(err => {

            this.sharedService.hideLoader();
            const error = err;
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                this.sharedService.loggerError('Token Expired! Login Again');
            }
            // else if(request.headers.has('noerror')){
            //     return throwError(err?.error?.message);
            // }
            // else {
            //     let urlArray = SharedService.urlExtemptWhenError;
            //     // console.log(urlArray);

            //     let isUrlExists = urlArray.map((x: any) => request.url.includes(x))
            //     // console.log(isUrlExists);

            //     if (!isUrlExists.includes(true)) {
            //         this.sharedService.loggerError(err?.error?.message);
            //     }
            // }

            //console.log('err: ', err.error);
            return throwError(err?.error?.message);
        }))
    }
}
