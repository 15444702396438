import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedSpaceIpAddComponent } from './shared-space-ip-add/shared-space-ip-add.component';
import { SharedSpaceIpDelegateModalComponent } from './shared-space-ip-delegate-modal/shared-space-ip-delegate-modal.component';
import { SharedSpaceIpDelegateComponent } from './shared-space-ip-delegate/shared-space-ip-delegate.component';

@Component({
  selector: 'app-shared-space-ip',
  templateUrl: './shared-space-ip.component.html',
  styleUrls: ['./shared-space-ip.component.scss']
})
export class SharedSpaceIpComponent implements OnInit {
  spaceList: any;

  @Input() ipList: any;
  @Input() delIpList: any;
  @Input() parentId: string;
  @Input() spaceData: any;
  @Input() isEdit: boolean;
  @Input() isIPLoader: boolean = false;
  @Input() isDelIPLoader: boolean = false;

  @Output() getIpList = new EventEmitter<any>();
  @Output() getDelIpList = new EventEmitter<any>();

  constructor(public sharedService: SharedService,
    private modalService: NgbModal, private storageService: StorageService,
    private cd: ChangeDetectorRef, private spaceService: PartnerSpaceService,
    private router: Router) { }

  ngOnInit(): void {
    this.getParentSpaceList();
  }

  getParentSpaceList() {
    let reqObj = { "url": `spaces/?parent=${this.parentId}`, "method": "GET" }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes?.code == 200) {
        this.spaceList = addRes.data
        this.cd.detectChanges();
      }
    })
  }

  addGroupAll(data: any) {
    let modal = this.modalService.open(SharedSpaceIpAddComponent, { size: 'lg' });
    if (data) {
      modal.componentInstance.groupAllocationData = data;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.componentInstance.spaceData = this.spaceData;
    modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.getIpList.emit();
        this.getDelIpList.emit();
      }
    }, () => { });
  }

  onDeleteIP(data: any) {
    let reqObj = {
      "method": "DELETE",
      "url": `spaces/${data?.space?.key}/ip_allocations/${data.id}/`,
    }
    let questionTitle = 'Delete allocation';
    let text = `Are you sure you wish to delete allocation ${data.network}?`;
    let confirmButtonText = "Yes, Delete it!";
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.spaceService.deleteData(reqObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getIpList.emit();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getIpList.emit();
            this.cd.detectChanges();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  onMerge(data: any) {
    let modal = this.modalService.open(SharedSpaceIpDelegateComponent, { size: 'md' });
    modal.componentInstance.merge = true;
    modal.componentInstance.detailUrl = data.possible_merge;
    modal.componentInstance.parentId = this.parentId;
  }

  onMergeIp(data: any, delegations: any) {
    let modal = this.modalService.open(SharedSpaceIpDelegateComponent, { size: 'md' });
    modal.componentInstance.merge = true;
    modal.componentInstance.detail = data;
    modal.componentInstance.detailUrl = delegations.url;
    modal.componentInstance.parentId = this.parentId;
  }

  addDelegate(data: any, isDelegate: boolean) {
    let modal = this.modalService.open(SharedSpaceIpDelegateModalComponent, { size: 'md' });
    modal.componentInstance.spaceData = data;
    modal.componentInstance.isEdit = false;
    modal.componentInstance.parentId = this.parentId;
    modal.componentInstance.spaceList = this.spaceList;
    modal.componentInstance.isDelegate = isDelegate;
    modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.getIpList.emit();
        this.getDelIpList.emit();
      }
    }, () => { });
  }

  editDelegate(data: any, delegations: any) {
    let modal = this.modalService.open(SharedSpaceIpDelegateModalComponent, { size: 'md' });
    modal.componentInstance.spaceData = data;
    modal.componentInstance.parentId = this.parentId;
    modal.componentInstance.spaceList = this.spaceList;
    modal.componentInstance.delegateData = delegations;
    modal.componentInstance.isEdit = true;
    modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.getIpList.emit();
        this.getDelIpList.emit();
      }
    }, () => { });
  }

  onDeleteDelegate(data: any) {
    let reqObj = {
      "url": `spaces/${data?.space?.key}/delegated_ip_allocations/${data.id}/`,
      "method": "DELETE"
    }
    let questionTitle = 'Delete delegation';
    let text = `Are you sure you wish to delete delegation ${data.network}?`;
    let confirmButtonText = "Yes, Delete it!";
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.spaceService.deleteData(reqObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getIpList.emit();
          this.getDelIpList.emit();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getIpList.emit();
            this.getDelIpList.emit();
            this.cd.detectChanges();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  onDetailsGroup(data: any) {
    let modal = this.modalService.open(SharedSpaceIpDelegateComponent,
      { size: 'md' });
    modal.componentInstance.detailsgroup = true;
    modal.componentInstance.ipDetail = data;
    modal.componentInstance.detailUrl = this.ipList[0].availability_list;
  }

  onDetailsIp(data: any) {
    let modal = this.modalService.open(SharedSpaceIpDelegateComponent,
      { size: 'md' });
    modal.componentInstance.details = true;
    modal.componentInstance.ipDetail = data;
    modal.componentInstance.detailUrl = data.availability_list;
  }

  onOpenSpaceEdit(space: any) {
    this.spaceService.setSpaceParentId(space.key);
    this.spaceService.setSpaceData(space);
    this.storageService.setCipherText('space', space.key);
    this.cd.detectChanges();
    this.router.navigate([`/partner/space/edit`]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }
}
