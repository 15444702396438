<div class="modal-header p-5">
    <h5 class="modal-title" id="addcpe">Tune bond settings</h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
    <p>Fusion SD-WAN will run a series of tests to determine the optimal configuration and leg speeds for this bond.
        When the process is complete, you will be notified by email and can accept or decline the recommended settings.
        Customer traffic will be interrupted a number of times during the tuning procedure.</p>
    <p>Ensure the bond QoS and tunnel security options are set as needed before running the tuning procedure.</p>
    <form [formGroup]="tuneForm">
        <h5>Preferences</h5>
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Bonded throughput
                                <span class="required-asterisk m-0">*</span>
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Percent of total leg throughput achieved by bond to consider tuning
                                    successful"></span>
                            </label>
                            <input formControlName="bonded_throughput_requirement" type="number"
                                name="bonded_throughput_requirement" autocomplete="off"
                                class="form-control form-control-lg form-control-solid" />
                            <small class="text-danger" *ngIf="errors">
                                {{errors['bonded_throughput_requirement']?.join('')}}
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Test stability<span class="required-asterisk m-0">*</span>
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Required level of throughput stability for leg tests"></span>
                            </label>
                            <div class="d-flex flex-column">
                                <div class="d-flex">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="testType"
                                            name="type" value="0.77" id="High" (change)="changeType($event)">
                                        <label class="m-2 form-label" for="High"> High </label>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="testType"
                                            name="type" value="0.72" id="Medium" (change)="changeType($event)">
                                        <label class="m-2 form-label" for="Medium"> Medium </label>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="testType"
                                            value="0.67" name="type" id="Low" (change)="changeType($event)">
                                        <label class="m-2 form-label" for="Low"> Low </label>
                                    </div>
                                </div>
                                <small class="text-danger" *ngIf="errors">
                                    {{errors['throughput_stability_requirement']?.join(' ')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-1">
                        <label class="form-label">Test length<span class="required-asterisk m-0">*</span>
                            <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                ngbTooltip="Length of speed tests, in seconds "></span>
                        </label>
                        <div class="form-group inputtext-block mb-5">
                            <input formControlName="speedtest_length" type="number" name="speedtest_length"
                                autocomplete="off" class="form-control form-control-lg form-control-solid" />
                            <small class="text-danger" *ngIf="errors">
                                {{errors['speedtest_length']?.join(' ')}}
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 mb-3">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="accept_recommendations_automatically"
                                type="checkbox" name="accept_recommendations_automatically"
                                id="accept_recommendations_automatically">
                            <label class="m-2 form-label" for="accept_recommendations_automatically">
                                Accept recommended settings automatically </label>
                        </div>
                        <small class="text-danger" *ngIf="errors">
                            {{errors['accept_recommendations_automatically']?.join(' ')}}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <h5 class="mb-3">Schedule</h5>
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-md-12 col-lg-12 mb-1">
                        <label class="form-label">When should the tuning procedure take place?</label>
                        <div class="d-flex flex-column">
                            <div class="d-flex">
                                <div class="d-flex align-items-center justify-content-center">
                                    <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="scheduleType"
                                        name="schedule" value="immediate" id="immediate"
                                        (change)="changeSchedule($event)">
                                    <label class="m-2 form-label" for="immediate"> Right now </label>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="scheduleType"
                                        name="schedule" value="online" id="online" (change)="changeSchedule($event)">
                                    <label class="m-2 form-label" for="online"> When all active legs are online </label>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="scheduleType"
                                        name="schedule" value="timer" id="timer" (change)="changeSchedule($event)">
                                    <label class="m-2 form-label" for="timer"> At this time: </label>
                                    <ng-select [searchable]="false" [clearable]="false" formControlName="schedule_at"
                                        name="schedule_at" class="dropdown-block w-140">
                                        <ng-option *ngFor="let item of scheduleList;" [value]="item">
                                            {{item | date: 'dd-MMM, h:mm a'}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <small class="text-danger" *ngIf="errors">
                                {{errors['schedule']?.join(' ')}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" [disabled]="tuneForm.invalid"
        (click)="tuneForm.invalid ? '' : onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>