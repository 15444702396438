import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColorEvent } from 'ngx-color';
import * as customEditor from 'src/app/ckeditor-build/build/ckeditor';
import { BrandingService } from 'src/app/services/branding.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst, coreMailType, emailType } from 'src/app/shared/constants/app.constant'; //src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-custom-shared-email-template-form',
  templateUrl: './custom-shared-email-template-form.component.html',
  styleUrls: ['./custom-shared-email-template-form.component.scss']
})
export class CustomSharedEmailTemplateFormComponent implements OnInit {
  createCustomEmailTemplateForm: FormGroup;
  editorConfig = {
    toolbar: {
      items: [
        'heading', '|', 'alignment:left', "alignment:right", "alignment:center",
        "alignment:justify", 'fontsize', 'fontfamily', 'fontcolor', 'fontbackgroundcolor',
        '|', "blockQuote", "bold", 'italic', "selectAll", "undo", "redo", "|",
        "htmlEmbed", "link", "mediaEmbed", "|", "insertImage",
        "imageStyle:inline"
        , "imageStyle:alignLeft"
        , "imageStyle:alignRight"
        , "imageStyle:alignCenter"
        , "imageStyle:alignBlockLeft"
        , "imageStyle:alignBlockRight"
        , "imageStyle:block"
        , "imageStyle:side"
        , "imageStyle:wrapText"
        , "imageStyle:breakText", "|",
        "indent", "outdent", "|",
        "numberedList", "bulletedList", "|",
        "sourceEditing", "|",
        "insertTable", "tableColumn", "tableRow", "mergeTableCells"
      ],
      shouldNotGroupWhenFull: true
    },
    language: 'en',
  }

  @Output() onSave = new EventEmitter<any>();
  @Input() emailTemplate: any;
  @Input() templateTypeArr: any;


  // Set Editor to ClassicEditor with given functionality

  dataOnReset: string = '';
  basicEditor: any;
  emailPreview: string = '';
  editor = customEditor;
  currentEditor = 'header';
  coreMailType = coreMailType;
  emailType = emailType;
  coreMailTypeArray: any[] = [];
  emailTypeArray: any[] = [];
  isCoreSelected: boolean = true;
  foundationArray = AppConst.foundationList;
  NameFieldValidation: any = AppConst.NameFieldPattern;
  isNew: boolean;
  sharedS = SharedService;
  isDisabled: boolean;
  headerBackgroundColour: any = '#9b9b9b';
  bodyBackgroundColour: any = '#ffffff';
  footerBackgroundColour: any = '#9b9b9b';
  headerTextColour: any = '#000000';
  bodyTextColour: any = '#000000';
  footerTextColour: any = '#000000';
  urlValidator: any = AppConst.urlValidationPattern;


  constructor(private router: Router, private fb: FormBuilder,
    private formErrorHandler: FormErrorService,
    private cd: ChangeDetectorRef, private storageService: StorageService,
    private brandingService: BrandingService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    if (this.emailTemplate) {
      this.isNew = false;
      this.createCustomEmailTemplateForm = this.createNewForm(this.emailTemplate);
      this.headerBackgroundColour = this.createCustomEmailTemplateForm.value.headerBackgroundColour;
      this.bodyBackgroundColour = this.createCustomEmailTemplateForm.value.bodyBackgroundColour;
      this.footerBackgroundColour = this.createCustomEmailTemplateForm.value.footerBackgroundColour;
      this.headerTextColour = this.createCustomEmailTemplateForm.value.headerTextColour;
      this.bodyTextColour = this.createCustomEmailTemplateForm.value.bodyTextColour;
      this.footerTextColour = this.createCustomEmailTemplateForm.value.footerTextColour;
    } else {
      this.isNew = true;
      this.createCustomEmailTemplateForm = this.createNewForm();
    }
    this.cd.detectChanges();
  }

  ngOnChanges() { }

  createNewForm(formValues: any = {}) {
    return this.fb.group({
      titleName: [formValues?.titleName || '', [Validators.required, Validators.pattern(this.NameFieldValidation)]],
      templateType: [formValues?.templateType || '', Validators.compose([Validators.required])],
      headerBackgroundColour: [formValues?.headerBackgroundColour || this.headerBackgroundColour],
      bodyBackgroundColour: [formValues?.bodyBackgroundColour || this.bodyBackgroundColour],
      footerBackgroundColour: [formValues?.footerBackgroundColour || this.footerBackgroundColour],
      headerTextColour: [formValues?.headerTextColour || this.headerTextColour],
      bodyTextColour: [formValues?.bodyTextColour || this.bodyTextColour],
      footerTextColour: [formValues?.footerTextColour || this.footerTextColour],
      styleBody: [formValues?.styleBody || ''],
      isActive: [formValues.isActive == true ? true : false],
      facebook_link: [formValues?.facebook_link || '', Validators.compose([
        Validators.pattern(this.urlValidator),
        Validators.maxLength(2000)
      ])],
      twitter_link: [formValues?.twitter_link || '', Validators.compose([
        Validators.maxLength(2000),
        Validators.pattern(this.urlValidator)
      ])],
      instagram_link: [formValues?.instagram_link || '', Validators.compose([
        Validators.maxLength(2000),
        Validators.pattern(this.urlValidator)
      ])],
      website_link: [formValues?.website_link || '', Validators.compose([
        Validators.maxLength(2000),
        Validators.pattern(this.urlValidator)
      ])],
    });
  }

  public onReady(editor: any) {
    this.basicEditor = editor;
  }

  onBackClicked() {

    if (Number(this.storageService.getCipherObj('role')) == AppConst.admin) {
      this.router.navigate(['/admin/configuration/email-management'], { queryParams: { selectedTab: '2' } })
    }
    else if (Number(this.storageService.getCipherObj('role')) == AppConst.partner || Number(this.storageService.getCipherObj('role')) == AppConst.user) {
      this.router.navigate(['/partner/configuration/email-management'], { queryParams: { selectedTab: '2' } })
    }
  }

  onCancel() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.admin) {
      this.router.navigate(['/admin/configuration/email-management'], { queryParams: { selectedTab: '2' } })
    }
    else if (Number(this.storageService.getCipherObj('role')) == AppConst.partner || Number(this.storageService.getCipherObj('role')) == AppConst.user) {
      this.router.navigate(['/partner/configuration/email-management'], { queryParams: { selectedTab: '2' } })
    }
  }

  onSubmit() {
    if (this.createCustomEmailTemplateForm.invalid) {
      this.createCustomEmailTemplateForm.markAllAsTouched();
      return;
    }
    this.sharedService.showLoader();
    this.onSave.emit({ value: this.createCustomEmailTemplateForm.value, isNew: this.isNew })
  }

  isControlValid(controlName: string): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.createCustomEmailTemplateForm);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.createCustomEmailTemplateForm);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.createCustomEmailTemplateForm);
  }

  isControlTouched(controlName: string): boolean {
    return this.formErrorHandler.isControlTouched(controlName, this.createCustomEmailTemplateForm);
  }

  rgba2hex(colorcode: any) {
    let rgb: any;
    let alpha: any;
    let hex: any;
    let a: any, isPercent: any;
    rgb = `rgba(${colorcode.r},${colorcode.g},${colorcode.b},${colorcode.a})`;

    //var a, isPercent,
    rgb = rgb.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = (rgb && rgb[4] || "").trim(),
      hex = rgb ?
        (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : rgb;

    if (alpha !== "") {
      a = alpha;
    } else {
      a = 1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | 1 << 8).toString(16).slice(1)
    // hex = '#' + hex + a;
    hex = '#' + hex;

    return hex;
  }

  setMyStyles(color: any) {
    //  this.cd.detectChanges();
    let styles = {
      'background-color': color
    };
    return styles;
  }

  headerBackGroundChangeComplete(event: ColorEvent) {
    this.headerBackgroundColour = this.rgba2hex(event.color.rgb);
    this.createCustomEmailTemplateForm.value.headerBackgroundColour = this.headerBackgroundColour;
    this.cd.detectChanges();
  }

  bodyBackGroundChangeComplete(event: ColorEvent) {
    this.bodyBackgroundColour = this.rgba2hex(event.color.rgb);
    this.createCustomEmailTemplateForm.value.bodyBackgroundColour = this.bodyBackgroundColour;
    this.cd.detectChanges();
  }

  footerBackGroundChangeComplete(event: ColorEvent) {
    this.footerBackgroundColour = this.rgba2hex(event.color.rgb);
    this.createCustomEmailTemplateForm.value.footerBackgroundColour = this.footerBackgroundColour;
    this.cd.detectChanges();
  }

  headerTextChangeComplete(event: ColorEvent) {
    this.headerTextColour = this.rgba2hex(event.color.rgb);
    this.createCustomEmailTemplateForm.value.headerTextColour = this.headerTextColour;
    this.cd.detectChanges();
  }

  bodyTextChangeComplete(event: ColorEvent) {
    this.bodyTextColour = this.rgba2hex(event.color.rgb);
    this.createCustomEmailTemplateForm.value.bodyTextColour = this.bodyTextColour;
    this.cd.detectChanges();
  }

  footerTextChangeComplete(event: ColorEvent) {
    this.footerTextColour = this.rgba2hex(event.color.rgb);
    this.createCustomEmailTemplateForm.value.footerTextColour = this.footerTextColour;
    this.cd.detectChanges();
  }

  getDefault() {
    let body = {
      "templateType": this.createCustomEmailTemplateForm.value.templateType
    }
    this.brandingService.getDefaultCustomTemplate(body)?.subscribe((res: any) => {
      if (res.code == 200 && res.data) {
        this.createCustomEmailTemplateForm.patchValue(res.data);
        this.headerBackgroundColour = this.createCustomEmailTemplateForm.value?.headerBackgroundColour || this.headerBackgroundColour;
        this.bodyBackgroundColour = this.createCustomEmailTemplateForm.value?.bodyBackgroundColour || this.bodyBackgroundColour;;
        this.footerBackgroundColour = this.createCustomEmailTemplateForm.value?.footerBackgroundColour || this.footerBackgroundColour;
        this.headerTextColour = this.createCustomEmailTemplateForm.value?.headerTextColour || this.headerTextColour;
        this.bodyTextColour = this.createCustomEmailTemplateForm.value?.bodyTextColour || this.bodyTextColour;
        this.footerTextColour = this.createCustomEmailTemplateForm.value?.footerTextColour || this.footerTextColour;
        this.cd.detectChanges();
      }
    }, (err) => {
      this.sharedService.loggerError(err)
    });
  }

  setEditorValueOnFoundationChange(event: any) {
    if (event) {
      const content = event;
      const viewFragment = this.basicEditor.data.processor.toView(content);
      const modelFragment = this.basicEditor.data.toModel(viewFragment);
      this.basicEditor.model.insertContent(modelFragment);
      this.onReady(this.basicEditor);
    }
  }

  onPreview() {
    const body = {
      title: this.createCustomEmailTemplateForm.value?.titleName,
      templateType: this.createCustomEmailTemplateForm.value?.templateType,
      headerBackgroundColour: this.createCustomEmailTemplateForm.value?.headerBackgroundColour,
      bodyBackgroundColour: this.createCustomEmailTemplateForm.value?.bodyBackgroundColour,
      footerBackgroundColour: this.createCustomEmailTemplateForm.value?.footerBackgroundColour,
      headerTextColour: this.createCustomEmailTemplateForm.value?.headerTextColour,
      bodyTextColour: this.createCustomEmailTemplateForm.value?.bodyTextColour,
      footerTextColour: this.createCustomEmailTemplateForm.value?.footerTextColour,
      styleBody: this.createCustomEmailTemplateForm.value?.styleBody,
      facebook_link: this.createCustomEmailTemplateForm.value?.facebook_link,
      twitter_link: this.createCustomEmailTemplateForm.value?.twitter_link,
      instagram_link: this.createCustomEmailTemplateForm.value?.instagram_link,
      website_link: this.createCustomEmailTemplateForm.value?.website_link,
      navBarText: this.storageService.getCipherObj('navBarText'),
      navBarImage: this.storageService.getCipherObj('navBarImage')
    };
    this.brandingService.getTemp(body)?.subscribe((res: any) => {
      if (res.code == 200 && res.data) {
        var w: any = window.open();
        w.document.write(res.data);
      }
    }, (err) => {
      this.sharedService.loggerError(err)
    });
  }
}
