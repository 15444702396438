import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterBySubstring' })
export class FilterBySubstringPipe implements PipeTransform {
    transform(array: any[], substring: string): any[] {
        if (!array) {
            return [];
        }
        //    let arr = array.filter(item => item.ip_allocations.length > 0 == true).filter(item1 => console.log('item1', item1));
        let arr: any = [];
        array.filter(item => {
            if (item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                    let publicIp = element.network.split("/");
                    if (publicIp.length > 0 && publicIp[0] == substring) arr.push(item);
                });
            }
        });
        return arr;
        // return array.filter(item => item.name.includes(substring));
    }
}