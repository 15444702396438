import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storageService: StorageService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let getToken = this.storageService.getCipherObj('authToken');
        request = request.clone({ setHeaders: { Authorization: `Bearer ${getToken}` } });
        request = request.clone({ setHeaders: { 'X-XSS-Protection': '1; mode=block' }, withCredentials: true });
        return next.handle(request);
    }
}
