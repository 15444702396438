<div class="modal-header px-5 py-3">
    <h5 class="modal-title">What's New in Version {{versionData?.version}} </h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5 version_popup">
    <div class="card">
        <h3>{{selectedData?.title}}</h3>
        <div class="row min-height-250">
            <div class="col-md-8" [innerHtml]="selectedData?.content"></div>
            <div class="col-md-4 text-center"><img [src]="selectedData?.image" class="w-100"></div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-2 px-5" *ngIf="childMessage > 0">
            <div class="d-flex align-items-center justify-content-center fs-6 text-gray-700">
                <ngb-pagination class="d-flex justify-content-center" [collectionSize]="childMessage"
                    (pageChange)="getNext($event)" [(page)]="page" [maxSize]="5" [pageSize]="pageSize"
                    [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
        <!-- <div class="row" *ngIf="childMessage > 0">
            <ul class="pagination">
                <li class="paginate_button page-item previous">
                    <a href="#" class="page-link"><i class="previous"></i></a>
                </li>
                <li class="paginate_button page-item active"><a href="#" class="page-link">1</a></li>
                <li class="paginate_button page-item "><a href="#" class="page-link">2</a></li>
                <li class="paginate_button page-item next"><a href="#" class="page-link"><i class="next"></i></a>
                </li>
            </ul>
        </div> -->
    </div>
</div>
<div class="modal-footer px-5 py-3 justify-content-between">
    <label class="form-check form-check-sm form-check-custom form-check-solid me-0" *ngIf="!isPreview">
        <input type="checkbox" name="show_again" id="show_again" class="form-check-input" [(ngModel)]="isChecked">
        <span class="form-check-label m-2 fw-bolder">Don't show again</span>
    </label>
    <button (click)="onClose()" type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal">
        Got it
    </button>
</div>