import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as customEditor from 'src/app/ckeditor-build/build/ckeditor';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst, coreMailType, emailType } from 'src/app/shared/constants/app.constant'; //src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-email-template-form',
  templateUrl: './shared-email-template-form.component.html',
  styleUrls: ['./shared-email-template-form.component.scss']
})
export class SharedEmailTemplateFormComponent implements OnInit {
  createEmailTemplateForm: FormGroup;
  editorConfig = {
    toolbar: {
      items: [
        'heading', '|', 'alignment:left', "alignment:right", "alignment:center",
        "alignment:justify", 'fontfamily', 'fontcolor', 'fontbackgroundcolor',
        '|', "blockQuote", "bold", 'italic', "selectAll", "undo", "redo", "|",
        "htmlEmbed", "link", "mediaEmbed", "|", "insertImage",
        "imageStyle:inline"
        , "imageStyle:alignLeft"
        , "imageStyle:alignRight"
        , "imageStyle:alignCenter"
        , "imageStyle:alignBlockLeft"
        , "imageStyle:alignBlockRight"
        , "imageStyle:block"
        , "imageStyle:side"
        , "imageStyle:wrapText"
        , "imageStyle:breakText", "|",
        "indent", "outdent", "|",
        "numberedList", "bulletedList", "|",
        "sourceEditing", "|",
        "insertTable", "tableColumn", "tableRow", "mergeTableCells"
      ],
      shouldNotGroupWhenFull: true
    },
    language: 'en',
  }

  @Output() onSave = new EventEmitter<any>();
  @Input() emailTemplate: any;

  // Set Editor to ClassicEditor with given functionality

  dataOnReset: string = '';
  basicEditor: any;
  emailPreview: string = '';
  editor = customEditor;
  currentEditor = 'header';
  coreMailType = coreMailType;
  emailType = emailType;
  coreMailTypeArray: any[] = [];
  emailTypeArray: any[] = [];
  isCoreSelected: boolean = true;
  foundationArray = AppConst.foundationList;
  isNew: boolean;
  sharedS = SharedService;
  isDisabled: boolean;

  constructor(private router: Router, private fb: FormBuilder,
    private formErrorHandler: FormErrorService,
    private cd: ChangeDetectorRef, private storageService: StorageService,
    private sharedService: SharedService) { }

  get coreMailTypeControl() {
    return this.createEmailTemplateForm.get('coreMailType')
  }

  get moduleTypeControl() {
    return this.createEmailTemplateForm.get('moduleType')
  }

  ngOnInit(): void {
    // this.sharedService.showLoader();
    this.emailTypeArray = SharedService.getTextValuePairFromEnum(this.emailType);
    this.coreMailTypeArray = SharedService.getTextValuePairFromEnum(this.coreMailType);
    if (this.emailTemplate) {
      this.isNew = false;
      this.createEmailTemplateForm = this.createNewForm(this.emailTemplate);
      if (this.emailTemplate.moduleType == emailType.core) {
        this.createEmailTemplateForm.controls['customMailType'].patchValue(this.sharedS.prettifyCamelCase(this.coreMailType[this.emailTemplate.coreMailType]));
        this.isDisabled = true;
      }
      else {
        this.isDisabled = false;
      }
    }
    else {
      this.isNew = true;
      this.createEmailTemplateForm = this.createNewForm();
    }
    this.cd.detectChanges();
  }

  ngOnChanges() {


  }

  createNewForm(formValues: any = {}) {
    return this.fb.group({
      // _id:this.fb.control(formValues?._id),
      moduleType: [formValues?.moduleType],
      coreMailType: [formValues?.coreMailType],
      customMailSubject: [formValues?.customMailSubject, Validators.compose([Validators.required])],
      customMailType: [formValues?.customMailType, Validators.compose([Validators.required])],
      // headerTemplate: this.fb.controlformValues?.headerTemplate, (,
      styleTemplate: [formValues?.styleTemplate],
      //footerTemplate: [formValues?.footerTemplat])
    });
  }

  public onReady(editor: any) {
    this.basicEditor = editor;
  }



  onBackClicked() {

    if (Number(this.storageService.getCipherObj('role')) == AppConst.admin) {
      this.router.navigate(['/admin/configuration/email-management'])
    }
    else if (Number(this.storageService.getCipherObj('role')) == AppConst.partner || Number(this.storageService.getCipherObj('role')) == AppConst.organization || Number(this.storageService.getCipherObj('role')) == AppConst.user) {
      this.router.navigate(['/partner/configuration/email-management'])
    }
  }

  onCancel() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.admin) {
      this.router.navigate(['/admin/configuration/email-management'])
    }
    else if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.router.navigate(['/partner/configuration/email-management'])
    }


  }

  onChangeMailType(event: any) {
    // let optionSelected = event;
    // if (optionSelected == 1) {
    //   this.isCoreSelected = true;
    // }
    // else {
    //   this.isCoreSelected = false;
    // }
  }

  setEditorValueOnFoundationChange(event: any) {
    if (event) {
      const content = event;
      const viewFragment = this.basicEditor.data.processor.toView(content);
      const modelFragment = this.basicEditor.data.toModel(viewFragment);
      this.basicEditor.model.insertContent(modelFragment);
      this.onReady(this.basicEditor);
    }
  }

  setSubjectFoundation(event: any) {
    if (event == undefined) return
    // this.createEmailTemplateForm.controls['customMailSubject'].patchValue(event);
    let value = this.createEmailTemplateForm.controls['customMailSubject'].value;
    this.createEmailTemplateForm.controls['customMailSubject'].patchValue(value + ' ' + event);
  }

  onSubmit() {
    if (this.createEmailTemplateForm.invalid) {
      this.createEmailTemplateForm.markAllAsTouched();
      return;
    }
    this.sharedService.showLoader();
    if (this.createEmailTemplateForm.controls.moduleType.value == emailType.custom) {
      delete this.createEmailTemplateForm.value.coreMailType
    }
    if (this.isNew) {
      this.createEmailTemplateForm.controls['moduleType'].setValue(2);
    }
    this.onSave.emit({ value: this.createEmailTemplateForm.value, isNew: this.isNew })
  }

  isControlValid(controlName: string): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.createEmailTemplateForm);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.createEmailTemplateForm);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.createEmailTemplateForm);
  }

  isControlTouched(controlName: string): boolean {
    return this.formErrorHandler.isControlTouched(controlName, this.createEmailTemplateForm);
  }

}
