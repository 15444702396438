import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-bonds-quick-view',
  templateUrl: './shared-bonds-quick-view.component.html',
  styleUrls: ['./shared-bonds-quick-view.component.scss']
})
export class SharedBondsQuickViewComponent implements OnInit {
@Input() bondstatus:any;
@Input() downTimeData:any;
  constructor() { }

  ngOnInit(): void {
  }

}
