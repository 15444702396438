
<div class="d-flex flex-wrap flex-stack my-3">

  <div class="fw-bolder my-2 d-flex div-position">
    <input type="text" class="form-control" name="searchText" placeholder="Search" value="" />
    <span  [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'" class="svg-icon svg-icon-1 me-0 span-input"></span>
</div>
  <div class="d-flex flex-wrap my-2">
     <div class="me-4 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
          data-bs-placement="top" data-bs-trigger="hover" title="Select Column">
          <a class="btn btn-sm btn-primary pe-4 ps-4" >
              <span [inlineSVG]="'./assets/media/icons/duotune/icon/columns.svg'"
                  class="svg-icon svg-icon-1 me-0"></span>
          </a>
      </div>
  </div>




</div>

<div class="card mb-5 mb-xl-10">
  <div class="card-body p-5">
      <div class="row">
          <div class="col-md-12 col-lg-12 mb-8 mb-0">

                 <!-- begin::Table container -->
              <div class="table-responsive border">
                  <!-- begin::Table -->

                  <table class="table table-row-bordered table-row-gray-300 align-middle g-5">
                      <thead>
                          <tr class="datatable-row fw-bolder text-muted bg-light">

                            <th class="w-25px ps-5">
                              <div class="form-check form-check-sm form-check-custom form-check-solid">
                                  <input class="form-check-input" type="checkbox" value="1" data-kt-check="true"
                                      data-kt-check-target=".widget-9-check" />
                              </div>
                             </th>

                              <th class="min-w-50px"> ID </th>
                              <th class="min-w-200px">Name </th>
                              <th  class="min-w-100px">Space </th>
                              <th  class="min-w-100px">IP </th>
                              <th  class="min-w-100px">IPV6 </th>
                              <th  class="min-w-100px">Version </th>
                              <th  class="min-w-200px">Routing Group </th>
                              <th class="min-w-200px">CPU</th>
                              <th class="min-w-150px">Primary Bonds	</th>
                              <th class="min-w-150px">Secondary Bonds </th>
                              <th class="min-w-150px">Current Bonds	</th>
                              <th class="min-w-100px">Status</th>
                              <th class="min-w-150px">Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let aggregator of inheritedAggregatorList; let i=index">
                          <td class="ps-5"> <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" value="1" data-kt-check="true"  data-kt-check-target=".widget-9-check" />
                                </div>
                            </td>
                            <td>{{aggregator.id}}</td>
                            <td> {{aggregator.name}} </td>
                            <td> {{aggregator.space?.name}}	  </td>
                            <td> {{aggregator.ip}}</td>
                            <td> {{aggregator.ipv6}}</td>
                            <td>{{aggregator.version}} </td>
                            <td> {{aggregator.routing_group?.hostname}}</td>
                            <td>{{aggregator.abbreviated_cpu}}</td>
                            <td> {{aggregator.bonds?.count}}	</td>
                            <td> {{aggregator.secondary_bonds_count}}	</td>
                            <td> {{aggregator.current_bonds_count}}</td>
                            <td> {{aggregator.status}}	</td>
                            <td class="text-start">

                              <a title="View"
                              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ml-1 me-2 ms-2">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/eye.svg'"
                                  class="svg-icon svg-icon-3">
                              </span>
                            </a>

                                <a title="Delete" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                    class="svg-icon svg-icon-3">
                                </span>
                            </a>


                            </td>
                          </tr>

                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>
</div>
