import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-alert-config-modal',
  templateUrl: './shared-alert-config-modal.component.html',
  styleUrls: ['./shared-alert-config-modal.component.scss']
})
export class SharedAlertConfigModalComponent implements OnInit {
  permissionsObj = { view: true, update: true, delete: true };
  errors: any;
  isEdit: any;
  settingData: any;
  selectedChecks: any = 2;
  selectedMonitor: any = 60;
  submitted: any = false;
  checkList: any = [2, 3, 4, 5, 6, 7];
  monitorList: any = [
    { title: '1 min', key: 60 },
    { title: '2 min', key: 120 },
    { title: '3 min', key: 180 },
    { title: '4 min', key: 240 },
  ];

  constructor(private cd: ChangeDetectorRef,
    private partnerBondingService: PartnerBondingService,
    public activeModal: NgbActiveModal,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getAlertData();
  }

  getAlertData() {
    this.sharedService.showLoader();
    this.partnerBondingService.getChecks()?.subscribe((editData: any) => {
      if (editData && editData.code == 200 && editData.data) {
        this.selectedMonitor = +editData.data.monitorInterval;
        this.selectedChecks = +editData.data.monitorChecks;
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.errors = err;
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }

  onSave() {
    this.submitted = true;
    if (!this.selectedChecks || !this.selectedMonitor) return;
    this.sharedService.showLoader();
    this.partnerBondingService.updateChecks(this.selectedChecks, this.selectedMonitor)?.subscribe((editData: any) => {
      if (editData && editData.code == 200) {
        this.sharedService.loggerSuccess("Update successfully");
        this.activeModal.close({ event: 'save' });
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.errors = err;
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}