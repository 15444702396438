<div *ngIf="permissionsObj.view" class="card p-0 mb-5 mb-xl-5 mt-15">
    <!-- <div class="card-header bg-light px-5">
        <div class="card-title d-flex mb-0">
            <h3 class="card-label"> Navigation bar </h3>
        </div>
    </div> -->

    <div class="card-body p-2">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-5 bg-light p-5 py-2 br-6">
                    <h4 class="fw-bold text-dark mb-0 fs-3">Layouts</h4>
                    <span class="fw-semibold text-muted fs-7 lh-1">2 main layouts.</span>
                </div>
            </div>
            <!-- <div class="col-md-12"><div class="separator separator-dashed my-5"></div></div> -->
            <div class="col-md-5 col-lg-3 col-xl-2 ms-5">
                <div class="text-gray-700 form-label fs-3">Left side menu</div>
                <button type="submit" (click)="onLeftSideMenuClicked()" [ngClass]="{'btn-outline btn-outline-dashed btn-active-light-primary border-2': menu == 1,
                    'btn-default' : menu != 1}" class="btn btn-light p-1 br-6">
                    <img src="./assets/media/avatars/darksidebar.png" class="w-100 br-6" alt="" />
                </button>
            </div>
            <div class="col-md-5 col-lg-3 col-xl-2 ms-5">
                <div class="form-label text-gray-700 fs-3">Top side menu </div>
                <button type="cancel" (click)="onTopSideMenuClicked()" [ngClass]="{'btn-outline btn-outline-dashed btn-active-light-primary border-2': menu == 2,
                ' btn-default' : menu != 2}" class="btn btn-light p-1 br-6">
                    <img src="./assets/media/avatars/lightheader.png" class="w-100 br-6" alt="" />
                </button>
            </div>
            <!-- <button type="submit" class="btn btn-primary me-5">Full top menu</button>
                <button type="cancel" class="btn btn-primary me-5">Short top menu</button> -->
        </div>

        <ng-template #navBackGroundColorPicker>
            <color-sketch [disableAlpha]="false" [color]="navbgColour"
                (onChangeComplete)="navBackGroundChangeComplete($event)"></color-sketch>
        </ng-template>

        <ng-template #navLinkBackGroundColorPicker>
            <color-sketch [disableAlpha]="false" [color]="navLinkbackgroundColour"
                (onChangeComplete)="navLinkBackGroundChangeComplete($event)"></color-sketch>
        </ng-template>

        <ng-template #navLinkColorPicker>
            <color-sketch [disableAlpha]="false" [color]="navLinkColour"
                (onChangeComplete)="navLinkColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #navLinkHoverColorPicker>
            <color-sketch [disableAlpha]="false" [color]="navLinkHoverColour"
                (onChangeComplete)="navLinkHoverColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #navLinkActiveColorPicker>
            <color-sketch [disableAlpha]="false" [color]="navLinkActiveColour"
                (onChangeComplete)="navLinkActiveColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #bodyTextColorPicker>
            <color-sketch [disableAlpha]="false" [color]="textColour"
                (onChangeComplete)="textColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #bodyLinkColorPicker>
            <color-sketch [disableAlpha]="false" [color]="linkColour"
                (onChangeComplete)="linkColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #bodyLinkHoverColorPicker>
            <color-sketch [disableAlpha]="false" [color]="linkHoverColour"
                (onChangeComplete)="linkHoverColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #buttonPrimaryColorPicker>
            <color-sketch [disableAlpha]="false" [color]="buttonPrimaryColour"
                (onChangeComplete)="buttonPrimaryColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #buttonSecondaryColorPicker>
            <color-sketch [disableAlpha]="false" [color]="buttonSecondaryColour"
                (onChangeComplete)="buttonSecondaryColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #buttonDangerColorPicker>
            <color-sketch [disableAlpha]="false" [color]="buttonDangerColour"
                (onChangeComplete)="buttonDangerColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #brandingTextColorPicker>
            <color-sketch [disableAlpha]="false" [color]="'#000000'"
                (onChangeComplete)="brandingTextColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <!-- <ng-template #bodySuccessColorPicker>
            <color-sketch [disableAlpha]="false" [color]="successColour"
                (onChangeComplete)="successColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #bodyInfoColorPicker>
            <color-sketch [disableAlpha]="false" [color]="infoColour"
                (onChangeComplete)="infoColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #bodyDangerColorPicker>
            <color-sketch [disableAlpha]="false" [color]="dangerColour"
                (onChangeComplete)="dangerColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #bodyWarningColorPicker>
            <color-sketch [disableAlpha]="false" [color]="warningColour"
                (onChangeComplete)="warningColourChangeComplete($event)">
            </color-sketch>
        </ng-template> -->

        <ng-template #bodyBackgroundColorPicker>
            <color-sketch [disableAlpha]="false" [color]="bodyBackgroundColour"
                (onChangeComplete)="bodyBGColorChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <ng-template #buttonTextColorPicker>
            <color-sketch [disableAlpha]="false" [color]="buttonTextColour"
                (onChangeComplete)="buttonTextColourChangeComplete($event)">
            </color-sketch>
        </ng-template>

        <div class="row mt-5">
            <!-- <div class="col-md-12"> <div class="separator separator-dashed my-5"></div> </div> -->
            <div class="col-md-12">
                <div class="mb-5 bg-light p-5 py-3 mt-5 br-6">
                    <h4 class="fw-bold text-dark mb-0 fs-3">Navigation Bar</h4>
                    <span class="fw-semibold text-muted fs-7 lh-1">Color Option</span>
                </div>
            </div>
            <!-- <div class="col-md-12"> <div class="separator separator-dashed my-5"></div> </div> -->
        </div>

        <div class="row px-5">
            <div class="col-md-3 col-lg-3 mb-1">
                <label class="form-label">Background Colour</label>
                <div [ngbPopover]="navBackGroundColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border p-1 color-picker-block">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(navbgColour)">
                    </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="navbgColour" />
                </div>
                <!-- <color-sketch   (onSwatchHover)="onSwatchHover($event)" (onChangeComplete)="changeComplete($event)"></color-sketch> -->
                <!-- <color-sketch [color]="state" (onChangeComplete)="changeComplete($event)"></color-sketch> -->
            </div>
            <div class="col-md-3 col-lg-3 mb-1">
                <label class="form-label">Link Colour </label>
                <div [ngbPopover]="navLinkColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border p-1 color-picker-block">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(navLinkColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="navLinkColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-1">
                <label class="form-label">Link Hover Colour </label>
                <div [ngbPopover]="navLinkHoverColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border p-1 color-picker-block">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(navLinkHoverColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="navLinkHoverColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-1">
                <label class="form-label">Link Active Colour
                </label>
                <div [ngbPopover]="navLinkActiveColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border p-1 color-picker-block">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(navLinkActiveColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="navLinkActiveColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-1">
                <label class="form-label">Link Background Colour
                </label>
                <div [ngbPopover]="navLinkBackGroundColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border p-1 color-picker-block">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(navLinkbackgroundColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="navLinkbackgroundColour" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="row mt-5">
                    <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
                    <div class="col-md-12">
                        <div class="mb-5 bg-light p-5 py-3 br-6">
                            <h4 class="fw-bold text-dark mb-0 fs-3">Logo</h4>
                            <span class="fw-semibold text-muted fs-7 lh-1">Image</span>
                        </div>
                    </div>
                    <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mb-5"></div></div> -->
                </div>
                <div class="row mx-5">
                    <div class="col-md-12 col-lg-12 col-xl-6 my-5">
                        <input style="display: none;" type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
                            #fileNavBarInput (change)="uploadFile($event,'navbar')" onclick="this.value = null" />
                        <div fileDragDrop (filesChangeEmiter)="uploadFile($event,'navbar')"
                            class="notice d-flex flex-column bg-light-primary br-6 border-primary border border-dashed align-items-center p-6">
                            <!--begin::Icon-->
                            <!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
                            <span class="svg-icon svg-icon-2tx svg-icon-primary me-4"><svg
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                        <rect fill="#000000" opacity="0.3" x="11" y="2" width="2" height="14" rx="1">
                                        </rect>
                                        <path
                                            d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                            fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                            <!--end::Svg Icon--> <!--end::Icon-->
                            <!--begin::Wrapper-->
                            <div class="d-flex justify-content-center flex-wrap">
                                <!--begin::Content-->
                                <div class="mb-3 mb-md-0 fw-semibold text-center">
                                    <h4 class="text-gray-900 fw-bold mb-3">Drop files here or click to upload.</h4>
                                    <button type="submit" class="btn btn-primary btn-style me-5"
                                        (click)="fileNavBarInput.click()">Browse File</button>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <p class="mt-5">PNG files only, 20x20 px or larger. We recommend 20 px tall </p>
                    </div>

                    <div class="col-md-12 col-lg-12 col-xl-4 mb-5">
                        <div class="dropzone dropzone-default dz-clickable border text-center p-5 br-6 position-relative w-150px h-150px m-auto mt-5"
                            id="kt_dropzone_1">
                            <div>
                                <img class="image-size" *ngIf="navBarAPIUrl" src="{{navBarAPIUrl}}" />
                            </div>
                            <!-- <div class="dropzone-msg dz-message needsclick">
                            <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                            <span class="dropzone-msg-desc">This is just a demo dropzone. Selected files are
                                <strong>not</strong>actually uploaded.</span>
                            </div> -->
                            <button type="cancel" (click)="onDeletePicture('navbar')"
                                class="btn btn-danger btn-style img-close-icon">
                                <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                                    class="svg-icon svg-icon-block mx-1"></span>
                            </button>
                        </div>
                        <p class="mt-5 invalid-feedback d-block" *ngIf="!navBarImageStatus">
                            Logo image should not be empty
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row mt-5">
                    <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
                    <div class="col-md-12">
                        <div class="mb-5 bg-light p-5 py-3 br-6">
                            <h4 class="fw-bold text-dark mb-0 fs-3">Logo</h4>
                            <span class="fw-semibold text-muted fs-7 lh-1">Text</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mb-5"></div></div> -->
                <div class="row mx-5">
                    <div class="col-md-12 col-lg-12 col-xl-6 mb-5">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Branding Text</label>
                            <input type="text" class="form-control form-control-lg form-control-solid"
                                placeholder="Text for navbar" [(ngModel)]="navBarText" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="!navBarText">
                                    Branding text is required
                                </span>
                                <span class="text-danger" *ngIf="!isPatternBrandTextValid(this.navBarText)">
                                    Branding text is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xl-6 mb-5">
                        <label class="form-label">Branding Text Colour</label>
                        <div [ngbPopover]="brandingTextColorPicker" [autoClose]="'outside'" placement="bottom"
                            class="d-flex border color-picker-block p-1">
                            <div class="colorpicker-div" [ngStyle]="setMyStyles(brandingTextColour)">
                            </div>
                            <input readonly type="text"
                                class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                                autocomplete="off" [(ngModel)]="brandingTextColour" />
                        </div>
                        <!-- <div [ngbPopover]="navLinkBackGroundColorPicker" [autoClose]="'outside'" placement="bottom"
                            class="d-flex ">
                            <div class="colorpicker-div" [ngStyle]="setMyStyles(navLinkbackgroundColour)">
                            </div>
                            <input readonly type="text" class="form-control form-control-lg form-control-solid"
                                autocomplete="off" [(ngModel)]="navLinkbackgroundColour" />
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body p-2">
        <div class="row mt-5">
            <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
            <div class="col-md-12">
                <div class="mb-5 bg-light p-5 py-3 mt-5 br-6">
                    <h4 class="fw-bold text-dark mb-0 fs-3">Body</h4>
                    <span class="fw-semibold text-muted fs-7 lh-1">Colour</span>
                </div>
            </div>
            <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
        </div>

        <div class="row px-5">
            <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Text Colour</label>
                <div [ngbPopover]="bodyTextColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border color-picker-block p-1">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(textColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="textColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Link Colour
                </label>
                <div [ngbPopover]="bodyLinkColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border color-picker-block p-1">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(linkColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="linkColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Link Hover Colour
                </label>
                <div [ngbPopover]="bodyLinkHoverColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border color-picker-block p-1">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(linkHoverColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="linkHoverColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Button Text Color</label>
                <div [ngbPopover]="buttonTextColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border color-picker-block p-1">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(buttonTextColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="buttonTextColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Button Primary Colour</label>
                <div [ngbPopover]="buttonPrimaryColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border color-picker-block p-1">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(buttonPrimaryColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="buttonPrimaryColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Button Secondary Colour</label>
                <div [ngbPopover]="buttonSecondaryColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border color-picker-block p-1">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(buttonSecondaryColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="buttonSecondaryColour" />
                </div>
            </div>
            <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Button Danger Colour</label>
                <div [ngbPopover]="buttonDangerColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border color-picker-block p-1">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(buttonDangerColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="buttonDangerColour" />
                </div>
            </div>
            <!-- <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Body Background Color</label>
                <div [ngbPopover]="bodyBackgroundColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex ">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(bodyBackgroundColour)">
                    </div>
                    <input readonly type="text" class="form-control form-control-lg form-control-solid"
                        autocomplete="off" [(ngModel)]="bodyBackgroundColour" />
                </div>
            </div> -->
        </div>
        <!-- <div class="row mt-5">
            <div class="form-group row mb-5">
                <div class="col-md-3 col-lg-3 mb-5">
                    <label class="form-label">Success colour</label>
                    <div [ngbPopover]="bodySuccessColorPicker" [autoClose]="'outside'" placement="bottom"
                        class="d-flex ">
                        <div class="colorpicker-div" [ngStyle]="setMyStyles(successColour)">
                        </div>
                        <input readonly type="text" class="form-control form-control-lg form-control-solid"
                            autocomplete="off" [(ngModel)]="successColour" />
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 mb-5">
                    <label class="form-label">Info colour</label>
                    <div [ngbPopover]="bodyInfoColorPicker" [autoClose]="'outside'" placement="bottom" class="d-flex ">
                        <div class="colorpicker-div" [ngStyle]="setMyStyles(infoColour)">
                        </div>
                        <input readonly type="text" class="form-control form-control-lg form-control-solid"
                            autocomplete="off" [(ngModel)]="infoColour" />
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 mb-5">
                    <label class="form-label">Warning colour </label>
                    <div [ngbPopover]="bodyWarningColorPicker" [autoClose]="'outside'" placement="bottom"
                        class="d-flex ">
                        <div class="colorpicker-div" [ngStyle]="setMyStyles(warningColour)">
                        </div>
                        <input readonly type="text" class="form-control form-control-lg form-control-solid"
                            autocomplete="off" [(ngModel)]="warningColour" />
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 mb-5">
                    <label class="form-label">Danger colour </label>
                    <div [ngbPopover]="bodyDangerColorPicker" [autoClose]="'outside'" placement="bottom"
                        class="d-flex ">
                        <div class="colorpicker-div" [ngStyle]="setMyStyles(dangerColour)">
                        </div>
                        <input readonly type="text" class="form-control form-control-lg form-control-solid"
                            autocomplete="off" [(ngModel)]="dangerColour" />
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <div class="card-body p-2" *ngIf="userRole == AppConst.admin || userRole == AppConst.partner || 
        (userRole == AppConst.user && parentRole == AppConst.partner)">
        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="row">
                    <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
                    <div class="col-md-12">
                        <div class="mb-5 bg-light p-5 py-3 mt-5 br-6">
                            <h4 class="fw-bold text-dark mb-0 fs-3">Login page</h4>
                            <span class="fw-semibold text-muted fs-7 lh-1">Image</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mb-5"></div></div> -->
                <div class="row px-5">
                    <div class="col-md-12 col-lg-12 col-xl-6 my-5">
                        <input style="display: none;" type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                            (change)="uploadFile($event,'login')" onclick="this.value = null" />
                        <div fileDragDrop (filesChangeEmiter)="uploadFile($event,'login')"
                            class="notice d-flex flex-column bg-light-primary rounded border-primary border border-dashed align-items-center p-6 br-6">
                            <!--begin::Icon-->
                            <!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
                            <span class="svg-icon svg-icon-2tx svg-icon-primary me-4"><svg
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                        <rect fill="#000000" opacity="0.3" x="11" y="2" width="2" height="14" rx="1">
                                        </rect>
                                        <path
                                            d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                            fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                            <!--end::Svg Icon--> <!--end::Icon-->
                            <!--begin::Wrapper-->
                            <div class="d-flex justify-content-center flex-wrap">
                                <!--begin::Content-->
                                <div class="mb-3 mb-md-0 fw-semibold text-center">
                                    <h4 class="text-gray-900 fw-bold mb-3">Drop files here or click to upload.</h4>
                                    <button type="submit" class="btn btn-primary btn-style me-5"
                                        (click)="fileInput.click()">Browse File</button>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <p class="mt-5"> PNG files only, 300 px wide or larger. We recommend at least 1200 px wide for
                            clear rendering on high-DPI devices.
                        </p>

                    </div>
                    <div class="col-md-12 col-lg-12 col-xl-6 mb-5">
                        <div class="dropzone dropzone-default dz-clickable border text-center p-5 position-relative w-150px h-150px m-auto mt-5 br-6"
                            id="kt_dropzone_1">
                            <div>
                                <img class="image-size" *ngIf="loginPageAPIUrl" src="{{loginPageAPIUrl}}" />
                            </div>
                            <!-- <div class="dropzone-msg dz-message needsclick">
                                <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                <span class="dropzone-msg-desc">This is just a demo dropzone. Selected files are
                                    <strong>not</strong>actually uploaded.</span>
                            </div> -->
                            <button type="cancel" (click)="onDeletePicture('login')"
                                class="btn btn-danger btn-style img-close-icon">
                                <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                                    class="svg-icon svg-icon-block mx-1"></span>
                            </button>
                        </div>
                        <p class="mt-5 invalid-feedback d-block" *ngIf="!loginPageImageStatus">
                            Login page image should not be empty
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="row">
                    <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
                    <div class="col-md-12">
                        <div class="mb-5 bg-light p-5 py-3 mt-5 br-6">
                            <h4 class="fw-bold text-dark mb-0 fs-3">Favicon</h4>
                            <span class="fw-semibold text-muted fs-7 lh-1">Image</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mb-5"></div></div> -->
                <div class="row px-5">
                    <div class="col-md-12 col-lg-12 col-xl-6 my-5">
                        <input style="display: none;" type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
                            #fileFaviconInput (change)="uploadFile($event,'favicon')" onclick="this.value = null" />
                        <div fileDragDrop (filesChangeEmiter)="uploadFile($event,'favicon')"
                            class="notice d-flex flex-column bg-light-primary rounded border-primary border border-dashed align-items-center p-6 br-6">
                            <!--begin::Icon-->
                            <!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
                            <span class="svg-icon svg-icon-2tx svg-icon-primary me-4"><svg
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                        <rect fill="#000000" opacity="0.3" x="11" y="2" width="2" height="14" rx="1">
                                        </rect>
                                        <path
                                            d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                            fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                            <!--end::Svg Icon--> <!--end::Icon-->
                            <!--begin::Wrapper-->
                            <div class="d-flex justify-content-center flex-wrap">
                                <!--begin::Content-->
                                <div class="mb-3 mb-md-0 fw-semibold text-center">
                                    <h4 class="text-gray-900 fw-bold mb-3">Drop files here or click to upload.</h4>
                                    <button type="submit" class="btn btn-primary btn-style"
                                        (click)="fileFaviconInput.click()">Browse File</button>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <p class="mt-5"> PNG or ICO file, 16x16 or 32x32 px. Used as icon for tabs and bookmarks.</p>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xl-6 mb-5">
                        <div class="dropzone dropzone-default dz-clickable border text-center p-5 br-6 position-relative w-150px h-150px m-auto mt-5"
                            id="kt_dropzone_1">
                            <div>
                                <img class="image-size" *ngIf="faviconAPIUrl" src="{{faviconAPIUrl}}" />
                            </div>
                            <!-- <div class="dropzone-msg dz-message needsclick">
                                <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                <span class="dropzone-msg-desc">This is just a demo dropzone. Selected files are
                                    <strong>not</strong>actually uploaded.</span>
                            </div> -->
                            <button type="cancel" (click)="onDeletePicture('favicon')"
                                class="btn btn-danger btn-style img-close-icon">
                                <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                                    class="svg-icon svg-icon-block mx-1"></span>
                            </button>
                        </div>
                        <p class="mt-5 invalid-feedback d-block" *ngIf="!faviconImageStatus">
                            Favicon image should not be empty
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body p-2">
        <div class="row mt-5">
            <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
            <div class="col-md-12">
                <div class="mb-5 bg-light p-5 py-3 mt-5 br-6">
                    <h4 class="fw-bold text-dark mb-0 fs-3">Technical Support</h4>
                    <!-- <span class="fw-semibold text-muted fs-7 lh-1">Colour</span> -->
                </div>
            </div>
            <!-- <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div> -->
        </div>

        <div class="row px-5">
            <div class="col-md-4 col-lg-4 mb-5">
                <div class="form-group inputtext-block mb-5">
                    <label class="form-label">URL</label>
                    <input type="text" class="form-control form-control-lg form-control-solid"
                        placeholder="Enter technical support URL" [(ngModel)]="technicalSupportURL" />
                    <small class="form-invalid-message">
                        <span class="text-danger"
                            *ngIf="technicalSupportURL && !isURLPatternValid(this.technicalSupportURL)">
                            Technical support URL is invalid
                        </span>
                    </small>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 mb-5">
                <div class="form-group inputtext-block mb-5">
                    <label class="form-label">Email</label>
                    <input type="text" class="form-control form-control-lg form-control-solid"
                        placeholder="Enter technical support Email" [(ngModel)]="technicalSupportEmail" />
                    <small class="form-invalid-message">
                        <span class="text-danger"
                            *ngIf="technicalSupportEmail && !isEmailPatternValid(this.technicalSupportEmail)">
                            Technical support email is invalid
                        </span>
                    </small>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 mb-5">
                <div class="form-group inputtext-block mb-5">
                    <label class="form-label">Contact us</label>
                    <input type="text" class="form-control form-control-lg form-control-solid"
                        placeholder="Enter technical support Contact No" [(ngModel)]="technicalSupportContact" />
                    <small class="form-invalid-message">
                        <span class="text-danger"
                            *ngIf="technicalSupportContact && !isMobilePatternValid(this.technicalSupportContact)">
                            Technical support contact is invalid
                        </span>
                    </small>
                </div>
            </div>
            <!-- <div class="col-md-3 col-lg-3 mb-5">
                <label class="form-label">Link colour
                </label>
                <div [ngbPopover]="bodyLinkColorPicker" [autoClose]="'outside'" placement="bottom"
                    class="d-flex border">
                    <div class="colorpicker-div" [ngStyle]="setMyStyles(linkColour)"> </div>
                    <input readonly type="text"
                        class="form-control form-control-lg form-control-solid border-top-0 border-bottom-0 border-end-0"
                        autocomplete="off" [(ngModel)]="linkColour" />
                </div>
            </div> -->
        </div>
    </div>

    <!-- <div class="card-header border-top">
        <div class="card-title d-flex">
            <h3 class="card-label">Navbar {{navOptionSelected=='1'?'Image':'Text'}}
            </h3>
            <ng-select [searchable]="false" [clearable]="false" [(ngModel)]="navOptionSelected" class="min-w-200px"
                (change)="onChangeNavBarOption($event)">
                <ng-option value=1> Image </ng-option>
                <ng-option value=2> Text </ng-option>
            </ng-select> -->
    <!-- <select name="status" data-control="select2" data-hide-search="true" (change)="onChangeNavBarOption($event)"
        class="form-select form-select-sm form-select-white w-125px" defaultValue="Active">
        <option value=1 [selected]="isNavImageSelected">Image</option>
        <option value=2 [selected]="isNavTextSelected">Text</option>
    </select>
</div>
</div> -->

    <!-- <div *ngIf="navOptionSelected=='1'" class="card-body pt-9 pb-9">
        <div class="row mt-5">
            <div class="col-md-6 col-lg-6 mb-5">
            <input style="display: none;" type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileNavBarInput
                (change)="uploadFile($event,'navbar')" />
            <button type="submit" class="btn btn-info me-5" (click)="fileNavBarInput.click()">Select</button>
                <button type="cancel" (click)="onDeletePicture('navbar')" class="btn btn-danger me-5">Delete</button>
                <p class="mt-5">PNG files only, 20x20 px or larger. We recommend 20 px tall </p>
            </div>
            <div  class="col-md-6 col-lg-6 mb-5">
                <div class="dropzone dropzone-default dz-clickable" id="kt_dropzone_1">
                    <img class="image-size" *ngIf="navBarAPIUrl" src="{{navBarAPIUrl}}" alt="Metronic" />
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div *ngIf="navOptionSelected=='2'" class="card-body pt-9 pb-9">
        <div class="row mt-5">
            <div class="col-md-4 col-lg-4 mb-5">
                <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Text for navbar"
                    [(ngModel)]="navBarText" />
            </div>
        </div>
    </div> -->

    <!-- <div class="card-body p-5">
        <div class="row">
            <div class="col-md-12"><div class="separator separator-dashed my-3 mt-0"></div> </div>
            <div class="col-md-12">
                <div class="mb-0">
                    <h4 class="fw-bold text-dark mb-0">Custom</h4>
                    <span class="fw-semibold text-muted fs-7 lh-1">css</span>
                </div>
            </div>
            <div class="col-md-12"><div class="separator separator-dashed my-3 mb-5"></div></div>
            <div class="col-md-12 col-lg-12 mb-5">
                <p class="">Custom CSS can be used to override any included CSS definitions. This is intended for
                    experts only. Links to external resources must use HTTPS (not HTTP),
                    in order to guarantee security and avoid browser warnings.
                </p>
                <div style="height: 500px">
                    <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
                </div>
                <textarea [(ngModel)]="customCSSText"
                    class="form-control form-control-lg form-control-solid min-h-200px" type="textarea"> </textarea>
            </div>
        </div>
    </div> -->

    <div class="card-footer px-5 py-3">
        <button *ngIf="permissionsObj.update" type="button" class="btn btn-primary btn-style"
            (click)="onSubmitBrandSetting()">
            <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                    class="svg-icon svg-icon-block mb-2"></span>
            </div> Save Theme
        </button>
        <button type="button" class="btn btn-primary btn-style" *ngIf="!isPreview" (click)="onPreviewBrandSetting()">
            <div>
                <span [inlineSVG]="'./assets/media/icons/normal/eye.svg'" class="svg-icon svg-icon-block mb-2"></span>
            </div> Preview
        </button>
        <button type="cancel" class="btn btn-secondary btn-style" *ngIf="isPreview" (click)="onCancelPreview()">
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
            Cancel
        </button>
    </div>
</div>

<div *ngIf="!permissionsObj?.view"> You have no permission to view the data. Kindly contact the authority.</div>