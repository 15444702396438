import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { LayoutService } from 'src/app/_metronic/layout';
import { DefaultLayoutConfig, ILayout } from 'src/app/_metronic/layout/core/default-layout.config';
import { BrandingService } from 'src/app/services/branding.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { VersionService } from 'src/app/services/version.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthHTTPService } from './auth-http';
export type UserType = any | undefined;
export type imageType = string | null;

const secretKey = environment.secretKey;

@Injectable({ providedIn: 'root', })
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  otpSubject: BehaviorSubject<any>;
  tfaSubject: BehaviorSubject<any>;
  forgotPwdSubject$: BehaviorSubject<any>;
  imageUrl$: Observable<any>;
  imageUrlSubject: BehaviorSubject<imageType>;
  navBar: BehaviorSubject<any>;
  defaultConfigs: any;
  fieldsToUpdate: Partial<ILayout>;
  showHeaderSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  nameBreadcrumb: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  navBarTextSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  brandingFileIdSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  technicalSupportObj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  versionId: any;
  appVersion: any = '0.0.0.'
  leftMenuflag: boolean;
  topMenuflag: boolean;
  state: any;
  navbgColour: any = '#000000';
  navLinkColour: any = '#000000';
  navLinkHoverColour: any = '#000000'
  navLinkActiveColour: any = '#000000';
  navLinkbackgroundColour: any = '#000000';
  brandingTextColour: any = '#000000';

  textColour: any = '#000000'
  linkColour: any = '#000000';
  linkHoverColour: any = '#000000';
  successColour: any = '#000000';
  infoColour: any = '#000000';
  warningColour: any = '#000000';
  dangerColour: any = '#000000';
  bodyBackgroundColour: any = '#000000';
  buttonPrimaryColour: any = '#0095e8';
  buttonTextColour: any = '#ffffffff';
  buttonSecondaryColour: any = '#6c757d';
  buttonDangerColour: any = '#dd3333';
  loginPageUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  faviconUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  navBarUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  menu: any = 1;
  loginPageAPIUrl: any;
  navBarAPIUrl: any;
  faviconAPIUrl: any;
  customCSSText: any = '';
  brandingId: any;
  brandSettings: any;
  isPreview: boolean = false;
  apiBrandingData: any;
  favIcon: any = document.querySelector('#appIcons');

  get navBar$() {
    return this.navBar.asObservable();
  }

  get navBarText$() {
    return this.navBarTextSubject.asObservable();
  }

  get technicalSupport$() {
    return this.technicalSupportObj.asObservable();
  }

  get brandingFileId$() {
    return this.brandingFileIdSubject.asObservable();
  }

  get currentImageUrl() {
    return this.imageUrlSubject.value;
  }

  set currentImageUrl(image: any) {
    this.imageUrlSubject.next(image);
  }

  get showHeader$() {
    return this.showHeaderSubject.asObservable();
  }

  get nameBreadcrumb$() {
    return this.nameBreadcrumb.asObservable();
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private partnerBondingService: PartnerBondingService,
    private router: Router, public spinner: NgxSpinnerService,
    private storageService: StorageService, private layout: LayoutService,
    private brandSettingService: BrandingService, private versionService: VersionService,) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.imageUrlSubject = new BehaviorSubject<imageType>(null);
    this.otpSubject = new BehaviorSubject<any>(null);
    this.tfaSubject = new BehaviorSubject<any>(null);
    this.forgotPwdSubject$ = new BehaviorSubject<any>(null);
    this.navBar = new BehaviorSubject<any>('./assets/media/logos/login-logo.svg');
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.imageUrl$ = this.imageUrlSubject.asObservable();
    this.defaultConfigs = DefaultLayoutConfig;
  }

  loginWithOTPAdmin(data: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(data).pipe(
      map((auth: any) => this.setAuthFromLocalStorage(auth)),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  login(data: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(data).pipe(
      map((auth: any) => {
        if (auth.data.v_type == 0) {
          return this.setAuthFromLocalStorage(auth);
        } else if (auth.data.v_type == 5) {
          this.tfaSubject.next({
            email: data.email,
            pass: data.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message
          });
          this.router.navigate(['/auth/tfa'], { queryParams: { role: 1 } });
        } else if (auth.data.v_type == 4) {
          this.otpSubject.next({
            email: data.email,
            pass: data.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message,
          });
          this.router.navigate(['/auth/otp'], { queryParams: { role: 1 } });
        }
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  partnerLoginWithOTP(body: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.partnerLogin(body).pipe(
      map((auth: any) => this.setAuthFromLocalStorage(auth)),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  partnerLogin(body: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.partnerLogin(body).pipe(
      map((auth: any) => {
        if (auth.data.v_type == 0) {
          return this.setAuthFromLocalStoragePartner(auth);
        } else if (auth.data.v_type == 5) {
          this.tfaSubject.next({
            email: body.email,
            pass: body.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message,
          });
          this.router.navigate(['/auth/tfa'], { queryParams: { role: 2 } });
        } else if (auth.data.v_type == 4) {
          this.otpSubject.next({
            email: body.email,
            pass: body.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message,
          });
          this.router.navigate(['/auth/otp'], { queryParams: { role: 2 } });
        }
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    let role = Number(this.storageService.getCipherObj('role'));
    localStorage.clear();
    if (role == AppConst.admin || role == AppConst.basicUser) {
      this.router.navigate(['/auth/login'], { queryParams: {} }).then((res) => window.location.reload());
    } else if (role == AppConst.partner || role == AppConst.user || role == AppConst.organization || role == AppConst.basicUser) {
      this.router.navigate(['/auth/login/partner'], { queryParams: {} }).then((res) => window.location.reload());
    }
  }

  logoutWithErrorHandler() {
    console.log('logout-handler');
    this.clearCookies()
    this.router.navigate(['/auth/login'], { queryParams: { isRedirect: true } });
    Swal.fire({
      position: 'top-right',
      width: 300,
      icon: 'error',
      text: 'Token Expired! Login Again'
    });
  }

  getUserByToken(): Observable<any> {
    this.spinner.show();
    const auth = this.getAuthFromLocalStorage();
    if (!auth.token) {
      this.spinner.hide();
      // this.logout();
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: any) => {
        this.spinner.hide();
        if (user) {
          this.setUserIDs(user.data);
          this.storageService.setCipherObj('Module_Access', user.moduleAccessData);
          this.storageService.setCipherObj('ID', user.data._id);
          this.storageService.setCipherObj('role', user.data.role);
          this.partnerBondingService.setUpSocket();
          this.currentUserSubject.next(user.data);
          this.versionId = user?.data?.versionId;
          if (Number(this.storageService.getCipherObj('role')) == AppConst.admin || Number(this.storageService.getCipherObj('role')) == AppConst.basicUser) {
            if (user.data.partnerPageSize) this.storageService.setCipherText('partnerPageSize', user.data.partnerPageSize);
            this.getDefaultBrandSettings('/admin/branding/view');
            this.storageService.removeCipherText('group_permission');
            this.storageService.removeCipherText('graph_color_palate');
            this.storageService.removeCipherText('cacheInterval');
            this.storageService.removeCipherText('mainId');
            this.storageService.removeCipherText('homeSpace');
            this.storageService.removeCipherText('parentRole');
          } else {
            let mainId = this.storageService.getCipherObj('mainId');
            this.getDefaultBrandSettings(`/partnerPanel/branding/view/${mainId}/`);
            this.getActiveVersion();
            this.getCachingInterval();
            if (user.data.graph_color_palate) this.storageService.setCipherObj('graph_color_palate', user.data.graph_color_palate);
            if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
              this.storageService.setCipherObj('partnerModuleAccess', user.partnerModuleAccess);
              if (user?.data?.firewallList) this.storageService.setCipherObj('partnerFirewallList', user?.data?.firewallList);
              this.storageService.removeCipherText('homeSpace');
              this.storageService.removeCipherText('parentRole');
            } else {
              if (user.data?.homeSpace) this.storageService.setCipherObj('homeSpace', user.data?.homeSpace);
              else this.storageService.removeCipherText('homeSpace')
              if (Number(this.storageService.getCipherObj('role')) == AppConst.user) {
                if (user.data.parentRole) this.storageService.setCipherObj('parentRole', user.data.parentRole);
                else this.storageService.removeCipherText('parentRole');
                this.storageService.setCipherObj('ID', user.data.parentId);
              }
            }
            this.storageService.setCipherObj('group_permission', user.groupPermission);
            this.storageService.removeCipherText('partnerPageSize');
          }
        } else this.logout();
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getActiveVersion() {
    this.versionService.getActiveVersion().subscribe((res: any) => {
      if (res && res.data) this.storageService.setCipherObj('version', res.data);
      if (res?.data?._id && this.versionId !== res?.data?._id) this.versionService.openVersion.next(true);
    }, (err) => {
      this.storageService.removeCipherText('version');
    });
  }

  getCachingInterval() {
    this.partnerBondingService.getCacheInterval()?.subscribe((res: any) => {
      if (res && res.data) this.storageService.setCipherObj('cacheInterval', res.data.cachedInterval);
    }, (err) => {
      this.storageService.removeCipherText('cacheInterval');
    });
  }

  // private methods
  public setAuthFromLocalStorage(auth: any): boolean {
    let JWTdecoded: any = jwt_decode(auth.data.token);
    if (auth && JWTdecoded) {
      this.storageService.setCipherObj('authToken', auth.data.token);
      this.storageService.setCipherObj('role', auth.data.role);
      this.storageService.setCipherObj('ID', JWTdecoded.sub);
      this.storageService.setCipherObj('menu', auth.data.brandSettingMenu || '');
      this.storageService.setCipherObj('navBarImage', auth.data.brandSettingNavImg || '');
      this.storageService.setCipherObj('navBarText', auth.data.brandSettingNavText || '');
      this.setLayoutConfig();
      this.showHeader();
      return true;
    }
    return false;
  }

  setUserIDs(userData: any) {
    let userIds: any[] = [];
    let breadCrumbs: any[] = [];
    userIds = this.storageService.getCipherObj('userIds') ?? [];
    breadCrumbs = this.storageService.getCipherObj('breadCrumbs') ?? [];
    let userIDFind = userIds?.find(x => x._id == userData._id);
    if (!userIDFind) {
      userIds.push({
        _id: userData._id,
        role: userData.role,
        parentRole: userData.parentRole,
        parentId: userData.parentId
      });
      breadCrumbs.push(userData?.fname);
      this.storageService.setCipherObj('userIds', userIds);
      this.storageService.setCipherObj('breadCrumbs', breadCrumbs);
    }
  }


  // private methods
  public setAuthFromLocalStoragePartner(auth: any): boolean {
    let JWTdecoded: any = jwt_decode(auth.data.token);
    if (auth && JWTdecoded) {
      this.storageService.setCipherObj('authToken', auth.data.token);
      this.storageService.setCipherObj('role', auth.data.role);
      this.storageService.setCipherObj('ID', JWTdecoded.sub);
      this.storageService.setCipherObj('menu', auth.data.brandSettingMenu || '');
      this.storageService.setCipherObj('navBarImage', auth.data.brandSettingNavImg || '');
      this.storageService.setCipherObj('navBarText', auth.data.brandSettingNavText || '');
      this.setLayoutConfig();
      this.showHeader();
      return true;
    }
    return false;
  }

  public setAuthAfterPartnerLogin(auth: any) {
    let JWTdecoded: any = jwt_decode(auth.token);
    if (auth && JWTdecoded) {
      this.storageService.setCipherObj('authToken', auth.token);
      this.storageService.setCipherObj('role', auth.data.role);
      this.storageService.setCipherObj('ID', auth.data._id);
      this.storageService.setCipherObj('menu', auth.brandSettingMenu || '');
      this.storageService.setCipherObj('navBarImage', auth.brandSettingNavImg || '');
      this.storageService.setCipherObj('navBarText', auth.brandSettingNavText || '');
      this.setLayoutConfig();
      this.showHeader();
      return true;
    }
    return false;
  }

  public setAuthAfterUserLogin(auth: any) {
    let JWTdecoded: any = jwt_decode(auth.token);
    if (auth && JWTdecoded) {
      this.storageService.setCipherObj('authToken', auth.token);
      this.storageService.setCipherObj('role', auth.role);
      this.storageService.setCipherObj('mainId', auth.mainId);
      this.storageService.setCipherObj('ID', auth.partnerID);
      this.storageService.setCipherObj('menu', auth.brandSettingMenu || '');
      this.storageService.setCipherObj('navBarImage', auth.brandSettingNavImg || '');
      this.storageService.setCipherObj('navBarText', auth.brandSettingNavText || '');
      this.setLayoutConfig();
      this.showHeader();
      return true;
    }
    return false;
  }

  setLayoutConfig() {
    if (Number(this.storageService.getCipherObj('menu')) == 1) {
      this.fieldsToUpdate = {
        header: {
          ...DefaultLayoutConfig.header,
          left: 'page-title',
          display: false
        },
        aside: {
          ...DefaultLayoutConfig.aside,
          display: true
        },
      }
      //  this.layout.setConfig(this.fieldsToUpdate);
      // window.location.reload();
    } else {
      this.fieldsToUpdate = {
        header: {
          ...DefaultLayoutConfig.header,
          left: 'menu',
          display: true
        },
        aside: {
          ...DefaultLayoutConfig.aside,
          display: false
        },
      }
      // window.location.reload();
    }
    this.showHeader();
    this.layout.setConfig(this.fieldsToUpdate);
  }

  showHeader() {
    if (Number(this.storageService.getCipherObj('menu')) == 1) {
      this.showHeaderSubject.next(false);
    } else {
      this.showHeaderSubject.next(true);
    }
  }

  public getAuthFromLocalStorage(): any {
    try {
      const authData = {
        token: this.storageService.getCipherObj('authToken'),
        role: this.storageService.getCipherObj('role'),
      }
      return authData;
    } catch (error) {
      // console.error(error);
      return undefined;
    }
  }

  clearCookies() {
    this.storageService.removeCipherText('authToken');
    this.storageService.removeCipherText('preview');
    this.storageService.removeCipherText('brandSetting');
    this.storageService.removeCipherText('role');
    this.storageService.removeCipherText('mainId');
    this.storageService.removeCipherText('ID');
    this.storageService.removeCipherText('homeSpace');
    this.storageService.removeCipherText('Module_Access');
    this.storageService.removeCipherText('menu');
    this.storageService.removeCipherText('navBarText');
    this.storageService.removeCipherText('navBarImage');
    this.storageService.removeCipherText('partnerModuleAccess');
    this.storageService.removeCipherText('partnerFirewallList');
    this.storageService.removeCipherText('group_permission');
    this.storageService.removeCipherText('graph_color_palate');
  }

  getDefaultBrandSettings(viewURL: any) {
    if (!this.storageService.getCipherObj('preview')) {
      this.brandSettingService.getBrandSettingData(viewURL).subscribe(
        (res: any) => {
          if (res?.data?.createdBy) this.brandingFileIdSubject.next(res?.data?.createdBy);
          this.brandSettings = res.data;
          this.brandingId = res.data._id;
          if (this.brandSettings.navbgColour) this.navbgColour = this.brandSettings.navbgColour;
          if (this.brandSettings.navLinkColour) this.navLinkColour = this.brandSettings.navLinkColour;
          if (this.brandSettings.navLinkHoverColour) this.navLinkHoverColour = this.brandSettings.navLinkHoverColour;
          if (this.brandSettings.navLinkActiveColour) this.navLinkActiveColour = this.brandSettings.navLinkActiveColour;
          if (this.brandSettings.navLinkbackgroundColour) this.navLinkbackgroundColour = this.brandSettings.navLinkbackgroundColour;
          if (this.brandSettings.brandingTextColour) this.brandingTextColour = this.brandSettings.brandingTextColour;

          if (this.brandSettings.body.bodyBackgroundColour) this.bodyBackgroundColour = this.brandSettings.body.bodyBackgroundColour;
          if (this.brandSettings.body.textColour) this.textColour = this.brandSettings.body.textColour;
          if (this.brandSettings.body.linkColour) this.linkColour = this.brandSettings.body.linkColour;
          if (this.brandSettings.body.linkHoverColour) this.linkHoverColour = this.brandSettings.body.linkHoverColour;
          if (this.brandSettings.body.buttonPrimaryColour) this.buttonPrimaryColour = this.brandSettings.body.buttonPrimaryColour;
          if (this.brandSettings.body.buttonSecondaryColour) this.buttonSecondaryColour = this.brandSettings.body.buttonSecondaryColour;
          if (this.brandSettings.body.buttonDangerColour) this.buttonDangerColour = this.brandSettings.body.buttonDangerColour;
          if (this.brandSettings.body.buttonTextColour) this.buttonTextColour = this.brandSettings.body.buttonTextColour;
          if (this.brandSettings.body.successColour) this.successColour = this.brandSettings.body.successColour;
          if (this.brandSettings.body.infoColour) this.infoColour = this.brandSettings.body.infoColour;
          if (this.brandSettings.body.warningColour) this.warningColour = this.brandSettings.body.warningColour;
          if (this.brandSettings.body.dangerColour) this.dangerColour = this.brandSettings.body.dangerColour;
          this.loginPageAPIUrl = this.brandSettings.loginPageImg;
          this.faviconAPIUrl = this.brandSettings.favicon;
          this.favIcon.href = this.faviconAPIUrl;
          this.navBarAPIUrl = this.brandSettings.navbarImg;
          this.menu = this.brandSettings.menu;
          this.storageService.setCipherObj('menu', this.menu || '');
          this.customCSSText = this.brandSettings.customCss;
          let obj = {
            navbgColour: this.navbgColour,
            navLinkColour: this.navLinkColour,
            navLinkHoverColour: this.navLinkHoverColour,
            navLinkActiveColour: this.navLinkActiveColour,
            navLinkbackgroundColour: this.navLinkbackgroundColour,
            brandingTextColour: this.brandingTextColour,
            bodyBackgroundColour: this.bodyBackgroundColour,
            textColour: this.textColour,
            linkColour: this.linkColour,
            linkHoverColour: this.linkHoverColour,
            buttonPrimaryColour: this.buttonPrimaryColour,
            buttonSecondaryColour: this.buttonSecondaryColour,
            buttonDangerColour: this.buttonDangerColour,
            buttonTextColour: this.buttonTextColour,
            successColour: this.successColour,
            infoColour: this.infoColour,
            warningColour: this.warningColour,
            dangerColour: this.dangerColour
          }
          this.navBar.next({ 'image': this.brandSettings?.navbarImg, 'text': this.brandSettings?.navBarText, 'favicon': this.brandSettings.favicon });
          //   this.authService.navBarImage.next(this.navBarAPIUrl);
          let technicalSupport = {
            technicalSupportURL: this.brandSettings?.technicalSupportURL,
            technicalSupportEmail: this.brandSettings?.technicalSupportEmail,
            technicalSupportContact: this.brandSettings?.technicalSupportContact
          }
          this.technicalSupportObj.next(technicalSupport);
          this.showHeader();
          this.setCSSProperties(obj);
          this.setLayoutConfig();
          // this.cd.detectChanges();
          // window.location.reload();
        }
      );
    } else {
      this.setBrandSettings();
    }
  }

  setCSSProperties(obj: any) {
    // this.setCssProperty('--background_colour', obj.navbgColour);
    this.setCssProperty('--navbgColour', obj.navbgColour);
    this.setCssProperty('--navLinkColour', obj.navLinkColour);
    this.setCssProperty('--navLinkbackgroundColour', obj.navLinkbackgroundColour);
    this.setCssProperty('--brandingTextColour', obj.brandingTextColour);
    this.setCssProperty('--navLinkHoverColour', obj.navLinkHoverColour);
    this.setCssProperty('--navLinkActiveColour', obj.navLinkActiveColour);
    this.setCssProperty('--bodyBackgroundColour', obj.bodyBackgroundColour);
    this.setCssProperty('--textColour', obj.textColour);
    this.setCssProperty('--linkColour', obj.linkColour);
    this.setCssProperty('--linkHoverColour', obj.linkHoverColour);
    this.setCssProperty('--buttonPrimaryColour', obj.buttonPrimaryColour);
    this.setCssProperty('--buttonSecondaryColour', obj.buttonSecondaryColour);
    this.setCssProperty('--buttonDangerColour', obj.buttonDangerColour);
    this.setCssProperty('--buttonTextColour', obj.buttonTextColour);
    this.setCssProperty('--successColour', obj.successColour);
    this.setCssProperty('--infoColour', obj.infoColour);
    this.setCssProperty('--warningColour', obj.warningColour);
    this.setCssProperty('--dangerColour', obj.dangerColour);
  }

  setCssProperty(variable: string, value: any) {
    document.documentElement.style.setProperty(variable, value);
  }

  setBrandSettings() {
    if (this.storageService.getCipherObj('preview')) {
      let obj = this.storageService.getCipherObj('brandSetting');
      this.navbgColour = obj.navbgColour;
      this.navLinkColour = obj.navLinkColour;
      this.navLinkHoverColour = obj.navLinkHoverColour;
      this.navLinkActiveColour = obj.navLinkActiveColour;
      this.navLinkbackgroundColour = obj.navLinkbackgroundColour;
      this.brandingTextColour = obj.brandingTextColour;
      this.bodyBackgroundColour = obj.bodyBackgroundColour;
      this.textColour = obj.textColour;
      this.linkColour = obj.linkColour;
      this.linkHoverColour = obj.linkHoverColour;
      this.buttonPrimaryColour = obj.buttonPrimaryColour;
      this.buttonSecondaryColour = obj.buttonSecondaryColour;
      this.buttonDangerColour = obj.buttonDangerColour;
      this.buttonTextColour = obj.buttonTextColour;
      this.successColour = obj.successColour;
      this.infoColour = obj.infoColour;
      this.warningColour = obj.warningColour;
      this.dangerColour = obj.dangerColour;
      this.loginPageAPIUrl = obj.loginPageAPIUrl;
      // this.loginPageUrl = obj.loginPageAPIUrl;
      this.faviconAPIUrl = obj.faviconAPIUrl;
      // this.faviconUrl = obj.faviconAPIUrl;
      this.navBarAPIUrl = obj.navBarAPIUrl;
      // this.navBarUrl = obj.navBarAPIUrl;
      this.brandingId = obj.brandingId;
      this.customCSSText = obj.customCssText;
      this.menu = obj.menu;
      // this.authService.navBarImage.next(this.navBarAPIUrl);
      this.setCSSProperties(obj);
      this.showHeader();
      //  this.cd.detectChanges();
    }
    return;
  }

  validateTokenExpiry() {
    try {
      let token = this.storageService.getCipherObj('authToken');
      if (!token) {
        this.logout();
      }
      let data: any = jwt_decode(token)
      if (data && data.sub && data.exp) {
        let today: any = new Date();
        let expDate: any = new Date(data.exp);
        let minDiff = expDate - today;
        minDiff = Math.floor(minDiff / 60000);
        if (minDiff && minDiff > 0) {
          if (minDiff > 3) return;
          else {
            this.authHttpService.refreshToken().subscribe((res) => {
              if (res && res.data && res.data.refreshToken) {
                this.storageService.setCipherObj('authToken', res.data.refreshToken);
              }
            }, (err) => {
              console.log('err', err)
            })
          }
        } else {
          this.logout();
        }
      } else {
        this.logout();
      }
    } catch (err) {
      console.error(err);
      this.logout();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
