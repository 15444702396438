<ng-container *ngIf="selectedOption==1">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title">Add DHCP Service </h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <form [formGroup]="addDHCP">
    <div class="modal-body p-0">
      <div class="overflow-auto mt-0 card border-0 border bg-light border-bottom-0">
        <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
          <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6 px-0">
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(1)" class="nav-link text-active-primary mx-0 px-5 mx-0 px-5"
                [class.active]="selectedTab==1">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span>
                Standard configuration
              </a>
            </li>
            <li class="nav-item"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(2)" class="nav-link text-active-primary mx-0 px-5 mx-0 px-5"
                [class.active]="selectedTab==2">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span> DNS
              </a>
            </li>
            <li class="nav-item"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(3)" class="nav-link text-active-primary mx-0 px-5 mx-0 px-5"
                [class.active]="selectedTab==3">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span>
                Custom configuration
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body p-5 bg-white">
          <ng-container *ngIf="selectedTab==1">
            <div class="row">
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Connected IP <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="The connected IP the service will run on. "></span></label>
                  <ng-select formControlName="connectedip" name="connectedip" class="dropdown-block">
                    <ng-option *ngFor="let ip of connectedIPs" [value]="ip.url"> {{ip.ip}} on {{ip.ifname}}
                    </ng-option>
                  </ng-select>
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['connectedip']?.join(' ')}}
                    </span>
                    <span *ngIf="submitted==true && f.connectedip.invalid && f.connectedip.errors?.required"
                      class="text-danger"> This field is required
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DHCP <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Local DHCP service will be provided on the connected IP interface."></span></label>
                  <ng-select formControlName="dhcp" name="dhcp" placeholder="Mode Options" (change)="changeType($event)"
                    class="dropdown-block" [searchable]="true" [clearable]="false">
                    <ng-option value="local"> Local </ng-option>
                    <ng-option value="relay"> Relay </ng-option>
                    <ng-option value="disabled"> Disabled </ng-option>
                  </ng-select>
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dhcp']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1" *ngIf="addDHCP.value.dhcp == 'local'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DHCP range start <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="First address of DHCP range. "></span> </label>
                  <input formControlName="dhcp_range_start" name="dhcp_range_start" type="text"
                    class="form-control form-control-lg form-control-solid" name="DHCP range start"
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dhcp_range_start']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1" *ngIf="addDHCP.value.dhcp == 'local'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DHCP range end <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Last address of DHCP range. "></span> </label>
                  <input formControlName="dhcp_range_end" name="dhcp_range_end" type="text"
                    class="form-control form-control-lg form-control-solid" name="DHCP range end" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dhcp_range_end']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 mb-5" *ngIf="addDHCP.value.dhcp == 'local'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DHCP lease time <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="The length of the lease time should be written as seconds (e.g. 100s), or minutes (e.g. 10m),
                  or hours (e.g. 12h), or as “infinite.” The lease time must be a minimum of 2 minutes."></span>
                  </label>
                  <input formControlName="dhcp_lease_time" name="dhcp_lease_time" type="text"
                    class="form-control form-control-lg form-control-solid" name="DHCP range end" placeholder="24h"
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dhcp_lease_time']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1" *ngIf="addDHCP.value.dhcp == 'local'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Domain <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Send this domain in DHCP responses."></span> </label>
                  <input formControlName="domain" name="domain" type="text"
                    class="form-control form-control-lg form-control-solid" name="Send this domain in DHCP responses."
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['domain']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1" *ngIf="addDHCP.value.dhcp == 'local'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">TFTP server <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Send this TFTP server address in DHCP responses."></span> </label>
                  <input formControlName="tftp_server" name="tftp_server" type="text"
                    class="form-control form-control-lg form-control-solid" name="TFTP" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['tftp_server']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1" *ngIf="addDHCP.value.dhcp == 'relay'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DHCP Relay Host 1 </label>
                  <input formControlName="relay_host_1" name="relay_host_1" type="text"
                    class="form-control form-control-lg form-control-solid" name="DNS Server 1      "
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['relay_host_1']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1" *ngIf="addDHCP.value.dhcp == 'relay'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DHCP Relay Host 2 </label>
                  <input formControlName="relay_host_2" name="relay_host_2" type="text"
                    class="form-control form-control-lg form-control-solid" name="DNS Server 2" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['relay_host_2']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1" *ngIf="addDHCP.value.dhcp == 'relay'">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DHCP Relay Host 3 </label>
                  <input formControlName="relay_host_3" name="relay_host_3" type="text"
                    class="form-control form-control-lg form-control-solid" name="DNS Server 3" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['relay_host_3']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedTab==2">
            <div class="row">
              <div class="col-md-12 col-lg-4 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DNS Server 1 </label>
                  <input formControlName="dns_server_1" name="dns_server_1" type="text"
                    class="form-control form-control-lg form-control-solid" name="DNS Server 1" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dns_server_1']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-4 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DNS Server 2 </label>
                  <input formControlName="dns_server_2" name="dns_server_2" type="text"
                    class="form-control form-control-lg form-control-solid" name="DNS Server 2" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dns_server_2']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-4 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DNS Server 3 </label>
                  <input formControlName="dns_server_3" name="dns_server_3" type="text"
                    class="form-control form-control-lg form-control-solid" name="DNS Server 3" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dns_server_3']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <label class="form-label">Enable DNS caching <span
                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="The servers given below will be sent to the clients in DHCP responses."></span>
                </label>
                <div class="d-flex flex-column">
                  <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="dns"
                    name="dns"></ui-switch>
                  <small class="text-danger" *ngIf="errors">
                    {{errors['dns']?.join(' ')}}
                  </small>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedTab==3">
            <div class="row">
              <div class="col-md-12 col-lg-10 mb-1">
                <label class="form-label">Custom configuration <span
                    [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="Custom configuration for the Dnsmasq service. See the Dnsmasq man page for details about what
                  is allowed here. Note that syntax errors will result in the service failing to start on the bonder."></span></label>
                <div class="d-flex flex-column">
                  <textarea cols="70" formControlName="custom_configuration" name="custom_configuration"
                    class="textarea-field br-6"></textarea>
                  <small class="text-danger" *ngIf="errors">
                    {{errors['custom_configuration']?.join(' ')}}
                  </small>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</ng-container>

<ng-container *ngIf="selectedOption==2">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title">Add DNS Caching Service</h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <form [formGroup]="addDnsCaching">
    <div class="modal-body p-0">
      <div class="overflow-auto mt-0 card border-0 border bg-light border-bottom-0">
        <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
          <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6 px-0">
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(1)" class="nav-link text-active-primary mx-0 px-5 mx-0 px-5"
                [class.active]="selectedTab==1">
                Standard configuration
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body p-5 bg-white">
          <ng-container>
            <div class="row">
              <div class="col-md-12 col-lg-12 mb-5">
                <p> DNS requests to the selected connected IPs will be forwarded to upstream DNS servers and cached. If
                  DNS servers are given below, they will be used as the upstream servers. If they are not given, the
                  node's own DNS servers will be used.</p>
              </div>
              <div class="col-md-12 col-lg-12 mb-5">
                <h5> Connected IPs </h5>
                <p> Choose which connected IPs the DNS caching service will bind to.</p>
                <div class="mb-5">
                  <div class="mb-2" *ngFor="let ip of connectedIPs">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" name="connectedips"
                      [checked]="ip.enable" (change)="onConnectedIPChange($event,ip)"></ui-switch>
                    <label class="form-label ms-2 mb-0">{{ip.ip}} on {{ip.ifname}} </label>
                    <small class="text-danger" *ngIf="errors">
                      {{errors['connectedips']?.join(' ')}}
                    </small><br>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 mb-1">
                <div class="col-md-12 d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="mb-0"> DNS servers </h5>
                  </div>
                  <div>
                    <button type="button" class="btn btn-primary btn-style" (click)="onAddDnsCachingServer()">
                      <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                          class="svg-icon svg-icon-block mb-2"></span>
                      </div> Add DNS Server
                    </button>
                  </div>
                </div>
                <div formArrayName="dns_servers" class="row mb-0">
                  <div [formGroupName]="i" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-1 
                    d-flex align-items-center gap-3" *ngFor="let x of dnsCachingServers?.controls;let i=index">
                    <div class="form-group inputtext-block mb-5">
                      <label class="form-label">DNS server {{i+1}} </label>
                      <input formControlName="dns" type="text" class="form-control form-control-lg form-control-solid"
                        name="dns_server_01" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="errors && errors['dns_servers'] 
                            && errors['dns_servers'][0] && errors['dns_servers'][0][i]">
                          {{errors['dns_servers'][0][i]?.join(' ')}}
                        </span>
                      </small>
                    </div>
                    <div class="mt-3">
                      <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemoveDnsCachingServer(i)">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSaveDnsCaching()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</ng-container>

<ng-container *ngIf="selectedOption==3">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title">Add DHCPv6 Prefix Delegation Service </h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <form [formGroup]="addDHCPV6">
    <div class="modal-body p-0">
      <div class="overflow-auto mt-0 card border-0 border bg-light border-bottom-0">
        <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
          <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6 px-0">
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(1)" class="nav-link text-active-primary mx-0 px-5"
                [class.active]="selectedTab==1">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span>
                DHCPv6-PD
              </a>
            </li>
            <li class="nav-item"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(2)" class="nav-link text-active-primary mx-0 px-5"
                [class.active]="selectedTab==2">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span> DNS
                servers
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body p-5 bg-white">
          <ng-container *ngIf="selectedTab==1" [class.active]="selectedTab==1">
            <div class="row">
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Connected IP <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="The connected IP the service will run on. "></span> </label>
                  <ng-select formControlName="connectedip" name="connectedip" class="dropdown-block">
                    <ng-option *ngFor="let ip of connectedIPs" [value]="ip.url"> {{ip.ip}} on {{ip.ifname}}
                    </ng-option>
                  </ng-select>
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['connectedip']?.join(' ')}}
                    </span>
                    <span *ngIf="submitted==true && fDHCPV6.connectedip.invalid 
                        && fDHCPV6.connectedip.errors?.required" class="text-danger">
                      This field is required
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Prefix delegation pool <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Network to delegate prefixes from via IA_PD, in CIDR format. "></span></label>
                  <input formControlName="prefix" name="prefix" type="text"
                    class="form-control form-control-lg form-control-solid" name="DHCP range start"
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['prefix']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Prefix delegation length <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Size of prefixes to delegate via IA_PD. Must be equal to or smaller than the prefix length of
                    the pool."></span> </label>
                  <input formControlName="delegated_len" name="delegated_len"
                    class="form-control form-control-lg form-control-solid" name=">Prefix delegation length"
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['delegated_len']?.join(' ')}}
                    </span>
                    <span *ngIf="submitted==true && fDHCPV6.delegated_len.invalid 
                        && fDHCPV6.delegated_len.errors?.required" class="text-danger">
                      This field is required
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label"> Excluded prefix <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" If given, this prefix will be excluded from prefix delegation. Its prefix length must be
                    smaller than the delegation prefix."></span> </label>
                  <input formControlName="excluded_prefix" name="excluded_prefix"
                    class="form-control form-control-lg form-control-solid" name="excluded_prefix" placeholder="24th"
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['excluded_prefix']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-2 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Enabled <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Enable the service."></span> </label>
                  <div class="d-flex flex-column mt-2">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="enabled"
                      name="enabled"></ui-switch>
                    <small></small>
                    <small class="text-danger" *ngIf="errors">
                      {{errors['enabled']?.join(' ')}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedTab==2" [class.active]="selectedTab==2">
            <div class="row">
              <div formArrayName="dns_servers" class="row mb-5">
                <div [formGroupName]="i" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-1 
                d-flex align-items-center gap-3" *ngFor="let x of dnsServers?.controls;let i=index">
                  <div class="form-group inputtext-block mb-5">
                    <label class="form-label">DNS server {{i+1}} </label>
                    <input formControlName="dns" type="text" class="form-control form-control-lg form-control-solid"
                      name="dns_server_01" autocomplete="off" />
                    <small class="form-invalid-message">
                      <span class="text-danger"
                        *ngIf="errors && errors['dns_servers'] && errors['dns_servers'][0] && errors['dns_servers'][0][i]">
                        {{errors['dns_servers'][0][i]?.join(' ')}}
                      </span>
                    </small>
                  </div>
                  <div class="mt-3">
                    <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemove(i)">
                      <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                          class="svg-icon svg-icon-block">
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-style" (click)="onAddDNSServer()">
              <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                  class="svg-icon svg-icon-block mb-2"></span>
              </div> Add DNS Server
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSaveDHCPV6()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</ng-container>

<ng-container *ngIf="selectedOption==4">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title">Add DHCPv6 Non-Temporary Address Service</h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <form [formGroup]="addDHCPNA">
    <div class="modal-body p-0">
      <div class="overflow-auto mt-0 card border-0 border bg-light border-bottom-0">
        <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
          <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6 px-0">
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(1)" class="nav-link text-active-primary mx-0 px-5"
                [class.active]="selectedTab==1">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'"
                  class="svg-icon svg-icon-5 me-2 "></span>DHCPv6-NA
              </a>
            </li>
            <li class="nav-item"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(2)" class="nav-link text-active-primary mx-0 px-5"
                [class.active]="selectedTab==2">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span> DNS
                servers
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body p-5 bg-white">
          <ng-container *ngIf="selectedTab==1" [class.active]="selectedTab==1">
            <div class="row">
              <div class="col-md-12 col-lg-5 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Connected IP <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="The connected IP the service will run on."></span> </label>
                  <ng-select formControlName="connectedip" name="connectedip" class="dropdown-block">
                    <ng-option *ngFor="let ip of connectedIPs" [value]="ip.url"> {{ip.ip}} on {{ip.ifname}}
                    </ng-option>
                  </ng-select>
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['connectedip']?.join(' ')}}
                    </span>
                    <span *ngIf="submitted==true && fDHCPNA.connectedip.invalid 
                        && fDHCPNA.connectedip.errors?.required" class="text-danger">
                      This field is required
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-5 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Address pool <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Network to delegate addresses from via IA_NA, in CIDR format."></span> </label>
                  <input formControlName="pool" name="pool" type="text"
                    class="form-control form-control-lg form-control-solid" name="Address pool                  "
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['pool']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-2 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Enabled <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Enable the service."></span> </label>
                  <div class="d-flex flex-column mt-2">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="enabled"
                      name="enabled"></ui-switch>
                    <small class="text-danger" *ngIf="errors">
                      {{errors['enabled']?.join(' ')}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedTab==2" [class.active]="selectedTab==2">
            <div class="row">
              <div formArrayName="dns_servers" class="row mb-5">
                <div [formGroupName]="i" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-1 
                d-flex align-items-center gap-3" *ngFor="let x of naDnsServers?.controls;let i=index">
                  <div class="form-group inputtext-block mb-5">
                    <label class="form-label me-5">DNS server {{i+1}} </label>
                    <input formControlName="dns" type="text" class="form-control form-control-lg form-control-solid"
                      name="dns_server_01" autocomplete="off" />
                    <small class="form-invalid-message">
                      <span class="text-danger"
                        *ngIf="errors && errors['dns_servers'] && errors['dns_servers'][0] && errors['dns_servers'][0][i]">
                        {{errors['dns_servers'][0][i]?.join(' ')}}
                      </span>
                    </small>
                  </div>
                  <div class="mt-3">
                    <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemoveNAServer(i)">
                      <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                          class="svg-icon svg-icon-block">
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-style" (click)="onNAAddDNSServer()">
              <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                  class="svg-icon svg-icon-block mb-2"></span>
              </div> Add DNS Server
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSaveDHCPNA()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</ng-container>

<ng-container *ngIf="selectedOption==5">
  <div class="modal-header px-5 py-3">
    <h5 class="modal-title">Add Router Advertisement Service </h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <form [formGroup]="addRouterAdv">
    <div class="modal-body p-0">
      <div class="overflow-auto mt-0 card border-0 border bg-light border-bottom-0">
        <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
          <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6 px-0">
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(1)" class="nav-link text-active-primary mx-0 px-5"
                [class.active]="selectedTab==1">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span>
                Router advertisement
              </a>
            </li>
            <li class="nav-item"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(2)" class="nav-link text-active-primary mx-0 px-5"
                [class.active]="selectedTab==2">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span>
                RDNSS
              </a>
            </li>
            <li class="nav-item"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
            <li class="nav-item text-start text-md-center text-lg-start">
              <a (click)="onTabChange(3)" class="nav-link text-active-primary mx-0 px-5"
                [class.active]="selectedTab==3">
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-5 me-2 "></span>
                DNSSL
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body p-5 bg-white">
          <ng-container>
            <div class="row" *ngIf="selectedTab == 1">
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Connected IP <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="The connected IP the service will run on."></span> </label>
                  <ng-select formControlName="connectedip" name="connectedip" class="dropdown-block">
                    <ng-option *ngFor="let ip of connectedIPs" [value]="ip.url"> {{ip.ip}} on {{ip.ifname}}
                    </ng-option>
                  </ng-select>
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['connectedip']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Enabled <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Enable the service."></span> </label>
                  <div class="d-flex flex-column mt-2">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="enabled"
                      name="enabled"></ui-switch>
                    <small></small>
                    <small class="text-danger" *ngIf="errors">
                      {{errors['enabled']?.join(' ')}}
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Prefix <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Prefix to advertise, in CIDR format."></span> </label>
                  <input formControlName="prefix" name="prefix" type="text"
                    class="form-control form-control-lg form-control-solid" name="Prefix" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['prefix']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Minimum advertisement interval <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Minimum interval between advertisements, in seconds."></span></label>
                  <input formControlName="min_advertise_interval" name="min_advertise_interval"
                    type="min_advertise_interval" class="form-control form-control-lg form-control-solid"
                    name="advertisement" placeholder="10" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['min_advertise_interval']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Maximum advertisement interval <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                      ngbTooltip="Maximum interval between advertisements, in seconds."></span></label>
                  <input formControlName="max_advertise_interval" name="max_advertise_interval" type="text"
                    class="form-control form-control-lg form-control-solid" name="advertisement" placeholder="10"
                    autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['max_advertise_interval']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-2 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Set managed flag <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Set the 'managed' (M) flag on advertisements, indicating that they may receive an address via
                  DHCPv6."></span></label>
                  <div class="d-flex flex-column mt-2">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="set_managed_flag"
                      name="set_managed_flag"></ui-switch>
                    <small class="text-danger" *ngIf="errors">
                      {{errors['set_managed_flag']?.join(' ')}}
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-4 mb-1">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">Set other configuration flag <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="Set the 'other configuration' (O) flag on advertisements, indicating that they may receive
                  other configuration options, such as DNS server information, via DHCPv6."></span> </label>
                  <div class="d-flex flex-column mt-2">
                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                      formControlName="set_other_config_flag" name="set_other_config_flag"></ui-switch>
                    <small class="text-danger" *ngIf="errors">
                      {{errors['set_other_config_flag']?.join(' ')}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedTab==2">
            <div class="row">
              <div class="col-md-12 col-lg-6 mb-3">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">RDNSS lifetime <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" How long, in seconds, a server advertised via RDNSS should be considered valid. If 0, the
                    server will be advertised as no longer valid for use."></span> </label>
                  <input formControlName="rdnss_lifetime" name="rdnss_lifetime" type="text"
                    class="form-control form-control-lg form-control-solid" name="RDNSS" autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['rdnss_lifetime']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 mb-1">
                <div class="col-md-12 d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="mb-0"> RDNSS Servers </h5>
                  </div>
                  <div>
                    <button type="button" class="btn btn-primary btn-style" (click)="onAddRdnssServer()">
                      <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                          class="svg-icon svg-icon-block mb-2"></span>
                      </div> Add RDNSS Server
                    </button>
                  </div>
                </div>
                <div formArrayName="rdnss_servers" class="row">
                  <div [formGroupName]="i" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-1 
                      d-flex align-items-center gap-3" *ngFor="let x of rdnssServers?.controls;let i=index">
                    <div class="form-group inputtext-block mb-5">
                      <label class="form-label">DNS server {{i+1}} </label>
                      <input formControlName="dns" type="text" class="form-control form-control-lg form-control-solid"
                        name="dns_server_01" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger"
                          *ngIf="errors && errors['rdnss_servers'] && errors['rdnss_servers'][0] && errors['rdnss_servers'][0][i]">
                          {{errors['rdnss_servers'][0][i]}}
                        </span>
                      </small>
                    </div>
                    <div class="mt-3">
                      <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemoveRdnssServer(i)">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedTab==3">
            <div class="row">
              <div class="col-md-12 col-lg-6 mb-3">
                <div class="form-group inputtext-block mb-5">
                  <label class="form-label">DNSSL lifetime <span
                      [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="How long, in seconds, a domain advertised via DNSSL should be considered valid. If 0, the
                    domain will be advertised as no longer valid."></span> </label>
                  <input formControlName="dnssl_lifetime" name="dnssl_lifetime" type="text"
                    class="form-control form-control-lg form-control-solid" name="DNSSL " autocomplete="off" />
                  <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="errors">
                      {{errors['dnssl_lifetime']?.join(' ')}}
                    </span>
                  </small>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 mb-1">
                <div class="col-md-12 d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="mb-0"> DNSSL domains </h5>
                  </div>
                  <div>
                    <button type="button" class="btn btn-primary btn-style" (click)="onAddDnsslServer()">
                      <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                          class="svg-icon svg-icon-block mb-2"></span>
                      </div> Add DNSSL Domain
                    </button>
                  </div>
                </div>
                <div formArrayName="dnssl_domains" class="row">
                  <div [formGroupName]="i" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-1 
                  d-flex align-items-center gap-3" *ngFor="let x of dnsslServers?.controls;let i=index">
                    <div class="form-group inputtext-block mb-5">
                      <label class="form-label">Domain {{i+1}} </label>
                      <input formControlName="dns" type="text" class="form-control form-control-lg form-control-solid"
                        name="domain_01" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger"
                          *ngIf="errors && errors['dnssl_domains'] && errors['dnssl_domains'][0] && errors['dnssl_domains'][i]">
                          {{errors['dnssl_domains'][0][i]}}
                        </span>
                      </small>
                    </div>
                    <div class="mt-3">
                      <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemoveDnsslServer(i)">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSaveRouterAdv()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</ng-container>