<div class="card mb-5 border-1 border">
    <div *ngIf="permissionsObj?.view" class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50">
        <div class="card-title m-0">
            <h4 class="fw-bolder m-0 fs-4">CPE NAT IP</h4>
        </div>
        <div class="card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Edit" *ngIf="permissionsObj?.update">
            <button type="button" class="btn btn-primary btn-style"
                [ngClass]="(latest_tuning && latest_tuning?.status == 'running')  || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                (click)="(latest_tuning && latest_tuning?.status == 'running')  || (bondPermission && bondPermission.isUpdateDisable) ? '': editCpe('')">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Add CPE NAT IP
            </button>
        </div>
    </div>

    <div class="card-body p-2">
        <div class="row g-5 g-xxl-8">
            <div class="col-md-12 col-lg-12 mb-8">
                <div *ngIf="permissionsObj?.view" class="table-responsive border">
                    <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                        <thead class="table-light">
                            <tr class="fw-bolder text-dark fs-7">
                                <th class="py-4 min-w-75px w-75px"> ID </th>
                                <th class="py-4 min-w-100px w-100px">Enabled </th>
                                <th class="py-4 min-w-300px w-300px">IP </th>
                                <th class="py-4">Destination </th>
                                <!-- <th class="py-4 min-w-100px w-100px text-center" *ngIf="ctsPermission">
                                    <span>CTS </span>
                                </th> -->
                                <th class="py-4 min-w-50px w-50px text-center">
                                    <span *ngIf="(permissionsObj.update || permissionsObj.delete)">Actions </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!(sharedService?.Loader?.isCPELoader) && allCpe && allCpe.length > 0">
                            <tr *ngFor="let cpe of allCpe;">
                                <td> {{ cpe.id }} </td>
                                <td *ngIf="cpe.enabled" class="ps-8">
                                    <a title="true">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                            class="svg-icon svg-icon-3">
                                        </span>
                                    </a>
                                </td>
                                <td *ngIf="!cpe.enabled" class="ps-8">
                                    <a title="false">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                                            class="svg-icon svg-icon-3 svg-icon-danger">
                                        </span>
                                    </a>
                                </td>
                                <td> {{ cpe.ip }} </td>
                                <td> {{ cpe.dest_nat_ip }} </td>
                                <!-- <td class="text-center" *ngIf="ctsPermission">
                                    <a title="Open Connection" *ngIf="getTunnelForCPE(cpe.id)"
                                        (click)="openConnection(cpe.id)"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-success btn-sm h-30px w-30px me-2 mat-menu-trigger">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/technology/teh007.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Create Tunnel" [ngbPopover]="cpeTunnelPopup" [autoClose]="'outside'"
                                        (click)="openTunnelPopup(cpe)" #cpeTunnelPopupButton="ngbPopover"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px mat-menu-trigger">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/coding/cod008.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <ng-template #cpeTunnelPopup>
                                        <div class="card">
                                            <div class="card-header px-5 py-3 d-flex justify-content-between"
                                                style="min-height: 40px;">
                                                <h5 class="m-0">
                                                    {{ cpeTunnelData ? 'Edit Tunnel' : 'Create Tunnel'}}
                                                </h5>
                                                <button type="button" class="btn-close"
                                                    (click)="cpeTunnelPopupButton.close()"></button>
                                            </div>
                                            <div class="card-body px-5 py-3">
                                                <div *ngIf="cpeTunnelData"
                                                    class="d-flex justify-content-end align-items-center pb-1">
                                                    <span class="last-update">
                                                        expired at <span class="text-danger fw-bolder me-2">
                                                            {{tunnelExpiry.hour}}:{{tunnelExpiry.minutes}}:{{tunnelExpiry.seconds}}
                                                        </span>
                                                    </span>
                                                </div>
                                                <form [formGroup]="CPETunnelForm">
                                                    <div class="card mb-5 mb-xl-0">
                                                        <div class="card-body p-0">
                                                            <div class="row" *ngIf="isTunnelError">
                                                                <div class="col-md-12">
                                                                    <div class="alert alert-danger p-2">
                                                                        Please enter IP & Port
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row align-items-center mb-5">
                                                                <label class="col-3 fw-bolder fs-7 text-black
                                                                    text-end separator-vertical">
                                                                    IP</label>
                                                                <div class="col-9">
                                                                    <div class="form-group inputtext-block">
                                                                        <input formControlName="ip" type="text"
                                                                            name="ip"
                                                                            class="form-control form-control-lg form-control-solid"
                                                                            autocomplete="off" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row align-items-center mb-5">
                                                                <label
                                                                    class="col-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                    Port</label>
                                                                <div class="col-9">
                                                                    <div class="form-group inputtext-block">
                                                                        <input formControlName="port" type="number"
                                                                            name="port"
                                                                            class="form-control form-control-lg form-control-solid"
                                                                            autocomplete="off" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row align-items-center mb-5">
                                                                <label
                                                                    class="col-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                    Expiry</label>
                                                                <div class="col-9">
                                                                    <div class="form-group inputtext-block">
                                                                        <ng-select formControlName="expiry"
                                                                            name="expiry" class="dropdown-block"
                                                                            [clearable]="false" [searchable]="false"
                                                                            placeholder="Select Expiry Time">
                                                                            <ng-option [value]="option.value"
                                                                                *ngFor="let option of expiryOptionList;">
                                                                                {{option.name}}
                                                                            </ng-option>
                                                                        </ng-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="card-footer px-5 py-3 d-flex justify-content-end">
                                                <button type="button" class="btn btn-danger btn-style"
                                                    *ngIf="cpeTunnelData" (click)="deleteTunnel(cpe.id)">
                                                    <div>
                                                        <span
                                                            [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                                                            class="svg-icon svg-icon-block mb-2"
                                                            *ngIf="!cpeTunnelDeleteLoader"></span>
                                                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'"
                                                            class="me-2 " *ngIf="cpeTunnelDeleteLoader"></span>
                                                    </div> Close Tunnel
                                                </button>
                                                <button type="button" class="btn btn-primary btn-style"
                                                    (click)="createUpdateTunnel(cpe)">
                                                    <div>
                                                        <span *ngIf="cpeTunnelData && !cpeTunnelEditLoader"
                                                            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                                                            class="svg-icon svg-icon-block mb-2"></span>
                                                        <span *ngIf="!cpeTunnelData && !cpeTunnelEditLoader"
                                                            [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
                                                            class="svg-icon svg-icon-block mb-2"></span>
                                                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'"
                                                            class="mb-2" *ngIf="cpeTunnelEditLoader"></span>
                                                    </div> {{ cpeTunnelData ? 'Update':'Create' }}
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </td> -->
                                <td class="text-center">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false : true" title="Edit"
                                            [ngClass]="(latest_tuning && latest_tuning?.status == 'running')  || (bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? 'disable-tune':''"
                                            (click)="(latest_tuning && latest_tuning?.status == 'running')  || (bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update ? '': editCpe(cpe)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false : true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': deleteCpe(cpe)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-preloader-table-list *ngIf="sharedService?.Loader?.isCPELoader"
                        [length]="2"></app-preloader-table-list>
                    <div *ngIf="!(sharedService?.Loader?.isCPELoader) && allCpe && allCpe.length <= 0"
                        class="text-center font16 mt15 mb15 pt-4">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                        </h3>
                    </div>
                </div>
                <div *ngIf="!permissionsObj?.view">
                    You have no permission to view the data. Kindly contact the authority.
                </div>
            </div>
        </div>
    </div>
</div>