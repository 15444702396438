<!-- Dashboard -->
<ng-container *ngIf="menuConfig && menuConfig?.items">
  <div class="top-menu-logo d-flex align-items-center me-3">
    <img *ngIf="navBarImageUrl" [src]="navBarImageUrl" class="h-30px" />
    <a [routerLink]="routerLink" class="ms-3">
      <span *ngIf="navBarText" class="logo logo-text ps-0">{{navBarText}}</span>
    </a>
  </div>
  <div *ngFor="let menuItem of menuConfig?.items; let i=index" class="menu-item me-lg-1" (click)="toggleMenu()">
    <ng-container *ngIf="!menuItem.submenu">
      <a (click)="onSideMenuClick($event, menuItem.page)" class="menu-link p-3" routerLinkActive="active"
        [routerLink]="menuItem.page">
        <span [inlineSVG]="menuItem.svg" class="svg-icon svg-icon-2 me-2"></span>
        <span class="menu-title" *ngIf="menuItem.title" [translate]="menuItem.translate"></span></a>
    </ng-container>
  </div>

  <!-- Layout Builder -->
  <!-- <div class="menu-item me-lg-1">
  <a class="menu-link py-3" routerLinkActive="active" routerLink="/builder"><span class="menu-title">Layout
      Builder</span></a>
  </div> -->

  <!-- Crafted -->
  <!-- <div class="menu-item menu-lg-down-accordion me-lg-1 menu-dropdown" data-kt-menu-trigger="click"
  data-kt-menu-placement="bottom-start">
  <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted')"><span
      class="menu-title">Crafted</span></span>
  <div class="
      menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0
      py-lg-4
      w-lg-225px
    " data-kt-menu-dismiss="true" data-popper-placement="bottom-start">
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
      data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/pages')"><span class="menu-icon"><i
            class="bi fs-3 bi-archive"></i></span><span class="menu-title">Pages</span><span
          class="menu-arrow"></span></span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
          data-kt-menu-placement="right-start">
          <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/pages/profile')"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"
              data-link="/crafted/pages/profile">Profile</span><span class="menu-arrow"></span></span>
          <div class="
              menu-sub
              menu-sub-lg-down-accordion
              menu-sub-lg-dropdown
              menu-rounded-0
              py-lg-4
              w-lg-225px
            " data-kt-menu-dismiss="true">
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/overview"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
                  class="menu-title">Overview</span></a>
            </div>
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/projects"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
                  class="menu-title">Projects</span></a>
            </div>

            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/campaigns"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
                  class="menu-title">Campaigns</span></a>
            </div>

            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/documents"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
                  class="menu-title">Documents</span></a>
            </div>

            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/connections"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
                  class="menu-title">Connections</span></a>
            </div>
          </div>
        </div>
        <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
          data-kt-menu-placement="right-start">
          <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/pages/wizards')"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"
              data-link="/crafted/pages/wizards">Wizards</span><span class="menu-arrow"></span></span>
          <div class="
              menu-sub
              menu-sub-lg-down-accordion
              menu-sub-lg-dropdown
              menu-rounded-0
              py-lg-4
              w-lg-225px
            " data-kt-menu-dismiss="true">
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/wizards/horizontal"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
                  class="menu-title">Horizontal</span></a>
            </div>
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/wizards/vertical"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
                  class="menu-title">Vertical</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
      data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/account')"><span class="menu-icon"><i
            class="bi fs-3 bi-person"></i></span><span class="menu-title"
          data-link="/crafted/account">Accounts</span><span class="menu-arrow"></span></span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/account/overview"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Overview</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/account/settings"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Settings</span></a>
        </div>
      </div>
    </div>
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
      data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/error')"><span class="menu-icon"><i
            class="bi fs-3 bi-sticky"></i></span><span class="menu-title" data-link="/error">Errors</span><span
          class="menu-arrow"></span></span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/error/404"><span class="menu-bullet"><span
                class="bullet bullet-dot"></span></span><span class="menu-title">Error 404</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/error/500"><span class="menu-bullet"><span
                class="bullet bullet-dot"></span></span><span class="menu-title">Error 500</span></a>
        </div>
      </div>
    </div>
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
      data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/widgets')"><span class="menu-icon"><i
            class="bi fs-3 bi-layers"></i></span><span class="menu-title"
          data-link="/crafted/widgets">Widgets</span><span class="menu-arrow"></span></span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/lists"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Lists</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/statistics"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Statistics</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/charts"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Charts</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/mixed"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Mixed</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/tables"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Tables</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/feeds"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
              class="menu-title">Feeds</span></a>
        </div>
      </div>
    </div>
  </div>
</div> -->
  <!-- Apps -->

  <div *ngFor="let menuItem of menuConfig?.items; let i=index" class="menu-item menu-lg-down-accordion me-lg-1">
    <ng-container *ngIf="menuItem.submenu">
      <!-- <a (click)="onSideMenuClick($event, menuItem.page)" class="menu-link py-3" routerLinkActive="active"
    [routerLink]="menuItem.page">
    <span class="menu-title" *ngIf="menuItem.title" [translate]="menuItem.translate"></span></a> -->
      <span class="menu-link" [ngbPopover]="submenus" [class.active]="isActiveSubmenuHeader(menuItem.page)">
        <span class="menu-icon">
          <span class="svg-icon svg-icon-2 me-2" [inlineSVG]="menuItem.svg"></span>
        </span>
        <span class="menu-title" data-link="/crafted/account" [translate]="menuItem.translate"></span>
        <span class="menu-arrow"></span>
      </span>

      <ng-template #submenus>
        <!-- {{menuItem.submenu | json}} -->
        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px d-block">
          <div *ngFor="let subMenuItem of menuItem.submenu; let i=index"
            class="menu-item menu-lg-down-accordion me-lg-1">
            <div *ngIf="!subMenuItem.submenu; else treeStructure" class="menu-item mb-2">
              <a class="menu-link without-sub" (click)="onSideMenuClick($event, subMenuItem.page)"
                routerLinkActive="active" [routerLink]="subMenuItem.page">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2 me-2" [inlineSVG]="subMenuItem.svg"></span>
                </span>
                <span class="menu-title">{{subMenuItem.title}}</span>
              </a>
            </div>

            <ng-template #treeStructure>
              <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="click"
                routerLinkActive="here show" (mouseenter)="showSubMenu()" (mouseleave)="hideSubMenu()">
                <!-- <ng-container *ngIf="menuItem.submenu"> -->
                <!-- <span class="menu-link">
                  <span class="menu-icon">
                    <span class="bullet bullet-dot"></span>
                  </span>
                  <span class="menu-title" data-link="/crafted/account"
                    [translate]="subMenuItem.translate">{{subMenuItem.title}}</span>
                </span> -->
                <div>
                  <a class="menu-link without-sub" routerLinkActive="active" [routerLink]="subMenuItem.page">
                    <span class="menu-icon">
                      <span class="svg-icon svg-icon-2 me-2" [inlineSVG]="subMenuItem.svg"></span>
                    </span>
                    <span class="menu-title">{{ subMenuItem.title }}</span>
                    <span class="menu-arrow"></span>
                  </a>
                </div>
                <div [style.display]="isSubMenuVisible ? 'block' : 'none'"
                  class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px third-block">
                  <div *ngFor="let subItem of subMenuItem.submenu; let i=index"
                    class="menu-item menu-lg-down-accordion me-lg-1">
                    <div>
                      <a class="menu-link without-sub" (click)="onSideMenuClick($event, subItem.page)"
                        routerLinkActive="active" [routerLink]="subItem.page">
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot"></span>
                        </span>
                        <span class="menu-title">{{ subItem.title }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <!-- <span class="menu-link py-3" ><span class="menu-icon"><span
            class="svg-icon svg-icon-2"
            [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'"></span></span><span class="menu-title"
          data-link="/apps/chat">Chat</span></span> -->

          </div>
        </div>
      </ng-template>
      <!-- <span class="menu-link py-3" ><span
      class="menu-title" [translate]="menuItem.translate">{{menuItem.title}}</span></span> -->
      <!-- data-kt-menu-trigger="{default:'click', lg: 'hover'}"
      data-kt-menu-placement="right-start" -->


    </ng-container>
  </div>

  <!-- <div class="menu-item documentaion-menu-top" (click)="openDoc()">
    <span class="menu-link">
      
      <span class="menu-icon"> <span [inlineSVG]="'./assets/media/icons/duotune/icon/book.svg'"  class="svg-icon svg-icon-2 me-3"></span>
      </span>
    
    <span class="menu-title">Documentaion</span>
  
  </span>
</div> -->
</ng-container>


<!-- MegaMenu -->
<!-- <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="click"
  data-kt-menu-placement="bottom-start">
  <span class="menu-link py-3"><span class="menu-title">Mega menu</span></span>
  <div class="
      menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown
      w-100 w-lg-600px
      p-5 p-lg-5
    " data-kt-menu-dismiss="true">
    <div class="row" data-kt-menu-dismiss="true">
      <div class="col-lg-4 border-left-lg-1">
        <div class="menu-inline menu-column menu-active-bg">
          <div class="menu-item">
            <a class="menu-link cursor-pointer"><span class="menu-bullet"><span
                  class="bullet bullet-dot"></span></span><span class="menu-title">Example link</span></a>
          </div>
          <div class="menu-item">
            <a class="menu-link cursor-pointer"><span class="menu-bullet"><span
                  class="bullet bullet-dot"></span></span><span class="menu-title">Example link</span></a>
          </div>
          <div class="menu-item">
            <a class="menu-link cursor-pointer"><span class="menu-bullet"><span
                  class="bullet bullet-dot"></span></span><span class="menu-title">Example link</span></a>
          </div>
          <div class="menu-item">
            <a class="menu-link cursor-pointer"><span class="menu-bullet"><span
                  class="bullet bullet-dot"></span></span><span class="menu-title">Example link</span></a>
          </div>
          <div class="menu-item">
            <a class="menu-link cursor-pointer"><span class="menu-bullet"><span
                  class="bullet bullet-dot"></span></span><span class="menu-title">Example link</span></a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->