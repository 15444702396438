import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AppConst } from '../../constants/app.constant';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-shared-error-logs',
  templateUrl: './shared-error-logs.component.html',
  styleUrls: ['./shared-error-logs.component.scss']
})
export class SharedErrorLogsComponent implements OnInit {


  page: any = 1;
  limit: number = AppConst.pageSize;
  searchKey: any = null;
  status: any;
  //length: any;
  searchInput: any = '';
  index: number = 1;
  searchControl: FormControl = new FormControl();

  @Output() onSearch = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Output() onSortFields = new EventEmitter<any>();
  @Output() onReceiveMessage = new EventEmitter<any>();

  @Input() logs: any[] = [];
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() length: any;
  @Input() permissions: any = {};

  constructor(private sharedService: SharedService) { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }


  ngOnInit(): void {
    this.sharedService.showLoader();
    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data: any) => {
        if (data != null || data != undefined) {
          this.searchKey = data;
          this.onSearch.emit(data);
        }
      })
  }


  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  onResetFilter() {
    this.searchControl.patchValue('');
    this.onReset.emit();
  }

  onSort(event: any) {
    this.onSortFields.emit(event);
  }

  onDeleteLogs(logId: any) {
    if (logId) {
      let questionTitle = 'Are you sure?';
      let text = "<b>You are deleting this log. <br>It can't be revert back! </b>"
      let confirmButtonText = "Yes, Delete it!"
      this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
        if (result.isConfirmed) {
          this.sharedService.showLoader();
          this.onDelete.emit(logId)
        }
      })

    }
  }

  receiveMessage(event: any) {
    this.onReceiveMessage.emit(event);
    // this.limit = event.pageSize;
    // this.page = event.pageIndex;
  }
}
