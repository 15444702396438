import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedBondToSpaceMoveComponent } from '../../shared-bonds-listing/shared-bond-to-space-move/shared-bond-to-space-move.component';
import { SharedBondsViewConfigModalComponent } from '../shared-bonds-view-config-modal/shared-bonds-view-config-modal.component';

@Component({
  selector: 'app-shared-bonds-view-configuration-details',
  templateUrl: './shared-bonds-view-configuration-details.component.html',
  styleUrls: ['./shared-bonds-view-configuration-details.component.scss']
})
export class SharedBondsViewConfigurationDetailsComponent implements OnInit {
  show_eye: boolean;
  latest_tuning: any;
  bondPermission: any;
  isHomeSpace: boolean = true;
  isPartner: boolean = false;
  isSharedBond: boolean = false;
  isSdwanSharedBond: boolean = false;
  homeSpace: any;
  networkConfigurationPermissionsObj: any = { view: true, update: true, delete: true };

  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() bondDetails: any;
  @Input() bonderDetails: any;
  @Input() nodeDetails: any;
  @Input() name: any;
  @Input() allClassificationList: any[] = [];

  @Output() getBondStatus = new EventEmitter<any>();
  @Output() getClassificationName = new EventEmitter<any>();

  constructor(private partnerBondingService: PartnerBondingService,
    private sharedService: SharedService, private storageService: StorageService,
    private spaceService: PartnerSpaceService, private router: Router,
    private cd: ChangeDetectorRef, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.sharedService.hideLoader();
    if (Number(this.storageService.getCipherObj('role')) === AppConst.partner ||
      (Number(this.storageService.getCipherObj('role')) == AppConst.user &&
        Number(this.storageService.getCipherObj('parentRole')) == AppConst.partner)) this.isPartner = true;
    if (this.storageService.getCipherObj('homeSpace')) {
      if (this.storageService.getCipherObj('homeSpace') == '0' && !this.isPartner) this.homeSpace = null;
      else this.homeSpace = this.storageService.getCipherObj('homeSpace');
    } else this.homeSpace = null;
    if (this.storageService.getCipherObj('homeSpace') == '0') this.isHomeSpace = false;
    this.isSdwanSharedBond = this.storageService.getCipherBoolean('isSdwanSharedBond') ?? false;
    this.isSharedBond = this.storageService.getCipherBoolean('isSharedBond') ?? false;
    this.bonderDetails.dns_servers_list = ['8.8.8.8', '8.8.4.4'];
    this.partnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
    this.getPermission();
  }

  getPermission() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.networkConfigurationPermissionsObj = { view: true, update: true, delete: true }
    } else {
      this.sharedService.getPermission();
      if (this.sharedService.noPermission) this.networkConfigurationPermissionsObj = { view: true, update: true, delete: true }
      else this.networkConfigurationPermissionsObj = this.sharedService.networkConfiguration;
    }
  }

  getBondById(bondId: any) {
    this.sharedService.showLoader();
    let url = `bonds/${bondId}`, method = "GET";
    this.partnerBondingService.viewBond(method, url).subscribe((bondData: any) => {
      this.bondDetails = bondData.data
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.hideLoader();
    });
  }

  getBonderById(bonderId: any) {
    this.sharedService.showLoader();
    let url = `bonders/${bonderId}`, method = "GET";
    this.partnerBondingService.viewBond(method, url).subscribe((bonderData: any) => {
      this.bonderDetails = bonderData.data;
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.hideLoader();
    })
  }

  keyFormate(key: string) {
    if (!key) return;
    return key.match(/.{1,5}/g)?.join('-');
  }

  openGeneralConfig() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.option = 2;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondStatus.emit();
      this.cd.detectChanges();
    }, () => { });
  }

  openSecurityAndOptimization() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.allClassificationList = this.allClassificationList;
    modal.componentInstance.option = 3;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondStatus.emit();
      this.cd.detectChanges();
    }, () => { });
  }

  openWanServices() {
    let modal = this.modalService.open(SharedBondsViewConfigModalComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.bonderDetails = this.bonderDetails;
    modal.componentInstance.nodeDetails = this.nodeDetails;
    modal.componentInstance.option = 4;
    modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.getBondStatus.emit();
        setTimeout(() => this.getClassificationName.emit(), 1500);
        this.cd.detectChanges();
      }
    }, () => { });
  }

  navigateToBond() {
    this.router.navigate([`/partner/bonds/edit/${this.bondDetails.id}/${this.bondDetails.bonder.id}`], { queryParams: { isconfig: true } })
  }

  navigateToSpace(spaceDetails: any) {
    if (!spaceDetails) return;
    this.spaceService.setSpaceParentId(spaceDetails.key);
    this.spaceService.setSpaceData(spaceDetails);
    this.cd.detectChanges();
    this.router.navigate([`/partner/space/edit`])
  }

  openSpacepopup() {
    let url = `spaces/?as_tree=true`;
    let method = 'GET';
    this.sharedService.showLoader();
    this.partnerBondingService.getResponse(url, method).subscribe((res: any) => {
      this.sharedService.hideLoader();
      let allSpaceList = [];
      if (res && res.data && res.data.length > 0) {
        if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
          allSpaceList = res.data;
        } else if (Number(this.storageService.getCipherObj('role')) == AppConst.organization) {
          if (this.homeSpace != null) {
            const findKey = (array: any = [], id: string): any => {
              for (const item of array) {
                const r = item.key === id ? item : findKey(item.child_spaces, id);
                if (r) return r;
              }
            };
            let result = findKey(res.data, this.homeSpace);
            let tmpSpaceList = [];
            tmpSpaceList.push(result);
            allSpaceList = tmpSpaceList;
          }
        } else if (Number(this.storageService.getCipherObj('role')) == AppConst.user) {
          if (Number(this.storageService.getCipherObj('parentRole')) == AppConst.partner) {
            allSpaceList = res.data;
          } else if (Number(this.storageService.getCipherObj('parentRole')) == AppConst.organization) {
            if (this.homeSpace != null) {
              const findKey = (array: any = [], id: string): any => {
                for (const item of array) {
                  const r = item.key === id ? item : findKey(item.child_spaces, id);
                  if (r) return r;
                }
              };
              let result = findKey(res.data, this.homeSpace);
              let tmpSpaceList = [];
              tmpSpaceList.push(result);
              allSpaceList = tmpSpaceList;
            }
          }
        }
      }
      if (allSpaceList && allSpaceList.length > 0) {
        let modal = this.modalService.open(SharedBondToSpaceMoveComponent, { size: 'lg' });
        modal.componentInstance.bondDetails = allSpaceList;
        modal.componentInstance.selectedOption = this.bondDetails.space.key;
        modal.componentInstance.isConfig = true;
        modal.componentInstance.bondId = this.bondDetails?.id;
        modal.componentInstance.bonderId = this.bondDetails?.bonder?.id;
        modal.result.then((result: any) => {
          if (result.event == 'save') {
            this.getBondById(this.bondDetails.id);
            this.getBonderById(this.bondDetails.bonder.id)
          }
        });
      }
    });
  }

  timeconverter(sec: number) {
    if (sec < 60) return `${sec.toFixed()} seconds`;
    else {
      sec = sec / 60;
      if (sec < 60) return `${sec.toFixed()} minutes`;
      else {
        sec = sec / 60;
        if (sec < 60) return `${sec.toFixed()} hours`;
        else {
          sec = sec / 24;
          if (sec < 24) return `${sec.toFixed()} days`;
          else {
            sec = sec / 30;
            return `${sec.toFixed()} months`;
          }
        }
      }
    }
  }

  getClassificationPolicyName(url: string) {
    if (!url) return '';
    let policy: any = this.allClassificationList.find((item) => item.url === url);
    if (policy && policy.display_name) return policy.display_name;
    return 'Unavailable';
  }
}
