import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerOrganizationManagementService } from 'src/app/services/partner-organization-management.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-bond-sharing-modal',
  templateUrl: './shared-bond-sharing-modal.component.html',
  styleUrls: ['./shared-bond-sharing-modal.component.scss']
})
export class SharedBondSharingModalComponent implements OnInit {
  bondData: any;
  isMultiple: boolean;
  orgList: any[] = [];
  selectedOrg: string = '';
  permissions: any;
  isOrganization: boolean = false;
  isPreloader: boolean = false;
  errors: any;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private partnerOrgService: PartnerOrganizationManagementService,
    private cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.getOrgData();
  }

  getOrgData() {
    this.isPreloader = true;
    this.partnerOrgService.getAllActiveOrg().subscribe((res: any) => {
      if (res.code == 200 || res.code == 201) {
        if (res && res.data) this.orgList = res.data;
        this.isOrganization = true;
      } else this.sharedService.loggerError(res.message);
      this.isPreloader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isPreloader = false;
      this.cd.detectChanges();
    });
  }

  onSave() {
    if (!this.selectedOrg) return;
    let payload = {
      "bondData": this.bondData,
      "permissions": { "view": true, "update": this.permissions.update || false, "delete": this.permissions.delete || false }
    }
    this.sharedService.showLoader();
    this.partnerOrgService.sharedBond(this.selectedOrg, payload).subscribe((res: any) => {
      if (res.code == 200 || res.code == 201) {
        if (this.isMultiple) {
          if (res.sharedBondsIds && res.sharedBondsIds.length <= 0) this.sharedService.loggerError(`${res.duplicateBondId ? res.duplicateBondId?.join(',') + ' was already shared.' : ''}`);
          else if (res.duplicateBondId && res.duplicateBondId.length <= 0) this.sharedService.loggerSuccess(`${res.sharedBondsIds ? res.sharedBondsIds?.join(',') + ' has been shared successfully.' : ''}`);
          else this.sharedService.loggerSuccess(`${res.sharedBondsIds ? res.sharedBondsIds?.join(',') + ' has been shared successfully & ' : ''} ${res.duplicateBondId ? res.duplicateBondId?.join(',') + ' was already shared.' : ''} ${res.issueBondsIds.length > 0 ? res.issueBondsIds?.join(',') + ' faced some issue.' : ''}`);
        } else this.sharedService.loggerSuccess('The bond has been shared successfully');
      } else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
      this.activeModal.close({ event: 'save' });
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        // JSON parsing successful, handle the JSON-encoded error
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError(err);
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
