<!-- container to loop on array of menu items -->
<ng-container *ngIf="menuConfig && menuConfig?.items">
  <!-- Dashboard -->
  <div class="menu-item" *ngFor="let menuItem of menuConfig?.items; let i=index" (click)="toggleMenu()">
    <!-- If menu don't have submenu -->
    <ng-container *ngIf="!menuItem.submenu">
      <a class="menu-link without-sub" (click)="onSideMenuClick($event, menuItem.page)" [routerLink]="menuItem.page"
        routerLinkActive="active"><span class="menu-icon">
          <span [inlineSVG]="menuItem.svg" class="svg-icon svg-icon-2"></span>
        </span>
        <span class="menu-title" *ngIf="menuItem.title" [translate]="menuItem.translate"></span></a>
    </ng-container>
  </div>

  <!-- If menu have submenu -->
  <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show"
    *ngFor="let menuItem of menuConfig?.items; let i=index">
    <ng-container *ngIf="menuItem.submenu">
      <span class="menu-link">
        <span class="menu-icon">
          <span class="svg-icon svg-icon-2" [inlineSVG]="menuItem.svg"></span>
        </span>
        <span class="menu-title" data-link="/crafted/account" [translate]="menuItem.translate"></span>
        <span class="menu-arrow"></span>
      </span>
      <!-- Loop for submenu  -->
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg"
        *ngFor="let subMenuItem of menuItem.submenu; let i=index">
        <div *ngIf="!subMenuItem.submenu; else treeStructure" class="menu-item mb-2">
          <a class="menu-link without-sub" (click)="onSideMenuClick($event, subMenuItem.page)" routerLinkActive="active" [routerLink]="subMenuItem.page">
            <span class="menu-icon">
              <span class="svg-icon svg-icon-4" [inlineSVG]="subMenuItem.svg"></span>
            </span>
            <span class="menu-title">{{subMenuItem.title}}</span></a>
        </div>
        <ng-template #treeStructure>

          <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
      <!-- <ng-container *ngIf="menuItem.submenu"> -->
        <span class="menu-link">
          <span class="menu-icon">
            <span class="svg-icon svg-icon-2" [inlineSVG]="subMenuItem.svg"></span>
          </span>
          <span class="menu-title" data-link="/crafted/account" [translate]="subMenuItem.translate">{{subMenuItem.title}}</span>

          <span class="menu-arrow"></span>
        </span>
        <!-- Loop for submenu  -->
        <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg"
          *ngFor="let subItem of subMenuItem.submenu; let i=index">
          <div  class="menu-item" *ngIf="!subItem.submenu; else treeStructure1">
            <a class="menu-link without-sub" (click)="onSideMenuClick($event,subItem.page)" routerLinkActive="active" [routerLink]="subItem.page">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <!-- <span class="menu-icon">
                <span class="svg-icon svg-icon-2" [inlineSVG]="subMenuItem.svg"></span>
              </span> -->
              <span class="menu-title">{{subItem.title}}</span></a>
          </div>
          <ng-template #treeStructure1>
            <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg"
                     *ngFor="let item of subItem.submenu; let j=index">
                     <div  class="menu-item">
                       <a class="menu-link without-sub" (click)="onSideMenuClick($event, item.page)" routerLinkActive="active" [routerLink]="item.page">
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2" [inlineSVG]="menuItem.svg"></span>
                        </span>
                         <span class="menu-title">{{item.title}}</span></a>
                     </div>
                   </div>
                   </ng-template>
        </div>


      <!-- </ng-container> -->
    </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
    
     
    <!-- <ng-container class="documentaion">
      <div class="menu-item documentaion-menu" (click)="openDoc()">
    <span class="menu-link">
      
      <span class="menu-icon"> <span [inlineSVG]="'./assets/media/icons/duotune/icon/book.svg'"  class="svg-icon svg-icon-2 me-3"></span>
      </span>
    
    <span class="menu-title">Documentaion</span>
  
  </span>
</div>
</ng-container> -->



</ng-container>
