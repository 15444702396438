<div class="modal-header px-5 py-3">
    <h5 class="modal-title">Select Alert Group </h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="card mb-3 mb-xl-0">
    <div class="card-body p-0" *ngIf="!preLoaderScreen &&  alertGroupData?.length">
        <div class="border-bottom px-1" *ngFor="let alertData of alertGroupData; let i = index">
            <div class="col-sm-12 col-md-12" *ngIf="alertData?.groupName && alertData.bondList">
                <div class="d-flex align-items-center p-3">
                    <div class="switch-width">
                        <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                            [checked]="(alertData.bondList.includes(bondId) == true || alertData.bondList.includes('' + bondId) == true) && !isMultiple ? true : false"
                            (change)="onChange($event,alertData._id, bondId,i)">
                            <i class="fa fa-spinner fa-pulse" *ngIf="loading[i] == true"></i>
                        </ui-switch>
                    </div>
                    <div class="ms-2">
                        <label class="text-capitalize fw-bold fs-6">
                            {{alertData.groupName}}</label>
                        <div class="">
                            {{'('}}
                            <a *ngFor="let recipients of alertData.toEmail; let j=index " class="text-dark">
                                <span *ngIf="j == 0">
                                    {{recipients}}
                                </span>
                                <span *ngIf="j != 0">
                                    {{', '+recipients}}
                                </span>
                            </a>
                            {{')'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" *ngIf="preLoaderScreen">
        <div class="d-flex align-items-center justify-content-center flex-column">
            <div class="d-flex align-items-center w-100 px-5 justify-content-between p-3 border-bottom">
                <div class="lines box shine" style="height: 22px;border-radius: 14px;"></div>
                <div class="d-flex flex-column w-100 ps-2">
                    <div class="lines shine w-100" style="height: 16px;"></div>
                    <div class="lines shine w-100"></div>
                </div>
            </div>
            <div class="d-flex align-items-center w-100 px-5 justify-content-between p-3 border-bottom">
                <div class="lines box shine" style="height: 22px;border-radius: 14px;"></div>
                <div class="d-flex flex-column w-100 ps-2">
                    <div class="lines shine w-100" style="height: 16px;"></div>
                    <div class="lines shine w-100"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!alertGroupData?.length && !preLoaderScreen">
    <div class="card text-center">
        <div class="card-body p-0 ">
            <h4 class="mt-5 mb-5 "> <span> No records found. </span> </h4>
        </div>
    </div>
</div>
<div class="modal-footer px-5 py-3" *ngIf="isMultiple">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal"
        (click)="alertGroupData?.length > 0 ? onSave(): ''" [disabled]="alertGroupData?.length > 0 ? false: true">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>