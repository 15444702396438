import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-bond-to-space-move',
  templateUrl: './shared-bond-to-space-move.component.html',
  styleUrls: ['./shared-bond-to-space-move.component.scss']
})
export class SharedBondToSpaceMoveComponent implements OnInit {
  bondDetails: any;
  bondId: number;
  bonderId: number;
  count: any;
  selectedOption: string;
  body: any;
  selectedSpace: any;
  isConfig: boolean;
  errors: any;

  @Output() onSaveSpace = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private partnerBondingService: PartnerBondingService,
    private cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.sharedService.selectedSpaceSubject.subscribe((res: any) => {
      this.selectedSpace = res.data;
    });
  }

  onSave() {
    let payload = {
      bond: {
        space: {
          url: this.selectedSpace.url,
          name: this.selectedSpace.name,
          key: this.selectedSpace.key,
          private_wan_enabled: false
        }
      },
      bonder: {}
    }
    this.sharedService.showLoader();
    this.partnerBondingService.moveBondToSpace(payload, this.bondId, this.bonderId, 'GET').subscribe((res: any) => {
      if (res.code != 400) {
        if (res.data && res.data.bondCode == 200 && res.data.bonderCode == 200) {
          this.sharedService.loggerSuccess('The bond has been moved successfully');
          this.activeModal.close({ event: 'save' });
        } else {
          if (res.data.bondCode != 200) this.errors = res.data.bond;
          if (res.data.bonderCode != 200) this.errors = res.data.bonder;
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
      } else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError(err);
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }

    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
