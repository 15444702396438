<div class="card mb-5 mb-xl-8 border-1 border border-top-0">
  <div class="card-body p-0">
    <div class="card-header d-flex justify-content-between mb-0 p-4" *ngIf="spaceData && spaceData?.level !== 0">
      <h2 class="m-0 fs-4 d-flex justify-content-center gap-3"> <span class="text-muted">Parent : </span>
        <span class="shine" style="height: 100%;width: 200px;" *ngIf="!parentName"></span>
        <span *ngIf="parentName">{{parentName}}</span>
      </h2>
      <!-- <h3 class="m-0 fs-4" *ngIf="isEdit && spaceData?.created"> <span class="text-muted">Created : </span>
        {{ spaceData?.created | date:'MMM. dd,yyyy'}}</h3> -->
    </div>
    <form action="#" [formGroup]="SpaceAdd" class="p-5 pb-0">
      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4 mb-1">
          <div class="form-group inputtext-block mb-5">
            <label class="form-label col-md-12"> Name </label>
            <div *ngIf="!isSpaceLoader">
              <input type="text" class="form-control form-control-lg form-control-solid mb-1" formControlName="name"
                name="name" autocomplete="off" />
              <small class="form-invalid-message">
                <span *ngIf="controlHasError('required', 'name')" class="text-danger">
                  This field is required
                </span>
                <span class="text-danger">
                  {{errors['name']?.join(' ')}}
                </span>
                <span class="invalid-feedback d-block" *ngIf="controlHasError('pattern', 'name')">
                  Name is invalid
                </span>
              </small>
            </div>
          </div>
          <div class="shine w-100 br-6" style="height: 36px;" *ngIf="isSpaceLoader"></div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-1">
          <div class="form-group inputtext-block mb-5">
            <label class="form-label col-md-12"> Key <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" Short string that uniquely identifies the space; used in node hostnames, ISO file filenames, etc.
              (1-6 lowercase alphanumeric characters) "></span> </label>
            <div *ngIf="!isSpaceLoader">
              <input type="text" class="form-control form-control-lg form-control-solid mb-1" formControlName="key"
                name="key" autocomplete="off" />
              <small class="form-invalid-message">
                <span *ngIf="controlHasError('required', 'key')" class="text-danger">
                  This field is required
                </span>
                <span class="text-danger">
                  {{errors['key']?.join(' ')}}
                </span>
              </small>
            </div>
          </div>
          <div class="shine w-100 br-6" style="height: 36px;" *ngIf="isSpaceLoader"></div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-1">
          <div class="form-group inputtext-block mb-5">
            <label class="form-label col-md-12"> CRM ID <span
                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                ngbTooltip="Optional identifier or URL for this customer from your customer relationship management software"></span>
            </label>
            <div *ngIf="!isSpaceLoader">
              <input type="text" class="form-control form-control-lg form-control-solid mb-1" formControlName="crm_id"
                name="crm_id" autocomplete="off" />
              <small class="form-invalid-message">
                <span class="text-danger">
                  {{errors['crm_id']?.join(' ')}}
                </span>
              </small>
            </div>
          </div>
          <div class="shine w-100 br-6" style="height: 36px;" *ngIf="isSpaceLoader"></div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-1">
          <div class="form-group inputtext-block mb-5">
            <label class="form-label"> Note </label>
            <div *ngIf="!isSpaceLoader">
              <input type="text" class="form-control form-control-lg form-control-solid mb-1" formControlName="note"
                name="note" autocomplete="off" />
              <small class="form-invalid-message">
                <span class="text-danger">
                  {{errors['note']?.join(' ')}}
                </span>
              </small>
            </div>
          </div>
          <div class="shine w-100 br-6" style="height: 36px;" *ngIf="isSpaceLoader"></div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-1">
          <div class="form-group inputtext-block mb-5">
            <label class="form-label"> Replify enterprise manager <span
                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                ngbTooltip=" IP address or DNS name of the Replify enterprise manager to use for this space and its children"></span>
            </label>
            <div *ngIf="!isSpaceLoader">
              <input type="text" class="form-control form-control-lg form-control-solid mb-1"
                formControlName="replify_enterprise_manager" name="replify_enterprise_manager" autocomplete="off" />
              <small class="form-invalid-message">
                <span class="text-danger">
                  {{errors['replify_enterprise_manager']?.join(' ')}}
                </span>
              </small>
            </div>
          </div>
          <div class="shine w-100 br-6" style="height: 36px;" *ngIf="isSpaceLoader"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_qos_profiles"
                [attr.disabled]="spaceData?.parent === null ? '' : null" id="use_parent_qos_profiles"
                name="use_parent_qos_profiles">
              <label class="mb-0 form-label ms-2 fs-7 cursor-pointer" for="use_parent_qos_profiles">
                Use QoS profiles from parent space
                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Allow bonds in this space to be assigned to QoS profiles available from its parent space."></span>
              </label>
            </div>
          </div>
          <small class="text-danger">
            {{errors['use_parent_qos_profiles']?.join(' ')}}
          </small>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_classification_profiles"
                [attr.disabled]="spaceData?.parent === null ? '' : null" name="use_parent_classification_profiles"
                id="use_parent_classification_profiles">
              <label class="mb-0 form-label ms-2 fs-7 cursor-pointer" for="use_parent_classification_profiles">
                Use classification profiles from parent space
                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Allow bonds in this space to be assigned to classification profiles available from its parent space."></span>
              </label>
            </div>
          </div>
          <small class="text-danger">
            {{errors['use_parent_classification_profiles']?.join(' ')}}
          </small>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_flow_collectors"
                [attr.disabled]="spaceData?.parent === null ? '' : null" name="use_parent_flow_collectors"
                id="use_parent_flow_collectors">
              <label class="mb-0 form-label ms-2 fs-7 cursor-pointer" for="use_parent_flow_collectors">
                Use flow collectors from parent space <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Allow bonds in this space use flow collectors available from its parent space."></span>
              </label>
            </div>
          </div>
          <small class="text-danger">
            {{errors['use_parent_flow_collectors']?.join(' ')}}
          </small>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_aggregators"
                [attr.disabled]="spaceData?.parent === null ? '' : null" name="use_parent_aggregators"
                id="use_parent_aggregators">
              <label class="mb-0 form-label ms-2 fs-7 cursor-pointer" for="use_parent_aggregators">
                Use aggregators from parent space
                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Allow bonds in this space to be assigned to aggregators available from its parent space."></span></label>
            </div>
          </div>
          <small class="text-danger">
            {{errors['use_parent_aggregators']?.join(' ')}}
          </small>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_mobile_providers"
                [attr.disabled]="spaceData?.parent === null ? '' : null" name="use_parent_mobile_providers"
                id="use_parent_mobile_providers">
              <label class="mb-0 form-label ms-2 fs-7 cursor-pointer" for="use_parent_mobile_providers">
                Use mobile providers from parent space <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Allow bonds in this space to be assigned to provider profiles available from its parent space."></span>
              </label>
            </div>
          </div>
          <small class="text-danger">
            {{errors['use_parent_mobile_providers']?.join(' ')}}
          </small>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_routing_groups"
                [attr.disabled]="spaceData?.parent === null ? '' : null" name="use_parent_routing_groups"
                id="use_parent_routing_groups">
              <label class="mb-0 form-label ms-2 fs-7 cursor-pointer" for="use_parent_routing_groups">
                Use routing groups from parent space <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Allow aggregators in this space to be assigned to routing groups available from its parent space."></span>
              </label>
            </div>
          </div>
          <small class="text-danger">
            {{errors['use_parent_routing_groups']?.join(' ')}}
          </small>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="create_iso" name="create_iso"
                [attr.disabled]="spaceData?.parent === null ? '' : null" id="create_iso">
              <label class="mb-0 form-label ms-2 cursor-pointer" for="create_iso"> Create ISOs <span
                  [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                  class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                  ngbTooltip="Create ISO provisioning files specific to this space."></span> </label>
            </div>
          </div>
          <small class="text-danger">
            {{errors['create_iso']?.join(' ')}}
          </small>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" (click)="SpaceAdd.invalid ? '' :onSave()"
      [ngClass]="SpaceAdd.invalid ? 'c-disable':''">
      <div>
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
          class="svg-icon svg-icon-block mb-2"></span>
      </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" (click)="navigateToSpace()">
      <div>
        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
      </div> Cancel
    </button>
  </div>
</div>