import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmailTemplateModel } from '../models/email-template.model';

const BACKEND_URL = environment.apiUrl + '/brand/';
@Injectable({
  providedIn: 'root'
})
export class PartnerEmailManagementService {

  constructor(private http: HttpClient) { }
  updateEmailTemplatePartner(data: any,id:any,partnerId:any) {
    return this.http.put<EmailTemplateModel>(environment.apiUrl  + `/partnerPanel/emailModule/update/${id}/${partnerId}`, data);
}
getEmailTemplateListingPartner( search: string,
  page: string,
  limit: number,
  name: string,
  sort: any,
  partnerId:any
) {
  let params = new HttpParams()
      .set('searchText', search ? search : '')
      .set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
      console.log("🚀 ~ file: partner-email-management.service.ts ~ line 30 ~ PartnerEmailManagementService ~ environment.apiUrl  + `partnerPanel/emailModule/list/${partnerId}`,{params}", environment.apiUrl  + `/partnerPanel/emailModule/list/${partnerId}`,{params})
  return this.http.get<any>( environment.apiUrl  + `/partnerPanel/emailModule/list/${partnerId}`,{params});
}
getEmailTemplateByIdPartner(id:any,partnerId:any){
  return this.http.get<any>(environment.apiUrl + `/partnerPanel/emailModule/view/${id}/${partnerId}`);

}
deleteEmailTemplatePartner(id:any,partnerId:any){
  return this.http.put(environment.apiUrl + `/partnerPanel/emailModule/delete/${id}/${partnerId}`,{});

}
sendEmail(body:any,id:any){
  return this.http.put(BACKEND_URL + `emailBranding/send/${id}`,body);
}

sendEmailTemplatePartner(data: any,partnerId:any,id:any){
  return this.http.put(environment.apiUrl + `/partnerPanel/emailModule/send/${id}/${partnerId}`,data);
}

createEmailTemplatePartner(data: any,partnerId:any) {
  return this.http.post<EmailTemplateModel>(environment.apiUrl + `/partnerPanel/emailModule/add/${partnerId}`, data);
}

getCustomEmailTemplateListingPartner( search: string,
  page: string,
  limit: number,
  name: string,
  sort: any,
  partnerId:any
) {
  let params = new HttpParams()
      .set('searchText', search ? search : '')
      .set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
  return this.http.get<any>( environment.apiUrl  + `/partnerPanel/customeEmailTemplate/list/${partnerId}`,{params});
}

deleteCustomEmailTemplate(id:any,partnerId:any){
  return this.http.delete(environment.apiUrl + `/partnerPanel/customeEmailTemplate/deleteEmailTemplate/${id}/${partnerId}`);

}

createCustomEmailTemplatePartner(data: any,partnerId:any) {
  return this.http.post<EmailTemplateModel>(environment.apiUrl + `/partnerPanel/customeEmailTemplate/add/${partnerId}`, data);
}

updateCustomEmailTemplatePartner(data: any,id:any,partnerId:any) {
  return this.http.put<EmailTemplateModel>(environment.apiUrl  + `/partnerPanel/customeEmailTemplate/update/${id}/${partnerId}`, data);
}

getCustomEmailTemplateByIdPartner(id:any,partnerId:any){
  return this.http.get<any>(environment.apiUrl + `/partnerPanel/customeEmailTemplate/view/${id}/${partnerId}`);
}

getEmailTemplateById(id:any){
  return this.http.get<any>(environment.apiUrl + `/brand/emailBranding/view/${id}`);
}
}
