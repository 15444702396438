


<div  id="kt_toolbar_container" [ngClass]="toolbarContainerCssClasses" class="d-flex flex-stack">
  <app-page-title  #ktPageTitle class="page-title d-flex" [ngClass]="pageTitleCssClasses"></app-page-title>
  <!-- begin::Actions  -->
  <div class="d-flex align-items-center py-1">
    <!-- begin::Button  -->
    <!-- <a class="btn btn-sm btn-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app"
      id="kt_toolbar_primary_button">
      Create
    </a> -->
    <!-- end::Button  -->
  </div>
  <!-- end::Actions  -->
</div>