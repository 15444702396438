<div class="d-flex flex-wrap justify-content-end mb-2">
    <div class="my-2">
        <button (click)="!createCustomEmailTemplateForm.value.templateType ? '':getDefault()" type="button"
            [ngClass]="!createCustomEmailTemplateForm.value.templateType ? 'disable-tune':''"
            class="btn btn-primary btn-style">
            Reset Template
        </button>
    </div>
    <div class="my-2">
        <div class="card-toolbar ms-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
            title="Click to go back">
            <button (click)="onBackClicked()" type="button" class="btn btn-secondary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/angle-left-solid.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Back
            </button>
        </div>
    </div>
</div>
<form [formGroup]="createCustomEmailTemplateForm">
    <div class="card mb-5 mb-xl-10">
        <div class="card-body p-5">
            <div>
                <div class="form-group row">
                    <div class="col-md-6 col-lg-3 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Template Name / Subject
                                <span class="required-asterisk m-0">*</span>
                            </label>
                            <input formControlName="titleName" type="text"
                                class="form-control form-control-lg form-control-solid" name=""
                                placeholder="Title Name" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'titleName')">
                                    Template name is required
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('pattern', 'titleName')">
                                    Template name is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Template Type
                                <span class="required-asterisk m-0">*</span>
                            </label>
                            <ng-select class="dropdown-block" [class.is-invalid]="isControlInvalid('templateType')"
                                [class.is-valid]="isControlValid('templateType')" labelForId="heroId"
                                formControlName="templateType">
                                <ng-option *ngFor="let templateType of templateTypeArr"
                                    [disabled]="templateType.disable" [value]="templateType.key">
                                    {{templateType.value}}
                                </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'templateType')">
                                    Template type is required
                                </span>
                            </small>
                        </div>
                    </div>

                    <ng-template #headerBackGroundColorPicker>
                        <color-sketch [disableAlpha]="false" [color]="headerBackgroundColour"
                            (onChangeComplete)="headerBackGroundChangeComplete($event)"></color-sketch>
                    </ng-template>
                    <ng-template #bodyBackGroundColorPicker>
                        <color-sketch [disableAlpha]="false" [color]="bodyBackgroundColour"
                            (onChangeComplete)="bodyBackGroundChangeComplete($event)"></color-sketch>
                    </ng-template>

                    <div class="col-md-3 col-lg-3 mb-1">
                        <label class="form-label">Header background colour</label>
                        <div [ngbPopover]="headerBackGroundColorPicker" [autoClose]="'outside'" placement="bottom"
                            class="d-flex border p-1 color-picker-block">
                            <div class="colorpicker-div" [ngStyle]="setMyStyles(headerBackgroundColour)">
                            </div>
                            <input readonly type="text" class="form-control form-control-lg form-control-solid border-0"
                                autocomplete="off" formControlName="headerBackgroundColour"
                                [(ngModel)]="headerBackgroundColour" />
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 mb-1">
                        <label class="form-label">Body background colour</label>
                        <div [ngbPopover]="bodyBackGroundColorPicker" [autoClose]="'outside'" placement="bottom"
                            class="d-flex border p-1 color-picker-block">
                            <div class="colorpicker-div" [ngStyle]="setMyStyles(bodyBackgroundColour)">
                            </div>
                            <input readonly type="text" class="form-control form-control-lg form-control-solid border-0"
                                autocomplete="off" formControlName="bodyBackgroundColour"
                                [(ngModel)]="bodyBackgroundColour" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-5 mb-xl-1">
                <div class="d-flex card mb-1 mb-xl-1">
                    <div class="card-body p-2">
                        <ng-template #footerBackGroundColorPicker>
                            <color-sketch [disableAlpha]="false" [color]="footerBackgroundColour"
                                (onChangeComplete)="footerBackGroundChangeComplete($event)"></color-sketch>
                        </ng-template>
                        <ng-template #headerTextColorPicker>
                            <color-sketch [disableAlpha]="false" [color]="headerTextColour"
                                (onChangeComplete)="headerTextChangeComplete($event)"></color-sketch>
                        </ng-template>
                        <ng-template #bodyTextColorPicker>
                            <color-sketch [disableAlpha]="false" [color]="bodyTextColour"
                                (onChangeComplete)="bodyTextChangeComplete($event)"></color-sketch>
                        </ng-template>
                        <ng-template #footerTextColorPicker>
                            <color-sketch [disableAlpha]="false" [color]="footerTextColour"
                                (onChangeComplete)="footerTextChangeComplete($event)"></color-sketch>
                        </ng-template>
                        <div class="row">
                            <div class="col-md-3 col-lg-3 mb-5">
                                <label class="form-label">Footer background colour</label>
                                <div [ngbPopover]="footerBackGroundColorPicker" [autoClose]="'outside'"
                                    placement="bottom" class="color-picker-block d-flex border p-1">
                                    <div class="colorpicker-div" [ngStyle]="setMyStyles(footerBackgroundColour)">
                                    </div>
                                    <input readonly type="text"
                                        class="form-control form-control-lg form-control-solid border-0"
                                        autocomplete="off" formControlName="footerBackgroundColour"
                                        [(ngModel)]="footerBackgroundColour" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 mb-5">
                                <label class="form-label">Header text colour</label>
                                <div [ngbPopover]="headerTextColorPicker" [autoClose]="'outside'" placement="bottom"
                                    class="color-picker-block d-flex border p-1">
                                    <div class="colorpicker-div" [ngStyle]="setMyStyles(headerTextColour)">
                                    </div>
                                    <input readonly type="text"
                                        class="form-control form-control-lg form-control-solid border-0"
                                        autocomplete="off" formControlName="headerTextColour"
                                        [(ngModel)]="headerTextColour" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 mb-5">
                                <label class="form-label">Body text colour</label>
                                <div [ngbPopover]="bodyTextColorPicker" [autoClose]="'outside'" placement="bottom"
                                    class="color-picker-block d-flex border p-1">
                                    <div class="colorpicker-div" [ngStyle]="setMyStyles(bodyTextColour)">
                                    </div>
                                    <input readonly type="text"
                                        class="form-control form-control-lg form-control-solid border-0"
                                        autocomplete="off" formControlName="bodyTextColour"
                                        [(ngModel)]="bodyTextColour" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 mb-5">
                                <label class="form-label">Footer text colour</label>
                                <div [ngbPopover]="footerTextColorPicker" [autoClose]="'outside'" placement="bottom"
                                    class="color-picker-block d-flex border p-1">
                                    <div class="colorpicker-div" [ngStyle]="setMyStyles(footerTextColour)">
                                    </div>
                                    <input readonly type="text"
                                        class="form-control form-control-lg form-control-solid border-0"
                                        autocomplete="off" formControlName="footerTextColour"
                                        [(ngModel)]="footerTextColour" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div style=" border-radius: 6px;"
                                class="col-md-1 col-lg-2 d-flex align-items-center form-control form-control-lg form-control-solid">
                                <h3 class="mb-0 form-label">Style Body </h3>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="form-group row mb-5">
                                    <div>
                                        <ckeditor formControlName="styleBody" [config]="editorConfig" [editor]="editor"
                                            (ready)="onReady($event)">
                                        </ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-5 col-lg-2 mb-5">
                    <label class="form-label"> Enabled </label>
                    <div class="d-flex flex-column">
                        <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="isActive">
                        </ui-switch>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-6 col-lg-6 mb-1">
                    <div class="form-group inputtext-block mb-5">
                        <label class="form-label">Facebook link
                        </label>
                        <input formControlName="facebook_link" type="text"
                            class="form-control form-control-lg form-control-solid" name=""
                            placeholder="Facebook Link" />
                        <small class="form-invalid-message">
                            <span class="text-danger" *ngIf="controlHasError('pattern', 'facebook_link')">
                                Invalid link format
                            </span>
                            <span class="text-danger" *ngIf="controlHasError('maxlength', 'facebook_link')">
                                Invalid link format
                            </span>
                        </small>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 mb-1">
                    <div class="form-group inputtext-block mb-5">
                        <label class="form-label">Twitter link
                        </label>
                        <input formControlName="twitter_link" type="text"
                            class="form-control form-control-lg form-control-solid" name=""
                            placeholder="Twitter Link" />
                        <small class="form-invalid-message">
                            <span class="text-danger" *ngIf="controlHasError('pattern', 'twitter_link')">
                                Invalid link format
                            </span>
                            <span class="text-danger" *ngIf="controlHasError('maxlength', 'twitter_link')">
                                Invalid link format
                            </span>
                        </small>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 mb-1">
                    <div class="form-group inputtext-block mb-5">
                        <label class="form-label">Instagram link
                        </label>
                        <input formControlName="instagram_link" type="text"
                            class="form-control form-control-lg form-control-solid" name=""
                            placeholder="Instagram Link" />
                        <small class="form-invalid-message">
                            <span class="text-danger" *ngIf="controlHasError('pattern', 'instagram_link')">
                                Invalid link format
                            </span>
                            <span class="text-danger" *ngIf="controlHasError('maxlength', 'instagram_link')">
                                Invalid link format
                            </span>
                        </small>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 mb-1">
                    <div class="form-group inputtext-block mb-5">
                        <label class="form-label">Website link
                        </label>
                        <input formControlName="website_link" type="text"
                            class="form-control form-control-lg form-control-solid" name=""
                            placeholder="Website Link" />
                        <small class="form-invalid-message">
                            <span class="text-danger" *ngIf="controlHasError('pattern', 'website_link')">
                                Invalid link format
                            </span>
                            <span class="text-danger" *ngIf="controlHasError('maxlength', 'website_link')">
                                Invalid link format
                            </span>
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer px-5 py-3">
            <button (click)="onSubmit()" type="button" class="btn btn-primary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div>Save
            </button>
            <button (click)="onCancel()" type="button" class="btn btn-secondary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div>Cancel
            </button>
            <button (click)="!createCustomEmailTemplateForm.value.templateType ? '':onPreview()"
                [disabled]="!createCustomEmailTemplateForm.value.templateType" type="button"
                class="btn btn-primary btn-style"
                [ngClass]="!createCustomEmailTemplateForm.value.templateType ? 'disable-tune':''">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/eye.svg'" class="svg-icon svg-icon-block mb-2">
                    </span>
                </div>Preview
            </button>
        </div>
    </div>
</form>