<div class="modal-header px-5 py-3">
    <h5 class="modal-title">Alert Configuration </h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-0">
    <form>
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-5">
                <div class="row" *ngIf="errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors}}</div>
                    </div>
                </div>
                <div class="row mb-1 align-items-center">
                    <label class="col-lg-7 col-md-7 col-xxl-7 fw-bolder fs-7 text-black text-end separator-vertical">
                        Aggregator maintenance</label>
                    <div class="col-lg-5 col-xxl-5 col-md-5">
                        <span *ngIf="settingData?.agg_maintenance" title="true">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                class="svg-icon svg-icon-3 ml-2 me-1">
                            </span>
                        </span>
                        <span *ngIf="!settingData?.agg_maintenance" title="false">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                class="svg-icon svg-icon-3 ml-2 me-1">
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row mb-1 align-items-center">
                    <label class="col-lg-7 col-md-7 col-xxl-7 fw-bolder fs-7 text-black text-end separator-vertical">
                        Aggregator failover suspended</label>
                    <div class="col-lg-5 col-xxl-5 col-md-5">
                        <span class="fw-bolder fs-6 text-dark">
                            <span *ngIf="!settingData?.agg_failover_suspended" title="false">
                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                </span>
                            </span>
                            <span *ngIf="settingData?.agg_failover_suspended" title="true">
                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                    class="svg-icon svg-icon-3 ml-2 me-1">
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <label class="col-lg-7 col-md-7 col-xxl-7 fw-bolder fs-7 text-black text-end separator-vertical">
                        Heartbeat Interval</label>
                    <div class="col-lg-4 col-xxl-4 col-md-3">
                        <ng-select class="dropdown-block" [(ngModel)]="selectedMonitor" name="selectedMonitor"
                            [attr.disabled]="!permissionsObj.update ? '': null" [clearable]="false"
                            [searchable]="false">
                            <ng-option class="fs-6" *ngFor="let item of monitorList" [value]="item.key">{{item.title}}
                            </ng-option>
                        </ng-select>
                        <!-- <ng-select name="natrouting" formControlName="natrouting" class="select-width" [multiple]="true"
                            placeholder="No routing groups">
                            <ng-option *ngFor="let rgp of routingGrps"
                                value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                        </ng-select> -->
                    </div>
                </div>
                <div class="row mb-1 align-items-center">
                    <label class="col-lg-7 col-md-7 col-xxl-7 fw-bolder fs-7 text-black text-end separator-vertical">
                        Alert Retries</label>
                    <div class="col-lg-2 col-xxl-2 col-md-2">
                        <ng-select class="dropdown-block" [(ngModel)]="selectedChecks" name="selectedChecks"
                            [attr.disabled]="!permissionsObj.update ? '': null" [clearable]="false"
                            [searchable]="false">
                            <ng-option class="fs-6" *ngFor="let item of checkList" [value]="item">{{item}}</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" (click)="permissionsObj.update ? onSave(): ''"
        data-bs-dismiss="modal" [disabled]="!permissionsObj.update">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" (click)="onClose()" data-bs-dismiss="modal">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>