import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';

@Injectable({ providedIn: 'root' })
export class AdminPermissionGuard implements CanActivate {

    constructor(private router: Router, private storageService: StorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let userRole = Number(this.storageService.getCipherObj('role'));
        if (userRole == AppConst.basicUser) {
            if (state.url.includes('dashboard') || state.url.includes('partner-management')
                || state.url.includes('profile')) {
                return true;
            } else {
                this.router.navigate([`/admin/dashboard`]);
                return false;
            }
        }
        return true;
    }

}
