<!-- End tabbing details section Speed test -->
<div class="card mb-5 mb-xl-10">

    <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-5">
        <div class="card-title m-0 flex-grow-1">
            <h3 class="fw-bolder m-0">
                {{speedTestData?.target}} speed test {{speedTestData?.id}}
            </h3>
        </div>

        <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Repeat">
            <button (click)="navigateSpeed()" type="button" class="btn btn-secondary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/angle-left-solid.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Back
            </button>
        </div>
        <div class="ms-3 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Repeat">
            <button (click)="onRepeatClick()" type="button" class="btn btn-primary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr029.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Repeat
            </button>
        </div>
    </div>

    <div class="card-body p-5">

        <div class="row">
            <div class="col-md-12 col-lg-6">
                <div class="d-flex">
                    <button type="button"
                        class="btn btn-outline-primary d-flex align-items-center justify-content-between bg-light w-100"
                        (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                        Configuration
                        <span *ngIf="!isCollapsed" [inlineSVG]="'./assets/media/icons/duotune/icon/caret-up-solid.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-1">
                        </span>
                        <span *ngIf="isCollapsed" [inlineSVG]="'./assets/media/icons/duotune/icon/sort-down-solid.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-1">
                        </span>
                    </button>

                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="border p-5">
                    <div class="card speed-test-view">
                        <div>
                            <table>
                                <tr>
                                    <td><b>Test ID</b></td>
                                    <td>{{speedTestData?.id}}</td>
                                </tr>
                                <tr>
                                    <td><b>Target</b></td>
                                    <td>{{speedTestData?.target}}</td>
                                </tr>
                                <tr>
                                    <td><b>Description</b></td>
                                    <td class=""> <span> {{ speedTestData?.protocol | uppercase}} </span>
                                        <span *ngIf="speedTestData?.direction == 'down' ">Download test, </span>
                                        <span *ngIf="speedTestData?.direction == 'up' ">Upload test, </span>
                                        <span *ngIf="speedTestData?.rate_limit !== null">
                                            rate limit {{ speedTestData?.rate_limit}},
                                        </span>
                                        <span *ngIf="speedTestData?.rate_limit == null">
                                            no rate limit, {{ speedTestData?.rate_limit}}
                                        </span>
                                        <span>{{ speedTestData?.length}} seconds, </span>
                                        <span> concurrency {{ speedTestData?.concurrency}} </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td><b>Submitted Time</b></td>
                                    <td>
                                        {{ getSubmitDate(speedTestData?.submitted_time) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td><b>Submitted By</b></td>
                                    <td>{{speedTestData?.submitted_by?.name}}</td>
                                </tr>

                                <tr *ngIf="speedTestData?.bond_configuration">
                                    <td><b>Node Configuration</b></td>
                                    <td>
                                        <span>
                                            Packet distribution:
                                            {{speedTestData?.bond_configuration?.balancing=='wrr'?
                                            'Weighted round robin':speedTestData?.bond_configuration?.balancing}},
                                            {{speedTestData?.bond_configuration?.flowlet_delta || 0}} ms delta
                                        </span><br>
                                        <span>
                                            Automatic reorder max hold:
                                            <a *ngIf="speedTestData?.bond_configuration?.automatic_reorder_max_hold">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>

                                            <a *ngIf="!speedTestData?.bond_configuration">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>

                                        </span><br>
                                        <span>
                                            Reorder max hold: {{speedTestData?.bond_configuration?.reorder_max_hold}} ms
                                        </span><br>
                                        <span>
                                            Compression:
                                            <a *ngIf="speedTestData?.bond_configuration?.compression">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>

                                            <a *ngIf="!speedTestData?.bond_configuration?.compression">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>
                                        </span><br>
                                        <span>
                                            QoS Profile: {{speedTestData?.bond_configuration?.qos_profile}}
                                        </span><br>

                                        <span>
                                            Batched leg send operations:
                                            <a *ngIf="speedTestData?.bond_configuration?.batched_leg_tx">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>

                                            <a *ngIf="!speedTestData?.bond_configuration?.batched_leg_tx">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>
                                        </span><br>

                                        <span>
                                            Clamp TCP:
                                            <a *ngIf="speedTestData?.bond_configuration?.clamp_tcp">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/check-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>

                                            <a *ngIf="!speedTestData?.bond_configuration?.clamp_tcp">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>
                                        </span><br>

                                        <span>
                                            Tunnel Security:
                                            <span *ngIf="speedTestData?.bond_configuration?.tunnel_security">
                                                {{speedTestData?.bond_configuration?.tunnel_security}}</span>


                                            <a *ngIf="!speedTestData?.bond_configuration?.tunnel_security">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>

                                        </span><br>

                                        <span>
                                            Bonder TCP congestion control algorithm:

                                            <span
                                                *ngIf="speedTestData?.bond_configuration?.bonder_tcp_congestion_control_algorithm">
                                                {{speedTestData?.bond_configuration?.bonder_tcp_congestion_control_algorithm}}
                                            </span>

                                            <a
                                                *ngIf="!speedTestData?.bond_configuration?.bonder_tcp_congestion_control_algorithm">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>
                                        </span><br>

                                        <span>
                                            Aggregator TCP congestion control algorithm:
                                            <span
                                                *ngIf="speedTestData?.bond_configuration?.agg_tcp_congestion_control_algorithm">
                                                {{speedTestData?.bond_configuration?.agg_tcp_congestion_control_algorithm}}
                                            </span>

                                            <a *ngIf="!speedTestData?.bond_configuration">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>
                                        </span><br>

                                        <span>
                                            TCP proxy:
                                            <span *ngIf="speedTestData?.bond_configuration?.tcp_proxy"
                                                [inlineSVG]="'./assets/media/icons/duotune/icon/check-solid.svg'"
                                                class="svg-icon svg-icon-8 ml-2 me-1">
                                            </span>

                                            <a *ngIf="!speedTestData?.bond_configuration?.tcp_proxy">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                    class="svg-icon svg-icon-8 ml-2 me-1">
                                                </span> </a>
                                        </span><br>
                                        <span>
                                            <b>Legs</b> <br>
                                            <span *ngIf="speedTestData?.bond_configuration?.legs
                                                && speedTestData?.bond_configuration?.legs.length >  0">
                                                <span *ngFor="let leg of speedTestData?.bond_configuration?.legs;">
                                                    Leg {{leg?.id}}: interface {{leg?.interface}},
                                                    {{leg?.down_speed}} Mbps down, {{leg?.up_speed}} Mbps up
                                                    <span *ngIf="leg?.failover">, failover</span><br>
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td><b>Aggregator</b></td>
                                    <td>{{speedTestData?.aggregator_name}}</td>
                                </tr>
                                <tr>
                                    <td><b>Versions</b></td>
                                    <td>
                                        <span>
                                            Bonder: {{speedTestData?.bonder_version}}
                                        </span><br>
                                        <span>
                                            Aggregator: {{speedTestData?.agg_version}}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-12 mt-5">
                <p class="m-0">
                    <button type="button"
                        class="btn btn-outline-primary d-flex align-items-center justify-content-between bg-light w-100"
                        (click)="resultcollapse.toggle()" [attr.aria-expanded]="!resultIsCollapsed"
                        aria-controls="collapseExample">
                        Results
                        <span *ngIf="!resultIsCollapsed"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/caret-up-solid.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-1">
                        </span>
                        <span *ngIf="resultIsCollapsed"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/sort-down-solid.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-1">
                        </span>
                    </button>
                </p>
                <div #resultcollapse="ngbCollapse" [(ngbCollapse)]="resultIsCollapsed" class="border">
                    <div class="card">
                        <div class="card-body p-5">
                            <span *ngIf="!speedTestData?.error_message">
                                <div *ngIf="speedTestData && speedTestData.status == 'completed'">
                                    <span>
                                        Throughput: {{ speedTestData?.avg_throughput}} Mbps,
                                        {{ speedTestData?.avg_throughput}}
                                        packets/sec
                                    </span>
                                    <br>
                                    <span>
                                        Latency: avg. {{speedTestData?.avg_latency}} ms, st.dev
                                        {{(speedTestData?.master_stats?.latency?.mean)*1000 | number: '0.2'}} ms
                                        (n={{speedTestData?.master_stats?.latency?.n}})
                                    </span><br>
                                    <span>
                                        Send queue: {{speedTestData?.master_stats?.queue_size?.n==0?
                                        'not recorded':speedTestData?.master_stats?.queue_size?.n}}
                                    </span><br>
                                    <span>
                                        Packet loss: {{speedTestData?.avg_pkt_loss}} %
                                    </span>

                                    <div *ngIf="this.speedTestData?.progress_results?.throughput && this.speedTestData?.progress_results?.throughput.length > 0"
                                        class="mt-5 d-flex flex-column border-top border-1 pt-5 pb-0">
                                        <h4>Throughput</h4>
                                        <div id="throughputChart" style="height: 230px; width: 100%; margin:auto;">
                                        </div>
                                    </div>
                                    <div *ngIf="this.speedTestData?.progress_results?.latency && this.speedTestData?.progress_results?.latency.length > 0"
                                        class="mt-5 d-flex flex-column border-top border-1 pt-5 pb-0">
                                        <h4>Latency</h4>
                                        <div id="latencyChart" style="height: 230px; width: 100%; margin:auto;"></div>
                                    </div>
                                    <div *ngIf="this.speedTestData?.progress_results?.queue_size && this.speedTestData?.progress_results?.queue_size.length > 0"
                                        class="mt-5 d-flex flex-column border-top border-1 pt-5 pb-0">
                                        <h4>Send queue size</h4>
                                        <div id="queueSizeChart" style="height: 230px; width: 100%; margin:auto;"></div>
                                    </div>
                                    <div *ngIf="this.speedTestData?.slave_cpu_load && ((this.speedTestData?.slave_cpu_load[0] && this.speedTestData?.slave_cpu_load[0].length > 0)
                                        || (this.speedTestData?.slave_cpu_load[1] && this.speedTestData?.slave_cpu_load[1].length > 0))"
                                        class="mt-5 d-flex flex-column border-top border-1 pt-5 pb-0">
                                        <h4>Bonder CPU usage
                                            <i class="fa fa-question-circle" data-toggle="tooltip"
                                                title="How much of the CPU each core is using for the duration of the test."></i>
                                        </h4>
                                        <div id="bonderCPUChart" style="height: 230px; width: 100%; margin:auto;"></div>
                                    </div>
                                    <!-- <div *ngIf="this.speedTestData?.master_cpu_load && ((this.speedTestData?.master_cpu_load[0] && this.speedTestData?.master_cpu_load[0].length > 0) ||
                                        (this.speedTestData?.master_cpu_load[1] && this.speedTestData?.master_cpu_load[1].length > 0))"
                                        class="mt-5 d-flex flex-column border-top border-1 pt-5 pb-0">
                                        <h4>Aggregator CPU usage <i class="fa fa-question-circle" data-toggle="tooltip" title="How much of the CPU each core is using for the duration of the test."></i>
                                        </h4>
                                        <div id="aggregatorCPUChart" style="height: 230px; width: 100%; margin:auto;">
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="speedTestData && (speedTestData.status == 'running' || speedTestData.status == 'queued')"
                                    class="ring">
                                    <div class="spinner-box">
                                        <div class="three-quarter-spinner"></div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div *ngIf="speedTestData?.error_message">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/exclamation-circle.svg'"
                                class="svg-icon svg-icon-3 p-5">
                            </span>
                            Error: {{speedTestData?.error_message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>