import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FormErrorService {

    isControlValid(controlName: string, formGroup: any): boolean {
        const control = formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string, formGroup: any): boolean {
        const control = formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation: any, controlName: string, formGroup: any): boolean {
        const control = formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched);
    }

    isControlTouched(controlName: string, formGroup: any): boolean {
        const control = formGroup.controls[controlName];
        return control.dirty || control.touched;
    }
}