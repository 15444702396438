<app-sdwan-bonds-listing [allBonds]="allBonds" [length]="length" [sortBy]="sortBy" [sortOrder]="sortOrder"
  (onSearch)="searchBond($event)" (onReceiveMessage)="onReceiveMessage($event)" (onReset)="onResetFilter()"
  (onSortFields)="onSort($event)" [availableColumnsArray]="availableColumns" [selectedItems]="selectedColumns"
  [sharedSdwanData]="sharedSdwanData" [isSDWAN]="isSDWAN" (onSaveColumn)="saveColumn()" (onSelectColumn)="open($event)"
  (getSpaceBondList)="getSpaceBondList()" (onFilterOptionChange)="onFilterChange($event)"
  (onsecondFilterOptionChange)="onSecondFilterChange($event)" (onAdd)="onAdd()" (onEditBond)="onEdit($event)"
  [spaceOption]="spaceOption" [permissions]="permissionsObj" [isBondListAPI]="isBondListAPI"
  [isColumnListAPI]="isColumnListAPI" [isLegListAPI]="isLegListAPI" [isAggListAPI]="isAggListAPI"
  [isProfileListAPI]="isProfileListAPI" [isIPListAPI]="isIPListAPI" [isAdminMail]="isAdminMail">
</app-sdwan-bonds-listing>

<!-- <div class="card mb-5 mb-xl-8">
  <div class="card-body p-0">
    <div class="card-header d-flex justify-content-between mb-5 bg-light p-4 border-0">
      <h2 class="m-0 fs-3"> Parent : Fussion staff </h2>
      <h3 class="m-0 fs-3"> Created : Sep. 14, 2020 </h3>
    </div>
    <form action="#" [formGroup]="SpaceAdd" class="p-5">
      <div class="row">
        <div class="col-md-4 col-lg-4 mb-5">
          <label class="form-label col-md-2"> Name </label>
          <div class="d-flex flex-column col-md-12">
            <input type="text" class="form-control form-control-lg form-control-solid" formControlName="name"
              name="name" autocomplete="off" />
            <div *ngIf="submitted==true && f.name.invalid" class="text-danger">
              <div *ngIf="f.name.errors?.required">This field is required</div>
            </div>
            <small class="text-danger">
              {{errors['name']?.join(' ')}}
            </small>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <label class="form-label col-md-2"> Key </label>
          <div class="d-flex flex-column col-md-12">
            <input type="text" class="form-control form-control-lg form-control-solid" formControlName="key" name="key"
              autocomplete="off" />
            <small> Short string that uniquely identifies the space; used in node hostnames, ISO file filenames, etc.
              (1-6 lowercase alphanumeric characters) </small>
            <div *ngIf="submitted==true && f.key.invalid" class="text-danger">
              <div *ngIf="f.key.errors?.required">This field is required</div>
            </div>
            <small class="text-danger">
              {{errors['key']?.join(' ')}}
            </small>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <label class="form-label col-md-2"> CRM ID </label>
          <div class="d-flex flex-column col-md-12">
            <input type="text" class="form-control form-control-lg form-control-solid" formControlName="crm_id"
              name="crm_id" placeholder="" autocomplete="off" />
            <small>Optional identifier or URL for this customer from your customer relationship management software
            </small>
            <small class="text-danger">
              {{errors['crm_id']?.join(' ')}}
            </small>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <label class="form-label"> Note </label>
          <div class="d-flex flex-column col-md-12">
            <input type="text" class="form-control form-control-lg form-control-solid" formControlName="note"
              name="note" placeholder="" autocomplete="off" />
            <small class="text-danger">
              {{errors['note']?.join(' ')}}
            </small>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <label class="form-label"> Replify enterprise manager </label>
          <div class="d-flex flex-column col-md-12">
            <input type="text" class="form-control form-control-lg form-control-solid"
              formControlName="replify_enterprise_manager" name="replify_enterprise_manager" placeholder=""
              autocomplete="off" />
            <small> IP address or DNS name of the Replify enterprise manager to use for this space and its children
            </small>
            <small class="text-danger">
              {{errors['replify_enterprise_manager']?.join(' ')}}
            </small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-lg-4 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_qos_profiles"
                name="use_parent_qos_profiles" id=""> <label class="mb-0 form-label ms-2"> Use QoS profiles from parent
                space </label>
            </div>
          </div>

          <small>Allow bonds in this space to be assigned to QoS profiles available from its parent space. </small>
          <small class="text-danger">
            {{errors['use_parent_qos_profiles']?.join(' ')}}
          </small>

        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_classification_profiles"
                name="use_parent_classification_profiles" id=""> <label class="mb-0 form-label ms-2"> Use classification
                profiles from parent space </label>
            </div>
          </div>
          <small>Allow bonds in this space to be assigned to classification profiles available from its parent space.
          </small>
          <small class="text-danger">
            {{errors['use_parent_classification_profiles']?.join(' ')}}
          </small>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_flow_collectors"
                name="use_parent_flow_collectors" id=""> <label class="mb-0 form-label ms-2"> Use flow collectors from
                parent space </label>
            </div>
          </div>
          <small>Allow bonds in this space use flow collectors available from its parent space. </small>
          <small class="text-danger">
            {{errors['use_parent_flow_collectors']?.join(' ')}}
          </small>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_aggregators"
                name="use_parent_aggregators" id=""> <label class="mb-0 form-label ms-2"> Use aggregators from parent
                space
              </label>
            </div>
          </div>
          <small> Allow bonds in this space to be assigned to aggregators available from its parent space. </small>
          <small class="text-danger">
            {{errors['use_parent_aggregators']?.join(' ')}}
          </small>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_mobile_providers"
                name="use_parent_mobile_providers" id=""> <label class="mb-0 form-label ms-2"> Use mobile providers from
                parent space </label>
            </div>
          </div>
          <small> Allow bonds in this space to be assigned to provider profiles available from its parent space.
          </small>
          <small class="text-danger">
            {{errors['use_parent_mobile_providers']?.join(' ')}}
          </small>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="use_parent_routing_groups"
                name="use_parent_routing_groups" id=""> <label class="mb-0 form-label ms-2"> Use routing groups from
                parent space </label>
            </div>
          </div>
          <small> Allow aggregators in this space to be assigned to routing groups available from its parent space.
          </small>
          <small class="text-danger">
            {{errors['use_parent_routing_groups']?.join(' ')}}
          </small>
        </div>

        <div class="col-md-4 col-lg-4 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" formControlName="create_iso" name="create_iso" id="">
              <label class="mb-0 form-label ms-2"> Create ISOs </label>
            </div>
          </div>
          <small> Create ISO provisioning files specific to this space. </small>
          <small class="text-danger">
            {{errors['create_iso']?.join(' ')}}
          </small>
        </div>

      </div>

    </form>
  </div>

  <div class="card-footer p-5">
    <button type="submit" class="btn btn-info mr-2 ml-2" (click)="onSave()">Save</button>
    <button type="submit" class="btn btn-danger mr-2 ml-2">Cancel</button>

  </div>

</div> -->