<div class="modal-header px-5 py-3">
    <h5 class="modal-title">Select Firewall</h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body m-2">
    <div class="card">
        <div class="card-body p-0">
            <div class="row" *ngIf="errors">
                <div class="col-md-12">
                    <div class="alert alert-danger">Please correct the errors below.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 plan-item my-2" *ngFor="let firewall of totalList; let i = index;">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input [id]="''+i" class="form-check-input" [(ngModel)]="firewall.access" type="checkbox"
                                value="{{(firewall?.access == 'true') ? 'true' : 'false'}}" data-kt-check="true"
                                data-kt-check-target=".widget-9-check">
                            <label class="mb-0 form-label ms-3 cursor-pointer" [for]="''+i">
                                {{firewall.name}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div>Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>