import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService } from 'src/app/modules/shared/auth';
import { AuthHTTPService } from 'src/app/modules/shared/auth/services/auth-http/auth-http.service';
import { VersionModalComponent } from 'src/app/modules/super-admin/configuration/general-config/version/version-modal/version-modal.component';
import { PartnerManagmentService } from 'src/app/services/partner-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { VersionService } from 'src/app/services/version.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { LayoutService } from '../../core/layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  imageUrl: any = './assets/media/avatars/blank.png';
  toolbarButtonMarginClass = 'ms-1 ms-lg-2';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-30px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft: string = 'menu';
  showCounter: boolean = false;
  counter: any;
  tick = 1000;
  countDown: Subscription;
  showTimerAccess: boolean = true;
  showRevertAccess: boolean = false;
  partnerDetails: any;
  mainId: any;
  loginId: any;
  accessTimeForAdmin: any;
  isChangeDuration: boolean = false;
  user$: Observable<any>;
  _user: any;
  showSignOut: boolean = true;
  isForeverPermission: boolean = false;
  isShowChangeDuration: boolean = true;
  isAdmin: boolean = false;
  isPartner: boolean;
  isUser: boolean;
  durationValue: any;
  breadCrumbs: any[] = [];
  userIds: any[] = [];
  logoutId: any;
  technicalSupportObj: any;
  technicalSupportVisible: boolean = false;

  constructor(private layout: LayoutService, private auth: AuthService,
    private authHTTPService: AuthHTTPService, private router: Router,
    private cd: ChangeDetectorRef, private sharedService: SharedService,
    private partnerService: PartnerManagmentService, private modalService: NgbModal,
    private versionService: VersionService, private storageService: StorageService) { }

  ngOnInit(): void {
    this.auth.currentUserSubject.subscribe(data => {
      if (data) {
        this._user = data;
        if (data?.imageUrl) this.imageUrl = data?.imageUrl;
        else this.imageUrl = this.imageUrl;
      }
    });
    // this.setLanguage(this.translationService.getSelectedLanguage());
    this.mainId = this.storageService.getCipherObj('mainId');
    this.loginId = this.storageService.getCipherObj('ID');
    let role = this.storageService.getCipherObj('role');
    if (Number(role) == AppConst.admin || Number(role) == AppConst.basicUser) {
      this.isAdmin = true;
    } else if (Number(role) == AppConst.partner) {
      this.isPartner = true;
      this.setSetting();
    } else if (Number(role) == AppConst.user || Number(role) == AppConst.organization) {
      this.isUser = true;
      this.setSetting();
    }
    this.versionService.openVersionModal$.subscribe((val) => {
      if (val) this.openVersionList();
    });
    this.auth.technicalSupport$.subscribe((val) => {
      this.technicalSupportObj = val;
      if (this.technicalSupportObj?.technicalSupportURL || this.technicalSupportObj?.technicalSupportEmail || this.technicalSupportObj?.technicalSupportContact) {
        this.technicalSupportVisible = true;
      }
      this.cd.detectChanges();
    });
    this.headerLeft = this.layout.getProp('header.left') as string;
    this.userIds = this.storageService.getCipherObj('userIds') ?? [];
    if (this.userIds?.length > 1) this.showSignOut = false;
    else this.showSignOut = true;
  }

  setSetting() {
    this.breadCrumbs = this.storageService.getCipherObj('breadCrumbs');
    this.auth.nameBreadcrumb$.subscribe(res => {
      if (res != null && res != 'null' && res != 'undefined' && res != undefined) {
        this.breadCrumbs = this.storageService.getCipherObj('breadCrumbs');
        if (this.breadCrumbs?.length > 0) {
          this.breadCrumbs[this.breadCrumbs?.length - 1] = res;
          this.storageService.setCipherObj('breadCrumbs', this.breadCrumbs);
        }
      }
    });
    if (this.storageService.getCipherObj('isAdminLoggedInAsPartner') == '1') {
      this.showSignOut = false;
      this.setTimer();
    }
    if (this._user) {
      if (this._user?.isLP) {
        this.showTimerAccess = false;
        this.showRevertAccess = true;
        if (this._user?.lpDuration) {
          this.isForeverPermission = false;
        } else {
          this.isShowChangeDuration = false;
          this.isForeverPermission = true;
        }
        this.storageService.setCipherObj('logInExpiresIn', this._user?.lpDuration || '-1');
      } else {
        this.showTimerAccess = true;
        this.showRevertAccess = false;
        if (this.storageService.getCipherObj('isAdminLoggedInAsPartner') == '1') {
          this.countDown?.unsubscribe();
          this.showCounter = false;
          this.logoutFromProfile(true);
        }
      }
    } else {
      this.showTimerAccess = true;
      this.showRevertAccess = false;
    }
    this.cd.detectChanges();
  }

  setTimer() {
    this.showCounter = true;
    if (Number(this.storageService.getCipherObj('logInExpiresIn')) == -1) {
      this.isForeverPermission = true;
      return;
    } else {
      this.isForeverPermission = false;
      // get currentTime and expiresTime for comparison
      let currentTime = new Date().getTime() / 1000;
      let expiresTime = new Date(this.storageService.getCipherObj('logInExpiresIn') ?? '').getTime() / 1000;
      let seconds = (expiresTime - currentTime);
      this.counter = seconds;
      this.countDown = timer(0, this.tick).subscribe(() => {
        if (this.counter >= 3) {
          --this.counter;
        } else {
          this.countDown.unsubscribe();
          this.showCounter = false;
          this.logoutFromProfile(true);
        }
        this.cd.detectChanges();
      });
    }
  }

  logout() {
    // this.auth.logout();
    let role = Number(this.storageService.getCipherObj('role'));
    this.auth.clearCookies();
    localStorage.clear();
    let logoutPath = "";
    if (role == AppConst.admin || role == AppConst.basicUser) {
      logoutPath = "/auth/login";
    } else if (role == AppConst.partner || role == AppConst.user || role == AppConst.organization) {
      logoutPath = "/auth/login/partner";
    }
    this.storageService.setCipherObj('logoutPath', logoutPath);
    window.location.reload();
  }

  redirectToProfile() {
    let currentUser;
    if (Number(this.sharedService.getRole()) == AppConst.basicUser || Number(this.sharedService.getRole()) == AppConst.admin) {
      currentUser = 'admin';
    } else currentUser = 'partner';
    this.router.navigateByUrl(`${currentUser}/profile`);
  }

  changeDuration() {
    this.isChangeDuration = !this.isChangeDuration;
    this.cd.detectChanges();
  }

  back() {
    this.isChangeDuration = false;
    this.cd.detectChanges();
  }

  revokePermission() {
    let questionTitle = 'Are you sure you want to revoke this permission?';
    let text = ``
    let confirmButtonText = "Yes"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
          this.partnerService.revokePermission()?.subscribe((res: any) => {
            if (res) {
              this.sharedService.loggerSuccess(res.message);
              this.showTimerAccess = true;
              this.showRevertAccess = false;
            } else this.sharedService.loggerError(res.message)
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          }, (err) => {
            this.sharedService.loggerError(err.message)
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          });
        }
      }
    });
  }

  setAdminPartnerAccess(value: any) {
    this.sharedService.showLoader();
    if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.authHTTPService.setPartnerLP({ duration: value }).subscribe((res: any) => {
        if (res.code == 200) {
          this.showRevertAccess = true;
          this.showTimerAccess = false;
          this.isChangeDuration = false;
          if (value == -1) {
            this.isForeverPermission = true;
            this.isShowChangeDuration = false;
          } else {
            this.accessTimeForAdmin = value;
            this.isForeverPermission = false;
            this.isShowChangeDuration = true;
          }
          this.sharedService.loggerSuccess(res?.message);
        } else this.sharedService.loggerError(res?.message)
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        this.sharedService.loggerError(err)
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      })
    }
  }

  logoutFromProfile(directPartnerLogout: boolean = false) {
    this.userIds = this.storageService.getCipherObj('userIds') ?? [];
    if (this.isPartner || directPartnerLogout) {
      this.sharedService.showLoader();
      this.authHTTPService.logoutFromPartner().subscribe((result: any) => {
        if (result && result.code == 200) {
          this.storageService.setCipherObj('userIds', [this.userIds[0]]);
          this.storageService.setCipherObj('breadCrumbs', [this.breadCrumbs[0]]);
          this.storageService.removeCipherText('isAdminLoggedInAsPartner')
          this.storageService.removeCipherText('rootAdminID');
          this.storageService.removeCipherText('logInExpiresIn');
          this.auth.clearCookies()
          this.auth.setAuthAfterPartnerLogin(result);
          this.storageService.removeCipherText(`view_qol_params`);
          this.router.navigateByUrl('/admin/dashboard');
          window.location.reload();
        } else this.sharedService.loggerError(result.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      })
    } else if (this.isUser) {
      let parentData = this.userIds[this.userIds.length - 2];
      this.logoutId = parentData?._id;
      this.sharedService.showLoader();
      this.authHTTPService.logoutFromUser({ LoginID: this.logoutId }).subscribe((result: any) => {
        if (result && result.code == 200) {
          this.breadCrumbs.splice(this.breadCrumbs.length - 1, 1)
          this.storageService.setCipherObj('breadCrumbs', this.breadCrumbs);
          this.userIds.splice(this.userIds.length - 1, 1);
          this.storageService.setCipherObj('userIds', this.userIds);
          this.auth.clearCookies();
          this.auth.setAuthAfterUserLogin(result.data);
          this.storageService.removeCipherText(`view_qol_params`);
          this.router.navigateByUrl('/partner/dashboard');
          window.location.reload();
        } else this.sharedService.loggerError(result.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      })
    }
  }

  openVersionList() {
    let versionData = this.storageService.getCipherObj('version');
    if (versionData && versionData?.status) {
      let modal = this.modalService.open(VersionModalComponent, { size: 'lg' });
      modal.componentInstance.versionData = versionData;
      modal.result.then((data) => {
        if (data && data?.event === 'close' && data.isChecked && (Number(this.storageService.getCipherObj('role')) !== AppConst.admin && Number(this.storageService.getCipherObj('role')) !== AppConst.basicUser)) {
          this.versionService.updateLoginVersion(versionData?._id)?.subscribe((res) => {
          }, (err) => console.log(err));
        }
      }, () => { });
    }
  }

  openDoc() {
    window.open(`https://${window.location.host}/docs`, '_blank')
  }

  // langs = AppConst.Languages;
  // language: LanguageFlag;
  // setLanguage(lang: string) {
  //   this.langs.forEach((language: LanguageFlag) => {
  //     if (language.lang === lang) {
  //       language.active = true;
  //       this.language = language;
  //     } else {
  //       language.active = false;
  //     }
  //   });
  // }

  // selectLanguage(lang: string) {
  //   this.translationService.setLanguage(lang);
  //   this.setLanguage(lang);
  //   // document.location.reload();
  // }
}

// interface LanguageFlag {
//   lang: string;
//   name: string;
//   flag: string;
//   active?: boolean;
// }

