import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ColorEvent } from 'ngx-color';
import { LayoutService } from 'src/app/_metronic/layout';
import { DefaultLayoutConfig, ILayout } from 'src/app/_metronic/layout/core/default-layout.config';
import { AuthService } from 'src/app/modules/shared/auth';
import { BrandingService } from 'src/app/services/branding.service';
import { ProfileService } from 'src/app/services/profile.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { AppConst } from '../../constants/app.constant';
import { SharedBrandSettingService } from '../../services/shared-brand-setting.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-shared-brand-setting',
  templateUrl: './shared-brand-setting.component.html',
  styleUrls: ['./shared-brand-setting.component.scss']
})
export class SharedBrandSettingComponent implements OnInit {

  @Input() viewURL: string;
  @Input() updateURL: string;
  @Input() createURL: any;
  @Input() permissionsObj: any;

  authServicee = AuthService;
  AppConst = AppConst;
  defaultConfigs: any;
  fieldsToUpdate: Partial<ILayout>;
  leftMenuflag: boolean;
  topMenuflag: boolean;
  state: any;
  navbgColour: any = '#000000';
  navLinkColour: any = '#000000';
  navLinkHoverColour: any = '#000000'
  navLinkActiveColour: any = '#000000';
  navLinkbackgroundColour: any = '#000000';
  brandingTextColour: any = '#000000';

  textColour: any = '#000000'
  linkColour: any = '#000000';
  linkHoverColour: any = '#000000';
  successColour: any = '#000000';
  infoColour: any = '#000000';
  warningColour: any = '#000000';
  dangerColour: any = '#000000';
  bodyBackgroundColour: any = '#000000';
  buttonPrimaryColour: any = '#0095e8';
  buttonTextColour: any = '#ffffffff';
  buttonSecondaryColour: any = '#6c757d';
  buttonDangerColour: any = '#dd3333';
  loginPageUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  faviconUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  navBarUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  menu: any = 1;
  loginPageAPIUrl: any;
  navBarAPIUrl: any;
  faviconAPIUrl: any;
  customCSSText: any = '';
  brandingId: any;
  brandSettings: any;
  isPreview: boolean = false;
  apiBrandingData: any;
  navBarText: string = '';
  technicalSupportURL: string = '';
  technicalSupportEmail: string = '';
  technicalSupportContact: string = '';
  editorOptions = { theme: 'vs-dark', language: 'css' };
  code: string = '.body{background-color:red}';
  text: string = 'Image';
  navOptionSelected: any = '1';
  NameFieldValidation: any = AppConst.NameFieldPattern;
  urlValidationPattern: any = AppConst.urlValidationPattern;
  mobilePattern: any = AppConst.mobilePattern;
  emailVal: any = AppConst.emailVal;
  isInvalidPattern: boolean = false;
  ImageNotUploaded: number;
  navBarImageStatus: boolean = true;
  loginPageImageStatus: boolean = true;
  faviconImageStatus: boolean = true;
  userRole: any;
  parentRole: any;

  constructor(private sharedService: SharedService,
    private layout: LayoutService, private storageService: StorageService,
    private cd: ChangeDetectorRef, private profileService: ProfileService,
    private brandSettingService: BrandingService, private authService: AuthService,
    private sharedBrandingSerice: SharedBrandSettingService,
    private router: Router) { }

  ngOnInit(): void {
    this.userRole = Number(this.storageService.getCipherObj('role'));
    this.parentRole = Number(this.storageService.getCipherObj('parentRole'));
    // this.navBarText = '';
    this.defaultConfigs = DefaultLayoutConfig;
    this.getDefaultBrandSettings();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.storageService.getCipherObj('preview')) {
          this.deleteCookies();
          this.sharedBrandingSerice.getDefaultBrandSettings(this.viewURL);
        }
      }
    })
    this.cd.detectChanges();
  }

  deleteCookies() {
    this.isPreview = false;
    this.storageService.removeCipherText('preview');
    this.storageService.removeCipherText('brandSetting');
  }

  setBrandSettings() {
    if (this.storageService.getCipherObj('preview')) {
      this.isPreview = true;
      let obj = this.storageService.getCipherObj('brandSetting')
      this.navbgColour = obj.navbgColour;
      this.navLinkColour = obj.navLinkColour;
      this.navLinkHoverColour = obj.navLinkHoverColour;
      this.navLinkActiveColour = obj.navLinkActiveColour;
      this.navLinkbackgroundColour = obj.navLinkbackgroundColour;
      this.bodyBackgroundColour = obj.bodyBackgroundColour;
      this.textColour = obj.textColour;
      this.linkColour = obj.linkColour;
      this.linkHoverColour = obj.linkHoverColour;
      this.buttonPrimaryColour = obj.buttonPrimaryColour;
      this.buttonSecondaryColour = obj.buttonSecondaryColour;
      this.buttonDangerColour = obj.buttonDangerColour;
      this.buttonTextColour = obj.buttonTextColour;
      this.successColour = obj.successColour;
      this.infoColour = obj.infoColour;
      this.warningColour = obj.warningColour;
      this.dangerColour = obj.dangerColour;
      this.loginPageAPIUrl = obj.loginPageAPIUrl;
      // this.loginPageUrl = obj.loginPageAPIUrl;
      this.faviconAPIUrl = obj.faviconAPIUrl;
      // this.faviconUrl = obj.faviconAPIUrl;
      this.navBarAPIUrl = obj.navBarAPIUrl;
      this.navBarText = obj.navBarText;
      // this.navBarUrl = obj.navBarAPIUrl;
      this.brandingId = obj.brandingId;
      this.customCSSText = obj.customCssText;
      this.brandingTextColour = obj.brandingTextColour;
      this.menu = obj.menu;
      this.technicalSupportURL = obj.technicalSupportURL;
      this.technicalSupportEmail = obj.technicalSupportEmail;
      this.technicalSupportContact = obj.technicalSupportContact;
      //this.authService.navBarImage.next(this.navBarAPIUrl);
      this.setNavBarOption();
      this.setCSSProperties(obj);
      //   this.deleteCookies();
      this.showHeader();
      this.cd.detectChanges();
    } else this.isPreview = false;
    return;
  }

  setNavBarOption() {
    this.authService.navBar.next({ 'image': this.navBarAPIUrl, 'text': this.navBarText, 'favicon': this.faviconAPIUrl });
    //  this.authService.navBar.next({ 'content': this.navBarText, 'type': 2 });
    // if (!this.navBarText && !this.navBarAPIUrl) {
    //   this.navOptionSelected = '1'
    //   this.navBarAPIUrl = './assets/media/logos/login-logo.svg';
    //   this.authService.navBar.next({ 'content': './assets/media/logos/login-logo.svg', 'type': 1 });
    // } else if (this.navBarAPIUrl) {
    //   this.navOptionSelected = '1'
    //   this.navBarText = '';
    //   this.authService.navBar.next({ 'content': this.navBarAPIUrl, 'type': 1 });
    // } else if (this.navBarText) {
    //   this.navOptionSelected = '2'
    //   this.navBarAPIUrl = '';
    //   this.authService.navBar.next({ 'content': this.navBarText, 'type': 2 });
    // }
    // console.log('this.faviconAPIUrl: ', this.faviconAPIUrl);
    // if(!this.faviconAPIUrl){
    // document.getElementById('favIcon')?.setAttribute('href',' ./assets/media/logos/login-logo.svg')
    // } else{
    //   document.getElementById('favIcon')?.setAttribute('href',this.faviconAPIUrl)
    // }
  }

  showHeader() {
    if (this.menu == 1) this.authService.showHeaderSubject.next(false);
    else this.authService.showHeaderSubject.next(true);
    this.cd.detectChanges();
  }

  onChangeNavBarOption(event: any) {
    this.navOptionSelected = event;
    if (this.navOptionSelected == '1') this.navBarText = '';
    else if (this.navOptionSelected == '2') this.navBarAPIUrl = '';
  }

  setLayoutConfig() {
    if (this.menu == 1) {
      this.fieldsToUpdate = {
        header: { ...DefaultLayoutConfig.header, left: 'page-title', display: false },
        aside: { ...DefaultLayoutConfig.aside, display: true },
      }
      // this.authService.showHeaderSubject.next(false);
    } else {
      this.fieldsToUpdate = {
        header: { ...DefaultLayoutConfig.header, left: 'menu', display: true },
        aside: { ...DefaultLayoutConfig.aside, display: false },
      }
      // this.authService.showHeaderSubject.next(true);
    }
    this.layout.setConfig(this.fieldsToUpdate);
  }

  onPreviewBrandSetting() {
    this.setLayoutConfig();
    let obj = {
      'navbgColour': this.navbgColour,
      'navLinkColour': this.navLinkColour,
      'navLinkHoverColour': this.navLinkHoverColour,
      'navLinkActiveColour': this.navLinkActiveColour,
      'navLinkbackgroundColour': this.navLinkbackgroundColour,
      'bodyBackgroundColour': this.bodyBackgroundColour,
      'textColour': this.textColour,
      'linkColour': this.linkColour,
      'linkHoverColour': this.linkHoverColour,
      'buttonPrimaryColour': this.buttonPrimaryColour,
      'buttonSecondaryColour': this.buttonSecondaryColour,
      'buttonDangerColour': this.buttonDangerColour,
      'buttonTextColour': this.buttonTextColour,
      'successColour': this.successColour,
      'infoColour': this.infoColour,
      'warningColour': this.warningColour,
      'dangerColour': this.dangerColour,
      'loginPageAPIUrl': this.loginPageAPIUrl,
      'navBarAPIUrl': this.navBarAPIUrl,
      'navBarText': this.navBarText,
      'faviconAPIUrl': this.faviconAPIUrl,
      'menu': this.menu,
      // 'loginPageUrl': this.loginPageUrl,
      // 'navBarUrl': this.navBarUrl,
      // 'faviconUrl': this.faviconUrl,
      'brandingId': this.brandingId,
      'customCssText': this.customCSSText,
      'brandingTextColour': this.brandingTextColour
    }
    //  this.setNavBarOption();
    this.storageService.setCipherObj('brandSetting', obj);
    this.storageService.setCipherObj('preview', 'true');
    this.storageService.setCipherObj('navBarImage', this.navBarAPIUrl || '');
    this.storageService.setCipherObj('navBarText', this.navBarText || '');
    window.location.reload();
  }

  favIcon: any = document.querySelector('#appIcons');

  getDefaultBrandSettings() {
    if (!this.storageService.getCipherObj('preview')) {
      this.brandSettingService.getBrandSettingData(this.viewURL).subscribe(
        (res: any) => {
          this.brandSettings = res.data;
          this.brandingId = res.data._id;
          if (this.brandSettings.navbgColour) this.navbgColour = this.brandSettings.navbgColour;
          if (this.brandSettings.navLinkColour) this.navLinkColour = this.brandSettings.navLinkColour;
          if (this.brandSettings.navLinkHoverColour) this.navLinkHoverColour = this.brandSettings.navLinkHoverColour;
          if (this.brandSettings.navLinkActiveColour) this.navLinkActiveColour = this.brandSettings.navLinkActiveColour;
          if (this.brandSettings.navLinkbackgroundColour) this.navLinkbackgroundColour = this.brandSettings.navLinkbackgroundColour;
          if (this.brandSettings.body.bodyBackgroundColour) this.bodyBackgroundColour = this.brandSettings.body.bodyBackgroundColour;
          if (this.brandSettings.body.textColour) this.textColour = this.brandSettings.body.textColour;
          if (this.brandSettings.body.linkColour) this.linkColour = this.brandSettings.body.linkColour;
          if (this.brandSettings.body.linkHoverColour) this.linkHoverColour = this.brandSettings.body.linkHoverColour;
          if (this.brandSettings.body.buttonPrimaryColour) this.buttonPrimaryColour = this.brandSettings.body.buttonPrimaryColour;
          if (this.brandSettings.body.buttonSecondaryColour) this.buttonSecondaryColour = this.brandSettings.body.buttonSecondaryColour;
          if (this.brandSettings.body.buttonDangerColour) this.buttonDangerColour = this.brandSettings.body.buttonDangerColour;
          if (this.brandSettings.body.buttonTextColour) this.buttonTextColour = this.brandSettings.body.buttonTextColour;
          if (this.brandSettings.body.successColour) this.successColour = this.brandSettings.body.successColour;
          if (this.brandSettings.body.infoColour) this.infoColour = this.brandSettings.body.infoColour;
          if (this.brandSettings.body.warningColour) this.warningColour = this.brandSettings.body.warningColour;
          if (this.brandSettings.body.dangerColour) this.dangerColour = this.brandSettings.body.dangerColour;
          this.loginPageAPIUrl = this.brandSettings.loginPageImg;
          this.faviconAPIUrl = this.brandSettings.favicon;
          this.faviconAPIUrl ? this.favIcon.href = this.faviconAPIUrl : this.favIcon.href = ``;
          this.navBarAPIUrl = this.brandSettings.navbarImg;
          this.navBarText = this.brandSettings.navBarText;
          this.menu = this.brandSettings.menu;
          this.customCSSText = this.brandSettings.customCss;
          this.technicalSupportURL = this.brandSettings.technicalSupportURL;
          this.technicalSupportEmail = this.brandSettings.technicalSupportEmail;
          this.technicalSupportContact = this.brandSettings.technicalSupportContact;
          //   this.brandingTextColour=this.brandSettings.brandingTextColour;
          if (this.brandSettings.brandingTextColour) {
            this.brandingTextColour = this.brandSettings.brandingTextColour;
          } else {
            this.brandingTextColour = '#000000'
          }
          this.setNavBarOption();
          this.showHeader();
          let obj1 = {
            navbgColour: this.navbgColour,
            navLinkColour: this.navLinkColour,
            navLinkHoverColour: this.navLinkHoverColour,
            navLinkActiveColour: this.navLinkActiveColour,
            navLinkbackgroundColour: this.navLinkbackgroundColour,
            bodyBackgroundColour: this.bodyBackgroundColour,
            textColour: this.textColour,
            linkColour: this.linkColour,
            linkHoverColour: this.linkHoverColour,
            buttonPrimaryColour: this.buttonPrimaryColour,
            buttonSecondaryColour: this.buttonSecondaryColour,
            buttonDangerColour: this.buttonDangerColour,
            buttonTextColour: this.buttonTextColour,
            successColour: this.successColour,
            infoColour: this.infoColour,
            warningColour: this.warningColour,
            dangerColour: this.dangerColour,
            brandingTextColour: this.brandingTextColour
          }
          //this.authService.navBar.next({ 'content': this.navBarAPIUrl, 'type': 1 });
          this.storageService.setCipherObj('oldNavBarText', this.navBarText);
          this.storageService.setCipherObj('oldNavBarImage', this.navBarAPIUrl);
          this.setCSSProperties(obj1);
          this.setLayoutConfig();
          this.cd.detectChanges();
          this.sharedService.hideLoader();
        }
      );
    } else {
      this.setBrandSettings();
    }
  }

  onCancelPreview() {
    this.deleteCookies();
    this.storageService.setCipherObj('navBarText', this.storageService.getCipherObj('oldNavBarText') || '');
    this.storageService.setCipherObj('navBarImage', this.storageService.getCipherObj('oldNavBarImage') || '');
    this.brandSettingService.getBrandSettingData(this.viewURL).subscribe((res: any) => {
      if (res.data) {
        setTimeout(() => {
          this.sharedService.loggerSuccess('Cancelled theme changes!')
        }, 500);
        this.menu = res.data.menu;
        this.setLayoutConfig();
        window.location.reload();
      }
    });
  }

  rgba2hex(colorcode: any) {
    let rgb: any;
    let alpha: any;
    let hex: any;
    let a: any;
    rgb = `rgba(${colorcode.r},${colorcode.g},${colorcode.b},${colorcode.a})`;
    rgb = rgb.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = (rgb && rgb[4] || "").trim(),
      hex = rgb ?
        (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : rgb;
    if (alpha !== "") a = alpha;
    else a = 1;
    // multiply before convert to HEX
    a = ((a * 255) | 1 << 8).toString(16).slice(1)
    hex = '#' + hex + a;
    return hex;
  }

  onLeftSideMenuClicked() {
    this.menu = 1;
    this.sharedService.loggerSuccess('Left side menu option selected!')
  }

  setCSSProperties(obj: any) {
    // this.setCssProperty('--background_colour', obj.navbgColour);
    this.setCssProperty('--navbgColour', obj.navbgColour);
    this.setCssProperty('--navLinkColour', obj.navLinkColour);
    this.setCssProperty('--navLinkbackgroundColour', obj.navLinkbackgroundColour);
    this.setCssProperty('--navLinkHoverColour', obj.navLinkHoverColour);
    this.setCssProperty('--navLinkActiveColour', obj.navLinkActiveColour);
    this.setCssProperty('--bodyBackgroundColour', obj.bodyBackgroundColour);
    this.setCssProperty('--textColour', obj.textColour);
    this.setCssProperty('--linkColour', obj.linkColour);
    this.setCssProperty('--linkHoverColour', obj.linkHoverColour);
    this.setCssProperty('--buttonPrimaryColour', obj.buttonPrimaryColour);
    this.setCssProperty('--buttonSecondaryColour', obj.buttonSecondaryColour);
    this.setCssProperty('--buttonDangerColour', obj.buttonDangerColour);
    this.setCssProperty('--buttonTextColour', obj.buttonTextColour);
    this.setCssProperty('--successColour', obj.successColour);
    this.setCssProperty('--infoColour', obj.infoColour);
    this.setCssProperty('--warningColour', obj.warningColour);
    this.setCssProperty('--dangerColour', obj.dangerColour);
    this.setCssProperty('--brandingTextColour', obj.brandingTextColour);
  }

  setCssProperty(variable: string, value: any) {
    document.documentElement.style.setProperty(variable, value);
  }

  onTopSideMenuClicked() {
    this.menu = 2;
    this.sharedService.loggerSuccess('Top side menu option selected!')
  }

  navBackGroundChangeComplete(event: ColorEvent) {
    this.navbgColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  navLinkBackGroundChangeComplete(event: ColorEvent) {
    this.navLinkbackgroundColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  navLinkColourChangeComplete(event: ColorEvent) {
    this.navLinkColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  navLinkHoverColourChangeComplete(event: ColorEvent) {
    this.navLinkHoverColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  navLinkActiveColourChangeComplete(event: ColorEvent) {
    this.navLinkActiveColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  textColourChangeComplete(event: ColorEvent) {
    this.textColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  linkColourChangeComplete(event: ColorEvent) {
    this.linkColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  linkHoverColourChangeComplete(event: ColorEvent) {
    this.linkHoverColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  buttonPrimaryColourChangeComplete(event: ColorEvent) {
    this.buttonPrimaryColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  buttonSecondaryColourChangeComplete(event: ColorEvent) {
    this.buttonSecondaryColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  buttonDangerColourChangeComplete(event: ColorEvent) {
    this.buttonDangerColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  successColourChangeComplete(event: ColorEvent) {
    this.successColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  bodyBGColorChangeComplete(event: ColorEvent) {
    this.bodyBackgroundColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  infoColourChangeComplete(event: ColorEvent) {
    this.infoColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  warningColourChangeComplete(event: ColorEvent) {
    this.warningColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  buttonTextColourChangeComplete(event: ColorEvent) {
    this.buttonTextColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  brandingTextColourChangeComplete(event: ColorEvent) {
    this.brandingTextColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  dangerColourChangeComplete(event: ColorEvent) {
    this.dangerColour = this.rgba2hex(event.color.rgb);
    this.cd.detectChanges();
  }

  setMyStyles(color: any) {
    let styles = { 'background-color': color };
    return styles;
  }

  async uploadFile(event: any, name: any) {
    let file;
    if (event?.target?.files) file = event.target.files;
    else file = event;
    if (file && file[0]) {
      if (file[0].type == 'image/jpeg' || file[0].type == 'image/png' || file[0].type == 'image/jpg') {
        if (file[0].size > 2000000) {
          this.sharedService.loggerError('File size should be less than 2mb');
          return;
        }
      } else {
        this.sharedService.loggerError('Only PNG, JPG, and JPEG files are allowed.');
        return;
      }
      this.sharedService.showLoader();
      const formData = new FormData();
      formData.append('img', file[0]);
      await this.profileService.uploadImage(formData).subscribe((res: any) => {
        if (res) {
          if (name == 'login') {
            this.loginPageImageStatus = true;
            this.loginPageAPIUrl = res.data[0];
          }
          if (name == 'favicon') {
            this.faviconImageStatus = true;
            this.faviconAPIUrl = res.data[0];
          }
          if (name == 'navbar') {
            this.navBarImageStatus = true;
            this.navBarAPIUrl = res.data[0];
          }
          // reader.readAsDataURL(file);
          // reader.onload = () => {
          this.sharedService.hideLoader();
          //   // ChangeDetectorRef since file is loading outside the zone
          this.cd.markForCheck();
          // }
        }
      }, (err: any) => {
        this.sharedService.hideLoader();
        let errorMessage;
        if (err == 'Maintenance mode is on. Please try again later.') errorMessage = 'App In Maintenance Mode';
        else errorMessage = err?.error?.err;
        Swal.fire({
          // position: 'top-right',
          width: 300,
          icon: 'error',
          text: errorMessage
        });
      });
    }
  }

  onDeletePicture(name: string) {
    if (name == 'login') {
      this.loginPageAPIUrl = '';
    } else if (name == 'favicon') {
      this.faviconAPIUrl = '';
      //document.getElementById('favIcon')?.setAttribute('href',' ./assets/media/logos/login-logo.svg')
    } else if (name == 'navbar') {
      this.navBarAPIUrl = '';
      // this.navBarAPIUrl = './assets/media/logos/login-logo.svg';
      // this.authService.navBar.next({ 'content': './assets/media/logos/login-logo.svg', 'type': 1 });
      //this.authService.navBarImage.next('./assets/media/logos/login-logo.svg');
    }
  }

  onSubmitBrandSetting() {
    let questionTitle = 'Are you sure you want to save this brand settings?';
    let text = ``
    let confirmButtonText = "Yes";
    if (!this.navBarAPIUrl) this.navBarImageStatus = false;
    if (!this.loginPageAPIUrl) this.loginPageImageStatus = false;
    if (!this.faviconAPIUrl) this.faviconImageStatus = false;
    if (this.isInvalidPattern || !this.navBarAPIUrl || !this.loginPageAPIUrl || !this.faviconAPIUrl || !this.navBarText) return;
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.apiBrandingData = {
          navbgColour: this.navbgColour,
          navLinkbackgroundColour: this.navLinkbackgroundColour,
          body: {
            bodyBackgroundColour: this.bodyBackgroundColour,
            dangerColour: this.dangerColour,
            infoColour: this.infoColour,
            linkColour: this.linkColour,
            linkHoverColour: this.linkHoverColour,
            buttonPrimaryColour: this.buttonPrimaryColour,
            buttonSecondaryColour: this.buttonSecondaryColour,
            buttonDangerColour: this.buttonDangerColour,
            buttonTextColour: this.buttonTextColour,
            successColour: this.successColour,
            textColour: this.textColour,
            warningColour: this.warningColour
          },
          favicon: this.faviconAPIUrl,
          navLinkActiveColour: this.navLinkActiveColour,
          navLinkColour: this.navLinkColour,
          navLinkHoverColour: this.navLinkHoverColour,
          loginPageImg: this.loginPageAPIUrl,
          menu: this.menu,
          brandingTextColour: this.brandingTextColour,
          navBarText: this.navBarText,
          navbarImg: this.navBarAPIUrl,
          technicalSupportURL: this.technicalSupportURL,
          technicalSupportEmail: this.technicalSupportEmail,
          technicalSupportContact: this.technicalSupportContact
        }
        // if (this.navBarAPIUrl != '') {
        //   this.apiBrandingData = {
        //     ...this.apiBrandingData,
        //     navbarImg: this.navBarAPIUrl
        //   }
        // }
        // if (this.navBarText != '') {
        //   this.apiBrandingData = {
        //     ...this.apiBrandingData,
        //     navBarText: this.navBarText
        //   }
        // }
        if (this.customCSSText != '') {
          this.apiBrandingData = { ...this.apiBrandingData, customCss: this.customCSSText }
        }
        this.sharedService.showLoader();
        if (this.brandingId) {
          this.brandSettingService.updateBrandSetting(this.updateURL, this.apiBrandingData, this.brandingId).subscribe(
            (res: any) => {
              if (res.code == 200) {
                this.setLayoutConfig();
                this.layout.setConfig(this.fieldsToUpdate);
                this.storageService.setCipherObj('navBarImage', this.navBarAPIUrl || '');
                this.storageService.setCipherObj('navBarText', this.navBarText || '');
                this.storageService.setCipherObj('menu', this.menu || '');
                this.showHeader();
                this.deleteCookies();
                this.sharedService.hideLoader();
                this.sharedService.loggerSuccess('Changes to the theme are saved!')
                window.location.reload();
              }
            },
            (err) => {
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
            }
          );
        } else {
          this.brandSettingService.createBrandSetting(this.createURL, this.apiBrandingData).subscribe(
            (res: any) => {
              if (res.code == 200) {
                this.setLayoutConfig();
                this.showHeader();
                this.deleteCookies();
                this.sharedService.hideLoader();
                window.location.reload();
              }
            },
            (err) => {
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
            }
          );
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.storageService.getCipherObj('preview')) this.onCancelPreview();
    this.storageService.removeCipherText('oldNavBarText');
    this.storageService.removeCipherText('oldNavBarImage');
  }

  isPatternBrandTextValid(input: string) {
    if (this.NameFieldValidation.test(input) == false) this.isInvalidPattern = true;
    else this.isInvalidPattern = false;
    return this.NameFieldValidation.test(input);
  }

  isURLPatternValid(input: string) {
    if (this.urlValidationPattern.test(input) == false) this.isInvalidPattern = true;
    else this.isInvalidPattern = false;
    return this.urlValidationPattern.test(input);
  }

  isEmailPatternValid(input: string) {
    if (this.emailVal.test(input) == false) this.isInvalidPattern = true;
    else this.isInvalidPattern = false;
    return this.emailVal.test(input);
  }

  isMobilePatternValid(input: string) {
    if (this.mobilePattern.test(input) == false) this.isInvalidPattern = true;
    else this.isInvalidPattern = false;
    return this.mobilePattern.test(input);
  }
}
