<div class="card mb-5 mb-xl-10 border-2 border">
    <div class="card-body p-3">
        <div class="row">
            <div class="col-lg-6 col-xxl-6">
                <div class="card mb-5 mb-xl-10 border-1 border">
                    <div class="card-header cursor-pointer border-top d-flex px-5 min-h-45px bg-light">
                        <div class="card-title m-0">
                            <h3 class="fw-bolder m-0">WAN Services </h3>
                        </div>
                        <div class="me-2 card-toolbar d-flex align-items-center justify-content-end" title="Edit">
                            <button class="btn btn-sm btn-primary"
                                [ngClass]="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                                (click)="(latest_tuning && latest_tuning?.status == 'running') || (bondPermission && bondPermission.isUpdateDisable)  ? '': openWanServices()">
                                <span [inlineSVG]="'./assets/media/icons/normal/pen-square.svg'"
                                    class="svg-icon svg-icon-5"></span> Edit
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-5">
                        <div class="row mb-5">
                            <div class="col-lg-12 col-xxl-6 col-md-6">
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        QoS profile </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.qos_profile?.name">
                                            {{bondDetails?.qos_profile?.name}}
                                        </span>
                                        <span *ngIf="!bondDetails?.qos_profile?.name" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Classification profile </label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark"
                                            *ngIf="bondDetails?.classification_profile && bondDetails?.classification_display_name">
                                            {{bondDetails?.classification_display_name}}
                                        </span>
                                        <span *ngIf="!bondDetails?.classification_profile" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <label
                                        class="col-lg-4 col-md-4 col-xxl-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                        Flow collectors</label>
                                    <div class="col-lg-4 col-xxl-6 col-md-6">
                                        <span class="fs-7 text-dark" *ngIf="bondDetails?.flow_collectors[0]">
                                            {{bondDetails?.flow_collectors[0]}}
                                        </span>
                                        <span *ngIf="!bondDetails?.flow_collectors[0]" title="false">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/minus-solid.svg'"
                                                class="svg-icon svg-icon-3 ml-2 me-1">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>