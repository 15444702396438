<div class="modal-header px-5 py-3">
  <h5 class="modal-title">{{isEdit ? 'Edit': 'Add'}} Group Allocation</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body p-5">
  <div class="row" *ngIf="errors?.non_field_errors">
    <div class="col-md-12">
      <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
    </div>
  </div>
  <div class="row" *ngIf="errors && !errors['non_field_errors']">
    <div class="col-md-12">
      <div class="alert alert-danger">Please correct the errors below.</div>
    </div>
  </div>
  <form [formGroup]="GroupAllocationAdd">
    <p> Specify an IP subnet that can be used at the routing groups specified below.</p>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-5">
          <div class="form-group inputtext-block mb-5">
            <label class="form-label"> Subnet </label>
            <input type="text" formControlName="network" name="network"
              class="form-control form-control-lg form-control-solid" aria-describedby="emailHelp" placeholder="Subnet">
            <small class="form-invalid-message">
              <span class="text-danger">
                {{errors?.network?.join(' ')}}
              </span>
            </small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label label-list"> Routing Groups </label>
          <select multiple class="group-select-box textarea-field w-100 br-6" id="routing_group"
            formControlName="routing_group">
            <option *ngFor="let group of groupList"
              style="text-align: left; border-radius: 0px; margin-bottom: 2px; padding: 5px 15px;" [value]="group.url">
              {{group.name}}
            </option>
          </select>
          <small class="form-invalid-message">
            <span class="text-danger">
              {{errors?.routing_group?.join(' ')}}
            </span>
          </small>
        </div>
      </div>
    </div>
  </form>
  <p class="mt-5"> Hold down <span class="bg-black p-2 text-white mx-2">CTRL</span>, or <span
      class="bg-black p-2 text-white mx-2">⌘</span> on a Mac, to select more than one. </p>
</div>
<div class="modal-footer px-5 py-3">
  <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
    <div>
      <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
      <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
    </div> {{isEdit ? 'Save': 'Add'}} Allocation
  </button>
  <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
    <div>
      <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
    </div> Cancel
  </button>
</div>