import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/';

@Injectable({
  providedIn: 'root'
})
export class PartnerBondsPerformanceService {
  mainId = this.storageService.getCipherObj('mainId');

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getResponse(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  getBonderStatus(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  getGraphCredentials(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  getSenderData(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  getReceiverData(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  getTunnelLatencySender(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  getTunnelLatencyReceiver(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }
}
