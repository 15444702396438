import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedBondToSpaceMoveComponent } from 'src/app/shared/components/shared-bonds/shared-bonds-listing/shared-bond-to-space-move/shared-bond-to-space-move.component';
import { SharedSelectColumnFormComponent } from 'src/app/shared/components/shared-bonds/shared-bonds-listing/shared-select-column-form/shared-select-column-form.component';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedBondEditMultipleComponent } from '../../shared-bonds/shared-bonds-listing/shared-bond-edit-multiple/shared-bond-edit-multiple.component';

@Component({
  selector: 'app-shared-sdwan-create',
  templateUrl: './shared-sdwan-create.component.html',
  styleUrls: ['./shared-sdwan-create.component.scss']
})
export class SharedSdwanCreateComponent implements OnInit {
  page: any = 1;
  limit: any = AppConst.pageSize;
  searchKey = '';
  allBonds: any[] = [];
  length: any;
  emailTemplate: any;
  partnerList: any[] = [];
  sortBy: any = 'bonder__name';
  sortOrder: number = -1;
  templateId: any;
  availableColumns: any[] = [];
  selectedColumns: any[] = [];
  bondsId: any[] = [];
  aggregatorIds: any[] = [];
  secondaryAggregatorIds: any[] = [];
  aggregatorsInfo: any[] = [];
  connectedIPInfo: any;
  classificationProfile: any = [];
  legsInfo: any;
  lengthCopy: any;
  key: any = 'space';
  spaceOption: any = '0';
  isBondListAPI: boolean = true;
  isLegListAPI: boolean = false;
  isAggListAPI: boolean = false;
  isProfileListAPI: boolean = false;
  isIPListAPI: boolean = false;
  isColumnListAPI: boolean = false;

  @Input() parentId: any;
  @Input() sharedSdwanData: any;
  @Input() isAdminMail: boolean = false;
  @Input() isSDWAN: boolean = false;
  @Input() permissionsObj: { view: boolean; update: boolean; delete: boolean; clone: boolean; } = { view: true, update: true, delete: true, clone: true };

  @Output() setBondList = new EventEmitter<any>();

  constructor(private sharedService: SharedService,
    private bondingService: PartnerBondingService,
    private spaceService: PartnerSpaceService,
    private cd: ChangeDetectorRef, private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getAvailableColumns();
  }

  getAvailableColumns() {
    this.isColumnListAPI = true;
    this.bondingService.columnFilter().subscribe((res: any) => {
      if (res) {
        this.selectedColumns = res.selectedColumn;
        this.availableColumns = res.remainColumn;
        // this.spaceOption = res.spaceOption;
        if (!this.selectedColumns.length) this.length = 0;
        else this.length = this.allBonds.length;
        this.getSpaceBondList();
      }
      this.isColumnListAPI = false;
      this.cd.detectChanges();
    }, (err) => {
      this.isColumnListAPI = false;
      this.sharedService.loggerError(err);
    });
  }

  getSpaceBondList() {
    let sortKey = 'bonder__name';
    sortKey = this.sortBy
    if (this.sortOrder == -1 && this.sortBy != undefined) {
      sortKey = '-' + this.sortBy
    }
    if (this.spaceOption == 0) this.key = 'space';
    else this.key = 'space__under';
    let reqObj = {
      "url": `bonds/?ordering=${sortKey}&search=${this.searchKey}&${this.key}=${this.parentId}`,
      "method": "GET",
      "data": {}
    }
    this.isBondListAPI = true;
    this.spaceService.getSpace(reqObj).subscribe((res: any) => {
      if (res?.data?.length > 0) {
        this.lengthCopy = res.data.length;
        this.length = res.data.length;
        this.allBonds = res.data;
        if (this.allBonds && this.allBonds.length > 0) {
          if (this.sharedSdwanData) {
            this.allBonds = this.allBonds.filter((bond: any) => {
              if (this.sharedSdwanData?.unsharedBondList.includes('' + bond.id) || this.sharedSdwanData?.unAvailableBondList.includes('' + bond.id)) return false;
              else return true;
            });
          }
          this.getAllAggregatorsId();
          this.getBondIds();
        }
      } else this.length = 0;
      this.isBondListAPI = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isBondListAPI = false;
      this.cd.detectChanges();
    });
  }

  loginToIlluminate(): void {
    let keyList: any[] = [];
    if (this.allBonds.length > 0) {
      this.allBonds.forEach((bond) => {
        if (bond && bond?.bonder?.key) keyList.push(this.keyFormate(bond?.bonder?.key));
      })
    } else return;
    if (keyList.length <= 0) return;
    this.sharedService.showLoader();
    this.bondingService.generateIlluminateToken(keyList)?.subscribe((res: any) => {
      if (res.code == 200 && res?.data?.token && res?.data?.url) window.open(res?.data?.url + '?token=' + res?.data?.token, '_blank')
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }

  onAdd() {
    this.router.navigate(['/partner/bonds/create'], { queryParams: { isSpace: true } })
  }

  onEdit(data: any) {
    this.router.navigate([`/partner/bonds/edit/${data.bondId}/${data.bonderId}`], { queryParams: { isSpace: true } })
  }

  onFilterChange(data: any) {
    this.spaceOption = data.spaceOption ?? '0';
    this.getSpaceBondList();
  }

  onSecondFilterChange(data: any) {
    let bondID = data.bondId;
    let url, method;
    if (data.key == 'restart') {
      url = `bonds/${bondID}/restart/`;
      method = 'POST';
    } else if (data.key == 'restore') {
      url = `bonds/${bondID}/restore_to_primary_agg/`;
      method = 'POST';
    } else if (data.key == 'move') {
      url = `spaces/?as_tree=true`;
      method = 'GET';
      let count = data.count;
      this.sharedService.showLoader();
      this.bondingService.getResponse(url, method).subscribe((res: any) => {
        this.sharedService.hideLoader();
        let modal = this.modalService.open(SharedBondToSpaceMoveComponent, { size: 'lg' });
        modal.componentInstance.bondDetails = res.data;
        modal.componentInstance.count = count;
        modal.componentInstance.isConfig = false;
        modal.componentInstance.bondId = bondID;
        modal.componentInstance.bonderId = data.bonderId;
        modal.closed.subscribe((result: any) => {
          if (result.event == 'save') this.getSpaceBondList();
        });
      }, (err) => {
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      });
      return;
    } else if (data.key == 'edit') {
      let modal = this.modalService.open(SharedBondEditMultipleComponent, { size: 'lg' });
      modal.componentInstance.bondList = data.bondList;
      modal.closed.subscribe((result: any) => {
        if (result.event == 'save') this.getSpaceBondList();
      });
      return;
    }

    this.sharedService.showLoader()
    this.bondingService.getResponse(url, method).subscribe((res: any) => {
      if (res.code == 200) {
        this.sharedService.loggerSuccess(res.data.description);
        this.getSpaceBondList();
      }
      this.sharedService.hideLoader()
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  restartBond() {
    let url = `bonds/${this.bondsId}/restart/`
    this.bondingService.getResponse(url, 'POST').subscribe((res: any) => {
      this.cd.detectChanges();
    });
  }

  getAggregators() {
    let id = this.aggregatorIds.toString();
    let url = `aggregators/?id__in=${id}`;
    this.isAggListAPI = true;
    this.bondingService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res && res.data) {
        this.aggregatorsInfo = res.data;
      }
      this.setBondList.emit({ bondList: this.allBonds, aggregatorList: this.aggregatorsInfo });
      this.isAggListAPI = false;
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isAggListAPI = false;
      this.cd.detectChanges();
    });
  }

  getConnectedIps() {
    let id = this.bondsId.toString();
    let url = `connected_ips/?enabled=true&bond__id__in=${id}`;
    this.isIPListAPI = true;
    this.bondingService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res && res.data) {
        this.connectedIPInfo = res.data;
        this.allBonds.forEach(e => {
          e.connectedIPInfo = this.connectedIPInfo.filter((x: any) => x.bond === e.url)
        });
      }
      this.isIPListAPI = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isIPListAPI = false;
      this.cd.detectChanges();
    });
  }

  getClassificationProfile() {
    let id = this.bondsId.toString();
    let url = `classification/profiles/?bond__id__in=${id}`;
    this.isProfileListAPI = true;
    this.bondingService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res && res.data && res.data.length > 0) {
        this.classificationProfile = res.data;
        this.allBonds.forEach(e => {
          this.classificationProfile.forEach((x: any) => {
            if (x.url === e.classification_profile) e.classification_display_name = x.display_name;
          })
        });
      }
      this.isProfileListAPI = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isProfileListAPI = false;
      this.cd.detectChanges();
    });
  }

  getLegsInfo() {
    let id = this.bondsId.toString();
    let url = `legs/?bond__id__in=${id}`;
    this.isLegListAPI = true;
    this.bondingService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res && res.data) {
        this.legsInfo = res.data;
        this.allBonds.forEach(e => {
          e.legsInfo = this.legsInfo.filter((x: any) => x.bond_id == e.id)
        })
        let bondList = this.allBonds.forEach(e => {
          e.active_legs = e.legsInfo;
        })
        this.setBondList.emit({ bondList: bondList, aggregatorList: this.aggregatorsInfo });
      }
      this.isLegListAPI = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isLegListAPI = false;
      this.cd.detectChanges();
    });
  }

  getBondIds() {
    this.bondsId = this.allBonds.map(x => x.id)
    this.bondsId = Array.from(new Set(this.bondsId));
    this.getConnectedIps();
    this.getClassificationProfile();
    this.getLegsInfo();
  }

  getAllAggregatorsId() {
    this.aggregatorIds = this.allBonds.map(x => {
      if (x.aggregator_id != 'null' && x.aggregator_id != undefined && x.aggregator_id != null) {
        return x.aggregator_id
      }
    }
      // x.aggregator_id
    ).filter(x => x);

    this.aggregatorIds = Array.from(new Set(this.aggregatorIds));

    this.secondaryAggregatorIds = this.allBonds.map(x => {
      if (x.secondary_aggregator_id != 'null' && x.secondary_aggregator_id != undefined && x.secondary_aggregator_id != null) {
        return x.secondary_aggregator_id
      }
    }
    ).filter(x => x);
    this.secondaryAggregatorIds = Array.from(new Set(this.secondaryAggregatorIds));
    if (this.secondaryAggregatorIds.length)
      this.aggregatorIds = this.aggregatorIds.concat(this.secondaryAggregatorIds);

    this.getAggregators();
  }

  searchBond(event: any) {
    this.searchKey = event;
    this.getSpaceBondList();
  }

  onResetFilter() {
    this.searchKey = '';
    this.getSpaceBondList();
  }

  onReceiveMessage(event: any) {
    this.limit = event.pageSize;
    this.page = event.pageIndex;
    this.getSpaceBondList();
  }

  onSort(event: any) {
    if (this.sortBy == event) this.sortOrder = this.sortOrder == 1 ? -1 : 1;
    else {
      this.sortBy = event;
      this.sortOrder = 1;
    }
    this.getSpaceBondList();
    this.cd.detectChanges();
  }

  saveColumn() {
    // this.sharedService.showLoader();
    this.bondingService.saveColumn(this.selectedColumns, parseInt(this.spaceOption), this.limit).subscribe((res: any) => {
      if (res.code == 200) {
        this.sharedService.hideLoader();
        this.getSpaceBondList();
      }
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  open(event: any) {
    let modal = this.modalService.open(SharedSelectColumnFormComponent, { size: 'lg' });
    modal.componentInstance.availableColumnsArray = this.availableColumns;
    modal.componentInstance.selectedItems = this.selectedColumns.slice();
    modal.closed.subscribe((result: any) => {
      if (result.event == 'save') {
        this.selectedColumns = result.selectedColumns;
        this.availableColumns = result.availableColumns;
        this.saveColumn();
        if (!this.selectedColumns.length) this.length = 0;
        else this.length = this.lengthCopy;
      }
      this.cd.detectChanges();
    });
  }

  keyFormate(key: string) {
    if (!key) return;
    return key.match(/.{1,5}/g)?.join('-');
  }

}
