import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { EmailTemplateModel } from '../models/email-template.model';
import { SystemConfigModel } from '../models/system-configuration.model';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {

    loginPageImg: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
        this.loginPageImg = new BehaviorSubject<any>(null);
    }

    get loginPageImg$() {
        return this.loginPageImg.asObservable();
    }

    // Fetch Brand Data
    getBrandSettingData(url: any) {
        return this.http.get<SystemConfigModel>(environment.apiUrl + url);
    }

    // Create Brand 
    createBrandSetting(url: any, data: any) {
        return this.http.post<SystemConfigModel>(environment.apiUrl + url, data);
    }

    // update brand
    updateBrandSetting(url: any, data: any, id: any) {
        return this.http.put<SystemConfigModel>(`${environment.apiUrl}${url}${id}`, data);
    }

    createCustomEmailTemplate(data: any) {
        return this.http.post<EmailTemplateModel>(environment.apiUrl + '/customeEmailTemplate/add', data);
    }

    getCustomEmailTemplateById(id: any) {
        return this.http.get<any>(environment.apiUrl + `/customeEmailTemplate/view/${id}`);
    }

    updateCustomEmailTemplate(data: any, id: any) {
        return this.http.put<EmailTemplateModel>(environment.apiUrl + `/customeEmailTemplate/update/${id}`, data);
    }

    deleteCustomEmailTemplate(id: any) {
        return this.http.delete(environment.apiUrl + `/customeEmailTemplate/deleteEmailTemplate/${id}`);
    }

    getAllEmailTemplateListing(search: string,
        page: string,
        limit: number,
        name: string,
        sort: any,
    ) {
        let params = new HttpParams()
            .set('searchText', search ? search : '')
            .set('page', page)
            .set('pageSize', limit)
            .set('name', name ? name : 'createdAt')
            .set('sort', sort ? sort : -1)
        return this.http.get<any>(environment.apiUrl + '/customeEmailTemplate/list', { params });
    }

    getDefaultCustomTemplate(body: any) {
        return this.http.post<any>(environment.apiUrl + '/customeEmailTemplate/getByType', body);
    }

    getTemp(body: any) {
        return this.http.post<any>(environment.apiUrl + '/customeEmailTemplate/getPreview', body);
    }
}
