import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-hosts-bonders',
  templateUrl: './shared-hosts-bonders.component.html',
  styleUrls: ['./shared-hosts-bonders.component.scss']
})
export class SharedHostsBondersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
