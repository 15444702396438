<div class="row-reverse mt-10 mb-1">
    <div *ngIf="BondFailbackMessageArr?.length > 0">
        <ul class="col-md-12 messages list-unstyled"
            *ngFor="let bondFailbackMsg of BondFailbackMessageArr; let i=index">
            <li class="alert alert-warning animated fadeIn d-flex align-items-center justify-content-between bg-light-warning px-3 py-1 br-6"
                *ngIf="bondFailbackMsg?.object_type == 'bond' && bondFailbackMsg?.severity == 'warning' &&
                    bondFailbackMsg?.message !='Bond has no legs. Please add a leg before deploying.'">
                {{getFormattedMessage(bondFailbackMsg?.message)}} <button type="button"
                    class="close text-dark border-0 fs-1 bg-light-warning p-0" data-dismiss="alert"
                    (click)="removeBondFailbackMessage(i)">×</button>
            </li>
        </ul>
        <ul class="col-md-12 messages list-unstyled" *ngIf="isNodeProvisionData">
            <li
                class="alert alert-info animated fadeIn d-flex align-items-center justify-content-between bg-info px-3 py-2 br-6">
                <span class="text-white fw-bold">
                    Node provisioning is pending for Device with MAC-Address : {{nodeProvisionData.mac}}.
                </span>
                <div class="d-flex align-items-center">
                    <span *ngIf="nodeProvisionTimer.hour || nodeProvisionTimer.minutes || nodeProvisionTimer.seconds"
                        class="me-2">
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen013.svg'"
                            class="svg-icon svg-icon-1 svg-icon-block svg-clock-white mb-2"></span>
                        <span class="text-danger fw-bolder me-2 timer-w">
                            {{nodeProvisionTimer.hour}}:{{nodeProvisionTimer.minutes}}:{{nodeProvisionTimer.seconds}}
                        </span>
                    </span>
                    <button (click)="cancelProvisioning()" type="button" class="btn btn-danger btn-style cancel-btn">
                        Cancel Provisioning
                    </button>
                </div>
            </li>
        </ul>
        <ul class="col-md-12 messages list-unstyled"
            *ngIf="latest_tuning?.bond && latest_tuning.status && latest_tuning.id">
            <li class="alert animated fadeIn d-flex align-items-center justify-content-between px-3 py-1 br-6"
                [ngClass]="latest_tuning.status === 'tuned' ? 'alert-success border-success':'alert-primary border-primary'">
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'running'">
                    {{bondStatus?.bonder?.name}} is being automatically tuned. Network service will be interrupted
                    occasionally and bond settings cannot be changed until this process is complete.
                </span>
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'scheduled' 
                    && !latest_tuning?.schedule_at">
                    {{bondStatus?.bonder?.name}} is scheduled to be automatically tuned once all active legs are online.
                </span>
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'scheduled' 
                && latest_tuning?.schedule_at">
                    {{bondStatus?.bonder?.name}} is scheduled to be automatically tuned at
                    {{latest_tuning?.schedule_at | date: 'dd-MMM, h:mm a'}}.
                </span>
                <span class="text-black fw-bold" *ngIf="latest_tuning.status === 'tuned'">
                    {{bondStatus?.bonder?.name}} has been tuned automatically but the configuration recommendations have
                    not yet been accepted.
                </span>
                <div class="d-flex align-items-center"
                    *ngIf="latest_tuning.status === 'running' || latest_tuning.status==='scheduled'">
                    <button (click)="cancelTuning('')" type="button" class="btn btn-danger btn-style cancel-btn">
                        Cancel Tuning
                    </button>
                </div>
                <div class="d-flex align-items-center" *ngIf="latest_tuning.status === 'tuned'">
                    <button (click)="viewTuning()" type="button" class="btn btn-success btn-style cancel-btn">
                        View Recommendations
                    </button>
                </div>
            </li>
        </ul>
        <ul class="col-md-12 messages list-unstyled"
            *ngIf="latest_tuning?.leg && latest_tuning.status && !latest_tuning?.bond">
            <li class="alert animated fadeIn d-flex align-items-center justify-content-between px-3 py-1 br-6"
                [ngClass]="latest_tuning.status === 'tuned' ? 'alert-success border-success':'alert-primary border-primary'">
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'running'">
                    Leg {{legId}} is being automatically tuned. Bond settings cannot be changed until this process is
                    complete.
                </span>
                <span class="text-black fw-bold" *ngIf="latest_tuning.status === 'tuned'">
                    Leg {{legId}} has been tuned automatically but the configuration recommendations have not yet been
                    accepted.
                </span>
                <div class="d-flex align-items-center" *ngIf="latest_tuning.status === 'running'">
                    <button (click)="cancelTuning(legId)" type="button" class="btn btn-danger btn-style cancel-btn">
                        Cancel Tuning
                    </button>
                </div>
                <div class="d-flex align-items-center" *ngIf="latest_tuning.status === 'tuned'">
                    <button (click)="viewTuning()" type="button" class="btn btn-success btn-style cancel-btn">
                        View Recommendations
                    </button>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="d-flex flex-wrap flex-stack mb-3">
    <div class="fw-bolder my-2 d-flex div-position align-items-center">
        <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
            title="Click to go back">
            <button (click)="onBack()" type="button" class="btn btn-secondary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/angle-left-solid.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Back
            </button>
        </div>
        <h2 class="mb-0 ms-3">{{bondStatus?.bonder?.name}}
            <a class="fs-5 mt-2 ms-1" *ngIf="(!isSharedBond || isPartner) && bondStatus?.space?.private_wan_enabled"
                (click)="navigateToSpace(bondStatus?.space)">
                <span class="mx-2">-</span> <span [inlineSVG]="'./assets/media/icons/duotune/icon/chart-network.svg'"
                    class="svg-icon svg-icon-5 ml-2 me-1 svg-icon-primary"></span>{{bondStatus?.space?.name}}</a>
        </h2>
    </div>

    <div class="d-flex flex-wrap my-2">
        <div class="card-toolbar d-flex align-items-center justify-content-end">
            <button type="button" class="btn btn-style btn-primary" *ngIf="nepeanNexusPermission && addOnPackagesPermission?.view && firewallData 
                && firewallData?.operation == 'installed' && !firewallTunnelData" title="Connect Firewall"
                (click)="connectFirewall()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/firewall.svg'"
                        class="svg-icon svg-icon-block mb-2 svg-clock-white" *ngIf="!firewallTunnelEditLoader">
                    </span>
                    <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'"
                        *ngIf="firewallTunnelEditLoader"></span>
                </div>
                <span> Firewall </span>
            </button>
            <button *ngIf="nepeanNexusPermission && addOnPackagesPermission?.view && firewallData && firewallData?.operation == 'installed' 
                && firewallTunnelData" type="button" class="btn btn-style"
                [title]="firewallTunnelEditLoader ? 'Connecting...' : 'Open Firewall Connection'"
                [ngClass]="firewallTunnelEditLoader ? 'checking-connection border-right-flat' : 'btn-success border-right-flat'"
                (click)="firewallTunnelEditLoader ? '' : openFirewall()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/firewall.svg'"
                        class="svg-icon svg-icon-block mb-2 svg-clock-white">
                    </span>
                </div>
                <span>
                    {{firewallTunnelEditLoader ? 'Connecting' : 'Firewall'}}
                </span>
                <span *ngIf="firewallTunnelEditLoader">
                    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
                </span>
            </button>
            <div *ngIf="nepeanNexusPermission && addOnPackagesPermission?.view && firewallData && firewallData?.operation == 'installed' && firewallTunnelData"
                class="hover-side-menu-block close-connection" title="Close Firewall Connection"
                (click)="updateFirewall()">
                <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" *ngIf="firewallTunnelDeleteLoader"></span>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr011.svg'" class="svg-icon svg-icon-block"
                    *ngIf="!firewallTunnelDeleteLoader">
                </span>
            </div>

            <button *ngIf="nepeanNexusPermission && SecureConnectTunnelPermission?.update" type="button"
                class="btn btn-style ms-2"
                [title]="bondTunnelData ? isTunnelActive ? 'Open Connection': 'Connecting...' : 'Open Connection Not Available'"
                [ngClass]="bondTunnelData ? isTunnelActive ? 'btn-success': 'checking-connection' : 'disable-connection'"
                (click)="bondTunnelData && isTunnelActive ? openConnection(): ''"
                style="border-radius: 6px 0 0 6px !important;">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs039.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div>
                <span>
                    {{bondTunnelData && !isTunnelActive ? 'Connecting' : 'Secure Connect'}}
                </span>
                <span *ngIf="bondTunnelData && !isTunnelActive">
                    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
                </span>
            </button>
            <div *ngIf="nepeanNexusPermission && SecureConnectTunnelPermission?.update && !bondTunnelData"
                class="hover-side-menu-block me-2" title="Create Connection" [ngbPopover]="nodeTunnelPopup"
                [autoClose]="'outside'" (click)="openTunnelPopup()" #nodeTunnelPopupButton="ngbPopover"
                placement="bottom-end">
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr009.svg'" class="svg-icon svg-icon-block">
                </span>
            </div>
            <div *ngIf="nepeanNexusPermission && SecureConnectTunnelPermission?.update && bondTunnelData"
                class="hover-side-menu-block me-2 close-connection" title="Close Connection" (click)="deleteTunnel()">
                <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" *ngIf="nodeTunnelDeleteLoader"></span>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr011.svg'" class="svg-icon svg-icon-block"
                    *ngIf="!nodeTunnelDeleteLoader">
                </span>
            </div>
            <button *ngIf="isIlluminate && isTrafficAnalyticViewData" type="button"
                class="btn btn-primary btn-style me-2" (click)="loginToIlluminate()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/illuminate.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Illuminate
            </button>
            <!-- <button *ngIf="isIlluminate" type="button" class="btn btn-primary btn-style me-2"
                [ngClass]="(lastInstalledLog && lastProvisionedLog) ? '':'disable'"
                [disabled]="(lastInstalledLog && lastProvisionedLog) ? false : true"
                (click)="lastInstalledLog && lastProvisionedLog ? loginToIlluminate(): ''">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/illuminate.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Illuminate
            </button> -->
            <button type="button" class="btn btn-primary btn-style me-2" placement="bottom-end"
                [ngClass]="(latest_tuning && latest_tuning?.status == 'running') ? 'disable-tune':''"
                [ngbPopover]="(latest_tuning && latest_tuning?.status == 'running') ? '': bondAction">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Action
            </button>
            <ng-template #bondAction>
                <ul class="p-0 mb-0">
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="getRestart()"> Restart </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="getDetectLegMTU()"> Detect leg MTUs </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="latest_tuning?.bond ? '' :openTuneSetting()"
                            [ngClass]="latest_tuning?.bond ? 'disable-action':''"> Tune bond settings </span>
                    </li>
                    <li class="menu-item list-style-none"
                        *ngIf="bondPermissionsObj?.clone && !(isSharedBond && !isSdwanSharedBond)">
                        <span class="menu-link px-5" [ngClass]="!isAdminMail ? 'disable-action':''"
                            (click)="isAdminMail ? cloneBond(bondStatus) : ''"> Clone Bond </span>
                    </li>
                    <li class="menu-item list-style-none" *ngIf="bondPermissionsObj?.update">
                        <span class="menu-link px-5" [ngClass]="isNodeProvisionData ? 'disable-action':''"
                            (click)="isNodeProvisionData ? '' :nodeProvisioning()"> Node Provisioning
                        </span>
                    </li>
                    <li class="menu-item list-style-none" *ngIf="alertPermission">
                        <span class="menu-link px-5" (click)="onAlert()"> Configure Alert
                        </span>
                    </li>
                </ul>
            </ng-template>
            <ng-template #nodeTunnelPopup>
                <div class="card">
                    <div class="card-header px-5 py-3 d-flex justify-content-between" style="min-height: 40px;">
                        <h5 class="m-0">
                            {{ bondTunnelData ? 'Edit Connection' : 'Create Connection'}}
                        </h5>
                        <button type="button" class="btn-close" (click)="nodeTunnelPopupButton.close()"></button>
                    </div>
                    <div class="card-body px-5 py-3">
                        <form [formGroup]="nodeTunnelForm">
                            <div class="card mb-5 mb-xl-0">
                                <div class="card-body p-0">
                                    <div class="row" *ngIf="isTunnelError">
                                        <div class="col-md-12">
                                            <div class="alert alert-danger p-2">
                                                Please enter IP & Port
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mb-5">
                                        <label class="col-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                            Protocol
                                        </label>
                                        <div class="col-8 d-flex">
                                            <label
                                                class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                <input type="radio" checked formControlName="protocol" name="protocol"
                                                    id="protocol1" value="https" class="form-check-input">
                                                <span class="form-check-label" for="protocol1">HTTPS</span>
                                            </label>
                                            <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                <input type="radio" formControlName="protocol" name="protocol"
                                                    id="protocol2" value="http" class="form-check-input">
                                                <span class="form-check-label" for="protocol2">HTTP</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mb-5">
                                        <label class="col-4 fw-bolder fs-7 text-black
                                            text-end separator-vertical">
                                            IP</label>
                                        <div class="col-8">
                                            <div class="form-group inputtext-block">
                                                <input formControlName="ip" type="text" name="ip"
                                                    class="form-control form-control-lg form-control-solid"
                                                    autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mb-5">
                                        <label class="col-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                            Port</label>
                                        <div class="col-8">
                                            <div class="form-group inputtext-block">
                                                <input formControlName="port" type="number" name="port"
                                                    class="form-control form-control-lg form-control-solid"
                                                    autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mb-5">
                                        <label class="col-4 fw-bolder fs-7 text-black text-end separator-vertical">
                                            Expiry</label>
                                        <div class="col-8">
                                            <div class="form-group inputtext-block">
                                                <ng-select formControlName="expiry" name="expiry" class="dropdown-block"
                                                    [clearable]="false" [searchable]="false"
                                                    placeholder="Select Expiry Time">
                                                    <ng-option [value]="option.value"
                                                        *ngFor="let option of expiryOptionList;">
                                                        {{option.name}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer px-5 py-3 d-flex justify-content-between align-items-center">
                        <a class="text-primary text-underline" title="Secure Connect Guideline"
                            [ngbPopover]="SecureConnectGuid" [autoClose]="'outside'" placement="bottom-end">How to
                            Use</a>
                        <button type="button" class="btn btn-primary btn-style" (click)="createUpdateTunnel()">
                            <div>
                                <span *ngIf="bondTunnelData && !nodeTunnelEditLoader"
                                    [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                                    class="svg-icon svg-icon-block mb-2"></span>
                                <span *ngIf="!bondTunnelData && !nodeTunnelEditLoader"
                                    [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
                                    class="svg-icon svg-icon-block mb-2"></span>
                                <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="mb-2"
                                    *ngIf="nodeTunnelEditLoader"></span>
                            </div> {{ bondTunnelData ? 'Update':'Create' }}
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template #SecureConnectGuid>
                <div class="card" style="width: 500px;">
                    <div class="card-header px-5 py-3 d-flex justify-content-between" style="min-height: 40px;">
                        <h5 class="m-0">
                            Secure Connect Guideline
                        </h5>
                    </div>
                    <div class="card-body px-5 py-3">
                        <div class="m-0">
                            <div class="d-flex align-items-center py-2 mb-0">
                                <h6 class="text-gray-700 fw-bold cursor-pointer mb-0">
                                    How to Use Secure Connect!
                                </h6>
                            </div>
                            <div class="fs-7 ms-1">
                                <div class="mb-4 text-gray-600 fw-semibold">
                                    Secure Connect employs a reverse tunnel with end-to-end NOISE encryption, as such
                                    the process for connecting to your devices first requires the establishing of the
                                    tunnel from the Node to Antares which is then limited to your current IP at the time
                                    of tunnel establishment. Once open you can click
                                    <span class="text-success">Connect</span> and manage devices as if on
                                    the same network.
                                </div>
                            </div>
                            <div class="separator separator-dashed"></div>
                            <div class="d-flex align-items-center py-2 mb-0">
                                <h6 class="text-gray-700 fw-bold cursor-pointer mb-0">
                                    Connecting Downstream
                                </h6>
                            </div>
                            <div class="fs-7 ms-1">
                                <div class="mb-4 text-gray-600 fw-semibold">
                                    To connect to a Router/Firewall or LAN connected devices (Printer, Wifi AP, Switch)
                                    simply input the IP address for your device and appropriate Web interface port, note
                                    the device will need to allow access on your desired port to function, then click
                                    <span class="text-primary">Create</span>.
                                    <i>allow 30 – 60 seconds for connection</i>
                                </div>
                            </div>
                            <div class="separator separator-dashed"></div>
                            <div class="d-flex align-items-center py-2 mb-0">
                                <h6 class="text-gray-700 fw-bold cursor-pointer mb-0">
                                    Connecting Upstream
                                </h6>
                            </div>
                            <div class="fs-7 ms-1">
                                <div class="mb-4 text-gray-600 fw-semibold">
                                    To connect to a Modem/Router/4G/5G or any WAN connected device first create a
                                    Connected IP in the same subnet and physical interface for the desired WAN
                                    connection. <br>
                                    <div class="notice d-flex bg-light-warning rounded 
                                        border-warning border border-dashed p-2 my-2">
                                        <div class="d-flex flex-stack flex-grow-1 ">
                                            <div class="fw-semibold">
                                                <div class="fs-7 text-gray-700 ">
                                                    Do not use the same IP as the WAN Interface and avoid
                                                    conflicting with any other potential IP’s in that network
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    Note the Router IP which can be obtained from the WAN Leg expanded data
                                    dropdown. Then click Secure Connect input the destination IP address and port
                                    then click <span class="text-primary">Create</span>.
                                    <i>allow 30 – 60 seconds for connection</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="mb-0 mb-xl-0">
    <div class="overflow-auto mt-0 card border-1 border bg-light border-bottom-0">
        <div
            class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0 d-flex justify-content-between">
            <ul class="nav nav-stretch nav-line-tabs border-transparent flex-nowrap fs-6">
                <li class="nav-item">
                    <a [class.active]="selectedTab==1" (click)="onTabChange(1)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span
                            *ngIf="!sharedService?.Loader?.isBondStatusLoader && (status == 'up' || status == 'ssl up')"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-2"></span>
                        <span *ngIf="!sharedService?.Loader?.isBondStatusLoader && status == 'down'"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-2"></span>
                        <span
                            *ngIf="!sharedService?.Loader?.isBondStatusLoader && !(status == 'down' || status == 'up' || status == 'ssl up')"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-2"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondStatusLoader"></span>
                        Status
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==2" (click)="onTabChange(2)"
                        class="nav-link text-active-primary mx-0 px-5"> <span
                            [inlineSVG]="'./assets/media/icons/normal/cog.svg'"
                            class="svg-icon svg-icon-5 me-2 "></span> Details & Optimization
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==3" (click)="onTabChange(3)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'"
                            class="svg-icon svg-icon-5 me-2 "></span>
                        <span class="icon-badge" *ngIf="selectedTab != 3 
                            && latest_tuning?.leg && latest_tuning?.status === 'tuned'">
                            <div class="badge-circle-icon pulsate"></div>
                        </span>
                        Network Configuration
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==4" (click)="onTabChange(4)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/repeat.svg'" class="svg-icon svg-icon-5 me-2 "
                            *ngIf="!sharedService?.Loader?.isBondUpdateLogLoader"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondUpdateLogLoader"></span>
                        Event Logs
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==5" (click)="onTabChange(5)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/chart-line.svg'"
                            class="svg-icon svg-icon-5 me-2 "
                            *ngIf="!sharedService?.Loader?.isBondPerformanceLoader"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondPerformanceLoader"></span>
                        Performance
                    </a>
                </li>
                <li class="nav-item align-items-end">
                    <span class="h-40px border-start border-muted mx-0 border-1"></span>
                </li>
                <li class="nav-item" *ngIf="isIlluminate && isTrafficAnalyticViewData">
                    <a [class.active]="selectedTab==7" (click)="onTabChange(7)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/illuminate.svg'"
                            *ngIf="!(sharedService?.Loader?.isIlluminatePlanListLoader)"
                            class="svg-icon svg-icon-5 me-2 "></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="(sharedService?.Loader?.isIlluminatePlanListLoader)"></span>
                        <!-- <span [inlineSVG]="'./assets/media/icons/normal/exclamation-triangle.svg'"
                            class="svg-icon svg-icon-warning svg-icon-5 me-2 " *ngIf="!sharedService?.Loader?.isIlluminatePlanListLoader && 
                            lastInstalledLog && illuminateMac && lastInstalledLog?.mac != illuminateMac"></span> -->
                        Illuminate
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==6" (click)="onTabChange(6)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/tachometer.svg'"
                            class="svg-icon svg-icon-5 me-2 " *ngIf="!sharedService?.Loader?.isBondStatusLoader"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondStatusLoader"></span>
                        Speed tests
                    </a>
                </li>
                <li class="nav-item align-items-end">
                    <span class="h-40px border-start border-muted mx-0 border-1"></span>
                </li>
                <li class="nav-item" *ngIf="nepeanNexusPermission && addOnPackagesPermission?.view">
                    <a [class.active]="selectedTab==8" (click)="onTabChange(8)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/duotune/coding/cod009.svg'"
                            *ngIf="!(sharedService?.Loader?.isFirewallListLoader)"
                            class="svg-icon svg-icon-5 me-2 "></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="(sharedService?.Loader?.isFirewallListLoader)"></span>
                        Add On
                    </a>
                </li>
                <li class="nav-item align-items-end">
                    <span class="h-40px border-start border-muted mx-0 border-1"></span>
                </li>
                <li class="nav-item" *ngIf="nepeanNexusPermission && toolDiagnosticsPermission?.view">
                    <a [class.active]="selectedTab==9" (click)="onTabChange(9)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 " *ngIf="false"></span>
                        <span [inlineSVG]="'./assets/media/icons/duotune/coding/cod003.svg'"
                            class="svg-icon svg-icon-5 me-2 "></span>
                        Tool & Diagnostics
                    </a>
                </li>
                <li class="nav-item align-items-end"> </li>
            </ul>
            <!-- <div class="px-5">
                <div class="preload" *ngIf="isCachedData">
                    <div class="loader-frame">
                        <div class="loader1"></div>
                        <div class="loader2"></div>
                        <div class="wrapper-wheel">
                            <div class="leftHalf"></div>
                            <div class="rightHalf"></div>
                            <div class="spinner-wheel"></div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div [hidden]="selectedTab != 1">
    <app-partner-bond-view-status *ngIf="bondStatus && bonderData" [bondId]="bondId" [bonderId]="bonderId"
        [alertPermission]="alertPermission" [bondStatus]="bondStatus" [bonderData]="bonderData" [isQuickView]="false"
        [nodeDetails]="allNodeDetails">
    </app-partner-bond-view-status>
</div>

<div *ngIf="selectedTab == 2">
    <app-partner-bond-view-configuration-details *ngIf="bondStatus && bonderData" (getBondStatus)="getBondStatus()"
        (getNodes)="getNodes()" [bondId]="bondId" [bonderId]="bonderId" [bondData]="bondStatus"
        [bonderData]="bonderData" [nodeDetails]="allNodeDetails">
    </app-partner-bond-view-configuration-details>
</div>

<div *ngIf="selectedTab == 3">
    <app-partner-bond-view-network-details *ngIf="bondId && bonderId" [bondId]="bondId" [bonderId]="bonderId"
        [alertPermission]="alertPermission" [bondData]="bondStatus">
    </app-partner-bond-view-network-details>
</div>

<div *ngIf="selectedTab == 4">
    <app-partner-bond-view-updates *ngIf="bondId" [bondId]="bondId" [bondActivityArr]="bondActivityArr"
        [bondActivityLength]="bondActivityLength" [deviceActivityArr]="deviceActivityArr"
        [deviceActivityLength]="deviceActivityLength" [alertActivityArr]="alertActivityArr"
        [alertActivityLength]="alertActivityLength" [alertPermission]="alertPermission"
        [illuminatePlanAccessList]="illuminatePlanAccessList"></app-partner-bond-view-updates>
</div>

<div *ngIf="selectedTab == 5">
    <app-partner-bond-view-performance *ngIf="bondStatus && bonderData" [bondId]="bondId" [bonderId]="bonderId"
        [bondData]="bondStatus" [bonderData]="bonderData">
    </app-partner-bond-view-performance>
</div>

<div *ngIf="selectedTab == 6">
    <app-shared-bonds-view-speed-test *ngIf="bondStatus" [bondId]="bondId" [bonderId]="bonderId"
        [bondData]="bondStatus">
    </app-shared-bonds-view-speed-test>
</div>

<div *ngIf="selectedTab == 7">
    <app-partner-bond-view-illuminate *ngIf="bondStatus" [bondId]="bondId" [bonderId]="bonderId" [bondData]="bondStatus"
        [illuminatePlanAccessList]="illuminatePlanAccessList" [selectedIlluminatePlan]="selectedIlluminatePlan"
        [illuminateMac]="illuminateMac" [isTrafficAnalyticManageAgent]="isTrafficAnalyticManageAgent"
        (getRecentActivity)="getRecentActivity()"></app-partner-bond-view-illuminate>
</div>

<div *ngIf="selectedTab == 8">
    <app-partner-package-manager *ngIf="bondStatus" [bondId]="bondId" [bonderId]="bonderId" [bondData]="bondStatus"
        [illuminateMac]="illuminateMac" [firewallData]="firewallData" [firewallTunnelData]="firewallTunnelData"
        [toolList]="toolList" [addOnPackagesUpdatePermission]="addOnPackagesPermission?.update" [pvsList]="pvsList"
        [UtilitiesData]="UtilitiesData" (getInstallFirewall)="getInstallFirewall()">
    </app-partner-package-manager>
</div>

<div [hidden]="selectedTab != 9">
    <app-partner-rathole *ngIf="bondStatus"></app-partner-rathole>
</div>

<!-- <div *ngIf="selectedTab == 9">
    <app-partner-bond-view-tuning></app-partner-bond-view-tuning>
</div> -->

<!-- <div class="engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2">
    <button _ngcontent-ehy-c57="" id="kt_engage_demos_toggle" title="" data-bs-toggle="tooltip" data-bs-placement="left"
        data-bs-dismiss="click" data-bs-trigger="hover" data-bs-original-title="Check out 20 more demos"
        class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0">
        <span _ngcontent-ehy-c57="" id="kt_engage_demos_label">Quick View</span>
    </button>
</div> -->
<div id="kt_engage_demos" data-kt-drawer="true" data-kt-drawer-name="explore" data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'350px', 'lg': '475px'}" data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_engage_demos_toggle" data-kt-drawer-close="#kt_engage_demos_close"
    class="bg-body drawer drawer-end drawer-on" style="width: 475px !important;">
    <div class="card shadow-none rounded-0 w-100 quick-view">
        <app-partner-bond-view-tuning [bondData]="bondStatus"></app-partner-bond-view-tuning>
        <!-- <app-partner-bond-view-status [alertPermission]="alertPermission" *ngIf="bondStatus && bonderData"
            [bondId]="bondId" [bonderId]="bonderId" [bondStatus]="bondStatus" [bonderData]="bonderData"
            [isQuickView]="true">
        </app-partner-bond-view-status>
        <app-shared-bonds-quick-view></app-shared-bonds-quick-view> -->
    </div>
</div>