<div class="modal-header bg-light px-5 py-3">
    <h5 class="modal-title" id="exampleModalLabel">Select Column</h5>
    <button type="button" (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body px-5">
    <div class="card mb-5 mb-xl-0">
        <div class="card-body pt-0 pb-0">
            <div class="form-group row mb-5">
                <div class="col-md-5">
                    <label class="mb-2"> <b> Available Columns </b></label>
                    <select class="form-control group-select-box br-6" [(ngModel)]="selectedToAdd" multiple>
                        <option *ngFor="let item of availableColumnsArray"
                            style="text-align: left; border-radius: 6px; background: #fff; margin-bottom: 2px; padding: 5px 15px;"
                            [ngValue]="item">{{item.title}}</option>
                    </select>
                </div>
                <div class="col-md-1 text-center d-flex align-items-center justify-content-center flex-column">
                    <button type="button" class="btn btn-primary btn-icon-1 mb-1" (click)="assign()">
                        <div class="ms-1">
                            <i class="fa fa-angle-double-right"></i>
                        </div>
                    </button>
                    <button type="button" class="btn btn-primary btn-icon-1" (click)="unassign()">
                        <div class="ms-1">
                            <i class="fa fa-angle-double-left"></i>
                        </div>
                    </button>
                </div>
                <div class="col-md-5">
                    <label class="mb-2"> <b> Chosen Columns</b> </label>
                    <select class="form-control group-select-box br-6" [(ngModel)]="selectedToRemove" multiple>
                        <option *ngFor="let item of selectedItems"
                            style="text-align: left;border-radius: 6px;background: #fff;margin-bottom: 2px;padding: 5px 15px;"
                            [ngValue]="item">{{item.title}}</option>
                    </select>
                </div>
                <div class="col-md-1 text-center d-flex align-items-center justify-content-center flex-column">
                    <button type="button" class="btn btn-primary btn-icon-1 mb-1" (click)="up()">
                        <div class="ms-1">
                            <i class="fa fa-angle-double-up"></i>
                        </div>
                    </button>
                    <button type="button" class="btn btn-primary btn-icon-1" (click)="down()">
                        <div class="ms-1">
                            <i class="fa fa-angle-double-down"></i>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSaveColumns()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/repeat.svg'" class="svg-icon svg-icon-block mb-2"></span>
        </div> Reload
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>