<!-- End tabbing details section Speed test -->
<div *ngIf="!isView">
    <div class="card border-1 border p-0">
        <div class="card-body p-3">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-6">
                    <div class="card mb-5 mb-xl-5 border border-1 h-100">
                        <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-5 bg-light min-h-45px">
                            <div class="card-title m-0">
                                <h3 class="fw-bolder m-0 fs-4"> Speed Tests </h3>
                            </div>
                        </div>
                        <div class="card-body p-5 pb-0">
                            <div class="row g-5 g-xxl-8">
                                <form class="form form-label-right" [formGroup]="TestAdd">
                                    <div class="form-group row">
                                        <!-- Target start-->
                                        <div class="col-md-6 col-lg-6 col-xxl-6 mb-3">
                                            <div class="form-group inputtext-block mb-5">
                                                <label class="form-label">Target</label>
                                                <ng-select class="dropdown-block" formControlName="target" name="target"
                                                    (change)="targetChange()" *ngIf="!preInterfaceLoaderScreen">
                                                    <ng-option *ngFor="let x of mergeArray" [value]="x.url">
                                                        <span [title]="x.name"> {{x.name}} </span>
                                                    </ng-option>
                                                </ng-select>
                                                <div class="shine w-100 br-6" style="height: 36px;"
                                                    *ngIf="preInterfaceLoaderScreen">
                                                </div>
                                                <small class="form-invalid-message">
                                                    <span class="text-danger"> {{errors?.detail}} </span>
                                                </small>
                                            </div>
                                        </div>
                                        <!-- Target end-->
                                        <!-- Length start-->
                                        <div class="col-md-6 col-lg-6 col-xxl-6 mb-3">
                                            <div class="form-group inputtext-block mb-5">
                                                <label class="form-label">Length
                                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                        placement="top" ngbTooltip="Length of test (seconds)"></span>
                                                </label>
                                                <input type="number" formControlName="length" name="length"
                                                    class="form-control form-control-lg form-control-solid" min="0"
                                                    max="365" placeholder="Enter Regular leg fail time"
                                                    autocomplete="off" />
                                                <small class="form-invalid-message">
                                                    <span class="text-danger"> {{errors?.length}} </span>
                                                </small>
                                            </div>
                                        </div>
                                        <!-- Length End-->
                                        <!-- Rate Limit start-->
                                        <div class="col-md-12 col-lg-12 col-xxl-12 mb-5">
                                            <div class="form-group inputtext-block mb-5">
                                                <label class="form-label">Rate Limit
                                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                        placement="top" ngbTooltip="In Mbps; optionally separate values with commas to run test
                                                    at multiple speeds (i.e. 2.4,2.5,2.6)"></span>
                                                </label>
                                                <input type="text" formControlName="rate_limit" name="rate_limit"
                                                    class="form-control form-control-lg form-control-solid"
                                                    placeholder="Enter Rate Limit" />
                                                <small class="form-invalid-message">
                                                    <span class="text-danger"> {{errors?.rate_limit}} </span>
                                                </small>
                                            </div>
                                        </div>
                                        <!-- Rate Limit End-->
                                        <!-- Protocol start-->
                                        <div class="col-md-6 col-lg-6 col-xxl-6 mb-5">
                                            <label class="form-label">Protocol</label>
                                            <div class="d-flex">
                                                <label
                                                    class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                    <input type="radio" checked formControlName="protocol" id="radio1"
                                                        value="tcp" class="form-check-input">
                                                    <span class="form-check-label">TCP</span></label>
                                                <label
                                                    class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input type="radio" formControlName="protocol" id="radio1"
                                                        value="udp" class="form-check-input">
                                                    <span class="form-check-label">UDP</span></label>
                                            </div>
                                        </div>
                                        <!-- Protocol End-->
                                        <!-- Direction start-->
                                        <div class="col-md-6 col-lg-6 col-xxl-6 mb-5">
                                            <label class="form-label">Direction</label>
                                            <div class="d-flex">
                                                <label
                                                    class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                    <input type="radio" formControlName="direction" id="radio1"
                                                        value="down" class="form-check-input">
                                                    <span class="form-check-label">Download</span></label>
                                                <label
                                                    class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input type="radio" formControlName="direction" id="radio2"
                                                        value="up" defaultchecked="{true}" class="form-check-input">
                                                    <span class="form-check-label">Upload</span></label>
                                            </div>
                                        </div>
                                        <!-- Direction End-->
                                        <!-- Concurrency start-->
                                        <div class="col-md-6 col-lg-6 col-xxl-6 mb-5">
                                            <div class="form-group inputtext-block mb-5">
                                                <label class="form-label">Concurrency
                                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                        placement="top"
                                                        ngbTooltip="Number of TCP sessions to use"></span>
                                                </label>
                                                <input type="number" formControlName="concurrency" name="concurrency"
                                                    class="form-control form-control-lg form-control-solid" min="0"
                                                    max="365" placeholder="Enter Regular leg fail time"
                                                    autocomplete="off" />
                                                <small class="form-invalid-message">
                                                    <span class="text-danger"> {{errors?.concurrency}} </span>
                                                </small>
                                            </div>
                                        </div>
                                        <!-- Concurrency End-->
                                        <!-- Rate limit start-->
                                        <div class="col-md-6 col-lg-6 col-xxl-6 mb-5">
                                            <div class="form-group inputtext-block mb-5">
                                                <label class="form-label">Payload size
                                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                        placement="top"
                                                        ngbTooltip="Size of UDP test packets, excluding headers (bytes)"></span>
                                                </label>
                                                <input type="text" formControlName="payload_size" name="payload_size"
                                                    class="form-control form-control-lg form-control-solid"
                                                    placeholder="Payload size" autocomplete="off" />
                                                <small class="form-invalid-message">
                                                    <span class="text-danger"> {{errors?.payload_size}} </span>
                                                </small>
                                            </div>
                                        </div>
                                        <!-- Rate limit End-->
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card-footer p-5 pt-0 border-0">
                            <button type="button" class="btn btn-primary btn-style"
                                (click)="preInterfaceLoaderScreen ? '' : onSave('')">
                                <div>
                                    <span [inlineSVG]="'./assets/media/icons/duotune/medicine/med005.svg'"
                                        class="svg-icon svg-icon-block mb-2"></span>
                                </div>
                                Test
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-6">
                    <div class="card mb-5 mb-xl-5 border border-1 h-100">
                        <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-5 bg-light min-h-45px">
                            <div class="card-title m-0">
                                <h3 class="fw-bolder m-0 fs-4"> Preset Tests </h3>
                            </div>
                        </div>
                        <div class="card-body p-3">
                            <ul class="fs-7">
                                <li> <a class="fs-7 cursor-pointer" (click)="onSave('btd')"> Bonded TCP download </a>
                                </li>
                                <li> <a class="fs-7 cursor-pointer" (click)="onSave('btd8')"> Bonded TCP download
                                        (8-concurrent) </a> </li>
                                <li> <a class="fs-7 cursor-pointer" (click)="onSave('btu')"> Bonded TCP upload </a>
                                </li>
                                <li> <a class="fs-7 cursor-pointer" (click)="onSave('bpl')"> Bonded packet loss </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End tabbing details section Speed test -->
    <div class="card border-1 border p-0 mt-5">
        <div class="card-body p-2">
            <!-- <div class="d-flex flex-wrap mb-2 justify-content-end">
            <div class="d-flex flex-wrap mb-2">
                <div class="me-4">
                    <select name="status" data-hide-search="true" class="form-select form-select-sm"
                        defaultValue="Active">
                        <option value='all' selected>Table Option</option>
                        <option value="" value='true'>Show 25 Record</option>
                        <option value="Show 25 Record" value='true'>Show 50 Record</option>
                        <option value="Show 75 Record" value='true'>Show 75 Record</option>
                    </select>
                </div>
                <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
                    data-bs-placement="top" data-bs-trigger="hover" title="Click to  Compare Test">
                    <a class="btn btn-sm btn-primary"> Compare Test </a>
                </div>
            </div>
        </div> -->
            <div class="table-responsive border">
                <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                    <thead class="table-light">
                        <tr class="fw-bolder text-dark fs-7">
                            <!-- <th class="ps-4 fs-7 py-4">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox">
                            </div>
                        </th> -->
                            <th class="py-4"> ID </th>
                            <th class="py-4">Target </th>
                            <th class="py-4">Description </th>
                            <th class="py-4 min-w-150px">Submitted </th>
                            <th class="py-4">Results </th>
                            <th class="py-4 min-w-50px w-50px text-center">Actions </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="allSpeedTest && allSpeedTest.length>0">
                        <tr *ngFor="let SpeedTest of allSpeedTest;">
                            <!-- <td class="ps-4">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" name="list_name" value="" />
                            </div>
                        </td> -->
                            <td (click)="navigateToView(SpeedTest.id)" class="hyper-link"><u> {{ SpeedTest.id }}</u>
                            </td>
                            <td> {{ SpeedTest.target }} </td>
                            <td> <span> {{ SpeedTest.protocol | uppercase}} </span> <span
                                    *ngIf="SpeedTest.direction == 'down' ">Download test, </span>
                                <span *ngIf="SpeedTest.direction == 'up' ">Upload test, </span> <span
                                    *ngIf="SpeedTest.rate_limit !== null">rate limit {{ SpeedTest.rate_limit}}, </span>
                                <span *ngIf="SpeedTest.rate_limit == null">no rate limit, {{
                                    SpeedTest.rate_limit}}</span>
                                <span>{{ SpeedTest.length}} seconds,
                                </span> <span> concurrency {{ SpeedTest.concurrency}} </span>
                            </td>
                            <td> {{ SpeedTest.submitted_time | date: "yyyy-MM-dd hh:mm:ss" }} </td>
                            <td>
                                <span *ngIf="!SpeedTest?.error_message">
                                    <span *ngIf="SpeedTest && SpeedTest.avg_latency">
                                        {{ SpeedTest.avg_throughput}} Mbps,
                                        {{SpeedTest.avg_latency}}, {{SpeedTest.avg_pkt_loss}} %
                                    </span>
                                    <span *ngIf="SpeedTest && !SpeedTest.avg_latency">
                                        Results not yet received.
                                    </span>
                                </span>
                                <span *ngIf="SpeedTest?.error_message">
                                    {{SpeedTest.error_message}}
                                </span>
                            </td>
                            <td class="text-center">
                                <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                        class="svg svg-icon svg-transparent">
                                    </span>
                                </a>
                                <mat-menu #actionMenu="matMenu">
                                    <button mat-menu-item class="menu-list-block" title="View"
                                        (click)="navigateToView(SpeedTest.id)">
                                        <div class="clone-icon">
                                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/eye.svg'"
                                                class="svg svg-icon svg-icon-primary">
                                            </span>
                                        </div>
                                        <span>View</span>
                                    </button>
                                    <button mat-menu-item class="menu-list-block" (click)="onRepeat(SpeedTest)"
                                        title="Repeat">
                                        <div>
                                            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr029.svg'"
                                                class="svg svg-icon svg-icon-info">
                                            </span>
                                        </div>
                                        <span>Repeat</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <app-preloader-table-list *ngIf="isBondSpeedTestListLoader" [length]="2"></app-preloader-table-list>
                <div *ngIf="!isBondSpeedTestListLoader && allSpeedTest && allSpeedTest.length <= 0"
                    class="text-center font16 mt15 mb15 pt-4">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder  fs-3 mb-1">Sorry! No speed tests to show.</span>
                    </h3>
                </div>
            </div>
            <app-pagination *ngIf="!isBondSpeedTestListLoader && allSpeedTest && allSpeedTest.length > 0"
                [childMessage]="testLength" [index]="page" [pagesize]="limit" (messageEvent)="receiveMessage($event)">
            </app-pagination>
        </div>
    </div>
</div>

<app-shared-speed-test-view *ngIf="isView && speedTestId" [bondId]="bondId" [bonderId]="bonderId"
    [speedTestId]="speedTestId" (onRepeat)="onRepeat($event)" (navigateToSpeedTest)="navigateToSpeedTest()">
</app-shared-speed-test-view>