import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  NgbDropdownModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { AdminPermissionGuard } from 'src/app/modules/shared/auth/services/admin-permission.guard';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { TranslationModule } from '../../modules/shared/i18n';
import { SharedModule } from '../../shared/modules/shared.module';
import { AsideMenuComponent } from './components/aside/aside-menu/aside-menu.component';
import { AsideComponent } from './components/aside/aside.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header/header-menu/header-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { PageTitleComponent } from './components/header/page-title/page-title.component';
import { ScriptsInitComponent } from './components/scripts-init/scripts-init.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { LayoutComponent } from './layout.component';
import { Error404Component } from 'src/app/modules/shared/errors/error404/error404.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full'
      },
      {
        path: 'partner',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('../../modules/partner/partner.module').then((m) => m.PartnerModule),
      },
      {
        path: 'admin',
        data: { breadcrumb: { skip: true } },
        canActivate: [AdminPermissionGuard],
        loadChildren: () => import('../../modules/super-admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: '**',
        component: Error404Component,
        data: { breadcrumb: { skip: true } }
      }
    ],
  },
];

@NgModule({
  declarations: [
    LayoutComponent,
    AsideComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    ScriptsInitComponent,
    ToolbarComponent,
    AsideMenuComponent,
    TopbarComponent,
    PageTitleComponent,
    HeaderMenuComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    TranslationModule,
    InlineSVGModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbTooltipModule,
    TranslateModule,
    BreadcrumbModule,
    NgSelectModule,
    SharedModule
  ],
  exports: [RouterModule],
  providers: [AdminPermissionGuard]
})
export class LayoutModule { }
