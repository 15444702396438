import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmailTemplateModel } from '../models/email-template.model';
import { SystemConfigModel } from '../models/system-configuration.model';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/alertNotification/';
@Injectable({ providedIn: 'root' })
export class AlertService {
    activeAlertLegList: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    alertGroupData: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(private http: HttpClient, private storageService: StorageService) { }

    get activeAlertLegList$() {
        return this.activeAlertLegList.asObservable();
    }

    get alertGroupData$() {
        return this.alertGroupData.asObservable();
    }

    sendEmail(id: any, body: any) {
        return this.http.put(BACKEND_URL + `emailBranding/send/${id}`, body);
    }

    // Fetch Brand Data
    getBrandSettingData(url: any) {
        return this.http.get<SystemConfigModel>(environment.apiUrl + url);
    }

    // Create Brand 
    createBrandSetting(url: any, data: any) {
        return this.http.post<SystemConfigModel>(environment.apiUrl + url, data);
    }

    // update brand
    updateBrandSetting(url: any, data: any, id: any) {
        return this.http.put<SystemConfigModel>(`${environment.apiUrl}${url}${id}`, data);
    }

    // Fetch Email Template Data
    getEmailTemplateData() {
        return this.http.get<EmailTemplateModel>(BACKEND_URL + 'emailBranding/view');
    }

    // Create staff 
    createEmailTemplate(data: any) {
        return this.http.post<EmailTemplateModel>(BACKEND_URL + 'emailBranding/add', data);
    }

    // update Partner
    updateEmailTemplate(data: any, id: any) {
        return this.http.put<EmailTemplateModel>(BACKEND_URL + `emailBranding/update/${id}`, data);
    }

    getEmailTemplateById(id: any) {
        if (!id) return;
        return this.http.get<any>(BACKEND_URL + `emailBranding/view/${id}`);
    }

    getAllAlertListing() {
        let userId = this.storageService.getCipherObj('ID');
        let mainId = this.storageService.getCipherObj('mainId');
        if (!userId || !mainId) return;
        return this.http.post<any>(BACKEND_URL + `listAlert/${userId}/${mainId}`, {});
    }

    updateAlert(data: any, notificationId: any) {
        let mainId = this.storageService.getCipherObj('mainId');
        if (!mainId) return;
        return this.http.patch<any>(BACKEND_URL + `updateAlert/${notificationId}/${mainId}`, data);
    }

    addAlert(data: any) {
        let userId = this.storageService.getCipherObj('ID');
        let mainId = this.storageService.getCipherObj('mainId');
        if (!userId || !mainId) return;
        return this.http.post<any>(BACKEND_URL + `addAlert/${userId}/${mainId}`, data);
    }

    deleteAlert(notificationId: any) {
        let mainId = this.storageService.getCipherObj('mainId');
        if (!mainId) return;
        return this.http.delete(BACKEND_URL + `deleteAlert/${notificationId}/${mainId}`);
    }

    addMonitoring(bondId: any, legId: any, state: string) {
        let partnerId = this.storageService.getCipherObj('mainId');
        if (!partnerId) return;
        let params: any = { legId: String(legId), bondId: bondId, state: state };
        if (!this.storageService.getCipherObj('ID')) return;
        else params = { ...params, userId: this.storageService.getCipherObj('ID') };
        return this.http.post<any>(`${BACKEND_URL}addMonitor/${partnerId}`, params);
    }

    addMultiMonitoring(bondList: any, alertGroupList: any) {
        let partnerId = this.storageService.getCipherObj('mainId');
        if (!partnerId) return;
        let params: any = { bondList: bondList, alertGroupList: alertGroupList }
        if (!this.storageService.getCipherObj('ID')) return;
        else params = { ...params, userId: this.storageService.getCipherObj('ID') };
        return this.http.post<any>(`${BACKEND_URL}addMultiMonitor/${partnerId}`, params);
    }

    removeMonitor(bondId: any, legId: any) {
        let partnerId = this.storageService.getCipherObj('mainId');
        if (!partnerId) return;
        let params: any = { legId: String(legId), bondId: bondId }
        if (!this.storageService.getCipherObj('ID')) return;
        else params = { ...params, userId: this.storageService.getCipherObj('ID') };
        return this.http.post<any>(`${BACKEND_URL}removeMonitor/${partnerId}`, params);
    }

    activeLegList(bondId: any) {
        let partnerId = this.storageService.getCipherObj('mainId');
        if (!partnerId) return;
        let params: any = { bondId: bondId }
        if (!this.storageService.getCipherObj('ID')) return;
        else params = { ...params, userId: this.storageService.getCipherObj('ID') };
        return this.http.post<any>(`${BACKEND_URL}activeLegList/${partnerId}`, params);
    }

    getAlertHistory(bondId: any) {
        let userId = this.storageService.getCipherObj('ID');
        let partnerId = this.storageService.getCipherObj('mainId');
        if (!userId || !partnerId) return;
        let params = { bondId: bondId, userId: userId }
        return this.http.post<any>(`${BACKEND_URL}getAlertHistory/${partnerId}`, params);
    }
}
