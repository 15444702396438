<!-- begin::Header -->

<div class="d-flex flex-wrap flex-stack mb-3">
    <div class="fw-bolder my-2 d-flex div-position">
        <input type="text" class="form-control" [formControl]="searchControl"
         name="searchText" placeholder="Search"
            (keydown)="preventSpace($event)" value="" />
        <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
            class="svg-icon svg-icon-1 me-0 span-input"></span>
    </div>


</div>


<div class="card mb-5 mb-xl-8">
    <div class="card-body p-5">
        <!-- begin::Table container -->
        <div class="table-responsive">
            <!-- begin::Table -->
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >
                <!-- begin::Table head -->
                <thead>
                    <tr class="fw-bolder text-muted">
                        <th (click)="onSort('ipAddress')" class="min-w-125px">
                            IP Address
                            <span *ngIf="sortBy == 'ipAddress'"
                                [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th (click)="onSort('operation')" class="min-w-150px">
                            Operation
                            <span *ngIf="sortBy == 'operation'"
                                [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th (click)="onSort('priority')" class="min-w-125px">
                            Priority
                            <span *ngIf="sortBy == 'priority'"
                                [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th (click)="onSort('type')" class="min-w-100px">
                            Type
                            <span *ngIf="sortBy == 'type'"
                                [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>

                        <th (click)="onSort('createdAt')" class="min-w-200px">
                            Created At
                            <span *ngIf="sortBy == 'createdAt'"
                                [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="min-w-50px">Actions</th>
                    </tr>
                </thead>
                <!-- end::Table head -->
                <!-- begin::Table body -->
                <tbody *ngIf="length > 0">
                    <tr *ngFor="let log of logs; let i = index">
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="d-flex justify-content-start flex-column">
                                    <a class="text-dark fw-bolder fs-6">
                                        {{log.ipAddress}}
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td >
                            <a class="text-dark word-wrap fw-bolder d-block fs-6">
                                {{log.operation}}
                            </a>
                        </td>
                        <td>
                            <a class="text-dark fw-bolder d-block fs-6">
                                {{log.priority}}
                            </a>
                        </td>
                            <td>
                                <a class="text-dark fw-bolder d-block fs-6">
                                    {{log.type}}
                                </a>
                            </td>

                        <td>
                            <a class="text-dark fw-bolder d-block fs-6">
                                {{log.createdAt | dateFormatPipe}}
                            </a>
                        </td>


                        <td>


                            <a title="Delete" *ngIf="log.isDeleted == false"
                                (click)="onDeleteLogs(log._id)"
                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                    class="svg-icon svg-icon-3">
                                </span>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <!-- end::Table body -->
            </table>
            <div *ngIf="logs.length == 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>

                <!-- <hr> -->
            </div>
            <!-- end::Table -->
        </div>
        <!-- end: Table -->
        <app-pagination *ngIf="length > 0" [childMessage]="length" [index]="index"
            (messageEvent)="receiveMessage($event)">
        </app-pagination>
        <!-- end::Table container -->
    </div>
</div>
