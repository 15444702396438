<div *ngIf="permissionsObj.view" class="card mb-5 mb-xl-10">
    <div class="card-body p-5">
        <div class="row g-5 g-xxl-8">
            <form class="form form-label-right" [formGroup]="formMailConfig" *ngIf="formMailConfig">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <label class="form-label">Driver <span class="required-asterisk m-0">*</span>
                            <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                ngbTooltip="Mail drivers such as SMTP and sendmail, allowing to sending mail through a local or
                                cloud based service of your choice."></span>
                        </label>
                        <div>
                            <ng-select class="dropdown-block" (change)="onDropdownChange($event)"
                                [class.is-invalid]="isControlInvalid('driver')"
                                [class.is-valid]="isControlValid('driver')" [searchable]="false" [clearable]="false"
                                labelForId="heroId" formControlName="driver">
                                <ng-option *ngFor="let driver of driverArray" [value]="driver.value">
                                    {{driver.label}}
                                </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'driver')">
                                    Driver is required
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Host <span class="required-asterisk m-0">*</span>
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The host name of outgoing SMTP (eg:smtp.gmail.com)."></span>
                            </label>
                            <input type="text" class="form-control form-control-lg form-control-solid" name="host"
                                placeholder="Enter Host Address" autocomplete="off"
                                [class.is-invalid]="isControlInvalid('host')" [class.is-valid]="isControlValid('host')"
                                formControlName="host" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'host')">
                                    Host is required
                                </span>
                            </small>
                        </div>
                    </div>
                    <div [class.disable]="!isSMTPSelected" class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Port <span class="required-asterisk m-0">*</span>
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="The default port number of an outgoing SMTP server. Typically Common SMTP ports:
                                    SMTP - port 25 or 2525 or 587, Secure SMTP (SSL / TLS) - port 465 or 25 or 587, 2526
                                    (Elastic Email)."></span>
                            </label>
                            <input type="number" class="form-control form-control-lg form-control-solid pe-5"
                                name="port" min="0" max="10000" placeholder="Enter Port number" autocomplete="off"
                                [class.is-invalid]="isControlInvalid('port')" [class.is-valid]="isControlValid('port')"
                                formControlName="port" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'port')">
                                    Port is required
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('min', 'port')">
                                    Port should be greater than 0
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('max', 'port')">
                                    Port should be less than 10000
                                </span>
                            </small>
                        </div>
                    </div>
                    <div [class.disable]="!isSMTPSelected" class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Email</label>&nbsp;<span class="required-asterisk m-0">*</span>
                            <input type="text" class="form-control form-control-lg form-control-solid" name="email"
                                placeholder="Enter Email" autocomplete="off"
                                [class.is-invalid]="isControlInvalid('email')"
                                [class.is-valid]="isControlValid('email')" formControlName="email" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'email')">
                                    Email is required
                                </span>
                                <span class="text-danger"
                                    *ngIf="!controlHasError('required', 'email') && controlHasError('pattern', 'email')">
                                    Email is Invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <div [class.disable]="!isSMTPSelected" *ngIf="formMailConfig?.controls?.isSAP?.value"
                        class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Username
                                <span class="required-asterisk m-0">*</span>
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="SMTP server username credential."></span>
                            </label>
                            <input type="text" class="form-control form-control-lg form-control-solid" name="username"
                                placeholder="Enter Username number" autocomplete="off"
                                [class.is-invalid]="isControlInvalid('username')"
                                [class.is-valid]="isControlValid('username')" formControlName="username" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'username')">
                                    Username is required
                                </span>
                                <span class="text-danger"
                                    *ngIf="!controlHasError('required', 'username') && controlHasError('pattern', 'username')">
                                    User Name is Invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4 mb-5" *ngIf="formMailConfig?.controls?.isSAP?.value"
                        [class.disable]="!isSMTPSelected">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Password<span class="required-asterisk m-0">*</span>
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="SMTP server password credential."></span>
                            </label>
                            <div class="password-input-block">
                                <input class="form-control form-control-lg form-control-solid" autocomplete="off"
                                    [type]="show_button ? 'text' : 'password'" placeholder="Password"
                                    formControlName="password" [class.is-invalid]="isControlInvalid('password')" />
                                <i [class]="show_eye ? 'fa fa-eye password_hide_show' : 'fa fa-eye-slash password_hide_show'"
                                    (click)="showPassword()"></i>
                            </div>
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'password')">
                                    Password is required
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('minlength', 'password')">
                                    Password should have at least 6 character
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'password')">
                                    Password Number should have maximum 20 character
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12" [class.disable]="!isSMTPSelected ">
                        <div class="d-flex align-items-center mt-1">
                            <div class="switch-width">
                                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="secure">
                                </ui-switch>
                            </div>
                            <label class="form-label align-items-center ms-5 mb-0">Secure</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12" [class.disable]="!isSMTPSelected ">
                        <div class="d-flex mb-5 align-items-center mt-3">
                            <div class="switch-width">
                                <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="isSAP">
                                </ui-switch>
                            </div>
                            <label class="form-label align-items-center ms-5 mb-0">
                                Secure Password Authentication (SPA)
                            </label>
                        </div>
                    </div>
                </div>
                <div style="border-top:1px solid #eff2f5">
                    <h3 class="mt-5"> Administration Mail
                        <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                            ngbTooltip="Listed address will receive bond clone notifications, leave blank to disable bond clone functionality.">
                        </span>
                    </h3>
                    <div class="col-md-6 col-lg-6 col-xl-4 mb-5">
                        <div class="form-group inputtext-block mb-5">
                            <input type="text" class="form-control form-control-lg form-control-solid"
                                name="administrationEmail" placeholder="Enter Email" autocomplete="off"
                                [class.is-invalid]="isControlInvalid('administrationEmail')"
                                [class.is-valid]="isControlValid('administrationEmail')"
                                formControlName="administrationEmail" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('pattern', 'administrationEmail')">
                                    Email is Invalid
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card-footer px-5 py-3">
        <button *ngIf="permissionsObj.update" type="button" class="btn btn-primary btn-style" (click)="onSubmit()">
            <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                    class="svg-icon svg-icon-block mb-2"></span>
            </div> Save
        </button>
        <button *ngIf="permissionsObj.update" type="button" class="btn btn-primary btn-style" (click)="onTestSettings()"
            data-bs-toggle="modal" data-bs-target="#testMail">
            <div>
                <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-block mb-2"></span>
            </div> Test Settings
        </button>
    </div>
</div>

<div *ngIf="!permissionsObj?.view">
    You have no permission to view the data. Kindly contact the authority.
</div>

<div class="modal fade" id="testMail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header px-5 py-3">
                <h5 class="modal-title" id="exampleModalLabel">Test Email Configuration</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">
                <form>
                    <div class="form-group mb-5">
                        <div class="form-group inputtext-block mb-5">
                            <input type="email" name="receiverid" [(ngModel)]="receiverid" class="form-control"
                                id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email Address">
                            <small id="emailHelp" class="form-text text-muted">We'll never share or save your email with
                                anyone else.</small>
                        </div>
                    </div>
                    <div class="form-group mb-5">
                        <textarea name="receiverid" placeholder="Email Subject" [(ngModel)]="subject"
                            class="form-control br-6" id="exampleFormControlTextarea1" rows="2"></textarea>
                    </div>
                    <div class="form-group mb-5">
                        <textarea name="receiverid" [(ngModel)]="bodyText" placeholder="Email Body"
                            class="form-control topSpace min-h-150px br-6" id="exampleFormControlTextarea2"
                            rows="5"></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer px-5 py-3">
                <button type="button" class="btn btn-primary btn-style" (click)="sendTestEmail()"
                    data-bs-dismiss="modal">
                    <div>
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen016.svg'"
                            class="svg-icon svg-icon-block mb-2"></span>
                    </div>Send Email
                </button>
                <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal">
                    <div>
                        <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                            class="svg-icon svg-icon-block mb-2"></span>
                    </div> Close
                </button>
            </div>
        </div>
    </div>
</div>