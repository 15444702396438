import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PartnerMailConfigService } from 'src/app/services/partner-mail-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from '../../../constants/app.constant';
import { FormErrorService } from '../../../services/fromError.service';
import { SharedService } from '../../../services/shared.service';
@Component({
  selector: 'app-shared-mail-config',
  templateUrl: './shared-mail-config.component.html',
  styleUrls: ['./shared-mail-config.component.scss']
})
export class SharedMailConfigComponent implements OnInit {
  formMailConfig: FormGroup;
  testSettingsForm: FormGroup;
  driverArray = AppConst.driverList;
  encryptionArray = AppConst.encryptionList;

  show_button: Boolean = false;
  show_eye: Boolean = false;
  isSMTPSelected: boolean = true;

  emailValidator: any = AppConst.emailVal;
  isTestSettingsVisible: boolean = false;
  receiverid: any;
  subject: any;
  bodyText: any;
  permissionsObj: any;
  @Input() driver: any[];
  @Input() mailConfig: any;
  @Input() mailConfigId: any;

  @ViewChild('testMail') myModal: any

  constructor(
    private _fb: FormBuilder,
    private formErrorHandler: FormErrorService,
    private shared: SharedService,
    private generalService: PartnerMailConfigService,
    private cd: ChangeDetectorRef,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    if (this.mailConfig) {
      this.formMailConfig = this.initForm(this.mailConfig);
    } else {
      this.formMailConfig = this.initForm();
    }
    this.formMailConfig.controls.isSAP.valueChanges.subscribe((val) => {
      if (val) {
        this.formMailConfig.controls.username.setValidators([Validators.pattern(this.emailValidator), Validators.required]);
        this.formMailConfig.controls.username.updateValueAndValidity();
        this.formMailConfig.controls.password.setValidators([Validators.required]);
        this.formMailConfig.controls.password.updateValueAndValidity();
        this.formMailConfig.markAsPristine();
      } else {
        this.formMailConfig.controls.username.clearValidators();
        this.formMailConfig.controls.username.updateValueAndValidity();
        this.formMailConfig.controls.username.setValue('');
        this.formMailConfig.controls.username.setErrors(null);
        this.formMailConfig.controls.password.clearValidators();
        this.formMailConfig.controls.password.updateValueAndValidity();
        this.formMailConfig.controls.password.setValue('');
        this.formMailConfig.controls.password.setErrors(null);
      }
    })
  }

  onCreate(data: any) {
    this.shared.showLoader();
    if (this.mailConfigId) {
      this.generalService.updateMail(data.mailConfig, this.mailConfigId).subscribe((res: any) => {
        if (res.code == 200) this.shared.loggerSuccess(res.message);
        this.shared.hideLoader();
      }, (err: any) => {
        this.shared.hideLoader();
        this.shared.loggerError(err);
      })
    } else {
      this.generalService.createMail(data.mailConfig).subscribe((res: any) => {
        if (res.code == 200 && res.result) {
          this.shared.loggerSuccess(res.message);
          if (res?.result?._id) this.mailConfigId = res.result._id;
          this.mailConfig = res.result;
        }
        this.shared.hideLoader();
        this.cd.detectChanges();
      }, (err: any) => {
        this.shared.hideLoader();
        this.shared.loggerError(err);
      })
    }

  }

  onSendMail(data: any) {
    this.shared.showLoader();
    this.generalService.sendTestMail(data.data).subscribe((res: any) => {
      if (res.code == 200) {
        this.shared.loggerSuccess(res.message);
        this.testSettingsForm?.reset();
      }
      this.shared.hideLoader();
      this.cd.detectChanges();
    }, (err: any) => {
      this.shared.hideLoader();
      this.shared.loggerError(err);
    })
  }

  getPermission() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.admin || Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.permissionsObj = { view: true, update: true, delete: true }
    } else {
      this.shared.getPermission();
      if (this.shared.noPermission) this.permissionsObj = { view: true, update: true, delete: true }
      else {
        this.permissionsObj = this.shared.generalConfiguration;
        if (!this.permissionsObj) this.permissionsObj = { view: true, update: true, delete: true }
      }
    }
    this.cd.detectChanges();
  }

  initForm(data: any = {}) {
    return this._fb.group({
      driver: new FormControl(data.driver || '', Validators.required),
      host: new FormControl(data.host || '', Validators.required),
      port: new FormControl(data.port || '', Validators.compose(
        [
          Validators.required,
          Validators.min(0),
          Validators.max(65537)
        ]
      )),
      email: new FormControl(data.email || '', Validators.compose(
        [
          Validators.pattern(this.emailValidator),
          Validators.required
        ]
      )),
      username: new FormControl(data.username || '', Validators.compose(
        data.username ? [Validators.pattern(this.emailValidator), Validators.required] : []
      )),
      password: new FormControl(data.password || '', Validators.compose(
        data.password ? [Validators.required] : []
      )),
      secure: new FormControl(data.secure ? true : false, Validators.required),
      isSAP: new FormControl(data.isSAP ? true : false, Validators.required),
      administrationEmail: [data.administrationEmail || '', Validators.pattern(this.emailValidator)],
    });
  }

  onTestSettings() {
    if (this.formMailConfig.value.driver == 'SMTP') {
      if (this.formMailConfig.invalid) {
        this.formMailConfig.markAllAsTouched();
        return;
      }
    }
    if (this.formMailConfig.value.driver == 'Send Mail') {
      this.formMailConfig.setValidators(null);
      this.formMailConfig.updateValueAndValidity();
    }
    this.isTestSettingsVisible = true;
  }

  initTestSettingsForm() {
    return this._fb.group({
      receiverid: ['', [Validators.required, Validators.pattern(this.emailValidator)]],
      subject: ['', Validators.required],
      bodyText: ['', Validators.required]
    })
  }

  sendTestEmail() {
    if (!this.receiverid) return;
    let obj = {
      ...this.formMailConfig.value, receiverid: this.receiverid, subject: this.subject,
      bodyText: this.bodyText
    }
    this.onSendMail({ data: obj })
    // this.shared.showLoader();
    // this.generalService.sendTestMail(obj).subscribe(
    //   (res:any)=>{
    //     this.shared.loggerSuccess(res.message);
    //     this.shared.hideLoader();
    //     this.isTestSettingsVisible=false;
    //     this.receiverid='';
    //     this.subject='';
    //     this.bodyText='';
    //   },
    //   (err)=>{
    //     this.shared.hideLoader();
    //     this.shared.loggerError(err.message)
    //   }
    // );
  }

  resetEmailForm() {
    this.isTestSettingsVisible = false;
    this.formMailConfig.enable();
  }

  getMailData() {
    this.generalService.getMailViewData().subscribe((res: any) => {
      if (res.code === 200) {
        this.shared.hideLoader();
        if (res?.data?._id) {
          this.mailConfigId = res.data._id;
        }
        this.mailConfig = res.data;
        this.setMailData();
      }
    }, (err: any) => {
      this.shared.hideLoader();
      if (err.error.code === 400) {
        this.mailConfigId = false;
      }
    }
    )
  }


  setMailData() {
    this.formMailConfig.patchValue(this.mailConfig);
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  onSubmit() {
    // console.log(this.formMailConfig);

    if (this.formMailConfig.invalid) {
      this.formMailConfig.markAllAsTouched();
      return
    }
    // loader
    this.mailConfig = {
      driver: this.formMailConfig.controls.driver.value,
      email: this.formMailConfig.controls.email.value,
      username: this.formMailConfig.controls.username.value,
      host: this.formMailConfig.controls.host.value,
      port: this.formMailConfig.controls.port.value,
      //  encryption: this.formMailConfig.controls.encryption.value,
      // sendEmailCommand: this.formMailConfig.controls.sendEmailCommand.value,
      secure: this.formMailConfig.controls.secure.value,
      isSAP: this.formMailConfig.controls.isSAP.value,
      password: this.formMailConfig.controls.password.value,
      administrationEmail: this.formMailConfig.controls.administrationEmail.value,
    }
    this.onCreate({ mailConfig: this.mailConfig });
    // console.log(this.mailConfigId);

    // if (!this.mailConfigId) {
    //  // this.mailConfig.email = this.formMailConfig.controls.email.value;
    //  // this.mailConfig.password = this.formMailConfig.controls.password.value;
    //   this.create.emit({mailConfig:this.mailConfig});
    // } else {
    //   this.update.emit(this.mailConfig, this.mailConfigId);
    // }
  }

  createMailConfig(securityData: any) {

    this.generalService.createMail(securityData).subscribe((res: any) => {
      if (res.code == 200) {
        this.shared.hideLoader();
        this.shared.loggerSuccess(res.message);
      }
    }, (err: any) => {
      this.shared.hideLoader();
      this.shared.loggerError(err);
    })
  }

  updateMailConfig(securityData: any, id: any) {
    this.generalService.updateMail(securityData, id).subscribe((res: any) => {
      if (res.code == 200) {
        this.shared.hideLoader();
        this.shared.loggerSuccess(res.message);
      }
    }, (err: any) => {
      this.shared.hideLoader();
      this.shared.loggerError(err);
    })
  }

  onDropdownChange(value: any) {
    if (value == 'SMTP') {
      this.isSMTPSelected = true;
    }
    else {
      this.isSMTPSelected = false;
    }
  }



  resetForm() {
    this.formMailConfig.reset();
    this.setMailData();
  }

  isControlValid(controlName: string,): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.formMailConfig);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.formMailConfig);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.formMailConfig);
  }

  isControlTouched(controlName: string): boolean {
    return this.formErrorHandler.isControlTouched(controlName, this.formMailConfig);
  }
}
