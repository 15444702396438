<div class="row">
  <div *ngFor="let item of spaceList" class="col-md-12 col-lg-12 col-xl-12">
    <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
      <div class="m-0 px-2 fs-7 d-flex align-items-center"> <span
          [inlineSVG]="'./assets/media/icons/duotune/icon/planet-moon.svg'"
          class="svg-icon svg-icon-3 ml-2 me-1 svg-icon-primary"></span> <span class="text-hover ms-2 fw-bolder"
          (click)="onEdit(item)">{{item.name}}</span> <span
          class="badge badge-light bg-light-primary text-success p-1 mx-2" *ngIf="item.isShared"> Shared
        </span>
      </div>
      <div class="d-flex">
        <a title="Private WAN : Enabled">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" disabled checked>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-lg-12 col-xl-12" *ngIf="isSpacePreLoader || isSharedSpacePreLoader">
    <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
      <div class="d-flex align-items-center w-100  justify-content-between sdwanList">
        <div class="lines round shine"></div>
        <div class="lines shine"></div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
      <div class="d-flex align-items-center w-100  justify-content-between sdwanList">
        <div class="lines round shine"></div>
        <div class="lines shine"></div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
      <div class="d-flex align-items-center w-100  justify-content-between sdwanList">
        <div class="lines round shine"></div>
        <div class="lines shine"></div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
      <div class="d-flex align-items-center w-100  justify-content-between sdwanList">
        <div class="lines round shine"></div>
        <div class="lines shine"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!(isSpacePreLoader || isSharedSpacePreLoader) && !(spaceList && spaceList.length > 0)"
  class="text-center font16 mt15 mb15 pt-4">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
  </h3>
</div>