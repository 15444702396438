import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-network-interface-edit',
  templateUrl: './shared-network-interface-edit.component.html',
  styleUrls: ['./shared-network-interface-edit.component.scss']
})
export class SharedNetworkInterfaceEditComponent implements OnInit {
  interfaceDetail: any;
  InterfaceAdd: any;
  typeInterface: any;
  bonderId: any;
  allInterfaces: any[] = [];
  errors: any = {};
  arrBridge: any;
  bondName: any;
  bondId: any;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef, private storageService: StorageService,
    private partnerBondingService: PartnerBondingService) { }

  ngOnInit(): void {
    // this.getSuccess();
    this.typeInterface = this.interfaceDetail.type
    this.InterfaceAdd = new FormGroup({
      type: new FormControl(this.interfaceDetail.type,),
      ethernet_interface: new FormControl(this.interfaceDetail.ethernet_interface,),
      vlan_tag: new FormControl(this.interfaceDetail.vlan_tag,),
      bridge: new FormControl(this.interfaceDetail.bridge,),
      ifname: new FormControl(this.interfaceDetail.ifname,),
      Interface_mode: new FormControl(this.interfaceDetail.mode,),
      VNI: new FormControl(this.interfaceDetail.vni,),
      Port: new FormControl(this.interfaceDetail.port,),
      ethernet_name: new FormControl(this.interfaceDetail.ethernet_name,),
      multicast_group: new FormControl(this.interfaceDetail.multicast_group,),
      source_address: new FormControl(this.interfaceDetail.source_address,),
      ttl: new FormControl(this.interfaceDetail.ttl,),
      tos: new FormControl(this.interfaceDetail.tos,),
      MAC_address: new FormControl(this.interfaceDetail.mac,),
      Interface_MTU: new FormControl(this.interfaceDetail.mtu,),
      ageing_time: new FormControl(this.interfaceDetail.ageing_time,),
      stp: new FormControl(this.interfaceDetail.stp,),
      hello_time: new FormControl(this.interfaceDetail.hello_time,),
      forward_delay: new FormControl(this.interfaceDetail.forward_delay,),
      max_age: new FormControl(this.interfaceDetail.max_age,),
      priority: new FormControl(this.interfaceDetail.priority,),
      note: new FormControl(this.interfaceDetail.note, [this.sharedService.xssValidator]),
      failbackMessage: new FormControl(this.interfaceDetail.failbackMessage,),
      endpoints: new FormArray([])
    });

    this.interfaceDetail.endpoints?.forEach((element: any) => {
      this.endpoints.push(this.newEndpoints(element));
    });
    this.cd.detectChanges()
  }


  get endpoints(): FormArray {
    return this.InterfaceAdd.get("endpoints") as FormArray
  }

  getSuccess() {
    this.partnerBondingService.successForInterface$.subscribe((res: any) => {
      if (res && res.success) this.activeModal.close({});
    });
  }

  newEndpoints(dataItem: any = {}): FormGroup {
    return new FormGroup({
      address: new FormControl(dataItem.address || ''),
      port: new FormControl(dataItem.port || '4789'),
      vni: new FormControl(dataItem.vni || '0'),
    })
  }

  onRemove(index: number) {
    this.endpoints.removeAt(index);
  }

  get f() {
    return this.InterfaceAdd.controls;
  }

  onAdd() {
    this.endpoints.push(this.newEndpoints());
  }

  // get formControls() { return this.InterfaceAdd.controls; }
  changeType(e: any) {
    this.typeInterface = e.target.value;
    this.cd.detectChanges()
  }

  onSave() {
    let apiURL = this.storageService.getCipherObj('api_url');
    if (!apiURL) return;
    let node = `${apiURL}nodes/${this.bonderId}/`;
    let body: any = {}
    if (this.InterfaceAdd.value.type == 'Ethernet') {
      // this.method="POST",
      // this.url=`bonders/46/ethernet_interfaces/`,
      body = {
        type: this.InterfaceAdd.value.type,
        ifname: this.InterfaceAdd.value.ifname,
        mode: this.InterfaceAdd.value.Interface_mode,
        mac: this.InterfaceAdd.value.MAC_address,
        node: node,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        note: this.InterfaceAdd.value.note,
        bridge: this.InterfaceAdd.value.bridge,
        status: null
      }
    }
    else if (this.InterfaceAdd.value.type == 'VLAN') {
      // this.method="POST",
      // this.url=`bonders/46/vlan_interfaces/`,
      body = {
        ifname: this.InterfaceAdd.value.ifname,
        type: this.InterfaceAdd.value.type,
        mode: this.InterfaceAdd.value.Interface_mode,
        mac: this.InterfaceAdd.value.MAC_address,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        ethernet_interface: this.InterfaceAdd.value.ethernet_interface,
        vlan_tag: this.InterfaceAdd.value.vlan_tag,
        bridge: this.InterfaceAdd.value.bridge,
        note: this.InterfaceAdd.value.note,
        node: node//"node_url" in bonder response
      }
    } else if (this.typeInterface == 'VXLAN') {
      // this.method="POST",
      // this.url=`bonders/46/vxlan_interfaces/`,
      body = {
        type: this.InterfaceAdd.value.type,
        mode: this.InterfaceAdd.value.Interface_mode,
        mac: this.InterfaceAdd.value.MAC_address,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        ip_addresses: [],
        space: null,
        ifname: this.InterfaceAdd.value.ifname,
        vni: this.InterfaceAdd.value.VNI,
        port: this.InterfaceAdd.value.Port,
        ethernet_interface: this.InterfaceAdd.value.ethernet_interface,
        multicast_group: this.InterfaceAdd.value.multicast_group,
        source_address: this.InterfaceAdd.value.source_address,
        ttl: this.InterfaceAdd.value.ttl,
        tos: this.InterfaceAdd.value.tos,
        endpoints: this.InterfaceAdd.value.endpoints,
        bridge: this.InterfaceAdd.value.bridge,
        note: this.InterfaceAdd.value.note,
        node: node//"node_url" in bonder response
      }
    } else {
      body = {
        type: this.typeInterface,
        ifname: this.InterfaceAdd.value.ifname,
        ageing_time: this.InterfaceAdd.value.ageing_time,
        stp: this.InterfaceAdd.value.stp,
        hello_time: this.InterfaceAdd.value.hello_time,
        forward_delay: this.InterfaceAdd.value.forward_delay,
        max_age: this.InterfaceAdd.value.max_age,
        priority: this.InterfaceAdd.value.priority,
        mac: this.InterfaceAdd.value.MAC_address,
        mtu: this.InterfaceAdd.value.Interface_MTU,
        note: this.InterfaceAdd.value.note,
        node: node//"node_url" in bonder response,
      }
    }
    body.bondName = this.bondName;
    body.bondId = this.bondId;
    let InterfaceData1: any = {};
    InterfaceData1['method'] = "PATCH";
    InterfaceData1['data'] = body;
    if (body.type == "Ethernet") InterfaceData1['url'] = `bonders/${this.bonderId}/ethernet_interfaces/${this.interfaceDetail.id}/`;
    else if (body.type == "VLAN") InterfaceData1['url'] = `bonders/${this.bonderId}/vlan_interfaces/${this.interfaceDetail.id}/`;
    else if (body.type == "VXLAN") InterfaceData1['url'] = `bonders/${this.bonderId}/vxlan_interfaces/${this.interfaceDetail.id}/`;
    else InterfaceData1['url'] = `bonders/${this.bonderId}/bridge_interfaces/${this.interfaceDetail.id}/`;

    this.sharedService.showLoader();
    this.partnerBondingService.addData(InterfaceData1).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) {
        this.partnerBondingService.successForInterface.next({ success: true });
        this.sharedService.loggerSuccess(addRes.message);
      } else this.sharedService.loggerError(addRes.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
      this.activeModal.close({ event: 'save' });
    }, (err) => {
      try {
        this.errors = err;
        this.errors = JSON.parse(err);
        this.partnerBondingService.successForInterface.next({ success: false })
        Object.keys(this.errors).forEach(x => {
          let interfaceAddControl = this.InterfaceAdd.get(x);
          if (interfaceAddControl) this.InterfaceAdd.setErrors({ ...this.InterfaceAdd.errors, [x]: this.errors[x] });
        });
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  onClose() {
    this.activeModal.close();
  }
}
