import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { ClipboardModule } from 'ngx-clipboard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AgmCoreModule } from '@agm/core';
import { MatIconModule } from '@angular/material/icon';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { TreeviewModule } from 'ngx-treeview';
import { UiSwitchModule } from 'ngx-ui-switch';
import { environment } from 'src/environments/environment';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { AuthInterceptor } from './modules/shared/auth/services/auth.interceptor';
import { AuthService } from './modules/shared/auth/services/auth.service';
import { ErrorInterceptor } from './modules/shared/auth/services/error.interceptor';
import { SharedModule } from './shared/modules/shared.module';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    TreeviewModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-right', preventDuplicates: true, }),
    AgmCoreModule.forRoot({ apiKey: environment.googleMapKey, libraries: ['places'] }),
    NgxSpinnerModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    MatIconModule,
    SharedModule,
    NgSelectModule,
    InlineSVGModule,
    UiSwitchModule.forRoot({ color: '#4287f5' }),
    CKEditorModule,
    BreadcrumbModule,
    AngularSvgIconModule.forRoot(),
    MonacoEditorModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService], },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
