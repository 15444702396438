<div class="d-flex align-items-stretch"></div>
<div class="d-flex align-items-center">
  <ng-container>
    <div *ngIf="breadCrumbs.length > 0">
      <span class="align-items-center d-flex px-2 mx-3 p-1 breadCrumbs-text">{{breadCrumbs.join(' > ')}}</span>
    </div>
    <div class="d-flex align-items-center" *ngIf="showCounter && !isForeverPermission">
      <div>
        <h4 class="mb-0 me-2" style="line-height: 10px;">
          <span class="form-label d-block mb-0 text-primary">
            You will be signed out after {{counter | formatTime}} hours.
          </span>
        </h4>
      </div>
    </div>
    <div *ngIf="!showSignOut" class="me-0 card-toolbar d-flex align-items-center justify-content-end"
      data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Back to your profile">
      <div class="cursor-pointer btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px"
        [ngClass]="toolbarUserAvatarHeightClass" (click)="logoutFromProfile()">
        <span [inlineSVG]="'./assets/media/icons/normal/logout01.svg'" class="svg-icon svg-icon-1 svg-icon-dark"></span>
      </div>
    </div>
    <!-- give access -->
    <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass"
      *ngIf="showTimerAccess && !showCounter && (isPartner || isUserPartnerAdministrator)">
      <div class="btn btn-icon btn-active-light-primary clock-custom-red" [autoClose]="'outside'"
        [ngClass]="toolbarButtonHeightClass" [ngbPopover]="popContent">
        <span [inlineSVG]="'./assets/media/icons/normal/red_clock.svg'" class="svg-icon"
          [ngClass]="toolbarButtonIconSizeClass"></span>
      </div>
    </div>
    <!-- revert Access -->
    <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass"
      *ngIf="showRevertAccess && !showCounter && (isPartner || isUserPartnerAdministrator)">
      <div class="btn btn-icon btn-active-light-primary clock-custom-green" [autoClose]="'outside'"
        [ngClass]="toolbarButtonHeightClass" [ngbPopover]="popContent1">
        <span [inlineSVG]="'./assets/media/icons/normal/green_clock.svg'" class="svg-icon " fill:red
          [ngClass]="toolbarButtonIconSizeClass"></span>
      </div>
    </div>
    <ng-template #popContent1>
      <div class="menu-item p-4 bg-light">
        <h4 *ngIf="!isForeverPermission" class="mb-0">
          Granted {{accessTimeForAdmin}} minutes.
        </h4>
        <h4 *ngIf="isForeverPermission" class="mb-0">
          Granted forever access to the profile.
        </h4>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <div class="menu-item me-2">
          <button (click)="revokePermission()" *ngIf="!isChangeDuration" type="button"
            class="btn btn-primary btn-style">
            Revoke Permission
          </button>
        </div>
        <button (click)="changeDuration()" *ngIf="!isChangeDuration && isShowChangeDuration" type="button"
          class="btn btn-primary btn-style">
          Change Duration
        </button>
      </div>
      <ng-container *ngIf="isChangeDuration">
        <div class="menu-item">
          <a (click)="setAdminPartnerAccess(30)" class="menu-link cursor-pointer py-2 pt-4"> 30 Minutes </a>
        </div>
        <div class="separator my-2"></div>
        <div class="menu-item">
          <a (click)="setAdminPartnerAccess(45)" class="menu-link cursor-pointer py-2"> 45 Minutes </a>
        </div>
        <div class="separator my-2"></div>
        <div class="menu-item">
          <a (click)="setAdminPartnerAccess(60)" class="menu-link cursor-pointer py-2"> 60 Minutes </a>
        </div>
        <div class="separator my-2"></div>
        <div class="menu-item">
          <a (click)="setAdminPartnerAccess(120)" class="menu-link cursor-pointer pt-2 pb-4"> 120 Minutes </a>
        </div>
        <div class="separator my-2"></div>
        <div class="menu-item">
          <a (click)="setAdminPartnerAccess(-1)" class="menu-link cursor-pointer pt-2 pb-4"> No Limit </a>
        </div>
        <div class="separator my-2"></div>
        <div class="d-flex align-items-center justify-content-center">
          <button (click)="back()" *ngIf="isChangeDuration" type="button" class="btn btn-primary btn-style">
            Cancel
          </button>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #popContent class="p-0">
      <div class="menu-item p-4 pb-3 bg-light">
        <h4 class="mb-0">
          Give access for the profile
        </h4>
      </div>
      <div class="menu-item">
        <a (click)="setAdminPartnerAccess(30)" class="menu-link cursor-pointer py-2 pt-4"> 30 Minutes </a>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item">
        <a (click)="setAdminPartnerAccess(45)" class="menu-link cursor-pointer py-2"> 45 Minutes </a>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item">
        <a (click)="setAdminPartnerAccess(60)" class="menu-link cursor-pointer py-2"> 60 Minutes </a>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item">
        <a (click)="setAdminPartnerAccess(120)" class="menu-link cursor-pointer pt-2 pb-4"> 120 Minutes </a>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item">
        <a (click)="setAdminPartnerAccess(-1)" class="menu-link cursor-pointer pt-2 pb-4"> No Limit </a>
      </div>
    </ng-template>
  </ng-container>
  <!-- support -->
  <div class="app-engage app-engage-hide">
    <a [ngbPopover]="documentation"
      class="app-engage-btn app-engage-btn-toggle-on text-hover-primary p-0 text-gray-800 cursor-pointer p-2">
      <span [inlineSVG]="'./assets/media/icons/duotune/icon/info-circle-line.svg'"
        class="svg-icon svg-icon-2 mx-1"></span> </a>
    <ng-template #documentation>
      <a data-bs-toggle="modal" *ngIf="isAdmin" class="app-engage-btn hover-dark mb-3 cursor-pointer"
        (click)="openDoc()">
        <span [inlineSVG]="'./assets/media/icons/duotune/icon/file-contract.svg'"
          class="svg-icon svg-icon-5 ms-1 me-2"></span> Documentation
      </a>
      <a class="app-engage-btn hover-primary cursor-pointer" (click)="openVersionList()">
        <span [inlineSVG]="'./assets/media/icons/duotune/icon/info-circle-line.svg'"
          class="svg-icon svg-icon-5 ms-1 me-2"></span> What's New </a>
      <div class="d-flex flex-column" *ngIf="technicalSupportVisible">
        <h3 class="border-top my-3 py-3 fs-7 pb-0"> Technical support </h3>
        <a class="hover-primary py-2" target="_blank" href="{{technicalSupportObj?.technicalSupportURL}}"
          *ngIf="technicalSupportObj?.technicalSupportURL && technicalSupportObj?.technicalSupportURL != ''">
          <span [inlineSVG]="'./assets/media/icons/duotune/icon/link.svg'"
            class="svg-icon svg-icon-5 ms-1 me-2"></span>{{technicalSupportObj?.technicalSupportURL}}</a>
        <a class="hover-primary py-2" target="_blank"
          *ngIf="technicalSupportObj?.technicalSupportEmail && technicalSupportObj?.technicalSupportEmail != ''">
          <span [inlineSVG]="'./assets/media/icons/duotune/icon/envelope-line.svg'"
            class="svg-icon svg-icon-5 ms-1 me-2"></span>{{technicalSupportObj?.technicalSupportEmail}}</a>
        <a class="hover-primary py-2" target="_blank"
          *ngIf="technicalSupportObj?.technicalSupportContact && technicalSupportObj?.technicalSupportContact != ''">
          <span [inlineSVG]="'./assets/media/icons/duotune/icon/phone.svg'"
            class="svg-icon svg-icon-5 ms-1 me-2"></span>{{technicalSupportObj?.technicalSupportContact}}</a>
      </div>
    </ng-template>
  </div>
  <!-- profile -->
  <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass" id="kt_header_user_menu_toggle">
    <div class="cursor-pointer symbol" [ngClass]="toolbarUserAvatarHeightClass" [ngbPopover]="profilePopUp">
      <img *ngIf="!imageUrl" src="./assets/media/avatars/blank.png" alt="Logo" />
      <img alt="Logo" *ngIf="imageUrl" [src]="imageUrl" />
    </div>
    <ng-template #profilePopUp>
      <div *ngIf="_user" class="menu-item px-3">
        <div class="menu-content d-flex align-items-center px-3">
          <div class="symbol symbol-50px me-5">
            <img alt="Logo" *ngIf="_user.imageUrl" [src]="_user.imageUrl" />
            <img *ngIf="!_user.imageUrl" src="./assets/media/avatars/blank.png" alt="">
          </div>
          <div class="d-flex flex-column">
            <div class=" fw-bolder d-flex align-items-center fs-5 text-nowrap">
              {{_user.fname | truncate: ['15', '...'] }} {{_user.lname | truncate: ['15', '...']}}
            </div>
            <a class="text-hover-primary fs-7 cursor-pointer user-email">
              {{ _user.email }}
            </a>
            <span class="text-success fw-bolder fs-8" *ngIf="_user.role == 0">Super Admin</span>
            <span class="text-success fw-bolder fs-8" *ngIf="_user.role == 1">Root Partner</span>
            <span class="text-success fw-bolder fs-8" *ngIf="_user.role == 2">User</span>
            <span class="text-success fw-bolder fs-8" *ngIf="_user.role == 3">User</span>
            <span class="text-success fw-bolder fs-8" *ngIf="_user.role == 4">End User</span>
            <span class="text-success fw-bolder fs-8" *ngIf="_user.role == 5">Basic User</span>
            <span class="text-success fw-bolder fs-8" *ngIf="_user.role == 6">Organization</span>
          </div>
        </div>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item px-5">
        <a (click)="redirectToProfile()" class="menu-link px-5 text-light-dark-custom">
          My Profile
        </a>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item px-5" *ngIf="showSignOut">
        <a (click)="logout()" class="menu-link px-5 cursor-pointer text-light-dark-custom"> Sign Out </a>
      </div>
    </ng-template>
  </div>
</div>