import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-bond-authorization-modal',
  templateUrl: './shared-bond-authorization-modal.component.html',
  styleUrls: ['./shared-bond-authorization-modal.component.scss']
})
export class SharedBondAuthorizationModalComponent implements OnInit {
  authData: any;
  bonderId: number;
  authForm: FormGroup;
  errors: any;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private partnerBondingService: PartnerBondingService,
    private cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
    if (this.authData) this.authForm = this.createForm(this.authData);
    else this.authForm = this.createForm();
  }

  createForm(data: any = {}) {
    return new FormGroup({
      username: new FormControl(data?.username || ''),
      password: new FormControl(data?.password || ''),
    });
  }

  onSave() {
    this.sharedService.showLoader();
    this.partnerBondingService.updateBondAuth(this.bonderId, this.authForm.value)?.subscribe((res: any) => {
      if (res.code == 200) {
        this.sharedService.loggerSuccess('update successfully.');
        this.activeModal.close({ event: 'save' });
      } else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError(err);
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }

    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
