<!-- <ng-container *ngIf="title$ | async as title">
  <ng-container *ngIf="showTitle">
    <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3 mr-2">
      {{ title }}<span *ngIf="showNav" class="h-20px border-gray-200 border-start mx-4"></span>
    </h1>
</ng-container>
</ng-container> -->

<!-- BreadCrumb Logic -->
<ng-container >
  <xng-breadcrumb style="font-weight: bold" [separator]="iconTemplate"></xng-breadcrumb>
</ng-container>

<ng-template #iconTemplate>
  <span inlineSVG="../../../../../../assets/media/svg/shapes/dash.svg" class="svg-icon svg-icon-9"></span>
</ng-template>
