<div class="modal-header px-5 py-3">
    <h5 class="modal-title" *ngIf="type == 1">Illuminate Plan Access</h5>
    <h5 class="modal-title" *ngIf="type == 2">Select Provision Plan</h5>
    <h5 class="modal-title" *ngIf="type == 3">Illuminate Details</h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body m-2" *ngIf="type == 1">
    <div class="card">
        <div class="card-body p-0">
            <div class="row" *ngIf="errors">
                <div class="col-md-12">
                    <div class="alert alert-danger">Please correct the errors below.</div>
                </div>
            </div>
            <div class="row" *ngIf="illuminatePlanAccess.length <= 0">
                <div class="col-md-12">
                    <div class="alert alert-danger">Atleast Single Illuminate Plan required.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 plan-item my-2" *ngFor="let plan of illuminatePlanAccess; let i = index;">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input [disabled]="plan.access == true && disablePlanAccess()" [id]="''+i"
                                class="form-check-input" [(ngModel)]="plan.access" type="checkbox"
                                value="{{(plan?.access == 'true') ? 'true' : 'false'}}" data-kt-check="true"
                                data-kt-check-target=".widget-9-check">
                            <label class="mb-0 form-label ms-3"
                                [style.cursor]="plan.access == true && disablePlanAccess()?'not-allowed': 'pointer'"
                                [for]="''+i"> {{plan.name}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-body m-2" *ngIf="type == 2">
    <div class="card">
        <div class="card-body p-0">
            <div class="row" *ngIf="errors">
                <div class="col-md-12">
                    <div class="alert alert-danger">Please correct the errors below.</div>
                </div>
            </div>
            <div class="row" *ngIf="illuminatePlanAccess.length <= 0">
                <div class="col-md-12">
                    <div class="alert alert-danger">Atleast Single Illuminate Plan required.</div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-md-12 col-lg-12 mb-5">
                    <label class="form-label"> Available Plan <span class="required-asterisk m-0">*</span> </label>
                    <ng-select [(ngModel)]="selectedPlan" placeholder="Select Provision Plan" class="dropdown-block"
                        [searchable]="false" [clearable]="false">
                        <ng-option *ngFor="let plan of illuminatePlanAccess;" [value]="plan.key"> {{plan.name}}
                        </ng-option>
                    </ng-select>
                </div> -->
                <div class="col-12 plan-item my-2" *ngFor="let plan of illuminatePlanAccess; let i = index;">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input [id]="'radio'+i" class="form-check-input" [(ngModel)]="selectedPlan" type="radio"
                                [value]="plan.key" data-kt-check="true" data-kt-check-target=".widget-9-check">
                            <label class="mb-0 form-label ms-3 cursor-pointer" [for]="'radio'+i"> {{plan.name}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-body p-5" *ngIf="type == 3">
    <form [formGroup]="illuminateForm">
        <div class="card mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Illuminate Provision URL</label>
                            <input formControlName="illuminate_provision_url" type="text"
                                name="illuminate_provision_url" class="form-control form-control-lg form-control-solid"
                                autocomplete="off" />
                            <small>ex. https://illuminate_example.com/api/v1/</small>
                            <small class="form-invalid-message">
                                <span class="text-danger"
                                    *ngIf="controlHasError('required', 'illuminate_provision_url')">
                                    URL is required
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Illuminate Provision Token</label>
                            <input formControlName="illuminate_provision_token" type="text" autocomplete="off"
                                class="form-control form-control-lg form-control-solid"
                                name="illuminate_provision_token" />
                            <small class="form-invalid-message">
                                <span class="text-danger"
                                    *ngIf="controlHasError('required', 'illuminate_provision_token')">
                                    Token is required
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal"
        [disabled]="type==2 && !selectedPlan ? true: false" (click)="onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div>{{type ==2 ? 'Provision':'Save'}}
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>