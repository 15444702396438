


<div class="d-flex flex-wrap flex-stack my-3">
  <div class="fw-bolder my-2 d-flex div-position">
      <input type="text" class="form-control" [formControl]="searchInput" name="searchText" placeholder="Search" (keydown)="preventSpace($event)" value="" />
      <span (click)="onResetSearch()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'" class="svg-icon svg-icon-1 me-0 span-input"></span>
  </div>

  <div class="d-flex flex-wrap my-2">

      <div class="me-4">
          <select name="status" data-hide-search="true" class="form-select form-select-sm form-select-white">
             <option value="Active" value='true'>active</option>
             <option value="Inactive" value='true'>Inactive</option> -->
             <option value="1" value='true'></option>
          </select>
      </div>

      <div class="me-4">
          <select  class="form-select form-select-sm form-select-white">
             <option value='all' selected>Filter</option>
              <option value="selected" value='true'>Filter 01</option>
              <option value="primary aggregator" value='true'>Filter 02</option>
              <option value="bonds to space" value='true'>Filter 02</option>
              <!-- <option value="InActive" value='false'></option> -->
          </select>
      </div>

      <div class="me-4 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
          data-bs-placement="top" data-bs-trigger="hover" title="Select Column">
          <a class="btn btn-sm btn-primary pe-4 ps-4" >
              <span [inlineSVG]="'./assets/media/icons/duotune/icon/columns.svg'"
                  class="svg-icon svg-icon-1 me-0"></span>
          </a>
      </div>

      <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
          data-bs-placement="top" data-bs-trigger="hover" title="Click to add an Aggregator" (click)="addAggregator()">
          <a class="btn btn-sm btn-primary">
            Add Aggregator
              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                  class="svg-icon svg-icon-1 me-0"></span>
          </a>
      </div>
  </div>

</div>


<div class="card mb-5 mb-xl-10">
  <div class="card-body p-0">
      <div class="row">
          <div class="col-md-12 col-lg-12 mb-8 mb-0">

                 <!-- begin::Table container -->
              <div class="table-responsive border">
                  <!-- begin::Table -->

                  <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-1 mb-0">
                    <thead class="table-light">
                      <tr class="fw-bolder text-dark fs-7">

                            <th class="py-4">
                              <div class="form-check form-check-sm form-check-custom form-check-solid">
                                  <!-- <input class="form-check-input" type="checkbox" value="1" data-kt-check="true"
                                      data-kt-check-target=".widget-9-check" /> -->
                                    <input class="form-check-input" [(ngModel)]="isCheckAll" (change)="checkUncheckAll($event)" type="checkbox">
                              </div>
                             </th>

                              <th class="py-4"> ID </th>
                              <th class="py-4">Name </th>
                              <th  class="py-4">Space </th>
                              <th  class="py-4">IP </th>
                              <th  class="py-4">IPV6 </th>
                              <th  class="py-4">Version </th>
                              <th  class="py-4">Routing Group </th>
                              <th class="py-4">CPU</th>
                              <th class="py-4">Primary Bonds	</th>
                              <th class="py-4">Secondary Bonds </th>
                              <th class="py-4">Current Bonds	</th>
                              <th class="py-4">Status</th>
                              <th class="py-4">Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let aggregator of aggregatorList; let i=index">
                          <td class="ps-5"> <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" value="1" data-kt-check="true"  data-kt-check-target=".widget-9-check" [(ngModel)]="aggregator.isSelected" (change)="isAllSelected()" />
                                </div>
                            </td>
                            <td>{{aggregator.id}}</td>
                            <td> {{aggregator.name}} </td>
                            <td> {{aggregator.space.name}}	  </td>
                            <td> {{aggregator.ip}}</td>
                            <td> {{aggregator.ipv6}}</td>
                            <td>{{aggregator.version}} </td>
                            <td> {{aggregator.routing_group.hostname}}</td>
                            <td>{{aggregator.abbreviated_cpu}}</td>
                            <td> {{aggregator.bonds.count}}	</td>
                            <td> {{aggregator.secondary_bonds_count}}	</td>
                            <td> {{aggregator.current_bonds_count}}</td>
                            <td> {{aggregator.status}}	</td>
                            <td class="text-start">

                              <a title="View"
                              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ml-1 me-2 ms-2">
                              <span [inlineSVG]="'./assets/media/icons/duotune/icon/eye.svg'"
                                  class="svg-icon svg-icon-3 svg-icon-primary">
                              </span>
                            </a>

                                <a title="Delete" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                    class="svg-icon svg-icon-3 svg-icon-danger">
                                </span>
                            </a>


                            </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>
</div>
