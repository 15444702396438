import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ShredNetworkProtocolsAddComponent } from './shred-network-protocols-add/shred-network-protocols-add.component';
@Component({
  selector: 'app-shared-network-protocols',
  templateUrl: './shared-network-protocols.component.html',
  styleUrls: ['./shared-network-protocols.component.scss']
})
export class SharedNetworkProtocolsComponent implements OnInit {
  authService = AuthService;
  latest_tuning: any;
  bondPermission: any;
  @Input() allprotocol: any[] = [];
  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() allfilters: any;
  @Input() InterfaceDetailArray: any[];
  @Input() permissionsObj: any;

  @Output() getprotocol = new EventEmitter<any>();

  constructor(public sharedService: SharedService,
    private cd: ChangeDetectorRef, private storageService: StorageService,
    private modalService: NgbModal,
    private partnerBondingService: PartnerBondingService) { }

  ngOnInit(): void {
    this.partnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
  }

  editProtocol(data: any) {
    let modal = this.modalService.open(ShredNetworkProtocolsAddComponent, { size: 'lg' });
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.allfilters = this.allfilters;
    modal.componentInstance.InterfaceDetailArray = this.InterfaceDetailArray
    if (data) {
      modal.componentInstance.protocolData = data;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = true;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getprotocol.emit();
    }, () => { });
  }

  deleteProtocol(data: any, name: any, protocol: any) {
    let reqObj = {
      "method": "DELETE",
      "url": `dynamic_routing/bonder/protocols/${data.id}/`,
      "data": {},
      "name": name,
      "bondName": this.storageService.getCipherObj('bondName'),
      "bondId": this.bondId,
      "type": 'Protocols',
      "protocol": protocol
    }
    let questionTitle = 'Are you sure you want to delete this protocol?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.partnerBondingService.deleteData(reqObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getprotocol.emit();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getprotocol.emit();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }
}
