import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/';

@Injectable({ providedIn: 'root' })
export class PartnerBondNetworkDetailsService {
  getAllLegs() {
    throw new Error('Method not implemented.');
  }

  mainId = this.storageService.getCipherObj('mainId');

  constructor(private http: HttpClient, private storageService: StorageService) { }

  addReq(url: any, methodType?: any, getData?: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`,
      { url: url, method: methodType, data: getData })
  }

  addSpeedTest(data: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`,
      data)
  }
  getResponse(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  getConnectedIPS() {
    return this.http.get<any>(`${BACKEND_URL}nepeanNetworksBonds/${this.mainId}/`); //api call
  }

  getUpdates() {
    return this.http.get<any>(`${BACKEND_URL}nepeanNetworksBonds/${this.mainId}/`); //api call
  }

  getSpeedTestById(bondId: any, speedTestId: any) {
    let url = `bonds/${bondId}/speedtests/${speedTestId}`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: 'GET' });
  }

  repeatSpeedTest(bondId: any, speedTestId: any) {
    let url = `bonds/${bondId}/speedtests/${speedTestId}/repeat`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: 'GET' });
  }

  getClassificationProfileName(url: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: 'GET' });
  }

  getConnectedIPs(url: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: 'GET' }); //api call
  }
}
