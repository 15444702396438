import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'app-shared-bonding-api',
  templateUrl: './shared-bonding-api.component.html',
  styleUrls: ['./shared-bonding-api.component.scss']
})
export class SharedBondingApiComponent implements OnInit {
  @Input() apiURL: any;
  @Output() editBondingApi = new EventEmitter<any>();
  @Output() addBondingApi = new EventEmitter<any>();
  @Output() testBondingApi = new EventEmitter<any>();
  BondApi: FormGroup;
  permissionsObj: { view: boolean; update: boolean; delete: boolean; };
  constructor(private cd: ChangeDetectorRef, public sharedService: SharedService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.BondApi = this.createForm()
  }
  ngOnChanges() {
    this.getPermission();
    if (this.apiURL) {
      this.BondApi.patchValue(this.apiURL)
    } else {
      this.BondApi = this.createForm()
    }
    this.cd.detectChanges();
  }

  getPermission() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.admin || Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.permissionsObj = { view: true, update: true, delete: true }
    } else {
      this.sharedService.getPermission();
      if (this.sharedService.noPermission) this.permissionsObj = { view: true, update: true, delete: true }
      else {
        this.permissionsObj = this.sharedService.generalConfiguration;
        if (!this.permissionsObj) this.permissionsObj = { view: true, update: true, delete: true }
      }
    }
    this.cd.detectChanges();
  }
  createForm(data: any = {}) {
    return new FormGroup({
      email: new FormControl(data?.email || ''),
      pass: new FormControl(data?.pass || ''),
      url: new FormControl(data?.url || ''),
    })
  }
  get f() {
    return this.BondApi.controls;

  }
  onSave() {
    if (this.apiURL) {
      this.editBondingApi.emit(this.BondApi.value)
    } else {
      this.addBondingApi.emit(this.BondApi.value)
    }
  }
  onTest() {
    console.log("🚀  ~  this.apiURL", this.BondApi)
    let obj = {
      username: this.BondApi.value.email,
      password: this.BondApi.value.pass,
      url: this.BondApi.value.url
    }
    this.testBondingApi.emit(obj)
    console.log("🚀 ~ file:  ~ onTest ~ obj", obj)
  }
}
