import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-hosts-routers',
  templateUrl: './shared-hosts-routers.component.html',
  styleUrls: ['./shared-hosts-routers.component.scss']
})
export class SharedHostsRoutersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
