<div class="modal-header px-5 py-3">
    <h5 class="modal-title" id="addcpe">Edit
        <span *ngIf="option == 1"> Node Information </span>
        <span *ngIf="option == 2"> General Configuration </span>
        <span *ngIf="option == 3"> Security & Optimization </span>
        <span *ngIf="option == 4"> WAN Services </span>
        <span *ngIf="option == 5"> Tunnel </span>
        <span *ngIf="option == 6"> Node </span>
        <span *ngIf="option == 7"> Site Address </span>
    </h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
    <form [formGroup]="nodeInfoForm" *ngIf="option == 1">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors?.non_field_errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <!-- Circuit ID start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Circuit ID</label>
                            <input formControlName="circuit_id" type="text"
                                class="form-control form-control-lg form-control-solid" name="Circuit ID"
                                placeholder="Enter Circuit ID" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.circuit_id?.join(' ')}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'circuit_id')">
                                    Circuit Id is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--  Circuit ID End-->
                    <!-- Product start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Product</label>
                            <input formControlName="product" type="text"
                                class="form-control form-control-lg form-control-solid" name="Product"
                                placeholder="Enter Product" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.product?.join(' ')}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'product')">
                                    Product is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--  Product End-->
                    <!-- Serial number start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Serial number</label>
                            <input formControlName="serial_number" type="text"
                                class="form-control form-control-lg form-control-solid" name="Serial number"
                                placeholder="Enter Serial number" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.serial_number?.join(' ')}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'serial_number')">
                                    Serial number is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--  Serial number End-->
                    <!-- Asset tag start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Asset tag</label>
                            <input formControlName="asset_tag" type="text"
                                class="form-control form-control-lg form-control-solid" name="Asset tag"
                                placeholder="Enter Asset tag" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.asset_tag?.join(' ')}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'asset_tag')">
                                    Asset tag is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--  Asset tag-->
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="generalConfigForm" *ngIf="option == 2">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors?.non_field_errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                    <div class="col-md-12" *ngFor="let err of errors | keyvalue">
                        <div class="alert alert-danger">{{err.value}}</div>
                    </div>
                </div>
                <div class="row">
                    <!-- Name start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Name</label>
                            <input formControlName="name" type="text"
                                class="form-control form-control-lg form-control-solid" name="Name"
                                placeholder="Enter Name" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.name?.join(' ')}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('pattern', 'name')">
                                    Name is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1" *ngIf="(homeSpace || isPartner) 
                        && !(isSharedBond || isSdwanSharedBond)">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Spaces</label>
                            <ng-select class="dropdown-block" formControlName="space" placeholder="Select Space"
                                *ngIf="!preSpaceLoaderScreen">
                                <ng-option *ngFor="let item of spaces;" [value]="item.key">{{item.name}}</ng-option>
                            </ng-select>
                            <div class="shine w-100 br-6" style="height: 36px;" *ngIf="preSpaceLoaderScreen">
                            </div>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.space?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">DB Notes</label>
                            <input formControlName="note" type="text"
                                class="form-control form-control-lg form-control-solid" name="note"
                                placeholder="Enter DB Notes" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.note?.join(' ')}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'note')">
                                    DB Notes is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="securityForm" *ngIf="option == 3">
        <div class="row checkbox-block">
            <div class="col-6">
                <div class="row">
                    <div class="col-12 mb-1">
                        <div
                            class="form-check form-check-sm form-check-custom form-check-solid flex-column align-items-start">
                            <label class="form-label mb-0">
                                <input class="form-check-input" formControlName="compression" type="checkbox"
                                    name="compression">
                                <span class="m-2 form-label">Compression </span>
                            </label>
                            <small class="checkbox-label">Compress traffic in tunnel </small>
                        </div>
                        <small class="text-danger">
                            {{errors?.compression?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-12 mb-1">
                        <div
                            class="form-check form-check-sm form-check-custom form-check-solid flex-column align-items-start">
                            <label class="form-label mb-0">
                                <input class="form-check-input" (change)="onLegMTUDetectionChange()" type="checkbox"
                                    formControlName="leg_mtu_detection" name="Leg MTU detection" id="leg-mtu-detection">
                                <span class="m-2 form-label">Leg MTU detection </span>
                            </label>
                            <small class="checkbox-label"> Automatically detect path MTU of links </small>
                        </div>
                        <small class="text-danger">
                            {{errors?.leg_mtu_detection?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-12 mb-1">
                        <div
                            class="form-check form-check-sm form-check-custom form-check-solid flex-column align-items-start">
                            <label class="form-label mb-0">
                                <input class="form-check-input" formControlName="packet_loss_detection" type="checkbox"
                                    name="Packet loss detection" id="packet-loss-detection">
                                <span class="m-2 form-label">Packet loss detection</span>
                            </label>
                            <small class="checkbox-label"> Track packet loss on bonded legs </small>
                        </div>
                        <small class="text-danger">
                            {{errors?.packet_loss_detection?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-12 mb-1">
                        <div
                            class="form-check form-check-sm form-check-custom form-check-solid flex-column align-items-start">
                            <label class="form-label mb-0">
                                <input class="form-check-input" formControlName="clamp_tcp" type="checkbox"
                                    name="Clamp TCP" id="clamp-TCP">
                                <span class="m-2 form-label">Clamp TCP</span>
                            </label>
                        </div>
                        <small class="checkbox-label"> Conform TCP packet size to tunnel MTU </small>
                        <small class="text-danger">
                            {{errors?.clamp_tcp?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-12 mb-1">
                        <div
                            class="form-check form-check-sm form-check-custom form-check-solid flex-column align-items-start">
                            <label class="form-label mb-0">
                                <input class="form-check-input" formControlName="flap_detection" type="checkbox"
                                    name="Flap detection" id="Flap-detection">
                                <span class="m-2 form-label">Flap detection</span>
                            </label>
                            <small class="checkbox-label">
                                Detect and remove legs that are rapidly switching between up
                                and down </small>
                        </div>
                        <small class="text-danger">
                            {{errors?.flap_detection?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-12 mb-1">
                        <div
                            class="form-check form-check-sm form-check-custom form-check-solid flex-column align-items-start">
                            <label class="form-label mb-0">
                                <input class="form-check-input" formControlName="source_address_verification"
                                    type="checkbox" name="source_address_verification" id="Source address verification">
                                <span class="m-2 form-label">Source address verification</span>
                            </label>
                            <small class="checkbox-label"> Drop upload traffic coming from unrecognized source IPs
                            </small>
                        </div>
                        <small class="text-danger">
                            {{errors?.source_address_verification?.join(' ')}}
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Packet distribution</label>
                            <ng-select class="dropdown-block" formControlName="packet_distribution" [clearable]="false"
                                [searchable]="false">
                                <ng-option *ngFor="let item of packetDistribution;" [value]="item.key">{{item.title}}
                                </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.packet_distribution?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Leg MTU detection time
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Repeat leg MTU detection at this interval (hours)"></span>
                            </label>
                            <input [readOnly]="!isLegMTUSelected" formControlName="leg_mtu_detection_time" type="number"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Leg MTU detection time" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.leg_mtu_detection_time?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <h3> QoS & Classification </h3>
                    </div>
                    <div class="col-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Qos Profile</label>
                            <ng-select class="dropdown-block" formControlName="qos_profile" placeholder="---------"
                                *ngIf="!preQOSLoaderScreen">
                                <ng-option value=""> --------- </ng-option>
                                <ng-option *ngFor="let item of allQosList;" [value]="item.url">
                                    {{item?.space?.name ? item?.space?.name+'-'+ item?.name: item?.name}}
                                </ng-option>
                            </ng-select>
                            <div class="shine w-100 br-6" style="height: 36px;" *ngIf="preQOSLoaderScreen"></div>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.qos_profile?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Classification Profile</label>
                            <ng-select class="dropdown-block" formControlName="classification_profile"
                                placeholder="---------">
                                <ng-option value=""> --------- </ng-option>
                                <ng-option *ngFor="let item of allClassificationList;" [value]="item.url">
                                    {{item?.display_name}}
                                </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.classification_profile?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <h3> Tunnel Security </h3>
                    </div>
                    <div class="col-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Type</label>
                            <ng-select class="dropdown-block" (change)="onEncryptionChange()" [clearable]="false"
                                [searchable]="false" formControlName="tunnel_security"
                                placeholder="Tunnel security Type">
                                <ng-option *ngFor="let item of tunnelSecurity;" [value]="item.key">{{item.title}}
                                </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.tunnel_security?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Cipher</label>
                            <ng-select class="dropdown-block" [readonly]="!encryptionEnabled" [clearable]="false"
                                [searchable]="false" formControlName="encryption_cipher"
                                placeholder="Encryption Cipher">
                                <ng-option *ngFor="let item of encyptionCipher" [value]="item.key"> {{item.title}}
                                </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.encryption_cipher?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-12 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Encryption handshake interval
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Re-handshake interval in seconds, 0 to disable re-handshaking"></span>
                            </label>
                            <input [readOnly]="!encryptionEnabled" formControlName="encryption_handshake_interval"
                                type="number" class="form-control form-control-lg form-control-solid"
                                name="Encryption handshake interval" placeholder="Enter Encryption handshake interval"
                                autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.encryption_handshake_interval?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors?.non_field_errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-6 mb-5">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="compression" type="checkbox"
                                name="compression" id="">
                            <label class="m-2 form-label"> Compression </label>
                        </div>
                        <small>Compress traffic in tunnel </small>
                        <small class="text-danger">
                            {{errors?.compression?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="flap_detection" type="checkbox"
                                name="Flap detection" id="Flap-detection">
                            <label class="m-2 form-label"> Flap detection </label>
                        </div>
                        <small>Detect and remove legs that are rapidly switching between up and down </small>
                        <small class="text-danger">
                            {{errors?.flap_detection?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" (change)="onLegMTUDetectionChange()" type="checkbox"
                                formControlName="leg_mtu_detection" name="Leg MTU detection" id="leg-mtu-detection">
                            <label class="m-2 form-label"> Leg MTU detection </label>
                        </div>
                        <small> Automatically detect path MTU of links </small>
                        <small class="text-danger">
                            {{errors?.leg_mtu_detection?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <label class="form-label">Packet distribution</label>
                        <ng-select formControlName="packet_distribution">
                            <ng-option *ngFor="let item of packetDistribution;" [value]="item.key">{{item.title}}
                            </ng-option>
                        </ng-select>
                        <small class="text-danger">
                            {{errors?.packet_distribution?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="packet_loss_detection" type="checkbox"
                                name="Packet loss detection" id="packet-loss-detection">
                            <label class="m-2 form-label"> Packet loss detection </label>
                        </div>
                        <small> Track packet loss on bonded legs </small>
                        <small class="text-danger">
                            {{errors?.packet_loss_detection?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <label class="form-label">Leg MTU detection time</label>
                        <input [readOnly]="!isLegMTUSelected" formControlName="leg_mtu_detection_time" type="number"
                            class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                            placeholder="Enter Leg MTU detection time" autocomplete="off" />
                        <small> Repeat leg MTU detection at this interval (hours) </small>
                        <small class="text-danger">
                            {{errors?.leg_mtu_detection_time?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="clamp_tcp" type="checkbox" name="Clamp TCP"
                                id="clamp-TCP">
                            <label class="m-2 form-label"> Clamp TCP </label>
                        </div>
                        <small> Conform TCP packet size to tunnel MTU </small>
                        <small class="text-danger">
                            {{errors?.clamp_tcp?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="source_address_verification"
                                type="checkbox" name="source_address_verification" id="Source address verification">
                            <label class="m-2 form-label"> Source address verification </label>
                        </div>
                        <small> Drop upload traffic coming from unrecognized source IPs </small>
                        <small class="text-danger">
                            {{errors?.source_address_verification?.join(' ')}}
                        </small>
                    </div>
                    <div class="separator separator-dashed my-5"> </div>
                    <div class="card-title d-flex align-items-center mb-0">
                        <h3 class="card-label m-0 fs-4 fw-bolder">TCP proxy</h3>
                        <div class="ms-5">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" (change)="onTCPProxyChange()"
                                    formControlName="bridge_enabled" type="checkbox" name="Enable TCP proxy"
                                    id="enable-tcp-proxy">
                                <label class="m-2 form-label"> Enable TCP proxy </label>
                            </div>
                            <small class="text-danger">
                                {{errors?.bridge_enabled?.join(' ')}}
                            </small>
                        </div>
                    </div>
                    <div class="separator separator-dashed my-5"></div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <label class="form-label">TCP ports to proxy</label>
                        <input formControlName="bridge_ports" type="text"
                            class="form-control form-control-lg form-control-solid" name="TCP ports to proxy"
                            placeholder="Enter TCP ports to proxy" autocomplete="off" />
                        <small class="text-danger">
                            {{errors?.bridge_ports?.join(' ')}}
                        </small>
                        <div class="text-danger" *ngIf="controlHasError('maxlength', 'bridge_ports')">
                            CTCP ports to proxy is invalid
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <label class="form-label">Concurrency</label>
                        <input formControlName="bridge_concurrency" type="number"
                            class="form-control form-control-lg form-control-solid" name="Concurrency"
                            placeholder="Enter Concurrency" autocomplete="off" />
                        <small class="text-danger">
                            {{errors?.bridge_concurrency?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <label class="form-label">Bridge congestion control algorithm</label>
                        <ng-select formControlName="bridge_congestion_control_algorithm"
                            placeholder="Select Bridge congestion control algorithm">
                            <ng-option *ngFor="let item of bridgeCongetionAlgorithms" [value]="item.key">
                                {{item.title}}
                            </ng-option>
                        </ng-select>
                        <small class="text-danger">
                            {{errors?.bridge_congestion_control_algorithm?.join(' ')}}
                        </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-5">
                        <label class="form-label">File descriptor limit</label>
                        <input formControlName="bridge_file_descriptor_limit" type="number"
                            class="form-control form-control-lg form-control-solid" name="File descriptor limit"
                            placeholder="File descriptor limit" autocomplete="off" />
                        <small class="text-danger">
                            {{errors?.bridge_file_descriptor_limit?.join(' ')}}
                        </small>
                    </div>
                </div>
            </div>
        </div> -->
    </form>
    <form [formGroup]="wanServicesForm" *ngIf="option == 4">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors?.non_field_errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-6 mb-5">
                        <label class="form-label">Flow collectors</label>
                        <ng-select formControlName="flow_collectors" placeholder="---------">
                            <ng-option *ngFor="let item of flowCollectors;" [value]="item">{{item?.name}}
                            </ng-option>
                        </ng-select>
                        <small class="text-danger"> {{errors?.flow_collectors?.join(' ')}} </small>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="tunnelForm" *ngIf="option == 5">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors?.non_field_errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Aggregator</label>
                            <ng-select class="dropdown-block" formControlName="aggregator" placeholder="---------"
                                *ngIf="!preAggregatorsLoaderScreen">
                                <ng-option *ngFor="let item of allAggregatorsList" [value]="item.url">
                                    {{item?.space?.name ? item?.space?.name+'-'+ item?.name: item?.name}}
                                </ng-option>
                            </ng-select>
                            <div class="shine w-100 br-6" style="height: 36px;" *ngIf="preAggregatorsLoaderScreen">
                            </div>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.aggregator?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Secondary aggregator</label>
                            <ng-select class="dropdown-block" formControlName="secondary_aggregator"
                                *ngIf="!preAggregatorsLoaderScreen">
                                <ng-option value=""> --------- </ng-option>
                                <ng-option *ngFor="let item of allSecondAggregatorsList" [value]="item.url">
                                    {{item?.space?.name ? item?.space?.name+'-'+ item?.name: item?.name}}
                                </ng-option>
                            </ng-select>
                            <div class="shine w-100 br-6" style="height: 36px;" *ngIf="preAggregatorsLoaderScreen">
                            </div>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.secondary_aggregator?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="d-flex align-items-center mt-3">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="aggregator_failback" type="checkbox"
                                    name="basic" id="basic">
                                <label class="mb-0 form-label ms-2"> Aggregator failback
                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="Automatically move this bond back to its primary aggregator when it recovers"></span>
                                </label>
                            </div>
                        </div>
                        <small class="text-danger"> {{errors?.aggregator_failback?.join(' ')}} </small>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="d-flex align-items-center mt-3">
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" formControlName="debug" type="checkbox" name="Debug"
                                    id="Debug">
                                <label class="mb-0 ms-2 form-label"> Debug
                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="Enable debug logging for tunnel and TCP proxy applications on bonder and aggregator."></span>
                                </label>
                            </div>
                        </div>
                        <small class="text-danger"> {{errors?.debug?.join(' ')}} </small>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="nodeForm" *ngIf="option == 6">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors?.non_field_errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="errors && !errors['non_field_errors']">
                    <div class="col-md-12">
                        <div class="alert alert-danger">Please correct the errors below.</div>
                    </div>
                </div>
                <div class="row">
                    <!-- Automatic reorder max hold start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" (change)="onReorderMaxChange()"
                                formControlName="automatic_reorder_max_hold" type="checkbox"
                                name="Automatic reorder max hold" id="Automatic reorder max hold">
                            <label class="m-2 form-label"> Automatic reorder max hold
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Automatically set reorder max hold value"></span>
                            </label>
                        </div>
                        <small class="text-danger">
                            {{errors?.automatic_reorder_max_hold?.join(' ')}}
                        </small>
                    </div>
                    <!--Automatic reorder max hold End-->
                    <!-- Reorder max hold start-->
                    <!-- <div class="col-md-6 col-lg-6 mb-1">
                        <label class="form-label">Reorder max hold</label>
                        <input [readOnly]="isReorderMaxSelected" formControlName="reorder_max_hold" type="number"
                            class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                            placeholder="Enter Reorder max hold" autocomplete="off" />
                        <small>Max duration to hold early packets (milliseconds) </small>
                        <small class="text-danger">
                            {{errors?.reorder_max_hold?.join(' ')}}
                        </small>
                    </div> -->
                    <!--Reorder max hold End-->
                    <!-- Batched leg send operations start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="batched_leg_tx" type="checkbox"
                                name="Batched leg send operations" id="Batched-leg-send-operations">
                            <label class="m-2 form-label"> Batched leg send operations
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Improve CPU utilization at the cost of up to 1ms extra latency on any sent packet"></span>
                            </label>
                        </div>
                        <small class="text-danger" *ngIf="errors && errors['batched_leg_tx']">
                            {{errors['batched_leg_tx']?.join(' ')}}
                        </small>
                    </div>
                    <!-- Batched leg send operations End-->
                    <!--Send jitter buffer start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="send_jitter_buffer" type="checkbox"
                                name="Send jitter buffe" id="send-jitter-buffe">
                            <label class="m-2 form-label"> Send jitter buffer
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Delay low-latency links to match high-latency links"></span>
                            </label>
                        </div>
                        <small class="text-danger">
                            {{errors?.send_jitter_buffer?.join(' ')}}
                        </small>
                    </div>
                    <!--Send jitter buffer End-->
                    <!-- Manage process affinity  start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="manage_process_affinity" type="checkbox"
                                name="Manage process affinity" id="Manage-process-affinity">
                            <label class="m-2 form-label"> Manage process affinity </label>
                            <small class="text-danger">
                                {{errors?.manage_process_affinity?.join(' ')}}
                            </small>
                        </div>
                    </div>
                    <!-- Manage process affinity End-->
                    <!-- Source address verification start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="automatic_source_ip" type="checkbox"
                                name="automatic_source_ip" id="Source address verification">
                            <label class="m-2 form-label"> Automatic source IP </label>
                        </div>
                        <small class="text-danger">
                            {{errors?.automatic_source_ip?.join(' ')}}
                        </small>
                    </div>
                    <!--Source address verification End-->
                    <!-- Automatic ping timing start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="automatic_ping_timing" type="checkbox"
                                name="Automatic ping timing" id="Automatic ping timing">
                            <label class="m-2 form-label"> Automatic ping timing
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Automatically set ping and failure intervals"></span>
                            </label>
                        </div>
                        <small class="text-danger">
                            {{errors?.automatic_ping_timing?.join(' ')}}
                        </small>
                    </div>
                    <!--Automatic ping timing End-->
                    <!-- Note start-->
                    <div class="col-md-6 col-lg-6 mb-3">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Note</label>
                            <input formControlName="note" type="text" name="Note" placeholder="Enter Note"
                                class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.note?.join(' ')}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'note')">
                                    Note is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--  Note End-->
                    <!-- Dns servers start-->
                    <div class="col-md-6 col-lg-6 mb-3">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Dns servers
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="List of DNS servers that will be used for name resolution of the management and
                                    update servers. Bond defaults DNS servers will be used if no DNS server is
                                    set."></span>
                            </label>
                            <input formControlName="dns_servers" type="text"
                                class="form-control form-control-lg form-control-solid" name="Dns servers"
                                placeholder="Enter Dns servers" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.non_field_errors ? errors?.non_field_errors : errors?.dns_servers[0]}}
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'dns_servers')">
                                    DNS Server is invalid
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--  Dns servers End-->
                    <!--Tunnel affinity core start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Tunnel affinity core
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Which CPU core to bind the tunnel process to, where 1 is the first CPU core"></span>
                            </label>
                            <input class="form-control form-control-lg form-control-solid" type="number"
                                formControlName="tunnel_affinity_core">
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.tunnel_affinity_core?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!-- Tunnel affinity core End-->
                    <!-- Metric collection interval start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Metric collection interval</label>
                            <input type="number" formControlName="metric_collection_interval"
                                class="form-control form-control-lg form-control-solid"
                                name="Metric collection interval" placeholder="Enter Metric collection interval"
                                autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.metric_collection_interval?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--Metric collection interval End-->
                    <!-- Metric reporting interval start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Metric reporting interval</label>
                            <input formControlName="metric_reporting_interval" type="number"
                                class="form-control form-control-lg form-control-solid" name="Metric reporting interval"
                                placeholder="Enter Metric reporting interval" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.metric_reporting_interval?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--Metric reporting interval End-->
                    <!-- TCP Congestion control algorithm start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">TCP Congestion control algorithm
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Algorithm to use for TCP congestion control."></span>
                            </label>
                            <ng-select class="dropdown-block" formControlName="tcp_congestion_control_algorithm"
                                placeholder="Conservative">
                                <ng-option *ngFor="let item of bridgeCongetionAlgorithms" [value]="item.key">
                                    {{item.title}}
                                </ng-option>
                            </ng-select>
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.tcp_congestion_control_algorithm?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!-- TCP Congestion control algorithm End-->
                    <!-- Web server start-->
                    <!-- <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="web_server" type="checkbox"
                                name="Web server" id="Webserver">
                            <label class="m-2 form-label"> Web server </label>
                        </div>
                        <small>Enable node web server. </small>
                        <small class="text-danger">
                            {{errors?.web_server?.join(' ')}}
                        </small>
                    </div> -->
                    <!--Web server End-->
                    <!-- Regular leg ping time start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Regular leg ping time
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Send pings this often on regular legs (milliseconds)"></span>
                            </label>
                            <input formControlName="regular_leg_ping_time" type="number"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Regular leg ping time" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.regular_leg_ping_time?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!-- Regular leg ping time End-->
                    <!-- Regular leg fail time start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Regular leg fail time
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Consider regular legs down after this receive idle time (milliseconds)"></span>
                            </label>
                            <input type="number" formControlName="regular_leg_fail_time"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Regular leg fail time" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.regular_leg_fail_time?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--Regular leg fail time End-->
                    <!-- Failover leg ping time start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Failover leg ping time
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Send pings this often on failover leg (milliseconds)"></span>
                            </label>
                            <input type="number" formControlName="failover_leg_ping_time"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Enter Regular leg fail time" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.failover_leg_ping_time?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--Failover leg fail time End-->
                    <!-- Failover leg fail time start-->
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Failover leg fail time
                                <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                    ngbTooltip="Consider failover leg down after this receive idle time (milliseconds)"></span>
                            </label>
                            <input formControlName="failover_leg_fail_time" type="number"
                                class="form-control form-control-lg form-control-solid" name="removeLogsInDay"
                                placeholder="Failover leg fail time" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger">
                                    {{errors?.failover_leg_fail_time?.join(' ')}}
                                </span>
                            </small>
                        </div>
                    </div>
                    <!--Failover leg fail time End-->
                    <!-- Node debug  start-->
                    <!-- <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" formControlName="node_debug" type="checkbox"
                                name="Node debug" id="Node-debug">
                            <label class="m-2 form-label">Node debug </label>
                        </div>
                        <small> Enable debug logging for core node services. </small>
                        <small class="text-danger">
                            {{errors?.node_debug?.join(' ')}}
                        </small>
                    </div> -->
                    <!-- Node debug End-->
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="bondGeoForm" *ngIf="option == 7">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Latitude</label>
                            <input formControlName="lat" type="number"
                                class="form-control form-control-lg form-control-solid" name="Latitude"
                                placeholder="Latitude" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'lat')">
                                    Latitude is required
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Longitude</label>
                            <input formControlName="lang" type="number"
                                class="form-control form-control-lg form-control-solid" name="Longitude"
                                placeholder="Longitude" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'lang')">
                                    Longitude is required
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Save
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>