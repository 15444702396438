import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-recursive-space',
  templateUrl: './recursive-space.component.html',
  styleUrls: ['./recursive-space.component.scss']
})
export class RecursiveSpaceComponent implements OnInit {
  @Input() bondDetails: any;
  @Input() selectedOption: any;

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void { }

  change(key: any) {
    let selectedSpace = {};
    this.bondDetails.forEach((item: any) => { if (item.key == key) selectedSpace = item; });
    this.sharedService.selectedSpaceSubject.next({ data: selectedSpace });
  }

}
