<div class="modal-header px-5 py-3">
  <h5 class="modal-title"> {{isEdit ? 'Edit': 'Add'}} Filter </h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body p-5">
  <div class="card mb-5 mb-xl-0">
    <form [formGroup]="addFilter">
      <div class="card mb-5 mb-xl-5">
        <div class="card-body p-0">
          <div class="row" *ngIf="errors?.non_field_errors">
            <div class="col-md-12">
              <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
            </div>
          </div>
          <div class="row" *ngIf="errors && !errors['non_field_errors']">
            <div class="col-md-12">
              <div class="alert alert-danger">Please correct the errors below.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6 mb-1">
              <div class="form-group inputtext-block mb-5">
                <label class="form-label">Name <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                    class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                    ngbTooltip="A short description of this filter's purpose."></span> </label>
                <input formControlName="name" name="name" type="text"
                  class="form-control form-control-lg form-control-solid" name="Name" placeholder="Name"
                  autocomplete="off" />
                <!-- <small >A short description of this filter's purpose.</small> -->
                <small class="form-invalid-message">
                  <span class="text-danger"
                    *ngIf="addFilter.controls.name.errors && !controlHasError('pattern', 'name')">
                    {{addFilter.controls.name.errors | json}}
                  </span>
                  <span class="text-danger" *ngIf="controlHasError('pattern', 'name')">
                    Name is invalid
                  </span>
                </small>
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-0"> Rules </h5>
              </div>
              <div>
                <button type="button" class="btn btn-primary btn-style" (click)="onAddospfRules()">
                  <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                      class="svg-icon svg-icon-block mb-2">
                    </span>
                  </div> Add Rule
                </button>
              </div>
            </div>
            <small class="text-danger" *ngIf="addFilter?.controls?.rules?.errors">
              {{addFilter.controls.rules.errors}}
            </small>
            <div formArrayName="rules">
              <div [formGroupName]="i" class="row" *ngFor="let x of ospfRules?.controls;let i=index">
                <div class="col-md-12 mt-5">
                  <div class="row br-6 border border-gray-300 m-0 p-5 rules-block">
                    <div class="col-md-12 btn-remove-rule">
                      <div class="index-block">
                        <span> {{i + 1 }} </span>
                      </div>
                      <div>
                        <button type="button" class="btn btn-danger btn-icon-1" (click)="onRemoveospfRules(i)"
                          title="Remove Rule">
                          <div>
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                              class="svg-icon svg-icon-block">
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 mb-1">
                      <div class="form-group inputtext-block mb-5">
                        <label class="form-label">Description
                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                            ngbTooltip="A short description of this rule's purpose."></span>
                        </label>
                        <input formControlName="description" name="description" type="text"
                          class="form-control form-control-lg form-control-solid" placeholder="" autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="x?.controls?.description?.errors">
                            {{x.controls.description.errors}}
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-12 mb-5">
                      <div class="row">
                        <div class="col-md-12  d-flex justify-content-between align-items-center my-2">
                          <div>
                            <h5 class="mb-0"> Matches </h5>
                          </div>
                          <div>
                            <button type="button" class="btn btn-primary btn-style" (click)="onAddospfmatches(i)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                  class="svg-icon svg-icon-block mb-2">
                                </span>
                              </div> Add Matches
                            </button>
                          </div>
                        </div>
                        <small class="text-danger" *ngIf="x?.controls?.matches?.errors">
                          {{x.controls.matches.errors}}
                        </small>
                        <div formArrayName="matches">
                          <div [formGroupName]="matchesi" class="row mt-3"
                            *ngFor="let matchControl of getOspfmatches(i)?.controls;let matchesi=index">
                            <div class="col-md-12 col-lg-12 mb-1">
                              <div class="row border border-gray-300 br-6 m-0 p-5 matches-block">
                                <div class="btn-remove-match">
                                  <button type="button" class="btn btn-danger btn-icon-1"
                                    (click)="onRemoveospfmatches(i,matchesi)" title="Remove Matches">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                        class="svg-icon svg-icon-block">
                                      </span>
                                    </div>
                                  </button>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Attribute
                                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="Which attribute to match."></span>
                                    </label>
                                    <ng-select formControlName="attribute" (change)="onChangeMatchAtt(i,matchesi)"
                                      class="dropdown-block" name="attribute">
                                      <ng-option value="net">Network</ng-option>
                                      <ng-option value="scope">Scope</ng-option>
                                      <ng-option value="preference">Preference</ng-option>
                                      <ng-option value="from">From</ng-option>
                                      <ng-option value="gw">Gateway</ng-option>
                                      <ng-option value="proto">Protocol</ng-option>
                                      <ng-option value="source">Source</ng-option>
                                      <ng-option value="dest">Destination</ng-option>
                                      <ng-option value="ifname">Interface name</ng-option>
                                      <ng-option value="ifindex">Interface index</ng-option>
                                      <ng-option value="igp_metric">IGP metric</ng-option>
                                      <ng-option value="babel_metric">Babel metric</ng-option>
                                      <ng-option value="bgp_path.first">BGP path (first)</ng-option>
                                      <ng-option value="bgp_path.last">BGP path (last)</ng-option>
                                      <ng-option value="bgp_path.last_nonaggregated">BGP path (last-nonaggregated)
                                      </ng-option>
                                      <ng-option value="bgp_path.len">BGP path length</ng-option>
                                      <ng-option value="bgp_path.matches">BGP path matches</ng-option>
                                      <ng-option value="bgp_local_pref">BGP local preference</ng-option>
                                      <ng-option value="bgp_med">BGP med</ng-option>
                                      <ng-option value="bgp_origin">BGP origin</ng-option>
                                      <ng-option value="bgp_next_hop">BGP next hop</ng-option>
                                      <ng-option value="bgp_atomic_aggr">BGP atomic aggregate</ng-option>
                                      <ng-option value="bgp_community.len">BGP community length</ng-option>
                                      <ng-option value="bgp_ext_community.len">BGP ext community length</ng-option>
                                      <ng-option value="bgp_large_community.len">BGP large community length</ng-option>
                                      <ng-option value="bgp_originator_id">BGP originator ID</ng-option>
                                      <ng-option value="bgp_cluster_list.len">BGP cluster list length</ng-option>
                                      <ng-option value="ospf_metric1">OSPF metric 1</ng-option>
                                      <ng-option value="ospf_metric2">OSPF metric 2</ng-option>
                                      <ng-option value="ospf_tag">OSPF tag</ng-option>
                                      <ng-option value="ospf_router_id">OSPF router ID</ng-option>
                                      <ng-option value="krt_source">Kernel routing table source</ng-option>
                                      <ng-option value="krt_metric">Kernel routing table metric</ng-option>
                                      <ng-option value="krt_prefsrc">Kernel routing table prefsrc</ng-option>
                                      <ng-option value="krt_realm">Kernel routing table realm</ng-option>
                                      <ng-option value="krt_scope">Kernel routing table scope</ng-option>
                                    </ng-select>
                                    <small class="form-invalid-message">
                                      <span class="text-danger" *ngIf="matchControl?.get('attribute')?.errors">
                                        {{matchControl?.get('attribute')?.errors}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-3 mb-1">
                                  <label class="form-label">Negate
                                    <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                      class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                      ngbTooltip="Match on false instead of true."></span>
                                  </label>
                                  <div class="d-flex flex-column mt-2">
                                    <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                      formControlName="negate" name="negate"></ui-switch>
                                    <small class="text-danger" *ngIf="matchControl?.get('negate')?.errors">
                                      {{matchControl?.get('negate')?.errors}}
                                    </small>
                                  </div>
                                </div>

                                <div formArrayName="value" *ngIf="matchControl?.value.attribute != 'proto' && matchControl?.value.attribute != 'ifname' &&
                                      matchControl?.value.attribute != 'bgp_atomic_aggr'" class="row">
                                  <small class="text-danger" *ngIf="matchControl?.get('value')?.errors">
                                    {{matchControl?.get('value')?.errors}}
                                  </small>
                                  <div [formGroupName]="valuesi" class="col-lg-6 col-md-6 col-12 mb-1 
                                  d-flex align-items-center gap-3"
                                    *ngFor="let valueControl of getOspfValues(i,matchesi)?.controls;let valuesi=index">
                                    <div class="form-group inputtext-block mb-1 w-100">
                                      <label class="form-label me-5"> Value {{valuesi+1}}
                                        <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                          class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                          placement="top"
                                          ngbTooltip="One of the values to match for this attribute."></span>
                                      </label>
                                      <div class="d-flex w-100">
                                        <div class="d-flex flex-column mb-5 w-100">
                                          <input formControlName="value" name="value" type="text"
                                            class="form-control form-control-lg form-control-solid" name="Value"
                                            placeholder="" autocomplete="off" />
                                          <small class="mt-2" *ngIf="matchControl?.value.attribute == 'scope'">
                                            Valid choices are "SCOPE_HOST", "SCOPE_LINK", "SCOPE_SITE",
                                            "SCOPE_ORGANIZATION", and "SCOPE_UNIVERSE"</small>
                                          <small class="mt-2" *ngIf="matchControl?.value.attribute == 'source'">
                                            Valid choices are "RTS_DUMMY", "RTS_STATIC", "RTS_INHERIT", "RTS_DEVICE",
                                            "RTS_STATIC_DEVICE", "RTS_REDIRECT", "RTS_RIP", "RTS_OSPF", "RTS_OSPF_IA",
                                            "RTS_OSPF_EXT1", "RTS_OSPF_EXT2", "RTS_BGP", "RTS_PIPE", and
                                            "RTS_BABEL"</small>
                                          <small class="mt-2" *ngIf="matchControl?.value.attribute == 'dest'">Valid
                                            choices are "RTD_UNICAST", "RTD_BLACKHOLE", "RTD_UNREACHABLE",
                                            "RTD_PROHIBIT"</small>
                                          <small class="mt-2"
                                            *ngIf="matchControl?.value.attribute == 'bgp_origin'">Valid
                                            choices are "ORIGIN_IGP", "ORIGIN_EGP", and "ORIGIN_INCOMPLETE"</small>
                                          <small class="text-danger" *ngIf="valueControl?.errors">
                                            {{valueControl?.errors}}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div [ngClass]="matchControl?.value.attribute == 'scope'? 'mb-10' : 
                                        matchControl?.value.attribute == 'source' ? 'mb-15' : 
                                        matchControl?.value.attribute == 'dest' ? 'mb-10' : 
                                        matchControl?.value.attribute == 'bgp_origin' ? 'mb-5' : 'mt-2'">
                                      <button type="button" class="btn btn-danger btn-icon-1"
                                        (click)="onRemoveospfvalues(i,matchesi,valuesi)">
                                        <div>
                                          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                            class="svg-icon svg-icon-block">
                                          </span>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1"
                                  *ngIf="matchControl?.value.attribute == 'proto' || matchControl?.value.attribute == 'ifname'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Value
                                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="The value of the attribute to match."></span>
                                    </label>
                                    <input formControlName="value1" name="value" type="text"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger" *ngIf="matchControl?.get('value')?.errors">
                                        {{matchControl?.get('value')?.errors}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1"
                                  *ngIf="matchControl?.value.attribute == 'bgp_atomic_aggr'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Value
                                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="Checked means that the route has been aggregated from multiple
                                          routes by some router on the path from the originator, unchecked means that this
                                          is strictly not true"></span>
                                    </label>
                                    <div class="d-flex flex-column">
                                      <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"
                                        formControlName="value1" name="value1"></ui-switch>
                                      <small class="text-danger" *ngIf="matchControl?.get('value')?.errors">
                                        {{matchControl?.get('value')?.errors}}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <button type="button" class="btn btn-primary btn-style" *ngIf="matchControl?.value.attribute != 'proto' && matchControl?.value.attribute != 'ifname' &&
                                    matchControl?.value.attribute != 'bgp_atomic_aggr'"
                                    (click)="onAddospfvalues(i,matchesi)">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                        class="svg-icon svg-icon-block mb-2">
                                      </span>
                                    </div> Add Value
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 d-flex justify-content-between align-items-center my-2">
                          <div>
                            <h5 class="mb-0"> Actions </h5>
                          </div>
                          <div>
                            <button type="button" class="btn btn-primary btn-style" (click)="onAddospfactions(i)">
                              <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                  class="svg-icon svg-icon-block mb-2">
                                </span>
                              </div> Add Actions
                            </button>
                          </div>
                        </div>
                        <small class="text-danger" *ngIf="x?.controls?.actions?.errors">
                          {{x.controls.actions.errors}}
                        </small>
                        <div formArrayName="actions">
                          <div [formGroupName]="actionsi" class="row mt-3"
                            *ngFor="let actionControl of getOspfactions(i)?.controls;let actionsi=index">
                            <div class="col-md-12 col-lg-12 mb-1">
                              <div class="row border border-gray-300 br-6 m-0 p-5 actions-block">
                                <div class="btn-remove-action">
                                  <button type="button" class="btn btn-danger btn-icon-1"
                                    (click)="onRemoveospfactions(i,actionsi)" title="Remove Actions">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                        class="svg-icon svg-icon-block">
                                      </span>
                                    </div>
                                  </button>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1 mt-2">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Action
                                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="The type of action."></span>
                                    </label>
                                    <ng-select class="dropdown-block" placeholder="" formControlName="action"
                                      name="action" [clearable]="false" [searchable]="false">
                                      <ng-option value="accept">Accept</ng-option>
                                      <ng-option value="reject">Reject</ng-option>
                                      <ng-option value="modify">Modify</ng-option>
                                      <ng-option value="log">Log</ng-option>
                                    </ng-select>
                                    <small class="form-invalid-message">
                                      <span class="text-danger" *ngIf="actionControl?.get('action')?.errors">
                                        {{actionControl?.get('action')?.errors}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1 mt-2" *ngIf="actionControl.value.action =='modify' ">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Attribute
                                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="Which attribute to match."></span>
                                    </label>
                                    <ng-select clrselect="" formControlName="attribute"
                                      (change)="onChangeActionAtt(i,actionsi)" class="dropdown-block" name="attribute">
                                      <!-- <ng-option value="net">Network</ng-option> -->
                                      <ng-option value="scope">Scope</ng-option>
                                      <ng-option value="preference">Preference</ng-option>
                                      <ng-option value="from">From</ng-option>
                                      <ng-option value="gw">Gateway</ng-option>
                                      <!-- <ng-option value="proto">Protocol</ng-option> -->
                                      <ng-option value="source">Source</ng-option>
                                      <ng-option value="dest">Destination</ng-option>
                                      <ng-option value="ifname">Interface name</ng-option>
                                      <ng-option value="ifindex">Interface index</ng-option>
                                      <ng-option value="igp_metric">IGP metric</ng-option>
                                      <ng-option value="babel_metric">Babel metric</ng-option>
                                      <ng-option value="bgp_path.empty">BGP path (empty)</ng-option>
                                      <ng-option value="bgp_path.prepend">BGP path (prepend)</ng-option>
                                      <ng-option value="bgp_path.delete">BGP path (delete)</ng-option>
                                      <ng-option value="bgp_path.filter">BGP path (filter)
                                      </ng-option>
                                      <!-- <ng-option value="bgp_path.len">BGP path length</ng-option>
                                        <ng-option value="bgp_path.matches">BGP path matches</ng-option> -->
                                      <ng-option value="bgp_local_pref">BGP local preference</ng-option>
                                      <ng-option value="bgp_med">BGP med</ng-option>
                                      <ng-option value="bgp_origin">BGP origin</ng-option>
                                      <ng-option value="bgp_next_hop">BGP next hop</ng-option>
                                      <!-- <ng-option value="bgp_atomic_aggr">BGP atomic aggregate</ng-option> -->
                                      <ng-option value="bgp_community.empty">BGP community (empty)</ng-option>
                                      <ng-option value="bgp_community.add">BGP community (add)</ng-option>
                                      <ng-option value="bgp_community.delete">BGP community (delete)</ng-option>
                                      <ng-option value="bgp_community.filter">BGP community (filter)</ng-option>
                                      <ng-option value="bgp_ext_community.empty">BGP ext community (empty)</ng-option>
                                      <ng-option value="bgp_ext_community.add">BGP ext community (add)</ng-option>
                                      <ng-option value="bgp_ext_community.delete">BGP ext community
                                        (delete)</ng-option>
                                      <ng-option value="bgp_ext_community.filter">BGP ext community
                                        (filter)</ng-option>
                                      <ng-option value="bgp_large_community.empty">BGP large community (empty)
                                      </ng-option>
                                      <ng-option value="bgp_large_community.add">BGP large community (add)</ng-option>
                                      <ng-option value="bgp_large_community.delete">BGP large community (delete)
                                      </ng-option>
                                      <ng-option value="bgp_large_community.filter">BGP large community (filter)
                                      </ng-option>
                                      <ng-option value="bgp_originator_id">BGP originator ID</ng-option>
                                      <ng-option value="bgp_cluster_list.empty">BGP cluster list (empty)</ng-option>
                                      <ng-option value="bgp_cluster_list.add">BGP cluster list (add)</ng-option>
                                      <ng-option value="bgp_cluster_list.delete">BGP cluster list (delete)</ng-option>
                                      <ng-option value="bgp_cluster_list.filter">BGP cluster list (filter)</ng-option>
                                      <ng-option value="ospf_metric1">OSPF metric 1</ng-option>
                                      <ng-option value="ospf_metric2">OSPF metric 2</ng-option>
                                      <ng-option value="ospf_tag">OSPF tag</ng-option>
                                      <ng-option value="krt_metric">Kernel routing table metric</ng-option>
                                      <ng-option value="krt_prefsrc">Kernel routing table prefsrc</ng-option>
                                      <ng-option value="krt_realm">Kernel routing table realm</ng-option>
                                      <ng-option value="krt_scope">Kernel routing table scope</ng-option>
                                    </ng-select>
                                    <small class="form-invalid-message">
                                      <span class="text-danger" *ngIf="actionControl?.get('attribute')?.errors">
                                        {{actionControl?.get('attribute')?.errors}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1"
                                  *ngIf="actionControl.value.action =='modify' &&  actionControl.value.attribute !='bgp_path.empty' &&
                                    actionControl.value.attribute !='bgp_path.filter' && actionControl.value.attribute !='bgp_path.delete' &&
                                    actionControl.value.attribute !='bgp_community.empty' && actionControl.value.attribute !='bgp_community.add' &&
                                    actionControl.value.attribute !='bgp_community.delete' &&  actionControl.value.attribute !='bgp_community.filter' &&
                                    actionControl.value.attribute !='bgp_ext_community.empty' &&  actionControl.value.attribute !='bgp_ext_community.add' &&
                                    actionControl.value.attribute !='bgp_ext_community.delete' &&  actionControl.value.attribute !='bgp_ext_community.filter' &&
                                    actionControl.value.attribute !='bgp_large_community.empty' && actionControl.value.attribute !='bgp_large_community.add' &&
                                    actionControl.value.attribute !='bgp_large_community.delete' && actionControl.value.attribute !='bgp_large_community.filter' &&
                                    actionControl.value.attribute !='bgp_cluster_list.empty' && actionControl.value.attribute !='bgp_cluster_list.filter'">
                                  <div class="form-group inputtext-block mb-1">
                                    <label class="form-label">Value
                                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="What to modify the attribute to."></span>
                                    </label>
                                    <div class="d-flex flex-column">
                                      <input formControlName="value" name="value" type="text"
                                        class="form-control form-control-lg form-control-solid" name="Name"
                                        placeholder="Value" autocomplete="off" />
                                      <small class="mt-2" *ngIf="actionControl.value.attribute=='scope'">
                                        Valid choices are "RTS_DUMMY", "RTS_STATIC", "RTS_INHERIT", "RTS_DEVICE",
                                        "RTS_STATIC_DEVICE", "RTS_REDIRECT", "RTS_RIP", "RTS_OSPF", "RTS_OSPF_IA",
                                        "RTS_OSPF_EXT1", "RTS_OSPF_EXT2", "RTS_BGP", "RTS_PIPE", and "RTS_BABEL"
                                      </small>
                                      <small class="mt-2" *ngIf="actionControl.value.attribute=='source'">
                                        Valid choices are "RTS_DUMMY", "RTS_STATIC", "RTS_INHERIT", "RTS_DEVICE",
                                        "RTS_STATIC_DEVICE", "RTS_REDIRECT", "RTS_RIP", "RTS_OSPF", "RTS_OSPF_IA",
                                        "RTS_OSPF_EXT1", "RTS_OSPF_EXT2", "RTS_BGP", "RTS_PIPE", and "RTS_BABEL"
                                      </small>
                                      <small class="mt-2" *ngIf="actionControl.value.attribute=='dest'">
                                        Valid choices are "RTD_BLACKHOLE", "RTD_UNREACHABLE", "RTD_PROHIBIT"
                                      </small>
                                      <small class="mt-2" *ngIf="actionControl.value.attribute=='bgp_origin'">
                                        Valid choices are "ORIGIN_IGP", "ORIGIN_EGP", and "ORIGIN_INCOMPLETE"
                                      </small>
                                      <small class="text-danger" *ngIf="actionControl?.get('value')?.errors">
                                        {{actionControl?.get('value')?.errors}}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <!-- log -->
                                <div class="col-md-6 col-lg-6 mb-1 mt-2" *ngIf="actionControl.value.action =='log' ">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Message
                                      <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                        class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                                        ngbTooltip="The message to log."></span>
                                    </label>
                                    <input formControlName="message" name="message" type="text"
                                      class="form-control form-control-lg form-control-solid" name="Message"
                                      placeholder="Message" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger" *ngIf="actionControl?.get('message')?.errors">
                                        {{actionControl?.get('message')?.errors}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <!-- comunity -->
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_community.add' ||
                                    actionControl.value.attribute =='bgp_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Key</label>
                                    <input formControlName="key1" name="key1" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                          && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                          && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[0]">
                                        {{errors.rules[i].actions[actionsi].value[0]}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_community.add' ||
                                  actionControl.value.attribute =='bgp_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Value</label>
                                    <input formControlName="value1" name="value1" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                            && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                            && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[1]">
                                        {{errors.rules[i].actions[actionsi].value[1]}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <!-- large community -->
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_large_community.add' ||
                                    actionControl.value.attribute =='bgp_large_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Community ASN </label>
                                    <input formControlName="key2" name="key2" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[0]">
                                        {{errors.rules[i].actions[actionsi].value[0]}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_large_community.add' ||
                                  actionControl.value.attribute =='bgp_large_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Community Value 1
                                    </label>
                                    <input formControlName="value2" name="value2" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[1]">
                                        {{errors.rules[i].actions[actionsi].value[1]}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_large_community.add' ||
                                  actionControl.value.attribute =='bgp_large_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Community Value 2</label>
                                    <input formControlName="value22" name="value22" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[2]">
                                        {{errors.rules[i].actions[actionsi].value[2]?.join(' ')}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <!-- ext community -->
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_ext_community.add' ||
                                    actionControl.value.attribute =='bgp_ext_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Type ID</label>
                                    <input formControlName="type_id" name="type_id" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value.type_id">
                                        {{errors.rules[i].actions[actionsi].value.type_id}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_ext_community.add' ||
                                    actionControl.value.attribute =='bgp_ext_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Extended Community type</label>
                                    <ng-select formControlName="type" name="type" class="dropdown-block"
                                      [clearable]="false" [searchable]="false">
                                      <ng-option value="ro">Route origin</ng-option>
                                      <ng-option value="rt">Route target</ng-option>
                                      <ng-option value="generic">Generic</ng-option>
                                      <ng-option value="unknown">Unknown</ng-option>
                                    </ng-select>
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value.type">
                                        {{errors.rules[i].actions[actionsi].value.type}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_ext_community.add' ||
                                    actionControl.value.attribute =='bgp_ext_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Key</label>
                                    <input formControlName="key3" name="key3" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value.key">
                                        {{errors.rules[i].actions[actionsi].value.key?.join(' ')}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mb-1" *ngIf="actionControl.value.attribute =='bgp_ext_community.add' ||
                                    actionControl.value.attribute =='bgp_ext_community.delete'">
                                  <div class="form-group inputtext-block mb-5">
                                    <label class="form-label">Value</label>
                                    <input formControlName="value3" name="value3" type="number"
                                      class="form-control form-control-lg form-control-solid" autocomplete="off" />
                                    <small class="form-invalid-message">
                                      <span class="text-danger"
                                        *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value.value">
                                        {{errors.rules[i].actions[actionsi].value.value?.join(' ')}}
                                      </span>
                                    </small>
                                  </div>
                                </div>
                                <!-- array value -->
                                <div formArrayName="valueArray" *ngIf="actionControl?.value.attribute == 'bgp_large_community.filter' ||
                                  actionControl?.value.attribute == 'bgp_ext_community.filter' || actionControl?.value.attribute == 'bgp_community.filter' ||
                                  actionControl?.value.attribute == 'bgp_cluster_list.filter' ||  actionControl?.value.attribute == 'bgp_path.filter' ||
                                  actionControl?.value.attribute == 'bgp_path.delete'" class="mb-2">
                                  <small class="text-danger" *ngIf="actionControl?.get('value')?.errors">
                                    {{actionControl?.get('value')?.errors}}
                                  </small>
                                  <!-- array value bgp_path-->
                                  <div class="row" *ngIf="actionControl?.value.attribute == 'bgp_cluster_list.filter' ||  actionControl?.value.attribute == 'bgp_path.filter' 
                                      || actionControl?.value.attribute == 'bgp_path.delete'">
                                    <div [formGroupName]="valuesi" class="col-lg-4 col-md-4 col-12 mb-1 
                                    d-flex align-items-center gap-3"
                                      *ngFor="let valueControl of getActionValues(i,actionsi)?.controls;let valuesi=index">
                                      <div class="form-group inputtext-block mb-1 w-100">
                                        <label class="form-label me-5"> Value {{valuesi+1}}
                                          <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                            class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                            placement="top"
                                            ngbTooltip="One of the values to match for this attribute."></span>
                                        </label>
                                        <div class="d-flex w-100">
                                          <div class="d-flex flex-column mb-5 w-100">
                                            <input formControlName="value" name="value" type="text"
                                              class="form-control form-control-lg form-control-solid" name="value"
                                              placeholder="" autocomplete="off" />
                                            <small class="text-danger"
                                              *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]">
                                              {{errors.rules[i].actions[actionsi].value[valuesi]?.join(' ')}}
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="mt-2">
                                        <button type="button" class="btn btn-danger btn-icon-1"
                                          (click)="onRemoveactionvalues(i,actionsi,valuesi)">
                                          <div>
                                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                              class="svg-icon svg-icon-block">
                                            </span>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- array value bgp_ext_community-->
                                  <div
                                    *ngIf="actionControl?.value.attribute == 'bgp_large_community.filter' ||
                                      actionControl?.value.attribute == 'bgp_ext_community.filter' || actionControl?.value.attribute == 'bgp_community.filter'">
                                    <div [formGroupName]="valuesi" class="row border border-gray-300 br-6 mx-0 my-2 p-3"
                                      *ngFor="let valueControl of getActionValues(i,actionsi)?.controls;let valuesi=index">
                                      <!-- community -->
                                      <div class="col-md-6 col-lg-6 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Key</label>
                                          <input formControlName="key1" name="key1" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi][0]">
                                              {{errors.rules[i].actions[actionsi].value[valuesi][0]}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-5 col-lg-5 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Value</label>
                                          <input formControlName="value1" name="value1" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi][1]">
                                              {{errors.rules[i].actions[actionsi].value[valuesi][1]}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <!-- large community -->
                                      <div class="col-md-6 col-lg-6 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_large_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Community ASN</label>
                                          <input formControlName="key2" name="key2" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi][0]">
                                              {{errors.rules[i].actions[actionsi].value[valuesi][0]}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_large_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Community Value 1</label>
                                          <input formControlName="value2" name="value2" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi][1]">
                                              {{errors.rules[i].actions[actionsi].value[valuesi][1]}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_large_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Community Value 2</label>
                                          <input formControlName="value22" name="value22" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi][2]">
                                              {{errors.rules[i].actions[actionsi].value[valuesi][2]}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <!-- ext community -->
                                      <div class="col-md-6 col-lg-6 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_ext_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Type ID</label>
                                          <input formControlName="type_id" name="type_id" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                            && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                            && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                            && errors.rules[i].actions[actionsi].value[valuesi].type_id">
                                              {{errors.rules[i].actions[actionsi].value[valuesi].type_id}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_ext_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Extended Community Type</label>
                                          <ng-select formControlName="type" name="type" class="dropdown-block"
                                            [clearable]="false" [searchable]="false">
                                            <ng-option value="ro">Route origin</ng-option>
                                            <ng-option value="rt">Route target</ng-option>
                                            <ng-option value="generic">Generic</ng-option>
                                            <ng-option value="unknown">Unknown</ng-option>
                                          </ng-select>
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi].type">
                                              {{errors.rules[i].actions[actionsi].value[valuesi].type}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_ext_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Key</label>
                                          <input formControlName="key3" name="key3" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi].key">
                                              {{errors.rules[i].actions[actionsi].value[valuesi].key}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-5 col-lg-5 mb-1"
                                        *ngIf="actionControl.value.attribute =='bgp_ext_community.filter'">
                                        <div class="form-group inputtext-block mb-5">
                                          <label class="form-label">Value</label>
                                          <input formControlName="value3" name="value3" type="number"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete="off" />
                                          <small class="form-invalid-message">
                                            <span class="text-danger" *ngIf="errors && errors.rules && errors.rules[i]
                                              && errors.rules[i].actions  && errors.rules[i].actions[actionsi]
                                              && errors.rules[i].actions[actionsi].value && errors.rules[i].actions[actionsi].value[valuesi]
                                              && errors.rules[i].actions[actionsi].value[valuesi].value">
                                              {{errors.rules[i].actions[actionsi].value[valuesi].value}}
                                            </span>
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-1 col-lg-1 mt-5">
                                        <div class="form-group inputtext-block mt-3">
                                          <button type="button" class="btn btn-danger btn-icon-1"
                                            (click)="onRemoveactionvalues(i,actionsi,valuesi)">
                                            <div>
                                              <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                class="svg-icon svg-icon-block">
                                              </span>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12" *ngIf="actionControl?.value.attribute == 'bgp_large_community.filter' ||
                                    actionControl?.value.attribute == 'bgp_ext_community.filter' || actionControl?.value.attribute == 'bgp_community.filter' ||
                                    actionControl?.value.attribute == 'bgp_cluster_list.filter' ||  actionControl?.value.attribute == 'bgp_path.filter' ||
                                    actionControl?.value.attribute == 'bgp_path.delete'">
                                  <button type="button" class="btn btn-primary btn-style"
                                    (click)="onAddactionvalues(i,actionsi)">
                                    <div>
                                      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                        class="svg-icon svg-icon-block mb-2">
                                      </span>
                                    </div> Add Value
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer px-5 py-3">
  <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onsave()">
    <div>
      <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
      <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
        class="svg-icon svg-icon-block mb-2"></span>
    </div> {{isEdit ? 'Save': 'Add'}}
  </button>
  <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
    <div>
      <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-block mb-2"></span>
    </div> Cancel
  </button>
</div>