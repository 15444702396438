<div class="overflow-auto mt-0 card border-1 border bg-light border-bottom-0">
  <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
    <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6">
      <li class="nav-item" *ngIf="privateWANPermissionsObj?.view">
        <a (click)="onTabChange(1)" [class.active]="selectedChildTab==1" class="nav-link text-active-primary mx-0 px-5">
          <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'" class="svg-icon svg-icon-5 me-2 "></span>
          SD-WAN
        </a>
      </li>
      <li class="nav-item align-items-center"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
      <li class="nav-item" *ngIf="manageMeshRoutingPermissionsObj?.view && isSharedManageMashPermission">
        <a (click)="onTabChange(2)" [class.active]="selectedChildTab==2" class="nav-link text-active-primary mx-0 px-5">
          <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'"
            class="svg-icon svg-icon-5 me-2 "></span>Managed Mesh Routing </a>
      </li>
      <li class="nav-item align-items-center"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
      <li class="nav-item" *ngIf="privateWANRoutingPermissionsObj?.view">
        <a (click)="onTabChange(3)" [class.active]="selectedChildTab==3" class="nav-link text-active-primary mx-0 px-5">
          <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'" class="svg-icon svg-icon-5 me-2 "></span>
          Private
          WAN router rules </a>
      </li>
      <li class="nav-item align-items-center"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
      <li class="nav-item" *ngIf="privateWANCloudPermissionsObj?.view && IsManagedMesh">
        <a (click)="onTabChange(4)" [class.active]="selectedChildTab==4" class="nav-link text-active-primary mx-0 px-5">
          <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'" class="svg-icon svg-icon-5 me-2 "></span>
          Cloud Firewall </a>
      </li>
    </ul>
  </div>
</div>
<div class="row"
  *ngIf="ipList?.length + delIpList?.length <= 0 && spaceData
    && spaceData.private_wan && !spaceData.private_wan.enabled && manageMeshRoutingPermissionsObj?.view && isSharedManageMashPermission">
  <div class="col-md-12">
    <div class="alert alert-info">No rules can be added because the space has no available IP allocations. The IPs tab
      shows any networks that have been allocated to this space.
    </div>
  </div>
</div>
<!-- Private WAN HTML start -->
<ng-container *ngIf="selectedChildTab==1 && privateWANPermissionsObj?.view">
  <div class="card border-2 border border-top-0">
    <div class="card-header align-items-center px-5">
      <h2 class="mb-0 fs-3"> Private WAN </h2>
      <div style="float: right;">
        <button type="button" class="btn btn-primary btn-style"
          (click)="privateWANPermissionsObj?.update && isSharedSdwanEditPermission ? savePWANBasics(): ''"
          [ngClass]="!privateWANPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''"
          [disabled]="privateWANPermissionsObj?.update && isSharedSdwanEditPermission ? false : true">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
              class="svg-icon svg-icon-block mb-2"></span>
          </div> Save
        </button>
      </div>
    </div>

    <div class="card-body p-5">
      <div class="row" *ngIf="pWanError && pWanError?.non_field_errors">
        <div class="col-md-12">
          <div class="alert alert-danger">Unable to save space private WAN settings. There were problems validating the
            data. Please review and correct the issues below. <br>
            {{pWanError?.non_field_errors}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" id="pWanEnable" name="pWanEnable"
                [(ngModel)]="pWanEnable">
              <label class="mb-0 form-label ms-2" for="pWanEnable">
                Enable private WAN </label>
            </div>
          </div>
          <small>Use private WAN routing between sites </small>
        </div>

        <div class="col-md-12 col-lg-12 mb-5">
          <p> Private WAN is the feature that allows geographically separated sites to securely route traffic to each
            other as if each site was connected to the same router. It also allows access to the Internet to be
            centralized for all bonds in a space.</p>
          <p> Regardless of which mode is selected, enabling private WAN on a space will set up dynamic routing such
            that bonds in the space can route traffic between private WAN-enabled connected IPs and routes, providing
            the bonds are on the same aggregator.
          </p>
          <p> Communication between bonds on separate aggregators and routing to external gateways can be configured
            below, depending on the private WAN mode. </p>
          <!-- <a href="#" class="text-primary"> Continue reading </a> -->
        </div>
      </div>

    </div>

    <div class="card-header align-items-center border-top d-flex justify-content-between mb-0 px-5">
      <h2 class="mb-0 fs-4"> Mode </h2>
    </div>

    <div class="card-body p-5">
      <div class="row">

        <div class="col-md-12 col-lg-12 mb-5">
          <div class="d-flex">
            <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
              <input type="radio" name="optSpaceMode" id="radio1" value="with_pwan_routers" class="form-check-input"
                [checked]="pWanMode == 'with_pwan_routers'" (click)="changePWANMode('with_pwan_routers')">
              <span class="form-check-label form-label mb-0">With private WAN routers</span></label>
          </div>
          <small> With private WAN routers uses the gateways and rules from this page to route traffic via private WAN
            routers
          </small>
        </div>

        <div class="col-md-12 col-lg-12 mb-5">
          <div class="d-flex">
            <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
              <input type="radio" name="optSpaceMode" id="radio2" value="managed_mesh" class="form-check-input"
                [checked]="pWanMode == 'managed_mesh'" (click)="changePWANMode('managed_mesh')">
              <span class="form-check-label form-label mb-0">Managed mesh</span></label>
          </div>
          <small> Managed mesh configures VXLAN and Wireguard encrypted tunnels between all aggregators. Routing to
            external networks requires custom protocol configuration to be defined on at least one aggregator.
          </small>
        </div>

        <div class="col-md-12 col-lg-12 mb-5">
          <div class="d-flex">
            <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
              <input type="radio" name="optSpaceMode" id="radio3" value="unmanaged" class="form-check-input"
                [checked]="pWanMode == 'unmanaged'" (click)="changePWANMode('unmanaged')">
              <span class="form-check-label form-label mb-0">Unmanaged</span></label>
          </div>
          <small> Unmanaged requires custom protocols to be configured on every involved node to manage how traffic is
            routed between those nodes and external networks.
          </small>
        </div>

      </div>

    </div>

    <div class="card-header align-items-center border-top d-flex justify-content-between mb-0 px-5">
      <h2 class="mb-0 fs-4"> Managed Mesh Options
      </h2>
    </div>

    <div class="card-body p-5">
      <div class="row">
        <div class="col-md-12 col-lg-12 mb-5">
          <div class="d-flex align-items-center mt-3">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" id="pWanMesh" name="pWanMesh" [(ngModel)]="pWanMesh">
              <label class="mb-0 form-label ms-2" for="pWanMesh">
                Include aggregator interfaces assigned to this space in the mesh </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="card-footer p-5"> </div> -->
  </div>
</ng-container>

<!-- Private WAN HTML End -->

<!-- Aggregator routing HTML Start -->
<ng-container *ngIf="selectedChildTab==2 && manageMeshRoutingPermissionsObj?.view && isSharedManageMashPermission">
  <div class="card mb-5 mb-xl-8 border-1 border border-top-0" *ngIf="manageMeshRoutingPermissionsObj?.view">

    <div class="card-header align-items-center border-top d-flex justify-content-between px-5">
      <h2 class="mb-0 fs-3"> Aggregator routing </h2>
    </div>

    <div class="card-body p-5">
      <p> Interfaces, IP addresses, and routing protocols can be configured on a per-aggregator basis or configured
        per-routing group below. The following configuration applies in managed mesh mode only.
      </p>

      <div class="align-items-center d-flex justify-content-between mb-5 px-0">
        <h2 class="mb-0 fs-4"> Routing group VLAN assignments </h2>
        <button type="button" class="btn btn-primary btn-style"
          (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? editVlan(''): ''"
          [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''"
          [disabled]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? true : false">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
            </span>
          </div> Add
        </button>
      </div>
      <p> Defines which VLANs and IP pools to assign address on VLAN ports for aggregators in each routing group. </p>
      <div class="border" *ngIf="vlanAssignmentsList && vlanAssignmentsList.length > 0">
        <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-2 mb-0">
          <thead class="table-light">
            <tr class="fw-bolder text-dark fs-7">
              <th class="py-4">ID</th>
              <th class="py-4">Routing group </th>
              <th class="py-4">VLAN </th>
              <th class="py-4">IPv4 pool </th>
              <th class="py-4">IPv4 exclusions </th>
              <th class="py-4"> IPv6 pool </th>
              <th class="py-4"> IPv6 exclusions </th>
              <th class="py-4 min-w-50px w-50px text-center"> Actions </th>
            </tr>
          </thead>
          <tbody *ngIf="!isVlanLoader">
            <tr *ngFor="let item of vlanAssignmentsList; let i=index">
              <td> {{item.id}} </td>
              <td> <span class="badge badge-light-warning">{{item.routing_group_name || '-'}}</span></td>
              <td>{{item.vlan_id || '-'}}</td>
              <td> {{item.ipv4_address_pool || '-'}} </td>
              <td>
                <span *ngIf="item.ipv4_excluded_addresses && item.ipv4_excluded_addresses.length <= 0"> - </span>
                <div *ngFor="let ip of item.ipv4_excluded_addresses"> {{ip}} <br> </div>
              </td>
              <td> {{item.ipv6_address_pool || '-'}} </td>
              <td>
                <span *ngIf="item.ipv6_excluded_addresses && item.ipv6_excluded_addresses.length <= 0"> - </span>
                <div *ngFor="let ip of item.ipv6_excluded_addresses"> {{ip}} <br> </div>
              </td>
              <td class="text-center">
                <a title="Click to view Action" [matMenuTriggerFor]="lanActionMenu"
                  class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                  <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                    class="svg svg-icon svg-transparent">
                  </span>
                </a>
                <mat-menu #lanActionMenu="matMenu">
                  <button mat-menu-item class="menu-list-block" title="Edit"
                    (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? editVlan(item): ''"
                    [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''">
                    <div class="clone-icon">
                      <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                        class="svg svg-icon svg-icon-warning">
                      </span>
                    </div>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item class="menu-list-block" title="Delete"
                    (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? deleteVlan(item): ''"
                    [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''">
                    <div>
                      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                        class="svg svg-icon svg-icon-danger">
                      </span>
                    </div>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <app-preloader-table-list [length]="2" *ngIf="isVlanLoader"></app-preloader-table-list>
      </div>
    </div>

    <div class="separator  my-3 mb-0"></div>

    <div class="card-body p-5">
      <div class="align-items-center d-flex justify-content-between mb-5 px-0">
        <h2 class="mb-0 fs-4"> Gateway aggregators </h2>
        <button type="button" class="btn btn-primary btn-style"
          [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''"
          [disabled]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? true : false"
          (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addGateway(): ''">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
            </span>
          </div> Add
        </button>
      </div>

      <p>By default, an aggregator will not run a space unless a bond, interface, or protocol is assigned to it. A
        gateway aggregator will be configured to run the space regardless of whether the prior conditions are met.
      </p>

      <div class="border" *ngIf="gatewayAggList && gatewayAggList.length > 0">
        <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-2 mb-0">
          <thead class="table-light">
            <tr class="fw-bolder text-dark fs-7">
              <th class="py-4">Aggregator ID</th>
              <th class="py-4">Aggregator</th>
              <th class="py-4">Routing group </th>
              <th class="py-4 min-w-50px w-50px text-center"> Actions </th>
            </tr>
          </thead>
          <tbody *ngIf="!isGatewayLoader">
            <tr *ngFor="let item of gatewayAggList; let i=index">
              <td> {{item.aggregator_id}} </td>
              <td> {{item.aggregator_name}} </td>
              <td> {{item.routing_group_name}} </td>
              <td class="text-center">
                <a title="Click to view Action" [matMenuTriggerFor]="aggActionMenu"
                  class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                  <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                    class="svg svg-icon svg-transparent">
                  </span>
                </a>
                <mat-menu #aggActionMenu="matMenu">
                  <button mat-menu-item class="menu-list-block" title="Delete"
                    (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? deleteGateway(item) : ''"
                    [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''">
                    <div>
                      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                        class="svg svg-icon svg-icon-danger">
                      </span>
                    </div>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <app-preloader-table-list [length]="2" *ngIf="isGatewayLoader"></app-preloader-table-list>
      </div>
    </div>

    <div class="separator  my-3 mb-0"></div>

    <div class="card-body p-5">
      <div class="align-items-center d-flex justify-content-between mb-5 px-0">
        <h2 class="mb-0 fs-4"> Protocols </h2>
        <button type="button" class="btn btn-primary btn-style"
          (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addProtocol(''): ''"
          [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''"
          [disabled]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? true : false">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
            </span>
          </div> Add
        </button>
      </div>

      <p> Protocols will run on any aggregator in the assigned routing groups that have a VLAN trunk interface defined
        and either a bond, interface or protocol configured in this space, as well as any aggregators that are
        configured as gateway aggregators.
      </p>

      <div class="border" *ngIf="protocolsList && protocolsList.length > 0">
        <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-2 mb-0">
          <thead class="table-light">
            <tr class="fw-bolder text-dark fs-7">
              <th class="py-4">ID</th>
              <th class="py-4">Enabled</th>
              <th class="py-4">Name </th>
              <th class="py-4">Routing groups </th>
              <th class="py-4"> Protocol </th>
              <th class="py-4 min-w-100px w-100px text-center"> Actions </th>
            </tr>
          </thead>
          <tbody *ngIf="!isProtocolLoader">
            <tr *ngFor="let item of protocolsList; let i=index">
              <td> {{item.id}} </td>
              <td>
                <span *ngIf="item.enabled">
                  <a title="true">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'" class="svg-icon svg-icon-3">
                    </span>
                  </a>
                </span>
                <span *ngIf="!item.enabled">
                  <a title="false">
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                      class="svg-icon svg-icon-3 svg-icon-danger">
                    </span>
                  </a>
                </span>
              </td>
              <td> {{item.name}}</td>
              <td>
                <div *ngFor="let group of item.routing_groups"> {{group.routing_group_name}} </div>
              </td>
              <td> {{item.protocol}} </td>
              <td class="text-center">
                <a title="Click to view Action" [matMenuTriggerFor]="protocolActionMenu"
                  class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                  <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                    class="svg svg-icon svg-transparent">
                  </span>
                </a>
                <mat-menu #protocolActionMenu="matMenu">
                  <button mat-menu-item class="menu-list-block" title="Edit"
                    (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addProtocol(item): ''"
                    [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''">
                    <div class="clone-icon">
                      <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                        class="svg svg-icon svg-icon-warning">
                      </span>
                    </div>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item class="menu-list-block" title="Delete"
                    (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? deleteProtocol(item): ''"
                    [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''">
                    <div>
                      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                        class="svg svg-icon svg-icon-danger">
                      </span>
                    </div>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <app-preloader-table-list [length]="2" *ngIf="isProtocolLoader"></app-preloader-table-list>
      </div>
    </div>

    <div class="separator  my-3 mb-0"></div>

    <div class="card-body p-5">
      <div class="align-items-center d-flex justify-content-between mb-5 px-0">
        <h2 class="mb-0 fs-4"> Filters </h2>
        <button type="button" class="btn btn-primary btn-style"
          (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addFilter('') : ''"
          [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''"
          [disabled]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? true : false">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
            </span>
          </div> Add
        </button>
      </div>

      <div class="border" *ngIf="filtersList && filtersList.length > 0">
        <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0 table-fixed">
          <thead class="table-light">
            <tr class="fw-bolder text-dark fs-7 ">
              <th class="py-4">ID</th>
              <th class="py-4">Name</th>
              <th class="py-4 min-w-100px w-100px text-center"> Actions </th>
            </tr>
          </thead>
          <tbody *ngIf="!isFilterLoader">
            <tr *ngFor="let item of filtersList; let i=index">
              <td> {{item.id}} </td>
              <td>{{item.name}}</td>
              <td class="text-center">
                <a title="Click to view Action" [matMenuTriggerFor]="filterActionMenu"
                  class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                  <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                    class="svg svg-icon svg-transparent">
                  </span>
                </a>
                <mat-menu #filterActionMenu="matMenu">
                  <button mat-menu-item class="menu-list-block" title="Edit"
                    (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addFilter(item) : ''"
                    [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''">
                    <div class="clone-icon">
                      <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                        class="svg svg-icon svg-icon-warning">
                      </span>
                    </div>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item class="menu-list-block" title="Delete"
                    (click)="manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? deleteFilter(item) : ''"
                    [ngClass]="!manageMeshRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''">
                    <div>
                      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                        class="svg svg-icon svg-icon-danger">
                      </span>
                    </div>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <app-preloader-table-list [length]="2" *ngIf="isFilterLoader"></app-preloader-table-list>
      </div>
    </div>

  </div>
  <div *ngIf="!manageMeshRoutingPermissionsObj?.view">
    <div class="card mb-5 border-1 border border-top-0">
      <div class="card-body p-2">
        <div class="row g-5 g-xxl-8">
          <div class="col-md-12 col-lg-12 mb-5">
            <div>
              You have no permission to view the data. Kindly contact the authority.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Aggregator routing HTML End -->

<!-- Private WAN router rules HTML Start -->
<ng-container *ngIf="selectedChildTab==3 && privateWANRoutingPermissionsObj?.view">
  <div class="card mb-5 mb-xl-8 border-1 border border-top-0">

    <div class="align-items-center border-top d-flex justify-content-between mb-0 px-5 pt-3">
      <h2 class="mb-0 fs-3"> Private WAN router rules </h2>
      <div style="float: right;">
        <button *ngIf="wFrm1 == true || wFrm2 == true || wFrm3 == true || wFrm4 == true || wFrm5 == true" type="button"
          class="btn btn-primary btn-style"
          (click)="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? updateAllRouting() : ''"
          [disabled]="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? false : true"
          [ngClass]="!(privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission) ? 'disable-tune':''">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
              class="svg-icon svg-icon-block mb-2"></span>
          </div> Save
        </button>
        <button *ngIf="wFrm1 == false && wFrm2 == false && wFrm3 == false && wFrm4 == false && wFrm5 == false"
          type="button" class="btn btn-primary btn-style notallowIcon" disabled>
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
              class="svg-icon svg-icon-block mb-2"></span>
          </div> Save
        </button>
      </div>
    </div>

    <div class="separator  my-3 mb-0"></div>

    <div class="card-body p-0">
      <div class="row" *ngIf="this.aFrm1Error.length > 0 || this.aFrm2Error.length> 0
          || this.aFrm3Error.length> 0 || this.aFrm4Error.length> 0 || this.aFrm5Error.length> 0">
        <div class="col-md-12">
          <div class="alert alert-danger">Unable to save space private WAN settings. There were problems validating the
            data. Please review and correct the issues below.
          </div>
        </div>
      </div>
      <div class="row" *ngIf="this.isUndefinedError">
        <div class="col-md-12">
          <div class="alert alert-danger">Unable to save space private WAN settings. An unknown error occurred.
          </div>
        </div>
      </div>

      <div class="align-items-center d-flex justify-content-between mb-5 p-5 pb-0">
        <h2 class="mb-0 fs-3"> Gateways </h2>
        <div class="card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
          data-bs-placement="top" data-bs-trigger="hover" title="Add Gateway">
          <button type="button" class="btn btn-primary btn-style"
            [ngClass]="!(privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission) ? 'disable-tune':''"
            [disabled]="!(privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission) ? true : false"
            [ngbPopover]="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addGateway: ''">
            <div>
              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
              </span>
            </div> Add Gateway
          </button>
          <ng-template #addGateway>
            <ul class="p-0 mb-0">
              <li class="menu-item list-style-none">
                <span class="menu-link px-5" (click)="addGatewayRouterChange('1')"> NAT via PWAN router </span>
              </li>
              <li class="menu-item list-style-none">
                <span class="menu-link px-5" (click)="addGatewayRouterChange('2')"> Dedicated gateway via PWAN router
                </span>
              </li>
              <!-- <li class="menu-item list-style-none">
                <span class="menu-link px-5" (click)="addGatewayRouterChange('3')"> Dedicated gateway via bonder </span>
              </li> -->
            </ul>
          </ng-template>
        </div>
      </div>

      <form [formGroup]="wanForm1" class="px-5 gatewaysNAT">
        <div formArrayName="data">
          <p class="mt-5">Gateway rules control routing for traffic from bonds to default destinations such as the
            Internet. Traffic can be NAT'ed and forwarded via a PWAN router's default gateway, sent to a dedicated
            gateway accessible from a PWAN router, or sent to a dedicated gateway available at a specific bonded site.
          </p>
          <ng-container *ngIf="wanData1.length > 0">
            <h3 class="fs-5 text-muted"> NAT via PWAN router </h3>
            <div class="overflow-x ">
              <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-2 mb-0">
                <thead class="table-light">
                  <tr class="fw-bolder text-dark fs-7">
                    <th class="py-4"></th>
                    <th class="py-4">Enabled</th>
                    <th class="py-4">IP</th>
                    <th class="py-4 min-w-200px">Routing groups</th>
                    <th class="py-4"> Note </th>
                    <th class="py-4 min-w-50px w-50px text-center"
                      *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody [formGroupName]="i" *ngFor="let x of wanData1?.controls;let i=index">
                  <tr *ngIf="aFrm1Error  && aFrm1Error[i] && aFrm1Error[i].non_field_errors">
                    <td colspan="5">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="alert alert-danger"> {{aFrm1Error[i]?.non_field_errors?.join(' ')}} </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <!-- <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" name="natenbl" formControlName="natenbl">
                        <label class="mb-0 form-label ms-2"> </label>
                      </div> -->
                      <div class="form-group inputtext-block mb-2">
                        <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="natenbl">
                        </ui-switch>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="natip" formControlName="natip"
                          autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm1Error  && aFrm1Error[i] && aFrm1Error[i].ip">
                            {{aFrm1Error[i]?.ip?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <ng-select name="natrouting" formControlName="natrouting" class="select-width dropdown-block"
                          [multiple]="true" placeholder="No routing groups">
                          <ng-option *ngFor="let rgp of x.get('natRoutingList')?.value"
                            value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                        </ng-select>
                        <small class="form-invalid-message">
                          <span class="text-danger"
                            *ngIf="aFrm1Error  && aFrm1Error[i] && aFrm1Error[i].routing_groups">
                            {{aFrm1Error[i]?.routing_groups?.join(' ')}}
                          </span>
                          <span class="badge badge-light bg-light-warning text-warning white-space-normal p-0" *ngIf="!(aFrm1Error  && aFrm1Error[i] && aFrm1Error[i].routing_groups) 
                          && !x.value.isNew && x.value.natrouting?.length <= 0">
                            No routing groups- will not apply on any PWAN router
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="natnote"
                          formControlName="natnote" autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm1Error  && aFrm1Error[i] && aFrm1Error[i].note">
                            {{aFrm1Error[i]?.note?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                      <div class="form-group inputtext-block mb-2">
                        <button type="button" class="btn btn-danger btn-icon-1"
                          [hidden]="wanData1.controls[i].get('isDeleted')?.value" (click)="onToggle1(i)" title="Delete">
                          <div>
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                              class="svg-icon svg-icon-block">
                            </span>
                          </div>
                        </button>
                        <button type="button" class="btn btn-primary btn-icon-1"
                          [hidden]="!wanData1.controls[i].get('isDeleted')?.value" (click)="onToggle1(i)"
                          title="Restore">
                          <div>
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'"
                              class="svg-icon svg-icon-block">
                            </span>
                          </div>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </form>

      <form [formGroup]="wanForm2" class="px-5 gateways">
        <div formArrayName="data">
          <ng-container *ngIf="wanData2.length > 0">
            <div class="separator  my-5"></div>
            <h3 class="fs-5 text-muted"> Dedicated gateway via PWAN router </h3>
            <div class="overflow-x">
              <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2 mb-0">
                <thead class="table-light">
                  <tr class="fw-bolder text-dark fs-7 ">
                    <th class="py-4"></th>
                    <th class="py-4">Enabled</th>
                    <th class="py-4">IP</th>
                    <th class="py-4">Gateway</th>
                    <th class="py-4"> IPv6 </th>
                    <th class="py-4"> IPv6 gateway </th>
                    <th class="py-4"> VLAN </th>
                    <th class="py-4 min-w-200px"> Routing groups </th>
                    <th class="py-4"> Note </th>
                    <th class="py-4 min-w-50px w-50px text-center"
                      *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody [formGroupName]="i" *ngFor="let x of wanData2?.controls;let i=index">
                  <tr *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].non_field_errors">
                    <td colspan="9">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="alert alert-danger"> {{aFrm2Error[i]?.non_field_errors?.join(' ')}} </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="pwnenbl">
                        </ui-switch>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="pwnip" formControlName="pwnip"
                          autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].ip">
                            {{aFrm2Error[i]?.ip?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="pwngate"
                          formControlName="pwngate" autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].gateway">
                            {{aFrm2Error[i]?.gateway?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="pwnip6"
                          formControlName="pwnip6" autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].ipv6">
                            {{aFrm2Error[i]?.ipv6?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="pwnip6gate"
                          formControlName="pwnip6gate" autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].gateway6">
                            {{aFrm2Error[i]?.gateway6?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="pwnvlan"
                          formControlName="pwnvlan" autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].vlan">
                            {{aFrm2Error[i]?.vlan?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <ng-select name="pwnrouting" formControlName="pwnrouting" class="select-width dropdown-block"
                          [multiple]="true" placeholder="No routing groups">
                          <ng-option *ngFor="let rgp of x.get('pwnRoutingList')?.value"
                            value="{{rgp.url}}">{{rgp.display_name}}
                          </ng-option>
                        </ng-select>
                        <small class="form-invalid-message">
                          <span class="text-danger"
                            *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].routing_groups">
                            {{aFrm2Error[i]?.routing_groups?.join(' ')}}
                          </span>
                          <span class="badge badge-light bg-light-warning text-warning white-space-normal p-0" *ngIf="!(aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].routing_groups) 
                          && !x.value.isNew && x.value.pwnrouting?.length <= 0">
                            No routing groups- will not apply on any PWAN router
                          </span>
                        </small>
                      </div>
                    </td>
                    <td>
                      <div class="form-group inputtext-block mb-2">
                        <input type="text" class="form-control  form-control-solid" name="pwnnote"
                          formControlName="pwnnote" autocomplete="off" />
                        <small class="form-invalid-message">
                          <span class="text-danger" *ngIf="aFrm2Error  && aFrm2Error[i] && aFrm2Error[i].note">
                            {{aFrm2Error[i]?.note?.join(' ')}}
                          </span>
                        </small>
                      </div>
                    </td>
                    <td *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                      <div class="form-group inputtext-block mb-2">
                        <button type="button" class="btn btn-danger btn-icon-1"
                          [hidden]="wanData2.controls[i].get('isDeleted')?.value" (click)="onToggle2(i)" title="Delete">
                          <div>
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                              class="svg-icon svg-icon-block">
                            </span>
                          </div>
                        </button>
                        <button type="button" class="btn btn-primary btn-icon-1"
                          [hidden]="!wanData2.controls[i].get('isDeleted')?.value" (click)="onToggle2(i)"
                          title="Restore">
                          <div>
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'"
                              class="svg-icon svg-icon-block">
                            </span>
                          </div>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </form>

      <!-- <form [formGroup]="wanForm3" class="px-5 gateways">
        <div formArrayName="data">
          <ng-container *ngIf="wanData3.length > 0">
            <div class="separator  my-5"></div>
            <h3 class="fs-5 text-muted"> Dedicated gateway via bonder </h3>
            <div class="overflow-x">
              <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2">
                <thead class="table-light">
                  <tr class="fw-bolder text-dark fs-7 ">
                    <th class="py-4"></th>
                    <th class="py-4">Enabled</th>
                    <th class="py-4">Gateway</th>
                    <th class="py-4">IPv6 gateway </th>
                    <th class="py-4 min-w-200px"> Routing groups </th>
                    <th class="py-4"> Note </th>
                    <th class="py-4" *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody [formGroupName]="i" *ngFor="let x of wanData3?.controls;let i=index">
                  <tr *ngIf="aFrm3Error  && aFrm3Error[i] && aFrm3Error[i].non_field_errors">
                    <td colspan="6">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="alert alert-danger"> {{aFrm3Error[i]?.non_field_errors?.join(' ')}} </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" name="bndenbl" formControlName="bndenbl">
                        <label class="mb-0 form-label ms-2"> </label>
                      </div>
                    </td>
                    <td>
                      <input type="text" class="form-control  form-control-solid" name="bndgate"
                        formControlName="bndgate" autocomplete="off" />
                      <small class="text-danger" *ngIf="aFrm3Error  && aFrm3Error[i] && aFrm3Error[i].gateway">
                        {{aFrm3Error[i]?.gateway?.join(' ')}}
                      </small>
                    </td>
                    <td>
                      <input type="text" class="form-control  form-control-solid" name="bndip6gate"
                        formControlName="bndip6gate" autocomplete="off" />
                      <small class="text-danger" *ngIf="aFrm3Error  && aFrm3Error[i] && aFrm3Error[i].gateway6">
                        {{aFrm3Error[i]?.gateway6?.join(' ')}}
                      </small>
                    </td>
                    <td>
                      <ng-select name="bndrouting" formControlName="bndrouting" class="select-width" [multiple]="true"
                        placeholder="No routing groups">
                        <ng-option *ngFor="let rgp of x.get('bndRoutingList')?.value" value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                      </ng-select>
                      <small class="text-danger" *ngIf="aFrm3Error  && aFrm3Error[i] && aFrm3Error[i].routing_groups">
                        {{aFrm3Error[i]?.routing_groups?.join(' ')}}
                      </small>
                      <small class="badge badge-light mt-2 bg-light-warning text-warning p-1 mx-2"
                        *ngIf="!x.value.isNew && x.value.bndrouting?.length <= 0">
                        No routing groups- will not apply on any PWAN router
                      </small>
                    </td>
                    <td>
                      <input type="text" class="form-control  form-control-solid" name="bndnote"
                        formControlName="bndnote" autocomplete="off" />
                      <small class="text-danger" *ngIf="aFrm3Error  && aFrm3Error[i] && aFrm3Error[i].note">
                        {{aFrm3Error[i]?.note?.join(' ')}}
                      </small>
                    </td>
                    <td *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                      <a [hidden]="wanData3.controls[i].get('isDeleted')?.value" (click)="onToggle3(i)" title="Delete"
                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-40px w-40px">
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                          class="svg-icon svg-icon-3 svg-icon-danger"></span>
                      </a>
                      <a [hidden]="!wanData3.controls[i].get('isDeleted')?.value" (click)="onToggle3(i)" title="Restore"
                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-40px w-40px">
                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'"
                          class="svg-icon svg-icon-3 svg-icon-success"></span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </form> -->

      <div
        class="card-header align-items-center border-top d-flex justify-content-between mb-0 py-5 mt-5 border-bottom-0 pb-0">
        <h2 class="mb-0 fs-3"> NAT Rules </h2>
        <button type="button" class="btn btn-primary btn-style"
          (click)="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addNttForm() : ''"
          [ngClass]="!(privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission) ? 'disable-tune':''"
          [disabled]="!(privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission) ? true: false">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
            </span>
          </div> Add NAT rule
        </button>
      </div>

      <form [formGroup]="nttForm" class="px-5 nat-rules">
        <div formArrayName="data">
          <p> 1:1 NAT rules allow incoming traffic to a public IP address to be forwarded to a specific host in the PWAN
            space.
          </p>
          <div *ngIf="nttData.length > 0" class="overflow-x mb-5">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-1 gy-2">
              <thead class="table-light">
                <tr class="fw-bolder text-dark fs-7 ">
                  <th class="py-4"></th>
                  <th class="py-4"> Enable</th>
                  <th class="py-4">Public IP </th>
                  <th class="py-4">Private IP </th>
                  <th class="py-4 min-w-200px">Routing groups</th>
                  <th class="py-4"> Note </th>
                  <th class="py-4 min-w-50px w-50px text-center"
                    *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="dropNAT($event)">
                <tr [formGroupName]="i" *ngFor="let x of nttData?.controls;let i=index" cdkDrag cdkDragLockAxis="y">
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                  <td><a cdkDragHandle class="hoverGrab"><i class="fa fa-bars"></i></a></td>
                  <td>
                    <!-- <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" name="nttenbl" formControlName="nttenbl">
                      <label class="mb-0 form-label ms-2"> </label>
                    </div> -->
                    <div class="form-group inputtext-block mb-2">
                      <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="nttenbl">
                      </ui-switch>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid" name="nttpubip"
                        formControlName="nttpubip" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm4Error  && aFrm4Error[i] && aFrm4Error[i].public_ip">
                          {{aFrm4Error[i]?.public_ip?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid" name="nttpriip"
                        formControlName="nttpriip" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm4Error  && aFrm4Error[i] && aFrm4Error[i].private_ip">
                          {{aFrm4Error[i]?.private_ip?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <ng-select name="nttrouting" formControlName="nttrouting" class="select-width dropdown-block"
                        placeholder="No routing groups">
                        <ng-option *ngFor="let rgp of x.get('nttRoutingList')?.value"
                          value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                      </ng-select>
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm4Error  && aFrm4Error[i] && aFrm4Error[i].routing_groups">
                          {{aFrm4Error[i]?.routing_groups?.join(' ')}}
                        </span>
                        <span class="text-danger"
                          *ngIf="aFrm4Error  && aFrm4Error[i] && aFrm4Error[i].non_field_errors">
                          {{aFrm4Error[i]?.non_field_errors?.join(' ')}}
                        </span>
                        <span class="badge badge-light bg-light-warning text-warning white-space-normal p-0" *ngIf="!(aFrm4Error  && aFrm4Error[i] && (aFrm4Error[i].non_field_errors || aFrm4Error[i].routing_groups)) 
                          && !x.value.isNew && x.value.nttrouting?.length <= 0">
                          No routing groups- will not apply on any PWAN router
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid" name="nttnote"
                        formControlName="nttnote" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm4Error  && aFrm4Error[i] && aFrm4Error[i].note">
                          {{aFrm4Error[i]?.note?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                    <div class="form-group inputtext-block mb-2">
                      <button type="button" class="btn btn-danger btn-icon-1"
                        [hidden]="nttData.controls[i].get('isDeleted')?.value" (click)="onNttToggle(i)" title="Delete">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                      <button type="button" class="btn btn-primary btn-icon-1"
                        [hidden]="!nttData.controls[i].get('isDeleted')?.value" (click)="onNttToggle(i)"
                        title="Restore">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>

      <div class="align-items-center border-top d-flex justify-content-between mb-0 mt-5 p-5 border-bottom-0 pb-0">
        <h2 class="mb-0 fs-3">Port Forwards </h2>
        <button type="button" class="btn btn-primary btn-style"
          [ngClass]="!privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? 'disable-tune':''"
          [disabled]="!privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? true : false"
          (click)="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission ? addPrtForm() : ''">
          <div>
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-block mb-2">
            </span>
          </div> Add port forward rule
        </button>
      </div>

      <form [formGroup]="prtForm" class="px-5 port-forwards">
        <div formArrayName="data">
          <p class="mt-5">Port Forward rules allow incoming traffic to a public IP/protocol/port to be forwarded to a
            specific private IP/port of a host in the PWAN space.
          </p>
          <div *ngIf="prtData.length > 0" class="overflow-x mb-5">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-1 gy-2">
              <thead class="table-light">
                <tr class="fw-bolder text-dark fs-7 ">
                  <th class="py-4"></th>
                  <th class="py-4">Enabled</th>
                  <th class="py-4">Protocol</th>
                  <th class="py-4">Public IP </th>
                  <th class="py-4"> Public port </th>
                  <th class="py-4"> Private IP </th>
                  <th class="py-4"> Private port </th>
                  <th class="py-4 min-w-150px"> Routing groups </th>
                  <th class="py-4"> Helper </th>
                  <th class="py-4"> Note </th>
                  <th class="py-4 min-w-50px w-50px text-center"
                    *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="dropPort($event)">
                <tr [formGroupName]="i" *ngFor="let x of prtData?.controls;let i=index" cdkDrag cdkDragLockAxis="y">
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                  <td><a cdkDragHandle class="hoverGrab"><i class="fa fa-bars"></i></a></td>
                  <td>
                    <!-- <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" name="prtenbl" formControlName="prtenbl">
                      <label class="mb-0 form-label ms-2"> </label>
                    </div> -->
                    <div class="form-group inputtext-block mb-2">
                      <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="prtenbl">
                      </ui-switch>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <ng-select name="prtprotocol" formControlName="prtprotocol" class="select-width dropdown-block"
                        (change)="isPrtProtocol(i)" [clearable]="false" [searchable]="false">
                        <ng-option value="ICMP"> ICMP </ng-option>
                        <ng-option value="IGMP"> IGMP </ng-option>
                        <ng-option value="TCP"> TCP </ng-option>
                        <ng-option value="UDP"> UDP </ng-option>
                        <ng-option value="GRE"> GRE </ng-option>
                        <ng-option value="ESP"> ESP </ng-option>
                        <ng-option value="SCTP"> SCTP </ng-option>
                      </ng-select>
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].protocol">
                          {{aFrm5Error[i]?.protocol?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid" name="prtpubip"
                        formControlName="prtpubip" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].public_ip">
                          {{aFrm5Error[i]?.public_ip?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid" id="prtpubport{{i}}"
                        [ngClass]="(x.value.prtprotocol == 'TCP' || x.value.prtprotocol == 'UDP' ||x.value.prtprotocol == 'SCTP') ? '' : 'port-disable'"
                        [attr.disabled]="(x.value.prtprotocol == 'TCP' || x.value.prtprotocol == 'UDP' ||x.value.prtprotocol == 'SCTP') ? null : ''"
                        name="prtpubport" formControlName="prtpubport" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].public_port">
                          {{aFrm5Error[i]?.public_port?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid" name="prtpriip"
                        formControlName="prtpriip" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].private_ip">
                          {{aFrm5Error[i]?.private_ip?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid"
                        [ngClass]="(x.value.prtprotocol == 'TCP' || x.value.prtprotocol == 'UDP' ||x.value.prtprotocol == 'SCTP') ? '' : 'port-disable'"
                        [attr.disabled]="(x.value.prtprotocol == 'TCP' || x.value.prtprotocol == 'UDP' ||x.value.prtprotocol == 'SCTP') ? null : ''"
                        id="prtpriport{{i}}" name="prtpriport" formControlName="prtpriport" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class=" text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].private_port">
                          {{aFrm5Error[i]?.private_port?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <ng-select name="prtrouting" formControlName="prtrouting" class="select-width dropdown-block"
                        [multiple]="true" placeholder="No routing groups" [searchable]="false">
                        <ng-option *ngFor="let rgp of x.get('prtroutingList')?.value" value="{{rgp.url}}">
                          <span data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-boundary="window"
                            data-bs-placement="top" [title]="rgp.display_name">
                            {{ (rgp.display_name.length>18)? (rgp.display_name | slice:0:18)+'...':(rgp.display_name) }}
                          </span>
                        </ng-option>
                      </ng-select>
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].routing_groups">
                          {{aFrm5Error[i]?.routing_groups?.join(' ')}}
                        </span>
                        <span class="text-danger"
                          *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].non_field_errors">
                          {{aFrm5Error[i]?.non_field_errors?.join(' ')}}
                        </span>
                        <span class="badge badge-light bg-light-warning text-warning white-space-normal p-0" *ngIf="!(aFrm5Error  && aFrm5Error[i] && (aFrm5Error[i].routing_groups || aFrm5Error[i].non_field_errors)) 
                          && !x.value.isNew && x.value.prtrouting?.length <= 0">
                          No routing groups- will not apply on any PWAN router
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <ng-select name="prthelper" formControlName="prthelper" class="select-width dropdown-block"
                        [clearable]="false" [searchable]="false">
                        <ng-option value=""></ng-option>
                        <ng-option value="ftp"> FTP </ng-option>
                        <ng-option value="irc"> IRC </ng-option>
                        <ng-option value="pptp"> PPTP </ng-option>
                        <ng-option value="sip"> SIP </ng-option>
                        <ng-option value="tftp"> TFTP </ng-option>
                      </ng-select>
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].helper">
                          {{aFrm5Error[i]?.helper?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="form-group inputtext-block mb-2">
                      <input type="text" class="form-control  form-control-solid" name="prtnote"
                        formControlName="prtnote" autocomplete="off" />
                      <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="aFrm5Error  && aFrm5Error[i] && aFrm5Error[i].note">
                          {{aFrm5Error[i]?.note?.join(' ')}}
                        </span>
                      </small>
                    </div>
                  </td>
                  <td *ngIf="privateWANRoutingPermissionsObj?.update && isSharedSdwanEditPermission">
                    <div class="form-group inputtext-block mb-2">
                      <button type="button" class="btn btn-danger btn-icon-1"
                        [hidden]="prtData.controls[i].get('isDeleted')?.value" (click)="onPrtToggle(i)" title="Delete">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                      <button type="button" class="btn btn-primary btn-icon-1"
                        [hidden]="!prtData.controls[i].get('isDeleted')?.value" (click)="onPrtToggle(i)"
                        title="Restore">
                        <div>
                          <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'"
                            class="svg-icon svg-icon-block">
                          </span>
                        </div>
                      </button>
                      <!-- <a class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-0 h-40px w-40px">
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                          class="svg-icon svg-icon-3 svg-icon-danger"></span>
                      </a> -->
                      <!-- <a class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-0 h-40px w-40px">
                        <span class="svg-icon svg-icon-3 svg-icon-success"></span> -->
                      <!-- </a> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
    <!-- <div class="card-footer p-5">
      <button *ngIf="wFrm1 == true || wFrm2 == true || wFrm3 == true || wFrm4 == true || wFrm5 == true" type="submit" class="btn btn-info mr-2 ml-2" (click)="updateAllRouting()">Save</button>
        <button *ngIf="wFrm1 == false && wFrm2 == false && wFrm3 == false && wFrm4 == false && wFrm5 == false" type="submit" class="btn btn-default mr-2 ml-2 notallowIcon">Save</button>
    </div> -->
  </div>
</ng-container>


<ng-container *ngIf="selectedChildTab==4 && privateWANCloudPermissionsObj?.view">
  <div class="card mb-5 mb-xl-8 border-1 border border-top-0">
    <div class="card-body p-5">
      <form [formGroup]="cloudFirewallForm">
        <div class="row" *ngIf="privateWANCloudPermissionsObj?.update">
          <div class="col-md-6 col-lg-6 col-xl-6 mb-1" *ngIf="isEditOption">
            <div class="form-group inputtext-block mb-5">
              <label class="form-label col-md-12"> URL </label>
              <input type="text" class="form-control  form-control-solid mb-1" formControlName="url" name="url"
                autocomplete="off" placeholder="Enter URL"
                [attr.disabled]="privateWANCloudPermissionsObj?.update ? null :''" />
              <small class="form-invalid-message">
                <span *ngIf="controlHasError('required', 'url')" class="text-danger">
                  URL is required
                </span>
              </small>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="privateWANCloudPermissionsObj?.update">
          <div class="col-md-6 col-lg-6 col-xl-6 mb-1" *ngIf="isEditOption">
            <div class="form-group inputtext-block mb-5">
              <label class="form-label col-md-12"> Username </label>
              <input type="text" class="form-control  form-control-solid mb-1" formControlName="username"
                name="username" autocomplete="off" placeholder="Enter Username" />
              <small class="form-invalid-message">
                <span *ngIf="controlHasError('required', 'username')" class="text-danger">
                  Username is required
                </span>
              </small>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="privateWANCloudPermissionsObj?.update">
          <div class="col-md-6 col-lg-6 col-xl-6 mb-1" *ngIf="isEditOption">
            <div class="form-group inputtext-block mb-5">
              <label class="form-label col-md-12"> Password </label>
              <input type="password" class="form-control  form-control-solid mb-1" formControlName="password"
                name="password" autocomplete="off" placeholder="Enter Password" />
              <small class="form-invalid-message">
                <span *ngIf="controlHasError('required', 'password')" class="text-danger">
                  Password is required
                </span>
              </small>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer p-5 d-flex justify-content-between flex-nowrap"> -->
        <div class="d-flex gap-5">
          <button *ngIf="privateWANCloudPermissionsObj?.update && isEditOption" type="button"
            class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="OnSaveCloudFirewall()">
            <div>
              <span [inlineSVG]="'./assets/media/icons/duotune/icon/edit.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            </div> {{!IsCloudFirewallLogin ? 'Add' : 'Update'}}
          </button>
          <button type="button" (click)="OnCanceCloudFirewall()" class="btn btn-secondary btn-style"
            data-bs-dismiss="modal"
            *ngIf="IsCloudFirewallLogin && privateWANCloudPermissionsObj?.update && isEditOption">
            <div>
              <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            </div> Cancel
          </button>
          <button type="button" (click)="onRemoveCloudFirewall()" class="btn btn-danger btn-style"
            data-bs-dismiss="modal"
            *ngIf="IsCloudFirewallLogin && privateWANCloudPermissionsObj?.update && isEditOption">
            <div>
              <span [inlineSVG]="'./assets/media/icons/duotune/icon/trash-alt.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            </div> Delete
          </button>
          <button type="button" (click)="OnEditCloudFirewall()" class="btn btn-primary btn-style"
            data-bs-dismiss="modal" *ngIf="privateWANCloudPermissionsObj?.update && !isEditOption">
            <div>
              <span [inlineSVG]="'./assets/media/icons/duotune/icon/edit.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            </div> Edit
          </button>
          <button type="button" (click)="loginToFirewall()" class="btn btn-primary btn-style" data-bs-dismiss="modal"
            *ngIf="IsCloudFirewallLogin && privateWANCloudPermissionsObj?.update && !isEditOption">
            <div>
              <span [inlineSVG]="'./assets/media/icons/duotune/icon/login.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            </div>Login
          </button>
        </div>
        <div *ngIf="!privateWANCloudPermissionsObj?.update && cloudFirewallForm?.value?.url == ''">
          <div class="row mb-1">
            <div class="col-lg-6 col-md-7">
              <div class="row mb-1">
                Cloud firewall credential not added.
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </form>
    </div>
  </div>
</ng-container>
<!-- Private WAN router rules HTML End -->