import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerOrganizationManagementService } from 'src/app/services/partner-organization-management.service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-sdwan-sharing-modal',
  templateUrl: './shared-sdwan-sharing-modal.component.html',
  styleUrls: ['./shared-sdwan-sharing-modal.component.scss']
})
export class SharedSdwanSharingModalComponent implements OnInit {
  sdwanData: any;
  isManageMeshRouting: boolean = true;
  isManageMeshRoutingDisable: boolean = false;
  orgList: any[] = [];
  allBonds: any[] = [];
  unAvailableBondList: any[] = [];
  selectedOrg: string = '';
  bondPermissions: any = { view: true, update: true, delete: true };
  sdwanPermissions: any = { view: true, update: true, delete: true };
  isOrganization: boolean = false;
  isEdit: boolean = false;
  isOrgPreloader: boolean = false;
  isBondPreloader: boolean = false;
  isMasterSel: boolean = true;
  errors: any;
  parentId: any;
  parentName: any;
  sharedSdwanData: any;

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService, private spaceService: PartnerSpaceService,
    private partnerOrgService: PartnerOrganizationManagementService,
    private cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
    if (this.isEdit && this.sdwanData) {
      this.parentId = this.sdwanData.homeSpace;
      this.parentName = this.sdwanData.homeSpaceName;
      this.isManageMeshRouting = this.sdwanData.isManageMeshRouting;
      this.isManageMeshRoutingDisable = this.sdwanData.isManageMeshRoutingDisable;
      this.unAvailableBondList = this.sdwanData.unAvailableBondList;
      // this.bondPermissions = this.sdwanData.bondPermissions;
      // this.sdwanPermissions = this.sdwanData.permissions;
      // if (this.sdwanData.permissions?.view == true) this.sdwanPermissions.view = true;
      // else this.sdwanPermissions.view = false;
      if (this.sdwanData.permissions?.update == true) this.sdwanPermissions.update = true;
      else this.sdwanPermissions.update = false;
      if (this.sdwanData.permissions?.isUpdateDisable == true) this.sdwanPermissions.isUpdateDisable = true;
      else this.sdwanPermissions.isUpdateDisable = false;
      // if (this.sdwanData.permissions?.delete == true) this.sdwanPermissions.delete = true;
      //bond permission
      // if (this.sdwanData.bondPermissions?.view == true) this.bondPermissions.view = true;
      // else this.bondPermissions.view = false;
      // if (this.sdwanData.bondPermissions?.isViewDisable == true) this.bondPermissions.isViewDisable = true;
      // else this.bondPermissions.isViewDisable = false;
      if (this.sdwanData.bondPermissions?.update == true) this.bondPermissions.update = true;
      else this.bondPermissions.update = false;
      if (this.sdwanData.bondPermissions?.isUpdateDisable == true) this.bondPermissions.isUpdateDisable = true;
      else this.bondPermissions.isUpdateDisable = false;
      if (this.sdwanData.bondPermissions?.delete == true) this.bondPermissions.delete = true;
      else this.bondPermissions.delete = false;
      if (this.sdwanData.bondPermissions?.isDeleteDisable == true) this.bondPermissions.isDeleteDisable = true;
      else this.sdwanPermissions.isDeleteDisable = false;
      this.selectedOrg = this.sdwanData._id;
      this.getSpaceBondList();
    } else {
      if (this.sharedSdwanData) {
        if (this.sharedSdwanData.unAvailableBondList && this.sharedSdwanData.unAvailableBondList.length > 0) {
          this.unAvailableBondList = this.sharedSdwanData.unAvailableBondList;
        }
        this.unAvailableBondList = [...this.unAvailableBondList, ...this.sharedSdwanData.unsharedBondList];
        this.isManageMeshRouting = this.sharedSdwanData.isManageMeshRouting;
        if (!this.isManageMeshRouting) this.isManageMeshRoutingDisable = true;
        if (this.sharedSdwanData.permissions?.update != true) this.sdwanPermissions.update = false;
        else this.sdwanPermissions.update = true;
        if (!this.sdwanPermissions.update) this.sdwanPermissions.isUpdateDisable = true;
        //bond permission
        // if (this.sharedSdwanData.bondPermissions?.view == true) this.bondPermissions.view = true;
        // else this.bondPermissions.view = false;
        // if (this.sharedSdwanData.bondPermissions?.view != true) this.bondPermissions.isViewDisable = true;
        if (this.sharedSdwanData.bondPermissions?.update == true) this.bondPermissions.update = true;
        else this.bondPermissions.update = false;
        if (!this.bondPermissions.update) this.bondPermissions.isUpdateDisable = true;
        if (this.sharedSdwanData.bondPermissions?.delete == true) this.bondPermissions.delete = true;
        else this.bondPermissions.delete = false;
        if (!this.bondPermissions.delete) this.bondPermissions.isDeleteDisable = true;
      }
      this.getOrgData();
      this.spaceService.spaceParentId$.subscribe((res: any) => {
        if (res) {
          this.parentId = res.parentId;
          this.getSpaceBondList();
          this.cd.detectChanges();
        }
      });
      this.spaceService.spaceData$.subscribe((res: any) => {
        if (res) {
          this.parentName = res.spaceName;
          this.cd.detectChanges();
        }
      });
    }
  }

  getOrgData() {
    this.isOrgPreloader = true;
    this.partnerOrgService.getAllActiveOrg().subscribe((res: any) => {
      if (res.code == 200 || res.code == 201) {
        if (res && res.data) this.orgList = res.data;
        this.isOrganization = true;
      } else this.sharedService.loggerError(res.message);
      this.isOrgPreloader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isOrgPreloader = false;
      this.cd.detectChanges();
    });
  }

  getSpaceBondList() {
    this.isBondPreloader = true;
    let reqObj = { "url": `bonds/?space=${this.parentId}`, "method": "GET", "data": {} }
    this.spaceService.getSpace(reqObj).subscribe((res: any) => {
      if (res?.code == 200) {
        this.allBonds = res.data;
        this.allBonds = this.allBonds?.filter((bond: any) => !this.unAvailableBondList.includes('' + bond.id));
        this.allBonds?.forEach((bond: any) => {
          if (bond && bond.id) {
            if (this.isEdit && this.sdwanData && this.sdwanData?.unsharedBondList?.includes('' + bond.id)) bond.isChecked = false;
            else bond.isChecked = true;
          }
        });
      }
      this.isBondPreloader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isBondPreloader = false;
    });
  }

  onSave() {
    if (!this.selectedOrg) return;
    let unsharedBondList: any = [];
    this.allBonds?.forEach((bond: any) => {
      if (bond && bond.id && !bond.isChecked) unsharedBondList.push('' + bond.id);
    });
    if (this.sharedSdwanData && this.sharedSdwanData.unsharedBondList && this.sharedSdwanData.unsharedBondList.length > 0) {
      unsharedBondList = [...unsharedBondList]
    }
    let payload = {
      "homeSpace": this.parentId,
      "homeSpaceName": this.parentName,
      "isManageMeshRouting": this.isManageMeshRouting,
      "isManageMeshRoutingDisable": this.isManageMeshRoutingDisable,
      "unsharedBondList": unsharedBondList,
      "unAvailableBondList": this.unAvailableBondList,
      'shareId': this.isEdit && this.sdwanData ? this.sdwanData.shareId : this.sharedSdwanData?._id ?? null,
      "permissions": {
        "view": true,
        "update": this.sdwanPermissions.update || false,
        "isUpdateDisable": this.sdwanPermissions.isUpdateDisable || false,
        "delete": true
      },
      "bondPermissions": {
        "view": true,
        "update": this.bondPermissions.update || false,
        "delete": this.bondPermissions.delete || false,
        // "isViewDisable": this.bondPermissions.isViewDisable || false,
        "isUpdateDisable": this.bondPermissions.isUpdateDisable || false,
        "isDeleteDisable": this.bondPermissions.isDeleteDisable || false,
      }
    }
    if (!this.isEdit) {
      this.sharedService.showLoader();
      this.partnerOrgService.sharedSdwan(this.selectedOrg, payload).subscribe((res: any) => {
        if (res.code == 200 || res.code == 201) {
          this.sharedService.loggerSuccess('The sd-wan has been shared successfully');
        } else this.sharedService.loggerError(res.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) {
            this.sharedService.loggerError(this.errors.non_field_errors);
          } else {
            this.sharedService.loggerError(err);
          }
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    } else {
      this.sharedService.showLoader();
      this.partnerOrgService.sharedSdwanEdit(this.selectedOrg, payload).subscribe((res: any) => {
        if (res.code == 200 || res.code == 201) {
          this.sharedService.loggerSuccess('The sd-wan has been updated successfully');
        } else this.sharedService.loggerError(res.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) {
            this.sharedService.loggerError(this.errors.non_field_errors);
          } else {
            this.sharedService.loggerError(err);
          }
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  checkUncheckAll() {
    if (this.isMasterSel) {
      this.allBonds?.forEach((bond: any) => {
        if (bond && bond.id) bond.isChecked = true;
      })
    } else {
      this.allBonds?.forEach((bond: any) => {
        if (bond && bond.id) bond.isChecked = false;
      })
    }
  }
}
