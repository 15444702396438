import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-space-wan-add-protocol',
  templateUrl: './shared-space-wan-add-protocol.component.html',
  styleUrls: ['./shared-space-wan-add-protocol.component.scss']
})
export class SharedSpaceWanAddProtocolComponent implements OnInit {
  addProtocol: FormGroup;
  protocolData: any;
  typeProtocol: any;
  vlanAssignmentsList: any = [];
  method: any;
  parentId: any;
  allfilters: any;
  errors: any;
  isEdit: boolean = false;
  NameFieldValidation: any = AppConst.NameFieldPattern;

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef, private storageService: StorageService,
    private partnerBondingService: PartnerBondingService,
    private formErrorHandler: FormErrorService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.createNewForm();
    this.addProtocol.valueChanges.subscribe(x => { this.errors = null; });
    this.getInfo();
    // this.getSuccess();
  }

  createNewForm() {
    this.addProtocol = this.fb.group({
      //main
      id: [''],
      name: ['', [this.sharedService.xssValidator, Validators.pattern(this.NameFieldValidation)]],
      protocol: [null, [Validators.required]],
      private_wan: [false],
      enabled: [true],
      ipv4_import: ['all'],
      ipv4_import_filter: [null],
      ipv4_export: ['all'],
      ipv4_export_filter: [null],
      ipv6_import: ['all'],
      ipv6_import_filter: [null],
      ipv6_export: ['all'],
      ipv6_export_filter: [null],
      debug: [false],
      ospfversion: ['2'],
      ospfchannel: ['IPv4'],
      ospfrfc1583compat: [''],
      ospfrfc5838: [''],
      ospfinstance_id: [''],
      ospfstub_router: [''],
      ospftick: [''],
      ospfecmp: [''],
      ospfecpmlimit: [''],
      ospfmerge_external: [''],
      ospfareas: this.fb.array([]),
      interfaceLinks: this.fb.array([]),
      neighbors: this.fb.array([]),
      // networks: this.fb.array([]),
      // externalnetworks: this.fb.array([]),
      // stubnets: this.fb.array([]),
      virtualinks: this.fb.array([]),
      //bgp
      localip: [''],
      localport: [''],
      localasn: [''],
      neighborip: [''],
      neighborrange: [''],
      neighborport: [''],
      neighborasn: [''],
      neighborautomatic_asn: [''],
      ipv4next_hop_keep: [''],
      ipv4next_hop_self: [''],
      ipv4next_hop_address: [''],
      ipv4mandatory: [''],
      ipv4missing_lladdr: [''],//n
      ipv4gateway: [''],
      ipv4import_table: [''],//n
      ipv4export_table: [''],//n
      ipv4secondary: [''],
      ipv4extended_next_hop: [''],//n
      ipv4add_paths: [''],//n
      ipv4aigp: [''],//n
      ipv4cost: [''],
      ipv6next_hop_keep: [''],
      ipv6next_hop_self: [''],
      ipv6next_hop_address: [''],
      ipv6mandatory: [''],
      ipv6missing_lladdr: [''],
      ipv6gateway: [''],
      ipv6import_table: [''],//n
      ipv6export_table: [''],//n
      ipv6secondary: [''],//n
      ipv6extended_next_hop: [''],//n
      ipv6add_paths: [''],
      ipv6aigp: [''],
      ipv6cost: [''],
      interface: [''],
      direct: [''],
      multihop: [''],
      source_address: [''],
      strict_bind: [''],
      // check_link: [''],
      ttl_security: [''],
      password: [''],
      passive: [''],
      confederation: [''],
      confederation_member: [''],
      rr_client: [''],
      rr_cluster_id: [''],
      rs_client: [''],
      allow_bgp_local_pref: [''],
      allow_local_as: [''],
      enable_route_refresh: [''],
      interpret_communities: [''],
      enable_as4: [''],
      enable_extended_messages: [''],
      capabilities: [''],
      hold_time: [''],
      startup_hold_time: [''],
      keepalive_time: [''],
      connect_delay_time: [''],
      connect_retry_time: [''],
      error_wait_time_min: [''],
      error_wait_time_len: [''],
      error_forget_time: [''],
      path_metric: [''],
      med_metric: [''],
      deterministic_med: [''],
      igp_metric: [''],
      prefer_older: [''],
      default_bgp_med: [''],
      default_bgp_local_pref: [''],
      // babelinterfacelinks: this.fb.array([]),
      babelrandomize_router_id: [''],
      link: [''],
      interfaces: this.fb.array([]),
      type: [''],
      rxcost: [''],
      limit: [''],
      hello_interval: [''],
      update_interval: [''],
      port: [''],
      tx_class: [''],
      tx_dscp: [''],
      tx_priority: [''],
      tx_length: [''],
      //check_link:[''],
      next_hop_ipv4: [''],
      next_hop_ipv6: [''],
      destination: [''],
      address: [''],
      //static
      check_link: [''],
      staticroutes: this.fb.array([]),
    });
  }

  getInfo() {
    this.vlanAssignmentsList.forEach((item: any) => {
      let isOn = false;
      if (this.protocolData && this.protocolData.routing_groups && this.protocolData.routing_groups.length > 0) {
        this.protocolData.routing_groups.forEach((data: any) => {
          if (data && data.routing_group == item.routing_group) isOn = true;
        })
      }
      item['isOn'] = isOn;
    })
    if (this.protocolData) {
      this.isEdit = true;
      this.method = "PATCH";
      this.typeProtocol = this.protocolData?.protocol;
      this.setFormData();
    } else {
      this.isEdit = false;
      this.method = "POST";
    }

    this.cd.detectChanges();
  }

  setFormData() {
    let protocoloName = this.protocolData?.protocol;
    if (protocoloName == "bgp") {
      this.addProtocol.patchValue({
        id: this.protocolData?.id,
        name: this.protocolData?.name,
        private_wan: this.protocolData?.private_wan,
        protocol: this.protocolData?.protocol,
        enabled: this.protocolData?.enabled,
        ipv4_import: this.protocolData?.ipv4_import,
        ipv4_export: this.protocolData?.ipv4_export,
        ipv6_import: this.protocolData?.ipv6_import,
        ipv6_export: this.protocolData?.ipv6_export,
        debug: this.protocolData?.debug,
        localip: this.protocolData?.bgp?.local?.ip,
        localport: this.protocolData?.bgp?.local?.port,
        localasn: this.protocolData?.bgp?.local?.asn,

        neighborip: this.protocolData?.bgp?.neighbor?.ip,
        neighborrange: this.protocolData?.bgp?.neighbor?.range,
        neighborport: this.protocolData?.bgp?.neighbor?.port,
        neighborasn: this.protocolData?.bgp?.neighbor?.asn,
        neighborautomatic_asn: this.protocolData?.bgp?.neighbor?.automatic_asn,

        ipv4next_hop_keep: this.protocolData?.bgp?.ipv4?.next_hop_keep,
        ipv4next_hop_self: this.protocolData?.bgp?.ipv4?.next_hop_self,
        ipv4next_hop_address: this.protocolData?.bgp?.ipv4?.next_hop_address,
        ipv4mandatory: this.protocolData?.bgp?.ipv4?.mandatory == true ? '1' : this.protocolData?.bgp?.ipv4?.mandatory == false ? '0' : '',
        ipv4missing_lladdr: this.protocolData?.bgp?.ipv4?.missing_lladdr,//n
        ipv4gateway: this.protocolData?.bgp?.ipv4?.gateway,
        ipv4import_table: this.protocolData?.bgp?.ipv4?.import_table,//n
        ipv4export_table: this.protocolData?.bgp?.ipv4?.export_table,//n
        ipv4secondary: this.protocolData?.bgp?.ipv4?.secondary,
        ipv4extended_next_hop: this.protocolData?.bgp?.ipv4?.extended_next_hop,//n
        ipv4add_paths: this.protocolData?.bgp?.ipv4?.add_paths,//n
        ipv4aigp: this.protocolData?.bgp?.ipv4?.aigp,//n
        ipv4cost: this.protocolData?.bgp?.ipv4?.cost,

        ipv6next_hop_keep: this.protocolData?.bgp?.ipv6?.next_hop_keep,
        ipv6next_hop_self: this.protocolData?.bgp?.ipv6?.next_hop_self,
        ipv6next_hop_address: this.protocolData?.bgp?.ipv6?.next_hop_address,
        ipv6mandatory: this.protocolData?.bgp?.ipv6?.mandatory == true ? '1' : this.protocolData?.bgp?.ipv6?.mandatory == false ? '0' : '',
        ipv6missing_lladdr: this.protocolData?.bgp?.ipv6?.missing_lladdr,
        ipv6gateway: this.protocolData?.bgp?.ipv6?.gateway,
        ipv6import_table: this.protocolData?.bgp?.ipv6?.import_table,//n
        ipv6export_table: this.protocolData?.bgp?.ipv6?.export_table,//n
        ipv6secondary: this.protocolData?.bgp?.ipv6?.secondary,//n
        ipv6extended_next_hop: this.protocolData?.bgp?.ipv6?.extended_next_hop,//n
        ipv6add_paths: this.protocolData?.bgp?.ipv6?.add_paths,
        ipv6aigp: this.protocolData?.bgp?.ipv6?.aigp,
        ipv6cost: this.protocolData?.bgp?.ipv6?.cost,

        interface: this.protocolData?.bgp?.interface,
        direct: this.protocolData?.bgp?.direct == true ? '1' : this.protocolData?.bgp?.direct == false ? '0' : '',
        multihop: this.protocolData?.bgp?.multihop,
        source_address: this.protocolData?.bgp?.source_address,
        strict_bind: this.protocolData?.bgp?.strict_bind == true ? '1' : this.protocolData?.bgp?.strict_bind == false ? '0' : '',
        check_link: this.protocolData?.bgp?.check_link == true ? '1' : this.protocolData?.bgp?.check_link == false ? '0' : '',
        ttl_security: this.protocolData?.bgp?.ttl_security == true ? '1' : this.protocolData?.bgp?.ttl_security == false ? '0' : '',
        password: this.protocolData?.bgp?.password,
        passive: this.protocolData?.bgp?.passive == true ? '1' : this.protocolData?.bgp?.passive == false ? '0' : '',
        confederation: this.protocolData?.bgp?.confederation,
        confederation_member: this.protocolData?.bgp?.confederation_member == true ? '1' : this.protocolData?.bgp?.confederation_member == false ? '0' : '',
        rr_client: this.protocolData?.bgp?.rr_client == true ? '1' : this.protocolData?.bgp?.rr_client == false ? '0' : '',
        rr_cluster_id: this.protocolData?.bgp?.rr_cluster_id,
        rs_client: this.protocolData?.bgp?.rs_client == true ? '1' : this.protocolData?.bgp?.rs_client == false ? '0' : '',
        allow_bgp_local_pref: this.protocolData?.bgp?.allow_bgp_local_pref == true ? '1' : this.protocolData?.bgp?.allow_bgp_local_pref == false ? '0' : '',
        allow_local_as: this.protocolData?.bgp?.allow_local_as,
        enable_route_refresh: this.protocolData?.bgp?.enable_route_refresh == true ? '1' : this.protocolData?.bgp?.enable_route_refresh == false ? '0' : '',
        interpret_communities: this.protocolData?.bgp?.interpret_communities == true ? '1' : this.protocolData?.bgp?.interpret_communities == false ? '0' : '',
        enable_as4: this.protocolData?.bgp?.enable_as4 == true ? '1' : this.protocolData?.bgp?.enable_as4 == false ? '0' : '',
        enable_extended_messages: this.protocolData?.bgp?.enable_extended_messages == true ? '1' : this.protocolData?.bgp?.enable_extended_messages == false ? '0' : '',
        capabilities: this.protocolData?.bgp?.capabilities == true ? '1' : this.protocolData?.bgp?.capabilities == false ? '0' : '',
        hold_time: this.protocolData?.bgp?.hold_time,
        startup_hold_time: this.protocolData?.bgp?.startup_hold_time,
        keepalive_time: this.protocolData?.bgp?.keepalive_time,
        connect_delay_time: this.protocolData?.bgp?.connect_delay_time,
        connect_retry_time: this.protocolData?.bgp?.connect_retry_time,
        error_wait_time_min: this.protocolData?.bgp?.error_wait_time[0],
        error_wait_time_len: this.protocolData?.bgp?.error_wait_time[1],
        error_forget_time: this.protocolData?.bgp?.error_forget_time,
        path_metric: this.protocolData?.bgp?.path_metric == true ? '1' : this.protocolData?.bgp?.path_metric == false ? '0' : '',
        med_metric: this.protocolData?.bgp?.med_metric == true ? '1' : this.protocolData?.bgp?.med_metric == false ? '0' : '',
        deterministic_med: this.protocolData?.bgp?.deterministic_med == true ? '1' : this.protocolData?.bgp?.deterministic_med == false ? '0' : '',
        igp_metric: this.protocolData?.bgp?.igp_metric == true ? '1' : this.protocolData?.bgp?.igp_metric == false ? '0' : '',
        prefer_older: this.protocolData?.bgp?.prefer_older == true ? '1' : this.protocolData?.bgp?.prefer_older == false ? '0' : '',
        default_bgp_med: this.protocolData?.bgp?.default_bgp_med,
        default_bgp_local_pref: this.protocolData?.bgp?.default_bgp_local_pref,
      });

    } else if (protocoloName == 'ospf') {
      this.addProtocol.patchValue({
        id: this.protocolData?.id,
        name: this.protocolData?.name,
        private_wan: this.protocolData?.private_wan,
        protocol: this.protocolData?.protocol,
        enabled: this.protocolData?.enabled,
        ipv4_import: this.protocolData?.ipv4_import,
        ipv4_export: this.protocolData?.ipv4_export,
        ipv6_import: this.protocolData?.ipv6_import,
        ipv6_export: this.protocolData?.ipv6_export,
        debug: this.protocolData?.debug,
        ospfversion: this.protocolData?.ospf?.version,
        ospfchannel: this.protocolData?.ospf?.channel == '6' ? 'IPv6' : this.protocolData?.ospf?.channel == '4' ? 'IPv4' : '',
        ospfrfc1583compat: this.protocolData?.ospf?.rfc1583compat == true ? '1' : this.protocolData?.ospf?.rfc1583compat == false ? '0' : '',
        ospfrfc5838: this.protocolData?.ospf?.rfc5838 == true ? '1' : this.protocolData?.ospf?.rfc5838 == false ? '0' : '',
        ospfinstance_id: this.protocolData?.ospf?.instance_id,
        ospfstub_router: this.protocolData?.ospf?.stub_router == true ? '1' : this.protocolData?.ospf?.stub_router == false ? '0' : '',
        ospftick: this.protocolData?.ospf?.tick,
        ospfecmp: this.protocolData?.ospf?.ecmp?.enable == true ? '1' : this.protocolData?.ospf?.ecmp?.enable == false ? '0' : '',
        ospfecpmlimit: this.protocolData?.ospf?.ecmp?.limit,
        ospfmerge_external: this.protocolData?.ospf?.merge_external == true ? '1' : this.protocolData?.ospf?.merge_external == false ? '0' : '',
      });
      setTimeout(() => {
        this.setospval();
      }, 500);

    } else if (protocoloName == 'babel') {
      this.addProtocol.patchValue({
        id: this.protocolData?.id,
        name: this.protocolData?.name,
        private_wan: this.protocolData?.private_wan,
        protocol: this.protocolData?.protocol,
        enabled: this.protocolData?.enabled,
        ipv4_import: this.protocolData?.ipv4_import,
        ipv4_export: this.protocolData?.ipv4_export,
        ipv6_import: this.protocolData?.ipv6_import,
        ipv6_export: this.protocolData?.ipv6_export,
        debug: this.protocolData?.debug,
      });
      setTimeout(() => {
        this.setbabelinterfaceval();
      }, 500);
    } else if (protocoloName == 'static') {
      this.addProtocol.patchValue({
        id: this.protocolData?.id,
        name: this.protocolData?.name,
        private_wan: this.protocolData?.private_wan ?? false,
        protocol: this.protocolData?.protocol,
        enabled: this.protocolData?.enabled ?? false,
        ipv4_import: this.protocolData?.ipv4_import,
        ipv4_export: this.protocolData?.ipv4_export,
        ipv6_import: this.protocolData?.ipv6_import,
        ipv6_export: this.protocolData?.ipv6_export,
        debug: this.protocolData?.debug == 'all' ? true : false,
        check_link: this.protocolData?.check_link ?? false,
      });
      setTimeout(() => { this.setstaticval(); }, 500);
    }
  }

  setospval() {
    if (this.protocolData.ospf.areas.length > 0) {
      this.protocolData.ospf.areas.forEach((element: any, areaIndex: number) => {
        let areaarr = this.newospfAreas(element);
        let control = <FormArray>this.addProtocol.controls.ospfareas;
        control.push(areaarr);
        if (element.interfaces && element.interfaces.length > 0) this.setospfinterfaces(areaIndex, element.interfaces);
        if (element.networks && element.networks.length > 0) this.setospfNetwork(areaIndex, element.networks);
        if (element.external_networks && element.external_networks.length > 0) this.setospfExternalNetwork(areaIndex, element.external_networks);
        if (element.stubnets && element.stubnets.length > 0) this.setospfStubnets(areaIndex, element.stubnets);
        if (element.virtual_links && element.virtual_links.length > 0) this.setospfVirtulLink(areaIndex, element.virtual_links);
      });
    }
  }

  setospfinterfaces(areaIndex: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any, i: number) => {
        let interfacesForm = this.newospfinterfaces(ele);
        let interfacesControl = <FormArray>this.ospfAreas.controls[areaIndex].get('interfaces');
        if (interfacesControl) interfacesControl.push(interfacesForm);
        if (ele.interfaces && ele.interfaces.length > 0) this.setospfLink(areaIndex, i, ele.interfaces);
        if (ele.neighbors && ele.neighbors.length > 0) this.setospfNeighbor(areaIndex, i, ele.neighbors);
        if (ele.passwords && ele.passwords.length > 0) this.setospfInterfacePassword(areaIndex, i, ele.passwords);
      });
    }
  }

  setospfLink(areaIndex: number, interfaceIndex: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        let linkForm = this.newospfinterfacelinks(ele);
        let interfaceControl = <FormArray>this.ospfAreas.controls[areaIndex].get('interfaces');
        let linkControl = <FormArray>interfaceControl.controls[interfaceIndex].get('interfaces');
        if (linkControl) linkControl.push(linkForm);
      });
    }
  }

  setospfNeighbor(areaIndex: number, interfaceIndex: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        let neighborsForm = this.newospfNeighbors(ele);
        let interfaceControl = <FormArray>this.ospfAreas.controls[areaIndex].get('interfaces');
        let neighborsControl = <FormArray>interfaceControl.controls[interfaceIndex].get('neighbors');
        if (neighborsControl) neighborsControl.push(neighborsForm);
      });
    }
  }

  setospfInterfacePassword(areaIndex: number, i: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        let passForm = this.newospfpassword(ele);
        let linkControl = <FormArray>this.ospfAreas.controls[areaIndex].get('interfaces');
        let passControl = <FormArray>linkControl.controls[i].get('passwords');
        if (passControl) passControl.push(passForm);
      });
    }
  }

  setospfNetwork(areaIndex: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        let networkForm = this.newospfnetworks(ele);
        let networkControl = <FormArray>this.ospfAreas.controls[areaIndex].get('networks');
        if (networkControl) networkControl.push(networkForm);
      });
    }
  }

  setospfExternalNetwork(areaIndex: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        let networkForm = this.newospfexternalnetworks(ele);
        let networkControl = <FormArray>this.ospfAreas.controls[areaIndex].get('external_networks');
        if (networkControl) networkControl.push(networkForm);
      });
    }
  }

  setospfStubnets(areaIndex: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        let stubnetsForm = this.newospfospfstubnets(ele);
        let stubnetsControl = <FormArray>this.ospfAreas.controls[areaIndex].get('stubnets');
        if (stubnetsControl) stubnetsControl.push(stubnetsForm);
      });
    }
  }

  setospfVirtulLink(areaIndex: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any, i: number) => {
        let linkForm = this.newospfvirtuallinks(ele);
        let linkControl = <FormArray>this.ospfAreas.controls[areaIndex].get('virtual_links');
        if (linkControl) linkControl.push(linkForm);
        if (ele.passwords && ele.passwords.length > 0) this.setospfLinkPassword(areaIndex, i, ele.passwords);
      });
    }
  }

  setospfLinkPassword(areaIndex: number, i: number, data: any) {
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        let passForm = this.newospfpassword(ele);
        let linkControl = <FormArray>this.ospfAreas.controls[areaIndex].get('virtual_links');
        let passControl = <FormArray>linkControl.controls[i].get('passwords');
        if (passControl) passControl.push(passForm);
      });
    }
  }

  setbabelinterfaceval() {
    console.log('this.protocolData.babel.interfaces: ', this.protocolData.babel.interfaces);
    if (this.protocolData.babel.interfaces.length > 0) {
      this.protocolData.babel.interfaces.forEach((element: any, i: number) => {
        let areaarr = this.newbabelinterfaces(element);
        let control = <FormArray>this.addProtocol.controls.interfaces;
        control.push(areaarr);
        // element.interfaces.forEach((emnt: any) => {
        //   let areaarr1 = this.newbabelinterfacelinks(emnt);
        //   let control1 = this.babelinterfacelinks(i).controls;
        //   control1.push(areaarr1);
        // });
      });
    }
  }

  // static

  setstaticval() {
    // console.log('this.addProtocol.controls: ', this.addProtocol.controls);
    if (this.protocolData.static.routes.length > 0) {
      this.protocolData.static.routes.forEach((element: any) => {
        let areaarr = this.newstaticroute(element);
        let control = <FormArray>this.addProtocol.controls.staticroutes;
        control.push(areaarr);
      });
    }
  }

  get staticroute(): FormArray {
    return this.addProtocol.get("staticroutes") as FormArray
  }

  onAddstaticroutes() {
    this.staticroute.controls?.push(this.newstaticroute());
  }

  onRemoveStaticRoute(index: any) {
    this.staticroute.removeAt(index);
  }

  newstaticroute(data: any = {}): FormGroup {
    return this.fb.group({
      network: [data.network || ''],
      interface: [data.interface || null],
      destination: [data.destination || null],
      address: [data.address || null],
    })
  }

  // babel
  babelinterfacelinks(interfaceIndex: number): FormArray {
    return this.babelinterface.controls[interfaceIndex].get("interfaces") as FormArray
  }
  get babelinterface(): FormArray {
    return this.addProtocol.get("interfaces") as FormArray
  }

  // onAddbabelinterfacelink(interfaceIndex: number) {
  //   this.babelinterfacelinks(interfaceIndex).controls?.push(this.newbabelinterfacelinks(''));
  // }

  // onRemovebabelInterfaceLink(interfaceIndex: number, index: any) {
  //   this.babelinterfacelinks(interfaceIndex).removeAt(index);
  // }

  onAddbabelinterfaces() {
    this.babelinterface?.controls?.push(this.newbabelinterfaces());
  }

  onRemovebabelinterfaces(index: any) {
    this.babelinterface.removeAt(index);
  }

  // newbabelinterfacelinks(data: any): FormGroup {
  //   return this.fb.group({ link: [data || '', Validators.required] });
  // }

  newbabelinterfaces(data: any = {}): FormGroup {
    console.log('newbabelinterfaces data: ', data);
    return this.fb.group({
      // interfaces: this.fb.array([]),
      type: [data.type || 'wired'],
      rxcost: [data.rxcost || ''],
      limit: [data.limit || ''],
      hello_interval: [data.hello_interval || ''],
      update_interval: [data.update_interval || ''],
      port: [data.port || ''],
      tx_class: [data.tx_class || ''],
      tx_dscp: [data.tx_dscp || ''],
      tx_priority: [data.tx_priority || ''],
      tx_length: [data.tx_length || ''],
      rx_buffer: [data.rx_buffer || ''],
      check_link: [data.check_link == false ? false : true],
      next_hop_ipv4: [data.next_hop_ipv4 || ''],
      next_hop_ipv6: [data.next_hop_ipv6 || '']
    });
  }

  getSuccess() {
    this.partnerBondingService.successForProtocol$.subscribe((res: any) => {
      if (res && res.success) this.activeModal.close();
    });
  }

  get ospfAreas(): FormArray {
    return this.addProtocol.get("ospfareas") as FormArray
  }
  getospfinterface(areaIndex: number): FormArray {
    return this.ospfAreas.controls[areaIndex].get("interfaces") as FormArray
  }
  getospfinterfacelinks(areaIndex: number, interfaceIndex: number): FormArray {
    return this.getospfinterface(areaIndex)?.controls[interfaceIndex]?.get("interfaces") as FormArray
  }
  getospfneighbors(areaIndex: number, interfaceIndex: number): FormArray {
    return this.getospfinterface(areaIndex)?.controls[interfaceIndex]?.get("neighbors") as FormArray
  }
  getospfInterfacePassword(areaIndex: number, interfaceIndex: number): FormArray {
    return this.getospfinterface(areaIndex)?.controls[interfaceIndex]?.get("passwords") as FormArray
  }
  getospfnetwork(areaIndex: any): FormArray {
    return this.ospfAreas.controls[areaIndex].get("networks") as FormArray
  }
  getospfexternalnetworks(areaIndex: any): FormArray {
    return this.ospfAreas.controls[areaIndex].get("external_networks") as FormArray
  }
  getospfstubnets(areaIndex: number): FormArray {
    return this.ospfAreas.controls[areaIndex].get("stubnets") as FormArray
  }
  getospfvirtuallinks(areaIndex: number): FormArray {
    return this.ospfAreas.controls[areaIndex].get("virtual_links") as FormArray
  }
  getospfVirtualLinkPassword(areaIndex: number, linkIndex: number): FormArray {
    return this.getospfvirtuallinks(areaIndex)?.controls[linkIndex]?.get("passwords") as FormArray
  }
  onAddospfAreas() {
    this.ospfAreas.controls?.push(this.newospfAreas());
  }
  onRemove(index: any) {
    this.ospfAreas.removeAt(index);
  }
  onAddexternalnetworks(areaIndex: number) {
    let newexternal_network = this.newospfexternalnetworks();
    this.getospfexternalnetworks(areaIndex)?.push(newexternal_network);
  }
  onRemoveexternalnetworks(areaIndex: number, index: any) {
    this.getospfexternalnetworks(areaIndex).removeAt(index);
  }
  onAddospfinterfaces(areaIndex: number) {
    this.getospfinterface(areaIndex)?.push(this.newospfinterfaces());
  }
  onRemoveinterfaces(areaIndex: number, index: any) {
    this.getospfinterface(areaIndex)?.removeAt(index);
  }
  onAddospfnetwork(areaIndex: number) {
    let newnetwork = this.newospfnetworks();
    this.getospfnetwork(areaIndex).push(newnetwork);
  }
  onRemoveospfnetwork(areaIndex: number, index: any) {
    this.getospfnetwork(areaIndex).removeAt(index);
  }
  onAddstubnets(areaIndex: number) {
    this.getospfstubnets(areaIndex)?.push(this.newospfospfstubnets());
  }
  onRemovestubnets(areaIndex: number, index: any) {
    this.getospfstubnets(areaIndex).removeAt(index);
  }
  onAddvirtuallinks(areaIndex: number) {
    this.getospfvirtuallinks(areaIndex)?.push(this.newospfvirtuallinks());
  }
  onRemovevirtuallinks(areaIndex: number, index: any) {
    this.getospfvirtuallinks(areaIndex)?.removeAt(index);
  }
  onAddneighbors(areaIndex: number, interfaceIndex: number) {
    this.getospfneighbors(areaIndex, interfaceIndex)?.push(this.newospfNeighbors());
  }
  onRemoveneighbors(areaIndex: number, interfaceIndex: number, index: any) {
    this.getospfneighbors(areaIndex, interfaceIndex)?.removeAt(index);
  }
  onAddinterfacelinks(areaIndex: number, interfaceIndex: number) {
    this.getospfinterfacelinks(areaIndex, interfaceIndex)?.push(this.newospfinterfacelinks(''));
  }
  onRemoveinterfacelinks(areaIndex: number, interfaceIndex: number, index: any) {
    this.getospfinterfacelinks(areaIndex, interfaceIndex)?.removeAt(index);
  }
  onAddInterfacePassword(areaIndex: number, interfaceIndex: number) {
    this.getospfInterfacePassword(areaIndex, interfaceIndex)?.push(this.newospfpassword());
  }
  onRemoveInterfacePassword(areaIndex: number, interfaceIndex: number, index: any) {
    this.getospfInterfacePassword(areaIndex, interfaceIndex)?.removeAt(index);
  }
  onAddLinkPassword(areaIndex: number, interfaceIndex: number) {
    this.getospfVirtualLinkPassword(areaIndex, interfaceIndex)?.push(this.newospfpassword());
  }
  onRemoveLinkPassword(areaIndex: number, interfaceIndex: number, index: any) {
    this.getospfVirtualLinkPassword(areaIndex, interfaceIndex)?.removeAt(index);
  }
  newospfinterfacelinks(data: any): FormGroup {
    return this.fb.group({ link: [data || ''] })
  }

  newospfNeighbors(data: any = {}): FormGroup {
    return this.fb.group({ address: [data.address || ''], eligible: [data.eligible || false] });
  }

  newospfvirtuallinks(data: any = {}): FormGroup {
    return this.fb.group({
      id: [data.id || ''],
      instance: [data.instance || ''],
      hello: [data.hello || ''],
      dead: [data.dead || ''],
      dead_count: [data.dead_count || ''],
      retransmit: [data.retransmit || ''],
      transmit_delay: [data.transmit_delay || ''],
      wait: [data.wait || ''],
      authentication: [data.authentication || ''],
      passwords: new FormArray([])
    })
  }

  newospfpassword(data: any = {}): FormGroup {
    return this.fb.group({
      id: [data.id || ''],
      password: [data.password || ''],
      generate_from: [data.generate_from || ''],
      generate_to: [data.generate_to || ''],
      accept_from: [data.accept_from || ''],
      accept_to: [data.accept_to || ''],
      algorithm: [data.algorithm || ''],
    })
  }

  newospfospfstubnets(data: any = {}): FormGroup {
    return this.fb.group({
      network: [data.network || ''],
      hidden: [data.hidden == true ? '1' : data.hidden == false ? '0' : ''],
      summary: [data.summary == true ? '1' : data.summary == false ? '0' : ''],
      cost: [data.cost || ''],
    })
  }
  newospfAreas(data: any = {}): FormGroup {
    return this.fb.group({
      id: [data.id || ''],
      type: [data.type || 'standard'],
      summary: [data.summary == true ? '1' : data.summary == false ? '0' : ''],
      default_nssa: [data.default_nssa == true ? '1' : data.default_nssa == false ? '0' : ''],
      default_cost: [data.default_cost || ''],
      default_cost2: [data.default_cost2 || ''],
      translator: [data.translator == true ? '1' : data.translator == false ? '0' : ''],
      translator_stability: [data.translator_stability || ''],

      interfaces: new FormArray([]),
      networks: new FormArray([]),
      external_networks: new FormArray([]),
      stubnets: new FormArray([]),
      virtual_links: new FormArray([]),
    })
  }
  newospfinterfaces(data: any = {}): FormGroup {
    return this.fb.group({
      instance: [data?.instance || ''],
      cost: [data?.cost || ''],
      hello: [data?.hello || ''],
      poll: [data?.poll || ''],
      dead: [data?.dead || ''],
      dead_count: [data?.dead_count || ''],
      retransmit: [data?.retransmit || ''],
      transmit_delay: [data?.transmit_delay || ''],
      wait: [data?.wait || ''],
      type: [data?.type || ''],
      real_broadcast: [data?.real_broadcast == true ? '1' : data?.real_broadcast == false ? '0' : ''],
      ptp_netmask: [data?.ptp_netmask == true ? '1' : data?.ptp_netmask == false ? '0' : ''],
      priority: [data?.priority || ''],
      strict_nonbroadcast: [data?.strict_nonbroadcast == true ? '1' : data?.strict_nonbroadcast == false ? '0' : ''],
      stub: [data?.stub == true ? '1' : data?.stub == false ? '0' : ''],
      check_link: [data?.check_link == true ? '1' : data?.check_link == false ? '0' : ''],
      ecmp_weight: [data?.ecmp_weight || ''],
      link_lsa_suppression: [data?.link_lsa_suppression == true ? '1' : data?.link_lsa_suppression == false ? '0' : ''],
      authentication: [data?.authentication || ''],
      tx_class: [data?.tx_class || ''],
      tx_dscp: [data?.tx_dscp || ''],
      tx_priority: [data?.tx_priority || ''],
      rx_buffer: [data?.rx_buffer || ''],
      tx_length: [data?.tx_length || ''],
      ttl_security: [data?.ttl_security || ''],
      interfaces: new FormArray([]),
      neighbors: new FormArray([]),
      passwords: new FormArray([]),
    })
  }

  newospfnetworks(data: any = {}): FormGroup {
    return this.fb.group({
      network: [data.network || ''],
      hidden: [data.hidden || false],
    })
  }

  newospfexternalnetworks(data: any = {}): FormGroup {
    return this.fb.group({
      network: [data?.network || ''],
      hidden: [data?.hidden || false],
      tag: [data?.tag || '']
    })
  }

  onGroupChange(event: any, group: any) {
    this.vlanAssignmentsList.forEach((item: any) => {
      if (item && item.id == group.id) {
        item.isOn = event;
        if ((event && this.isInGroup(group)) || (!event && !this.isInGroup(group))) {
          item.isAdd = false;
          item.isDelete = false;
        }
        if (event && !this.isInGroup(group)) {
          item.isAdd = true;
          item.isDelete = false;
        }
        if (!event && this.isInGroup(group)) {
          item.isAdd = false;
          item.isDelete = true;
        }
      }
    });
  }

  isInGroup(group: any) {
    let isIn = false;
    if (this.protocolData && this.protocolData.routing_groups && this.protocolData.routing_groups.length > 0) {
      this.protocolData.routing_groups.forEach((data: any) => {
        if (data && data.routing_group == group.routing_group) isIn = true;
      })
    }
    return isIn;
  }

  changeType(value: any) {
    console.log("the selected value is " + value);
    this.typeProtocol = value;
    this.cd.detectChanges()
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onSave() {
    this.errors = null;
    let body: any = {};
    let ProtocolData: any = {};
    if (this.addProtocol?.invalid) {
      this.addProtocol.markAllAsTouched();
      return;
    }
    body = {
      id: this.addProtocol.value.id,
      name: this.addProtocol.value.name ?? '',
      protocol: this.addProtocol.value.protocol ?? null,
      space: this.addProtocol.value.space ?? null,
      enabled: this.addProtocol.value.enabled ?? false,
      debug: (this.addProtocol.value.debug == false) ? "off" : "all",
      ipv4_import: this.addProtocol.value.ipv4_import ?? "all",
      ipv4_export: this.addProtocol.value.ipv4_export ?? "all",
      ipv6_import: this.addProtocol.value.ipv6_import ?? "all",
      ipv6_export: this.addProtocol.value.ipv6_export ?? "all",
      ipv4_import_filter: this.addProtocol.value.ipv4_import_filter ?? null,
      ipv4_export_filter: this.addProtocol.value.ipv4_export_filter ?? null,
      ipv6_import_filter: this.addProtocol.value.ipv6_import_filter ?? null,
      ipv6_export_filter: this.addProtocol.value.ipv6_export_filter ?? null,
    }
    if (this.addProtocol.value.protocol == 'static') {
      let staticArray: any = this.addProtocol.controls.staticroutes;
      let routedetails: any[] = [];
      staticArray.controls.forEach((e: any) => {
        if (e && e.value) routedetails.push(e.value);
      })
      body.static = {
        check_link: this.addProtocol.value.check_link,
        routes: routedetails
      }
    } else if (this.addProtocol.value.protocol == 'babel') {
      let interfaceArray: any[] = [];
      this.babelinterface.controls.forEach((obj: any) => {
        let interfaceLink: any[] = [];
        let objval = obj.controls;
        if (objval && objval.interfaces.controls.length > 0) {
          objval.interfaces.controls.forEach((link: any) => {
            if (link && link.controls.link.value) interfaceLink.push(link.controls.link.value);
          });
        }
        interfaceArray.push({
          check_link: objval.check_link.value,
          hello_interval: objval.hello_interval.value,
          interfaces: interfaceLink,
          limit: objval.limit.value,
          next_hop_ipv4: objval.next_hop_ipv4.value,
          next_hop_ipv6: objval.next_hop_ipv6.value,
          port: objval.port.value,
          rx_buffer: objval.rx_buffer.value,
          rxcost: objval.rxcost.value,
          tx_class: objval.tx_class.value,
          tx_dscp: objval.tx_dscp.value,
          tx_length: objval.tx_length.value,
          tx_priority: objval.tx_priority.value,
          type: objval.type.value,
          update_interval: objval.update_interval.value,
        });
      })
      body.babel = {
        randomize_router_id: this.addProtocol.value.babelrandomize_router_id,
        interfaces: interfaceArray
      }
    } else if (this.addProtocol.value.protocol == 'bgp') {
      body.bgp = {
        "local": {
          ip: this.addProtocol.value.localip || null,
          port: this.addProtocol.value.localport || null,
          asn: this.addProtocol.value.localasn || null,
        },
        "neighbor": {
          ip: this.addProtocol.value.neighborip || null,
          range: this.addProtocol.value.neighborrange || null,
          port: this.addProtocol.value.neighborport || null,
          asn: this.addProtocol.value.neighborasn || null,
          automatic_asn: this.addProtocol.value.neighborautomatic_asn || null,
        },
        "ipv4": {
          next_hop_keep: this.addProtocol.value.ipv4next_hop_keep || null,
          next_hop_self: this.addProtocol.value.ipv4next_hop_self || null,
          next_hop_address: this.addProtocol.value.ipv4next_hop_address || null,
          mandatory: this.addProtocol.value.ipv4mandatory == '1' ? true : this.addProtocol.value.ipv4mandatory == '0' ? false : '',
          missing_lladdr: this.addProtocol.value.ipv4missing_lladdr || null,//n
          gateway: this.addProtocol.value.ipv4gateway || null,
          import_table: this.addProtocol.value.ipv4import_table || null,//n
          export_table: this.addProtocol.value.ipv4export_table || null,//n
          secondary: this.addProtocol.value.ipv4secondary || null,
          extended_next_hop: this.addProtocol.value.ipv4extended_next_hop || null,//n
          add_paths: this.addProtocol.value.ipv4add_paths || null,//n
          aigp: this.addProtocol.value.ipv4aigp || null,//n
          cost: this.addProtocol.value.ipv4cost || null,
        },
        "ipv6": {
          next_hop_keep: this.addProtocol.value.ipv6next_hop_keep,
          next_hop_self: this.addProtocol.value.ipv6next_hop_self,
          next_hop_address: this.addProtocol.value.ipv6next_hop_address,
          mandatory: this.addProtocol.value.ipv6mandatory == '1' ? true : this.addProtocol.value.ipv6mandatory == '0' ? false : '',
          missing_lladdr: this.addProtocol.value.ipv6missing_lladdr,
          gateway: this.addProtocol.value.ipv6gateway,
          import_table: this.addProtocol.value.ipv6import_table,//n
          export_table: this.addProtocol.value.ipv6export_table,//n
          secondary: this.addProtocol.value.ipv6secondary,//n
          extended_next_hop: this.addProtocol.value.ipv6extended_next_hop,//n
          add_paths: this.addProtocol.value.ipv6add_paths,
          aigp: this.addProtocol.value.ipv6aigp,
          cost: this.addProtocol.value.ipv6cost,
        },
        interface: this.addProtocol.value.interface,
        direct: this.addProtocol.value.direct == '1' ? true : this.addProtocol.value.direct == '0' ? false : '',
        multihop: this.addProtocol.value.multihop,
        source_address: this.addProtocol.value.source_address,
        strict_bind: this.addProtocol.value.strict_bind == '1' ? true : this.addProtocol.value.strict_bind == '0' ? false : '',
        check_link: this.addProtocol.value.check_link == '1' ? true : this.addProtocol.value.check_link == '0' ? false : '',
        ttl_security: this.addProtocol.value.ttl_security == '1' ? true : this.addProtocol.value.ttl_security == '0' ? false : '',
        password: this.addProtocol.value.password,
        passive: this.addProtocol.value.passive == '1' ? true : this.addProtocol.value.passive == '0' ? false : '',
        confederation: this.addProtocol.value.confederation,
        confederation_member: this.addProtocol.value.confederation_member == '1' ? true : this.addProtocol.value.confederation_member == '0' ? false : '',
        rr_client: this.addProtocol.value.rr_client == '1' ? true : this.addProtocol.value.rr_client == '0' ? false : '',
        rr_cluster_id: this.addProtocol.value.rr_cluster_id,
        rs_client: this.addProtocol.value.rs_client == '1' ? true : this.addProtocol.value.rs_client == '0' ? false : '',
        allow_bgp_local_pref: this.addProtocol.value.allow_bgp_local_pref == '1' ? true : this.addProtocol.value.allow_bgp_local_pref == '0' ? false : '',
        allow_local_as: this.addProtocol.value.allow_local_as,
        enable_route_refresh: this.addProtocol.value.enable_route_refresh == '1' ? true : this.addProtocol.value.enable_route_refresh == '0' ? false : '',
        interpret_communities: this.addProtocol.value.interpret_communities == '1' ? true : this.addProtocol.value.interpret_communities == '0' ? false : '',
        enable_as4: this.addProtocol.value.enable_as4 == '1' ? true : this.addProtocol.value.enable_as4 == '0' ? false : '',
        enable_extended_messages: this.addProtocol.value.enable_extended_messages == '1' ? true : this.addProtocol.value.enable_extended_messages == '0' ? false : '',
        capabilities: this.addProtocol.value.capabilities == '1' ? true : this.addProtocol.value.capabilities == '0' ? false : '',
        hold_time: this.addProtocol.value.hold_time,
        startup_hold_time: this.addProtocol.value.startup_hold_time,
        keepalive_time: this.addProtocol.value.keepalive_time,
        connect_delay_time: this.addProtocol.value.connect_delay_time,
        connect_retry_time: this.addProtocol.value.connect_retry_time,
        error_forget_time: this.addProtocol.value.error_forget_time,
        path_metric: this.addProtocol.value.path_metric == '1' ? true : this.addProtocol.value.path_metric == '0' ? false : '',
        med_metric: this.addProtocol.value.med_metric == '1' ? true : this.addProtocol.value.med_metric == '0' ? false : '',
        deterministic_med: this.addProtocol.value.deterministic_med == '1' ? true : this.addProtocol.value.deterministic_med == '0' ? false : '',
        igp_metric: this.addProtocol.value.igp_metric == '1' ? true : this.addProtocol.value.igp_metric == '0' ? false : '',
        prefer_older: this.addProtocol.value.prefer_older == '1' ? true : this.addProtocol.value.prefer_older == '0' ? false : '',
        default_bgp_med: this.addProtocol.value.default_bgp_med,
        default_bgp_local_pref: this.addProtocol.value.default_bgp_local_pref,
        error_wait_time: [this.addProtocol.value.error_wait_time_min, this.addProtocol.value.error_wait_time_len],//n
      }

    } else if (this.addProtocol.value.protocol == 'ospf') {
      let areaList: any = [];
      this.ospfAreas.controls.forEach((area: any) => {
        if (area && area.value) {
          //interfaces
          let interfacesList: any = [];
          let interfacesControl = area?.controls?.interfaces?.controls;
          if (interfacesControl) interfacesControl.forEach((item: any) => {
            if (item && item.value) {
              let linkList: any = []
              let linkControl = item?.controls?.interfaces?.controls;
              if (linkControl) linkControl.forEach((link: any) => {
                if (link && link.value && link.value?.link) linkList.push(link.value?.link);
              })
              let neighborsList: any = []
              let neighborsControl = item?.controls?.neighbors?.controls;
              if (neighborsControl) neighborsControl.forEach((neighbors: any) => {
                if (neighbors && neighbors.value) neighborsList.push(neighbors.value);
              })
              let passList: any = []
              let passControl = item?.controls?.passwords?.controls;
              if (passControl) passControl.forEach((pass: any) => {
                if (pass && pass.value) passList.push(pass.value);
              })
              interfacesList.push({
                instance: item.value?.instance,
                cost: item.value?.cost,
                hello: item.value?.hello,
                poll: item.value?.poll,
                dead: item.value?.dead,
                dead_count: item.value?.dead_count,
                retransmit: item.value?.retransmit,
                transmit_delay: item.value?.transmit_delay,
                wait: item.value?.wait,
                type: item.valueata?.type,
                real_broadcast: item.value?.real_broadcast == '1' ? true : item?.real_broadcast == '0' ? false : null,
                ptp_netmask: item.value?.ptp_netmask == '1' ? true : item?.ptp_netmask == '0' ? false : null,
                priority: item.value?.priority,
                strict_nonbroadcast: item.value?.strict_nonbroadcast == '1' ? true : item?.strict_nonbroadcast == '0' ? false : null,
                stub: item.value?.stub == '1' ? true : item?.stub == '0' ? false : null,
                check_link: item.value?.check_link == '1' ? true : item?.check_link == '0' ? false : null,
                ecmp_weight: item.value?.ecmp_weight,
                link_lsa_suppression: item.value?.link_lsa_suppression == '1' ? true : item?.link_lsa_suppression == '0' ? false : null,
                authentication: item.value?.authentication,
                tx_class: item.value?.tx_class,
                tx_dscp: item.value?.tx_dscp,
                tx_priority: item.value?.tx_priority,
                rx_buffer: item.value?.rx_buffer,
                tx_length: item.value?.tx_length,
                ttl_security: item.value?.ttl_security,
                interfaces: linkList,
                neighbors: neighborsList,
                passwords: passList,
              });
            }
          });
          //networks
          let networkList: any = [];
          let networkControl = area?.controls?.networks?.controls;
          if (networkControl) networkControl.forEach((network: any) => {
            if (network && network.value) networkList.push(network.value);
          });
          //external_networks
          let extrenalNetworkList: any = [];
          let externalNetworkControl = area?.controls?.external_networks?.controls;
          if (externalNetworkControl) externalNetworkControl.forEach((network: any) => {
            if (network && network.value) extrenalNetworkList.push(network.value);
          });
          //stubnets
          let stubnetsList: any = [];
          let stubnetsControl = area?.controls?.stubnets?.controls;
          if (stubnetsControl) stubnetsControl.forEach((stubnet: any) => {
            if (stubnet && stubnet.value) {
              stubnetsList.push({
                network: stubnet.value.network,
                hidden: stubnet.value.hidden == '1' ? true : stubnet.value.hidden == '0' ? false : null,
                summary: stubnet.value.summary == '1' ? true : stubnet.value.summary == '0' ? false : null,
                cost: stubnet.value.cost,
              });
            }
          });
          //virtual links
          let virtualLinksList: any = [];
          let virtualLinksControl = area?.controls?.virtual_links?.controls;
          if (virtualLinksControl) virtualLinksControl.forEach((link: any) => {
            if (link && link.value) {
              let passList: any = []
              let passControl = link?.controls?.passwords?.controls;
              if (passControl) passControl.forEach((pass: any) => {
                if (pass && pass.value) passList.push(pass.value);
              })
              virtualLinksList.push({ ...link.value, passwords: passList });
            }
          });
          areaList.push({
            id: area.value.id,
            type: area.value.type,
            summary: area.value.type == 'stub' || area.value.type == 'nssa' ? area.value.summary == '1' ? true : area.value.summary == '0' ? false : null : null,
            default_nssa: area.value.type == 'nssa' ? area.value.default_nssa == '1' ? true : area.value.default_nssa == '0' ? false : null : null,
            default_cost: area.value.default_cost,
            default_cost2: area.value.type == 'nssa' ? area.value.default_cost2 : null,
            translator: area.value.translator == '1' ? true : area.value.translator == '0' ? false : null,
            translator_stability: area.value.translator_stability,
            networks: networkList,
            stubnets: stubnetsList,
            external_networks: extrenalNetworkList,
            virtual_links: virtualLinksList,
            interfaces: interfacesList
          });
        }
      })
      body.ospf = {
        areas: areaList,
        version: this.addProtocol.value.ospfversion,
        channel: this.addProtocol.value.ospfchannel == 'IPv4' ? '4' : this.addProtocol.value.ospfchannel == 'IPv6' ? '6' : '',
        rfc1583compat: this.addProtocol.value.ospfrfc1583compat == '1' ? true : this.addProtocol.value.ospfrfc1583compat == '0' ? false : null,
        rfc5838: this.addProtocol.value.ospfrfc5838 == '1' ? true : this.addProtocol.value.ospfrfc5838 == '0' ? false : null,
        instance_id: this.addProtocol.value.ospfinstance_id,
        stub_router: this.addProtocol.value.ospfstub_router == '1' ? true : this.addProtocol.value.ospfstub_router == '0' ? false : null,
        tick: this.addProtocol.value.ospftick,
        merge_external: this.addProtocol.value.ospfmerge_external == '1' ? true : this.addProtocol.value.ospfmerge_external == '0' ? false : null,
        ecmp: this.addProtocol.value.ospfecmp ? { enable: this.addProtocol.value.ospfecmp == '1' ? true : false, limit: this.addProtocol.value.ospfecmplimit } : null,
      }
    }
    let apiURL = this.storageService.getCipherObj('api_url');
    if (!apiURL) return;
    body.space = { url: `${apiURL}spaces/${this.parentId}/` }

    if (this.method == 'POST') ProtocolData['url'] = `dynamic_routing/space/protocols/`;
    else ProtocolData['url'] = `dynamic_routing/space/protocols/` + body.id + '/';
    ProtocolData['method'] = this.method;
    ProtocolData['data'] = body

    this.sharedService.showLoader()
    this.partnerBondingService.addData(ProtocolData).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        this.sharedService.loggerSuccess("protocol saved successfully.");
        if (this.vlanAssignmentsList && this.vlanAssignmentsList.length > 0) {
          let totalCount = 0;
          let closeCount = 0;
          this.vlanAssignmentsList.forEach((item: any) => {
            // add new vlan
            if (item && item.isAdd) {
              totalCount++;
              let addData: any = {
                url: `dynamic_routing/space/assignments/`,
                method: 'POST',
                data: {
                  routing_group: item?.routing_group,
                  space_protocol: `${apiURL}dynamic_routing/space/protocols/${addRes?.data?.id}/`
                }
              }
              this.partnerBondingService.addData(addData).subscribe((addRes) => {
                if (addRes.code == 201 || addRes.code == 200) {
                  this.sharedService.loggerSuccess(addRes.message);
                } else this.sharedService.loggerError(addRes.message);
                this.sharedService.hideLoader();
                this.cd.detectChanges();
                closeCount++;
                if (closeCount >= totalCount) {
                  this.activeModal.close({ event: 'save' });
                  this.sharedService.hideLoader();
                }
              }, (err) => {
                this.sharedService.hideLoader();
                this.sharedService.loggerError(err);
                closeCount++;
                if (closeCount >= totalCount) {
                  this.activeModal.close({ event: 'save' });
                  this.sharedService.hideLoader();
                }
                this.cd.detectChanges();
              })
            }
            // delete existing vlan
            if (item && item.isDelete) {
              totalCount++;
              let deleteData: any = { url: addRes?.data?.routing_groups?.url, method: 'DELETE' }
              this.sharedService.showLoader()
              this.partnerBondingService.deleteData(deleteData).subscribe((addRes) => {
                this.sharedService.hideLoader();
                closeCount++;
                if (closeCount >= totalCount) {
                  this.activeModal.close({ event: 'save' });
                  this.sharedService.hideLoader();
                }
                this.cd.detectChanges();
              }, (err) => {
                this.sharedService.hideLoader();
                this.sharedService.loggerError(err);
                closeCount++;
                if (closeCount >= totalCount) {
                  this.activeModal.close({ event: 'save' });
                  this.sharedService.hideLoader();
                }
                this.cd.detectChanges();
              })
            }
          });
          if (totalCount == 0) {
            this.activeModal.close({ event: 'save' });
            this.sharedService.hideLoader();
          }
        } else {
          this.activeModal.close({ event: 'save' });
          this.sharedService.hideLoader();
        }
      } else {
        this.sharedService.loggerError(addRes.message);
        this.activeModal.close({ event: 'save' });
        this.sharedService.hideLoader();
      }
      this.cd.detectChanges();
      // this.activeModal.close({ event: 'save' });
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        if (!this.errors.bgp) this.errorHandler(this.errors, this.addProtocol.controls);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    })
  }

  errorHandler(errorObj: any, controller: any) {
    if (errorObj && errorObj.static && errorObj.static.routes) {
      errorObj = errorObj.static.routes;
      controller = controller['staticroutes'].controls
    }
    if (errorObj && errorObj.ospf && errorObj.ospf.areas) {
      errorObj = errorObj.ospf.areas;
      controller = controller['ospfareas'].controls
    }
    if (errorObj && errorObj.babel) errorObj = errorObj.babel;
    Object.keys(errorObj).forEach(key => {
      let filterAddControl = controller[key];
      if (filterAddControl) {
        if (errorObj[key] && errorObj[key].length > 0) {
          if (filterAddControl) filterAddControl.setErrors(errorObj[key].join(''));
        } else this.errorHandler(errorObj[key], filterAddControl.controls)
      }
    })
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.addProtocol);
  }

}
