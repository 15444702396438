import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from './modules/shared/i18n/translation.service';
import { locale as enLang } from './modules/shared/i18n/vocabs/en';
import { StorageService } from './services/storage.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  constructor(private translationService: TranslationService,
    private titleService: Title, private storageService: StorageService
  ) {
    // register translations
    // this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
    this.translationService.loadTranslations(enLang);
  }

  ngOnInit() {
    this.titleService.setTitle(this.storageService.getCipherObj('navBarText') || 'Fusion Broadband');
  }
}
