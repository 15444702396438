import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { PartnerOrganizationManagementService } from 'src/app/services/partner-organization-management.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';


@Component({
  selector: 'app-shared-bonds-view-config-modal',
  templateUrl: './shared-bonds-view-config-modal.component.html',
  styleUrls: ['./shared-bonds-view-config-modal.component.scss']
})
export class SharedBondsViewConfigModalComponent implements OnInit {
  bondId: any;
  bonderId: any;
  bondDetails: any;
  bonderDetails: any;
  nodeDetails: any;
  bondGeoData: any;
  option: any;
  nodeInfoForm: FormGroup;
  generalConfigForm: FormGroup;
  wanServicesForm: FormGroup;
  securityForm: FormGroup;
  tunnelForm: FormGroup;
  nodeForm: FormGroup;
  statusForm: FormGroup;
  bondGeoForm: FormGroup;
  errors: any;
  //2
  homeSpace: any;
  apiURL: any = null;
  spaces: any[] = [];
  preSpaceLoaderScreen: boolean = false;
  //3
  tunnelSecurity = [
    { key: 'encryption', title: 'Encryption' },
    { key: 'hmac', title: 'HMAC' },
    { key: 'none', title: 'No Security' },
  ];
  encyptionCipher: any[] = [
    { key: 'AES128', title: 'AES 128' },
    { key: 'AES256', title: 'AES 256' }
  ];
  packetDistribution: any[] = [
    { key: 'wrr', title: 'Weighted Round Robin' },
    { title: 'Flowlet', key: 'flowlet' },
    { key: 'idmpq', title: 'IDMPQ' }
  ];
  bridgeCongetionAlgorithms: any[] = [
    { key: 'bbr', title: 'BBR (Recommended)' },
    { title: 'CUBIC', key: 'cubic' },
    { title: 'BIC', key: 'bic' },
    { title: 'CDG', key: 'cdg' },
    { title: 'Datacenter TCP', key: 'dctcp' },
    { title: 'Hamilton TCP', key: 'htcp' },
    { title: 'Highspeed TCP', key: 'highspeed' },
    { title: 'Hybla', key: 'hybla' },
    { title: 'Illinois', key: 'illinois' },
    { title: 'Low Priority', key: 'lp' },
    { title: 'Reno', key: 'reno' },
    { title: 'Scalable TCP', key: 'scalable' },
    { title: 'Vegas', key: 'vegas' },
    { title: 'Veno', key: 'veno' },
    { title: 'Westwood', key: 'westwood' },
    { title: 'Yeah TCP', key: 'yeah' },
  ];
  encryptionEnabled: boolean = false;
  isFlowletSelected: boolean = false;
  isLegMTUSelected: boolean = true;
  isTCPProxySelected: boolean = false;
  allQosList: any[] = [];
  allClassificationList: any[] = [];
  isPartner: boolean = false;
  isSharedBond: boolean = false;
  isSdwanSharedBond: boolean = false;
  preQOSLoaderScreen: boolean = false;
  preClassificationLoaderScreen: boolean = false;
  //4
  flowCollectors: any[] = [];
  //5
  allAggregatorsList: any[] = [];
  allSecondAggregatorsList: any[] = [];
  preAggregatorsLoaderScreen: boolean = false;
  //6
  isReorderMaxSelected: boolean = true;
  oldDBNotes: any;
  NameFieldValidation: any = AppConst.NameFieldPattern;

  constructor(private partnerBondingService: PartnerBondingService,
    private fb: FormBuilder, private cd: ChangeDetectorRef,
    private policiesService: PoliciesService, private modalService: NgbModal,
    private partnerOrganizationManagementService: PartnerOrganizationManagementService,
    public activeModal: NgbActiveModal, private sharedService: SharedService,
    private formErrorHandler: FormErrorService, private storageService: StorageService,) { }

  ngOnInit(): void {
    if (Number(this.storageService.getCipherObj('role')) === AppConst.partner ||
      (Number(this.storageService.getCipherObj('role')) == AppConst.user &&
        Number(this.storageService.getCipherObj('parentRole')) == AppConst.partner)) this.isPartner = true;
    if (this.storageService.getCipherObj('homeSpace')) {
      if (this.storageService.getCipherObj('homeSpace') == '0' && !this.isPartner) this.homeSpace = null;
      else this.homeSpace = this.storageService.getCipherObj('homeSpace');
    } else this.homeSpace = null;
    this.isSdwanSharedBond = this.storageService.getCipherBoolean('isSdwanSharedBond') ?? false;
    this.isSharedBond = this.storageService.getCipherBoolean('isSharedBond') ?? false;
    if (this.option == 1) this.nodeInfoForm = this.createNodeInfoForm(this.bondDetails);
    else if (this.option == 2) {
      this.generalConfigForm = this.createGeneralConfigForm(this.bonderDetails);
      if (!this.bonderDetails?.note) this.oldDBNotes = '';
      else this.oldDBNotes = this.bonderDetails?.note;
      if ((this.homeSpace || this.isPartner) && !(this.isSharedBond || this.isSdwanSharedBond)) {
        if (this.homeSpace && this.homeSpace != '0') this.getSpaces();
        if (this.storageService.getCipherObj('api_url')) this.apiURL = this.storageService.getCipherObj('api_url');
        if (this.apiURL) {
          if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
            this.getSpaces();
          } else if (Number(this.storageService.getCipherObj('role')) == AppConst.organization) {
            if (this.homeSpace != null) {
              if (this.homeSpace == '0') this.spaces = [];
              else this.getSpaces();
            }
          } else if (Number(this.storageService.getCipherObj('role')) == AppConst.user) {
            if (Number(this.storageService.getCipherObj('parentRole')) == AppConst.partner) {
              this.getSpaces();
            } else if (Number(this.storageService.getCipherObj('parentRole')) == AppConst.organization) {
              if (this.homeSpace != null) {
                if (this.homeSpace == '0') this.spaces = [];
                else this.getSpaces();
              }
            }
          }
        }
      }
    } else if (this.option == 3) {
      this.securityForm = this.createSecurityForm(this.bondDetails);
      this.getAllQOSList();
      // this.getAllClassificationList();
      if (this.bondDetails?.classification_profile) {
        let isIncluded: boolean = false;
        this.allClassificationList.forEach((classification: any) => {
          if (classification && classification.url === this.bondDetails?.classification_profile) {
            isIncluded = true;
          }
        });
        if (!isIncluded) this.allClassificationList = [...this.allClassificationList, { display_name: 'Unavailable', url: this.bondDetails?.classification_profile }]
      }
      this.onEncryptionChange();
      this.onPacketDistributionChange();
      this.onTCPProxyChange();
      this.onLegMTUDetectionChange();
    } else if (this.option == 4) {
      this.wanServicesForm = this.createWanServicesForm(this.bondDetails);
    } else if (this.option == 5) {
      this.tunnelForm = this.createTunnelForm(this.bondDetails);
      this.getAllAggregatorList();
    } else if (this.option == 6) {
      this.nodeForm = this.createNodeForm(this.bondDetails);
      this.onReorderMaxChange();
    } else if (this.option == 7) {
      this.bondGeoForm = this.createBondGeoForm(this.bondGeoData);
    }
  }

  createNodeInfoForm(data: any = {}) {
    return this.fb.group({
      circuit_id: [data?.circuit_id, [this.sharedService.xssValidator, Validators.maxLength(50)]],
      product: [data?.product || '', [this.sharedService.xssValidator, Validators.maxLength(200)]],
      asset_tag: [data?.bonder?.asset_tag || '', [this.sharedService.xssValidator, Validators.maxLength(100)]],
      serial_number: [data?.bonder?.serial_number || '', [this.sharedService.xssValidator, Validators.maxLength(50)]],
    });
  }

  createNodeForm(data: any = {}) {
    return this.fb.group({
      automatic_reorder_max_hold: [data?.automatic_reorder_max_hold == false ? false : true],
      reorder_max_hold: [data?.reorder_max_hold || 30],
      batched_leg_tx: [data?.batched_leg_tx || false],
      send_jitter_buffer: [data?.send_jitter_buffer || false],
      note: [data?.note || '' || '', [this.sharedService.xssValidator, Validators.maxLength(200)]],
      dns_servers: [this.bonderDetails?.dns_servers && this.bonderDetails?.dns_servers.length > 0 ? this.bonderDetails?.dns_servers.join() : '', [this.sharedService.xssValidator, Validators.maxLength(100)]],
      manage_process_affinity: [this.bonderDetails?.manage_process_affinity == false ? false : true],
      tunnel_affinity_core: [this.bonderDetails?.tunnel_affinity_core || 2],
      metric_collection_interval: [this.bonderDetails?.metric_collection_interval || 10],
      metric_reporting_interval: [data?.bonder?.metric_reporting_interval || 60],
      tcp_congestion_control_algorithm: [this.bonderDetails?.tcp_congestion_control_algorithm || 'bbr'],
      // web_server: [this.bonderDetails?.web_server == false ? false : true],
      automatic_source_ip: [data?.automatic_source_ip || false],
      automatic_ping_timing: [data?.automatic_ping_timing == false ? false : true],
      regular_leg_ping_time: [data?.regular_leg_ping_time || 100],
      regular_leg_fail_time: [data?.regular_leg_fail_time || 300],
      failover_leg_ping_time: [data?.failover_leg_ping_time || 1000],
      failover_leg_fail_time: [data?.failover_leg_fail_time || 3000],
      // node_debug: [data?.bonder?.debug || false]
    });
  }

  createTunnelForm(data: any = {}) {
    return this.fb.group({
      aggregator: [data?.aggregator, Validators.required],
      secondary_aggregator: [data?.secondary_aggregator],
      aggregator_failback: [data?.aggregator_failback || false],
      debug: [data?.debug || false],
    });
  }

  createSecurityForm(data: any = {}) {
    return this.fb.group({
      compression: [data?.compression == false ? false : true],
      flap_detection: [data?.flap_detection == false ? false : true],
      leg_mtu_detection: [data?.leg_mtu_detection == false ? false : true],
      packet_distribution: [data?.packet_distribution || 'wrr'],
      packet_loss_detection: [data?.packet_loss_detection == false ? false : true],
      leg_mtu_detection_time: [data?.leg_mtu_detection_time || 1],
      clamp_tcp: [data?.clamp_tcp == false ? false : true],
      source_address_verification: [data?.source_address_verification || false],
      tunnel_security: [data?.tunnel_security || 'hmac'],
      encryption_cipher: [data?.encryption_cipher || 'AES128'],
      encryption_handshake_interval: [data?.encryption_handshake_interval || 3600],
      qos_profile: [data?.qos_profile?.url || ''],
      classification_profile: [data?.classification_profile || ''],
      bridge_enabled: [data?.bridge_enabled || false],
      bridge_ports: [data?.bridge_ports || '80,443,8080', [this.sharedService.xssValidator, Validators.maxLength(50)]],
      bridge_concurrency: [data?.bridge_concurrency || 4],
      bridge_congestion_control_algorithm: [data?.bridge_congestion_control_algorithm || 'bbr'],
      bridge_file_descriptor_limit: [data?.bridge_file_descriptor_limit || 16384],
    });
  }

  createWanServicesForm(data: any = {}) {
    return this.fb.group({ flow_collectors: [data?.flow_collectors || ''] });
  }

  createGeneralConfigForm(data: any = {}) {
    return this.fb.group({
      name: [data?.name || '', [Validators.required, this.sharedService.xssValidator, Validators.pattern(this.NameFieldValidation)]],
      space: [data?.space?.key || ''],
      note: [this.bonderDetails?.note || '', [this.sharedService.xssValidator, Validators.maxLength(200)]]
    });
  }

  createBondGeoForm(data: any = {}) {
    return this.fb.group({ lat: [data?.lat || null, Validators.required], lang: [data?.lang || null, Validators.required] });
  }

  getSpaces() {
    this.preSpaceLoaderScreen = true;
    let url = `spaces/?as_tree=true`;
    this.partnerBondingService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res?.data) {
        if (this.homeSpace != null) {
          let tmpSpaceList: any = [];
          const addedf = (array: any = []): any => {
            if (array && array.length > 0) {
              array.forEach((item: any) => {
                tmpSpaceList.push(item);
                if (item.child_spaces && item.child_spaces.length > 0) addedf(item.child_spaces);
              })
            }
          }

          const findedf = (array: any = [], id: string): any => {
            if (array && array.length > 0) {
              for (const item of array) {
                if (item.key === id) {
                  tmpSpaceList.push(item);
                  if (item.child_spaces && item.child_spaces.length > 0) addedf(item.child_spaces);
                  return;
                } else findedf(item.child_spaces, id);
              }
            }
          };

          findedf(res.data, this.homeSpace);
          this.spaces = tmpSpaceList;
        } else {
          let tmpSpaceList: any = [];
          const addedf = (array: any = []): any => {
            if (array && array.length > 0) {
              array.forEach((item: any) => {
                tmpSpaceList.push(item);
                if (item.child_spaces && item.child_spaces.length > 0) addedf(item.child_spaces);
              })
            }
          }
          addedf(res.data);
          this.spaces = tmpSpaceList;
        }
      }
      this.preSpaceLoaderScreen = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.preSpaceLoaderScreen = false;
    });
  }

  getAllQOSList() {
    this.preQOSLoaderScreen = true;
    this.allQosList = [];
    this.cd.detectChanges();
    if (!this.homeSpace && !this.isPartner) {
      this.partnerOrganizationManagementService.sharedQOSList()?.subscribe((res: any) => {
        if (res && res?.finalArray && res?.finalArray?.length > 0) {
          this.allQosList = res.finalArray;
        } else this.allQosList = [];
        if (this.bondDetails?.qos_profile?.url) {
          let isIncluded: boolean = false;
          this.allQosList.forEach((qos: any) => {
            if (qos && qos.url === this.bondDetails?.qos_profile?.url) {
              isIncluded = true;
            }
          });
          if (!isIncluded) this.allQosList = [...this.allQosList, { name: 'Unavailable', url: this.bondDetails?.qos_profile?.url }];
        }
        this.preQOSLoaderScreen = false;
        this.cd.detectChanges();
      }, (err: any) => {
        this.sharedService.loggerError(err);
        this.preQOSLoaderScreen = false;
        this.cd.detectChanges();
      });
    } else {
      this.policiesService.getQOSListing(this.homeSpace)?.subscribe((res: any) => {
        if (res && res?.data && res?.data && res?.data?.length > 0) {
          this.allQosList = res.data;
        } else this.allQosList = [];
        this.preQOSLoaderScreen = false;
        this.cd.detectChanges();
      }, (err: any) => {
        this.preQOSLoaderScreen = false;
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      });
    }
  }

  getAllClassificationList() {
    this.preClassificationLoaderScreen = true;
    this.allClassificationList = [];
    if (!this.homeSpace && !this.isPartner) {
      this.partnerOrganizationManagementService.sharedClassificationList()?.subscribe((res: any) => {
        if (res && res?.finalArray && res?.finalArray?.length > 0) {
          this.allClassificationList = res.finalArray;
        } else this.allClassificationList = [];
        this.preClassificationLoaderScreen = false;
        this.cd.detectChanges();
      }, (err: any) => {
        this.sharedService.loggerError(err);
        this.preClassificationLoaderScreen = false;
        this.cd.detectChanges();
      });
    } else {
      this.policiesService.getClassificationListing(this.homeSpace)?.subscribe((res: any) => {
        if (res && res?.data && res?.data?.length > 0) {
          this.allClassificationList = res.data;
        } else this.allClassificationList = [];
        this.preClassificationLoaderScreen = false;
        this.cd.detectChanges();
      }, (err: any) => {
        this.sharedService.loggerError(err);
        this.preClassificationLoaderScreen = false;
        this.cd.detectChanges();
      });
    }
  }

  getAllAggregatorList() {
    this.allAggregatorsList = [];
    this.allSecondAggregatorsList = [];
    if (this.homeSpace || this.isPartner) {
      this.preAggregatorsLoaderScreen = true;
      this.policiesService.getAggregatorListing(this.homeSpace)?.subscribe((res: any) => {
        if (res && res?.data && res?.data && res?.data?.length > 0) {
          this.allAggregatorsList = res.data;
          this.allSecondAggregatorsList = res.data;
        } else {
          this.allAggregatorsList = [];
          this.allSecondAggregatorsList = [];
        }
        if (this.bondDetails?.aggregator) {
          let isIncluded: boolean = false;
          this.allAggregatorsList.forEach((aggregator: any) => {
            if (aggregator && aggregator.url === this.bondDetails?.aggregator) isIncluded = true;
          });
          if (!isIncluded) this.allAggregatorsList = [...this.allAggregatorsList, { name: this.bondDetails.aggregator_name ?? 'Unavailable', url: this.bondDetails?.aggregator }];
        }
        if (this.bondDetails?.secondary_aggregator) {
          let isIncluded: boolean = false;
          this.allSecondAggregatorsList.forEach((aggregator: any) => {
            if (aggregator && aggregator.url === this.bondDetails?.secondary_aggregator) isIncluded = true;
          });
          if (!isIncluded) this.allSecondAggregatorsList = [...this.allSecondAggregatorsList, { name: this.bondDetails.secondary_aggregator_name ?? 'Unavailable', url: this.bondDetails?.secondary_aggregator }];
        }
        this.preAggregatorsLoaderScreen = false;
        this.cd.detectChanges();
      }, (err: any) => {
        this.preAggregatorsLoaderScreen = false;
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      });
    } else {
      if (this.bondDetails?.aggregator) {
        let isIncluded: boolean = false;
        this.allAggregatorsList.forEach((aggregator: any) => {
          if (aggregator && aggregator.url === this.bondDetails?.aggregator) isIncluded = true;
        });
        if (!isIncluded) this.allAggregatorsList = [...this.allAggregatorsList, { name: this.bondDetails.aggregator_name ?? 'Unavailable', url: this.bondDetails?.aggregator }];
      }
      if (this.bondDetails?.secondary_aggregator) {
        let isIncluded: boolean = false;
        this.allSecondAggregatorsList.forEach((aggregator: any) => {
          if (aggregator && aggregator.url === this.bondDetails?.secondary_aggregator) isIncluded = true;
        });
        if (!isIncluded) this.allSecondAggregatorsList = [...this.allSecondAggregatorsList, { name: this.bondDetails.secondary_aggregator_name ?? 'Unavailable', url: this.bondDetails?.secondary_aggregator }];
      }
    }
  }

  onEncryptionChange() {
    let value = this.securityForm?.get('tunnel_security')?.value;
    if (value == 'encryption') this.encryptionEnabled = true;
    else this.encryptionEnabled = false;
    this.cd.detectChanges();
  }

  onPacketDistributionChange() {
    let value = this.securityForm.get('packet_distribution')?.value;
    if (value == 'flowlet') this.isFlowletSelected = true;
    else this.isFlowletSelected = false;
    this.cd.detectChanges();
  }

  onTCPProxyChange() {
    let value = this.securityForm.get('bridge_enabled')?.value;
    if (value) this.isTCPProxySelected = true;
    else this.isTCPProxySelected = false;
    this.cd.detectChanges();
  }

  onLegMTUDetectionChange() {
    let value = this.securityForm.get('leg_mtu_detection')?.value;
    if (value) this.isLegMTUSelected = true;
    else this.isLegMTUSelected = false;
    this.cd.detectChanges();
  }

  onReorderMaxChange() {
    let value = this.nodeForm.get('automatic_reorder_max_hold')?.value;
    if (value) this.isReorderMaxSelected = true;
    else this.isReorderMaxSelected = false;
    this.cd.detectChanges();
  }

  onSave() {
    let apiUrl = this.storageService.getCipherObj('api_url');
    if (!apiUrl) return;
    if (this.nodeInfoForm?.invalid) {
      this.nodeInfoForm.markAllAsTouched();
      return;
    } else if (this.generalConfigForm?.invalid) {
      this.generalConfigForm.markAllAsTouched();
      return;
    } else if (this.securityForm?.invalid) {
      this.securityForm.markAllAsTouched();
      return;
    } else if (this.nodeForm?.invalid) {
      this.nodeForm.markAllAsTouched();
      return;
    } else if (this.bondGeoForm?.invalid) {
      this.bondGeoForm.markAllAsTouched();
      return;
    }
    let bondUrl = `${apiUrl}bonds/${this.bondId}/`;
    if (this.option == 1) {
      let payload = { ...this.nodeInfoForm.value, bond: bondUrl }
      this.editDetails(payload, payload);
    } else if (this.option == 2) {
      if (this.preSpaceLoaderScreen) return;
      let spaceSelected = this.spaces.find((x: any) => x.key == this.generalConfigForm.value.space);
      let payload: any = {
        name: this.generalConfigForm.value.name,
        note: this.generalConfigForm.value.note,
        bond: bondUrl
      };

      if ((this.homeSpace || this.isPartner) && !(this.isSharedBond || this.isSdwanSharedBond)) payload = {
        ...payload, space: {
          url: spaceSelected?.url || '',
          name: spaceSelected?.name || '',
          key: spaceSelected?.key || '',
          private_wan_enabled: false
        },
      };
      if (payload?.name != this.bondDetails?.bonder?.name && payload?.name) payload.isNameUpdated = true;
      if (payload?.space?.key != this.bondDetails?.space?.key && payload?.space?.key) payload.isSpaceUpdated = true;
      if (payload?.note != this.oldDBNotes && payload?.note) payload.isDbNotesUpdated = true;
      payload.bondId = this.bondId;
      this.editDetails(payload, payload);
    } else if (this.option == 3) {
      if (this.preQOSLoaderScreen) return;
      let qp = this.securityForm.get('qos_profile')?.value;
      let selectedQP = this.allQosList.find((x: any) => x.url == qp);
      let payload = { ...this.securityForm.value, qos_profile: qp ? { url: selectedQP?.url || '', name: selectedQP?.name || '' } : null, bond: bondUrl };
      if (payload?.compression != this.bondDetails.compression) {
        payload.isCompression = payload?.compression == false ? 'disabled' : 'enabled';
      }
      if (payload?.leg_mtu_detection != this.bondDetails.leg_mtu_detection) {
        payload.isLeg_mtu_detection = payload?.leg_mtu_detection == false ? 'disabled' : 'enabled';
      }
      if (payload?.packet_loss_detection != this.bondDetails.packet_loss_detection) {
        payload.isPacket_loss_detection = payload?.packet_loss_detection == false ? 'disabled' : 'enabled';
      }
      if (payload?.clamp_tcp != this.bondDetails.clamp_tcp) {
        payload.isClamp_tcp = payload?.clamp_tcp == false ? 'disabled' : 'enabled';
      }
      if (payload?.flap_detection != this.bondDetails.flap_detection) {
        payload.isFlap_detection = payload?.flap_detection == false ? 'disabled' : 'enabled';
      }
      if (payload?.source_address_verification != this.bondDetails.source_address_verification) {
        payload.isSource_address_verification = payload?.source_address_verification == false ? 'disabled' : 'enabled';
      }
      if (payload?.leg_mtu_detection_time != this.bondDetails.leg_mtu_detection_time) {
        payload.isLeg_mtu_detection_time = payload?.leg_mtu_detection_time;
      }
      if (payload?.packet_distribution != this.bondDetails.packet_distribution) {
        let arr = this.packetDistribution.find((detailArr: any) => detailArr.key == payload?.packet_distribution);
        payload.isPacket_distribution = arr.title;
      }
      if (payload?.bridge_concurrency != this.bondDetails.bridge_concurrency || payload?.bridge_enabled != this.bondDetails.bridge_enabled) {
        payload.isBridgeEnabled = payload?.bridge_enabled == false ? 'disabled' : 'enabled';
      }
      payload.bondName = this.storageService.getCipherObj('bondName');
      payload.bondId = this.bondId;
      if (payload.tunnel_security == 'encryption' || payload.tunnel_security == 'hmac') {
        let modal = this.modalService.open(WarningModalContent, { size: 'md', centered: true });
        modal.componentInstance.text = "Enabling Tunnel Security will result in reduced tunnel MTU and up to 40% reduction in bandwidth capacity.";
        modal.result.then((data: any) => {
          if (data && data?.event === 'save') this.editDetails(payload, '');
          this.cd.detectChanges();
        }, () => { });
      } else this.editDetails(payload, '');
    } else if (this.option == 4) {
      let payload = {
        ...this.wanServicesForm.value,
        bond: bondUrl
      }
      this.editDetails(payload, '');
    } else if (this.option == 5) {
      if (this.preAggregatorsLoaderScreen) return;
      let payload = { ...this.tunnelForm.value, bond: bondUrl };
      this.editDetails(payload, '');
    } else if (this.option == 6) {
      let dnsList = this.nodeForm.controls['dns_servers'].value;
      if (dnsList) dnsList = dnsList.split(',');
      else dnsList = [];
      let payload = { ...this.nodeForm.value, dns_servers: dnsList, bond: bondUrl };
      this.editDetails(payload, { ...payload, debug: payload.node_debug });
    } else if (this.option == 7) {
      if (!this.bondGeoForm.value.lat || !this.bondGeoForm.value.lang) {
        this.bondGeoForm.markAllAsTouched();
        return;
      }
      this.saveBondCoordinate(this.bondGeoForm.value.lat, this.bondGeoForm.value.lang);
    }
  }

  editDetails(bondData: any, bonderData: any) {
    this.sharedService.showLoader();
    this.partnerBondingService.updateBond({ bond: bondData, bonder: bonderData }, this.bondId, this.bonderId)?.subscribe((res) => {
      if (res.code != 400) {
        if (res.data && res.data.bondCode == 200 && (res.data.bonderCode == 200 || !bonderData)) {
          this.sharedService.loggerSuccess('bond update successfully');
          this.activeModal.close({ event: 'save' });
        } else {
          if (res.data.bondCode != 200) this.errors = { ...this.errors, ...res.data.bond };
          if (res.data.bonderCode != 200) this.errors = { ...this.errors, ...res.data.bonder };
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors?.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
      } else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  saveBondCoordinate(lat: number, lang: number) {
    if (!this.bondId || !lat || !lang) return;
    this.partnerOrganizationManagementService.saveBondGeoMap({ nodeId: this.bondId, lat, lang })?.subscribe((res: any) => {
      if (res && res.code == 200) {
        this.activeModal.close({ event: 'save' });
      } else this.sharedService.loggerError(res.message);
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  controlHasError(validation: any, controlName: string): boolean {
    if (this.option == 1) return this.formErrorHandler.controlHasError(validation, controlName, this.nodeInfoForm);
    else if (this.option == 2) return this.formErrorHandler.controlHasError(validation, controlName, this.generalConfigForm);
    else if (this.option == 3) return this.formErrorHandler.controlHasError(validation, controlName, this.securityForm);
    else if (this.option == 5) return this.formErrorHandler.controlHasError(validation, controlName, this.tunnelForm);
    else if (this.option == 7) return this.formErrorHandler.controlHasError(validation, controlName, this.bondGeoForm);
    else return this.formErrorHandler.controlHasError(validation, controlName, this.nodeForm);
  }
}

@Component({
  selector: 'warning-modal-content',
  template: `
            <div class="">
                <div class="warning-block">
                    <div>
                        <span [inlineSVG]="'./assets/media/icons/normal/exclamation-triangle.svg'"
                            class="svg-icon svg-icon-3 me-0 warning-svg">
                        </span>
                    </div>
                </div>
                <div class="modal-body warning-body">
                    <h1 class="warning-title m-0">Warning!</h1>
                </div>
                <div class="text-center warning-text">Enabling Tunnel Security will result in reduced tunnel MTU and up to 40%
                    reduction in bandwidth
                    capacity.</div>
                <div class="warning-footer">
                    <div class="text-center warning-confirm">Do you want to continue ?</div>
                    <div class="warning-button">
                        <button type="button" class="btn btn-outline-danger" (click)="onSave()">Yes, Save it!</button>
                        <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">No</button>
                    </div>
                </div>
            </div>
  `,
  styleUrls: ['./shared-bonds-view-config-modal.component.scss']
})
export class WarningModalContent {
  organizations: any;
  text: string = ""
  constructor(public activeModal: NgbActiveModal) { }

  onSave() {
    this.activeModal.close({ event: 'save' });
  }

}